import { Dialog, Form, FormContext, colorValue } from '@hai/ui-react';
import { FileUploadStages } from '@hai/ui-react/dist/components/FileUpload/FileUpload';
import { IUploadState } from '@hai/ui-react/dist/components/FileUpload/IFileUpload';
import { uploadCertificate } from 'api/security/certificates';
import { EnumFormButtonState } from 'components/common/form/formButton/formButton';
import { FormContainer } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { BuildFormSelect } from 'components/common/form/formSelect/formSelect';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import KrakenUpload from 'components/common/krakenUpload/KrakenUpload';
import { EnumCertificatesAction } from 'context/security/certificates';
import { SecurityContext } from 'context/security/security';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useFormRef } from 'utils/hooks/useFormRef';

interface IProps {
  show: boolean;
  handleClose: any;
}

export enum EnumCaImportType {
  CERTIFICATES = 'identity',
  PAIR = 'private-key' // api payload
}

export const ImportCertificate = (props: IProps) => {
  const { show, handleClose } = props;

  const [buttonState, setButtonState] = useState(EnumFormButtonState.IDLE);
  const [caFile, setCaFile] = useState<File>();
  const [privateKeyFile, setPrivateKeyFile] = useState<File>();
  const [bundleFile, setBundleFile] = useState<File>();

  const initState: IUploadState = {
    stage: FileUploadStages.NONE,
    animationTime: 0.5,
    progress: 0
  };

  const [uploadStateCa, setUploadStateCa] = useState(initState);
  const [uploadStateKey, setUploadStateKey] = useState(initState);
  const [uploadStateBundle, setUploadStateBundle] = useState(initState);

  const formControl = useFormRef();
  const { formRefCallback, formRef } = formControl;
  const values = formRef.current?.values || null;

  const [isPairType, setIsPairType] = useState(values?.type === EnumCaImportType.PAIR);

  const { securityDispatch } = useContext(SecurityContext);

  const clearFiles = () => {
    setCaFile(undefined);
    setPrivateKeyFile(undefined);
    setBundleFile(undefined);
  };

  useEffect(() => {
    clearFiles();
    setUploadStateCa(prev => {
      return {
        ...prev,
        stage: FileUploadStages.NONE,
        progress: 0
      };
    });
    setUploadStateKey(prev => {
      return {
        ...prev,
        stage: FileUploadStages.NONE,
        progress: 0
      };
    });
    setUploadStateBundle(prev => {
      return {
        ...prev,
        stage: FileUploadStages.NONE,
        progress: 0
      };
    });
  }, [show]);

  const importTypeOptions = [
    {
      option: t('SECURITY.CERTIFICATES.IMPORT_TYPES.certificates'),
      value: EnumCaImportType.CERTIFICATES
    },
    {
      option: t('SECURITY.CERTIFICATES.IMPORT_TYPES.keyCertPair'),
      value: EnumCaImportType.PAIR
    }
  ];

  const formatOptions = [
    {
      option: t('SECURITY.CERTIFICATES.FORMAT.auto'),
      value: 'auto'
    },
    {
      option: t('SECURITY.CERTIFICATES.FORMAT.der'),
      value: 'der'
    },
    {
      option: t('SECURITY.CERTIFICATES.FORMAT.pkcs7'),
      value: 'p7p'
    },
    {
      option: t('SECURITY.CERTIFICATES.FORMAT.pkcs12'),
      value: 'p12'
    }
  ];

  const initData = {
    type: EnumCaImportType.CERTIFICATES,
    format: 'auto',
    name: '',
    password: '',
    infmt: undefined,
    file: undefined
  };

  const onSave = async (values: any) => {
    const firstInProgress = (prev: any) => {
      return {
        ...prev,
        stage: FileUploadStages.INPROGRESS,
        progress: 40
      };
    };
    // 0 -> 40
    setUploadStateCa(firstInProgress);
    if (privateKeyFile && isPairType) {
      setUploadStateKey(firstInProgress);
    }
    if (bundleFile && isPairType) {
      setUploadStateBundle(firstInProgress);
    }

    setButtonState(EnumFormButtonState.PENDING);
    const certificate: any = {
      name: values.name || '',
      type: values.type,
      infmt: values.format,
      password: values.password || '',
      file: caFile as File
    };

    if (isPairType) {
      certificate.filePrivateKey = privateKeyFile;
      certificate.fileBundle = bundleFile;
      certificate.password = '';
      certificate.infmt = 'auto';
    }

    const res = await uploadCertificate({ ...certificate });
    const secondInProgress = (prev: any) => {
      return {
        ...prev,
        stage: FileUploadStages.INPROGRESS,
        progress: 80
      };
    };
    // 40 -> 80
    setUploadStateCa(secondInProgress);
    if (privateKeyFile && isPairType) {
      setUploadStateKey(secondInProgress);
    }
    if (bundleFile && isPairType) {
      setUploadStateBundle(secondInProgress);
    }
    if (res.data) {
      securityDispatch({ type: EnumCertificatesAction.SET_CERTIFICATES, payload: res.data });
      setTimeout(() => {
        const completedState = (prev: any) => {
          return {
            ...prev,
            stage: FileUploadStages.COMPLETED,
            progress: 100
          };
        };
        // 80 -> 100 -> complete
        setUploadStateCa(completedState);
        if (privateKeyFile && isPairType) {
          setUploadStateKey(completedState);
        }
        if (bundleFile && isPairType) {
          setUploadStateBundle(completedState);
        }
        setButtonState(EnumFormButtonState.PENDING);
      }, 500);
    } else {
      const failedState = (prev: any) => {
        return {
          ...prev,
          stage: FileUploadStages.NONE,
          progress: 0
        };
      };
      // 80 -> failed -> 0
      setUploadStateCa(failedState);
      if (privateKeyFile && isPairType) {
        setUploadStateKey(failedState);
      }
      if (bundleFile && isPairType) {
        setUploadStateBundle(failedState);
      }
      setButtonState(EnumFormButtonState.IDLE);
      clearFiles();
    }
  };

  const formProps = {
    defaultValidation: true,
    handleSubmit: onSave,
    initialValues: initData,
    restValidationProps: {
      innerRef: formRefCallback
    }
  };

  const onFileChangeHandlerCa = (files: File[]) => {
    setCaFile(files[0]);
  };

  const onFileChangeHandlerKey = (files: File[]) => {
    setPrivateKeyFile(files[0]);
  };

  const onFileChangeHandlerBundle = (files: File[]) => {
    setBundleFile(files[0]);
  };

  const isPending = buttonState === EnumFormButtonState.PENDING;
  const disableClose =
    isPending ||
    (uploadStateCa.progress !== undefined &&
      uploadStateCa.progress > 0 &&
      uploadStateCa.progress < 100);

  let dialogButtons: any = [
    {
      variant: 'secondary',
      onClick: handleClose,
      label: t('cancel'),
      disabled: disableClose
    }
  ];

  if (uploadStateCa.progress !== 100) {
    dialogButtons.push({
      variant: 'primary',
      close: false,
      onClick: () => submitHandler(formControl.formRef),
      disabled: !caFile,
      state: buttonState,
      label: t('SECURITY.CERTIFICATES.FORMS.import')
    });
  } else if (caFile && uploadStateCa.progress === 100) {
    dialogButtons = [
      {
        variant: 'primary',
        close: false,
        onClick: () => handleClose(),
        label: t('done')
      }
    ];
  }

  return (
    <Dialog
      title={t('SECURITY.CERTIFICATES.FORMS.importCertificate')}
      size='md'
      dialogType='activity'
      accentColor={colorValue('haiui-aqua-01')}
      headerIcon='Certificate'
      content={
        <Form
          {...formProps}
          onSubmit={e => {
            e.preventDefault();
          }}
          className={'import-certificate'}
        >
          <FormContext.Consumer>
            {(formContext: any) => {
              setIsPairType(formContext?.values?.type === EnumCaImportType.PAIR);
              return (
                <>
                  <FormContainer columns={1}>
                    {BuildFormSelect({
                      label: t('SECURITY.CERTIFICATES.FORMS.type'),
                      items: importTypeOptions,
                      selectName: 'type',
                      disabled: isPending,
                      dataAuto: 'cert_type'
                    })}
                    {!isPairType && (
                      <FormInput
                        label={t('SECURITY.CERTIFICATES.FORMS.name')}
                        disabled={isPending}
                        name='name'
                        defaultValue={''}
                        dataAuto='cert_name'
                      />
                    )}
                    {!isPairType &&
                      BuildFormSelect({
                        label: t('SECURITY.CERTIFICATES.FORMS.format'),
                        items: formatOptions,
                        selectName: 'format',
                        disabled: isPending,
                        dataAuto: 'cert_format'
                      })}
                    <FormInput
                      label={t('SECURITY.CERTIFICATES.FORMS.password')}
                      name='password'
                      defaultValue={''}
                      onChange={() => null}
                      disabled={isPending}
                      dataAuto='cert_password'
                    />
                    <FormInput
                      label={t('SECURITY.CERTIFICATES.FORMS.certificateFile')}
                      name='cafile'
                      classNames='input-file-upload'
                      required
                      inputComponent={
                        <KrakenUpload
                          files={caFile ? [caFile] : []}
                          onFileChange={onFileChangeHandlerCa}
                          uploadState={uploadStateCa}
                        />
                      }
                      dataAuto='cert_file_input'
                    />
                    {isPairType && (
                      <>
                        <FormInput
                          label={t('SECURITY.CERTIFICATES.FORMS.privateKey')}
                          name='privateKeyFile'
                          classNames='input-file-upload'
                          inputComponent={
                            <KrakenUpload
                              files={privateKeyFile ? [privateKeyFile] : []}
                              onFileChange={onFileChangeHandlerKey}
                              uploadState={uploadStateKey}
                            />
                          }
                          dataAuto='cert_key_input'
                        />
                        <FormInput
                          label={t('SECURITY.CERTIFICATES.FORMS.bundleFile')}
                          name='bundleFile'
                          classNames='input-file-upload'
                          inputComponent={
                            <KrakenUpload
                              files={bundleFile ? [bundleFile] : []}
                              onFileChange={onFileChangeHandlerBundle}
                              uploadState={uploadStateBundle}
                            />
                          }
                          dataAuto='cert_bundle_input'
                        />
                      </>
                    )}
                  </FormContainer>
                </>
              );
            }}
          </FormContext.Consumer>
        </Form>
      }
      show={show}
      onClose={disableClose ? () => null : handleClose}
      buttons={dialogButtons}
      dataAuto='import_certificate_modal'
    />
  );
};
