import './formInfo.scss';

import { Icon, Label3Book } from '@hai/ui-react';
import React from 'react';
import { ReactI18NextChild } from 'react-i18next';

interface formInfo {
  info: string | ReactI18NextChild;
  type?: string;
  className?: string;
  dataAuto?: string;
}
export const FormInfo = (params: formInfo) => {
  let { info, className, type = 'StatusWarning', dataAuto = '' } = params;
  let color,
    dark = true;
  if (type === 'Information') {
    color = '#9595a1';
    dark = false;
  }
  if (type === 'InfoDark') {
    type = 'Information';
    color = '#9595a1';
  }
  if (!info) {
    return null;
  }

  return (
    <div className={'form-info ' + (dark ? 'dark ' : '') + className}>
      <div>
        <Icon color={color} iconname={type} size='sm' />
      </div>
      <Label3Book className={'form-info-text'} data-auto={dataAuto ? dataAuto : ''}>
        {info}
      </Label3Book>
    </div>
  );
};
