import {
  AbilityBuilder,
  AbilityClass,
  AbilityTuple,
  MatchConditions,
  PureAbility
} from '@casl/ability';

interface ISetRole {
  group: string;
  license: any;
}
type IAppAbility = PureAbility<AbilityTuple, MatchConditions>;
const AppAbility = PureAbility as AbilityClass<IAppAbility>;
export const buildPermissions = (props: ISetRole) => {
  const { group, license } = props;

  const { can, cannot, build } = new AbilityBuilder(AppAbility);
  const lambdaMatcher = (matchConditions: MatchConditions) => matchConditions;

  if (group === 'admin') {
    can(['view', 'edit', 'delete', 'add', 'start', 'stop', 'call', 'get'], 'all');
  }

  if (group === 'operator') {
    can('get', ['presets']);
    can(
      ['edit', 'delete', 'add', 'start', 'stop', 'call', 'get'],
      ['streams', 'inputs', 'outputs', 'metadata', 'transcoders']
    );
    can('view', ['settings', 'security/myAccount', 'settings/reporting', 'settings/licensing']);
  }
  if (group === 'viewer') {
    can('view', ['security/myAccount']);
  }
  if (license) {
    buildOutLicense({ can, cannot, license });
  }

  return build({ conditionsMatcher: lambdaMatcher });
};

enum EnumBypassMode {
  INDIVIDUALLY_SELECT = 0,
  UNLIMITED = 1,
  MIXED = 2
}

const buildOutLicense = ({ can, cannot, license }: { can: any; cannot: any; license: any }) => {
  const licensed = Object.entries(license)
    .map(item => {
      if (item[1] === true) {
        return item[0].toUpperCase();
      }
    })
    .filter(item => item);

  if (licensed.indexOf('ISLICENSEVALID') !== -1) {
    can('license', licensed);
    setMetadata({ can, licensed });
    setMaxbypass({ can, license });
    setTranscodersEnabled({ can, license });
    setIsHardwareEncoder({ can, licensed });
    setUpdate({ can, licensed });
  }
  // This check is independent of having a valid license
  setNetwork({ cannot, licensed });
  can('role', 'isSet');
};

interface iLicensed {
  can?: any;
  cannot?: any;
  licensed?: any;
  license?: any;
}

const setMetadata = ({ can, licensed }: iLicensed) => {
  if (licensed.indexOf('KLV') !== -1) {
    can('license', 'METADATA');
  }
};

const setIsHardwareEncoder = ({ can, licensed }: iLicensed) => {
  if (licensed.indexOf('H264QSV') !== -1 || licensed.indexOf('HEVCQSV') !== -1) {
    can('license', 'useHardwareEncoder');
  }
};

const setMaxbypass = ({ can, license }: iLicensed) => {
  can('license', `maxBypassMode-${EnumBypassMode[license?.['maxBypassMode']]}`);
};

const setTranscodersEnabled = ({ can, license }: iLicensed) => {
  if (license.maxEncoders > 0) {
    can('license', 'useTranscoders');
  }
};

const setUpdate = ({ can, licensed }: iLicensed) => {
  // If this is a cloud instance, we don't want to allow updates
  // This acts like a negative license, hence the negation in the check.
  if (!(licensed.indexOf('ISCLOUD') !== -1)) {
    can('license', 'UPDATES');
  }
};

const setNetwork = ({ cannot, licensed }: iLicensed) => {
  // If this is a cloud instance, we don't want to allow editing the network
  if (licensed.indexOf('ISCLOUD') !== -1) {
    cannot('edit', 'network');
  }
};
