import { IGeneralApiProps } from 'api/global.type';
import { getSystemNetwork } from 'api/system/network';
import { EnumNetwork, NetworkContext } from 'context/network';
import { EnumNetworkAction } from 'context/settings/network';
import { StreamingContext } from 'context/streaming/streaming';
import { useCallback, useContext } from 'react';
import { useService } from 'utils/useService';

export const useGetNetwork = (props?: IGeneralApiProps) => {
  const fetchNetwork = useService(getSystemNetwork);
  const { network, fetching, dispatch } = useContext(NetworkContext);
  const { streamingDispatch } = useContext(StreamingContext);

  const getNetworkService = useCallback(
    (callback?: Function) => {
      if (network) {
        return network;
      }

      if (fetching) {
        return {};
      }
      return fetchNetwork(props).then((value: any) => {
        if (value && value?.response?.status !== 401) {
          dispatch({ type: EnumNetwork.SET_NETWORK, payload: value });

          streamingDispatch({ type: EnumNetworkAction.SET_NIC_CLEAN, payload: value.nics });
        }
        callback && callback();
      });
    },
    [dispatch, fetchNetwork, fetching, network, props, streamingDispatch]
  );

  return getNetworkService;
};
