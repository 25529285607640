import { Icon, List } from '@hai/ui-react';
import {
  IArchive,
  connectArchiveLocation,
  disconnectArchiveLocation,
  getArchiveLocation
} from 'api/settings/archiveLocations';
import { EnumFormButtonState } from 'components/common/form/formButton/formButton';
import LoadingIcon from 'components/common/loadingIcon/LoadingIcon';
import { ArchiveStats } from 'components/settings/archive/ArchiveStats';
import { EditArchive } from 'components/settings/archive/EditArchive';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumArchivesAction } from 'context/settings/archives';
import { SettingsContext } from 'context/settings/settings';
import { t } from 'i18n';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import constant from 'utils/constant';
import { getArchiveLocationType, getArchiveSpace, getArchiveStyle } from 'utils/settings';
import { actionStatus } from 'utils/streaming';

enum EnumActions {
  CONFIG = 'settings',
  STATS = 'STATS',
  NONE = 'none'
}

interface IProps {
  item: IArchive;
}
const ArchiveItem = (props: IProps) => {
  const { item } = props;

  const [openedTab, setOpenedTab] = useState<EnumActions>(EnumActions.NONE);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [buttonState, setButtonState] = useState(EnumFormButtonState.IDLE);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const { settingsDispatch, updatingArchives } = useContext(SettingsContext);
  const { icon, iconColor, panelColor, actionType } = getArchiveStyle(item);
  const archiveSpace = getArchiveSpace(item);
  const { locationUsed, locationUsedDisplay, locationUsedPercentage, spaceWarning } = archiveSpace;

  const [killContentSlow, setKillContentSlow] = useState(false);

  useEffect(() => {
    setTimeout(() => setKillContentSlow(false), constant.timeout.panelTransition);
  }, [isOpen]);

  const onSelectHandler = (eventKey: EnumActions, expanded: boolean) => {
    setOpenedTab(eventKey);
    setIsOpen(expanded);
  };

  const toggleSelection = (selected: boolean): void => {
    settingsDispatch({
      type: EnumArchivesAction.SELECT_ARCHIVES,
      payload: {
        id: item.uuid,
        selected: selected
      }
    });
  };

  const getExpandContent = useCallback(() => {
    if (!item || killContentSlow) {
      return null;
    }

    const onSuccess = () => {
      setIsOpen(false);
    };

    if (openedTab === EnumActions.CONFIG) {
      return (
        <EditArchive
          item={item}
          onSuccess={onSuccess}
          setButtonState={setButtonState}
          buttonState={buttonState}
        />
      );
    }

    if (openedTab === EnumActions.STATS) {
      return (
        <ArchiveStats item={item} actionType={actionType} archiveSpace={archiveSpace} icon={icon} />
      );
    }

    return null;
  }, [item, killContentSlow, openedTab, buttonState, actionType, archiveSpace, icon]);

  const isActive = actionType === actionStatus.active;

  const isLoading = !!updatingArchives?.find(i => i.uuid === item.uuid) || isUpdating;

  const triggerNotification = (notificationName: string, args?: any) => {
    notifyDispatch({
      type: EnumNotify.ADD_NOTIFICATION,
      payload: {
        type: 'Success',
        message: t(`SETTINGS.ARCHIVES.MESSAGES.${notificationName}`, args)
      }
    });
  };

  const onStatusChange = async () => {
    setIsUpdating(true);
    let result;
    if (isActive) {
      const res = await disconnectArchiveLocation({ item });
      result = res.result;
    } else {
      const res = await connectArchiveLocation({ item });
      result = res.result;
      setTimeout(async () => {
        const connected = await getArchiveLocation({ item: item });
        settingsDispatch({ type: EnumArchivesAction.UPDATE_ARCHIVE, payload: connected });
      }, 2000);
    }
    if (result) {
      triggerNotification(isActive ? 'disconnect' : 'connect', { name: item.name, count: 1 });
      settingsDispatch({ type: EnumArchivesAction.ADD_UPDATING_ARCHIVE, payload: item });
    }
    setTimeout(() => {
      setIsUpdating(false);
    }, constant.timeout.minButtonLoading);
  };

  const onTimeout = useCallback(() => {
    settingsDispatch({
      type: EnumArchivesAction.REMOVE_UPDATING_ARCHIVE,
      payload: item.uuid
    });
  }, [item.uuid, settingsDispatch]);

  return (
    <List.Panel
      checked={item.selected}
      onSelect={toggleSelection}
      onClick={() => {
        onSelectHandler(isOpen ? EnumActions.NONE : EnumActions.CONFIG, !isOpen);
      }}
      expandedState={{
        expanded: isOpen,
        eventKey: openedTab
      }}
      key={`account-panel-${item.uuid}`}
      panelColor={panelColor}
    >
      <List.PanelTitle title={item.name} statusIcon={icon} iconColor={iconColor} useFsMask={true} />
      <List.PanelDetail>
        <List.PanelDetailItem
          dataAuto='panel-detail-type'
          text={getArchiveLocationType(item.type)}
          divider={locationUsed ? 'vertical' : 'none'}
        />
        {locationUsed > 0 && (
          <List.PanelDetailItem
            dataAuto='panel-detail-used'
            text={locationUsedDisplay + ' (' + locationUsedPercentage + '%)'}
            icon={'Database'}
          />
        )}
        {spaceWarning && (
          <List.PanelDetailItem
            icon={<Icon iconname='StatusWarning' size='sm3' color='haiui-amber-01' />}
          />
        )}
      </List.PanelDetail>
      <List.Actions>
        <List.ActionItem
          expandButton={true}
          eventKey={EnumActions.STATS}
          icon={'ReportStats'}
          title={'stats'}
          onSelect={async (eventKey: string, expanded?: boolean) => {
            onSelectHandler(eventKey as EnumActions, expanded!);
            const connected = await getArchiveLocation({ item: item });
            settingsDispatch({ type: EnumArchivesAction.UPDATE_ARCHIVE, payload: connected });
          }}
        />
        <List.ActionItem
          expandButton={true}
          eventKey={EnumActions.CONFIG}
          icon={'Settings'}
          title={'settings'}
          onSelect={(eventKey: string, expanded?: boolean) =>
            onSelectHandler(eventKey as EnumActions, expanded!)
          }
        />
        <List.ActionItem isDivider />
        <List.ActionItem
          eventKey={'connect'}
          icon={
            <LoadingIcon
              isActive={isActive}
              activeIcon='StatusConnected'
              inactiveIcon='StatusUnpaired'
              loading={isLoading}
              onTimeout={onTimeout}
            />
          }
          onSelect={onStatusChange}
          showActive={false}
          disabled={isLoading}
        />
      </List.Actions>
      <List.ExpandedPanel>{getExpandContent()}</List.ExpandedPanel>
    </List.Panel>
  );
};

export default ArchiveItem;
