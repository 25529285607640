export const presetsEn = {
  autosave: 'Autosave',
  autosaveHelpEnabled:
    'When enabled, all changes to the loaded preset will be saved automatically.',
  autosaveHelpDisabled:
    'When disabled, all changes to the loaded preset will need to be saved manually.',
  autosaveToggled: 'Autosave {{switchState}}',
  configuration: 'Configuration',
  untitledPreset: 'Untitled Preset',
  defaultStartup: 'Last Loaded Preset',
  dropdownHeader: 'Device Presets',
  deleteConfirm: 'Delete? This cannot be undone.',
  exportBulkMsg_one: 'Exported one preset.',
  exportBulkMsg: 'Exported {{count}} presets.',
  deleteBulkMsg_one: 'Deleted one preset.',
  deleteBulkMsg: 'Deleted {{count}} presets.',
  importMsg: 'Imported {{name}}.',
  importMsg_other: '{{count}} Presets Imported.',
  duplicatePreset: "Preset '{{name}}' exists already. Overwrite it?",
  duplicatePreset_other: '{{count}} Presets exists already. Overwrite them?',
  presetStartupMsg: 'Startup set to "{{name}}"',
  presetDeleteMsg: 'Deleted "{{name}}".',
  presetExportMsg: 'Exported "{{name}}"',
  presetRenameMsg: 'Renamed preset to "{{name}}".',
  presetDuplicateMsg: 'Duplicated "{{name}}".',
  ERROR: {
    importMsg: 'Failed to Import {{name}}.',
    importMsg_other: 'Failed to Import {{count}} Presets.',
    autosaveToggled: 'Failed to set autosave.',
    exportBulkMsg_one: 'Failed to export one preset.',
    exportBulkMsg: 'Failed to export {{count}} presets.',
    deleteBulkMsg_one: 'Failed to delete one preset.',
    deleteBulkMsg: 'Failed to delete {{count}} presets.',
    presetDuplicateMsg: 'Failed to duplicate "{{name}}".',
    presetDeleteMsg: 'Failed to delete "{{name}}".',
    presetExportMsg: 'Failed to export "{{name}}".',
    presetRenameMsg: 'Failed to rename "{{name}}".',
    presetStartupMsg: 'Failed to update Startup'
  },
  krakenStartup: 'At startup, Kraken will load',
  loadDescAutosave: 'The current device configuration will be replaced by “{{preset}}”.',
  loadDesc:
    'The current device configuration will be replaced. To save the current configuration, \
    choose Cancel and select Save or Save As from the menu.',
  loadPreset: 'Load Preset',
  loadTitle: "Load '{{name}}' Preset?",
  manageDetails: 'Save your device configuration to a preset to load for future use.',
  managePresets: 'Manage Presets',
  modified: 'Modified',
  name: 'Name',
  newPreset: 'New Preset',
  presetNew: 'Created a new preset called {{name}}',
  presetName: 'Preset Name',
  saved: 'Saved "{{name}}"',
  saveFailed: 'Failed to save {{name}}',
  saveAs: 'Saved as "{{name}}"',
  saveAsDesc: 'Save current configuration as a preset.',
  saveConfirm: 'Save? This cannot be undone.',
  startup: 'Startup',
  useCurrent: 'Use Current Config',
  viewAllPresets: 'View All Presets',
  DELETE_MODAL: {
    title: 'Delete preset?',
    title_other: 'Delete {{count}} presets?',
    desc: 'Are you sure you want to delete this preset?',
    desc_other: 'Are you sure you want to delete these {{count}} presets?'
  },
  UNSAVED: {
    signout: 'Sign Out',
    save: 'Save and Sign Out',
    unsavedChanges: 'Sign Out Without Saving?',
    unsavedDesc:
      'The currently loaded preset has unsaved changes and will be lost if you choose "Sign out".',
    unsavedDesc2: 'Choose "Save and Sign Out" to save the preset and sign out.'
  }
};
