import { Icon, Label2Book, ProgressBar, colorValue } from '@hai/ui-react';
import { IArchive, getArchiveLocation } from 'api/settings/archiveLocations';
import { EnumFormButtonState, FormButton } from 'components/common/form/formButton/formButton';
import { FormContainer, FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumArchivesAction } from 'context/settings/archives';
import { SettingsContext } from 'context/settings/settings';
import { t } from 'i18next';
import React, { useContext, useState } from 'react';
import constant from 'utils/constant';
import { IArchiveSpace } from 'utils/settings';
import { actionStatus } from 'utils/streaming';

interface IProps {
  item: IArchive;
  actionType: string;
  archiveSpace: IArchiveSpace;
  icon: string;
}

export const ArchiveStats = (props: IProps) => {
  const { item, actionType, archiveSpace, icon } = props;

  const { screen } = useContext(GlobalContext);
  const columns = screen.columns.main;
  const [refreshBtnState, setRefreshBtnState] = useState(EnumFormButtonState.IDLE);
  const { settingsDispatch } = useContext(SettingsContext);
  const { dispatch } = useContext(NotifyContext);

  const onRefresh = async () => {
    setRefreshBtnState(EnumFormButtonState.PENDING);
    const res = await getArchiveLocation({ item: item });
    if (res.uuid) {
      settingsDispatch({ type: EnumArchivesAction.UPDATE_ARCHIVE, payload: res });
      dispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: t('SETTINGS.ARCHIVES.MESSAGES.refresh')
        }
      });
    }
    setTimeout(() => {
      setRefreshBtnState(EnumFormButtonState.IDLE);
    }, constant.timeout.minButtonLoading);
  };

  const sections = [
    {
      value: archiveSpace.locationUsedPercentage,
      barColor: colorValue('haiui-aqua-01'),
      title: t('SETTINGS.ARCHIVES.archiveLocation'),
      data: archiveSpace.locationUsedDisplay
    },
    {
      value: archiveSpace.fsUsedPercentage - archiveSpace.locationUsedPercentage,
      barColor: colorValue('haiui-blue-04'),
      title: t('SETTINGS.ARCHIVES.systemFiles'),
      data: `${archiveSpace.systemUsedMB} MB`
    }
  ];

  const getStatusText = (actionType: string) => {
    switch (actionType) {
      case actionStatus.inactive:
        return t('SETTINGS.ARCHIVES.STATE.disconnected');
      case actionStatus.alert:
        return t('SETTINGS.ARCHIVES.STATE.connecting');
      default:
        return t('SETTINGS.ARCHIVES.STATE.connected');
    }
  };

  return (
    <div className='archive-stats'>
      <FormContainer columns={columns}>
        <FormInput
          label={t('SETTINGS.ARCHIVES.STATE.title')}
          name='status'
          viewOnly
          defaultValue={getStatusText(actionType)}
          icon={icon}
        />
        <FormButton
          columns={columns}
          variant={'secondary'}
          buttonText={t('refresh')}
          state={refreshBtnState}
          submitHandler={onRefresh}
        />
      </FormContainer>
      <FormSectionDark title={t('SETTINGS.ARCHIVES.status')}>
        <Label2Book className='progress-bar-label'>
          {t('SETTINGS.ARCHIVES.spaceUsage')}
          {archiveSpace.spaceWarning && (
            <Icon iconname='StatusWarning' size='sm3' color='haiui-amber-01' className='ml-2' />
          )}
        </Label2Book>
        <ProgressBar
          leftLabel={t('SETTINGS.ARCHIVES.remaining', {
            amount: `${archiveSpace.systemFreeMB + archiveSpace.systemUsedMB} MB`,
            percentage: archiveSpace.fsFreePercentage
          })}
          sections={actionType === actionStatus.inactive ? undefined : sections}
          value={0}
          hideRight={true}
        />
      </FormSectionDark>
      <FormSectionDark>
        <Label2Book className='progress-bar-label first'>
          {t('SETTINGS.ARCHIVES.locationUsed')}
        </Label2Book>
        <ProgressBar
          value={archiveSpace.locationUsedPercentage}
          leftLabel={archiveSpace.locationUsedDisplay}
          hideRight={false}
          // @ts-ignore
          barColors={{ left: colorValue('haiui-aqua-01'), right: colorValue('haiui-aqua-01') }}
        />
        <Label2Book className='progress-bar-label'>
          {t('SETTINGS.ARCHIVES.filesystemUsed')}
          {archiveSpace.fsUsedPercentage > 85 && (
            <Icon iconname='StatusWarning' size='sm3' color='haiui-amber-01' className='ml-2' />
          )}
        </Label2Book>
        <ProgressBar
          value={archiveSpace.fsUsedPercentage}
          leftLabel={`${archiveSpace.systemUsedMB} MB`}
          hideRight={false}
          barColors={{
            left: colorValue('haiui-blue-04'),
            right: colorValue('haiui-blue-04')
          }}
        />
        <Label2Book className='progress-bar-label'>
          {t('SETTINGS.ARCHIVES.filesystemFree')}
        </Label2Book>
        <ProgressBar
          value={archiveSpace.fsFreePercentage}
          leftLabel={`${archiveSpace.systemFreeMB} MB`}
          hideRight={false}
          barColors={{
            left: colorValue('haiui-purple-01'),
            right: colorValue('haiui-purple-01')
          }}
        />
      </FormSectionDark>
    </div>
  );
};
