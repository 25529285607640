import { getDashboardStats } from 'api/dashboard/dashboard';
import { EnumDashboardAction } from 'api/dashboard/dashboard.type';
import { IGeneralApiProps } from 'api/global.type';
import { DashboardContext } from 'context/dashboard';
import { useCallback, useContext } from 'react';
import { useService } from 'utils/useService';

export const useGetDashboardStats = (props?: IGeneralApiProps) => {
  const fetchDashboardStats = useService(getDashboardStats);
  const { dashboardDispatch } = useContext(DashboardContext);

  const getDashboardStatsService = useCallback(
    (callback?: Function) => {
      return fetchDashboardStats(props)
        .then((value: any) => {
          if (value) {
            dashboardDispatch({ type: EnumDashboardAction.SET, payload: value });
          }
          callback && callback();
        })
        .catch(() => callback && callback());
    },
    [dashboardDispatch, fetchDashboardStats, props]
  );

  return getDashboardStatsService;
};
