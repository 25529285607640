import { List } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { formatShort } from 'api/global.type';
import { IStreamItem } from 'api/streaming/streaming.type';
import { ITranscoder } from 'api/transcoder/transcoder.type';
import { StreamTable } from 'components/common/streamTable/StreamTable';
import { EditTranscoder } from 'components/streaming/transcoders/EditTranscoder';
import { StreamingContext } from 'context/streaming/streaming';
import { EnumTranscodersAction } from 'context/streaming/transcoders';
import { t } from 'i18next';
import { Licensed, RoleContext } from 'permissions/role/Roles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import constant from 'utils/constant';
import { useTranscoderInfo } from 'utils/hooks/useTranscoderInfo';
import { getPanelColor } from 'utils/streaming';

enum EnumActions {
  REPORT = 'report',
  EDIT = 'edit',
  NONE = 'none'
}

interface IProps {
  item: ITranscoder;
  saveCallback: Function;
}
const TranscoderItem = (props: IProps) => {
  const { item, saveCallback } = props;
  const { streamingDispatch, streamsClean } = useContext(StreamingContext);
  const getTranscoderInfo = useTranscoderInfo();
  const role = useContext(RoleContext);

  const [transcoderInfo, setTranscoderInfo] = useState(getTranscoderInfo(item));

  useEffect(() => {
    setTranscoderInfo(getTranscoderInfo(item));
  }, [getTranscoderInfo, item]);

  const [openedTab, setOpenedTab] = useState<EnumActions>(EnumActions.NONE);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [buttonState, setButtonState] = useState<ButtonStateType>('idle');

  const [killContentSlow, setKillContentSlow] = useState(false);

  useEffect(() => {
    setTimeout(() => setKillContentSlow(false), constant.timeout.panelTransition);
  }, [isOpen]);

  const cancel = () => {
    setOpenedTab(EnumActions.NONE);
    setIsOpen(false);
  };

  const onSelectHandler = useCallback(
    (eventKey: EnumActions, expanded: boolean) => {
      if (isOpen && expanded) {
        setIsOpen(false);
        setTimeout(() => {
          setOpenedTab(eventKey);
          setIsOpen(expanded);
        }, 100);
      } else {
        setOpenedTab(eventKey);
        setIsOpen(expanded);
      }
    },
    [isOpen]
  );

  const streamList = streamsClean?.filter((stream: IStreamItem) => stream.transcoder === item.uuid);

  const getExpandContent = useCallback(() => {
    if (!item || killContentSlow) {
      // clear states in tab
      return null;
    }

    const onSettingsApply = () => {
      setButtonState('idle');
      setOpenedTab(EnumActions.NONE);
      saveCallback();
      setIsOpen(false);
    };
    if (openedTab === EnumActions.EDIT) {
      return (
        <EditTranscoder
          cancel={cancel}
          setButtonState={setButtonState}
          buttonState={buttonState}
          item={item}
          saveCallback={onSettingsApply}
        />
      );
    }

    if (openedTab === EnumActions.REPORT) {
      return <StreamTable streams={streamList} />;
    }

    return null;
  }, [buttonState, item, killContentSlow, openedTab, saveCallback, streamList]);

  const toggleSelection = (selected: boolean): void => {
    streamingDispatch({
      type: EnumTranscodersAction.SELECT_TRANSCODER,
      payload: {
        id: item.uuid,
        selected: selected
      }
    });
  };

  const buildResolution = (id: string, video: any) => {
    const result = [];
    const formatFrameRate = (fr: string) => {
      return Number(fr);
    };

    const framerate = () => {
      const f = formatFrameRate(video.framerate);
      if (!f) {
        return '';
      }
      return `${video.isInterlaced ? 'i' : 'p'}${f}`;
    };

    if (video.resolutionMode !== 1 || video.framerate < 1) {
      if (video.resolutionMode !== 1) {
        result.push(
          <List.PanelDetailItem
            key='auto-resolution'
            divider={'vertical'}
            text={t('STREAMING.TRANSCODERS.autoSize')}
          />
        );
      } else if (video.resolutionWidth && video.resolutionHeight) {
        result.push(
          <List.PanelDetailItem
            key='resolution'
            divider={'vertical'}
            text={
              <span key={`multi-hw-${id}`}>
                {`${video.resolutionWidth}x${video.resolutionHeight}${
                  video.isInterlaced ? 'i' : 'p'
                }
                `}
              </span>
            }
          />
        );
      }
      if (video.framerate < 1 || isNaN(video.framerate)) {
        result.push(
          <List.PanelDetailItem
            key='auto-fps'
            divider={'vertical'}
            text={t('STREAMING.TRANSCODERS.autoFps')}
          />
        );
      } else {
        result.push(
          <List.PanelDetailItem key='fps' divider={'vertical'} text={`${video.framerate} fps`} />
        );
      }
      return result;
    } else {
      return (
        <List.PanelDetailItem
          divider={'vertical'}
          key='hw-fps'
          text={
            <span key={`multi-hw-${id}`}>
              {`${video.resolutionWidth}x${video.resolutionHeight}${framerate()}`}
            </span>
          }
        />
      );
    }
  };

  const videoTypeToString = (type: any) => {
    return formatShort[type];
  };

  return (
    <List.Panel
      checked={item.selected}
      onSelect={toggleSelection}
      onClick={() => {
        onSelectHandler(isOpen ? openedTab : EnumActions.EDIT, !isOpen);
      }}
      expandedState={{
        expanded: isOpen,
        eventKey: openedTab
      }}
      panelColor={getPanelColor(transcoderInfo.actionType)}
      key={`input-panel-${item.uuid}`}
    >
      <List.PanelTitle
        title={item.name}
        statusIcon={transcoderInfo.icon}
        iconColor={transcoderInfo.iconColor}
        useFsMask={true}
      />
      <List.PanelDetail>
        {item.videoType && (
          <List.PanelDetailItem
            dataAuto='panel-detail-bitrate'
            divider={'vertical'}
            text={videoTypeToString(item.videoType)}
          />
        )}

        {item.resolution && buildResolution(item.uuid, item)}
        {item.videoBitrateFormatted && (
          <List.PanelDetailItem
            dataAuto='panel-detail-bitrate'
            {...(item.gop || item.audioEnabled || role.can('METADATA')
              ? { divider: 'vertical' }
              : {})}
            text={item.videoBitrateFormatted}
          />
        )}
        {item.gop && (
          <List.PanelDetailItem
            dataAuto='panel-detail-gob'
            {...(item.audioEnabled || role.can('METADATA') ? { divider: 'vertical' } : {})}
            divider={'vertical'}
            text={item.gop}
          />
        )}
        {item.audioEnabled && (
          <List.PanelDetailItem
            {...(role.can('license', 'METADATA') ? { divider: 'vertical' } : {})}
            dataAuto='panel-detail-audio'
            text={
              item.audioEnabled === 'Enabled'
                ? t('STREAMING.TRANSCODERS.audioOn')
                : t('STREAMING.TRANSCODERS.audioOff')
            }
            icon={item.audioEnabled === 'Enabled' ? 'AudioOn' : 'AudioOff'}
          />
        )}

        <Licensed to='METADATA'>
          <List.PanelDetailItem
            text={
              item.metadata
                ? t('STREAMING.TRANSCODERS.klvEnabled')
                : t('STREAMING.TRANSCODERS.klvDisabled')
            }
            dataAuto='panel-detail-klv'
          />
        </Licensed>
      </List.PanelDetail>
      <List.Actions>
        <List.ActionItem
          expandButton={true}
          eventKey={EnumActions.REPORT}
          icon={'StreamsOut'}
          title={'stats'}
          disabled={!streamList?.length}
          onSelect={(eventKey: string, expanded?: boolean) =>
            onSelectHandler(eventKey as EnumActions, expanded!)
          }
        ></List.ActionItem>
        <List.ActionItem
          expandButton={true}
          eventKey={EnumActions.EDIT}
          icon={'Settings'}
          title={'settings'}
          onSelect={(eventKey: string, expanded?: boolean) =>
            onSelectHandler(eventKey as EnumActions, expanded!)
          }
        />
      </List.Actions>
      <List.ExpandedPanel>{getExpandContent()}</List.ExpandedPanel>
    </List.Panel>
  );
};

export default TranscoderItem;
