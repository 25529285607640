export const outputsEn = {
  addOutput: 'Add Output',
  createOutputs: 'Add an output to configure stream destinations',
  noOutputs: 'No Outputs Configured',
  DELETE_MODAL: {
    title: 'Delete output?',
    title_other: 'Delete {{count}} outputs?',
    desc: 'Are you sure you want to delete this output?',
    desc_other: 'Are you sure you want to delete these {{count}} outputs?'
  },
  ADD: {
    archive: 'Archive',
    archiveLocation: 'Archive Location',
    bandwidthOverhead: 'Bandwidth Overhead',
    encryption: 'Encryption',
    ENCRYPT: {
      none: '(None)',
      aes128: 'AES-128',
      aes256: 'AES-256'
    },
    mtu: 'MTU',
    hlsUrl: 'HLS URL',
    prefix: 'Prefix',
    protocol: 'Protocol',
    PROTOCOL: {
      RTMP: 'RTMP',
      RTSP: 'RTSP',
      TS_over_SRT: 'TS over SRT',
      TS_over_UDP: 'TS over UDP',
      TS_SEGMENT: 'TS Segment Archive',
      HLS: 'HLS'
    },
    linkParameters: 'Link Parameters',
    rtspDirection: 'Direction',
    rtspPath: 'Path',
    rtspServer: 'Server',
    rtspServerHelp: 'IP or Hostname',
    rtspPort: 'Port',
    sap: 'SAP',
    segmentFormat: 'Segment Format',
    segmentsMax: 'Max Number of Segments',
    segmentsDuration: 'Segment Duration (Milliseconds)',
    tos: 'TOS',
    transmitSAP: 'Transmit SAP',
    ttl: 'TTL',
    urlHelp: 'e.g. udp://239.100.100.100:6000',
    urlHelpRTMP: 'e.g. rtmp://domain.com/entrypoint or rtmp://[fe80::e77:ff:e00:8961]:1935/live',
    urlPlaceholder: 'Example: udp://239.1.1.2:4900'
  },
  MESSAGES: {
    added: '"{{name}}" added successfully.',
    deleted: '"{{name}}" deleted successfully.',
    deleted_other: '{{count}} outputs deleted successfully.'
  },
  STATS: {
    audioBitrate: 'Audio Bitrate',
    audioBytes: 'Audio Bytes',
    audioCount: 'Audio Count',
    audioProgId: 'Audio Prog. ID',
    audioType: 'Audio Type',
    auxBitrate: 'Aux Bitrate',
    auxBytes: 'Aux Bytes',
    auxCount: 'Aux Count',
    auxProgId: 'Aux Prog. ID',
    auxType: 'Aux Type',
    bitrate: 'Bitrate',
    bytes: 'Bytes',
    patCount: 'PAT Count',
    pmtCount: 'PMT Count',
    psiCount: 'PSI Count',
    srtMode: 'SRT Mode',
    srtState: 'SRT State',
    url: 'URl',
    videoBitrate: 'Video Bitrate',
    videoBytes: 'Video Bytes',
    videoCount: 'Video Count',
    videoProgId: 'Video Prog. ID',
    videoType: 'Video Type',
    noAux: 'No Aux'
  },
  RESTART: {
    title: 'Are you sure you want to restart the stream now?',
    restartStream: 'Restart Stream',
    desc: `The output you modified is in use, you will need to restart the following
     streams before those changes will take effect ({{listOfStreams}})\n
    Would you like to restart the following streams now?`
  }
};
