import './reporting.scss';

import {
  Button,
  DataTable,
  DynamicContainer,
  FormLabel,
  FormSection,
  Pagination,
  Placeholder,
  Switch
} from '@hai/ui-react';
import { IEvent, IEvents, downloadEvents, getEvents } from 'api/events/events';
import { getDebugLogging, updateDebugLogging } from 'api/settings/system';
import { IDebugLogging } from 'api/settings/system.type';
import config from 'api/utils/config';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { EnumFormButtonState } from 'components/common/form/formButton/formButton';
import { FormSectionDark } from 'components/common/form/formContainer/formContainer';
import SettingsHeader from 'components/common/settingsHeader/SettingsHeader';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { SettingsContext } from 'context/settings/settings';
import { EnumStatusAction } from 'context/settings/status';
import { t } from 'i18n';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { downloadFile } from 'utils/downloadFiles';
import { openNewWindow } from 'utils/global';

const Reporting = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(true);
  const [debugLoggingEnabled, setDebugLoggingEnabled] = useState<boolean | 'unset'>('unset');
  const [buttonState, setButtonState] = useState(EnumFormButtonState.IDLE);
  const { settingsDispatch } = useContext(SettingsContext);

  const [events, setEvents] = useState<IEvents>();

  const { dispatch } = useContext(GlobalContext);
  const { screen } = useContext(GlobalContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const dataFetchedRef = useRef(false);

  const columns = screen.columns.main;
  const tableStructure = [
    { fieldKey: 'stream', title: t('SETTINGS.REPORTING.stream') },
    { fieldKey: 'message', title: t('SETTINGS.REPORTING.message') },
    { fieldKey: 'date', title: t('SETTINGS.REPORTING.date') }
  ];

  const enableDebugLogging = useCallback(async () => {
    setIsUpdating(true);
    const result = await updateDebugLogging({ apiParams: { enabled: !debugLoggingEnabled } });

    settingsDispatch({ type: EnumStatusAction.SET_DEBUG_LOGGING, payload: !debugLoggingEnabled });
    if (result.message) {
      notifyDispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: debugLoggingEnabled
            ? t('SETTINGS.REPORTING.DEBUG_LOGGING.disabledMsg')
            : t('SETTINGS.REPORTING.DEBUG_LOGGING.enabledMsg')
        }
      });
    }
    setIsUpdating(false);
  }, [debugLoggingEnabled, notifyDispatch, settingsDispatch]);

  const onDebugLoggingClick = useCallback(
    (checked: boolean) => {
      // update value to match switch onclick event
      setDebugLoggingEnabled(checked);
      dispatch({
        type: EnumGlobalAction.SET_CONFIRM_MODAL,
        payload: {
          onCancel: () => {
            // revert switch behavior
            setDebugLoggingEnabled(!checked);
          },
          show: true,
          onConfirm: enableDebugLogging,
          title: debugLoggingEnabled
            ? t('SETTINGS.REPORTING.DEBUG_LOGGING.disableTitle')
            : t('SETTINGS.REPORTING.DEBUG_LOGGING.enableTitle'),
          desc: debugLoggingEnabled
            ? t('SETTINGS.REPORTING.DEBUG_LOGGING.disableDesc')
            : t('SETTINGS.REPORTING.DEBUG_LOGGING.enableDesc'),
          confirmText: t('confirm'),
          isDanger: false
        }
      });
    },
    [debugLoggingEnabled, dispatch, enableDebugLogging]
  );

  useEffect(() => {
    debugLoggingEnabled !== 'unset' && setIsUpdating(false);
  }, [debugLoggingEnabled]);

  const refreshEvents = useCallback(
    (page: number) =>
      getEvents({ page: page, pageSize: 25 }).then((value: IEvents) => {
        value && setEvents(value);
        setTimeout(() => {
          setButtonState(EnumFormButtonState.IDLE);
        }, 500);
      }),
    []
  );

  useEffect(() => {
    if (!events && !dataFetchedRef.current) {
      dataFetchedRef.current = true;
      refreshEvents(1);
    }
  }, [events, refreshEvents]);

  useEffect(() => {
    if (debugLoggingEnabled === 'unset') {
      getDebugLogging().then((value: IDebugLogging) => {
        setDebugLoggingEnabled(value.enabled);
      });
    }
  }, [debugLoggingEnabled]);

  const refresh = () => {
    setButtonState(EnumFormButtonState.PENDING);
    refreshEvents(events?.paging.page || 1);
  };

  const download = () => {
    downloadEvents().then(resp => {
      downloadFile({ data: resp, fileName: 'hvevents.log' });
    });
  };

  const onSystemSnapshot = () => {
    openNewWindow({
      url: `${config.ApiUrl}${config.ApiPath}system/snapshot/start`,
      target: '_blank',
      features: 'width=1080,height=800,scrollbars=1,menubar=1, resizable=1'
    });
  };
  return (
    <div className='settings-reporting'>
      <FadeIn
        visible={events?.eventList !== undefined}
        suspenseComponent={<ReportingPlaceholder tableStructure={tableStructure} />}
      >
        <SettingsHeader
          title={t('ROUTES.SETTINGS.reporting')}
          buttons={
            <Button variant='secondary' onClick={onSystemSnapshot} size='regular'>
              {t('SETTINGS.REPORTING.systemSnapshot')}
            </Button>
          }
        />
        <FormSectionDark title={t('SETTINGS.REPORTING.status')} className='status-section'>
          <DynamicContainer maxColumns={columns} minColumns={3}>
            <div
              className='HaiSwitch'
              style={{ display: 'flex', alignItems: 'flex-start' }}
              onClick={useCallback(() => {
                // mimic switch behavior
                onDebugLoggingClick(!debugLoggingEnabled);
              }, [debugLoggingEnabled, onDebugLoggingClick])}
            >
              <FormLabel className='toggle-label' text={t('SETTINGS.REPORTING.debugLogging')} />
              <Switch
                checked={!!debugLoggingEnabled}
                disabled={isUpdating}
                onChange={onDebugLoggingClick}
                labelPlacement='left'
              />
            </div>
          </DynamicContainer>
        </FormSectionDark>

        {events?.eventList && (
          <FormSection
            title={t('SETTINGS.REPORTING.events')}
            className='events-section'
            data-auto='form_section_events'
          >
            <div className='buttons'>
              <Button size='small' onClick={download}>
                {t('download')}
              </Button>
              <Button size='small' onClick={refresh} variant='primary' state={buttonState}>
                {t('refresh')}
              </Button>
            </div>

            <DataTable
              emptyStateText={t('SETTINGS.REPORTING.noEvents')}
              className='content'
              sortable={false}
              columnStructure={tableStructure}
              useFsMask={true}
            >
              <DataTable.Header bulkActions={() => null} />
              {events?.eventList?.map((value: IEvent, index: number) => {
                return (
                  <DataTable.Row
                    key={`${value.pid}-${index}`}
                    rowData={{
                      index: index,
                      id: `${value.pid}-${index}`,
                      stream: value.name,
                      message: value.message,
                      date: value.date
                    }}
                    className={`reporting-datatable-row`}
                  ></DataTable.Row>
                );
              })}
            </DataTable>
          </FormSection>
        )}
        {events?.paging && events?.eventList.length && (
          <Pagination
            pageCount={Math.ceil(events?.paging.results / events?.paging.pageSize)}
            currentPage={events?.paging.page || 1}
            onPageChange={newPage => refreshEvents(newPage)}
            className='reporting-pagination'
            data-auto='events_list_pagination'
          />
        )}
      </FadeIn>
    </div>
  );
};

const ReportingPlaceholder = (props: { tableStructure: any }) => {
  const { tableStructure } = props;
  return (
    <>
      <Placeholder.ListActionBar
        numToggleButtons={0}
        numRightComponents={1}
        numDropdowns={0}
        showCheckbox={false}
        showSearchInput={false}
      />
      <Placeholder as='layout' style={{ margin: '60px 0', width: '100%', height: '100px' }} />
      <Placeholder.Table columnStructure={tableStructure} numRows={20} />
    </>
  );
};

export default Reporting;
