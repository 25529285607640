export const inputsEn = {
  addInput: 'Add Input',
  currentCamera: 'Current Camera Configuration',
  createInput: 'Add an input to configure video sources',
  noInputs: 'No Inputs Configured',
  inputParameters: 'Input Parameters',
  ADD: {
    address: 'Address',
    addressHelp: 'IP or Hostname',
    chroma: 'Chroma',
    decklinkMode: 'DeckLink Mode',
    destinationPort: 'Destination Port',
    deviceAddress: 'Device Address',
    devicePort: 'Device Port',
    devicePortHelp: 'Range [1, 2]',
    gigeApply: 'Click Apply to populate ...',
    ipPort: 'IP Port',
    ipv6: 'IPv6',
    latency: 'Latency',
    mode: 'Mode',
    name: 'Name',
    analogCapture: 'Analog Capture',
    captureMode: 'Analog Capture Mode',
    namePlaceholder: 'Input Name',
    ndiName: 'NDI Source Name',
    nic: 'Network Interface',
    passphrase: 'Passphrase',
    port: 'Port',
    portHelp: 'Range [0, 65535]',
    rstpPortHelp: 'Range [1, 65535]',
    source: 'Source',
    sourcePort: 'Source Port',
    sourceSpecificMulticast: 'Source Specific Multicast',
    sourceSpecificMulticastHelp: 'e.g. 192.168.1.220',
    transport: 'Transport',
    transportHelp:
      'Examples: rtsp://domain.com/live or rtsp://10.6.62.38/live or rtsp://[fe80::e77:ff:e00:8961]/live',
    url: 'URL',
    urlRawHelp: 'Examples: udp://239.1.1.2:4900 or udp://[ff02::e77:ff:e00:8961]:4900',
    urlHelp: 'e.g. udp://239.100.100.100:5000',
    RTSPHelp: 'e.g. rtsp://239.100.100.100/live',
    RTMPHelp: 'e.g. rtmp://239.100.100.100:123/live',
    MJPEG_HTTPHelp: 'e.g. http://10.1.1.2:9000 or https://[fe80::e77:ff:e00:8961]:9000',
    urlPlaceholder: 'IP or Hostname',
    videoMode: 'Video Mode',
    RTMP_MODE: {
      client: 'Client',
      server: 'Server'
    },
    SOURCE: {
      TS_over_UDP: 'TS over UDP',
      TS_over_SRT: 'TS over SRT',
      RTSP: 'RTSP',
      RAW_Motion_JPEG: 'RAW Motion JPEG',
      MJPEG_HTTP: 'MJPEG over HTTP',
      RTMP: 'RTMP',
      ST2110: 'ST2110',
      GigE_Vision: 'GigE Vision',
      NDI: 'NDI'
    },
    SRT_MODE: {
      caller: 'Caller',
      listener: 'Listener',
      rendezvous: 'Rendezvous'
    }
  },

  DELETE_MODAL: {
    title: 'Delete input?',
    title_other: 'Delete {{count}} inputs?',
    desc: 'Are you sure you want to delete this input?',
    desc_other: 'Are you sure you want to delete these {{count}} inputs?'
  },
  MESSAGES: {
    added: '"{{name}}" added successfully.',
    deleted: '"{{name}}" deleted successfully.',
    deleted_other: '{{count}} inputs deleted successfully.'
  },
  STATS: {
    audioBitrate: 'Audio Bitrate',
    audioBytes: 'Audio Bytes',
    audioCount: 'Audio Count',
    audioProgId: 'Audio Prog. ID',
    audioType: 'Audio Type',
    auxBitrate: 'Aux Bitrate',
    auxBytes: 'Aux Bytes',
    auxCount: 'Aux Count',
    auxProgId: 'Aux Prog. ID',
    auxType: 'Aux Type',
    bitrate: 'Bitrate',
    bytes: 'Bytes',
    patCount: 'PAT Count',
    pmtCount: 'PMT Count',
    psiCount: 'PSI Count',
    srtMode: 'SRT Mode',
    srtState: 'SRT State',
    streamType: 'Stream Type',
    url: 'URl',
    videoBitrate: 'Video Bitrate',
    videoBytes: 'Video Bytes',
    videoCount: 'Video Count',
    videoProgId: 'Video Prog. ID',
    videoType: 'Video Type',
    noAux: 'No Aux'
  },
  RESTART: {
    title: 'Are you sure you want to restart the stream now?',
    restartStream: 'Restart Stream',
    desc: `The input you modified is in use,
     you will need to restart the following streams before those changes will take effect ({{listOfStreams}})\n
    Would you like to restart the following streams now?`
  }
};
