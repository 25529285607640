import client from 'api/utils/client';

export interface ICaptureResp {
  portInfoSize: number;
  portInfo: ICapture[];
}
export interface ICapture {
  label: string;
  modelName: string;
  portno: number;
  index: number;
  videomodes: ICaptureVideomode[];
}
interface ICaptureVideomode {
  width: number;
  height: number;
  framerate: number;
  interlaced: boolean;
  modeDesc: string;
}

export const getCapture = () => {
  return client
    .get('/v4l2')
    .then(resp => {
      const data = resp.data;
      return apiToFormdata(data);
    })
    .catch(e => {
      return e;
    });
};

const apiToFormdata = (data: ICaptureResp) => {
  if (data.portInfoSize && data.portInfo) {
    return {
      portInfoSize: data.portInfoSize,
      portInfo: data.portInfo.map((item: ICapture, index: number) => {
        return {
          modelName: item.modelName,
          portno: item.portno,
          index: index,
          videomodes: item.videomodes.map((i: ICaptureVideomode, index: number) => {
            return {
              value: index,
              option: i.modeDesc
            };
          }),
          label: `${item.modelName.toUpperCase().indexOf('CAPTURE ') !== -1 ? 'SDI ' : ''}${
            item.modelName
          } ${item.portno + 1}`
        };
      })
    };
  }
  return data;
};
