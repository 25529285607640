export const networkEn = {
  general: 'General',
  hostname: 'Hostname',
  defaultInterface: 'Default Interface',
  dnsServer0: 'Primary DNS',
  dnsServer1: 'Secondary DNS',
  searchDomain0: 'Search Domain #1',
  searchDomain1: 'Search Domain #2',
  searchDomain2: 'Search Domain #3',
  ntpServer: 'NTP Server',
  ipForwarding: 'IP Forwarding',
  snmp: 'SNMP',
  redirectMessages: 'Accept Redirect Messages',
  interfaces: 'Interfaces',
  ipV4: 'IPv4',
  ipV6: 'IPv6',
  ipv6Error: 'Invalid IPv6 address',
  ipv6PrefixError: 'Prefix must be between 1 - 128',
  userLabel: 'User Description',
  ipAddress: 'IP Address',
  subnetMask: 'Subnet Mask',
  gateway: 'Gateway',
  mtu: 'MTU',
  macAddress: 'MAC Address',
  dnsMode: 'DNS Mode',
  modeAuto: 'Automatic',
  modeManual: 'Manual',
  staticRoutes: 'Static Routes',
  addStaticRoute: 'Add Static Route',
  dhcp: 'DHCP',
  static: 'Static',
  addressing: 'Addressing',
  ipV6Address: 'Global IPv6 Address',
  subnetPrefixLength: 'Subnet Prefix Length',
  automaticDhcp: 'Automatic (DHCP)',
  automatic: 'Automatic',
  privacyExtension: 'Privacy Extension',
  duplicateAddressDetection: 'Duplicate Address Detection',
  addRoute: 'Add Route',
  destination: 'Destination',
  interface: 'Interface',
  editStaticRoute: 'Edit Static Route',
  editRoute: 'Edit Route',
  ipv6linklocal: 'Link-Local IPv6 Address',
  ipv6management: 'Management IPv6 Address',
  ipv6temporary: 'Temporary IPv6 Address',
  protocol: 'Protocol',
  dnsPrecedence: 'DNS Precedence',
  readOnlyCommunity: 'Read-Only Community',
  snmpTrapServer: 'SNMP Trap Server',
  snmpTrapServerCommunity: 'SNMP Trap Server Community',
  snmpTrapServerAdd: 'Add SNMP Trap Server',
  updated: 'Network settings updated',
  warning: 'Any network modifications will require a reboot in order to take effect.',
  speed: 'Speed',
  speed10: '10 (Mbps)',
  speed100: '100 (Mbps)',
  speed1000: '1000 (Mbps)',
  duplex: 'Duplex',
  linkManual: 'Manual',
  linkAuto: 'Auto',
  link: 'Link',
  full: 'Full',
  half: 'Half',
  maskPrefix: 'Mask/Prefix Length',
  prefixLength: 'Prefix Length',
  deleteRoutesTitle_one: 'Are you sure you want to delete this static route?',
  deleteRoutesTitle_other: 'Are you sure you want to delete these static routes?',
  applyMsg: 'Are you sure you want to apply the changes',
  applyDesc: 'These changes will require a reboot to take effect',
  PLACEHOLDERS: {
    server: 'Server',
    community: 'Community'
  }
};
