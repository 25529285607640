import './network.scss';

import { Button, ButtonToggleGroup, Form, FormContext, Placeholder } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { IButtonToggleOption } from '@hai/ui-react/dist/components/ButtonToggleGroup/ButtonToggleGroup';
import { rebootSystem } from 'api/settings/system';
import { getSystemNetwork, updateSystemNetwork } from 'api/system/network';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { FormContainer, FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import { useNotifyChanges } from 'components/common/notify/NotifyReboot';
import SettingsHeader from 'components/common/settingsHeader/SettingsHeader';
import GeneralNetworkConfig from 'components/settings/network/GeneralNetworkConfig';
import IpV4Config from 'components/settings/network/IpV4Config';
import IpV6Config from 'components/settings/network/IpV6Config';
import StaticRoutes from 'components/settings/network/StaticRoutes';
import { networkSchema } from 'components/settings/network/validations';
import { EnumGlobalAction, EnumRebootState, GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { PresetContext } from 'context/preset';
import { EnumNetworkAction, INetwork, IStaticRoute } from 'context/settings/network';
import { SettingsContext } from 'context/settings/settings';
import { t } from 'i18n';
import { RoleContext } from 'permissions/role/Roles';
import React, { useContext, useEffect, useState } from 'react';
import { useFormRef } from 'utils/hooks/useFormRef';

const Network = () => {
  const [btnState, setBtnState] = useState<ButtonStateType>('idle');
  const [activeIcIndex, setActiveIcIndex] = useState<number>(-1);
  const [staticRoutes, setStaticRoutes] = useState<IStaticRoute[]>();
  const { settingsDispatch, network } = useContext(SettingsContext);
  const { dispatch, screen } = useContext(GlobalContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const formControl = useFormRef();

  const { notifyChanges } = useNotifyChanges();

  const { unsavedChanges } = useContext(PresetContext);

  const columns = screen.columns.main;
  const { formRef, formRefCallback } = formControl;
  const role = useContext(RoleContext);

  const interfaceOptions = network?.nics
    ? network?.nics?.map((i, index) => {
        return {
          label: i.name,
          active: index === activeIcIndex,
          index: index
        };
      })
    : [];

  const getInitialValues = (network?: INetwork) => {
    return network
      ? {
          hostname: network.hostname,
          autoDns: network?.autoDns,
          defaultInterface: network.defaultInterface || 'auto',
          'dnsServer-0': network.dnsserver0 || null,
          'dnsServer-1': network.dnsserver1 || null,
          'searchDomain-0': network.searchdomain0 || null,
          'searchDomain-1': network.searchdomain1 || null,
          'searchDomain-2': network.searchdomain2 || null,
          ntpAddress: network.ntpAddress || '',
          ipForward: network.ipForward,
          snmp: !!network.snmp,
          acceptRedirects: network?.acceptRedirects,
          dnsPrecedence: network?.dnsPrecedence,
          nics: network?.nics?.map(nic => {
            // Attach the user label to each nic
            return {
              ...nic,
              userLabel: nic.userLabel || '',
              // set default mtu, it can be un-configured on secondary NICs that don't have a config
              mtu: nic.mtu || 1500,
              // Split the single line IPv6 address into the two components of the form
              // Note these are temp values and get recombined in onSave
              ipv6AddressStatic:
                nic.ipv6Address?.static.split('/')[0] || nic.ipv6Address?.dhcp.split('/')[0],
              ipv6PrefixLength:
                nic.ipv6Address?.static.split('/')[1] || nic.ipv6Address?.dhcp.split('/')[1]
            };
          }),
          snmpTrapServers: network.snmpTrapServers,
          staticRoutes: network.staticRoutes,
          snmpRoCommunity: network.snmpRoCommunity
        }
      : {};
  };

  const initialValues = getInitialValues(network);

  useEffect(() => {
    if (!network) {
      getSystemNetwork().then((value: any) => {
        value && settingsDispatch({ type: EnumNetworkAction.SET, payload: value });
      });
    }
  }, [settingsDispatch, network]);

  useEffect(() => {
    setStaticRoutes(network?.staticRoutes);
  }, [network?.staticRoutes]);

  useEffect(() => {
    formRef?.current?.setFieldValue('staticRoutes', staticRoutes);
  }, [formRef, staticRoutes]);

  useEffect(() => {
    if (network?.nics?.length && activeIcIndex < 0) {
      setActiveIcIndex(0);
    }
  }, [activeIcIndex, network?.nics]);

  const reboot = () => {
    rebootSystem().then(() => {
      dispatch({
        type: EnumGlobalAction.SET_REBOOT_STATE,
        payload: EnumRebootState.START
      });
    });
  };

  const handleReboot = () => {
    dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        onConfirm: reboot,
        title: t('REBOOT.title', { type: t('reboot') }),
        desc: t('REBOOT.desc'),
        confirmText: t('reboot')
      }
    });
  };

  const onRebootClick = () => {
    if (unsavedChanges) {
      notifyChanges({ type: 'reboot', confirm: reboot });
    } else {
      handleReboot();
    }
  };

  const onApplyClick = () => {
    dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        onConfirm: () => submitHandler(formControl.formRef, notifyDispatch, () => reboot()),
        altConfirmText: t('rebootLater'),
        altConfirm: () => submitHandler(formControl.formRef, notifyDispatch),
        title: t('SETTINGS.NETWORK.applyMsg'),
        desc: t('SETTINGS.NETWORK.applyDesc'),
        confirmText: t('rebootNow')
      }
    });
  };

  const getButtons = (formContext: any) => {
    return (
      <>
        <Button variant='secondary' onClick={onRebootClick} size='regular'>
          {t('reboot')}
        </Button>
        <Button
          variant='secondary'
          disabled={!formContext.dirty}
          onClick={() => {
            formRef.current.setValues(formRef.current.initialValues);
            setStaticRoutes(formRef.current.initialValues.staticRoutes);
          }}
          size='regular'
        >
          {t('cancel')}
        </Button>
        <Button
          variant='primary'
          disabled={!formContext.dirty}
          onClick={onApplyClick}
          size='regular'
          state={btnState}
        >
          {t('apply')}
        </Button>
      </>
    );
  };

  const onSave = (formData: any, { resetForm }: any) => {
    const callback = formData.callback;
    const data = formData;
    delete data.callback;
    setBtnState('pending');
    const values = {
      ...data,
      defaultInterface: data.defaultInterface === 'auto' ? '' : data.defaultInterface,
      // Convert the split IP/Prefix to the single line needed for the API
      nics: data.nics.map((nic: any) => {
        if (typeof nic.ipv6PrivacyExtension === 'boolean') {
          nic.ipv6PrivacyExtension = nic.ipv6PrivacyExtension ? 2 : 0;
        }

        if (nic.ipv6Addressing === 'STATIC' && nic.ipv6AddressStatic && nic.ipv6PrefixLength) {
          nic.ipv6Address.static = nic.ipv6AddressStatic + '/' + nic.ipv6PrefixLength;
          delete nic.ipv6AddressStatic;
          delete nic.ipv6PrefixLength;
        }
        // cast DAD to a number
        nic.duplicateAddressDetection = nic.duplicateAddressDetection ? 1 : 0;
        return nic;
      })
    };

    Object.keys(values).map(i => {
      if (values[i as string] === '') {
        values[i as string] = null;
      }
    });

    updateSystemNetwork({ item: values })
      .then(() => {
        return getSystemNetwork();
      })
      .then((resp: INetwork) => {
        if (resp) {
          if (callback) {
            callback();
          } else {
            settingsDispatch({ type: EnumNetworkAction.SET, payload: resp });
            resetForm({
              values: getInitialValues(resp)
            });
          }
          notifyDispatch({
            type: EnumNotify.ADD_NOTIFICATION,
            payload: {
              type: 'Success',
              message: {
                name: 'SETTINGS.NETWORK.updated'
              }
            }
          });
        }
      })
      .finally(() => {
        setBtnState('idle');
      });
  };

  const formProps = {
    defaultValidation: true,
    handleSubmit: onSave,
    initialValues: initialValues,
    restValidationProps: {
      innerRef: formRefCallback,
      enableReinitialize: true,
      validationSchema: networkSchema
    }
  };

  const onOptionClicked = (opt: IButtonToggleOption) => {
    opt.label && setActiveIcIndex(opt.index);
  };

  const isSubmitting = btnState === 'pending';

  return (
    <div className='kraken-network'>
      {/* DO NOT render the form when initialValues is not finalized */}
      {network && (
        <>
          <Form {...formProps}>
            <FadeIn visible={!!network} suspenseComponent={<NetworkPlaceholder />}>
              <FormContext.Consumer>
                {(formContext: any) => {
                  return (
                    <>
                      <SettingsHeader
                        title={t('ROUTES.SETTINGS.network')}
                        buttons={getButtons(formContext)}
                      />
                      <FadeIn>
                        {/* GENERAL */}
                        <GeneralNetworkConfig
                          formRef={formRef}
                          columns={columns}
                          network={network}
                          isDisabled={isSubmitting || role.cannot('edit', 'network')}
                        />
                        {/* INTERFACES */}
                        {activeIcIndex >= 0 && (
                          <>
                            <FormSectionDark
                              title={t('SETTINGS.NETWORK.interfaces')}
                              rightComponent={
                                <ButtonToggleGroup
                                  className='ml-4'
                                  options={interfaceOptions}
                                  onOptionClicked={onOptionClicked}
                                  data-auto='interface_buttons'
                                  useFsMask={true}
                                ></ButtonToggleGroup>
                              }
                            >
                              <FormContainer columns={columns}>
                                <FormInput
                                  label={t('SETTINGS.NETWORK.userLabel')}
                                  name={`nics.${activeIcIndex}.userLabel`}
                                  disabled={isSubmitting || role.cannot('edit', 'network')}
                                  dataAuto='user_description'
                                />
                              </FormContainer>
                            </FormSectionDark>
                            <FormSectionDark>
                              <IpV4Config
                                activeIcIndex={activeIcIndex}
                                formRef={formRef}
                                columns={columns}
                                isDisabled={isSubmitting || role.cannot('edit', 'network')}
                              />
                            </FormSectionDark>
                            <FormSectionDark className='mb-4'>
                              <IpV6Config
                                activeIcIndex={activeIcIndex}
                                formRef={formRef}
                                columns={columns}
                                isDisabled={isSubmitting || role.cannot('edit', 'network')}
                              />
                            </FormSectionDark>
                            {/* STATIC ROUTES */}
                            {network.staticRoutes && (
                              <StaticRoutes
                                setStaticRoutes={setStaticRoutes}
                                staticRoutes={staticRoutes}
                                interfaces={network.nics}
                                isDisabled={isSubmitting || role.cannot('edit', 'network')}
                              />
                            )}
                          </>
                        )}
                      </FadeIn>
                    </>
                  );
                }}
              </FormContext.Consumer>
            </FadeIn>
          </Form>
        </>
      )}
    </div>
  );
};

const NetworkPlaceholder = () => {
  return (
    <div>
      <Placeholder.ListActionBar
        numToggleButtons={0}
        numDropdowns={0}
        numRightComponents={3}
        showCheckbox={false}
        showSearchInput={false}
      />
      <Placeholder as='layout' style={{ width: '100%', height: '450px', marginBottom: '16px' }} />
      <Placeholder as='layout' style={{ width: '100%', height: '350px', marginBottom: '16px' }} />
      <Placeholder as='layout' style={{ width: '100%', height: '150px', marginBottom: '16px' }} />
      <Placeholder as='layout' style={{ width: '100%', height: '250px', marginBottom: '16px' }} />
      <Placeholder as='layout' style={{ width: '100%', height: '350px', marginBottom: '16px' }} />
    </div>
  );
};

export default Network;
