import './updates.scss';

import { Button, DynamicContainer, Placeholder } from '@hai/ui-react';
import { FileUploadStages } from '@hai/ui-react/dist/components/FileUpload/FileUpload';
import { getSystemInfo } from 'api/settings/system';
import { ISystemInfo } from 'api/settings/system.type';
import {
  confirmSystemUpgrade,
  deleteSystemUpgrade,
  getSystemUpgrade,
  getSystemUpgradeUploadProgress,
  startSystemUpgrade,
  uploadSystemUpgrade
} from 'api/system/upgrade';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import KrakenUpload from 'components/common/krakenUpload/KrakenUpload';
import { useNotifyChanges } from 'components/common/notify/NotifyReboot';
import SettingsHeader from 'components/common/settingsHeader/SettingsHeader';
import { UpdateModal } from 'components/settings/updates/UpdateModal';
import { GlobalContext } from 'context/global';
import { PresetContext } from 'context/preset';
import { SecurityContext } from 'context/security/security';
import { EnumUpdatesAction, ISystemUpgrade } from 'context/security/updates';
import { SettingsContext } from 'context/settings/settings';
import { EnumStatusAction } from 'context/settings/status';
import { t } from 'i18n';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useLocalStorage from 'usehooks-ts/dist/esm/useLocalStorage/useLocalStorage';
import constant from 'utils/constant';

interface IUpgradeProgress {
  state: 'uploading' | 'error' | 'starting' | 'done';
  received: number;
  size: number;
}

const Updates = () => {
  const { notifyChanges } = useNotifyChanges();

  const { settingsDispatch, systemInfo } = useContext(SettingsContext);
  const { securityDispatch, upgrade } = useContext(SecurityContext);
  const { unsavedChanges } = useContext(PresetContext);

  const [isVerifying, setIsVerifying] = useState(false);
  const [progressId, setProgressId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');

  const [file, setFile] = useState<File>();

  const { screen } = useContext(GlobalContext);
  const columns = screen.columns.main;

  const [uploadState, setUploadState] = useState({
    stage: FileUploadStages.NONE,
    animationTime: 0.5,
    progress: 0
  });

  const [, setUpgradeBuild] = useLocalStorage(constant.updating?.build, upgrade?.build);
  const [, setUpgradeVersion] = useLocalStorage(constant.updating?.version, upgrade?.version);
  const [, setUpgradePrevBuild] = useLocalStorage(constant.updating?.prevBuild, systemInfo?.build);
  const [, setUpgradePrevVersion] = useLocalStorage(
    constant.updating?.prevVersion,
    systemInfo?.version
  );
  useEffect(() => {
    if (showModal) {
      setUpgradePrevBuild(systemInfo?.build);
      setUpgradePrevVersion(systemInfo?.version);
      if (upgrade?.build) {
        setUpgradeBuild(upgrade.build);
        setUpgradeVersion(upgrade.version);
      }
    } else {
      setUpgradeBuild('');
      setUpgradeVersion('');
    }
  }, [
    setUpgradeBuild,
    setUpgradeVersion,
    showModal,
    upgrade,
    systemInfo,
    setUpgradePrevBuild,
    setUpgradePrevVersion
  ]);

  const progress = uploadState.progress;
  const uploadComplete = progress === 100;
  const inProgress = progress < 100 && progress && !uploadComplete;
  const setProgress = (v: number, hasError?: boolean) => {
    let stage = v > 0 && v < 100 ? FileUploadStages.INPROGRESS : FileUploadStages.COMPLETED;
    if (v <= 0) {
      stage = FileUploadStages.NONE;
    }
    if (hasError) {
      stage = FileUploadStages.ERROR;
    }

    setUploadState((prev: any) => {
      return {
        ...prev,
        progress: v,
        stage: stage
      };
    });
  };

  const refreshSystemInfo = useCallback(
    () =>
      getSystemInfo().then((value: ISystemInfo) => {
        value && settingsDispatch({ type: EnumStatusAction.SET_SYSTEM_INFO, payload: value });
      }),
    [settingsDispatch]
  );

  const refreshSystemUpgrade = useCallback(
    () =>
      getSystemUpgrade().then((value: ISystemUpgrade) => {
        value && securityDispatch({ type: EnumUpdatesAction.SET_UPGRADE, payload: value });
        if (value.state === 'BUNDLE_READY') {
          setProgress(100);
        } else if (value.state === 'VERIFYING_BUNDLE') {
          setProgress(99);
          setIsVerifying(true);
        } else if (value.state === 'ERROR' || value.state === 'IDLE') {
          if (value.state === 'ERROR' && value.msg && progress) {
            setError(value.msg);
            setProgress(0, true);
          } else {
            setProgress(0);
          }
          setProgressId('');
        } else if (value.state === 'UPLOADING_BUNDLE' && value.id) {
          setProgressId(value.id);
          setError('');
        } else if (
          !!upgrade?.version ||
          value.state === 'UPGRADING' ||
          value.state === 'PREPARING_UPGRADE'
        ) {
          if (!showModal) {
            setShowModal(true);
          }
        }
      }),
    [progress, securityDispatch, showModal, upgrade?.version]
  );

  useEffect(() => {
    if (!systemInfo) {
      refreshSystemInfo();
    }
  }, [refreshSystemInfo, systemInfo]);

  useEffect(() => {
    if (progressId) {
      const progressInterval = setInterval(
        () =>
          getSystemUpgradeUploadProgress({ id: progressId }).then((value: IUpgradeProgress) => {
            if (value) {
              if (value.state === 'error') {
                setProgress(0, true);
                setProgressId('');
              }
              const receivedSize = Math.floor((value.received / value.size) * 100);
              refreshSystemUpgrade();
              if (value.state === 'uploading') {
                if (receivedSize === 100) {
                  setProgress(99);
                } else if (receivedSize >= 1) {
                  setProgress(receivedSize);
                } else if (value.received) {
                  setProgress(1);
                }
              }
            }
          }),
        constant.interval.upgradeStatus
      );
      if (progress === 100) {
        clearInterval(progressInterval);
      }
      return () => clearInterval(progressInterval);
    }
  }, [progress, progressId, refreshSystemUpgrade, upgrade?.state]);

  useEffect(() => {
    if (!upgrade) {
      refreshSystemUpgrade();
    }
  }, [refreshSystemUpgrade, upgrade]);

  useEffect(() => {
    if (upgrade?.state === 'BUNDLE_READY') {
      setProgress(100);
    }
  }, [upgrade]);

  useEffect(() => {
    if (error) {
      setFile(undefined);
    }
  }, [error]);

  const onUpload = () => {
    setProgress(1);
    startSystemUpgrade()
      .then((data: any) => {
        const id = data.id;
        if (id) {
          return id;
        } else {
          throw data;
        }
      })
      .then(id => {
        if (file) {
          uploadSystemUpgrade({ file: file, id: id });
        } else {
          throw new Error('uploadSystemUpgrade failed');
        }
      })
      .then(() => {
        refreshSystemUpgrade();
      })
      .catch(e => {
        const errMsg = e.response.data.error.type;
        if (errMsg && !error) {
          setError(errMsg);
        }
        setProgress(0, true);
        setProgressId('');
        setIsVerifying(false);
      });
  };

  const triggerUpdate = () => {
    setShowModal(true);
    confirmSystemUpgrade().then(() => {
      refreshSystemUpgrade();
    });
  };

  const onUpdate = () => {
    if (unsavedChanges) {
      notifyChanges({ type: 'update', confirm: triggerUpdate });
    } else {
      triggerUpdate();
    }
  };

  const onUploadCancel = () => {
    deleteSystemUpgrade().then(() => {
      refreshSystemUpgrade();
      setUploadState(prev => {
        return {
          ...prev,
          stage: FileUploadStages.NONE,
          progress: 0
        };
      });
    });
  };

  const successMessage =
    `${t('uploadSuccessful')} ${t('SETTINGS.UPDATES.version')}: ${upgrade?.version} ` +
    `${t('SETTINGS.UPDATES.build')}: ${upgrade?.build}`;

  const getHint = () => {
    if (uploadState.stage === FileUploadStages.ERROR) {
      return;
    }
    if (uploadComplete) {
      return t('readyToUpdate');
    }
    if (isVerifying) {
      return `${t('SETTINGS.UPDATES.verifying')}. ${t('SETTINGS.UPDATES.pleaseStay')}`;
    }
    if (progress) {
      return `${t('uploading')}. ${t('SETTINGS.UPDATES.pleaseStay')}`;
    }
  };

  const onFileChangeHandler = useCallback((files: File[]) => {
    setIsVerifying(false);
    files.map((file: any) => setFile(file));
  }, []);

  const getErrMsg = () => {
    if (error) {
      // TODO : user translate when not on a timeline
      return error;
    }
  };

  return (
    <div className='security-updates'>
      <FadeIn visible={!!upgrade && !!systemInfo} suspenseComponent={<UpgradePlaceholder />}>
        {showModal && <UpdateModal />}
        <SettingsHeader title={t('ROUTES.SETTINGS.updates')} />
        <FormSectionDark title={t('SETTINGS.UPDATES.current')} className={'current-section'}>
          <DynamicContainer maxColumns={columns} minColumns={3}>
            <FormInput
              dataAuto='version'
              label={t('SETTINGS.UPDATES.version')}
              defaultValue={systemInfo?.version}
              name='version'
              viewOnly
            />
            <FormInput
              dataAuto='build'
              label={t('SETTINGS.UPDATES.build')}
              defaultValue={systemInfo?.build}
              name='build'
              viewOnly
            />
          </DynamicContainer>
        </FormSectionDark>
        <FormSectionDark title={t('SETTINGS.UPDATES.update')} className={'update-section'}>
          {upgrade?.state && (
            <>
              <KrakenUpload
                files={file ? [file] : []}
                onFileChange={onFileChangeHandler}
                uploadState={uploadState}
                accept={{ '': ['.hai'] }}
                uploadErrorMessage={getErrMsg()}
                successMessage={successMessage}
                mainMessage={uploadComplete ? successMessage : undefined}
                browseMessage={uploadComplete ? t('SETTINGS.UPDATES.newUpload') : undefined}
                conjunction={uploadComplete ? '' : undefined}
                hint={getHint()}
              />
              <div className='update-buttons'>
                <Button
                  onClick={onUploadCancel}
                  disabled={!inProgress && !uploadComplete}
                  size={'small'}
                >
                  {'cancel'}
                </Button>
                <Button
                  onClick={uploadComplete ? onUpdate : onUpload}
                  disabled={(!file || !!inProgress) && !uploadComplete}
                  size={'small'}
                  variant='primary'
                >
                  {uploadComplete ? t('update') : t('upload')}
                </Button>
              </div>
            </>
          )}
        </FormSectionDark>
      </FadeIn>
    </div>
  );
};

const UpgradePlaceholder = () => {
  return (
    <>
      <Placeholder
        as='layout'
        style={{ margin: '100px 0 60px 0', width: '100%', height: '200px' }}
      />
      <Placeholder as='layout' style={{ margin: '0 0 60px 0', width: '100%', height: '300px' }} />
    </>
  );
};

export default Updates;
