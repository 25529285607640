import './certificates.scss';

import { Button, DataTable, Icon, Placeholder } from '@hai/ui-react';
import { HaiDataTableColumnType } from '@hai/ui-react/dist/components/DataTable/IHaiDataTable';
import {
  deleteCertificate,
  getCertificates,
  setDefaultCertificate
} from 'api/security/certificates';
import { rebootSystem } from 'api/settings/system';
import config from 'api/utils/config';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { FormInfo } from 'components/common/form/formInfo/formInfo';
import { useNotifyChanges } from 'components/common/notify/NotifyReboot';
import SettingsHeader from 'components/common/settingsHeader/SettingsHeader';
import { GenerateCertificate } from 'components/security/certificates/GenerateCertificate';
import { ImportCertificate } from 'components/security/certificates/ImportCertificate';
import { EnumGlobalAction, EnumRebootState, GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { PresetContext } from 'context/preset';
import {
  EnumCertificateType,
  EnumCertificatesAction,
  ICertificate
} from 'context/security/certificates';
import { SecurityContext } from 'context/security/security';
import { t } from 'i18n';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { openNewWindow } from 'utils/global';

const Certificates = () => {
  const [showGenerate, setShowGenerate] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [initializing, setInitializing] = useState(false);
  const { dispatch } = useContext(GlobalContext);
  const { securityDispatch, certificates } = useContext(SecurityContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const dataFetchedRef = useRef(false);
  const { notifyChanges } = useNotifyChanges();

  const { unsavedChanges } = useContext(PresetContext);

  const refreshCertificates = useCallback(
    (callback?: () => void) => {
      getCertificates()
        .then((value: ICertificate[]) => {
          value &&
            securityDispatch({ type: EnumCertificatesAction.SET_CERTIFICATES, payload: value });
          callback && callback();
        })
        .finally(() => {
          setInitializing(false);
        });
    },
    [securityDispatch]
  );

  useEffect(() => {
    if (!certificates && !dataFetchedRef.current) {
      setInitializing(true);
      dataFetchedRef.current = true;
      refreshCertificates();
    }
  }, [certificates, refreshCertificates, securityDispatch]);

  const reboot = () => {
    rebootSystem().then(() => {
      dispatch({
        type: EnumGlobalAction.SET_REBOOT_STATE,
        payload: EnumRebootState.START
      });
    });
  };

  const handleReboot = () => {
    dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        onConfirm: reboot,
        title: t('REBOOT.title', { type: t('reboot') }),
        desc: t('REBOOT.desc'),
        confirmText: t('reboot')
      }
    });
  };

  const onRebootClick = () => {
    if (unsavedChanges) {
      notifyChanges({ type: 'reboot', confirm: reboot });
    } else {
      handleReboot();
    }
  };

  const button = () => {
    return (
      <Button variant='primary' onClick={onRebootClick} size='regular'>
        {t('reboot')}
      </Button>
    );
  };

  const handleView = (eventKey: string, data: ICertificate) => {
    openNewWindow({
      url: `${config.ApiUrl}${config.ApiPath}system/certificates/files/${data.name}`
    });
  };

  const setAsDefault = (v: ICertificate) => {
    setDefaultCertificate({ item: v }).then(() => {
      refreshCertificates(() =>
        notifyDispatch({
          type: EnumNotify.ADD_NOTIFICATION,
          payload: {
            type: 'Success',
            message:
              v.type === EnumCertificateType.CA_CHAIN
                ? t('SECURITY.CERTIFICATES.MESSAGES.setToDefaultBundle', { name: v.name })
                : t('SECURITY.CERTIFICATES.MESSAGES.setToDefault', { name: v.name })
          }
        })
      );
    });
  };

  const onCheckAll = (NextCheckAllState: boolean | 'indeterminate') => {
    securityDispatch({
      type: EnumCertificatesAction.SELECT_ALL,
      payload: NextCheckAllState === true
    });
  };

  const onSelect = (selected: boolean, item: ICertificate): void => {
    securityDispatch({
      type: EnumCertificatesAction.SELECT_CERTIFICATE,
      payload: {
        id: item.id,
        selected: selected
      }
    });
  };

  const selected = certificates ? certificates.filter(i => i.selected) : [];

  const deleteItem = async (item: ICertificate) => {
    if (item.type === EnumCertificateType.CRT && item.isDefault) {
      return null;
    }
    const response = await deleteCertificate({ item: item }).then(res => {
      onCheckAll(false);
      securityDispatch({
        type: EnumCertificatesAction.DELETE_CERTIFICATE,
        payload: {
          id: item.id
        }
      });
      return res;
    });

    return response;
  };

  const actionDelete = (itemDelete?: ICertificate) => {
    const triggerDelete = async (item: ICertificate) => {
      const result = await deleteItem(item);
      return { result, item: item };
    };
    const itemsToDeleted = itemDelete ? [itemDelete] : selected;
    (async () => {
      if (itemsToDeleted.length) {
        onCheckAll(false);
        await Promise.all(itemsToDeleted.map(triggerDelete)).then(values => {
          const successItems = values.filter(x => x.result);
          if (successItems.length) {
            notifyDispatch({
              type: EnumNotify.ADD_NOTIFICATION,
              groupId: 'deleteCertificates',
              payload: {
                type: 'Success',
                message: t('SECURITY.CERTIFICATES.MESSAGES.deleted', {
                  name: itemsToDeleted[0].name,
                  count: successItems.length
                })
              }
            });
          }
          refreshCertificates();
        });
      }
    })();
    return true;
  };

  const onDeleteClick = (item?: ICertificate) => {
    dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        onConfirm: () => actionDelete(item),
        title: t('SECURITY.CERTIFICATES.MODALS.deleteTitle', {
          count: item ? 1 : selected.filter(sel => !sel.isDefault).length
        }),
        desc: t('SECURITY.CERTIFICATES.MODALS.deleteDesc', {
          count: item ? 1 : selected.filter(sel => !sel.isDefault).length
        })
      }
    });
  };

  const isDeleteDisabled = () => {
    if (
      selected.length === 1 &&
      selected[0].isDefault &&
      selected[0].type === EnumCertificateType.CRT
    ) {
      return true;
    } else {
      return false;
    }
  };

  const columnStructure = [
    { fieldKey: 'name', title: t('SECURITY.CERTIFICATES.name') },
    { fieldKey: 'message', title: t('SECURITY.CERTIFICATES.message') },
    { fieldKey: 'keySize', title: t('SECURITY.CERTIFICATES.keySize') },
    { fieldKey: 'digest', title: t('SECURITY.CERTIFICATES.digest') },
    { fieldKey: 'type', title: t('SECURITY.CERTIFICATES.type') },
    {
      fieldKey: 'default',
      title: t('SECURITY.CERTIFICATES.default'),
      type: HaiDataTableColumnType.SWITCH
    },
    { fieldKey: '', title: '', type: HaiDataTableColumnType.ACTIONS }
  ];

  return (
    <div className='security-certificates'>
      <SettingsHeader title={t('ROUTES.SECURITY.certificates')} buttons={button()} />
      <FadeIn
        visible={!!certificates?.length || !initializing}
        suspenseComponent={
          <Placeholder.Table numRows={6} columnStructure={columnStructure} showTitlebar />
        }
      >
        <FormInfo info={t('SECURITY.CERTIFICATES.warning')} />
        <div className='buttons'>
          <Button size='small' onClick={() => setShowGenerate(true)}>
            {t('SECURITY.CERTIFICATES.generate')}
          </Button>
          <Button size='small' onClick={() => setShowImport(true)}>
            {t('SECURITY.CERTIFICATES.import')}
          </Button>
        </div>
        {certificates?.length && (
          <DataTable
            className='content'
            selectable={true}
            sortable={true}
            columnStructure={columnStructure}
            onCheckAll={onCheckAll}
            useFsMask={true}
          >
            <DataTable.Header
              bulkActions={() =>
                selected.length && (
                  <Button
                    size='small'
                    onClick={() => onDeleteClick()}
                    disabled={isDeleteDisabled()}
                  >
                    {t('delete')}
                  </Button>
                )
              }
            />
            {certificates.map((value: ICertificate, index: number) => {
              let expire = [];
              if (value.expiringDate) {
                const expireTimestamp = new Date(value.expiringDate).getTime();
                const timestamp = new Date().getTime();
                const days = (expireTimestamp - timestamp) / (1000 * 3600 * 24);
                if (days < 0) {
                  expire.push(
                    <Icon
                      key='StatusExpired'
                      iconname='StatusExpired'
                      size='sm3'
                      className='mr-2 cert-icon'
                      color='haiui-red-01'
                    />
                  );
                } else if (days < 30) {
                  expire.push(
                    <Icon
                      key='StatusWarning'
                      iconname='StatusWarning'
                      className='mr-2 cert-icon'
                      size='sm3'
                      color='haiui-amber-01'
                    />
                  );
                } else if (days > 30) {
                  expire.push(
                    <Icon
                      key='StatusOK'
                      iconname='StatusOK'
                      size='sm3'
                      className='mr-2 cert-icon'
                      color='haiui-green-01'
                    />
                  );
                }
                expire.push(' ');
              }
              if (value.expired) {
                expire.push(t('SECURITY.CERTIFICATES.expired'));
              } else if (
                !value.matchesCurrentPrivateKey &&
                value?.type !== EnumCertificateType.CA_CHAIN
              ) {
                expire.push(
                  t('SECURITY.CERTIFICATES.notMatchPrivateKey', {
                    days: value.expiringDays
                  })
                );
              } else if (value.expiringDays > 0) {
                expire.push(t('SECURITY.CERTIFICATES.expiring', { date: value.expiringDate }));
              }

              const cellComponents = () => ({
                message: <Icon iconname='Copy' />
              });

              const isDisabled =
                value.isDefault ||
                value?.type === EnumCertificateType.CSR_RESPONSE ||
                (!value.matchesCurrentPrivateKey && value?.type !== EnumCertificateType.CA_CHAIN);

              return (
                <DataTable.Row
                  key={`${value}-${index}`}
                  rowData={{
                    index: index,
                    id: `${value.name}-${index}`,
                    name: value.name,
                    keySize: value.keySize,
                    digest: value.digest,
                    type: value?.type,
                    message: value.expiringDate !== -1 ? expire : '',
                    default: isDisabled
                      ? {
                          checked: value.isDefault,
                          disabled: true
                        }
                      : value.isDefault
                  }}
                  checked={!!value.selected}
                  onSelect={selected => onSelect(selected, value)}
                  onSwitchChange={() => (value.isDefault ? null : setAsDefault(value))}
                  cellComponents={cellComponents()}
                  actionItems={[
                    {
                      actionIcon: 'GoTo',
                      title: t('view'),
                      eventKey: 'view',
                      onSelect: handleView
                    },
                    {
                      actionIcon: 'TrashCan',
                      title: t('delete'),
                      eventKey: 'delete',
                      onSelect: () => null,
                      disabled: value.type === EnumCertificateType.CRT && value.isDefault,
                      inlineAction: {
                        confirmIcon: 'TrashCan',
                        confirmationMessage: t('SECURITY.CERTIFICATES.MODALS.deleteTitle', {
                          count: 1
                        }),
                        onConfirm: () => actionDelete(value)
                      }
                    }
                  ]}
                  className={`ca-datatable-row`}
                ></DataTable.Row>
              );
            })}
          </DataTable>
        )}
        {showGenerate && (
          <GenerateCertificate show={showGenerate} handleClose={() => setShowGenerate(false)} />
        )}
        {showImport && (
          <ImportCertificate show={showImport} handleClose={() => setShowImport(false)} />
        )}
      </FadeIn>
    </div>
  );
};

export default Certificates;
