export const dashboardEn = {
  activeBypassSessions: 'Active Bypass',
  activeTranscoderSessions: 'Active Transcoder',
  bypass: 'Bypass',
  capacity: 'Capacity',
  cpu: 'CPU',
  detailedStatus: 'Detailed Statistics',
  deviceStatus: 'DEVICE STATUS',
  load: 'Stream Load',
  memory: 'Memory',
  overallInput: 'Input',
  overallOutput: 'Output',
  overallBandwidth: 'Overall Bandwidth',
  processor: 'Processor',
  resetStatistics: 'Reset Statistics',
  resources: 'Resources',
  streams: 'Streams',
  uptime: 'Uptime',
  version: 'Version',
  BOTTOM_ROW: {
    input: 'Input Rate',
    inputAbr: 'IN',
    latency: 'Latency',
    latencyAbr: 'LT',
    overallRate: 'Overall Rate',
    overallRateAbr: 'OR',
    streamRate: 'Stream Rate',
    streamRateAbr: 'ST'
  }
};
