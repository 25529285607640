import { EnumStreamStatus, EnumStreamSubStatus } from 'api/streaming/streaming.type';
import { ITranscoder } from 'api/transcoder/transcoder.type';
import { StreamingContext } from 'context/streaming/streaming';
import { useCallback, useContext } from 'react';
import { actionStatus, getActionStatusColor } from 'utils/streaming';

export const useTranscoderInfo = () => {
  const { streamsClean } = useContext(StreamingContext);

  const getTranscoderInfo = useCallback(
    (i: ITranscoder) => {
      const getInfo = (
        item: ITranscoder
      ): {
        icon: string;
        actionType: string;
        iconColor?: string;
      } => {
        const stateCode = item.state;

        const transcoderStreams =
          streamsClean?.filter(s => s.transcoder?.includes(item.uuid)) || [];

        const transcoderStatus = transcoderStreams.map(i => {
          return {
            status: i.status,
            subStatus: i.subStatus,
            uuid: i.uuid
          };
        });

        const isOnline =
          stateCode === 2 ||
          (transcoderStatus.some(i => i.status !== EnumStreamStatus.STOPPED) && stateCode === 5);

        // The only alerts here are transcoder errors
        if (
          transcoderStatus.findIndex(
            i =>
              i.status === EnumStreamStatus.WARNING &&
              i.subStatus === EnumStreamSubStatus.TRANSCODER_ERROR
          ) !== -1
        ) {
          return {
            icon: 'StatusWarning',
            actionType: actionStatus.alert
          };
        }

        // fallback
        const fallbackInfo = {
          icon: 'StatusInactive',
          actionType: actionStatus.inactive
        };
        // ACTION STATUS: ONLINE
        if (isOnline) {
          return {
            icon: 'StatusTranscoding',
            actionType: actionStatus.active,
            iconColor: getActionStatusColor(actionStatus.active)
          };
          // ACTION STATUS: INACTIVE - Offline
        } else {
          return fallbackInfo;
        }
      };
      return getInfo(i);
    },
    [streamsClean]
  );

  return getTranscoderInfo;
};
