import { AutoLogout } from 'components/common/autoLogout/AutoLogout';
import React from 'react';
import { Navigate } from 'react-router-dom';

export type IProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  outlet: JSX.Element;
};

const ProtectedRoute = ({ isAuthenticated, authenticationPath, outlet }: IProtectedRouteProps) => {
  if (isAuthenticated) {
    return <AutoLogout>{outlet}</AutoLogout>;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
};
export default ProtectedRoute;
