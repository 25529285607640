import { t } from 'i18n';
import { generalValidation, isIpV4, isIpV6, isSubMask, isDomain } from 'utils/validations';
import * as Yup from 'yup';

export const networkSchema = Yup.object({
  'dnsServer-0': generalValidation.dnsServer(
    t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.dnsServer0') })
  ),
  'dnsServer-1': generalValidation.dnsServer(
    t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.dnsServer1') })
  ),
  hostname: generalValidation.hostname(
    t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.hostname') })
  ),
  // Note API calls it 'address', but UI uses 'server'
  ntpAddress: generalValidation.ntpServer(
    t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.ntpServer') })
  ),
  nics: Yup.array().of(
    Yup.object().shape({
      // ipv4
      ipv4: Yup.boolean(),

      ipAddress: Yup.string().when(['addressing', 'ipv4'], {
        is: (addressing: string, ipv4: boolean) => addressing === 'STATIC' && ipv4,
        then: generalValidation
          .required(t('SETTINGS.NETWORK.ipAddress'))
          .nullable()
          .test(
            'ipv4',
            t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.ipAddress') }),
            value => {
              return isIpV4(value);
            }
          ),
        otherwise: Yup.string().nullable()
      }),
      subnetMask: Yup.string().when(['addressing', 'ipv4'], {
        is: (addressing: string, ipv4: boolean) => addressing === 'STATIC' && ipv4,
        then: generalValidation
          .required(t('SETTINGS.NETWORK.subnetMask'))
          .nullable()
          .test(
            'subnetMask',
            t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.subnetMask') }),
            value => {
              return isSubMask(value);
            }
          ),
        otherwise: Yup.string().nullable()
      }),
      userLabel: Yup.string().max(50, t('VALIDATION.stringAtMost', { max: 50 }) as string),
      mtu: generalValidation.number
        .nullable()
        .min(124, t('VALIDATION.between', { x: 124, y: 10000 }) as string)
        .max(10000, t('VALIDATION.between', { x: 124, y: 10000 }) as string),
      gateway: Yup.string().when(['addressing', 'ipv4'], {
        is: (addressing: string, ipv4: boolean) => addressing === 'STATIC' && ipv4,
        then: Yup.string()
          .nullable()
          .test(
            'gateway',
            t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.gateway') }),
            value => {
              return isIpV4(value, false);
            }
          ),
        otherwise: Yup.string().nullable()
      }),
      // ipv6
      ipv6: Yup.boolean(),
      ipv6Addressing: Yup.string(),
      ipv6AddressStatic: Yup.string().when(['ipv6Addressing', 'ipv6'], {
        is: (ipv6Addressing: string, ipv6: boolean) => ipv6Addressing === 'STATIC' && ipv6,
        then: Yup.string().test(
          'ipv6Addressing',
          t<string>('SETTINGS.NETWORK.ipv6Error'),
          value => {
            return isIpV6(value);
          }
        ),
        otherwise: Yup.string().nullable()
      }),

      ipv6PrefixLength: Yup.number().when(['ipv6Addressing', 'ipv6'], {
        is: (ipv6Addressing: string, ipv6: boolean) => ipv6Addressing === 'STATIC' && ipv6,
        then: Yup.number()
          .min(1, <string>t('SETTINGS.NETWORK.ipv6PrefixError'))
          .max(128, <string>t('SETTINGS.NETWORK.ipv6PrefixError'))
          .typeError(<string>t('SETTINGS.NETWORK.ipv6PrefixError')),
        otherwise: Yup.number().nullable()
      }),

      ipv6Address: Yup.object().shape({
        dhcp: Yup.string(),
        linklocal: Yup.string(),
        mngtmpaddr: Yup.string(),
        static: Yup.string(),
        temporary: Yup.string()
      })
    })
  ),
  'searchDomain-0': Yup.string()
    .test(
      'searchDomain-0',
      t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.searchDomain0') }),
      (value: any) => {
        return value === undefined || value === null || value === '' || isDomain(value);
      }
    )
    .nullable(),
  'searchDomain-1': Yup.string()
    .test(
      'searchDomain-1',
      t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.searchDomain1') }),
      (value: any) => {
        return value === undefined || value === null || value === '' || isDomain(value);
      }
    )
    .nullable(),
  'searchDomain-2': Yup.string()
    .test(
      'searchDomain-2',
      t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.searchDomain2') }),
      (value: any) => {
        return value === undefined || value === null || value === '' || isDomain(value);
      }
    )
    .nullable(),
  // SNMP
  snmp: Yup.boolean(),
  snmpTrapServers: Yup.array().of(
    Yup.object().shape({
      server: generalValidation.required(t('SETTINGS.NETWORK.snmpTrapServer')),
      community: generalValidation.required(t('SETTINGS.NETWORK.snmpTrapServerCommunity'))
    })
  )
});

export const staticRouteSchema = Yup.object({
  protocol: Yup.number(),
  destination: Yup.string().when(['protocol'], {
    is: (protocol: number) => protocol === 4,
    then: generalValidation
      .required(t('SETTINGS.NETWORK.destination'))
      .test(
        'ipv4',
        t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.destination') }),
        value => {
          return isIpV4(value);
        }
      ),
    otherwise: generalValidation
      .required(t('SETTINGS.NETWORK.destination'))
      .test(
        'ipv6',
        t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.destination') }),
        value => {
          return isIpV6(value);
        }
      )
  }),
  subnetMask: Yup.string().when(['protocol'], {
    is: (protocol: number) => protocol === 4,
    then: generalValidation
      .required(t('SETTINGS.NETWORK.subnetMask'))
      .test(
        'subnetMask',
        t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.subnetMask') }),
        value => {
          return isSubMask(value);
        }
      ),
    otherwise: Yup.string().nullable()
  }),
  gateway: Yup.string().when(['protocol'], {
    is: (protocol: number) => protocol === 4,
    then: Yup.string().test(
      'gateway',
      t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.gateway') }),
      value => {
        return isIpV4(value, false);
      }
    ),
    otherwise: Yup.string().test(
      'gateway',
      t<string>('VALIDATION.invalid', { item: t('SETTINGS.NETWORK.gateway') }),
      value => {
        return isIpV6(value, false);
      }
    )
  }),
  prefixLength: generalValidation.number
});
