import { useEffect, useState } from 'react';

export const useMouseClick = () => {
  const [click, setClick] = useState({ x: 0, y: 0 });
  useEffect(() => {
    const getPosition = (e: MouseEvent) => setClick({ x: e.clientX, y: e.clientY });
    window.addEventListener('click', getPosition);
    return () => window.removeEventListener('click', getPosition);
  });

  return click;
};
