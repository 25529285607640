import { List, colorValue } from '@hai/ui-react';
import { IArchive } from 'api/settings/archiveLocations';
import ArchiveItem from 'components/settings/archive/ArchiveItem';
import React from 'react';

interface IProps {
  list: IArchive[];
}

const ArchiveList = (props: IProps) => {
  const { list } = props;
  return (
    <List accentColor={colorValue('haiui-aqua-01')}>
      {list.map((item: IArchive) => {
        return <ArchiveItem key={item.uuid} item={item} />;
      })}
    </List>
  );
};

export default ArchiveList;
