import { Form } from '@hai/ui-react';
import { FormContainer } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { t } from 'i18next';
import React from 'react';
import { IFormRef } from 'utils/hooks/useFormRef';
import { isHostname, isIpV4, isIpV6 } from 'utils/validations';
import * as Yup from 'yup';

interface IProps {
  columns?: number;
  item?: any;
  formControl: IFormRef;
}

const cotDefaults = { ipAddress: '', port: '', tos: '0', ttl: '64' };

export const AddCot = (props: IProps) => {
  const { columns = 2, formControl, item = cotDefaults } = props;
  const { formRefCallback } = formControl;

  const validationSchema = Yup.object({
    ipAddress: Yup.string()
      .required(t('STREAMING.REQUIRED.required', { item: 'IP address' }) as string)
      .test(srtAddress => {
        if (
          isIpV4(srtAddress as string) ||
          isIpV6(srtAddress as string) ||
          isHostname(srtAddress as string)
        ) {
          return true;
        }
        return false;
      }),
    port: Yup.number()
      .required(t('STREAMING.REQUIRED.required', { item: 'port' }) as string)
      .typeError(t('VALIDATION.between', { x: 1025, y: 65535 }) as string)
      .min(1025, t('VALIDATION.between', { x: 1025, y: 65535 }) as string)
      .max(65535, t('VALIDATION.between', { x: 1025, y: 65535 }) as string),

    ttl: Yup.number()
      .typeError(t('VALIDATION.between', { x: 1, y: 255 }) as string)
      .min(1, t('VALIDATION.between', { x: 1, y: 255 }) as string)
      .max(255, t('VALIDATION.between', { x: 1, y: 255 }) as string),
    tos: Yup.number()
      .typeError(t('STREAMING.METADATA.COT.tosOutOfRange') as string)
      .min(0, t('STREAMING.METADATA.COT.tosOutOfRange') as string)
      .max(255, t('STREAMING.METADATA.COT.tosOutOfRange') as string)
  });

  const formProps = {
    defaultValidation: true,
    initialValues: item,

    restValidationProps: {
      validationSchema,
      innerRef: formRefCallback
    }
  };

  return (
    <Form {...formProps}>
      <FormContainer columns={columns}>
        <FormInput
          dataAuto='ip_address'
          item={item}
          label={t('STREAMING.METADATA.COT.ipaddress')}
          name='ipAddress'
          autoFocus
          required
        />
        <FormInput
          dataAuto='port'
          item={item}
          label={t('STREAMING.METADATA.COT.port')}
          name='port'
          required
        />
        <FormInput
          dataAuto='ttl'
          placeholder='(1-255)'
          label={t('STREAMING.METADATA.COT.ttl')}
          name='ttl'
          item={item}
        />
        <FormInput
          dataAuto='tos'
          placeholder='(0x00 - 0xFF)'
          label={t('STREAMING.METADATA.COT.tos')}
          name='tos'
          item={item}
        />
      </FormContainer>
    </Form>
  );
};
