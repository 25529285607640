import { Form } from '@hai/ui-react';
import { FormContainer } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { t } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { IFormRef } from 'utils/hooks/useFormRef';

interface IProps {
  onSave: (subject: string) => void;
  formControl: IFormRef;
  subjectString: string;
}

interface ISubject {
  country: string;
  state: string;
  location: string;
  organization: string;
  unit: string;
  name: string;
}

export const EditSubject = (props: IProps) => {
  const { onSave, formControl, subjectString } = props;

  const { formRefCallback, formRef } = formControl;

  const regexExtract = (subject: string, regexp: any) => {
    var retVal = subject.match(regexp);
    if (retVal === null) {
      return '';
    }
    return retVal.length > 1 ? retVal[1] : retVal[0];
  };
  const country = regexExtract(subjectString, /\/C=([^\\/]*)/);
  const state = regexExtract(subjectString, /\/ST=([^\\/]*)/);
  const location = regexExtract(subjectString, /\/L=([^\\/]*)/);
  const organization = regexExtract(subjectString, /\/O=([^\\/]*)/);
  const organizationalUnit = regexExtract(subjectString, /\/OU=([^\\/]*)/);
  const commonName = regexExtract(subjectString, /\/CN=(((?!\/[A-Z]{1,2}=).)*)/);

  const data: ISubject = useMemo(() => {
    return {
      country: country,
      state: state,
      location: location,
      organization: organization,
      unit: organizationalUnit,
      name: commonName
    };
  }, [commonName, country, location, organization, organizationalUnit, state]);

  useEffect(() => {
    formRef.current?.setValues(data, false);
  }, [formRef, data]);

  const onSubmit = (values: any) => {
    const subjectValues: ISubject = values;
    let subject = '';
    subjectValues.country && (subject += '/C=' + subjectValues.country);
    subjectValues.state && (subject += '/ST=' + subjectValues.state);
    subjectValues.location && (subject += '/L=' + subjectValues.location);
    subjectValues.organization && (subject += '/O=' + subjectValues.organization);
    subjectValues.unit && (subject += '/OU=' + subjectValues.unit);
    subjectValues.name && (subject += '/CN=' + subjectValues.name);
    onSave(subject);
  };

  const formProps = {
    defaultValidation: true,
    handleSubmit: onSubmit,
    initialValues: data,
    restValidationProps: {
      innerRef: formRefCallback
    }
  };

  return (
    <Form
      {...formProps}
      onSubmit={e => {
        e.preventDefault();
      }}
      className={'generate-cert-form'}
    >
      <FormContainer columns={1} dataAuto='edit_subject'>
        <FormInput
          label={t('SECURITY.CERTIFICATES.FORMS.country')}
          name='country'
          defaultValue={''}
          dataAuto='country'
        />
        <FormInput
          label={t('SECURITY.CERTIFICATES.FORMS.state')}
          name='state'
          defaultValue={''}
          dataAuto='state'
        />
        <FormInput
          label={t('SECURITY.CERTIFICATES.FORMS.location')}
          name='location'
          defaultValue={''}
          dataAuto='location'
        />
        <FormInput
          label={t('SECURITY.CERTIFICATES.FORMS.organization')}
          name='organization'
          defaultValue={''}
          dataAuto='organization'
        />
        <FormInput
          label={t('SECURITY.CERTIFICATES.FORMS.unit')}
          name='unit'
          defaultValue={''}
          dataAuto='org_unit'
        />
        <FormInput
          label={t('SECURITY.CERTIFICATES.FORMS.commonName')}
          name='name'
          defaultValue={''}
          dataAuto='common_name'
        />
      </FormContainer>
    </Form>
  );
};
