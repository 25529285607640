import { IMetadataItem } from 'api/metadata/metadata.type';
import { IStreamingState } from 'context/streaming/streaming';

export interface IMetadatasState {
  metadatas: IMetadataItem[] | null;
  metadatasClean: IMetadataItem[] | null;
}

export enum EnumMetadatasAction {
  SET_METADATAS = 'SET_METADATAS',
  SET_METADATAS_CLEAN = 'SET_METADATAS_CLEAN',
  SELECT_METADATA = 'SELECT_METADATA',
  RESET = 'RESET'
}

export interface IMetadatasAction {
  type: EnumMetadatasAction;
  payload: any;
}

// Init states
export const initMetadatasState: IMetadatasState = {
  metadatas: null,
  metadatasClean: null
};

// Reducer
export const metadatasReducer = (
  state: IStreamingState,
  action: IMetadatasAction
): IMetadatasState => {
  switch (action.type) {
    case EnumMetadatasAction.SET_METADATAS_CLEAN: {
      const newMetadatas = action.payload?.map((archive: IMetadataItem) => {
        const selected = state.metadatasClean?.find(i => i.uuid === archive.uuid)?.selected;
        const newItem = archive;
        newItem.selected = selected;
        return newItem;
      });
      return {
        ...state,
        metadatas: newMetadatas,
        metadatasClean: newMetadatas || []
      };
    }
    case EnumMetadatasAction.SET_METADATAS: {
      return {
        ...state,
        metadatas: action.payload || []
      };
    }
    case EnumMetadatasAction.SELECT_METADATA: {
      const { id, selected } = action.payload;
      const newMetadatas = state.metadatas?.map((i: IMetadataItem) =>
        i.uuid === id
          ? {
              ...i,
              selected
            }
          : i
      );
      return {
        ...state,
        metadatas: newMetadatas || []
      };
    }
    case EnumMetadatasAction.RESET: {
      return { ...state, ...initMetadatasState };
    }
    default:
      return state;
  }
};
