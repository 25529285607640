import './formSelect.scss';

import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Icon,
  LabelValue,
  SelectOption,
  Typeahead,
  colorValue
} from '@hai/ui-react';
import { isLicensed } from 'context/settings/licenses';
import { t } from 'i18next';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

export const buildFormSelect = (props: ISelectProps) => {
  const { dataAuto = '', required, isHidden = '', helpMessage = '', label, ...rest } = props;
  const dataAutoGroup = 'group_' + dataAuto;

  return (
    <FormGroup data-auto={dataAutoGroup} {...isHidden}>
      {label && (
        <FormLabel
          data-auto={'title'}
          helpMessage={helpMessage}
          required={!props.viewOnly && required}
        >
          {label}
        </FormLabel>
      )}
      {buildSelect(rest)}
    </FormGroup>
  );
};

export const BuildFormSelect = buildFormSelect;

interface ISelectProps {
  btnLabel?: string;
  className?: string;
  defaultValue?: any;
  disabled?: boolean;
  idKey?: string;
  helpMessage?: string;
  includeNone?: boolean;
  dataAuto?: string;
  isHidden?: any;
  items: any;
  key?: string;
  label?: string;
  formRef?: any;
  nameProp?: string;
  onChange?: Function;
  required?: boolean;
  selectedId?: string | number;
  selectedIds?: (string | null | undefined)[];
  selectName: any;
  setSelectedIds?: any;
  typeahead?: boolean;
  typeaheadDirection?: 'down' | 'up';
  viewOnly?: boolean;
}

const buildSelect = (props: ISelectProps) => {
  const {
    className = '',
    defaultValue,
    disabled,
    idKey = 'value',
    includeNone = false,
    items,
    key,
    formRef,
    nameProp = 'option',
    onChange,
    selectedId,
    selectName,
    typeahead = false,
    typeaheadDirection = 'down',
    viewOnly
  } = props;
  let itemlist = [...items];
  if (includeNone) {
    if (itemlist.findIndex((i: any) => i[idKey] === '') !== -1) {
      itemlist.splice(
        itemlist.findIndex((i: any) => i[idKey] === ''),
        1
      );
    }
    itemlist.unshift({
      [idKey]: '',
      [nameProp]: t('none')
    });
  }

  const changeEvent = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
  };

  const typeaheadChange = (e: any) => {
    formRef.current.setFieldValue(selectName, e.target.value);
    onChange?.(e);
  };

  itemlist = itemlist
    .filter((i: any) => i[idKey] !== undefined)
    .filter((i: any) => (i.licensedFeature ? isLicensed(i) : true));

  const selectedIndex: number = selectDefault({ items: itemlist, selectedId, idKey });
  return viewOnly || (itemlist.length === 1 && !includeNone) ? (
    <div className='mb-4 form-input-viewonly'>
      <LabelValue showCopy={false} useFsMask={true}>
        {itemlist[selectedIndex]?.[nameProp] ||
          items.find((i: any) => i[idKey] === selectedId)?.[nameProp]}
      </LabelValue>
    </div>
  ) : disabled || !typeahead ? (
    <FormControl
      className={className}
      defaultValue={defaultValue || selectedId}
      disabled={disabled}
      as='select'
      key={key}
      name={selectName}
      useFsMask={true}
      onChange={changeEvent}
    >
      {itemlist.map((item: any, index: number) => {
        return (
          <SelectOption
            disabled={item.disabled === true}
            selected={selectedIndex === index}
            key={item[idKey]}
            value={item[idKey]}
            useFsMask={true}
          >
            {item[nameProp]}
          </SelectOption>
        );
      })}
    </FormControl>
  ) : (
    <Typeahead
      className={className}
      name={selectName}
      useFsMask={true}
      listItems={itemlist.map(i => ({
        eventKey: i[idKey],
        title: i[nameProp]
      }))}
      showAllOnExpand={true}
      lookupDirection={typeaheadDirection}
      initialValue={
        defaultValue ||
        itemlist.find(i => i[idKey] === selectedId)?.[nameProp] ||
        itemlist[0]?.[nameProp]
      }
      onChange={typeaheadChange}
    />
  );
};

export const buildMultiSelect = (props: ISelectProps) => {
  const {
    btnLabel = t('add'),
    disabled,
    label,
    selectedIds = [],
    setSelectedIds,
    viewOnly = false
  } = props;

  const [leadingItem, setLeadingItem] = useState(selectedIds[0]);

  useEffect(() => {
    setSelectedIds((old: any) => {
      old.shift();
      old.unshift(leadingItem);
      return old;
    });
  }, [leadingItem, setSelectedIds]);

  const addToForm = () => {
    if (selectedIds.length === 0 && leadingItem) {
      setSelectedIds([leadingItem, getNextItem()]);
    } else {
      setSelectedIds((o: (string | null)[]) => [...o, getNextItem()]);
    }
  };

  const getNextItem = () => {
    const itemsLeft = props.items.filter(
      (i: any) => selectedIds?.indexOf(i[props.idKey || 'id']) === -1
    );
    if (itemsLeft.length <= 1) {
      return '';
    }
    return itemsLeft[1][props.idKey || 'id'];
  };

  const removeItem = useCallback(
    (e: ChangeEvent<HTMLButtonElement>, id: string) => {
      e.preventDefault();
      setSelectedIds((o: (string | null)[]) => {
        const clone: (string | null)[] = [...o];
        if (clone.indexOf(id) !== -1) {
          clone.splice(clone.indexOf(id), 1);
        }
        return clone;
      });
    },
    [setSelectedIds]
  );

  const changeItem = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    setSelectedIds((o: (string | null)[]) => {
      if (o.indexOf(id) !== -1) {
        o[o.indexOf(id)] = e.target.value;
      }
      return o;
    });
  };

  const itemsLeft = props?.items?.filter(
    (i: any) => selectedIds?.indexOf(i[props.idKey || 'id']) === -1
  );
  return (
    <FormGroup>
      {label && <FormLabel>{label}</FormLabel>}
      {selectedIds?.length === 0 && (
        <FormGroup key={`${props.selectName}0`}>
          {buildSelect({
            ...props,
            key: `select-${props.selectName}0`,
            onChange: (e: any) => {
              setLeadingItem(e.target.value);
              addToForm();
            }
          })}
        </FormGroup>
      )}
      {selectedIds
        .filter((val, index, array) => array.indexOf(val) === index)
        ?.map((id: string | null | undefined, index: number) => {
          if (id === null) {
            return null;
          }
          const hasMultipleItems =
            selectedIds?.filter(s => [null].indexOf(s as null) === -1).length !== 1;
          const possibleSelection = props.items.filter(
            (i: any) => selectedIds?.indexOf(i[props.idKey as string]) === -1
          );
          const current = props.items.find((i: any) => i?.[props.idKey as string] === id);
          if (current && possibleSelection) {
            possibleSelection.unshift(current);
          }
          return (
            <FormGroup
              key={`${props.selectName}${index}`}
              className={
                hasMultipleItems ? 'full-width pb-4 formcontrol-append-icon' : 'full-width pb-3'
              }
            >
              {buildSelect({
                ...props,
                items: possibleSelection,
                key: `select-${props.selectName}${index}`,
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  changeItem(e, id as string);
                },
                selectName: `${props.selectName}${index}`,
                selectedId: id
              })}
              {hasMultipleItems && !disabled && !viewOnly ? (
                <Icon
                  onClick={e => removeItem(e, id as string)}
                  iconname='StatusInactive'
                  color={colorValue('haiui-gray-07')}
                  size='sm2'
                />
              ) : null}
            </FormGroup>
          );
        })}
      {!disabled && !viewOnly && (
        <FormGroup>
          <Button onClick={addToForm} disabled={itemsLeft.length <= 1}>
            {btnLabel}
          </Button>
        </FormGroup>
      )}
    </FormGroup>
  );
};

interface ISelectDefaultProps {
  items: any;
  idKey?: string;
  selectedId?: string | number;
}
export const selectDefault = (props: ISelectDefaultProps) => {
  const { items, idKey = 'value', selectedId } = props;
  if (selectedId) {
    return items?.findIndex((i: any) => i[idKey] === selectedId);
  }
  return 0;
};
