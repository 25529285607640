import { getDevices } from 'api/devices/devices';
import { IDeviceReturn, IDevices } from 'api/devices/devices.type';
import { useAuthContext } from 'context/auth';
import React, { Dispatch, createContext, useEffect, useReducer } from 'react';

// Init states
export const initDevicesState: any = {};

export enum EnumDevices {
  SET_DEVICES = 'SET_DEVICES',
  FETCHING_DEVICES = 'FETCHING_DEVICES'
}

// Reducer
export const devicesReducer = (state: any, action: any): any => {
  switch (action.type) {
    case EnumDevices.SET_DEVICES:
      return {
        ...state,
        fetching: false,
        devices: action.payload
      };
    case EnumDevices.FETCHING_DEVICES:
      return {
        ...state,
        fetching: true
      };
    default:
      return state;
  }
};

interface IDevicesContext {
  dispatch: Dispatch<any>;

  fetching: boolean;
  devices: IDevices[];
}

const DevicesContext = createContext<IDevicesContext>({
  ...initDevicesState,
  dispatch: () => null
});
const { Provider } = DevicesContext;

const DevicesProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(devicesReducer, initDevicesState);

  const [auth] = useAuthContext();

  useEffect(() => {
    if (!state.nvidia && auth.isAuth) {
      getDevices().then((value: IDeviceReturn) => {
        value && dispatch({ type: EnumDevices.SET_DEVICES, payload: value.nvidia });
      });
    }
  }, [auth, state.nvidia]);

  return <Provider value={{ ...state, dispatch }}>{children}</Provider>;
};

export { DevicesContext, DevicesProvider };
