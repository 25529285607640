export const thumbnailsEn = {
  disabled: 'DISABLED',
  incomplete: 'INCOMPLETE',
  inputErr: 'INPUT ERROR',
  noData: 'NO DATA',
  offline: 'OFFLINE',
  outputErr: 'OUTPUT ERROR',
  streaming: 'STREAMING',
  starting: 'STARTING',
  warning: 'WARNING'
};
