import { EnumArchiveLocation, IArchive } from 'api/settings/archiveLocations';
import { t } from 'i18n';
import { actionStatus } from 'utils/streaming';

export interface IArchiveSpace {
  locationUsed: number;
  locationUsedDisplay: string;
  locationUsedPercentage: number;
  fsUsedPercentage: number;
  fsFreePercentage: number;
  systemFreeMB: number;
  systemUsedMB: number;
  spaceWarning: boolean;
}

export const getArchiveSpace = (item: IArchive): IArchiveSpace => {
  const status = item.status;

  const locationUsed = status.usedBytes || 0;

  const locationUsedMB = Math.round(locationUsed / (1024 * 1024)) || 0;

  let locationUsedPercentage = 0;
  let fsUsedPercentage = 0; // when status not available
  let fsFreePercentage = 0;
  let spaceWarning = false;

  const systemUsed = status.filesystemUsedBytes || 0;
  const systemFree = status.filesystemFreeBytes || 0;
  const systemTotal = systemUsed + systemFree;

  if (systemTotal > 0) {
    locationUsedPercentage = Math.round((locationUsed / systemTotal) * 100);
    fsUsedPercentage = Math.ceil((systemUsed / systemTotal) * 100);
    fsFreePercentage = Math.floor((systemFree / systemTotal) * 100);
    if (fsUsedPercentage >= 85) {
      spaceWarning = true;
    }
  }

  let locationUsedDisplay;
  if (locationUsedMB > 0) {
    locationUsedDisplay = locationUsedMB.toString() + ' MB';
  } else {
    locationUsedDisplay = locationUsed + ' bytes';
  }

  const systemUsedMB = Math.round(systemUsed / (1024 * 1024));

  const systemFreeMB = Math.round(systemFree / (1024 * 1024));

  const archiveSpace = {
    locationUsed,
    locationUsedDisplay,
    systemUsedMB,
    systemFreeMB,
    locationUsedPercentage,
    fsUsedPercentage,
    fsFreePercentage,
    spaceWarning
  };

  return archiveSpace;
};

export const getArchiveLocationType = (item: EnumArchiveLocation) => {
  return item === EnumArchiveLocation.NFS
    ? t('SETTINGS.ARCHIVES.LOCATION.network')
    : t('SETTINGS.ARCHIVES.LOCATION.local');
};

export const getArchiveStyle = (item: IArchive) => {
  const stateCode = item.status.state;
  let type = actionStatus.disabled;
  let icon = 'StatusInactive';
  let iconColor = 'haiui-gray-07';
  let panelColor = 'haiui-gray-07';

  if (stateCode === 'connected') {
    type = actionStatus.active;
    icon = 'StatusOK';
    panelColor = 'haiui-green-01';
    iconColor = 'haiui-green-01';
  } else if (stateCode === 'connecting') {
    type = actionStatus.alert;
    icon = 'StatusWarning';
    panelColor = 'haiui-amber-01';
    iconColor = 'haiui-amber-01';
  } else if (stateCode === 'disconnected') {
    icon = 'StatusInactive';
    type = actionStatus.inactive;
  }
  return {
    actionType: type,
    icon,
    iconColor,
    panelColor
  };
};
