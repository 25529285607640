import './streamingPage.scss';

import { Placeholder } from '@hai/ui-react';
import { getDevicesNdi } from 'api/devices/devices';
import { getMetadatas } from 'api/metadata/metadata';
import { getStreamSnapshots } from 'api/streaming/streaming';
import { EnumSnapshotTapPoint, IStreamItem } from 'api/streaming/streaming.type';
import { getSystemNetwork } from 'api/system/network';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { NotifyContext } from 'context/notify';
import { EnumNetworkAction } from 'context/settings/network';
import { SettingsContext } from 'context/settings/settings';
import { EnumInputsAction } from 'context/streaming/inputs';
import { EnumMetadatasAction } from 'context/streaming/metadatas';
import { StreamingContext } from 'context/streaming/streaming';
import { EnumStreamsAction } from 'context/streaming/streams';
import { t } from 'i18next';
import { RoleContext } from 'permissions/role/Roles';
import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import constant from 'utils/constant';
import { useGetInputs } from 'utils/hooks/useGetInputs';
import { useGetOutputs } from 'utils/hooks/useGetOutputs';
import { useGetStreams } from 'utils/hooks/useGetStreams';
import { useGetTranscoders } from 'utils/hooks/useGetTranscoders';
import { EnumStreamState } from 'utils/hooks/useStreamInfo';
import { useService } from 'utils/useService';

interface IPageProps {
  children: ReactNode;
  fadeIn?: boolean;
  streamId?: string;
}

const StreamingPage: React.FunctionComponent<IPageProps> = (props: IPageProps) => {
  const { children, fadeIn = true } = props;
  const { snapshots, streamsClean, inputsClean, inputNdiList, updatingStreams, streamingDispatch } =
    useContext(StreamingContext);
  const { networkInterfaceClean, settingsDispatch } = useContext(SettingsContext);
  const { dispatch: dispatchNotify } = useContext(NotifyContext);
  const role = useContext(RoleContext);

  const getInputSnapshotsService = useService(getStreamSnapshots);
  const getMetadataService = useService(getMetadatas);

  const getStreamsService = useGetStreams();
  const getInputsService = useGetInputs();
  const getOutputsService = useGetOutputs();

  const getTranscodersService = useGetTranscoders();

  const [fetching, setFetching] = useState<boolean>(false);

  const [isSnapshotsLoading, setIsSnapshotsLoading] = useState<boolean>(false);
  const [isInputLoading, setIsInputLoading] = useState<boolean>(false);
  const [isNetworkInterfaceLoading, setIsNetworkInterfaceLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(!streamsClean);
  const [isStreamUpdating, setIsStreamUpdating] = useState<boolean>(false);

  const loadSnapshots = useCallback(
    () =>
      getInputSnapshotsService({ tappoint: EnumSnapshotTapPoint.INPUT }).then((value: any) => {
        if (value) {
          streamingDispatch({ type: EnumStreamsAction.SET_STREAM_SNAPSHOTS, payload: value });
        } else {
          // There was an error fetching snapshots. Possible cors error.
          streamingDispatch({ type: EnumStreamsAction.SET_STREAM_SNAPSHOTS, payload: [] });
        }
      }),
    [streamingDispatch, getInputSnapshotsService]
  );

  useEffect(() => {
    let isUpdating = false;
    if (updatingStreams?.length) {
      streamsClean?.map((cleanItem: IStreamItem) => {
        const updatingItem = updatingStreams.find((i: IStreamItem) => cleanItem.uuid === i.uuid);
        // start
        if (
          updatingItem &&
          ((updatingItem.state === EnumStreamState.STREAMING &&
            cleanItem.state !== EnumStreamState.INACTIVE) ||
            (updatingItem.state === EnumStreamState.INACTIVE &&
              cleanItem.state !== EnumStreamState.STREAMING))
        ) {
          isUpdating = true;
        }
        // done
        if (
          updatingItem &&
          ((updatingItem.state === EnumStreamState.STREAMING &&
            cleanItem.state === EnumStreamState.INACTIVE) ||
            (updatingItem.state === EnumStreamState.INACTIVE &&
              cleanItem.state === EnumStreamState.STREAMING))
        ) {
          streamingDispatch({
            type: EnumStreamsAction.REMOVE_UPDATING_STREAM,
            payload: updatingItem
          });

          loadSnapshots();
        }
      });
    }
    setIsStreamUpdating(isUpdating);
  }, [
    isStreamUpdating,
    streamingDispatch,
    streamsClean,
    updatingStreams,
    loadSnapshots,
    dispatchNotify
  ]);

  const fetchPageData = useCallback(async () => {
    Promise.all([
      getInputsService({}),
      getOutputsService(),
      getTranscodersService({}),
      getMetadataService()
    ]).then(([_inputs, _outputs, _transcoders, metadataResp]) => {
      // Streams fetch is dependant on the outputs thanks to how outputSummaries is built.
      getStreamsService((resp: any) => {
        if (resp.error) {
          setFetching(true);
        } else {
          setFetching(false);
        }
        setIsLoading(false);
        streamingDispatch({ type: EnumMetadatasAction.SET_METADATAS_CLEAN, payload: metadataResp });
      });
    });
  }, [
    getInputsService,
    getMetadataService,
    getOutputsService,
    getStreamsService,
    getTranscodersService,
    streamingDispatch
  ]);

  useEffect(() => {
    if (isStreamUpdating && streamsClean?.length && updatingStreams?.length) {
      const interval = setInterval(fetchPageData, 1000);
      return () => clearInterval(interval);
    }
  }, [fetchPageData, isStreamUpdating, streamsClean?.length, updatingStreams?.length]);

  useEffect(() => {
    if (!streamsClean && !fetching) {
      setFetching(true);
      fetchPageData();
    }
  }, [fetchPageData, fetching, streamsClean]);

  useEffect(() => {
    if (!inputNdiList) {
      getDevicesNdi().then((resp: any) => {
        streamingDispatch({
          type: EnumInputsAction.SET_NDI,
          payload: resp
        });
      });
    }
  }, [getInputsService, inputNdiList, inputsClean, isInputLoading, streamingDispatch]);

  useEffect(() => {
    if (snapshots === null && !isSnapshotsLoading) {
      setIsSnapshotsLoading(true);
      loadSnapshots().then(() => setIsSnapshotsLoading(false));
    }
  }, [snapshots, loadSnapshots, streamingDispatch, getInputSnapshotsService, isSnapshotsLoading]);

  useEffect(() => {
    if (role.can('view', 'nic') && !networkInterfaceClean && !isNetworkInterfaceLoading) {
      setIsNetworkInterfaceLoading(true);
      getSystemNetwork().then((value: any) => {
        if (value) {
          const dataWithAuto = value.nics;
          dataWithAuto.unshift({
            name: t('auto')
          });
          settingsDispatch({ type: EnumNetworkAction.SET_NIC_CLEAN, payload: value.nics });
          setIsInputLoading(false);
        }
      });
    }
  }, [isNetworkInterfaceLoading, networkInterfaceClean, role, settingsDispatch, streamingDispatch]);

  useInterval(() => {
    loadSnapshots();
  }, constant.interval.snapshot);

  return (
    <div className='streaming-container'>
      {fadeIn ? (
        <>
          <FadeIn suspenseComponent={<PlaceHolderPage />} visible={!isLoading}>
            {children}
          </FadeIn>
        </>
      ) : (
        children
      )}
    </div>
  );
};

const PlaceHolderPage = () => {
  return (
    <div className='streaming-placeholder'>
      <Placeholder.ListActionBar />
      <Placeholder.List />
    </div>
  );
};
export default StreamingPage;
