import './policies.scss';

import {
  Icon,
  OverlayTrigger,
  Placeholder,
  RadioButton,
  RadioButtonGroup,
  Switch
} from '@hai/ui-react';
import { getSecurity, updateSecurity } from 'api/system/security';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { FormContainer, FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { FormInfo } from 'components/common/form/formInfo/formInfo';
import SettingsHeader from 'components/common/settingsHeader/SettingsHeader';
import { EnumNotify, NotifyContext } from 'context/notify';
import { ICertificate } from 'context/security/certificates';
import { EnumPoliciesAction, IPolicy, ISslProtocols } from 'context/security/policies';
import { SecurityContext } from 'context/security/security';
import { t } from 'i18n';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

interface IData {
  strictFilter: '1' | '2';
  v13On: boolean;
  v12On: boolean;
}

const Policies = () => {
  const { dispatch } = useContext(NotifyContext);
  const { securityDispatch, policies } = useContext(SecurityContext);
  const dataFetchedRef = useRef(false);
  const { sslProtocols, rpFilter } = policies || {};

  const [data, setData] = useState<IData>();
  const [loading, setLoading] = useState(false);

  const refreshPolicies = useCallback(
    (callback?: () => void) => {
      getSecurity().then((value: ICertificate[]) => {
        value && securityDispatch({ type: EnumPoliciesAction.SET, payload: value });
        callback && callback();
        setLoading(false);
      });
    },
    [securityDispatch]
  );

  useEffect(() => {
    if (!policies && !dataFetchedRef.current) {
      setLoading(true);
      dataFetchedRef.current = true;
      refreshPolicies();
    }
  }, [policies, refreshPolicies, securityDispatch]);

  useEffect(() => {
    setData({
      strictFilter: rpFilter || '1',
      v13On: !!sslProtocols?.includes('TLSv1.3'),
      v12On: !!sslProtocols?.includes('TLSv1.2')
    });
  }, [sslProtocols, rpFilter]);

  const disabled = loading;

  const onChange = async (name: string, value: any) => {
    setLoading(true);
    const newData = {
      ...data,
      [name]: value
    } as IData;
    setData(newData);
    const newSslProtocols: ISslProtocols = [];
    if (newData.v13On) {
      newSslProtocols.push('TLSv1.3');
    }
    if (newData.v12On) {
      newSslProtocols.push('TLSv1.2');
    }
    if (policies) {
      const item: IPolicy = {
        ...policies,
        rpFilter: newData.strictFilter,
        sslProtocols: newSslProtocols
      };

      const res = await updateSecurity({ item });
      res && securityDispatch({ type: EnumPoliciesAction.SET, payload: res });
      dispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: t('SECURITY.POLICIES.updated')
        }
      });
      setLoading(false);
    }
  };
  return (
    <div className='security-policies'>
      <SettingsHeader title={t('ROUTES.SECURITY.policies')} />
      <FadeIn visible={!!data} suspenseComponent={<PoliciesPlaceholder />}>
        <FormInfo info={t('SECURITY.POLICIES.warning')} />
        <FormSectionDark title={t('SECURITY.POLICIES.versions')}>
          <FormContainer className={'policies-container'}>
            <Switch
              checked={data?.v13On}
              disabled={disabled || !!(data?.v13On && sslProtocols?.length === 1)}
              onChange={(v: boolean) => onChange('v13On', v)}
              label={t('SECURITY.POLICIES.v13')}
              labelPlacement='left'
              dataAuto={`${t('SECURITY.POLICIES.v13')}_${data?.v13On}`}
            />
            <Switch
              checked={data?.v12On}
              disabled={disabled || !!(data?.v12On && sslProtocols?.length === 1)}
              onChange={(v: boolean) => onChange('v12On', v)}
              label={t('SECURITY.POLICIES.v12')}
              labelPlacement='left'
              dataAuto={`${t('SECURITY.POLICIES.v12')}_${data?.v12On}`}
            />
          </FormContainer>
        </FormSectionDark>
        <FormSectionDark
          title={t('SECURITY.POLICIES.rpFilter')}
          rightComponent={
            <>
              <OverlayTrigger
                popoverContent={t('SECURITY.POLICIES.rpHelp')}
                className='checkbox-label-text ml-1'
              >
                <Icon iconname='HelpFilled' size='sm3' color='haiui-gray-06' />
              </OverlayTrigger>
            </>
          }
        >
          <FormContainer className={'policies-container'}>
            <RadioButtonGroup
              style={{ display: 'flex', gap: '3rem' }}
              checkedValue={data?.strictFilter || '1'}
              onChange={(v: any) => {
                if (v.target.value) {
                  onChange('strictFilter', v.target.value);
                }
              }}
              data-auto='rp_filter_radio_group'
            >
              <RadioButton disabled={disabled} value='1' data-auto='strict_radio_button'>
                {t('SECURITY.POLICIES.strict')}
              </RadioButton>
              <RadioButton disabled={disabled} value='2' data-auto='loose_radio_button'>
                {t('SECURITY.POLICIES.loose')}
              </RadioButton>
            </RadioButtonGroup>
          </FormContainer>
        </FormSectionDark>
      </FadeIn>
    </div>
  );
};

const PoliciesPlaceholder = () => {
  return (
    <>
      <Placeholder as='layout' style={{ width: '100%', height: '65px' }} />
      <Placeholder as='layout' style={{ width: '100%', height: '112px', margin: '60px 0' }} />
      <Placeholder as='layout' style={{ width: '100%', height: '112px' }} />
    </>
  );
};

export default Policies;
