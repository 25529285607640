import { t } from 'i18n';
import { generalValidation } from 'utils/validations';
import * as Yup from 'yup';

export const editAccountSchema = Yup.object({
  role: Yup.string().required(t<string>('VALIDATION.SETTINGS.role')),
  password: Yup.string(),
  confirmPwd: Yup.string().test(
    'passwords-match',
    t<string>('VALIDATION.SETTINGS.matchPasswords'),
    (value, { parent }) => {
      if (parent.password) {
        return parent.password === value;
      } else {
        if (value) {
          return parent.password === value;
        } else {
          return true;
        }
      }
    }
  )
});

export const editCurrentAccountSchema = Yup.object({
  role: Yup.string().required(t<string>('VALIDATION.SETTINGS.role')),
  currentPassword: Yup.string().required(t<string>('VALIDATION.SETTINGS.currentPwd')),
  password: Yup.string().test(
    'passwords-required',
    t<string>('VALIDATION.SETTINGS.password'),
    (value, { parent }) => {
      if (parent.currentPassword) {
        if (value) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  ),
  confirmPwd: Yup.string().test(
    'passwords-match',
    t<string>('VALIDATION.SETTINGS.matchPasswords'),
    (value, { parent }) => {
      if (parent.password) {
        return parent.password === value;
      } else {
        if (value) {
          return parent.password === value;
        } else {
          return true;
        }
      }
    }
  )
});

export const addAccountSchema = Yup.object({
  username: Yup.string()
    .required(t<string>('VALIDATION.SETTINGS.username'))
    .matches(/^[^\\/[\];:|=,+*?<>]*$/, t<string>('VALIDATION.SETTINGS.noCertainChars')),
  role: Yup.string().required(t<string>('VALIDATION.SETTINGS.role')),
  password: generalValidation.password,
  confirmPwd: generalValidation.confirmPassword
});
