export const updatesEn = {
  complete: 'is complete.',
  current: 'Current Software',
  version: 'Version',
  build: 'Build',
  update: 'Update Software',
  updated: 'Server has been updated',
  verifying: 'Verifying',
  warning: 'Warning: Please remain on this page during the upload process.',
  ERRORS: {
    NoLicense: 'System is unlicensed and cannot be updated',
    PutUploadUpgradeBundle: 'Upload Error.',
    InsufficientLicenseVersionLimit: 'Update license to install this bundle',
    'Unable to extract bundle properly.': 'Unable to extract bundle properly.',
    'Uploaded bundle version is older than installed version.':
      'Uploaded bundle version is older than installed version.',
    'Did not provide a file': 'Did not provide a file',
    'Internal error Error OldBundleVersion': 'Internal error: Error: OldBundleVersion',
    'Internal error Error InvalidState System cannot upload because it is in state ERROR':
      'System cannot upload because it is in state',
    'Internal error Error Update bundle version exceeds the maximum permitted':
      'Update bundle version exceeds the maximum permitted'
  },
  preparingUpgrade: 'Preparing Update',
  updating: 'Updating',
  updatingTo: 'Updating to ',
  rebooting: 'Rebooting',
  pleaseStay: 'Please remain on this page during the upload process.',
  previous: 'Previously,',
  newUpload: 'New Upload'
};
