import './services.scss';

import { DataTable, FormSection, Link, Placeholder, Switch } from '@hai/ui-react';
import { HaiDataTableColumnType } from '@hai/ui-react/dist/components/DataTable/IHaiDataTable';
import { getSessionSettings, setSessionSettings } from 'api/session/session';
import { getSystemInfo } from 'api/settings/system';
import { ISystemInfo } from 'api/settings/system.type';
import { getRtspserver, getServices, getWebserver, updateServices } from 'api/system/services';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { FormInfo } from 'components/common/form/formInfo/formInfo';
import SettingsHeader from 'components/common/settingsHeader/SettingsHeader';
import { IsHidden } from 'components/isHidden/isHidden';
import { RtspserverConfig } from 'components/settings/services/RtspserverConfig';
import { IWebserverConfig, WebserverConfig } from 'components/settings/services/WebserverConfig';
import { useAuthContext } from 'context';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumServicesAction, IRtspserver, IServices, IWebserver } from 'context/settings/services';
import { SettingsContext } from 'context/settings/settings';
import { EnumStatusAction } from 'context/settings/status';
import { StreamingContext } from 'context/streaming/streaming';
import { t } from 'i18n';
import { Licensed } from 'permissions/role/Roles';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useInterval } from 'usehooks-ts';

interface IDataRow {
  name: string;
  active: boolean;
  startup?: {
    disabled: boolean;
    checked: boolean;
    launching?: boolean;
  };
  config?: boolean;
  label: string;
  licensed?: boolean;
}

const Services = () => {
  const { dispatch } = useContext(NotifyContext);
  const { settingsDispatch, services, webserver, systemInfo, networkInterfaceClean } =
    useContext(SettingsContext);
  const { streamingDispatch } = useContext(StreamingContext);

  const [auth, setAuth] = useAuthContext();
  const dataFetchedRef = useRef(false);
  const [changedServices, setChangedServices] = useState<string[]>([]);
  const [webserverConfig, setWebserverConfig] = useState<IWebserverConfig[]>([]);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showRtsp, setShowRtsp] = useState(false);
  const [initializing, setInitializing] = useState(false);

  const refreshServices = useCallback(() => {
    getServices()
      .then((value: IServices) => {
        getSessionSettings({
          ignoreError: [404]
        }).then(v => {
          setAuth(o => {
            return { ...o, ...v };
          });
        });
        !value.error &&
          settingsDispatch({
            type: EnumServicesAction.SET_SERVICES,
            payload: value
          });
      })
      .finally(() => {
        setLoading(false);
        setInitializing(false);
      });
  }, [setAuth, settingsDispatch]);

  useInterval(refreshServices, 10000);

  useEffect(() => {
    if ((!services || !networkInterfaceClean) && !dataFetchedRef.current) {
      setInitializing(true);
      setLoading(true);
      dataFetchedRef.current = true;
      getWebserver().then((resp: IWebserver) => {
        resp && settingsDispatch({ type: EnumServicesAction.SET_WEBSERVER, payload: resp });
      });
      getRtspserver().then((resp: IRtspserver) => {
        resp && settingsDispatch({ type: EnumServicesAction.SET_RTSPSERVER, payload: resp });
      });
      refreshServices();
    }
  }, [services, refreshServices, streamingDispatch, networkInterfaceClean, settingsDispatch]);

  useEffect(() => {
    if (!systemInfo) {
      getSystemInfo().then((value: ISystemInfo) => {
        value &&
          settingsDispatch({
            type: EnumStatusAction.SET_SYSTEM_INFO,
            payload: value
          });
      });
    }
  }, [settingsDispatch, systemInfo]);

  const onChange = (name: string, value: boolean, label: string) => {
    if (!services) {
      return null;
    }
    setChangedServices((old: string[]) => {
      return [...old, name];
    });
    setLoading(true);
    const newServices = {
      ...services,
      [name]: {
        startup: value,
        active: value
      }
    };
    updateServices({ services: newServices as IServices })
      .then(v => {
        dispatch({
          type: EnumNotify.ADD_NOTIFICATION,
          payload: {
            type: 'Success',
            groupId: value ? 'servicesStarted' : 'servicesStopped',
            itemId: name, // the machine name
            message: {
              params: { name: label }, // the human name
              name: value ? 'SETTINGS.SERVICES.started' : 'SETTINGS.SERVICES.stopped'
            }
          }
        });
        v && settingsDispatch({ type: EnumServicesAction.SET_SERVICES, payload: v });
      })
      .finally(() => {
        if (items.find(i => i.name === name)?.startup?.checked) {
          refreshServices();
          setChangedServices(old => old.filter(item => item !== name));
        } else {
          setTimeout(() => {
            refreshServices();
            // give 15 seconds for service to start before showing an error.
            setChangedServices(old => old.filter(item => item !== name));
          }, 15 * 1000);
        }
        setLoading(false);
      });
  };

  const actionItems = [
    {
      actionIcon: 'Settings',
      title: '',
      eventKey: 'config',
      onSelect: (name: string, obj: any) => {
        if (obj.id.indexOf('webserver') !== -1) {
          setShow(true);
        }
        if (obj.id.indexOf('rtsp') !== -1) {
          setShowRtsp(true);
        }
      }
    }
  ];

  const toggleUserAnalytics = async (val: boolean) => {
    const result = await setSessionSettings({ apiParams: { shareUserAnalytics: val } });
    if (result?.success) {
      setAuth(o => {
        return { ...o, shareUserAnalytics: val };
      });
      dispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: {
            name: val
              ? t('SETTINGS.SERVICES.analyticsShare')
              : t('SETTINGS.SERVICES.analyticsDontShare')
          }
        }
      });
    }
  };

  const columnStructure = [
    { fieldKey: 'name', title: t('SETTINGS.SERVICES.services') },
    {
      fieldKey: 'active',
      title: t('SETTINGS.SERVICES.status'),
      type: HaiDataTableColumnType.ICON
    },

    {
      fieldKey: 'startup',
      title: '',
      type: HaiDataTableColumnType.SWITCH
    },
    { fieldKey: 'config', title: '', type: HaiDataTableColumnType.ACTIONS }
  ];

  const setData = useCallback(() => {
    const webserverCfg: IWebserverConfig[] = [
      {
        label: t('SETTINGS.SERVICES.all'),
        name: 'all',
        checked: !!webserver?.listeners?.all
      }
    ];

    const nics = systemInfo?.networkInterfaces;
    if (nics) {
      Object.keys(nics).map(key => {
        if (key !== 'lo' && nics[key].enabled) {
          return webserverCfg.push({
            label: key,
            name: key,
            checked: !!webserver?.listeners?.interfaces.includes(key)
          });
        }
      });
    }
    setWebserverConfig(webserverCfg);
  }, [systemInfo, webserver]);

  useEffect(() => {
    setData();
  }, [setData]);

  let items: IDataRow[] = [];

  if (services) {
    items = [
      {
        name: 'ssh',
        label: t('SETTINGS.SERVICES.ssh'),
        active: services?.ssh?.active,
        startup: {
          disabled: loading,
          checked: services?.ssh?.startup
        }
      },
      {
        name: 'decklink',
        label: t('SETTINGS.SERVICES.sdi'),
        active: services?.decklink?.active,
        startup: {
          disabled: loading,
          checked: services?.decklink?.startup
        }
      },
      {
        name: 'gigevis',
        label: t('SETTINGS.SERVICES.gigE'),
        active: services?.gigevis?.active,
        startup: {
          disabled: loading,
          checked: services?.gigevis?.startup
        },
        licensed: true
      },
      {
        name: 'ndi',
        label: t('SETTINGS.SERVICES.ndi'),
        active: services?.ndi?.active,
        startup: {
          disabled: loading,
          checked: services?.ndi?.startup
        },
        licensed: true
      },
      {
        name: 'st2110',
        label: t('SETTINGS.SERVICES.st2110'),
        active: services?.st2110?.active,
        startup: {
          disabled: loading,
          checked: services?.st2110?.startup
        },
        licensed: true
      },
      {
        name: 'v4l2',
        label: t('SETTINGS.SERVICES.analog'),
        active: services?.v4l2?.active,
        startup: {
          disabled: loading,
          checked: services?.v4l2?.startup
        }
      },
      {
        name: 'rtspserver',
        label: t('SETTINGS.SERVICES.rtsp'),
        active: services?.rtspserver?.active,
        startup: {
          disabled: loading,
          checked: services?.rtspserver?.startup
        },
        config: webserverConfig.length > 1
      },
      {
        name: 'webserver',
        label: t('SETTINGS.SERVICES.webserver'),
        active: services?.webserver?.active,
        startup: undefined,
        config: webserverConfig.length > 1
      },
      {
        name: 'recording',
        label: t('SETTINGS.SERVICES.recording'),
        active: services?.recording?.active,
        startup: undefined,
        licensed: true
      }
    ];
  }

  const iconStatus = (value: IDataRow) => {
    const serviceItem =
      services && Object.values(services)[Object.keys(services).indexOf(value.name)];

    if (serviceItem.launching || changedServices.indexOf(value.name) !== -1) {
      return {
        active: 'StatusStarting'
      };
    }
    if (!value.active && !value?.startup?.checked) {
      return { active: 'StatusInactive' };
    }
    if (value.active && value?.startup?.checked) {
      return { active: 'StatusOK' };
    }

    if (!value.active && value?.startup?.checked) {
      return {
        active: {
          iconName: 'StatusError',
          popoverContent: t('SETTINGS.SERVICES.serviceWarning', {
            status: value.active ? t('on') : t('off'),
            isStartup: value?.startup?.checked ? '' : `${t('not')} `,
            name: value.name
          }),
          placement: 'left'
        }
      };
    }
    return { active: 'StatusOK' };
  };

  return (
    <div className='settings-services'>
      <FadeIn
        visible={!!services || !initializing}
        suspenseComponent={<LicensingPlaceholder tableStructure={columnStructure} />}
      >
        <IsHidden condition={(show: boolean) => !show} param={show}>
          <WebserverConfig
            show={show}
            webserverConfig={webserverConfig}
            handleClose={() => setShow(false)}
            handleReset={setData}
          />
        </IsHidden>
        <RtspserverConfig show={showRtsp} handleClose={() => setShowRtsp(false)} />

        <SettingsHeader title={t('SETTINGS.SERVICES.services')} />

        <FormSection className='service-table'>
          {/* NOTE - don't mask the services DataTable */}
          <DataTable sortable={false} columnStructure={columnStructure}>
            <DataTable.Header bulkActions={() => null} />
            {items?.length &&
              items.map((value: IDataRow, index: number) => {
                const row = (
                  <DataTable.Row
                    {...(!value.licensed ? { key: `${value.name}-${index}` } : {})}
                    rowData={{
                      index: index,
                      id: `${value.name}-${index}`,
                      name: value.label,
                      startup: {
                        disabled: value?.startup?.disabled,
                        checked: value?.startup?.checked
                      },
                      ...iconStatus(value)
                    }}
                    className={`services-datatable-row${
                      value?.startup?.checked === undefined ? ' hideCheckbox' : ''
                    }`}
                    onIconClick={() => null}
                    onSwitchChange={(fieldkey: string, rowId: string, newState: boolean) =>
                      onChange(value.name, newState, value.label)
                    }
                    actionItems={value.config ? actionItems : []}
                  />
                );

                if (value.licensed) {
                  return (
                    <Licensed key={`${value.name}-${index}`} to={value.name.toUpperCase()}>
                      {row}
                    </Licensed>
                  );
                }
                return row;
              })}
          </DataTable>
        </FormSection>

        <FormSection
          title={t('SETTINGS.SERVICES.productAnalytics')}
          data-auto='product_analytics'
          className='mb-4'
        >
          <Switch
            label={t('SETTINGS.SERVICES.enableProductAnalytics')}
            checked={auth?.shareUserAnalytics}
            onChange={toggleUserAnalytics}
          />
          <FormInfo
            type='InfoDark'
            className='mt-3'
            info={
              <Trans
                t={t}
                i18nKey={'SETTINGS.SERVICES.productAnalyticsInfo'}
                values={{ link: t('SETTINGS.SERVICES.privacyPolicy') }}
                components={[
                  <Link
                    href='https://www.haivision.com/legal/technology-privacy-policy/'
                    target='_blank'
                    showGoTo={false}
                    className='privacy-policy-link'
                    key='privacy-link'
                  />
                ]}
              />
            }
          />
        </FormSection>
      </FadeIn>
    </div>
  );
};

const LicensingPlaceholder = (props: { tableStructure: any }) => {
  const { tableStructure } = props;
  return (
    <>
      <Placeholder.Table columnStructure={tableStructure} numRows={9} />
      <Placeholder as='layout' style={{ margin: '60px 0', width: '100%', height: '200px' }} />
    </>
  );
};

export default Services;
