import client from 'api/utils/client';

export const getDevicesNdi = () => {
  return client
    .get('/devices/ndi')
    .then(resp => {
      const data = resp.data?.data;
      return data;
    })
    .catch(e => {
      return e;
    });
};

export const getDevices = () => {
  return client
    .get('/devices')
    .then(resp => {
      const data = resp.data?.data;
      return apiToFormdata(data);
    })
    .catch(e => {
      return e;
    });
};

const apiToFormdata = (apiData: any) => {
  return {
    nvidia: apiData.nvidia.map((i: any) => {
      return {
        ...i,
        index: `${i.index}`
      };
    })
  };
};
