import './licensing.scss';

import { Button, DataTable, Form, FormSection, Placeholder } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { HaiDataTableColumnType } from '@hai/ui-react/dist/components/DataTable/IHaiDataTable';
import { FileUploadStages, IUploadState } from '@hai/ui-react/dist/types';
import { getLicense, setLicense } from 'api/license/license';
import { getSystemInfo } from 'api/settings/system';
import { EnumBypassMode, ILicenseSettings, ISystemInfo } from 'api/settings/system.type';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { FormContainer, FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { FormInfo } from 'components/common/form/formInfo/formInfo';
import { FormInput } from 'components/common/form/formInput/formInput';
import KrakenUpload from 'components/common/krakenUpload/KrakenUpload';
import SettingsHeader from 'components/common/settingsHeader/SettingsHeader';
import { isHidden } from 'components/isHidden/isHidden';
import { GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumLicensesAction } from 'context/settings/licenses';
import { SettingsContext } from 'context/settings/settings';
import { EnumStatusAction } from 'context/settings/status';
import { t } from 'i18n';
import { DateTime } from 'luxon';
import { RoleCan } from 'permissions/role/Roles';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { licenseWarningMessage, shouldHideInstanceUuid } from './licensing_helpers';

const Licensing = () => {
  const [newLicense, setNewLicense] = useState<string>('');
  const [buttonState, setButtonState] = useState<ButtonStateType>('idle');

  const initState: IUploadState = {
    stage: FileUploadStages.NONE,
    animationTime: 0.5,
    progress: 0
  };
  const [uploadState, setUploadState] = useState<IUploadState>(initState);

  const [file, setFile] = useState();
  const { settingsDispatch, systemInfo, license } = useContext(SettingsContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const { screen } = useContext(GlobalContext);

  const columns = screen.columns.main;
  const tableStructure = [
    { fieldKey: 'feature', title: t('SETTINGS.LICENSING.features') },
    {
      fieldKey: 'licensed',
      title: t('SETTINGS.LICENSING.licensed'),
      type: HaiDataTableColumnType.ICON
    }
  ];

  useEffect(() => {
    const pastedText = (event: any) => {
      const textLen = event?.clipboardData?.getData('text/plain')?.length;
      if (textLen > 200 && textLen < 1_000_000) {
        setNewLicense(event?.clipboardData?.getData('text/plain') as string);
      }
    };
    addEventListener('paste', pastedText, true);

    return () => {
      window.removeEventListener('paste', pastedText, true);
    };
  }, []);

  const refreshSystemInfo = useCallback(
    () =>
      getSystemInfo().then((value: ISystemInfo) => {
        value && settingsDispatch({ type: EnumStatusAction.SET_SYSTEM_INFO, payload: value });
      }),
    [settingsDispatch]
  );

  useEffect(() => {
    if (!systemInfo) {
      refreshSystemInfo();
    }
  }, [refreshSystemInfo, systemInfo]);

  const formProps = {
    defaultValidation: true
  };

  const features = [
    {
      name: t('SETTINGS.LICENSING.FEATURES.mpeg2'),
      licensed: license?.mpeg2video
    },
    {
      name: t('SETTINGS.LICENSING.FEATURES.gige'),
      licensed: license?.gigevis
    },
    {
      name: t('SETTINGS.LICENSING.FEATURES.qsvH264Encoder'),
      licensed: license?.h264Qsv
    },
    {
      name: t('SETTINGS.LICENSING.FEATURES.qsvHvecEncoder'),
      licensed: license?.hevcQsv
    },
    {
      name: t('SETTINGS.LICENSING.FEATURES.hvec'),
      licensed: license?.hevc
    },
    {
      name: t('SETTINGS.LICENSING.FEATURES.ndi'),
      licensed: license?.ndi
    },
    {
      name: t('SETTINGS.LICENSING.FEATURES.klv'),
      licensed: license?.klv
    },
    {
      name: t('SETTINGS.LICENSING.FEATURES.st2110'),
      licensed: license?.st2110
    },
    {
      name: t('SETTINGS.LICENSING.FEATURES.recording'),
      licensed: license?.recording
    }
  ];

  let containerClassName = 'form-container-lg';
  if (columns === 2) {
    containerClassName = 'form-container-md';
  }
  if (columns === 1) {
    containerClassName = 'form-container-sm';
  }

  const onUpdate = () => {
    setButtonState('pending');
    setLicense({ license: newLicense })
      .then(v => {
        if (v.result) {
          getLicense().then((value: ILicenseSettings) => {
            value && settingsDispatch({ type: EnumLicensesAction.SET_LICENSE, payload: value });
            notifyDispatch({
              type: EnumNotify.ADD_NOTIFICATION,
              payload: {
                type: 'Success',
                message: t('SETTINGS.LICENSING.updateComplete')
              }
            });

            setUploadState(prev => {
              return {
                ...prev,
                stage: FileUploadStages.COMPLETED,
                progress: 100
              };
            });

            setTimeout(() => {
              setButtonState('idle');
              setNewLicense('');
              uploadDone();
              window.location.reload();
            }, 3500);
          });
        }
      })
      .catch(() => {
        notifyDispatch({
          type: EnumNotify.ADD_NOTIFICATION,
          payload: {
            type: 'Error',
            message: t('SETTINGS.LICENSING.updateFailed')
          }
        });
        uploadDone();

        setButtonState('idle');
        setNewLicense('');
      });
  };

  const onFileChangeHandler = useCallback((files: any[]) => {
    setFile(files?.[0]);
  }, []);

  useEffect(() => {
    if (file) {
      let fr = new FileReader();
      fr.onload = () => {
        if (
          typeof fr.result === 'string' &&
          fr.result.length > 200 &&
          fr.result.length < 1_000_000
        ) {
          setNewLicense(fr.result as string);
        }
      };

      fr.readAsText(file);
    } else {
      onUploadCancel();
    }
  }, [file]);

  const onUploadCancel = () => {
    setNewLicense('');
    setFile(undefined);
  };

  const uploadDone = () => {
    setFile(undefined);
    setUploadState(prev => {
      return {
        ...prev,
        stage: FileUploadStages.NONE,
        progress: 0
      };
    });
  };

  return (
    <div className='settings-licensing'>
      <Form {...formProps}>
        <FadeIn
          visible={!!license}
          suspenseComponent={<LicensingPlaceholder tableStructure={tableStructure} />}
        >
          <SettingsHeader title={t('ROUTES.SETTINGS.licensing')} />
          <FormInfo info={licenseWarningMessage({ systemInfo, license })} />
          <FormSectionDark title={t('SETTINGS.LICENSING.kraken')} className='header-section'>
            <FormContainer columns={columns} className={containerClassName}>
              <RoleCan I='edit' a='settings/licensing'>
                <FormInput
                  label={t('SETTINGS.LICENSING.expires')}
                  defaultValue={
                    license
                      ? DateTime.fromSeconds(Number(license.expiration)).toLocaleString(
                          DateTime.DATETIME_MED
                        )
                      : t('none')
                  }
                  name='expires'
                  viewOnly
                  dataAuto='license_expiry'
                />
              </RoleCan>
              <FormInput
                label={t('SETTINGS.LICENSING.product')}
                defaultValue={systemInfo?.version}
                name='product'
                viewOnly
                dataAuto='product_version'
              />

              <RoleCan I='edit' a='settings/licensing'>
                <FormInput
                  label={t('SETTINGS.LICENSING.mac')}
                  defaultValue={license?.nicMacAddress}
                  name='mac'
                  viewOnly
                  showCopy
                  dataAuto='mac_address'
                />
              </RoleCan>
              {license?.isCloud && (
                <>
                  <FormInput
                    label={
                      license?.isEc2
                        ? t('SETTINGS.LICENSING.instanceId')
                        : t('SETTINGS.LICENSING.resourceId')
                    }
                    defaultValue={license?.resourceId || t('none')}
                    name='resourceId'
                    classNames='grid-column span1'
                    viewOnly
                  />
                  <FormInput
                    label={t('SETTINGS.LICENSING.cloudLicenseType')}
                    defaultValue={license?.cloudLicenseType || t('none')}
                    name='cloudLicenseType'
                    viewOnly
                  />
                </>
              )}
              <FormInput
                label={t('SETTINGS.LICENSING.streamsAllowed')}
                defaultValue={license?.maxEncoders}
                name='streamsAllowed'
                viewOnly
                dataAuto='streams_allowed'
              />
              <FormInput
                isHidden={isHidden({
                  param: license?.maxBypassMode,
                  condition: (a: number) => a && a === EnumBypassMode.MIXED
                })}
                label={t('SETTINGS.LICENSING.bypassAllowed')}
                defaultValue={
                  license?.maxBypassMode === EnumBypassMode.UNLIMITED
                    ? t('SETTINGS.LICENSING.unlimited')
                    : license?.maxBypass
                }
                name='maxBypass'
                viewOnly
                dataAuto='max_bypass'
              />

              <FormInput
                label={t('SETTINGS.LICENSING.streamLoad')}
                defaultValue={license?.load}
                name='load'
                viewOnly
                dataAuto='stream_load'
              />

              <FormInput
                isHidden={isHidden({
                  param: license?.maxBypassMode,
                  condition: (a: number) => a && a === EnumBypassMode.MIXED
                })}
                label={t('SETTINGS.LICENSING.loadBypass')}
                defaultValue={license?.loadBypass}
                name='loadBypass'
                viewOnly
                dataAuto='load_bypass'
              />
              <RoleCan I='edit' a='settings/licensing'>
                <FormInput
                  isHidden={isHidden({
                    param: license?.versionLimitText,
                    condition: (a: number) => !a
                  })}
                  label={t('SETTINGS.LICENSING.versionLimit')}
                  defaultValue={license?.versionLimitText}
                  name='versionLimit'
                  viewOnly
                  dataAuto='version_limit'
                />
              </RoleCan>
            </FormContainer>
            <FormContainer columns={columns} className={containerClassName}>
              <RoleCan I='edit' a='settings/licensing'>
                <FormInput
                  isHidden={isHidden({
                    param: {
                      isVmware: license?.isVmware,
                      systemInstanceUuid: license?.systemInstanceUuid
                    },
                    condition: shouldHideInstanceUuid,
                    className: 'grid-column span1'
                  })}
                  label={t('SETTINGS.LICENSING.instanceUuid')}
                  defaultValue={license?.systemInstanceUuid}
                  name='instanceUuid'
                  viewOnly
                  showCopy
                  dataAuto='instance_uuid'
                />
              </RoleCan>
            </FormContainer>
          </FormSectionDark>
          <FormSection
            title={t('SETTINGS.LICENSING.features')}
            className='features-section'
            data-auto='form_section_licensing_features'
          >
            {/* NOTE - don't mask the license DataTable the feature list is not sensitive and could be informative */}
            <DataTable className='content' sortable={false} columnStructure={tableStructure}>
              <DataTable.Header bulkActions={() => null} />
              {features.map((value, index: number) => {
                return (
                  <DataTable.Row
                    key={`${value.name}-${index}`}
                    rowData={{
                      index: index,
                      id: `${value.name}-${index}`,
                      feature: value.name,
                      licensed: value.licensed ? 'StatusOK' : 'StatusInactive'
                    }}
                    onIconClick={() => {}}
                    className={`licensing-datatable-row`}
                  ></DataTable.Row>
                );
              })}
            </DataTable>
          </FormSection>
          <RoleCan I='edit' a='settings/licensing'>
            <FormSectionDark title={t('SETTINGS.LICENSING.update')} className='update-section'>
              <KrakenUpload
                onFileChange={onFileChangeHandler}
                accept={{ 'text/plain': ['.lic'] }}
                uploadState={uploadState}
                files={file ? [file] : []}
              />
              <div className='update-buttons'>
                <Button onClick={onUploadCancel} disabled={newLicense.length === 0}>
                  {t('cancel')}
                </Button>

                <Button
                  variant='primary'
                  disabled={!newLicense}
                  state={buttonState}
                  onClick={onUpdate}
                >
                  {t('apply')}
                </Button>
              </div>
            </FormSectionDark>
          </RoleCan>
        </FadeIn>
      </Form>
    </div>
  );
};

const LicensingPlaceholder = (props: { tableStructure: any }) => {
  const { tableStructure } = props;
  return (
    <>
      <Placeholder as='layout' style={{ margin: '60px 0', width: '100%', height: '500px' }} />
      <Placeholder.Table columnStructure={tableStructure} numRows={20} />
    </>
  );
};

export default Licensing;
