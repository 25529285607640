import { useEffect } from 'react';
import { useNavigate } from 'react-router';

interface ITabRedirectProps {
  // default subTab in a tab
  to: string;
}

const TabRedirect = ({ to }: ITabRedirectProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, [navigate, to]);
  return null;
};

export default TabRedirect;
