import { getSnapshot } from 'api/streaming/streaming';
import React from 'react';

export const downloadFile = ({
  data,
  fileName,
  fileType
}: {
  data: any;
  fileName: string;
  fileType?: string;
}) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const exportToCsv = (
  e: React.MouseEvent<HTMLButtonElement>,
  dataCsv: any,
  headers: string[],
  fileName: string
) => {
  e.preventDefault();

  downloadFile({
    data: [...headers, ...dataCsv].join('\n'),
    fileName: fileName,
    fileType: 'text/csv'
  });
};

export const exportImage = async (
  e: React.MouseEvent<HTMLButtonElement>,
  url: string,
  fileName: string
) => {
  let apiPath;
  // If this is the webpack dev server the URL is a full URL with proxied host
  try {
    // The url here is a full URL and not a relative API path
    //  https://hostname/apis/v2/snapshots...
    //  We need to strip the this down to just the path and params so it can be handed to the API client in getSnapshot
    const urlObj = new URL(url);
    apiPath = urlObj.pathname + urlObj.search;
    // Additionally we need to strip the leading /apis/v2 path prefix since it is set as a base path in the getSnapshot API client
  } catch (e) {
    // this is the production build and the url is simply a relative path
    apiPath = url;
  }
  // When processing the image export the URL can sometimes have the api path,
  // strip it because the client in getSnapshot has it already as a base param
  const downloadUrl = apiPath.replace('/apis/v2', '');
  getSnapshot({ url: downloadUrl }).then(response => {
    const blob = response;
    const blobURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobURL;
    a.download = fileName + '.png';
    document.body.appendChild(a);
    a.click();
  });
};

interface IDownloadTextFile {
  name: string;
  data: any;
}
export const downloadTextFile = async (props: IDownloadTextFile) => {
  const { data, name } = props;
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.cfg`);
  document.body.appendChild(link);
  link.click();
  link.remove();
  return true;
};
