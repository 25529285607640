import { getInputs } from 'api/inputs/input';
import { IInputItem } from 'api/inputs/input.type';
import { EnumInputsAction } from 'context/streaming/inputs';
import { StreamingContext } from 'context/streaming/streaming';
import { useCallback, useContext } from 'react';
import { getStreamConnection } from 'utils/streaming';
import { useService } from 'utils/useService';

interface IProps {
  ignoreError?: string[];
  callback?: Function;
}
export const useGetInputs = () => {
  const fetchInputs = useService(getInputs);
  const { streamingDispatch } = useContext(StreamingContext);

  const getInputsService = useCallback(
    (props: IProps) => {
      const { callback } = props;
      return fetchInputs(props)
        .then((value: any) => {
          if (value) {
            const newData = value.map((input: IInputItem) => {
              return {
                ...input,
                connection: getStreamConnection(input)
              };
            });

            streamingDispatch({ type: EnumInputsAction.SET_INPUTS_CLEAN, payload: newData });
          }
          callback && callback();
        })
        .catch(() => callback && callback());
    },
    [fetchInputs, streamingDispatch]
  );

  return getInputsService;
};
