import { IGeneralApiProps } from 'api/global.type';
import client, { handleErrors } from 'api/utils/client';

interface ISerialports {
  result: string;
  portsList: { id: string }[];
}

export const getSerialports = (props?: IGeneralApiProps) => {
  handleErrors(props);
  return client.get('/serialports').then(resp => {
    const dataList: ISerialports = resp.data;
    return dataList;
  });
};
