export const streamsEn = {
  addStream: 'Add Stream',
  createStream: 'Add a stream to start sharing video',
  noStreams: 'No Streams Configured',
  started: '{{name}} Started Successfully',
  started_other: '{{count}} Streams Started Successfully',
  stopped: '{{name}} Stopped Successfully',
  stopped_other: '{{count}} Streams Stopped Successfully',
  streamingParameters: 'Streaming parameters',
  active: 'Active',
  inactive: 'Inactive',
  bandwidth: 'Bandwidth',
  inputRate: 'Input Rate',
  streamRate: 'Stream Rate',
  latency: 'Latency',
  time: 'Time',
  ADD: {
    addOutput: 'Add Output',
    autoStart: 'Auto Start',
    input: 'Input',
    mode: 'Mode',
    name: 'Name',
    output: 'Output',
    passthruOutput: 'Passthru Output',
    transcoder: 'Transcoder',
    MODE: {
      bypass: 'Bypass',
      transcoder: 'Transcoder',
      passthru: 'Transcoder + Passthru'
    },
    metadata: 'Metadata'
  },
  NO_STREAMS: {
    title: 'No Active Streams',
    desc: 'When streams are active, you can monitor them here.',
    go: 'Go to streams'
  },
  DELETE_MODAL: {
    desc_other: 'Are you sure you want to delete these {{count}} streams?',
    desc: 'Are you sure you want to delete this stream?',
    title_other: 'Delete {{count}} streams?',
    title: 'Delete stream?'
  },
  REPORT: {
    bandwidth: 'BANDWIDTH • Kbps',
    delay: 'DELAY • msec',
    detailedStats: 'DETAILED STATISTICS',
    downloadCsv: 'DOWNLOAD CSV',
    downloadImage: 'DOWNLOAD LIVE IMAGE',
    goBack: 'GO BACK',
    pid: 'Process ID',
    reset: 'RESET',
    resetStats: 'RESET STATISTICS',
    state: 'State',
    enableLog: '{{enabled}} Logs and Restart Stream',
    statistics: 'STATISTICS',
    streamReview: 'STREAM REVIEW',
    uptime: 'Uptime',
    uuid: 'UUID',
    CSV: {
      date: 'Date Time',
      inputRate: 'Input Rate(Mb)',
      streamRate: 'Stream Rate(Mb)',
      latency: 'latency(msec)',
      overallRate: 'Overall Rate(Mb)'
    }
  },
  TIMESCALE: {
    fiveMins: '5 mins',
    sixtyMins: '60 mins',
    twentyFourHours: '24 hrs'
  },
  RESTART: {
    title: 'Are you sure you want to restart the stream now?',
    restartStream: 'Restart Stream',
    desc: `The stream you modified is currently in use and will need to be restarted for these changes to take effect  ({{listOfStreams}})\n
            Would you like to restart the stream now?`
  },
  STATISTICS: {
    input: 'Input ',
    output: 'Output ',
    encoder: 'Encoder ',
    decoder: 'Decoder '
  },
  MESSAGES: {
    startedStream: '"{{name}}" started successfully.',
    startedStream_other: '{{count}} streams started successfully.',
    stoppedStream: '"{{name}}" stopped successfully.',
    stoppedStream_other: '{{count}} streams stopped successfully.',
    startedStreams: '{{itemsStopped}} streams started',
    stoppedStreams: '{{itemsStopped}} streams stopped ',
    added: '"{{name}}" added successfully.',
    deleted: '"{{name}}" deleted successfully.',
    deleted_other: '{{count}} streams deleted successfully.',
    updated: '"{{name}}" updated successfully.',
    'Starting encoder would exceed license limit': 'Starting encoder would exceed license limit.',
    'Starting encoder would exceed license limit_other':
      'Starting {{count}} encoders would exceed license limit.',
    'Failed to start stream': 'Failed to start stream.',
    'Failed to start stream_other': 'Failed to start {{count}} streams.',
    'No output selected': 'No output selected.',
    'No output selected_other': 'No output selected for {{count}} streams.'
  }
};
