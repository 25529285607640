import { IGeneralApiProps } from 'api/global.type';
import client, { handleErrors } from 'api/utils/client';
import { AxiosError } from 'axios';

interface IProps extends IGeneralApiProps {
  username: string;
  password: string;
}

export const signin = (props: IProps) => {
  const { username, password } = props;
  handleErrors(props);

  const triggerSignin = async () => {
    try {
      return await client.post('/login', { username, password });
    } catch (e) {
      const error = e as AxiosError;
      if (
        error?.response &&
        error?.response?.status &&
        [401, 500, 502, 504].indexOf(error.response.status) !== -1
      ) {
        return error;
      } else if (error.code === 'ERR_NETWORK') {
        // no network and we dont get a response from the error.
        return { response: { status: 'ERR_NETWORK' } };
      }
    }

    return { accesstoken: password, username };
  };

  return triggerSignin();
};
