import { srtValidationSchema } from 'components/streaming/srtForm/srtFormValidation';
import { t } from 'i18next';
import { isHostname, isIpV4, isIpV6, isRTMPUrl, isUrl } from 'utils/validations';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  ...srtValidationSchema,
  ...{
    name: Yup.string().required(t('STREAMING.REQUIRED.nameRequired') as string),
    url: Yup.string().when('streamType', {
      is: (streamType: string) => ['RTMP', 'MPEG2TS'].indexOf(streamType) !== -1,
      then: Yup.string()
        .required(t('STREAMING.REQUIRED.required', { item: 'Url' }) as string)
        .test((address, obj) => {
          if (obj.parent.streamType === 'MPEG2TS') {
            return isUrl((address as string) || '');
          }
          return isRTMPUrl(address?.toLowerCase());
        }),
      otherwise: Yup.string().notRequired()
    }),

    prefix: Yup.string().when('streamType', {
      is: (streamType: string) => streamType === 'TS_SEGMENT_ARCHIVE',
      then: Yup.string().required(t('STREAMING.REQUIRED.prefix') as string),
      otherwise: Yup.string().notRequired()
    }),
    archiveLocationUuid: Yup.string().when('streamType', {
      is: (streamType: string) => streamType === 'TS_SEGMENT_ARCHIVE',
      then: Yup.string().required(t('STREAMING.REQUIRED.archiveLocation') as string),
      otherwise: Yup.string().notRequired()
    }),

    ttl: Yup.mixed().when('streamType', {
      is: (streamType: string) => ['MPEG2TS', 'SRT'].indexOf(streamType) !== -1,
      then: Yup.number()
        .typeError(t('VALIDATION.between', { x: 0, y: 255 }) as string)
        .min(0, t('VALIDATION.between', { x: 0, y: 255 }) as string)
        .max(255, t('VALIDATION.between', { x: 0, y: 255 }) as string),
      otherwise: Yup.mixed().notRequired()
    }),
    mtu: Yup.mixed().when('streamType', {
      is: (streamType: string) => ['MPEG2TS', 'SRT'].indexOf(streamType) !== -1,
      then: Yup.number()
        .typeError(t('VALIDATION.between', { x: 124, y: 10000 }) as string)
        .min(124, t('VALIDATION.between', { x: 124, y: 10000 }) as string)
        .max(10000, t('VALIDATION.between', { x: 124, y: 10000 }) as string),
      otherwise: Yup.mixed().notRequired()
    }),
    tos: Yup.mixed().when('streamType', {
      is: (streamType: string) => ['MPEG2TS', 'SRT'].indexOf(streamType) !== -1,
      then: Yup.number()
        .typeError(t('VALIDATION.between', { x: 0, y: 255 }) as string)
        .min(0, t('VALIDATION.between', { x: 0, y: 255 }) as string)
        .max(255, t('VALIDATION.between', { x: 0, y: 255 }) as string),
      otherwise: Yup.mixed().notRequired()
    }),

    rtspServer: Yup.string().when(['streamType', 'rtspDirection'], {
      is: (streamType: string, rtspDirection: string) => {
        return streamType === 'RTSP' && ['PUSH', undefined].indexOf(rtspDirection) !== -1;
      },
      then: Yup.string()
        .required(t('STREAMING.REQUIRED.required', { item: 'Server' }) as string)
        .test(srtAddress => {
          if (
            isIpV4(srtAddress as string) ||
            isIpV6(srtAddress as string) ||
            isHostname(srtAddress as string)
          ) {
            return true;
          }
          return false;
        })
    }),

    rtspPort: Yup.mixed().when('streamType', {
      is: (streamType: string) => ['RTSP'].indexOf(streamType) !== -1,
      then: Yup.number()
        .typeError(t('VALIDATION.between', { x: 1, y: 65535 }) as string)
        .min(1, t('VALIDATION.between', { x: 1, y: 65535 }) as string)
        .max(65535, t('VALIDATION.between', { x: 1, y: 65535 }) as string),
      otherwise: Yup.mixed().notRequired()
    }),

    rtspPath: Yup.string().when('streamType', {
      is: (streamType: string) => streamType === 'RTSP',
      then: Yup.string()
        .required(t('STREAMING.REQUIRED.required', { item: 'RTSP path' }) as string)
        .test(path => {
          return isUrl((path as string) || '');
        })
    }),
    sapName: Yup.string().when(['streamType', 'sapEnable'], {
      is: (streamType: string, sapEnable: boolean) => streamType === 'MPEG2TS' && sapEnable,
      then: Yup.string().required(t('STREAMING.REQUIRED.required', { item: 'Name' }) as string)
    }),
    sapAddress: Yup.string().when('streamType', {
      is: (streamType: string) => streamType === 'MPEG2TS',
      then: Yup.string().test(srtAddress => {
        if (
          !srtAddress ||
          isIpV4(srtAddress as string) ||
          isIpV6(srtAddress as string) ||
          isHostname(srtAddress as string)
        ) {
          return true;
        }
        return false;
      })
    }),

    sapPort: Yup.mixed().when('streamType', {
      is: (streamType: string) => 'MPEG2TS' === streamType,
      then: Yup.number()
        .typeError(t('VALIDATION.between', { x: 1, y: 65535 }) as string)
        .min(1, t('VALIDATION.between', { x: 1, y: 65535 }) as string)
        .max(65535, t('VALIDATION.between', { x: 1, y: 65535 }) as string),
      otherwise: Yup.mixed().notRequired()
    }),

    hls: Yup.object().when('streamType', {
      is: (streamType: string) => 'HLS' === streamType,
      then: Yup.object().shape({
        playlistMaxNumSegments: Yup.number()
          .required(
            t('STREAMING.REQUIRED.required', {
              item: t('STREAMING.OUTPUTS.ADD.segmentsMax')
            }) as string
          )
          .typeError(t('VALIDATION.between', { x: 1, y: 20 }) as string)
          .min(1, t('VALIDATION.between', { x: 1, y: 20 }) as string)
          .max(20, t('VALIDATION.between', { x: 1, y: 20 }) as string),

        segmentDurationMsec: Yup.number()
          .required(
            t('STREAMING.REQUIRED.required', {
              item: t('STREAMING.OUTPUTS.ADD.segmentsDuration')
            }) as string
          )

          .typeError(t('VALIDATION.between', { x: 1000, y: 10000 }) as string)
          .min(1000, t('VALIDATION.between', { x: 1000, y: 10000 }) as string)
          .max(10000, t('VALIDATION.between', { x: 1000, y: 10000 }) as string)
      })
    }),

    srtOverhead: Yup.mixed().when('streamType', {
      is: (streamType: string) => 'SRT' === streamType,
      then: Yup.number()
        .typeError(t('VALIDATION.between', { x: 5, y: 100 }) as string)
        .min(5, t('VALIDATION.between', { x: 5, y: 100 }) as string)
        .max(100, t('VALIDATION.between', { x: 5, y: 100 }) as string),
      otherwise: Yup.mixed().notRequired()
    })
  }
});
