import './network.scss';

import {
  Button,
  DynamicContainer,
  FormGroup,
  FormLabel,
  Icon,
  IconButton,
  RadioButton,
  RadioButtonGroup,
  Switch
} from '@hai/ui-react';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { FormCheckbox } from 'components/common/form/formCheckbox/formCheckbox';
import { FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { buildFormSelect } from 'components/common/form/formSelect/formSelect';
import { INetwork, ISnmpTrapServer } from 'context/settings/network';
import { t } from 'i18n';
import React from 'react';

interface IProps {
  formRef: any;
  columns: number;
  network: INetwork;
  isDisabled: boolean;
}

const GeneralNetworkConfig = ({ formRef, columns, network, isDisabled }: IProps) => {
  const { nics } = network;
  const formValues = formRef?.current?.values;
  const initialValues = formRef?.current?.initialValues;

  const nicsOptions = nics?.map(i => {
    return {
      option: `${i.name}${i.userLabel ? ` (${i.userLabel})` : ''}`,
      value: i.name
    };
  });
  nicsOptions?.push({
    option: t('auto'),
    value: 'auto'
  });

  const dnsPrecedenceOptions = [
    {
      option: t('SETTINGS.NETWORK.ipV6'),
      value: 'IPv6'
    },
    {
      option: t('SETTINGS.NETWORK.ipV4'),
      value: 'IPv4'
    }
  ];

  const addSnmpTrapServer = () => {
    const servers = formValues.snmpTrapServers || [];
    const newServers = [...servers];
    newServers.push({
      server: '',
      community: '',
      type: 'trap2sink'
    });
    formRef?.current?.setFieldValue('snmpTrapServers', newServers);
  };

  const removeSnmpTrapServer = (i: number) => {
    const newServers = [...formValues.snmpTrapServers];
    newServers.splice(i, 1);
    formRef?.current?.setFieldValue('snmpTrapServers', newServers);
  };

  const hasIpv6Enabled = nicsOptions?.length && nics.findIndex(n => n.ipv6 === true) !== -1;

  return (
    <FadeIn>
      <FormSectionDark title={t('SETTINGS.NETWORK.general')}>
        <DynamicContainer minColumns={columns} maxColumns={columns}>
          <FormInput
            label={t('SETTINGS.NETWORK.hostname')}
            name='hostname'
            disabled={isDisabled}
            dataAuto='hostname'
            required
          />
          <FormInput
            label={t('SETTINGS.NETWORK.ntpServer')}
            name='ntpAddress'
            disabled={isDisabled}
            dataAuto='ntp_server'
          />
        </DynamicContainer>
        <DynamicContainer minColumns={columns} maxColumns={columns}>
          <FormCheckbox
            formRef={formRef}
            label={t('SETTINGS.NETWORK.ipForwarding')}
            name='ipForward'
            checked={formValues?.ipForward}
            disabled={isDisabled}
          />
          <FormCheckbox
            formRef={formRef}
            label={t('SETTINGS.NETWORK.redirectMessages')}
            name='acceptRedirects'
            checked={formValues?.acceptRedirects}
            disabled={isDisabled}
          />
        </DynamicContainer>
      </FormSectionDark>
      <FormSectionDark name='general_2'>
        <DynamicContainer minColumns={columns} maxColumns={columns}>
          {nicsOptions?.length &&
            buildFormSelect({
              label: t('SETTINGS.NETWORK.defaultInterface'),
              items: nicsOptions,
              selectName: 'defaultInterface',
              disabled: isDisabled,
              dataAuto: 'default_interface'
            })}
          {hasIpv6Enabled &&
            buildFormSelect({
              label: t('SETTINGS.NETWORK.dnsPrecedence'),
              items: dnsPrecedenceOptions,
              selectName: 'dnsPrecedence',
              disabled: isDisabled,
              dataAuto: 'dns_precedence'
            })}
        </DynamicContainer>
        <FormGroup data-auto='dns_mode_form_group'>
          <FormLabel data-auto='label'>{t('SETTINGS.NETWORK.dnsMode')}</FormLabel>
          <RadioButtonGroup
            style={{ display: 'flex', gap: '3rem' }}
            checkedValue={formValues?.autoDns ? 'true' : 'false'}
            onChange={(v: any) => {
              if (v.target.value) {
                formRef?.current?.setFieldValue('autoDns', v.target.value === 'true');
              }
            }}
          >
            <RadioButton disabled={isDisabled} value='true' data-auto='auto_radio_button'>
              {t('SETTINGS.NETWORK.modeAuto')}
            </RadioButton>
            <RadioButton disabled={isDisabled} value='false' data-auto='manual_radio_button'>
              {t('SETTINGS.NETWORK.modeManual')}
            </RadioButton>
          </RadioButtonGroup>
        </FormGroup>
        <DynamicContainer minColumns={columns} maxColumns={columns}>
          <FormInput
            label={t('SETTINGS.NETWORK.dnsServer0')}
            name='dnsServer-0'
            disabled={isDisabled || formValues?.autoDns}
            dataAuto='primary_dns'
          />
          <FormInput
            label={t('SETTINGS.NETWORK.dnsServer1')}
            name='dnsServer-1'
            disabled={isDisabled || formValues?.autoDns}
            dataAuto='secondary_dns'
          />
        </DynamicContainer>
        <DynamicContainer minColumns={columns} maxColumns={columns}>
          <FormInput
            label={t('SETTINGS.NETWORK.searchDomain0')}
            name='searchDomain-0'
            disabled={isDisabled || formValues?.autoDns}
            dataAuto='search_domain_1'
          />
          <FormInput
            label={t('SETTINGS.NETWORK.searchDomain1')}
            name='searchDomain-1'
            disabled={isDisabled || formValues?.autoDns}
            dataAuto='search_domain_2'
          />
          <FormInput
            label={t('SETTINGS.NETWORK.searchDomain2')}
            name='searchDomain-2'
            disabled={isDisabled || formValues?.autoDns}
            dataAuto='search_domain_3'
          />
        </DynamicContainer>
      </FormSectionDark>
      <FormSectionDark className='snmp-section'>
        <Switch
          labelPlacement='left'
          dataAuto={`snmp-${formValues?.snmp}`}
          label={t('SETTINGS.NETWORK.snmp')}
          checked={formValues?.snmp}
          onChange={(value: boolean) => {
            const newValues = {
              ...formValues,
              snmp: value,
              // reset
              snmpTrapServers: initialValues.snmpTrapServers
            };
            formRef.current.setValues(newValues);
          }}
          className={'pb-4'}
          disabled={isDisabled}
        />
        {formValues?.snmp && (
          <DynamicContainer maxColumns={1} data-auto='form-section-snmp'>
            <DynamicContainer minColumns={3}>
              <FormInput
                label={t('SETTINGS.NETWORK.readOnlyCommunity')}
                name='snmpRoCommunity'
                disabled={isDisabled}
                dataAuto='read_only_community'
              />
            </DynamicContainer>
            {formValues?.snmpTrapServers?.map((i: ISnmpTrapServer, index: number) => {
              const showLabel = index === 0 || columns < 2;
              return (
                <DynamicContainer key={index} minColumns={columns} maxColumns={columns}>
                  <FormInput
                    label={showLabel ? t('SETTINGS.NETWORK.snmpTrapServer') : ' '}
                    name={`snmpTrapServers.${index}.server`}
                    placeholder={t('SETTINGS.NETWORK.PLACEHOLDERS.server')}
                    required
                    disabled={isDisabled}
                    dataAuto={`snmp_trap_server_${index}`}
                  />
                  <FormGroup className={'formcontrol-append-icon'}>
                    <FormInput
                      label={showLabel ? t('SETTINGS.NETWORK.snmpTrapServerCommunity') : ' '}
                      name={`snmpTrapServers.${index}.community`}
                      placeholder={t('SETTINGS.NETWORK.PLACEHOLDERS.community')}
                      required
                      disabled={isDisabled}
                      dataAuto={`snmp_trap_server_community_${index}`}
                    />
                    <IconButton
                      data-auto={`snmp_trap_server_remove_${index}`}
                      style={{ width: '20px' }}
                      onClick={() => removeSnmpTrapServer(index)}
                    >
                      <Icon iconname='StatusInactive' size='sm2' />
                    </IconButton>
                  </FormGroup>
                </DynamicContainer>
              );
            })}

            <Button
              onClick={addSnmpTrapServer}
              variant='secondary'
              disabled={isDisabled}
              className='snmp-add-button'
            >
              {t('SETTINGS.NETWORK.snmpTrapServerAdd')}
            </Button>
          </DynamicContainer>
        )}
      </FormSectionDark>
    </FadeIn>
  );
};

export default GeneralNetworkConfig;
