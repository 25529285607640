import React, { JSXElementConstructor, PropsWithChildren, ReactElement } from 'react';
import { default as FadeInLib } from 'react-fade-in/lib/FadeIn';

interface Props {
  delay?: number;
  transitionDuration?: number;
  wrapperTag?: JSXElementConstructor<any>;
  childTag?: JSXElementConstructor<any>;
  className?: string;
  childClassName?: string;
  visible?: boolean;
  onComplete?: () => any;
  suspenseComponent?: ReactElement;
}
export const FadeIn = (props: PropsWithChildren<Props>) => {
  const {
    delay = 50,
    transitionDuration = 100,
    className = '',
    suspenseComponent,
    visible,
    ...rest
  } = props;
  const classes = `fadein ${className}`;

  if (suspenseComponent && visible === false) {
    return suspenseComponent;
  }

  return (
    <FadeInLib
      transitionDuration={transitionDuration}
      delay={delay}
      className={classes}
      visible={visible}
      {...rest}
    />
  );
};
