import './notify.scss';

import { Notification } from '@hai/ui-react';
import { restartStream } from 'api/streaming/streaming';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import { EnumNotify, INotifyMessage, NotifyContext } from 'context/notify';
import { StreamingContext } from 'context/streaming/streaming';
import { t } from 'i18next';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ErrorHandler from 'utils/hooks/useErrorHandling';

const Notify = () => {
  const { notifyList, dispatch } = useContext(NotifyContext);
  const [timerRunning, setTimerRunning] = useState<boolean>(false);
  const [currentMsg, setCurrentMsg] = useState<INotifyMessage>({ message: '' });

  const hideMsg = useCallback(() => {
    setTimerRunning(false);
  }, []);

  const showMsg = useCallback(() => {
    setTimerRunning(true);
    if (notifyList[0].message.name) {
      setCurrentMsg({
        message: t(notifyList[0].message.name, {
          ...(notifyList[0].message.params || {}),
          count:
            notifyList[0].message.items?.length ||
            notifyList.filter(n => n.groupId === notifyList[0].groupId).length
        }),
        type: notifyList[0].type,
        ...(notifyList[0]?.onLinkClick ? { onLinkClick: notifyList[0]?.onLinkClick } : {}),
        ...(notifyList[0]?.linkText ? { linkText: notifyList[0]?.linkText } : {})
      });
    } else {
      setCurrentMsg({
        message: notifyList[0].message,
        type: notifyList[0].type,
        ...(notifyList[0]?.onLinkClick ? { onLinkClick: notifyList[0]?.onLinkClick } : {}),
        ...(notifyList[0]?.linkText ? { linkText: notifyList[0]?.linkText } : {})
      });
    }
    dispatch({
      type: EnumNotify.REMOVE_NOTIFICATION,
      payload: notifyList[0]
    });
  }, [dispatch, notifyList]);

  useEffect(() => {
    if (notifyList.length && !timerRunning) {
      showMsg();
    }
  }, [notifyList.length, showMsg, timerRunning]);

  return (
    <ErrorHandler>
      {timerRunning ? (
        <Notification
          show={timerRunning}
          onDismiss={hideMsg}
          {...(currentMsg.onLinkClick ? { onLinkClick: currentMsg.onLinkClick } : {})}
          {...(currentMsg.linkText ? { linkText: currentMsg.linkText } : {})}
          message={currentMsg.message}
          variant={currentMsg.type || 'Success'}
          data-auto='hai-notification-popup'
          useFsMask={true}
        />
      ) : (
        <></>
      )}
    </ErrorHandler>
  );
};

export default Notify;

interface INotifyRestart {
  result: any;
  type: string;
}
export const useNotifyRestartStream = () => {
  const { streamsClean } = useContext(StreamingContext);
  const globalContext = useContext(GlobalContext);
  const notifyRestartStream = ({ result, type }: INotifyRestart) => {
    const listOfStreams =
      streamsClean
        ?.filter(s => result.streamsToRestart.indexOf(s.uuid) !== -1)
        .map(i => ` ${i.name}`)
        .toString() + ' ';

    globalContext.dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        confirmText: t(`STREAMING.${type}.RESTART.restartStream`),
        cancelText: t('later'),
        onConfirm: () => {
          result.streamsToRestart.forEach((item: string) => {
            restartStream({ apiParams: { uuid: item } });
          });
        },
        onCancel: () => {},
        title: t(`STREAMING.${type}.RESTART.title`),
        desc: t(`STREAMING.${type}.RESTART.desc`, { listOfStreams }),
        size: 'lg'
      }
    });
  };
  return { notifyRestartStream };
};
