import { IPresetItem, savePreset } from 'api/presets/presets';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { PresetContext } from 'context/preset';
import { t } from 'i18next';
import { useContext } from 'react';
import { useGetPresets } from 'utils/hooks/useGetPresets';

interface INotifyChanges {
  type: string;
  confirm: () => void;
}
export const useNotifyChanges = () => {
  const { configList } = useContext(PresetContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const getPresetsService = useGetPresets();

  const currentPreset = configList?.find((preset: IPresetItem) => preset.active) || {
    name: t('PRESETS.untitledPreset')
  };

  const savePresetEvent = async (signout: () => void) => {
    const { success } = await savePreset({
      apiParams: {
        ...configList?.find((preset: IPresetItem) => preset.active)
      }
    });

    notifyDispatch({
      type: EnumNotify.ADD_NOTIFICATION,
      payload: {
        type: success ? 'Success' : 'Error',
        message: t(success ? `PRESETS.saved` : `PRESETS.saveFailed`, currentPreset)
      }
    });
    await updatePresets();
    if (success) {
      setTimeout(() => {
        signout();
      }, 1000);
    }
  };

  const updatePresets = async () => {
    return await getPresetsService();
  };

  const globalContext = useContext(GlobalContext);
  const notifyChanges = (props: INotifyChanges) => {
    const { type, confirm } = props;

    globalContext.dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        isDanger: type === 'signout' ? false : true,

        cancelText: t('cancel'),
        onCancel: () => {},

        altConfirmText: type === 'signout' ? t('PRESETS.UNSAVED.signout') : t(type),
        altConfirm: confirm,

        confirmText:
          type === 'signout' ? t(`PRESETS.UNSAVED.save`) : t('REBOOT.save', { type: t(type) }),
        onConfirm: () => {
          savePresetEvent(confirm);
        },
        title:
          type === 'signout'
            ? t(`PRESETS.UNSAVED.unsavedChanges`)
            : t('REBOOT.title', { type: t(type) }),

        desc:
          type === 'signout'
            ? [t(`PRESETS.UNSAVED.unsavedDesc`), t(`PRESETS.UNSAVED.unsavedDesc2`)]
            : [
                t('REBOOT.presetUnsaved', { type: t(type) }),
                t('REBOOT.presetUnsaved2', { type: t(type) })
              ],
        size: 'lg'
      }
    });
  };
  return { notifyChanges };
};
