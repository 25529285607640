import { IAccount } from 'api/accounts/account';

export interface IAccountsState {
  accounts: IAccount[] | null;
  accountsClean: IAccount[] | null;
}

export enum EnumAccountsAction {
  SET_ACCOUNTS = 'SET_ACCOUNTS',
  SELECT_ACCOUNT = 'SELECT_ACCOUNT',
  SET_ACCOUNTS_CLEAN = 'SET_ACCOUNTS_CLEAN',
  SELECT_ALL = 'SELECT_ALL_ACCOUNTS',
  RESET = 'RESET'
}

export interface IAccountsAction {
  type: EnumAccountsAction;
  payload: any;
}

// Init states
export const initAccountsState: IAccountsState = {
  accounts: null,
  accountsClean: null
};

// Reducer
export const accountsReducer = (state: IAccountsState, action: IAccountsAction): IAccountsState => {
  switch (action.type) {
    case EnumAccountsAction.SET_ACCOUNTS_CLEAN: {
      const newAccounts = action.payload?.map((account: IAccount) => {
        const selected = state.accountsClean?.find(i => i.id === account.id)?.selected;
        const newItem = account;
        newItem.selected = selected;
        return newItem;
      });
      return {
        ...state,
        accountsClean: newAccounts,
        accounts: newAccounts
      };
    }
    case EnumAccountsAction.SET_ACCOUNTS: {
      return {
        ...state,
        accounts: action.payload
      };
    }
    case EnumAccountsAction.SELECT_ACCOUNT: {
      const { id, selected } = action.payload;
      const newAccounts = state.accounts?.map((i: IAccount) => {
        const newItem = i;
        if (i.id === id) {
          newItem.selected = selected;
        }
        return newItem;
      });
      const newAccountsClean = state.accountsClean?.map((i: IAccount) => {
        const newItem = i;
        if (i.id === id) {
          newItem.selected = selected;
        }
        return newItem;
      });
      return {
        ...state,
        accountsClean: newAccountsClean || null,
        accounts: newAccounts || null
      };
    }
    case EnumAccountsAction.SELECT_ALL: {
      const selected = action.payload;
      const newAccounts = state.accounts?.map((i: IAccount) => {
        const newItem = i;
        newItem.selected = selected;
        return newItem;
      });
      const newAccountsClean = state.accountsClean?.map((i: IAccount) => {
        const newItem = i;
        newItem.selected = selected;
        return newItem;
      });
      return {
        ...state,
        accounts: newAccounts || [],
        accountsClean: newAccountsClean || []
      };
    }
    case EnumAccountsAction.RESET: {
      return initAccountsState;
    }
    default:
      return state;
  }
};
