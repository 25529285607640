import { IGeneralApiProps } from 'api/global.type';
import client from 'api/utils/client';

export enum EnumAccountRole {
  ADMIN = 'admin',
  OPERATOR = 'operator',
  VIEWER = 'viewer'
}

export interface IAccount {
  id: string;
  username: string;
  role: EnumAccountRole;
  state: string;
  expiration: number | undefined;
  // frontend use only;
  selected?: boolean;
  expiring?: boolean;
}

export const getAccounts = (props?: IGeneralApiProps) => {
  return client
    .get('/accounts')
    .then(resp => {
      const data = resp.data.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return [];
    });
};

interface IUpdateAccount extends IGeneralApiProps {
  item: Partial<IAccount>;
}

export const enableAccount = (props: IUpdateAccount) => {
  const { item, onError } = props;

  return client
    .put(`accounts/${item.id}/enable`)
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};

export const disableAccount = (props: IUpdateAccount) => {
  const { item, onError } = props;

  return client
    .put(`accounts/${item.id}/disable`)
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};

export const updateAccount = (props: IUpdateAccount) => {
  const { item, onError } = props;

  return client
    .put(`accounts/${item.id}`, { ...item })
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};

export const deleteAccount = (props: IUpdateAccount) => {
  const { item, onError } = props;

  return client
    .delete(`accounts/${item.id}`)
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};

export const addAccount = (props: IUpdateAccount) => {
  const { item, onError } = props;

  return client
    .post('accounts', { ...item })
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};

interface IChangePasswordProps extends IGeneralApiProps {
  apiParams: IApiParams;
}

interface IApiParams {
  confirm_password: string;
  current_password: string;
  id: string;
  password: string;
  username: string;
  state: string;
}

export const changePassword = (props: IChangePasswordProps): Promise<{ success: boolean }> => {
  const { apiParams, onError } = props;
  return client
    .put(`/accounts/${apiParams.id}`, formatDataForApi(apiParams))
    .then(resp => {
      if (resp.status === 200) {
        return { success: true };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

const formatDataForApi = (data: IApiParams) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state, ...newData } = data;
  return newData;
};
