import { IGeneralApiProps } from 'api/global.type';
import client, { disableNormalizingConfig } from 'api/utils/client';
import { ICertificate } from 'context/security/certificates';

export const getCertificates = (props?: IGeneralApiProps) => {
  return client
    .get('/system/certificates/files')
    .then(resp => {
      const data = resp.data.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return [];
    });
};

interface IUpdateCertificate extends IGeneralApiProps {
  item: Partial<ICertificate>;
}

export const addCertificate = (props: IUpdateCertificate) => {
  const { item, onError } = props;

  return client
    .post('/system/certificates/files', { ...item }, disableNormalizingConfig)
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};

interface IUploadCertificate extends IGeneralApiProps {
  name: string;
  type: string;
  infmt: string;
  password: string;
  file: File;
  filePrivateKey?: File;
  fileBundle?: File;
}

interface IUploadCertificateParams {
  [key: string]: any;
}

export const uploadCertificate = (props: IUploadCertificate) => {
  const { onError, ...rest } = props;
  const params: IUploadCertificateParams = rest;
  const formData = new FormData();
  Object.keys(params).forEach((el: string) => {
    formData.append(el, params[el as any]);
  });
  return client
    .post('/system/certificates/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};

export const deleteCertificate = (props: IUpdateCertificate) => {
  const { item, onError } = props;
  return client
    .delete(`/system/certificates/files/${item.id}`)
    .then(() => {
      return true;
    })
    .catch(e => {
      onError && onError(e);
      return null;
    });
};

export const setDefaultCertificate = (props: IUpdateCertificate) => {
  const { item, onError } = props;

  return client
    .put(`/system/certificates/files/${item.id}/default`, { ...item })
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};
