import './archives.scss';

import { Button, Dialog, Placeholder, colorValue } from '@hai/ui-react';
import {
  IArchive,
  connectArchiveLocation,
  deleteArchiveLocation,
  disconnectArchiveLocation,
  getArchiveLocations
} from 'api/settings/archiveLocations';
import ActionBar, { Status } from 'components/actionBar/ActionBar';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { EnumFormButtonState } from 'components/common/form/formButton/formButton';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import NoContent from 'components/noItems/noContent';
import { AddArchive } from 'components/settings/archive/AddArchive';
import ArchiveList from 'components/settings/archive/ArchiveList';
import SettingsPage from 'components/settings/SettingsPage';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumArchivesAction } from 'context/settings/archives';
import { SettingsContext } from 'context/settings/settings';
import { t } from 'i18n';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useFormRef } from 'utils/hooks/useFormRef';
import { getArchiveStyle } from 'utils/settings';
import { actionStatus, getActionStatusColor } from 'utils/streaming';

const Archive = () => {
  const [show, setShow] = useState(false);
  const dataFetchedRef = useRef(false);
  const [buttonState, setButtonState] = useState(EnumFormButtonState.IDLE);
  const [initializing, setInitializing] = useState(false);

  const { settingsDispatch, archives, archivesClean, updatingArchives } =
    useContext(SettingsContext);
  const { dispatch } = useContext(GlobalContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const formControl = useFormRef();

  const statusTypes: Status[] = [
    {
      status: actionStatus.active,
      color: getActionStatusColor(actionStatus.active)
    },
    {
      status: actionStatus.inactive,
      color: getActionStatusColor(actionStatus.inactive)
    }
  ];

  useEffect(() => {
    if (updatingArchives?.length) {
      updatingArchives?.map((updatingItem: IArchive) => {
        const cleanItem = archivesClean?.find(
          (i: IArchive) => updatingItem.uuid === i.uuid
        ) as IArchive;
        if (updatingItem && updatingItem.status.state === cleanItem.status.state) {
        } else {
          settingsDispatch({
            type: EnumArchivesAction.REMOVE_UPDATING_ARCHIVE,
            payload: updatingItem.uuid
          });
        }
      });
    }
  }, [archivesClean, settingsDispatch, updatingArchives]);

  useEffect(() => {
    if (archivesClean?.length && updatingArchives?.length) {
      const interval = setInterval(() => {
        getArchiveLocations().then((value: IArchive[]) => {
          value &&
            settingsDispatch({ type: EnumArchivesAction.SET_ARCHIVES_CLEAN, payload: value });
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [archivesClean, settingsDispatch, updatingArchives]);

  useEffect(() => {
    if (!archivesClean && !dataFetchedRef.current) {
      setInitializing(true);
      dataFetchedRef.current = true;
      getArchiveLocations()
        .then((value: IArchive[]) => {
          value &&
            settingsDispatch({ type: EnumArchivesAction.SET_ARCHIVES_CLEAN, payload: value });
        })
        .finally(() => {
          setInitializing(false);
        });
    }
  }, [archivesClean, settingsDispatch]);

  const memoizedSetArchive = useCallback(
    (value: IArchive[]) => {
      settingsDispatch({ type: EnumArchivesAction.SET_ARCHIVES, payload: value });
    },
    [settingsDispatch]
  );

  const actionDelete = () => {
    const triggerDelete = async (archive: IArchive) => {
      const res = await deleteArchiveLocation({ item: archive });
      return res;
    };
    (async () => {
      if (archives) {
        const res = await Promise.all(archives.filter(i => i.selected).map(triggerDelete));
        if (res.filter(i => i.message).length) {
          triggerNotification('deleted', {
            name: archives.filter(i => i.selected)[0].name,
            count: res.filter(i => i.message).length
          });
        }

        getArchiveLocations().then((value: IArchive[]) => {
          value &&
            settingsDispatch({ type: EnumArchivesAction.SET_ARCHIVES_CLEAN, payload: value });
        });
      }
    })();
  };

  const triggerNotification = (notificationName: string, args?: any) => {
    notifyDispatch({
      type: EnumNotify.ADD_NOTIFICATION,
      payload: {
        type: 'Success',
        message: t(`SETTINGS.ARCHIVES.MESSAGES.${notificationName}`, args)
      }
    });
  };

  const onDeleteClick = () => {
    const selected = archives ? archives.filter(i => i.selected) : [];
    dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        onConfirm: actionDelete,
        title: t('SETTINGS.ARCHIVES.MODALS.deleteTitle', { count: selected?.length }),
        desc: t('SETTINGS.ARCHIVES.MODALS.deleteDesc', { count: selected?.length })
      }
    });
  };

  const onConnect = () => {
    const triggerConnect = async (item: IArchive) => {
      const res = await connectArchiveLocation({ item });
      const result = res.result;
      if (result) {
        settingsDispatch({ type: EnumArchivesAction.ADD_UPDATING_ARCHIVE, payload: item });
      }
      return result;
    };
    (async () => {
      if (archives) {
        const res = await Promise.all(
          archives.filter(i => i.selected && i.status.state === 'disconnected').map(triggerConnect)
        );
        triggerNotification('connect', {
          count: res.filter(i => i === true).length,
          name: archives.filter(i => i.selected && i.status.state === 'disconnected')[0].name
        });
      }
    })();
  };

  const onDisconnect = () => {
    const triggerDisconnect = async (item: IArchive) => {
      const res = await disconnectArchiveLocation({ item });
      const result = res.result;
      if (result) {
        settingsDispatch({ type: EnumArchivesAction.ADD_UPDATING_ARCHIVE, payload: item });
      }
    };
    (async () => {
      if (archives) {
        await Promise.all(
          archives.filter(i => i.selected && i.status.state === 'connected').map(triggerDisconnect)
        );
        triggerNotification('disconnect', {
          count: archives.filter(i => i.selected && i.status.state === 'connected').length,
          name: archives.filter(i => i.selected && i.status.state === 'connected')[0].name
        });
      }
    })();
  };

  const onSelectAll = (selected: boolean | 'indeterminate' | 'none') => {
    return settingsDispatch({ type: EnumArchivesAction.SELECT_ALL, payload: selected === false });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <SettingsPage>
      <FadeIn
        visible={!!archives?.length || !initializing}
        suspenseComponent={
          <div className='list-placeholder-container'>
            <Placeholder.ListActionBar
              numToggleButtons={2}
              numRightComponents={1}
              numDropdowns={0}
            />
            <Placeholder.List showThumbnail={false} numActions={3} />
          </div>
        }
      >
        {archivesClean?.length !== 0 && (
          <ActionBar
            getStatus={getArchiveStyle}
            statusTypes={statusTypes}
            items={archives}
            cleanItems={archivesClean}
            setItems={memoizedSetArchive}
            onSelectAll={onSelectAll}
            ActionButtons={
              <>
                <Button onClick={onConnect}>{t('connect')}</Button>
                <Button onClick={onDisconnect}>{t('disconnect')}</Button>
                <Button onClick={onDeleteClick}>{t('delete')}</Button>
              </>
            }
            actionRightPrimary={{
              onClick: handleShow,
              children: t('SETTINGS.ARCHIVES.add')
            }}
          />
        )}
        <ArchiveList list={archives || []} />

        {archivesClean?.length === 0 && (
          <NoContent
            buttonText={t('SETTINGS.ARCHIVES.add')}
            buttonAction={handleShow}
            detailsText={t('SETTINGS.ARCHIVES.createArchive')}
            iconName='Storage'
            noItemMsg={t('SETTINGS.ARCHIVES.noArchive')}
          />
        )}
      </FadeIn>
      <Dialog
        title={t('SETTINGS.ARCHIVES.add')}
        size='md'
        dialogType='activity'
        accentColor={colorValue('haiui-aqua-01')}
        headerIcon='Database'
        content={
          <AddArchive
            setButtonState={setButtonState}
            buttonState={buttonState}
            formControl={formControl}
            onSuccess={handleClose}
          />
        }
        show={show}
        onClose={handleClose}
        buttons={[
          { variant: 'secondary', onClick: handleClose, label: t('cancel') },
          {
            variant: 'primary',
            close: false,
            onClick: () => submitHandler(formControl.formRef),
            disabled: !formControl.formSubmitActive,
            state: buttonState,
            label: t('SETTINGS.ARCHIVES.add')
          }
        ]}
      />
    </SettingsPage>
  );
};

export default Archive;
