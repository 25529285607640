import { Button, Dialog, colorValue } from '@hai/ui-react';
import { IOutputItem } from 'api/outputs/output.type';
import { deleteOutput } from 'api/outputs/outputs';
import ActionBar, { Status } from 'components/actionBar/ActionBar';
import SortDropdown from 'components/actionBar/SortDropdown';
import { EnumSortDirection, EnumSortType } from 'components/actionBar/SortManager';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import NoContent from 'components/noItems/noContent';
import { AddOutput } from 'components/streaming/outputs/AddOutput';
import OutputList from 'components/streaming/outputs/OutputList';
import StreamingPage from 'components/streaming/StreamingPage';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumOutputsAction } from 'context/streaming/outputs';
import { StreamingContext } from 'context/streaming/streaming';
import { t } from 'i18n';
import { RoleContext } from 'permissions/role/Roles';
import React, { useCallback, useContext, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import constant from 'utils/constant';
import { useFormRef } from 'utils/hooks/useFormRef';
import { useGetOutputs } from 'utils/hooks/useGetOutputs';
import { useGetPresets } from 'utils/hooks/useGetPresets';
import { useGetStreams } from 'utils/hooks/useGetStreams';
import { useOutputInfo } from 'utils/hooks/useOutputInfo';
import { actionStatus, convertActionTypeForSort, getActionStatusColor } from 'utils/streaming';

const Outputs = () => {
  const { outputs, outputsClean, streamingDispatch } = useContext(StreamingContext);
  const { dispatch } = useContext(GlobalContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const role = useContext(RoleContext);

  const getOutputInfo = useOutputInfo();
  const getOutputsService = useGetOutputs();
  const getPresetsService = useGetPresets();
  const getStreamsService = useGetStreams();

  const [buttonState, setButtonState] = useState('idle');
  const [show, setShow] = useState(false);
  const [direction, setDirection] = useState(EnumSortDirection.ASCENDING);
  const [sorting, setSorting] = useState<string>('name');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [newItem, setNewItems] = useState('');
  const [actionFilter, setActionFilter] = useState('None');

  const [isSearchOn, setIsSearchOn] = useState(false);

  useInterval(() => {
    if (!isSearchOn) {
      getStreamsService();
      getOutputsService();
    }
  }, constant.stream.update);

  const formControl = useFormRef();

  const statusTypes: Status[] = [
    {
      status: actionStatus.online,
      color: getActionStatusColor(actionStatus.active)
    },
    {
      status: actionStatus.alert,
      color: getActionStatusColor(actionStatus.alert)
    },
    {
      status: actionStatus.inactive,
      color: getActionStatusColor(actionStatus.inactive)
    }
  ];

  const memoizedSetItems = useCallback(
    (value: any) => {
      streamingDispatch({ type: EnumOutputsAction.SET_OUTPUTS, payload: value });
    },
    [streamingDispatch]
  );

  const actionDelete = () => {
    const triggerDelete = async (output: IOutputItem) => {
      const result = await deleteOutput({ output });
      getPresetsService();

      return { result, item: output };
    };
    (async () => {
      if (outputs) {
        await Promise.all(outputs.filter(i => i.selected).map(triggerDelete)).then(values => {
          const successItems = values.filter(x => x.result);
          if (successItems && successItems.length !== 0) {
            notifyDispatch({
              type: EnumNotify.ADD_NOTIFICATION,
              payload: {
                type: 'Success',
                // groupId: 'deleteStream',
                message: {
                  name: 'STREAMING.OUTPUTS.MESSAGES.deleted',
                  params: { name: values[0].item.name },
                  items: values
                }
              }
            });
          } else {
            // TODO: Show error message if deleting failed.
          }
        });
        getOutputsService();
      }
    })();
  };

  const onDeleteClick = () => {
    const count = outputs?.filter(i => i.selected).length;
    dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        onConfirm: actionDelete,
        title: t('STREAMING.OUTPUTS.DELETE_MODAL.title', { count }),
        desc: t('STREAMING.OUTPUTS.DELETE_MODAL.desc', { count })
      }
    });
  };

  const saveCallback = (item: any) => {
    getOutputsService().then(() => {
      setNewItems(item);
      setButtonState('idle');
      handleClose();
      getPresetsService();
    });
  };

  const sortInfo = {
    name: { title: t('SORTING.OUTPUT.name'), type: EnumSortType.NATURAL_SORT },
    status: {
      title: t('SORTING.OUTPUT.status'),
      type: EnumSortType.CUSTOM_FUNCTION,
      comparator: (a: any, b: any, dirVal: any) => {
        const aa = getOutputInfo(a);
        const bb = getOutputInfo(b);

        // convert actionType string to number for sorting
        let aNum = convertActionTypeForSort(aa.actionType);
        let bNum = convertActionTypeForSort(bb.actionType);

        let result = 0; // equal
        if (aNum > bNum) {
          result = 1;
        } else if (aNum < bNum) {
          result = -1;
        }
        if (dirVal < 0) {
          if (result === -1) {
            return 1;
          } else if (result === 1) {
            return -1;
          }
        }
        return result as 0 | 1 | -1;
      }
    },
    connection: { title: t('SORTING.OUTPUT.destination'), type: EnumSortType.NATURAL_SORT }
  };

  const onChangeSort = (sections: any) => {
    if (sections.sortBy?.eventKey) {
      setSorting(sections.sortBy?.eventKey);
    }

    if (sections.direction?.eventKey === 'ascending') {
      setDirection(EnumSortDirection.ASCENDING);
    } else if (sections.direction?.eventKey === 'descending') {
      setDirection(EnumSortDirection.DESCENDING);
    } else {
      setDirection(EnumSortDirection.IDLE);
    }
  };

  const onSelectAll = (selected: boolean | 'indeterminate' | 'none') => {
    return streamingDispatch({ type: EnumOutputsAction.SELECT_ALL, payload: selected === false });
  };

  const onSearch = (searchItems: any) => {
    setIsSearchOn(!!searchItems.length);
  };

  const isNoSearchResult = isSearchOn && !outputs?.length;

  const actionButtons = role.can('delete', 'outputs')
    ? {
        ActionButtons: <Button onClick={onDeleteClick}>{t('delete')}</Button>
      }
    : {};

  return (
    <StreamingPage>
      {outputsClean?.length !== 0 && (
        <ActionBar
          cleanItems={outputsClean}
          getStatus={getOutputInfo}
          items={outputs}
          onSearch={onSearch}
          onSelectAll={onSelectAll}
          searchKeys={['name', 'connection']}
          setActionFilter={setActionFilter}
          setItems={memoizedSetItems}
          statusTypes={statusTypes}
          {...actionButtons}
          {...(role.can('add', 'outputs')
            ? {
                actionRightPrimary: {
                  onClick: handleShow,
                  children: t('STREAMING.OUTPUTS.addOutput')
                }
              }
            : {})}
          sortDropdown={
            <SortDropdown
              direction={direction}
              title={sorting}
              onChangeSort={onChangeSort}
              sortInfo={sortInfo}
              sorting={sorting}
            />
          }
        />
      )}
      <Dialog
        data-auto='add_output_modal'
        title={t('STREAMING.OUTPUTS.addOutput')}
        size='lg'
        dialogType='activity'
        accentColor={colorValue('haiui-aqua-01')}
        headerIcon='Output'
        content={
          <AddOutput
            buttonState={buttonState}
            setButtonState={setButtonState}
            saveCallback={saveCallback}
            formControl={formControl}
          />
        }
        show={show}
        onClose={handleClose}
        buttons={[
          { variant: 'secondary', onClick: handleClose, label: t('cancel') },
          {
            variant: 'primary',
            close: false,
            state: buttonState,
            disabled: !formControl.formSubmitActive,
            onClick: () => submitHandler(formControl.formRef, notifyDispatch),
            label: t('STREAMING.OUTPUTS.addOutput')
          }
        ]}
      />

      {outputs && (
        <OutputList
          direction={direction}
          hideCheckbox={!!actionButtons?.ActionButtons}
          sorting={sorting}
          sortInfo={sortInfo}
          list={outputs}
          selectedFilter={actionFilter}
          newItem={newItem}
          saveCallback={saveCallback}
        />
      )}
      {outputsClean?.length === 0 && (
        <NoContent
          buttonText={t('STREAMING.OUTPUTS.addOutput')}
          buttonAction={handleShow}
          detailsText={t('STREAMING.OUTPUTS.createOutputs')}
          iconName='Output'
          noItemMsg={t('STREAMING.OUTPUTS.noOutputs')}
        />
      )}
      {isNoSearchResult && <NoContent iconName='Search' noItemMsg={t('noSearchResult')} />}
    </StreamingPage>
  );
};

export default Outputs;
