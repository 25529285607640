import { DashboardProvider } from 'context/dashboard';
import { DevicesProvider } from 'context/devices';
import { GlobalProvider } from 'context/global';
import { NetworkProvider } from 'context/network';
import { NotifyProvider } from 'context/notify';
import { PresetProvider } from 'context/preset';
import { SecurityProvider } from 'context/security/security';
import { SettingsProvider } from 'context/settings/settings';
import { StreamingProvider } from 'context/streaming/streaming';
import { RoleProvider } from 'permissions/role/Roles';
import React from 'react';

import { AuthProvider } from './auth';

interface GlobalProviderProps {
  children: React.ReactNode;
}
export const Context = ({ children }: GlobalProviderProps) => {
  return (
    <AuthProvider>
      <PresetProvider>
        <RoleProvider>
          <GlobalProvider>
            <SecurityProvider>
              <SettingsProvider>
                <NetworkProvider>
                  <DevicesProvider>
                    <NotifyProvider>
                      <DashboardProvider>
                        <StreamingProvider>{children}</StreamingProvider>
                      </DashboardProvider>
                    </NotifyProvider>
                  </DevicesProvider>
                </NetworkProvider>
              </SettingsProvider>
            </SecurityProvider>
          </GlobalProvider>
        </RoleProvider>
      </PresetProvider>
    </AuthProvider>
  );
};
