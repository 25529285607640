import { IGeneralApiProps } from 'api/global.type';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import { htmlDecode } from 'utils/formatText';

import client, { handleErrors } from '../utils/client';

export const PRESET_STARTUP_CURRENT_ID = 'CURRENT';

export interface IPresetItem {
  active: boolean;
  lastModified: number;
  name: any;
  uuid: string;
  startup: boolean;
  selected?: boolean;
}
export interface IPresets {
  activeUuid?: string;
  configList?: IPresetItem[];
  currentUuid?: String;
  autosave: boolean;
  unsavedChanges: boolean;
  useCurrentForStartup: boolean;
}

export const getPresets = (props: IGeneralApiProps) => {
  const { onError } = props;
  return client
    .get('/presets')
    .then(resp => {
      return apiToFormdata(resp.data);
    })
    .catch(e => {
      onError && onError(e);
      return e;
    });
};

interface IUploadPreset extends IGeneralApiProps {
  apiParams: { file: any };
}

export const importPreset = (props: IUploadPreset) => {
  const { apiParams } = props;

  handleErrors(props);
  const formData = new FormData();
  formData.append('file', apiParams.file);
  return client
    .post('/presets', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(resp => {
      return resp.data;
    })
    .catch(_e => {
      return { error: true, name: apiParams.file.name };
    });
};

interface IAPreset extends IGeneralApiProps {
  apiParams: { name?: string; uuid?: string };
}

export const addPreset = (props: IAPreset) => {
  const { apiParams, onError } = props;
  return client
    .post('/presets', apiParams)
    .then(resp => {
      return apiToFormdata({ configList: [resp.data] });
    })
    .catch(e => {
      onError && onError(e);
      return e;
    });
};

interface ILoadPresetProps extends IGeneralApiProps {
  apiParams: ILoadPreset;
}

interface ILoadPreset {
  name?: string;
  startup?: boolean;
  uuid?: string;
}

export const duplicatePreset = (props: ILoadPresetProps) => {
  const { apiParams, onError } = props;

  return client
    .put(`/presets/${apiParams.uuid}/duplicate`, formdataToApi(apiParams))
    .then(resp => {
      if ([204, 200].indexOf(resp.status) !== -1) {
        return { success: true };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

export const renamePreset = async (props: ILoadPresetProps) => {
  const { apiParams, onError } = props;

  return client
    .put(`/presets/${apiParams.uuid}/rename`, formdataToApi(apiParams))
    .then(resp => {
      if (resp.status === 200) {
        return { success: true };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

export const startupPreset = (props: ILoadPresetProps) => {
  const { apiParams, onError } = props;

  return client
    .put(`/presets/${apiParams.uuid}/startup`, { startup: true })
    .then(resp => {
      if (resp.status === 200) {
        return { success: true };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

interface IAutosavePreset extends IGeneralApiProps {
  apiParams: { autosave: Boolean };
}

export const autosavePreset = (props: IAutosavePreset) => {
  const { apiParams, onError } = props;

  return client
    .put(`/presets/autosave`, { autosave: apiParams.autosave })
    .then(resp => {
      if ([200, 204].indexOf(resp.status) !== -1) {
        return { success: true };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

export const savePreset = async (props: ILoadPresetProps) => {
  const { apiParams, onError } = props;

  return await client
    .put(`/presets/${apiParams.uuid}`, formdataToApi(apiParams))
    .then(resp => {
      if ([200, 204].indexOf(resp.status) !== -1) {
        return { success: true };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

export const loadPreset = (props: ILoadPresetProps) => {
  const { apiParams, onError } = props;

  return client
    .put(`/presets/${apiParams.uuid}/load`, formdataToApi(apiParams))
    .then(resp => {
      if (resp.status === 204) {
        return { success: true };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

export const editPreset = async (props: IAPreset) => {
  const { apiParams, onError } = props;

  return client
    .put(`/presets/${apiParams.uuid}`, apiParams)
    .then(resp => {
      if (resp.status === 204) {
        return { success: true };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

export const exportPreset = (props: IAPreset): any => {
  const { apiParams, onError } = props;

  return client
    .get(`/presets/${apiParams.uuid}?format=text`)
    .then(resp => {
      if (resp.status === 200) {
        return { ...resp, success: true };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

export const deletePreset = async (props: IAPreset) => {
  const { apiParams, onError } = props;

  const resp = await client
    .delete(`/presets/${apiParams.uuid}`)
    .then(resp => {
      if (resp.status === 204) {
        return { ...resp, success: true };
      } else {
        onError && onError(resp);
        return { ...resp, success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { status: 404, success: false };
    });
  return resp;
};

const formdataToApi = (apiData: ILoadPreset) => {
  if (!apiData.name) {
    apiData.name = t('PRESETS.untitledPreset');
  }
  return {
    name: apiData.name?.slice(-4) === '.cfg' ? apiData.name : `${apiData.name}.cfg`,
    uuid: apiData.uuid
  };
};

const apiToFormdata = (apiData: any) => {
  apiData.configList = apiData.configList.map((i: IPresetItem) => {
    return {
      uuid: i.uuid,
      active: i.uuid === apiData.currentUuid,
      lastModified: i.lastModified,
      modifiedHuman:
        i.lastModified <= 0
          ? ''
          : DateTime.fromSeconds(i.lastModified).toLocaleString(DateTime.DATETIME_MED),
      name: htmlDecode(i.name.replace(/\.[^/.]+$/, '')),
      startup: i.uuid === apiData.activeUuid
    };
  });
  return apiData;
};
