import './accounts.scss';

import { Button, Dialog, Placeholder, colorValue } from '@hai/ui-react';
import {
  EnumAccountRole,
  IAccount,
  deleteAccount,
  disableAccount,
  enableAccount,
  getAccounts
} from 'api/accounts/account';
import { getLicense } from 'api/license/license';
import ActionBar, { Status } from 'components/actionBar/ActionBar';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { EnumFormButtonState } from 'components/common/form/formButton/formButton';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import AccountList from 'components/security/accounts/AccountList';
import { AddAccount } from 'components/security/accounts/AddAccount';
import SettingsPage from 'components/settings/SettingsPage';
import { useAuthContext } from 'context';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumAccountsAction } from 'context/security/accounts';
import { SecurityContext } from 'context/security/security';
import { t } from 'i18n';
import { RoleContext } from 'permissions/role/Roles';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useCache } from 'utils/hooks/useCache';
import { useFormRef } from 'utils/hooks/useFormRef';
import { getAccountStyle } from 'utils/security';
import { actionStatus, getActionStatusColor } from 'utils/streaming';

const Accounts = () => {
  const { status } = useCache({
    apiEndpoint: getLicense,
    url: '/license'
  });
  const [show, setShow] = useState(false);
  const [buttonState, setButtonState] = useState(EnumFormButtonState.IDLE);
  const dataFetchedRef = useRef(false);
  const { securityDispatch, accounts, accountsClean } = useContext(SecurityContext);
  const { dispatch } = useContext(GlobalContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const formControl = useFormRef();
  const role = useContext(RoleContext);
  const [auth] = useAuthContext();
  const [initializing, setInitializing] = useState(false);
  const { group, userId, username, expiration } = auth;

  const statusTypes: Status[] = [
    {
      status: actionStatus.active,
      color: getActionStatusColor(actionStatus.active)
    },
    {
      status: actionStatus.expiring,
      color: getActionStatusColor(actionStatus.alert)
    },
    {
      status: actionStatus.disabled,
      color: getActionStatusColor(actionStatus.disabled)
    },
    {
      status: actionStatus.locked,
      color: getActionStatusColor(actionStatus.locked)
    }
  ];

  const selectedAccounts = accounts ? accounts.filter(i => i.selected) : [];
  const disableStatusButton = selectedAccounts.length === 1 && selectedAccounts[0]?.id === userId;

  const canCallApiAccounts = role.can('call', 'api/accounts');

  useEffect(() => {
    if (!accountsClean?.length && canCallApiAccounts && !dataFetchedRef.current) {
      dataFetchedRef.current = true;
      getAccounts().then((value: IAccount[]) => {
        value && securityDispatch({ type: EnumAccountsAction.SET_ACCOUNTS_CLEAN, payload: value });
      });
    }
  }, [accountsClean, securityDispatch, canCallApiAccounts, status]);

  useEffect(() => {
    if (status === 'fetched') {
      setInitializing(false);
    } else {
      setInitializing(true);
    }
  }, [status]);

  const memoizedSetAccounts = useCallback(
    (value: IAccount[]) => {
      securityDispatch({ type: EnumAccountsAction.SET_ACCOUNTS, payload: value });
    },
    [securityDispatch]
  );

  const actionDelete = () => {
    const triggerDelete = async (account: IAccount) => {
      const res = await deleteAccount({ item: account });
      if (res.data) {
        notifyDispatch({
          type: EnumNotify.ADD_NOTIFICATION,
          payload: {
            type: 'Success',
            message: t('SECURITY.ACCOUNTS.MESSAGES.deleted', { name: account.username })
          }
        });
      }
    };
    (async () => {
      if (accounts) {
        await Promise.all(accounts.filter(i => i.selected && i.id !== userId).map(triggerDelete));
        getAccounts().then((value: IAccount[]) => {
          value &&
            securityDispatch({ type: EnumAccountsAction.SET_ACCOUNTS_CLEAN, payload: value });
        });
      }
    })();
  };

  const onDeleteClick = () => {
    dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        onConfirm: actionDelete,
        title: t('SECURITY.ACCOUNTS.MODALS.deleteTitle', { count: selectedAccounts.length }),
        desc: t('SECURITY.ACCOUNTS.MODALS.deleteDesc', { count: selectedAccounts.length })
      }
    });
  };

  const onEnableClick = () => {
    const triggerDelete = async (account: IAccount) => {
      const res = await enableAccount({ item: account });
      if (res.result) {
        notifyDispatch({
          type: EnumNotify.ADD_NOTIFICATION,
          payload: {
            type: 'Success',
            message: t('SECURITY.ACCOUNTS.MESSAGES.enabled')
          }
        });
      }
    };
    (async () => {
      if (accounts) {
        await Promise.all(accounts.filter(i => i.selected).map(triggerDelete));
        getAccounts().then((value: IAccount[]) => {
          value &&
            securityDispatch({ type: EnumAccountsAction.SET_ACCOUNTS_CLEAN, payload: value });
        });
      }
    })();
  };

  const onDisableClick = () => {
    const triggerDelete = async (account: IAccount) => {
      const res = await disableAccount({ item: account });
      if (res.result) {
        notifyDispatch({
          type: EnumNotify.ADD_NOTIFICATION,
          payload: {
            type: 'Success',
            message: t('SECURITY.ACCOUNTS.MESSAGES.disabled')
          }
        });
      }
    };
    (async () => {
      if (accounts) {
        await Promise.all(accounts.filter(i => i.selected).map(triggerDelete));
        getAccounts().then((value: IAccount[]) => {
          value &&
            securityDispatch({ type: EnumAccountsAction.SET_ACCOUNTS_CLEAN, payload: value });
        });
      }
    })();
  };

  const onSelectAll = (selected: boolean | 'indeterminate' | 'none') => {
    return securityDispatch({ type: EnumAccountsAction.SELECT_ALL, payload: selected === false });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const canEdit = role.can('edit', 'accounts');

  const currentAccount: IAccount = {
    id: userId || '',
    username: username || '',
    expiration: expiration || undefined,
    role: group as EnumAccountRole,
    state: 'active'
  };
  return (
    <SettingsPage>
      <FadeIn
        visible={!initializing}
        suspenseComponent={
          <div className='list-placeholder-container'>
            <Placeholder.ListActionBar
              numToggleButtons={3}
              numRightComponents={1}
              numDropdowns={0}
            />
            <Placeholder.List showThumbnail={false} numActions={2} />
          </div>
        }
      >
        {canEdit && (
          <ActionBar
            getStatus={getAccountStyle}
            statusTypes={statusTypes}
            items={accounts}
            cleanItems={accountsClean}
            setItems={memoizedSetAccounts}
            onSelectAll={onSelectAll}
            ActionButtons={
              <>
                <Button onClick={onEnableClick} disabled={disableStatusButton}>
                  {t('enable')}
                </Button>
                <Button onClick={onDisableClick} disabled={disableStatusButton}>
                  {t('disable')}
                </Button>
                <Button onClick={onDeleteClick} disabled={disableStatusButton}>
                  {t('delete')}
                </Button>
              </>
            }
            actionRightPrimary={{
              onClick: handleShow,
              children: t('SECURITY.ACCOUNTS.FORMS.addAccount')
            }}
          />
        )}

        {canEdit ? <AccountList list={accounts || []} /> : <AccountList list={[currentAccount]} />}
      </FadeIn>

      {canEdit && (
        <Dialog
          title={t('SECURITY.ACCOUNTS.FORMS.addAccount')}
          size='md'
          dialogType='activity'
          accentColor={colorValue('haiui-aqua-01')}
          headerIcon='User'
          content={
            <AddAccount
              setButtonState={setButtonState}
              buttonState={buttonState}
              formControl={formControl}
              onSuccess={handleClose}
            />
          }
          show={show}
          onClose={handleClose}
          buttons={[
            { variant: 'secondary', onClick: handleClose, label: t('cancel') },
            {
              variant: 'primary',
              close: false,
              onClick: () => submitHandler(formControl.formRef),
              disabled: !formControl.formSubmitActive,
              state: buttonState,
              label: t('SECURITY.ACCOUNTS.FORMS.addAccount')
            }
          ]}
          dataAuto='add_account_modal'
        />
      )}
    </SettingsPage>
  );
};

export default Accounts;
