import { t } from 'i18next';

// TODO: refactor onError with global notification
type IOnError = Function;
export interface IGeneralApiProps {
  onError?: IOnError;
  ignoreErrors?: boolean;
  ignoreError?: number[];
  disableNormalizing?: boolean;
}

export enum EnumTransportType {
  AUTO = 'auto',
  UDP = 'UDP',
  TCP = 'TCP',
  UDP_MULTICAST = 'UDP Multicast',
  HTTPS = 'HTTPS'
}

export const transportOptions = [
  {
    value: '',
    option: t(EnumTransportType.AUTO)
  },
  {
    value: 'udp',
    option: EnumTransportType.UDP
  },
  {
    value: 'tcp',
    option: EnumTransportType.TCP
  },
  {
    value: 'udpMulticast',
    option: EnumTransportType.UDP_MULTICAST
  },
  {
    value: 'http',
    option: EnumTransportType.HTTPS
  }
];

export enum EnumRtmpModeType {
  CLIENT = 'STREAMING.INPUTS.ADD.RTMP_MODE.client',
  SERVER = 'STREAMING.INPUTS.ADD.RTMP_MODE.server'
}

export const rtmpModeOptions = [
  {
    value: 'client',
    option: t(EnumRtmpModeType.CLIENT)
  },
  {
    value: 'server',
    option: t(EnumRtmpModeType.SERVER)
  }
];

export enum EnumSrtModeType {
  CALLER = 'STREAMING.INPUTS.ADD.SRT_MODE.caller',
  LISTENER = 'STREAMING.INPUTS.ADD.SRT_MODE.listener',
  RENDEZVOUS = 'STREAMING.INPUTS.ADD.SRT_MODE.rendezvous'
}

export const srtModeOptions = [
  {
    value: 'caller',
    option: t(EnumSrtModeType.CALLER)
  },
  {
    value: 'listener',
    option: t(EnumSrtModeType.LISTENER)
  },
  {
    value: 'rendezvous',
    option: t(EnumSrtModeType.RENDEZVOUS)
  }
];

enum EnumSrtEncryptionType {
  DEFAULT = 'STREAMING.OUTPUTS.ADD.ENCRYPT.none',
  AES128 = 'STREAMING.OUTPUTS.ADD.ENCRYPT.aes128',
  AES256 = 'STREAMING.OUTPUTS.ADD.ENCRYPT.aes256'
}
export const srtEncryptionOptions = [
  {
    value: '',
    option: t(EnumSrtEncryptionType.DEFAULT)
  },
  {
    value: 'aes128',
    option: t(EnumSrtEncryptionType.AES128)
  },
  {
    value: 'aes256',
    option: t(EnumSrtEncryptionType.AES256)
  }
];

export enum EnumStreamModeType {
  TRANSCODER = 'STREAMING.STREAMS.ADD.MODE.transcoder',
  PASSTHRU = 'STREAMING.STREAMS.ADD.MODE.passthru',
  BYPASS = 'STREAMING.STREAMS.ADD.MODE.bypass'
}

export const streamModeOptions = (editMode: boolean, mode: string) => {
  return [
    ...(editMode && mode === 'iorouter'
      ? []
      : [
          {
            option: t(EnumStreamModeType.TRANSCODER),
            value: 'transcoder',
            licensedFeature: true,
            licensedName: 'useTranscoders'
          },
          {
            option: t(EnumStreamModeType.PASSTHRU),
            value: 'passthru',
            licensedFeature: true,
            licensedName: 'useTranscoders'
          }
        ]),
    editMode && ['passthru', 'transcoder'].indexOf(mode) !== -1
      ? {}
      : {
          option: t(EnumStreamModeType.BYPASS),
          value: 'iorouter'
        }
  ];
};

enum EnumProtocolI18next {
  DEFAULT = 'STREAMING.OUTPUTS.ADD.PROTOCOL.TS_over_UDP',
  TS_over_SRT = 'STREAMING.OUTPUTS.ADD.PROTOCOL.TS_over_SRT',
  RTMP = 'STREAMING.OUTPUTS.ADD.PROTOCOL.RTMP',
  RTSP = 'STREAMING.OUTPUTS.ADD.PROTOCOL.RTSP',
  TS_SEGMENT = 'STREAMING.OUTPUTS.ADD.PROTOCOL.TS_SEGMENT',
  HLS = 'STREAMING.OUTPUTS.ADD.PROTOCOL.HLS'
}

export enum EnumProtocolType {
  MPEG2TS = 'MPEG2TS',
  SRT = 'SRT',
  RTMP = 'RTMP',
  RTSP = 'RTSP',
  TS_SEGMENT_ARCHIVE = 'TS_SEGMENT_ARCHIVE',
  HLS = 'HLS'
}

export const protocolOptions = [
  {
    value: 'MPEG2TS',
    option: t(EnumProtocolI18next.DEFAULT)
  },
  {
    value: 'SRT',
    option: t(EnumProtocolI18next.TS_over_SRT)
  },
  {
    value: 'RTMP',
    option: t(EnumProtocolI18next.RTMP)
  },

  {
    value: 'RTSP',
    option: t(EnumProtocolI18next.RTSP)
  },
  {
    value: 'TS_SEGMENT_ARCHIVE',
    licensedFeature: true,
    licensedName: 'RECORDING',
    option: t(EnumProtocolI18next.TS_SEGMENT)
  },
  {
    value: 'HLS',
    option: t(EnumProtocolI18next.HLS)
  }
];

export const segmentFormat = [
  {
    value: 'MPEG2TS',
    option: 'MPEG2TS'
  },
  {
    value: 'FMP4',
    option: 'FMP4'
  }
];

export const rtspDirection = [
  {
    value: 'PUSH',
    option: 'PUSH'
  },
  {
    value: 'PULL',
    option: 'PULL'
  }
];

export enum EnumSourceType {
  TS_over_UDP = 'STREAMING.INPUTS.ADD.SOURCE.TS_over_UDP',
  TS_over_SRT = 'STREAMING.INPUTS.ADD.SOURCE.TS_over_SRT',
  RTSP = 'STREAMING.INPUTS.ADD.SOURCE.RTSP',
  RAW_Motion_JPEG = 'STREAMING.INPUTS.ADD.SOURCE.RAW_Motion_JPEG',
  RAW_MJPEG_HTTP = 'STREAMING.INPUTS.ADD.SOURCE.MJPEG_HTTP',
  RTMP = 'STREAMING.INPUTS.ADD.SOURCE.RTMP',
  ST2110 = 'STREAMING.INPUTS.ADD.SOURCE.ST2110',
  GigE_Vision = 'STREAMING.INPUTS.ADD.SOURCE.GigE_Vision',
  NDI = 'STREAMING.INPUTS.ADD.SOURCE.NDI'
}

export const sourcesOptions = [
  {
    value: 'MPEG2TS',
    option: t(EnumSourceType.TS_over_UDP)
  },
  {
    value: 'SRT',
    option: t(EnumSourceType.TS_over_SRT)
  },
  {
    value: 'RTSP',
    option: t(EnumSourceType.RTSP)
  },
  {
    value: 'MJPEGRAW',
    option: t(EnumSourceType.RAW_Motion_JPEG)
  },
  {
    value: 'MJPEGHTTP',
    option: t(EnumSourceType.RAW_MJPEG_HTTP)
  },
  {
    value: 'RTMP',
    option: t(EnumSourceType.RTMP)
  },
  {
    value: 'ST2110',
    licensedFeature: true,
    option: t(EnumSourceType.ST2110)
  },
  {
    value: 'GIGEVIS',
    licensedFeature: true,
    option: t(EnumSourceType.GigE_Vision)
  },
  {
    value: 'NDI',
    licensedFeature: true,
    option: t(EnumSourceType.NDI)
  }
];

export enum EnumEncoderType {
  SOFTWARE = 'STREAMING.TRANSCODERS.ADD.ENCODER.software',
  HARDWARE = 'STREAMING.TRANSCODERS.ADD.ENCODER.hardware'
}

export const encoderOptions = [
  {
    option: t(EnumEncoderType.SOFTWARE),
    value: 'unset'
  },
  {
    option: t(EnumEncoderType.HARDWARE),
    value: 'qsv',
    licensedFeature: true,
    licensedName: 'useHardwareEncoder'
  }
];

export enum EnumFormatType {
  H264 = 'H.264 (AVC)',
  H265 = 'H.265 (HEVC)',
  MPEG4 = 'MPEG-4 (Part 2)',
  MPEG2 = 'MPEG-2'
}

export const formatShort: any = {
  unset: 'H264',
  avc: 'H264',
  hevc: 'HEVC',
  mpeg2video: 'MPEG2',
  mpeg4: 'MPEG4'
};

export const formatOptions = (encoderType: string = '') => [
  {
    option: EnumFormatType.H264,
    value: 'avc'
  },
  {
    option: EnumFormatType.H265,
    licensedFeature: true,
    licensedName: 'HEVC',
    value: 'hevc'
  },
  {
    option: EnumFormatType.MPEG4,
    value: 'mpeg4',
    disabled: encoderType === 'qsv'
  },
  {
    option: EnumFormatType.MPEG2,
    value: 'mpeg2video',
    licensedName: 'mpeg2video'.toUpperCase(),
    licensedFeature: true,
    disabled: encoderType === 'qsv'
  }
];

export enum EnumCodecType {
  AUTO = 'STREAMING.TRANSCODERS.CODEC_PROFILE.auto',
  SIMPLE = 'STREAMING.TRANSCODERS.CODEC_PROFILE.simple',
  MAIN = 'STREAMING.TRANSCODERS.CODEC_PROFILE.main'
}

export const codecOptions = [
  {
    option: t(EnumCodecType.AUTO),
    value: 'unset'
  },
  {
    option: t(EnumCodecType.SIMPLE),
    value: 'simple'
  },
  {
    option: t(EnumCodecType.MAIN),
    value: 'main'
  }
];

export enum EnumResolutionType {
  AUTO = 'STREAMING.TRANSCODERS.ADD.RESOLUTION_MODE.auto',
  START = 'STREAMING.TRANSCODERS.ADD.RESOLUTION_MODE.start',
  CUSTOM = 'STREAMING.TRANSCODERS.ADD.RESOLUTION_MODE.custom'
}
export const resolutionOptions = [
  {
    option: t(EnumResolutionType.AUTO),
    value: '3'
  },
  {
    option: t(EnumResolutionType.START),
    value: '2'
  },
  {
    option: t(EnumResolutionType.CUSTOM),
    value: '1'
  }
];

export enum EnumFramingType {
  AUTO = 'Auto',
  IP = 'IP',
  IBP = 'IBP',
  IBBP = 'IBBP',
  IBBBP = 'IBBBP'
}
export const framingOptions = [
  {
    option: EnumFramingType.AUTO,
    value: -1
  },
  {
    option: EnumFramingType.IP,
    value: 0
  },
  {
    option: EnumFramingType.IBP,
    value: 1
  },
  {
    option: EnumFramingType.IBBP,
    value: 2
  },
  {
    option: EnumFramingType.IBBBP,
    value: 3
  }
];

export enum EnumAudioType {
  AUTO = 'Auto',
  MPEG1 = 'MPEG1 Layer II',
  MPEG2 = 'MPEG2 AAC ADTS'
}
export const audioOptions = [
  {
    option: EnumAudioType.AUTO,
    value: 'unset'
  },
  {
    option: EnumAudioType.MPEG1,
    value: 'mpeg1_layer2'
  },
  {
    option: EnumAudioType.MPEG2,
    value: 'mpeg2_aac_adts'
  }
];

export enum EnumKlvTagsType {
  CUSTOM = 'STREAMING.TRANSCODERS.ADD.TAG_SETS.custom',
  NONE = 'STREAMING.TRANSCODERS.ADD.TAG_SETS.none',
  MIN = 'STREAMING.TRANSCODERS.ADD.TAG_SETS.min',
  ALL = 'STREAMING.TRANSCODERS.ADD.TAG_SETS.all'
}
export const klvTagsOptions = [
  {
    option: t(EnumKlvTagsType.MIN),
    value: 'min'
  },
  {
    option: t(EnumKlvTagsType.ALL),
    value: 'all'
  },
  {
    option: t(EnumKlvTagsType.NONE),
    value: 'none'
  }
];

export const klvTagsCustomOptions = [
  {
    option: t(EnumKlvTagsType.CUSTOM),
    value: 'custom'
  },
  {
    option: t(EnumKlvTagsType.MIN),
    value: 'min'
  },
  {
    option: t(EnumKlvTagsType.ALL),
    value: 'all'
  },
  {
    option: t(EnumKlvTagsType.NONE),
    value: 'none'
  }
];

export const frameRateOptions = [
  {
    option: t('STREAMING.TRANSCODERS.ADD.FRAME_RATE.auto'),
    value: -1
  },
  {
    option: t('STREAMING.TRANSCODERS.ADD.FRAME_RATE.start'),
    value: 0
  },
  ...[60, 50, 30, 25, 15, 12.5, 7.5, 5, 4, 3, 2, 1].map((i: any) => ({ option: `${i}`, value: i }))
];

export const decimationFactor = Array.from({ length: 59 }, (v, k) => {
  return { value: k, option: `1/${k + 2}` };
});
export const decimationFactorMode = [
  { option: t('none'), value: 'none' },
  { option: t('STREAMING.TRANSCODERS.ADD.byFactor'), value: 'factor' }
];
