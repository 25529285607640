import {
  Button,
  DataTable,
  Dialog,
  Form,
  FormGroup,
  FormLabel,
  Icon,
  Switch,
  TextInlineEditor,
  colorValue
} from '@hai/ui-react';
import { HaiDataTableColumnType } from '@hai/ui-react/dist/components/DataTable/IHaiDataTable';
import { FileUploadStages } from '@hai/ui-react/dist/components/FileUpload/FileUpload';
import {
  IPresetItem,
  IPresets,
  PRESET_STARTUP_CURRENT_ID,
  autosavePreset,
  duplicatePreset,
  importPreset,
  renamePreset,
  savePreset,
  startupPreset
} from 'api/presets/presets';
import { FormContainer } from 'components/common/form/formContainer/formContainer';
import { buildFormSelect } from 'components/common/form/formSelect/formSelect';
import { IsHidden } from 'components/isHidden/isHidden';
import {
  downloadPreset,
  presetsRowsWithIcons,
  sortPresets,
  triggerDeletePreset
} from 'components/presets/presetsHelper';
import { askToLoadPreset } from 'components/presets/PresetsLoadMenu';
import { PresetsUpload } from 'components/presets/PresetsUpload';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { PresetContext } from 'context/preset';
import { t } from 'i18next';
import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { useGetPresets } from 'utils/hooks/useGetPresets';

interface IPresetManager {
  show: boolean;
  newPreset: any;
  handleClose: () => void;
}

export const PresetsManager = (props: IPresetManager) => {
  const { show, newPreset, handleClose } = props;
  const getPresetsService = useGetPresets();
  const [isBusy, setIsBusy] = useState(false);
  const [showDropzone, setShowDropzone] = useState(false);
  const [files, setFiles] = useState<any>([]);

  const [uploadState, setUploadState] = useState({
    stage: FileUploadStages.NONE,
    animationTime: 0.5,
    progress: 0
  });

  const { dispatch } = useContext(GlobalContext);
  const { configList, autosave, activeUuid, currentUuid, useCurrentForStartup } =
    useContext(PresetContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const [showSpinner, setShowSpinner] = useState(false);

  const listWithDefault = [
    { uuid: PRESET_STARTUP_CURRENT_ID, name: t('PRESETS.defaultStartup') } as IPresetItem,
    ...(configList || [])
  ];
  const [workingList, setWorkingList] = useState<IPresetItem[] | undefined>(configList);
  const formProps = {
    defaultValidation: true,
    initialValues: {}
  };

  const headerData = [
    { fieldKey: 'name', title: t('PRESETS.name') },
    { fieldKey: 'modifiedHuman', title: t('PRESETS.modified') },
    { fieldKey: '', title: '', type: HaiDataTableColumnType.ACTIONS }
  ];

  const showNotification = useCallback(
    (props: IShowNotifications) => {
      const { args, state = 'Success', i18Name } = props;
      notifyDispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: state,
          message: t(i18Name, args)
        }
      });
    },
    [notifyDispatch]
  );

  const updateStartup = async (evt: any) => {
    const { value } = evt.target;
    const { success } = await startupPreset({ apiParams: { uuid: value } });
    await updatePresets();
    showNotification({
      state: success ? 'Success' : 'Error',
      i18Name: success ? 'PRESETS.presetStartupMsg' : 'PRESETS.ERROR.presetStartupMsg',
      args:
        value === PRESET_STARTUP_CURRENT_ID
          ? { name: t('PRESETS.defaultStartup') }
          : configList?.find(i => i.uuid === value) || { name: t('PRESETS.untitledPreset') }
    });
  };

  const updatePresets = useCallback(async () => {
    return await getPresetsService();
  }, [getPresetsService]);

  const checkAllEvent = () => {};

  const sortList = useCallback(() => {
    if (configList) {
      setWorkingList(prevState => {
        return sortPresets(configList).map(i => {
          const fromWorkingList = prevState?.find(w => w.uuid === i.uuid);
          return {
            ...i,
            selected: fromWorkingList?.selected,
            name: fromWorkingList?.name || i.name
          };
        });
      });
    }
  }, [configList]);

  useEffect(() => {
    sortList();
  }, [sortList]);

  const newName = useRef('');
  const customActionComponent = (data: any, action: string): ReactNode => {
    if (action === 'Delete' || action === 'Save') {
      return <div className='delete-row data-table-text pl-3'>{data.name}</div>;
    }
    const onChangeHandler = (evt: { target: { value: any } }) => {
      const { value } = evt.target;
      newName.current = value;
    };

    return (
      <TextInlineEditor
        initialMode='edit'
        focused
        onChange={onChangeHandler}
        defaultValue={data.name}
        useFsMask={true}
        data-auto={`edit-${data.name}`}
      >
        <Icon iconname={action} size='sm3' style={{ fill: colorValue('haiui-blue-01') }} />
      </TextInlineEditor>
    );
  };

  // TODO - adjust type to IActionItem[]
  const buildActionMenuItems = (preset: IPresetItem): Array<any> => {
    const actionItems = [
      {
        onSelect: (_name: string, data: any) => {
          askToLoadPreset({
            autosave,
            dispatch,
            configList,
            id: data.uuid,
            cb: () => {
              setShowSpinner(true);
            }
          });
        },
        actionIcon: 'Import',
        eventKey: 'show-load',
        title: 'Load'
      },
      {
        onSelect: (_action: any, item: any) => {
          newName.current = jsxObjToString(item.name);
        },
        actionIcon: 'Edit',
        eventKey: 'Rename',
        title: 'Rename',
        inlineAction: {
          actionComponent: customActionComponent(preset, 'Rename'),
          confirmIcon: 'Save',
          onCancel: inlineEditCancel,
          onConfirm: inlineEditSave,
          confirmationMessage: '',
          iconBackgroundColor: colorValue('haiui-blue-01')
        }
      },
      {
        onSelect: () => {},
        actionIcon: 'Save',
        eventKey: 'Save',
        title: 'Save',
        inlineAction: {
          actionComponent: customActionComponent(preset, 'Save'),
          confirmIcon: 'Save',
          onConfirm: (_action: string, data: any) => {
            const saveit = async () => {
              data.name = jsxObjToString(data.name);
              const { success } = await savePreset({ apiParams: data });
              showNotification({
                state: success ? 'Success' : 'Error',
                i18Name: success ? 'PRESETS.saved' : 'PRESETS.saveFailed',
                args: { name: data.name }
              });
            };
            saveit();
            return true;
          },
          confirmationMessage: t('PRESETS.saveConfirm'),
          iconBackgroundColor: colorValue('haiui-blue-01')
        }
      },
      {
        onSelect: (_action: any, item: any) => {
          newName.current = jsxObjToString(item.name);
        },
        actionIcon: 'Copy',
        eventKey: 'Duplicate',
        title: 'Duplicate',
        inlineAction: {
          actionComponent: customActionComponent(preset, 'Duplicate'),
          confirmIcon: 'Save',
          onConfirm: inlineEditSave,
          confirmationMessage: '',
          iconBackgroundColor: colorValue('haiui-blue-01')
        }
      },
      {
        onSelect: async (_name: string, data: any) => {
          const downloadit = async () => {
            const { success } = await downloadPreset(data.uuid, configList);
            showNotification({
              state: success ? 'Success' : 'Error',
              i18Name: success ? 'PRESETS.presetExportMsg' : 'PRESETS.ERROR.presetExportMsg',
              args: configList?.find(i => i.uuid === data.uuid)
            });
          };
          downloadit();
        },
        actionIcon: 'Download',
        eventKey: 'show-delete',
        title: 'Export'
      }
    ];
    // Don't let the user delete an active preset or a startup preset
    if (preset.uuid !== activeUuid && preset.uuid !== currentUuid) {
      actionItems.push({
        onSelect: () => {},
        actionIcon: 'TrashCan',
        eventKey: 'Delete',
        title: 'Delete',
        inlineAction: {
          actionComponent: customActionComponent(preset, 'Delete'),
          confirmIcon: 'TrashCan',
          onConfirm: inlineEditSave,
          confirmationMessage: t('PRESETS.deleteConfirm'),
          iconBackgroundColor: colorValue('haiui-red-01')
        }
      });
    }

    return actionItems;
  };

  const inlineEditCancel = (action: String) => {
    if (action === 'Rename') {
      // We dont know what values was canceled...
      //// this will have the issue of canceling out of one edit killing all other data.
      sortList();
    }
  };

  const deletedLastItem = useCallback(
    async (res: any, count: number) => {
      if (res.status === 204 && count === 0) {
        await autosavePreset({
          apiParams: { autosave: false }
        });
        getPresetsService();
      }
    },
    [getPresetsService]
  );

  const jsxObjToString = (name: any) => {
    if (typeof name === 'object') {
      const div = document.createElement('div');
      const root = createRoot(div);
      // The flushSync call is necessary so that the DOM is updated before reading its innerHTML property.
      flushSync(() => {
        // If the object has multiple items we only want to use the first (name, loaded icon, startup tag)
        root.render(name[0]);
      });

      return div.innerText;
    }
    return name;
  };

  const inlineEditSave = useCallback(
    (action: String, data: any) => {
      const triggerSave = async () => {
        data.name = jsxObjToString(data.name);
        let res;
        if (action === 'Rename') {
          res = await renamePreset({
            apiParams: { uuid: data.uuid, name: newName.current }
          });

          data = { ...data, name: newName.current ? newName.current : t('PRESETS.untitledPreset') };
        } else if (action === 'Delete') {
          const newList = workingList?.map(w => {
            return { ...w, ...(w.uuid == data.uuid ? { selected: false } : {}) };
          });
          res = await triggerDeletePreset(data.uuid);
          if (newList) {
            setWorkingList([...newList]);
            deletedLastItem(res, newList?.length - 1);
          }
        } else if (action === 'Duplicate') {
          res = await duplicatePreset({
            apiParams: {
              uuid: data.uuid,
              name: newName.current
            }
          });
          data = { ...data, name: newName.current };
        }

        const presets = await updatePresets();
        setWorkingList(presets);
        showNotification({
          state: res?.success ? 'Success' : 'Error',
          i18Name: res?.success ? `PRESETS.preset${action}Msg` : `PRESETS.preset${action}MsgFailed`,
          args: { ...res, ...data }
        });
      };
      triggerSave();
      return true;
    },
    [deletedLastItem, showNotification, updatePresets, workingList]
  );

  const bulkActionComponents = (selectedRows: any) => {
    const bulkDelete = () => deleteModal(selectedRows);

    const deleteModal = (uuids: any) => {
      const count = uuids.length;
      dispatch({
        type: EnumGlobalAction.SET_CONFIRM_MODAL,
        payload: {
          show: true,
          onConfirm: () => bulkAction('delete'),
          title: t('PRESETS.DELETE_MODAL.title', { count }),
          desc: t('PRESETS.DELETE_MODAL.desc', { count })
        }
      });
    };

    const bulkAction = async (action: string) => {
      let success = 0;
      let error = 0;
      for await (const row of selectedRows) {
        let res;
        if (action === 'export') {
          res = await downloadPreset(row, configList);
        }
        if (action === 'delete') {
          const newList = workingList?.map(w => {
            return { ...w, ...(selectedRows.indexOf(w.uuid) !== -1 ? { selected: false } : {}) };
          });

          res = await triggerDeletePreset(row);
          if (newList) {
            setWorkingList([...newList]);
            deletedLastItem(res, newList.length - selectedRows.length);
          }

          checkAllEvent();
        }
        if (res.success) {
          success++;
        } else {
          error++;
        }
      }
      await updatePresets();
      if (success > 0) {
        showNotification({
          i18Name: `PRESETS.${action}BulkMsg`,
          args: { count: success }
        });
      }

      if (error > 0) {
        showNotification({
          i18Name: `PRESETS.ERROR.${action}BulkMsg`,
          state: 'Error',
          args: { count: error }
        });
      }
      setIsBusy(false);
    };

    // Don't let the user delete an active preset or a startup preset
    // if (preset.uuid !== activeUuid && preset.uuid !== currentUuid) {
    let allowDelete = true;
    if (selectedRows.includes(activeUuid) || selectedRows.includes(currentUuid)) {
      allowDelete = false;
    }
    return (
      <>
        <Button
          className='bulk-action-btn'
          disabled={isBusy}
          onClick={() => bulkAction('export')}
          size='small'
          state='idle'
        >
          {t('export')}
        </Button>
        <Button
          className='bulk-action-btn'
          disabled={isBusy || !allowDelete}
          onClick={() => bulkDelete()}
          size='small'
          state='idle'
        >
          {t('delete')}
        </Button>
      </>
    );
  };

  interface IShowNotifications {
    args: any;
    state?: string;
    i18Name: string;
  }

  const onSelectRowHandler = (selected: boolean, id: string) => {
    if (!workingList) {
      return;
    }
    const updated = workingList.map(d =>
      d.uuid === id
        ? {
            ...d,
            selected
          }
        : d
    );
    setWorkingList([...updated]);
  };

  const content = (
    <Form {...formProps}>
      <FormGroup>
        <FormLabel data-auto='form_message'>{t('PRESETS.manageDetails')}</FormLabel>
      </FormGroup>

      <FormContainer className='mt-4' columns={2}>
        {buildFormSelect({
          dataAuto: 'preset_startup',
          className: 'startup-dropdown',
          idKey: 'uuid',
          items: listWithDefault,
          label: t('PRESETS.krakenStartup'),
          nameProp: 'name',
          onChange: updateStartup,
          selectedId: useCurrentForStartup
            ? PRESET_STARTUP_CURRENT_ID
            : listWithDefault.find(i => i.uuid === activeUuid)?.uuid,
          selectName: 'startup'
        })}
        <FormContainer className='mt-4' columns={2}>
          <FormLabel>&nbsp;</FormLabel>
          <FormGroup className='autosave-container'>
            {/* <FormLabel>&nbsp;</FormLabel> */}
            <FormLabel
              data-auto='form_auto_save'
              helpMessage={
                autosave ? t('PRESETS.autosaveHelpEnabled') : t('PRESETS.autosaveHelpDisabled')
              }
            >
              {t('PRESETS.autosave')}
              <Switch
                className='preset-autosave'
                checked={autosave}
                onChange={async (switchState: boolean) => {
                  const { success } = await autosavePreset({
                    apiParams: { autosave: switchState }
                  });
                  updatePresets();
                  showNotification({
                    state: success ? 'Success' : 'Error',
                    i18Name: success ? `PRESETS.autosaveToggled` : 'PRESETS.ERROR.autosaveToggled',
                    args: { switchState: switchState ? 'On' : 'Off' }
                  });
                }}
              />
            </FormLabel>
          </FormGroup>
        </FormContainer>
      </FormContainer>
      {workingList && (
        <DataTable
          className='presets-datatable scrollable'
          selectable={true}
          checkAll={false}
          maxHeight={showDropzone ? '272px' : '378px'}
          onCheckAll={checkAllEvent}
          columnStructure={headerData}
          useFsMask={true}
        >
          <DataTable.Header data-auto='header' bulkActions={bulkActionComponents} />
          {workingList.map((row: IPresetItem) => (
            <DataTable.Row
              data-auto={`row_${row.name}`}
              className='preset-row'
              key={row.uuid}
              checked={row.selected}
              onSelect={selected => onSelectRowHandler(selected, row.uuid)}
              actionItems={buildActionMenuItems(row)}
              rowData={{ ...row, id: row.uuid, name: presetsRowsWithIcons(row) }}
            />
          ))}
        </DataTable>
      )}
      <IsHidden condition={(showDropzone: boolean) => !showDropzone} param={showDropzone}>
        <PresetsUpload files={files} setFiles={setFiles} uploadState={uploadState} />
      </IsHidden>
    </Form>
  );

  const checkForDuplicates = () => {
    const dupFiles = files.filter((file: any) => {
      const hasDup = configList?.filter(i => i.name === file.name);
      if (hasDup?.length) {
        return true;
      }
      return false;
    });

    if (dupFiles.length === 0) {
      onUpload();
    } else {
      dispatch({
        type: EnumGlobalAction.SET_CONFIRM_MODAL,
        payload: {
          isDanger: false,
          show: true,
          onConfirm: onUpload,
          title: t('PRESETS.duplicatePreset', { count: dupFiles.length, name: dupFiles[0].name }),
          confirmText: t('ok')
        }
      });
    }
  };

  const onUpload = () => {
    Promise.all(
      files.map(async (file: any) => {
        return await importPreset({ apiParams: { file }, ignoreErrors: true });
      })
    ).then(results => {
      const errors = results.filter(i => i.error);
      const success = results.filter(i => !i.error);
      if (errors.length) {
        showNotification({
          i18Name: `PRESETS.ERROR.importMsg`,
          state: 'Error',
          args: { count: errors.length, name: errors[0].name }
        });
      }
      if (success.length) {
        showNotification({
          i18Name: `PRESETS.importMsg`,
          args: { count: success.length, name: success[0].name }
        });
      }

      getPresetsService(async (presets: IPresets) => {
        if (presets?.configList?.length === files.length) {
          await startupPreset({ apiParams: { uuid: PRESET_STARTUP_CURRENT_ID } });
          await autosavePreset({
            apiParams: { autosave: true }
          });
          askToLoadPreset({
            autosave,
            dispatch,
            configList: presets.configList,
            id: presets?.configList?.[0]?.uuid,
            cb: () => {
              setShowSpinner(true);
            }
          });
          getPresetsService();
        }
      });
    });
    setFiles([]);

    setUploadState(prev => {
      return { ...prev, stage: FileUploadStages.NONE, progress: 0 };
    });
    setShowDropzone(false);
  };

  const dialogButtons = [
    ...(!showDropzone
      ? [
          {
            label: t('import'),
            alignment: 'left',
            onClick: () => {
              setShowDropzone(true);
              return false;
            }
          },
          {
            alignment: 'left',
            label: t('PRESETS.newPreset'),
            onClick: () => {
              newPreset();
              return false;
            }
          },
          {
            label: t('done'),
            state: showSpinner ? 'pending' : 'idle',
            variant: 'primary'
          }
        ]
      : [
          {
            label: t('cancel'),
            onClick: () => {
              setFiles([]);
              setShowDropzone(false);
              return false;
            }
          },
          {
            label: t('import'),
            state: showSpinner ? 'pending' : 'idle',
            variant: 'primary',
            disabled: files.length === 0,
            onClick: () => {
              checkForDuplicates();
              return false;
            }
          }
        ])
  ];

  return (
    <Dialog
      accentColor={colorValue('haiui-aqua-01')}
      buttons={dialogButtons}
      className='preset-manage'
      content={content}
      dialogType='activity'
      headerIcon='Presets'
      onClose={handleClose}
      show={show}
      size='lg'
      title={t('PRESETS.managePresets')}
      data-auto='modal_manage_presets'
    />
  );
};
