import { Placeholder, TabItem, Tabs } from '@hai/ui-react';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { RoleContext } from 'permissions/role/Roles';
import React, { ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { ISubTab } from '../../routes/routePaths';
import Page from '../page/Page';

interface ITabPageProps {
  element: any;
  tabs: ISubTab;
  selected: string;
  headerComponent?: ReactNode;
  isLicenseLoading: boolean;
  clickCb?: any;
}

// With tabs
const TabPage: React.FunctionComponent<ITabPageProps> = ({
  tabs,
  element,
  selected,
  headerComponent,
  isLicenseLoading,
  clickCb = () => {}
}) => {
  const navigate = useNavigate();
  const role = useContext(RoleContext);
  const handleSelect = async (path?: string) => {
    if (path && path !== location.pathname) {
      clickCb();
      navigate(path);
    }
  };
  return (
    <Page>
      {headerComponent}
      <div className='tab-container'>
        <FadeIn
          visible={!isLicenseLoading}
          suspenseComponent={
            <div className='tab-placeholder'>
              <Placeholder.Tabs numTabs={5} />
            </div>
          }
        >
          <Tabs
            className='tabs full-width'
            selectedTab={selected}
            onSelect={(e: any) => handleSelect(e.target['data-auto'])}
          >
            {Object.keys(tabs)
              .filter(key => {
                if (tabs[key].licensedFeature) {
                  if (role.cannot('license', key.toUpperCase())) {
                    return false;
                  }
                }
                if (!tabs[key].show) {
                  return false;
                }
                return true;
              })
              .map(key => {
                return (
                  <TabItem
                    key={tabs[key].path}
                    caption={tabs[key].title}
                    data-auto={tabs[key].path}
                    eventkey={tabs[key].path}
                  />
                );
              })}
          </Tabs>
        </FadeIn>
      </div>

      {element}
    </Page>
  );
};

export default TabPage;
