import { UasSecurityTable, UasTable } from 'api/metadata/metadata.type';

export interface Tag {
  id?: number;
  active?: boolean;
  eventKey?: string;
  text: string;
  value?: string;
}

export interface Tags extends Array<Tag> {}

export const filterOnActiveTagSets = (tagSet: Tags) => {
  return tagSet
    .filter((tag: Tag) => tag.active)
    .map((tag: Tag) => tag.active && tag.text)
    .join(',');
};

export const tagSetToApi = (tagSet: Tags) => {
  return tagSet.filter((tag: Tag) => tag.active).map((tag: Tag) => tag.active && tag.id);
};

export const uasNone = UasTable.map(item => {
  return { id: item.id, text: item.title, active: false };
});
export const UasMin = UasTable.map(item => {
  return { id: item.id, text: item.title, active: item.minST0902 === true };
});
export const UasMax = UasTable.map(item => {
  return { id: item.id, text: item.title, active: true };
});

export const buildSelectedUasTags = (selectedTags: number[], UasList: any[]) => {
  return UasList.map(item => {
    return {
      id: item.id,
      text: item.title,
      active: selectedTags.indexOf(item.id) !== -1
    };
  });
};

export const UasSecurityNone = UasSecurityTable.map(item => {
  return { id: item.id, text: item.title, active: false };
});
export const UasSecurityMin = UasSecurityTable.map(item => {
  return { id: item.id, text: item.title, active: item.minST0902 === true };
});
export const UasSecurityMax = UasSecurityTable.map(item => {
  return { id: item.id, text: item.title, active: true };
});

export const filteredUasMin = filterOnActiveTagSets(UasMin);
export const filteredUasMax = filterOnActiveTagSets(UasMax);
export const filteredUasSecurityMin = filterOnActiveTagSets(UasSecurityMin);
export const filteredUasSecurityMax = filterOnActiveTagSets(UasSecurityMax);

export const uasDefaultDropdown = (tagSet: number[] | undefined) => {
  const selectedTags = tagSet?.join(',');
  switch (selectedTags) {
    case filteredUasMin:
      return 'min';
    case filteredUasMax:
      return 'all';
    case '':
      return 'none';
    default:
      return 'custom';
  }
};
export const uasSecurityDefaultDropdown = (tagSet: number[] | undefined) => {
  const selectedTags = tagSet?.join(',');

  switch (selectedTags) {
    case filteredUasSecurityMin:
      return 'min';
    case filteredUasSecurityMax:
      return 'all';
    case '':
      return 'none';
    default:
      return 'custom';
  }
};
