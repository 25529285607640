import './inputs.scss';

import { List, colorValue } from '@hai/ui-react';
import { IInputItem } from 'api/inputs/input.type';
import {
  EnumSortDirection,
  EnumSortType,
  ISortComparator,
  SortManager
} from 'components/actionBar/SortManager';
import React from 'react';
import { useInputInfo } from 'utils/hooks/useInputInfo';

import InputItem from './InputItem';

interface IInputListProps<T extends IInputItem> {
  list: T[];
  newItem?: string;
  hideCheckbox?: boolean;
  sortInfo: {
    [K in keyof Partial<T> | string]: {
      title: string;
      type: EnumSortType;
      /*
       *  Optional
       *  Supply custom comparator function receiving model A and B as input and returning -1 | 0 | 1
       */
      comparator?: ISortComparator<T>;
      /*
       *  Optional
       *  Value provider for the field. Takes model as input and output value to sort on
       */
      provider?: (arg: T) => any;
    };
  };
  sorting: string;
  selectedFilter: string;

  direction: EnumSortDirection;
  saveCallback: Function;
}

const InputList = <T extends IInputItem>(props: IInputListProps<T>) => {
  const {
    direction,
    hideCheckbox = false,
    newItem,
    list,
    saveCallback,
    selectedFilter,
    sortInfo,
    sorting
  } = props;
  const getInputInfo = useInputInfo();

  return (
    <List selectable={hideCheckbox} accentColor={colorValue('haiui-aqua-01')}>
      <SortManager
        newItem={newItem}
        sortInfo={sortInfo}
        list={list}
        sortKey={sorting}
        direction={direction}
      >
        {list &&
          list
            .filter((item: IInputItem) => {
              if (selectedFilter && selectedFilter !== 'None') {
                if (selectedFilter !== getInputInfo(item).actionType) {
                  return false;
                }
              }
              return true;
            })
            .map((item: IInputItem) => {
              return <InputItem key={item.uuid} item={item} saveCallback={saveCallback} />;
            })}
      </SortManager>
    </List>
  );
};

export default InputList;
