import './layout.scss';

import Rebooting from 'components/rebooting/Rebooting';
import { getPageName } from 'components/routes/RouteManager';
import { EnumRebootState, GlobalContext } from 'context/global';
import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { KrakenSidebar } from '../sidebar/KrakenSidebar';

const Layout: React.FunctionComponent = () => {
  const { rebootState } = useContext(GlobalContext);

  const { pathname } = useLocation();

  useEffect(() => {
    const titleBase = window.location.hostname;
    const pageName = getPageName?.(pathname);
    const title = `${titleBase}${pageName ? ` - ${pageName}` : ''}`;
    if (pageName) {
      document.title = title;
    }
  }, [pathname]);

  return rebootState === EnumRebootState.NONE ? (
    <div className='layout-with-sidebar'>
      <KrakenSidebar />
      <Outlet />
    </div>
  ) : (
    <Rebooting />
  );
};

export default Layout;
