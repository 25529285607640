export interface ISystemNetwork {
  bonds: any[];
  defaultInterface: string;
  dnsserver0: string;
  dnsserver1: string;
  hostname: string;
  ipForward: boolean;
  nics: ISystemNetworkNic[];
  ntpAddress: string;
  ntpConf: any;
  searchdomain0: string;
  searchdomain1: string;
  staticRoutes: any[];
}

export interface ISystemNetworkNicIPv6 {
  dhcp: string;
  linklocal: string;
  mngtmpaddr: string;
  static: string;
  temporary: string;
}

export interface ISystemNetworkNic {
  addressing: string;
  duplex: 'FULL' | 'HALF';
  gateway: string;
  ipAddress: string;
  link: 'MANUAL' | 'AUTO';
  ipv4: boolean;
  ipv6: boolean;
  macAddress: string;
  mtu: number;
  name: string;
  value?: string;
  peerDns: any;
  speed: any;
  subnetMask: string;
  userLabel?: string;
  duplicateAddressDetection: number;
  ipv6Addressing: string;
  ipv6Gateway: string;
  ipv6PrivacyExtension: number;
  ipv6Address: ISystemNetworkNicIPv6;
}

export interface ILicenseSettings {
  activeBypassSessions: number;
  activeTranscoderSessions: number;
  cloudLicenseType: string;
  expiration: string;
  gigevis: boolean;
  h264Qsv: boolean;
  hevc: boolean;
  hevcNvenc: boolean;
  hevcQsv: boolean;
  isCloud: boolean;
  isEc2: boolean;
  isLicenseValid: boolean;
  isVmware: boolean;
  klv: boolean;
  licenseMacAddress: string;
  load: string;
  loadBypass: string;
  maxBypass: number;
  maxBypassMode: number;
  maxEncoders: number;
  mpeg2video: boolean;
  ndi: boolean;
  nicMacAddress: string;
  recording: boolean;
  st2110: boolean;
  systemCpuId: string;
  systemInstanceUuid: string;
  versionLimitText: string;
  resourceId: string;
  // Unused from server :
  // h264_hvc8700
  // is_license_valid
  // version_limit

  // unused on old ui :
  // hvc8700_enabled
  // h264_nvenc
  // encoded_license
  // creation_date
  // host_migration_allowed
  // max_bypass_sessions
}

export interface IDebugLogging {
  enabled: boolean;
}

export interface ISystemMetrics {
  cpuload: number;
  gpus: ISystemLoadGpu[];
  memload: number;
  uptime: number;
}

export interface ISystemLoadGpu {
  uuid: string;
  index: string;
  utilization: string;
  videoClock: string;
  temperature: string;
  name: string;
  serial: string;
  memoryUsed: string;
  memoryTotal: string;
}

interface INetworkInterfaces {
  [key: string]: {
    enabled: boolean;
  };
}

export interface ISystemInfo {
  currenttime: string;
  uptime: string;
  version: string;
  build: string;
  networkInterfaces: INetworkInterfaces;
}

export enum EnumBypassMode {
  INDIVIDUALLY = 0,
  UNLIMITED = 1,
  MIXED = 2
}
