import { IGeneralApiProps } from 'api/global.type';
import { IMetadataItem, IMetadataWrapper } from 'api/metadata/metadata.type';
import { getSerialports } from 'api/serialports/serialports';
import { convertApiEmptyToEmpty, decodeResponse } from 'api/utils/helper';

import client from '../utils/client';

export const getMetadatas = async (props?: IGeneralApiProps) => {
  const metadata = await client
    .get('/metadata')
    .then(async resp => {
      const data: IMetadataWrapper = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return null;
    });

  if (!metadata) {
    return null;
  }
  const formData = await apiToFormdata(metadata?.metadataList);

  return formData;
};

interface IDeleteMetadata extends IGeneralApiProps {
  metadata: IMetadataItem;
}

export const deleteMetadata = (props: IDeleteMetadata) => {
  const { onError, metadata } = props;
  return client
    .delete(`/metadata/${metadata.uuid}`)
    .then(() => {
      return true;
    })
    .catch(e => {
      onError && onError(e);
    });
};

interface IAddMetadataProps extends IGeneralApiProps {
  apiParams: IMetadataItem;
}

export const addMetadata = (props: IAddMetadataProps): Promise<{ success: boolean }> => {
  const { apiParams, onError } = props;

  return client

    .post('/metadata', apiParams)

    .then((resp: any) => {
      if (resp.status === 200) {
        return { success: true, uuid: resp.data.uuid };
      } else {
        onError && onError(resp);

        return { success: false };
      }
    })

    .catch(e => {
      onError && onError(e);

      return { success: false };
    });
};

export const updateMetadata = (props: IAddMetadataProps) => {
  const { apiParams, onError } = props;
  return client
    .put(`/metadata/${apiParams.uuid}`, apiParams)
    .then(resp => {
      return { ...resp.data, success: true };
    })
    .catch((e: Error) => {
      onError && onError(e);
    });
};

const apiToFormdata = async (apiData: any) => {
  const serialPorts = await getSerialports().catch(() => {
    return apiData;
  });

  const res = apiData.map((i: any) => {
    const selectedSerialPort = serialPorts.portsList?.find((i: any) => i.id === i.serialDevice);
    return {
      ...i,
      name: decodeResponse(i.name),
      missionIdInsertion: decodeResponse(i.missionIdInsertion),
      ...(!selectedSerialPort
        ? {
            serialDeviceManual: i.serialDevice,
            serialDevice: 'manual'
          }
        : {}),
      ...setRelays(i)
    };
  });

  return res;
};

const setRelays = (item: any) => {
  const res = item.relays.map((i: any) => {
    i.tos = convertApiEmptyToEmpty(i.tos);
    i.ttl = convertApiEmptyToEmpty(i.ttl);

    return i;
  });

  return res;
};
