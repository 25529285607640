import { IGeneralApiProps } from 'api/global.type';
import client from 'api/utils/client';

export const getLicense = (props?: IGeneralApiProps) => {
  return client
    .get('/license')
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return e;
    });
};

interface ISetLicense extends IGeneralApiProps {
  license: string;
}

export const setLicense = (props: ISetLicense) => {
  return client
    .put('/license', { license: props.license })
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
    });
};
