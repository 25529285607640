import { Dialog, Form, colorValue } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { getRtspserver, updateRtspserver } from 'api/system/services';
import { FormContainer } from 'components/common/form/formContainer/formContainer';
import { FormInfo } from 'components/common/form/formInfo/formInfo';
import { FormInput } from 'components/common/form/formInput/formInput';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumServicesAction } from 'context/settings/services';
import { SettingsContext } from 'context/settings/settings';
import { t } from 'i18next';
import React, { useContext, useState } from 'react';
import constant from 'utils/constant';
import { useFormRef } from 'utils/hooks/useFormRef';
import * as Yup from 'yup';

export interface IWebserverConfig {
  name: string;
  checked: boolean;
  label: string;
}

interface IProps {
  show: boolean;
  handleClose: any;
}

export const RtspserverConfig = (props: IProps) => {
  const { show, handleClose } = props;

  const [buttonState, setButtonState] = useState<ButtonStateType>('idle');

  const { dispatch } = useContext(NotifyContext);
  const { settingsDispatch, rtspPort } = useContext(SettingsContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const [port, setPort] = useState(rtspPort?.rtspPort);

  const formControl = useFormRef();
  const { formRefCallback } = formControl;

  const onSave = () => {
    setButtonState('pending');
    updateRtspserver({ rtspPort: port || constant.system.rtspDefaultPort })
      .then(v => {
        v && settingsDispatch({ type: EnumServicesAction.SET_RTSPSERVER, payload: v });
        dispatch({
          type: EnumNotify.ADD_NOTIFICATION,
          payload: {
            type: 'Success',
            message: t('SETTINGS.SERVICES.rtspUpdated')
          }
        });
      })
      .finally(() => {
        getRtspserver();
        setButtonState('idle');
        handleClose();
      });
  };

  const validationSchema = Yup.object({
    rtspPort: Yup.number()
      .typeError(t('VALIDATION.between', { x: 1, y: 65535 }) as string)
      .min(1, t('VALIDATION.between', { x: 1, y: 65535 }) as string)
      .max(65535, t('VALIDATION.between', { x: 1, y: 65535 }) as string)
  });

  const formProps = {
    defaultValidation: true,
    handleSubmit: onSave,
    initialValues: { rtspPort: rtspPort?.rtspPort?.toString() },
    restValidationProps: { validationSchema, innerRef: formRefCallback }
  };

  return (
    <Dialog
      title={t('SETTINGS.SERVICES.rtsp')}
      size='md'
      dialogType='activity'
      accentColor={colorValue('haiui-aqua-01')}
      headerIcon={'Settings'}
      content={
        <div>
          <Form {...formProps} className={'generate-cert-form'}>
            <FormContainer columns={2}>
              <FormInput
                label={t('STREAMING.INPUTS.ADD.port')}
                name='rtspPort'
                defaultValue={port}
                onChange={e => {
                  setPort(Number(e.target.value));
                }}
                showHint
                placeholder={t('STREAMING.INPUTS.ADD.rstpPortHelp')}
                helpMessage={t('STREAMING.INPUTS.ADD.rstpPortHelp')}
                dataAuto='port'
              />
            </FormContainer>
            <FormInfo dataAuto='warning-message' info={t('SETTINGS.SERVICES.rtspInfo')} />
          </Form>
        </div>
      }
      show={show}
      onClose={handleClose}
      buttons={[
        {
          variant: 'secondary',
          onClick: handleClose,
          disabled: false,
          label: t('cancel')
        },
        {
          variant: 'primary',
          close: false,
          onClick: () => submitHandler(formControl.formRef, notifyDispatch),
          disabled: !formControl.formSubmitActive,
          state: buttonState,
          label: t('apply')
        }
      ]}
    />
  );
};
