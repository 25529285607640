export const certificatesEn = {
  generate: 'Generate',
  import: 'Import',
  name: 'Name',
  keySize: 'Key Size',
  digest: 'Digest',
  type: 'Type',
  default: 'Default',
  message: 'Message',
  expired: 'Expired',
  expiring: 'Valid until {{date}}',
  notMatchPrivateKey: 'Expiring in {{days}} days. Not compatible with current private key',
  warning: 'Any modifications will require a reboot in order to take effect.',
  DIGEST: {
    sha256: 'SHA-256',
    sha384: 'SHA-384',
    sha512: 'SHA-512'
  },
  TYPES: {
    csr: 'Certificate Signing Request',
    self: 'Self-Signed'
  },
  FORMS: {
    name: 'Name',
    type: 'Type',
    subject: 'Subject',
    generateCertificate: 'Generate Certificate',
    generate: 'Generate',
    import: 'Import',
    importCertificate: 'Import Certificate',
    digest: 'Digest Algorithm',
    v3: 'V3 Extension',
    newPrivateKey: 'Create New Private Key',
    newKey: 'Key Length',
    password: 'Password',
    format: 'Format',
    country: 'Country',
    state: 'State',
    location: 'Location',
    organization: 'Organization',
    unit: 'Org. Unit',
    commonName: 'Common Name',
    editSubject: 'Edit Subject',
    certificateFile: 'Certificate File',
    privateKey: 'Private Key',
    bundleFile: 'Bundle File',
    newKeyWarning: 'Generating a new private key will overwrite the current private key.'
  },
  IMPORT_TYPES: {
    keyCertPair: 'Private Key + Certificate Pair',
    certificates: 'Certificates (Identity/CA-chains/Bundles)'
  },
  FORMAT: {
    auto: 'Auto',
    der: 'DER',
    pkcs7: 'PKCS#7',
    pkcs12: 'PKCS#12'
  },
  MESSAGES: {
    uploaded: 'Certificate "{{name}}" imported successfully.',
    added: 'Certificate "{{name}}" added successfully.',
    deleted: 'Certificate "{{name}}" deleted successfully.',
    deleted_other: '{{count}} certificates deleted successfully.',
    setToDefaultBundle: '"{{name}}" set as default ca-chain bundle. Reboot to apply this change.',
    setToDefault: '"{{name}}" set as default certificate. Reboot to apply this change.'
  },
  MODALS: {
    deleteTitle_one: 'Are you sure you want to delete this certificate?',
    deleteTitle_other: 'Are you sure you want to delete these certificates?',
    deleteDesc_one: "This certificate will be deleted immediately. You can't undo this action.",
    deleteDesc_other:
      "{{count}} certificates will be deleted immediately. You can't undo this action."
  }
};
