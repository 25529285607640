import {
  Button,
  FormGroup,
  FormLabel,
  Heading4Med,
  Icon,
  Label2Book,
  Modal,
  ModalBody,
  ModalFooter,
  Tag,
  colorValue
} from '@hai/ui-react';
import { getSystemUpgrade, getSystemUpgradeStatus } from 'api/system/upgrade';
import { SecurityContext } from 'context/security/security';
import { EnumUpdatesAction, ISystemUpgrade } from 'context/security/updates';
import { SettingsContext } from 'context/settings/settings';
import { t } from 'i18next';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useLocalStorage from 'usehooks-ts/dist/esm/useLocalStorage/useLocalStorage';
import constant from 'utils/constant';

export const UpdateModal = () => {
  const [completion, setCompletion] = useState(0);
  const { securityDispatch, upgrade } = useContext(SecurityContext);
  const { systemInfo } = useContext(SettingsContext);

  const [upgradeBuild] = useLocalStorage(constant.updating?.build, upgrade?.build);
  const [upgradeVersion] = useLocalStorage(constant.updating?.version, upgrade?.version);
  const [upgradePrevBuild] = useLocalStorage(constant.updating?.prevBuild, systemInfo?.build);
  const [upgradePrevVersion] = useLocalStorage(constant.updating?.prevVersion, systemInfo?.version);
  const state = upgrade?.state;

  const isPreparingUpdate = state === 'PREPARING_UPGRADE' || state === 'BUNDLE_READY';
  const isUpdating = state === 'UPGRADING' && completion !== 100;

  let preparingUpdateIcon = 'SelectorCheckmark';
  let updatingIcon = 'SelectorCheckmark';
  let rebootingIcon = 'SelectorCheckmark';
  if (isPreparingUpdate) {
    preparingUpdateIcon = 'StatusDisconnecting';
  }

  if (isUpdating) {
    preparingUpdateIcon = 'StatusOK';
    updatingIcon = 'StatusDisconnecting';
  }

  const isAllDone = !isPreparingUpdate && !isUpdating;

  if (isAllDone) {
    preparingUpdateIcon = 'StatusOK';
    updatingIcon = 'StatusOK';
    rebootingIcon = 'StatusOK';
  }

  const refreshSystemUpgrade = useCallback(
    () =>
      getSystemUpgrade().then((value: ISystemUpgrade) => {
        value && securityDispatch({ type: EnumUpdatesAction.SET_UPGRADE, payload: value });
      }),
    [securityDispatch]
  );

  const refreshSystemStatus = useCallback(
    () =>
      getSystemUpgradeStatus().then(value => {
        if (value.data) {
          setCompletion(value.data.completion);
        }
      }),
    []
  );

  useEffect(() => {
    if (isPreparingUpdate) {
      const preparingInterval = setInterval(() => {
        refreshSystemUpgrade();
      }, constant.interval.upgradeStatus);
      return () => clearInterval(preparingInterval);
    }
  }, [isPreparingUpdate, refreshSystemUpgrade]);

  useEffect(() => {
    if (isUpdating) {
      const updatingInterval = setInterval(() => {
        if (completion !== 100) {
          refreshSystemStatus();
        }
      }, constant.interval.upgradeStatus);
      return () => clearInterval(updatingInterval);
    }
  }, [completion, isUpdating, refreshSystemStatus]);

  return (
    <Modal show={true} onHide={() => null} centered>
      <ModalBody className='update-modal'>
        {completion === 100 && (
          <Label2Book className='server-updated'>{t('SETTINGS.UPDATES.updated')}</Label2Book>
        )}
        {
          <>
            <div className='update-info'>
              <Heading4Med className='info-version'>
                {t('SETTINGS.UPDATES.updatingTo')}
                {`${t('SETTINGS.UPDATES.version')}: ${upgradeVersion} ${t(
                  'SETTINGS.UPDATES.build'
                )}: ${upgradeBuild} `}
                {completion === 100 && t('SETTINGS.UPDATES.complete')}
              </Heading4Med>
            </div>
            <FormGroup className='subtitle'>
              <FormLabel>
                {`${t('SETTINGS.UPDATES.previous')} ${t(
                  'SETTINGS.UPDATES.version'
                )}:${upgradePrevVersion} ${t('SETTINGS.UPDATES.build')}: ${upgradePrevBuild}`}
              </FormLabel>
            </FormGroup>
          </>
        }
        <div className='update-progress'>
          <div className='progress-item'>
            <Icon
              iconname={preparingUpdateIcon}
              spinning={preparingUpdateIcon === 'StatusDisconnecting'}
              color={colorValue('haiui-gray-06')}
            />
            <Tag text={t('SETTINGS.UPDATES.preparingUpgrade')} />
          </div>
          <div className='progress-item'>
            <Icon
              iconname={updatingIcon}
              spinning={updatingIcon === 'StatusDisconnecting'}
              color={colorValue('haiui-gray-06')}
            />
            <Tag text={t('SETTINGS.UPDATES.updating')} />
          </div>
          <div className='progress-item'>
            <Icon
              iconname={rebootingIcon}
              spinning={rebootingIcon === 'StatusDisconnecting'}
              color={colorValue('haiui-gray-06')}
            />

            <Tag text={t('SETTINGS.UPDATES.rebooting')} />
          </div>
        </div>
      </ModalBody>
      {completion === 100 && (
        <ModalFooter>
          <Button
            variant='primary'
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('done')}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};
