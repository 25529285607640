import { t } from 'i18next';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required(t('STREAMING.REQUIRED.nameRequired') as string),
  gopSize: Yup.number()
    .typeError(t('VALIDATION.between', { x: 0, y: 1000 }) as string)
    .integer(t('VALIDATION.between', { x: 0, y: 1000 }) as string)
    .min(-1, t('VALIDATION.between', { x: 0, y: 1000 }) as string)
    .max(1000, t('VALIDATION.between', { x: 0, y: 1000 }) as string),
  videoBitrate: Yup.number()
    .typeError(t('VALIDATION.between', { x: 0, y: 100000 }) as string)
    .integer(t('VALIDATION.between', { x: 0, y: 100000 }) as string)
    .min(0, t('VALIDATION.between', { x: 0, y: 100000 }) as string)
    .max(100000, t('VALIDATION.between', { x: 0, y: 100000 }) as string)
    .when('shapingBitrate', {
      is: (shapingBitrate: number) => shapingBitrate !== -1,
      then: Yup.number().required()
    }),

  audioBitrate: Yup.number()
    .typeError(t('VALIDATION.between', { x: 14, y: 576 }) as string)
    .integer(t('VALIDATION.between', { x: 14, y: 576 }) as string)
    .min(14, t('VALIDATION.between', { x: 14, y: 576 }) as string)
    .max(576, t('VALIDATION.between', { x: 14, y: 576 }) as string),
  presenterJitterbufferSizeMs: Yup.number()
    .typeError(t('VALIDATION.between', { x: 0, y: 5000 }) as string)
    .integer(t('VALIDATION.between', { x: 0, y: 5000 }) as string)
    .min(0, t('VALIDATION.between', { x: 0, y: 5000 }) as string)
    .max(5000, t('VALIDATION.between', { x: 0, y: 5000 }) as string),
  interleaveAdjustmentMs: Yup.number()
    .typeError(t('VALIDATION.between', { x: -5000, y: 5000 }) as string)
    .integer(t('VALIDATION.between', { x: -5000, y: 5000 }) as string)
    .min(-5000, t('VALIDATION.between', { x: -5000, y: 5000 }) as string)
    .max(5000, t('VALIDATION.between', { x: -5000, y: 5000 }) as string),
  pcrWindowSize: Yup.number()
    .typeError(t('VALIDATION.between', { x: 0, y: 10000 }) as string)
    .integer(t('VALIDATION.between', { x: 0, y: 10000 }) as string)
    .min(0, t('VALIDATION.between', { x: 0, y: 10000 }) as string)
    .max(10000, t('VALIDATION.between', { x: 0, y: 10000 }) as string),
  shapingVbvSizeMs: Yup.mixed().test({
    name: 'intOrUnset',
    message: t('VALIDATION.between', { x: 500, y: 3000 }) as string,
    test: val => {
      if (val === 'Unset' || val === undefined) {
        return true;
      }
      if (val >= 500 && val <= 3000) {
        return true;
      }
      return false;
    }
  }),
  resolutionWidth: Yup.mixed().when('resolutionMode', {
    is: (resolutionMode: number) => Number(resolutionMode) === 1,
    then: Yup.number()
      .required(t('VALIDATION.required', { item: t('STREAMING.TRANSCODERS.ADD.width') }) as string)
      .typeError(t('VALIDATION.between', { x: 1, y: 9999 }) as string)
      .min(1, t('VALIDATION.between', { x: 1, y: 9999 }) as string)
      .max(9999, t('VALIDATION.between', { x: 1, y: 9999 }) as string),
    otherwise: Yup.mixed().notRequired()
  }),

  resolutionHeight: Yup.mixed().when('resolutionMode', {
    is: (resolutionMode: any) => Number(resolutionMode) === 1,
    then: Yup.number()
      .required(t('VALIDATION.required', { item: t('STREAMING.TRANSCODERS.ADD.height') }) as string)
      .typeError(t('VALIDATION.between', { x: 1, y: 9999 }) as string)
      .min(1, t('VALIDATION.between', { x: 1, y: 9999 }) as string)
      .max(9999, t('VALIDATION.between', { x: 1, y: 9999 }) as string),
    otherwise: Yup.mixed().notRequired()
  }),

  shapingBufferingIntervalMs: Yup.mixed().test({
    name: 'intOrUnset',
    message: t('VALIDATION.between', { x: 500, y: 3000 }) as string,
    test: val => {
      if (val === 'Unset' || val === undefined) {
        return true;
      }
      if (val >= 500 && val <= 3000) {
        return true;
      }
      return false;
    }
  }),
  outputPacingInterval: Yup.mixed().test({
    name: 'intOrUnset',
    message: t('VALIDATION.between', { x: 0, y: 1000 }) as string,
    test: val => {
      if (val === 'Unset' || val === undefined) {
        return true;
      }
      if (val >= 0 && val <= 1000) {
        return true;
      }
      return false;
    }
  }),
  shapingMaxBitratePercent: Yup.number()
    .typeError(t('VALIDATION.between', { x: 50, y: 150 }) as string)
    .integer(t('VALIDATION.between', { x: 50, y: 150 }) as string)
    .min(50, t('VALIDATION.between', { x: 50, y: 150 }) as string)
    .max(150, t('VALIDATION.between', { x: 50, y: 150 }) as string),
  shapingBitrate: Yup.number()
    .typeError(t('VALIDATION.between', { x: 0, y: 100000 }) as string)
    .integer(t('VALIDATION.between', { x: 0, y: 100000 }) as string)
    .min(-1, t('VALIDATION.between', { x: 0, y: 100000 }) as string)
    .max(100000, t('VALIDATION.between', { x: 0, y: 100000 }) as string)
});
