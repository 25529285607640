import './formInput.scss';

import { FormControl, FormGroup, FormLabel, Icon, LabelValue } from '@hai/ui-react';
import { DefaultTFuncReturn } from 'i18next';
import React, { ChangeEvent, ReactElement } from 'react';

export interface IFormInput {
  autoFocus?: boolean;
  disabled?: boolean;
  defaultValue?: string | number | DefaultTFuncReturn;
  item?: any;
  isHidden?: Object;
  label?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  name: string;
  required?: boolean;
  helpMessage?: string;
  placeholder?: string;
  showHint?: boolean;
  inputType?: string;
  viewOnly?: boolean;
  pwdToggle?: boolean;
  classNames?: string;
  showCopy?: boolean;
  icon?: string;
  dataAuto?: string;
  as?: 'input' | 'textarea' | 'select';
  inputComponent?: ReactElement;
  onIconClick?: () => void;
  onFocus?: (e: any) => void;
}
export const FormInput = (props: IFormInput) => {
  const {
    as = 'input',
    autoFocus,
    // Warning Classnames is not compatible with isHidden Instead pass the class to isHidden
    classNames,
    defaultValue = props.item?.[props.name],
    disabled = false,
    helpMessage,
    icon,
    inputComponent,
    inputType = 'text',
    isHidden = '',
    label,
    maxLength,
    name,
    onChange,
    onFocus,
    onIconClick,
    placeholder,
    pwdToggle,
    required,
    showCopy = false,
    showHint = false,
    viewOnly,
    dataAuto = ''
  } = props;

  let dataAutoGroup = dataAuto;
  if (dataAuto) {
    dataAutoGroup = 'group_' + dataAuto;
  }
  if (isHidden !== '' && classNames !== undefined) {
    console.warn('The classnames prop will not work with isHidden.');
    console.warn('Use isHidden`s className instead');
  }
  const getInputComponent = () => {
    if (viewOnly) {
      const iconJsx = [defaultValue || helpMessage];
      if (icon) {
        // fix for comma in copy to clipboard and fix for [object] shown in label with icon
        iconJsx.push(<Icon iconname={icon} size='sm2' className='icon' />);
      }
      return (
        <div className='mb-4 form-input-viewonly' data-auto={name}>
          <LabelValue showCopy={showCopy} useFsMask={true} className={'label-value with-icon'}>
            {iconJsx}
          </LabelValue>
        </div>
      );
    }

    if (inputComponent) {
      return inputComponent;
    }

    return (
      <FormControl
        as={as}
        autoFocus={autoFocus}
        type={inputType}
        disabled={disabled}
        value={defaultValue}
        onChange={onChange}
        showHint={showHint}
        placeholder={placeholder || helpMessage}
        name={name}
        {...(maxLength ? { maxLength } : {})}
        pwdToggle={pwdToggle}
        iconName={icon}
        onIconClick={onIconClick}
        onFocus={onFocus}
        useFsMask={true}
      />
    );
  };

  return (
    <FormGroup
      data-auto={dataAutoGroup}
      {...isHidden}
      {...(classNames ? { className: classNames } : {})}
    >
      {label && (
        <FormLabel
          data-auto='title'
          required={!viewOnly && required}
          className={label === ' ' ? 'invisible' : ''}
        >
          {/* Haiui hack to allow empty labels */}
          {label === ' ' ? '.' : label}
        </FormLabel>
      )}
      {getInputComponent()}
    </FormGroup>
  );
};
