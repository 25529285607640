import { IGeneralApiProps } from 'api/global.type';
import { convertApiEmptyToEmpty, decodeResponse } from 'api/utils/helper';
import { AxiosError } from 'axios';

import client from '../utils/client';
import { ITranscoder, ITranscoderWrapper } from './transcoder.type';

export const getTranscoders = (props?: IGeneralApiProps) => {
  return client
    .get('/transcoders')
    .then(resp => {
      const data: ITranscoderWrapper = resp.data;
      return apiToFormdata(data.transcoderList);
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return e;
    });
};

interface IAddProps extends IGeneralApiProps {
  apiParams: {
    uuid: string;
  };
}
export const addTranscoder = (props: IAddProps) => {
  const { apiParams, onError } = props;

  return client
    .post('/transcoders', formdataToApi(apiParams))
    .then(resp => {
      if (resp.status === 200) {
        return { success: true, ...resp.data };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

export const updateTranscoder = (props: IAddProps) => {
  const { apiParams, onError } = props;

  return client
    .put(`/transcoders/${apiParams.uuid}`, formdataToApi(apiParams))
    .then(resp => {
      if (resp.status === 200) {
        return { ...resp.data, success: true };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

interface IDeleteTranscoder extends IGeneralApiProps {
  transcoder: ITranscoder;
}

export const deleteTranscoder = (props: IDeleteTranscoder) => {
  const { onError, transcoder } = props;
  return client
    .delete(`/transcoders/${transcoder.uuid}`)
    .then(() => {
      return true;
    })
    .catch((e: AxiosError) => {
      if (e.status === 204) {
        return true; // no response from the server is expected...
      }

      onError && onError(e);
      return false;
    });
};

const formdataToApi = (formData: any) => {
  let apiData = <any>{
    ...formData
  };

  if (!apiData.presenterJitterbufferSizeMs) {
    apiData.presenterJitterbufferSizeMs = 250;
  }
  if (!apiData.framerate) {
    apiData.framerate = -1;
  }

  if (!apiData.resolutionMode) {
    apiData.resolutionMode = 3;
  }

  if (!apiData.videoType) {
    apiData.videoType = 'avc';
  }
  if (apiData.videoType === 'hevc') {
    apiData.encodingProfile = 'cbr';
  } else {
    apiData.encodingProfile = 'vbr';
  }
  if (!apiData.framerate) {
    apiData.framerate = '-1.0';
  }
  if (!apiData.metadata) {
    apiData.metadata = false;
  }

  if (!isNaN(Number(apiData.encoderType))) {
    apiData.encoderType = 'nvidia';
    apiData.deviceId = formData.encoderType;
  }

  if (apiData.pcrWindowSize === '') {
    delete apiData.pcrWindowSize;
  }
  if (apiData.presenterJitterbufferSizeMs === '') {
    delete apiData.presenterJitterbufferSizeMs;
  }

  if (formData.videoType === 'unset') {
    apiData.videoType = 'avc';
  }
  if (formData.videoType === 'mpeg4') {
    if (formData.videoProfile === 'simple') {
      apiData.videoProfile = 'mpeg4_asp';
    } else if (formData.videoProfile === 'main') {
      apiData.videoProfile = 'mpeg4_main';
    }
  } else if (formData.videoType === 'mpeg2video') {
    if (formData.videoProfile === 'simple') {
      apiData.videoProfile = 'mpeg2_simple';
    } else if (formData.videoProfile === 'main') {
      apiData.videoProfile = 'mpeg2_main';
    }
  }

  return apiData;
};

const simplifyVideoProfile = (i: any) => {
  // reset to auto if not an option
  if (['mpeg2video', 'mpeg4'].indexOf(i.videoType) === -1) {
    return 'unset';
  }
  // if unset
  if (i.videoProfile === 'unset') {
    return i.videoProfile;
  }
  // option for codecProfile set to main or simple.
  return i.videoProfile.indexOf('main') !== -1 ? 'main' : 'simple';
};

const apiToFormdata = (apiData: any) => {
  return apiData.map((i: any) => {
    return {
      ...i,
      name: decodeResponse(i.name),
      deviceId: `${i.deviceId}`,
      videoProfile: simplifyVideoProfile(i),
      encoderType: i.encoderType === 'nvidia' ? `${i.deviceId}` : i.encoderType,
      pcrWindowSize: convertApiEmptyToEmpty(i.pcrWindowSize) || '',
      gopSize: convertApiEmptyToEmpty(i.gopSize) || '',
      videoBitrate: convertApiEmptyToEmpty(i.videoBitrate) || '',
      audioBitrate: convertApiEmptyToEmpty(i.audioBitrate) || '',
      interleaveAdjustmentMs: convertApiEmptyToEmpty(i.interleaveAdjustmentMs) || '',
      qualityLevel: convertApiEmptyToEmpty(i.qualityLevel) || '',
      resolutionHeight: convertApiEmptyToEmpty(i.resolutionHeight) || '',
      resolutionWidth: convertApiEmptyToEmpty(i.resolutionWidth) || '',
      shapingBitrate: convertApiEmptyToEmpty(i.shapingBitrate) || '',
      klvUasFilterEnabledSets: 'min',
      klvSecurityUasFilterEnabledSets: 'min',
      shapingBufferingIntervalMs: convertApiEmptyToEmpty(i.shapingBufferingIntervalMs) || '',
      shapingMaxBitratePercent: convertApiEmptyToEmpty(i.shapingMaxBitratePercent) || '',
      shapingTargetBitratePercent: convertApiEmptyToEmpty(i.shapingTargetBitratePercent) || '',
      shapingVbvSizeMs: convertApiEmptyToEmpty(i.shapingVbvSizeMs) || '',
      state: i.state === -1 ? 5 : i.state,
      outputPacingInterval: i.outputPacingInterval !== -1 ? i.outputPacingInterval : 100
    };
  });
};
