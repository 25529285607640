import './transcoders.scss';

import { Button, Dialog, colorValue } from '@hai/ui-react';
import { deleteTranscoder } from 'api/transcoder/transcoder';
import { ITranscoder } from 'api/transcoder/transcoder.type';
import ActionBar, { Status } from 'components/actionBar/ActionBar';
import SortDropdown from 'components/actionBar/SortDropdown';
import { EnumSortDirection, EnumSortType } from 'components/actionBar/SortManager';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import NoContent from 'components/noItems/noContent';
import StreamingPage from 'components/streaming/StreamingPage';
import { AddTranscoder } from 'components/streaming/transcoders/AddTranscoder';
import TranscoderList from 'components/streaming/transcoders/TranscoderList';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { StreamingContext } from 'context/streaming/streaming';
import { EnumTranscodersAction } from 'context/streaming/transcoders';
import { t } from 'i18next';
import { RoleContext } from 'permissions/role/Roles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFormRef } from 'utils/hooks/useFormRef';
import { useGetPresets } from 'utils/hooks/useGetPresets';
import { useGetTranscoders } from 'utils/hooks/useGetTranscoders';
import { useTranscoderInfo } from 'utils/hooks/useTranscoderInfo';
import { actionStatus, convertActionTypeForSort, getActionStatusColor } from 'utils/streaming';

const Transcoders = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nothingFound, setNothingFound] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [direction, setDirection] = useState(EnumSortDirection.ASCENDING);
  const [sorting, setSorting] = useState<string>('name');
  const [buttonState, setButtonState] = useState('idle');
  const [isSearchOn, setIsSearchOn] = useState(false);
  const [newItem, setNewItems] = useState('');
  const [actionFilter, setActionFilter] = useState('None');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const formControl = useFormRef();

  const getTranscoderService = useGetTranscoders();
  const getPresetsService = useGetPresets();

  const role = useContext(RoleContext);

  const { transcoders, transcodersClean, streamingDispatch } = useContext(StreamingContext);
  const { dispatch } = useContext(GlobalContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);

  const getTranscoderInfo = useTranscoderInfo();

  useEffect(() => {
    if (!transcodersClean && !isLoading && !nothingFound) {
      setIsLoading(true);
      getTranscoderService({
        onSuccess: () => setIsLoading(false),
        onEmpty: () => setNothingFound(true)
      });
    }
  }, [getTranscoderService, isLoading, nothingFound, streamingDispatch, transcodersClean]);

  const statusTypes: Status[] = [
    {
      status: actionStatus.active,
      color: getActionStatusColor(actionStatus.active)
    },
    {
      status: actionStatus.alert,
      color: getActionStatusColor(actionStatus.alert)
    },
    {
      status: actionStatus.inactive,
      color: getActionStatusColor(actionStatus.inactive)
    }
  ];

  const memoizedSetTranscoders = useCallback(
    (value: any) => {
      streamingDispatch({ type: EnumTranscodersAction.SET_TRANSCODERS, payload: value });
    },
    [streamingDispatch]
  );

  const actionDelete = () => {
    const triggerDelete = async (transcoder: ITranscoder) => {
      const result = await deleteTranscoder({ transcoder });
      getPresetsService();

      return { result, item: transcoder };
    };
    (async () => {
      if (transcoders) {
        await Promise.all(transcoders.filter(i => i.selected).map(triggerDelete)).then(values => {
          const successItems = values.filter(x => x.result);
          if (successItems) {
            notifyDispatch({
              type: EnumNotify.ADD_NOTIFICATION,
              payload: {
                type: 'Success',
                // groupId: 'deleteTranscoder',
                message: {
                  name: 'STREAMING.TRANSCODERS.MESSAGES.deleted',
                  params: { name: successItems[0].item.name },
                  items: successItems
                }
              }
            });
          } else {
            // TODO: Show error message if deleting failed.
          }
        });
        getTranscoderService({
          onSuccess: () => setIsLoading(false),
          onEmpty: () => setNothingFound(true)
        });
      }
    })();
  };

  const onDeleteClick = () => {
    const count = transcoders?.filter(i => i.selected).length;
    dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        onConfirm: actionDelete,
        title: t('STREAMING.TRANSCODERS.DELETE_MODAL.title', { count }),
        desc: t('STREAMING.TRANSCODERS.DELETE_MODAL.desc', { count })
      }
    });
  };

  const saveCallback = (item: any) => {
    getTranscoderService({
      onSuccess: () => {
        setNewItems(item);
        setIsLoading(false);
      },
      onEmpty: () => {
        setNothingFound(true);
      }
    }).then(() => {
      setButtonState('idle');
      getPresetsService();
      handleClose();
    });
  };

  const sortInfo = {
    name: { title: t('SORTING.TRANSCODER.name'), type: EnumSortType.NATURAL_SORT },
    status: {
      title: t('SORTING.TRANSCODER.status'),
      type: EnumSortType.CUSTOM_FUNCTION,
      comparator: (a: any, b: any, dirVal: any) => {
        const aa = getTranscoderInfo(a);
        const bb = getTranscoderInfo(b);

        // convert actionType string to number for sorting
        let aNum = convertActionTypeForSort(aa.actionType);
        let bNum = convertActionTypeForSort(bb.actionType);

        let result = 0; // equal
        if (aNum > bNum) {
          result = 1;
        } else if (aNum < bNum) {
          result = -1;
        }
        if (dirVal < 0) {
          if (result === -1) {
            return 1;
          } else if (result === 1) {
            return -1;
          }
        }
        return result as 0 | 1 | -1;
      }
    },
    resolution: { title: t('SORTING.TRANSCODER.resolution'), type: EnumSortType.NATURAL_SORT },
    videoBitrateFormatted: {
      title: t('SORTING.TRANSCODER.videoBitrate'),
      type: EnumSortType.NATURAL_SORT
    },
    gop: { title: t('SORTING.TRANSCODER.gop'), type: EnumSortType.NATURAL_SORT },
    audioEnabled: { title: t('SORTING.TRANSCODER.audio'), type: EnumSortType.NATURAL_SORT }
  };

  const onChangeSort = (sections: any) => {
    if (sections.sortBy?.eventKey) {
      setSorting(sections.sortBy?.eventKey);
    }

    if (sections.direction?.eventKey === 'ascending') {
      setDirection(EnumSortDirection.ASCENDING);
    } else if (sections.direction?.eventKey === 'descending') {
      setDirection(EnumSortDirection.DESCENDING);
    } else {
      setDirection(EnumSortDirection.IDLE);
    }
  };

  const onSearch = (searchItems: any) => {
    setIsSearchOn(!!searchItems.length);
  };

  const isNoSearchResult = isSearchOn && !transcoders?.length;
  const actionButtons = role.can('delete', 'transcoders')
    ? {
        ActionButtons: <Button onClick={onDeleteClick}>{t('delete')}</Button>
      }
    : {};

  const onSelectAll = (selected: boolean | 'indeterminate' | 'none') => {
    return streamingDispatch({
      type: EnumTranscodersAction.SELECT_ALL,
      payload: selected === false
    });
  };

  return (
    <StreamingPage>
      {transcodersClean?.length !== 0 && (
        <ActionBar
          cleanItems={transcodersClean}
          getStatus={getTranscoderInfo}
          items={transcoders}
          onSearch={onSearch}
          onSelectAll={onSelectAll}
          searchKeys={['name', 'resolution', 'videoBitrateFormatted', 'gop', 'audioEnabled']}
          setActionFilter={setActionFilter}
          setItems={memoizedSetTranscoders}
          statusTypes={statusTypes}
          {...actionButtons}
          {...(role.can('add', 'transcoders')
            ? {
                actionRightPrimary: {
                  onClick: handleShow,
                  children: t('STREAMING.TRANSCODERS.addTranscoder')
                }
              }
            : {})}
          sortDropdown={
            <SortDropdown
              direction={direction}
              title={sorting}
              onChangeSort={onChangeSort}
              sortInfo={sortInfo}
              sorting={sorting}
            />
          }
        />
      )}
      {transcoders && !isLoading && (
        <TranscoderList
          direction={direction}
          hideCheckbox={!!actionButtons?.ActionButtons}
          sorting={sorting}
          sortInfo={sortInfo}
          list={transcoders}
          selectedFilter={actionFilter}
          newItem={newItem}
          saveCallback={saveCallback}
        />
      )}
      {transcodersClean?.length === 0 && (
        <NoContent
          buttonText={t('STREAMING.TRANSCODERS.addTranscoder')}
          buttonAction={handleShow}
          detailsText={t('STREAMING.TRANSCODERS.createTranscoder')}
          iconName='Transcoder'
          noItemMsg={t('STREAMING.TRANSCODERS.noTranscoders')}
        />
      )}

      <Dialog
        title={t('STREAMING.TRANSCODERS.addTranscoder')}
        size='lg'
        dialogType='activity'
        content={
          <AddTranscoder
            saveCallback={saveCallback}
            formControl={formControl}
            buttonState={buttonState}
            setButtonState={setButtonState}
          />
        }
        show={show}
        accentColor={colorValue('haiui-aqua-01')}
        headerIcon='Transcoder'
        onClose={handleClose}
        buttons={[
          { variant: 'secondary', onClick: handleClose, label: t('cancel') },
          {
            variant: 'primary',
            close: false,
            disabled: !formControl.formSubmitActive,
            state: buttonState,
            onClick: () => submitHandler(formControl.formRef, notifyDispatch),
            label: t('STREAMING.TRANSCODERS.addTranscoder')
          }
        ]}
      />
      {isNoSearchResult && <NoContent iconName='Search' noItemMsg={t('noSearchResult')} />}
    </StreamingPage>
  );
};

export default Transcoders;
