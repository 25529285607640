import { IGeneralApiProps } from 'api/global.type';
import { IPresets, getPresets } from 'api/presets/presets';
import { EnumPresets, PresetContext } from 'context/preset';
import { useCallback, useContext } from 'react';
import { useService } from 'utils/useService';

export const useGetPresets = (props?: IGeneralApiProps) => {
  const fetchPresets = useService(getPresets);
  const { dispatch } = useContext(PresetContext);

  const getPresetsService = useCallback(
    (callback?: Function) => {
      return fetchPresets(props).then((value: IPresets) => {
        if (value) {
          dispatch({ type: EnumPresets.SET_PRESETS, payload: value });
        }
        callback && callback(value);
      });
    },
    [dispatch, fetchPresets, props]
  );

  return getPresetsService;
};
