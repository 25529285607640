import natsort from 'natsort';
import React, { ReactElement } from 'react';

export type ISortComparator<T> = (a: T, b: T, dirVal: any) => -1 | 0 | 1;

export enum EnumSortType {
  GENERIC = 1,
  NATURAL_SORT,
  CUSTOM_FUNCTION // via 'comparator' func
}

export enum EnumSortDirection {
  IDLE = 0,
  DESCENDING = -1,
  ASCENDING = 1
}

// for our view models
export interface KrakenObject {
  uuid: number | string;
}

/* extends {id:string|number}*/
interface Props<T extends KrakenObject> {
  children: ReactElement[]; // each mapping to a T element with key = T.id
  list: T[];
  newItem?: string;
  sortInfo: {
    [K in keyof Partial<T> | string]: {
      title: string;
      type: EnumSortType;
      /*
       *  Optional
       *  Supply custom comparator function receiving model A and B as input and returning -1 | 0 | 1
       */
      comparator?: ISortComparator<T>;
      /*
       *  Optional
       *  Value provider for the field. Takes model as input and output value to sort on
       */
      provider?: (arg: T) => any;
    };
  };
  sortKey: keyof T | string;
  direction: EnumSortDirection;
}

// local helper functions
const natsortFunc = natsort({ insensitive: true });

// No sorting sorts by 'id'
const comparatorNoSorting = (a: any, b: any, dirVal: any): 0 | 1 | -1 => {
  let result = 0;
  if (a.id > b.id) {
    result = 1;
  } else if (a.id < b.id) {
    result = -1;
  }

  if (dirVal < 0) {
    if (result === -1) {
      return 1;
    } else if (result === 1) {
      return -1;
    }
    return 0;
  }

  return result as 0 | 1 | -1;
};

// Comparator for strings (natural sort)
const comparatorNaturalSort = (
  item: string | number | symbol,

  newItem: string,
  a: any,
  b: any,
  dirVal: any,
  provider?: (model: any) => any
): 0 | 1 | -1 => {
  let valueA = a[item];
  let valueB = b[item];
  if (provider) {
    valueA = provider(a);
    valueB = provider(b);
  }

  // keep new stream form on top
  if (a?.id === newItem || a?.uuid === newItem) {
    return -1;
  }

  // ascend: empty str -> bottom
  if (!valueA && dirVal > 0) {
    return 1;
  }
  // descend: empty str -> top
  if (!valueA && dirVal < 0) {
    return -1;
  }

  const result = natsortFunc(valueA, valueB);

  if (dirVal < 0) {
    if (result === -1) {
      return 1;
    } else if (result === 1) {
      return -1;
    }
    return 0;
  }
  return result as 0 | 1 | -1;
};

// Generic comparator (for numbers)
const comparatorGeneric = (
  item: string | number | symbol,
  newItem: string,
  a: any,
  b: any,
  dirVal: any,
  provider?: (model: any) => any
): 0 | 1 | -1 => {
  let result = 0;

  // keep new stream form on top
  if (a?.id === newItem || a?.uuid === newItem) {
    return -1;
  }

  let valueA = a[item];
  let valueB = b[item];
  if (provider) {
    valueA = provider(a) ?? '';
    valueB = provider(b) ?? '';
  }

  if (valueA > valueB) {
    result = 1;
  } else if (valueA < valueB) {
    result = -1;
  }

  if (dirVal < 0) {
    return (-1 * result) as 1 | -1; // descending
  }

  return result as 0 | 1 | -1;
};

/**
 *
 */
export const SortManager: <T extends KrakenObject>(
  props: Props<T>
) => React.ReactElement<Props<T>> = ({
  children,
  list,
  newItem = '',
  sortInfo,
  direction,
  sortKey
}) => {
  let comparator = comparatorNoSorting;
  if (sortInfo[sortKey] !== undefined) {
    if (sortInfo[sortKey].type === EnumSortType.GENERIC) {
      comparator = function (a: any, b: any) {
        return comparatorGeneric(sortKey, newItem, a, b, direction, sortInfo[sortKey].provider);
      };
    } else if (sortInfo[sortKey].type === EnumSortType.NATURAL_SORT) {
      comparator = function (a: any, b: any) {
        return comparatorNaturalSort(sortKey, newItem, a, b, direction, sortInfo[sortKey].provider);
      };
    } else if (sortInfo[sortKey].type === EnumSortType.CUSTOM_FUNCTION) {
      comparator = sortInfo[sortKey].comparator as (a: any, b: any, dirVal: any) => 0 | 1 | -1;
    }
  } else {
    console.error('Sort key is not supported, verify sortInfo or clear localstorage', sortKey);
  }

  // Actual sort
  if (Array.isArray(children)) {
    children = [...children].sort((a, b) => {
      const item1 = list.find(item => item?.uuid == a.key);
      const item2 = list.find(item => item?.uuid == b.key);

      return comparator(item1, item2, direction);
    });
  }

  return <>{children}</>;
};
