import axios from 'axios';

import config from './config';
import setupInterceptorsTo from './interceptor';

const client = setupInterceptorsTo(
  axios.create({
    baseURL: `${config.ApiUrl}${config.ApiPath}`,
    headers: {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'content-type': 'application/json'
    },
    withCredentials: true
  })
);

export const disableNormalizingConfig = {
  headers: {
    disableNormalizing: true
  }
};

export const handleErrors = (props: any) => {
  if (!props) {
    return;
  }
  const { ignoreErrors, ignoreError } = props;

  if (ignoreErrors) {
    client.defaults.headers.common['ignoreErrors'] = true;
  }
  if (ignoreError) {
    client.defaults.headers.common['ignoreError'] = ignoreError;
  }
};

export default client;
