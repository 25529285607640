import { List, colorValue } from '@hai/ui-react';
import { thumbnailDefault } from 'assets/images';
import React from 'react';

interface IProps {
  thumbnail: string;
  title: string;
  textSize?: string;
}

const ListThumbnail = (props: IProps) => {
  const { title, thumbnail, textSize } = props;
  return (
    <List.Thumbnail
      src={title ? thumbnailDefault : thumbnail}
      overlayText={title}
      useFsMask={true}
      // HAIUI has an error with how it processes customOverlay
      // that doesn`t alow us to use the overlay (see console for error).
      // Must be fixed in haiui before this can be enabled.
      // customOverlay={true}
      overlayStyles={{
        backgroundColor: 'transparent',
        color: colorValue('haiui-gray-06'),
        fontFamily: `'HCo Gotham SSm Medium', Helvetica, Arial, sans-serif`,
        fontSize: textSize ?? '10px',
        fontWeight: '500',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        display: 'flex',
        textAlign: 'center',
        position: 'absolute'
      }}
    />
  );
};

ListThumbnail.displayName = 'List.Thumbnail';

export default ListThumbnail;
