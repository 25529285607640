import { IOutputItem } from 'api/outputs/output.type';
import { IStreamingState } from 'context/streaming/streaming';

export interface IOutputsState {
  outputs: IOutputItem[] | null;
  outputsClean: IOutputItem[] | null;
}

export enum EnumOutputsAction {
  SET_OUTPUTS = 'SET_OUTPUTS',
  SET_OUTPUTS_CLEAN = 'SET_OUTPUTS_CLEAN',
  SELECT_OUTPUT = 'SELECT_OUTPUT',
  SELECT_ALL = 'SELECT_ALL_OUTPUTS',
  RESET = 'RESET'
}

export interface IOutputsAction {
  type: EnumOutputsAction;
  payload: any;
}

// Init states
export const initOutputsState: IOutputsState = {
  outputs: null,
  outputsClean: null
};

// Reducer
export const outputsReducer = (state: IStreamingState, action: IOutputsAction): IOutputsState => {
  switch (action.type) {
    case EnumOutputsAction.SET_OUTPUTS_CLEAN: {
      const newOutputs = action.payload?.map((archive: IOutputItem) => {
        const selected = state.outputsClean?.find(i => i.uuid === archive.uuid)?.selected;
        const newItem = archive;
        newItem.selected = selected;
        return newItem;
      });
      return {
        ...state,
        outputs: newOutputs,
        outputsClean: newOutputs
      };
    }
    case EnumOutputsAction.SET_OUTPUTS: {
      return {
        ...state,
        outputs: action.payload
      };
    }
    case EnumOutputsAction.SELECT_OUTPUT: {
      const { id, selected } = action.payload;
      const newOutputs = state.outputs?.map((i: IOutputItem) =>
        i.uuid === id
          ? {
              ...i,
              selected
            }
          : i
      );
      const newOutputsClean = state.outputsClean?.map((i: IOutputItem) =>
        i.uuid === id
          ? {
              ...i,
              selected
            }
          : i
      );
      return {
        ...state,
        outputs: newOutputs || null,
        outputsClean: newOutputsClean || null
      };
    }
    case EnumOutputsAction.SELECT_ALL: {
      const selected = action.payload;
      const newOutputs = state.outputs?.map((i: IOutputItem) => {
        const newItem = i;
        newItem.selected = selected;
        return newItem;
      });
      const newOutputsClean = state.outputsClean?.map((i: IOutputItem) => {
        const newItem = i;
        newItem.selected = selected;
        return newItem;
      });
      return {
        ...state,
        outputs: newOutputs || [],
        outputsClean: newOutputsClean || []
      };
    }
    case EnumOutputsAction.RESET: {
      return { ...state, ...initOutputsState };
    }
    default:
      return state;
  }
};
