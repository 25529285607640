import { IInputItem } from 'api/inputs/input.type';
import { getStreamSnapshotURL as getStreamSnapshotUrl } from 'api/streaming/streaming';
import {
  EnumSnapshotTapPoint,
  EnumStreamStatus,
  EnumStreamSubStatus
} from 'api/streaming/streaming.type';
import { thumbnailDefault } from 'assets/images';
import { StreamingContext } from 'context/streaming/streaming';
import { useCallback, useContext } from 'react';
import { EnumThumbnail, actionStatus, getThumbnailTitle } from 'utils/streaming';

export const useInputInfo = () => {
  const { snapshots, streamsClean } = useContext(StreamingContext);

  const getInputInfo = useCallback(
    (i: IInputItem) => {
      const getInfo = (
        item: IInputItem
      ): {
        thumbnail: string;
        thumbnailTitle: string;
        icon: string;
        actionType: string;
      } => {
        const inputStreams = streamsClean?.filter(s => s.input.includes(item.uuid)) || [];

        const inputStatus = inputStreams.map(i => {
          return {
            mode: i.mode,
            status: i.status,
            subStatus: i.subStatus,
            uuid: i.uuid
          };
        });

        const inputSnapshots =
          snapshots?.filter(s => inputStatus.findIndex(i => i.uuid === s.sessionId) !== -1) || [];

        const hasSnapshot = inputSnapshots.length > 0;

        // If we are a bypass stream and have no thumbnail, try to set an ok state
        // TODO - This isn't a great check and should be improved with input specific state in APIv3
        let bypassStream = false;
        let onlineStreams = inputStatus.filter(i => i.status !== 'STOPPED');
        if (onlineStreams.length === 1 && onlineStreams[0].mode === 'iorouter') {
          bypassStream = true;
        }

        const getSnapshotUrl = () => {
          if (hasSnapshot) {
            return `${getStreamSnapshotUrl(
              EnumSnapshotTapPoint.INPUT,
              inputSnapshots[0].sessionId
            )}?id=${inputSnapshots[0].snapshotId}`;
          } else {
            return thumbnailDefault;
          }
        };

        if (
          inputStatus.findIndex(
            i =>
              i.status === EnumStreamStatus.ACTIVE && i.subStatus === EnumStreamSubStatus.CONNECTED
          ) !== -1
        ) {
          return {
            thumbnail: getSnapshotUrl(),
            thumbnailTitle: getThumbnailTitle(
              hasSnapshot && !bypassStream ? EnumThumbnail.NORMAL : EnumThumbnail.STREAMING
            ),
            icon: 'StatusStreaming',
            actionType: actionStatus.online
          };
        }

        if (inputStatus.findIndex(i => i.status === EnumStreamStatus.WARNING) !== -1) {
          if (inputStatus.findIndex(i => i.subStatus === EnumStreamSubStatus.STARTING) !== -1) {
            return {
              thumbnail: getSnapshotUrl(),
              thumbnailTitle: getThumbnailTitle(EnumThumbnail.OFFLINE),
              icon: 'StatusWarning',
              actionType: actionStatus.alert
            };
          }

          if (inputStatus.findIndex(i => i.subStatus === EnumStreamSubStatus.NO_DATA) !== -1) {
            return {
              thumbnail: getSnapshotUrl(),
              thumbnailTitle: getThumbnailTitle(EnumThumbnail.NO_DATA),
              icon: 'StatusInputOffline',
              actionType: actionStatus.alert
            };
          }
          if (inputStatus.findIndex(i => i.subStatus === EnumStreamSubStatus.INPUT_ERROR) !== -1) {
            return {
              thumbnailTitle: getThumbnailTitle(EnumThumbnail.INPUT_ERROR),
              thumbnail: getSnapshotUrl(),
              icon: 'StatusInputOffline',
              actionType: actionStatus.alert
            };
          }

          // Not tracking OUTPUT_ERROR, TRANSCODER_ERROR, or ERROR directly
          // If we have a thumbnail the input is valid and ONLINE
          let thumbTitle;
          if (!hasSnapshot && !bypassStream) {
            thumbTitle = getThumbnailTitle(EnumThumbnail.WARNING);
          } else if (bypassStream) {
            thumbTitle = getThumbnailTitle(EnumThumbnail.STREAMING);
          } else {
            thumbTitle = getThumbnailTitle(EnumThumbnail.NORMAL);
          }
          return {
            thumbnailTitle: thumbTitle,
            thumbnail: hasSnapshot ? getSnapshotUrl() : '',
            icon: hasSnapshot || bypassStream ? 'StatusStreaming' : 'StatusWarning',
            actionType: hasSnapshot || bypassStream ? actionStatus.online : actionStatus.alert
          };
        }

        if (
          inputStatus.findIndex(
            i => i.status === EnumStreamStatus.STOPPED && i.subStatus === EnumStreamSubStatus.ERROR
          ) !== -1
        ) {
          return {
            thumbnail: getSnapshotUrl(),
            thumbnailTitle: getThumbnailTitle(EnumThumbnail.INCOMPLETE),
            icon: 'StatusInactive',
            actionType: actionStatus.inactive
          };
        }

        // fallback
        const fallbackInfo = {
          thumbnail: getSnapshotUrl(),
          thumbnailTitle: getThumbnailTitle(EnumThumbnail.OFFLINE),
          icon: 'StatusInactive',
          actionType: actionStatus.inactive
        };
        return fallbackInfo;
      };

      return getInfo(i);
    },
    [snapshots, streamsClean]
  );

  return getInputInfo;
};
