import './formSlider.scss';

import { FormControl, FormGroup, FormLabel, LabelValue, Slider } from '@hai/ui-react';
import { t } from 'i18next';
import React, { useState } from 'react';

interface IFormSliderWithInput {
  className?: string;
  formRef: any;
  min?: undefined | string;
  max?: undefined | string;
  step?: undefined | string;
  showRange?: { left: string; right: string };
  disabled?: boolean;
  defaultValue?: undefined | string;
  name: string;
  label: any;
  isModal?: boolean;
  viewOnly: boolean;
}

export const FormSliderWithInput = (props: IFormSliderWithInput) => {
  const {
    className,
    disabled = false,
    showRange = false,
    formRef,
    min = '0',
    max,
    step = '1',
    defaultValue,
    name,
    label,
    isModal,
    viewOnly = false
  } = props;

  const [value, setValue] = useState(formRef.current?.values?.[name] || defaultValue);

  const valueOnChange = (v: any) => {
    setValue(v);
    changeTextField(v);
  };

  const changeTextField = (v: any) => {
    if (v >= Number(min) && v <= Number(max)) {
      setValue(v);
      formRef.current.setValues({ ...formRef.current.values, [name]: v === min ? t('unset') : v });
    }
  };

  const textOnChange = (event: any) => {
    changeTextField(event.target.value);
  };

  return (
    <FormGroup className={className}>
      {label && <FormLabel>{label}</FormLabel>}
      <div className='slider-with-input'>
        <Slider
          defaultValue={value}
          disabled={disabled || viewOnly}
          min={min}
          max={max}
          {...(showRange ? { showRange: { left: showRange.left, right: showRange.right } } : {})}
          onChange={valueOnChange}
          step={step}
          withTooltip={isModal ? false : true}
        />
        {viewOnly ? (
          <div className='mb-4'>
            <LabelValue useFsMask={true} showCopy={false}>
              {value === min ? t('unset') : value}
            </LabelValue>
          </div>
        ) : (
          <FormControl
            placeholder={value === min ? t('unset') : value}
            disabled={disabled}
            name={name}
            onChange={textOnChange}
          />
        )}
      </div>
    </FormGroup>
  );
};
