import { Form } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { ICapture, ICaptureResp, getCapture } from 'api/capture/capture';
import { IDecklink, IDecklinkResp, getDecklink } from 'api/decklink/decklink';
import { getDevicesNdi } from 'api/devices/devices';
import { rtmpModeOptions, sourcesOptions, transportOptions } from 'api/global.type';
import { IDiscoverDevice, IInputItem } from 'api/inputs/input.type';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { FormButton } from 'components/common/form/formButton/formButton';
import { FormCheckbox } from 'components/common/form/formCheckbox/formCheckbox';
import { FormContainer, FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { buildFormSelect } from 'components/common/form/formSelect/formSelect';
import { IsHidden, isHidden } from 'components/isHidden/isHidden';
import { validationSchema } from 'components/streaming/inputs/inputValidation';
import { SrtForm } from 'components/streaming/srtForm/SrtForm';
import { SettingsContext } from 'context/settings/settings';
import { EnumInputsAction } from 'context/streaming/inputs';
import { StreamingContext } from 'context/streaming/streaming';
import { t } from 'i18next';
import { RoleCan, RoleContext } from 'permissions/role/Roles';
import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IFormRef } from 'utils/hooks/useFormRef';

interface FormLayout {
  applyBtn?: any;
  cancel?: () => void;

  formControl: IFormRef;
  formProps: any;
  item?: IInputItem;
  columns?: number;
  submitHandler?: (event: any) => void;
  buttonState?: ButtonStateType;
}

export const InputFormLayout = (props: FormLayout) => {
  const { buttonState, cancel, submitHandler, columns = 2, formControl, formProps, item } = props;
  const { formRef, formRefCallback, formSubmitActive } = formControl;
  const { inputCapture, inputDecklink, inputNdiList, streamingDispatch } =
    useContext(StreamingContext);

  formProps.restValidationProps = {
    validationSchema,
    innerRef: formRefCallback,
    enableReinitialize: true
  };

  const { networkInterface } = useContext(SettingsContext);
  const role = useContext(RoleContext);

  const [source, setSource] = useState<string>(item?.streamType || 'MPEG2TS');
  const [selectedRtmpMode, setSelectedRtmpMode] = useState<string>(item?.rtmpMode || 'client');
  const [srtMode, setSrtMode] = useState<string>(item?.srtMode || 'caller');
  const [decklinkMode, setDecklinkMode] = useState<any>([]);
  const [captureMode, setCaptureMode] = useState<any>([]);
  const ndiList = inputNdiList?.discoverDeviceList;

  const deckLinkList = useMemo(
    () =>
      inputDecklink?.portInfo?.map((i: IDecklink) => {
        return {
          option: i.label,
          value: `DECKLINK${i.portno}`
        };
      }) || [],
    [inputDecklink]
  );
  const setDeckLinkModeList = useCallback(
    (source: string) => {
      const decklinkIndex = Number(source.replace('DECKLINK', ''));
      return [
        {
          option: t('auto'),
          value: -1
        },
        ...(inputDecklink && inputDecklink?.portInfo?.length
          ? inputDecklink.portInfo[decklinkIndex].videomodes
          : [{}])
      ];
    },
    [inputDecklink]
  );

  useEffect(() => {
    if (source.indexOf('DECKLINK') !== -1) {
      setDecklinkMode(setDeckLinkModeList(source));
    }
  }, [setDeckLinkModeList, source]);

  const captureList = useMemo(
    () =>
      inputCapture?.portInfo?.map((i: ICapture) => {
        return {
          option: `${t('STREAMING.INPUTS.ADD.analogCapture')} ${i.portno + 1}`,
          value: `CAPTURE${i.portno}`
        };
      }) || [],
    [inputCapture]
  );

  const setCaptureModeList = useCallback(
    (source: string) => {
      const captureIndex = Number(source.replace('CAPTURE', ''));
      return [
        {
          option: t('auto'),
          value: -1
        },
        ...(inputCapture ? inputCapture.portInfo[captureIndex].videomodes : [{}])
      ];
    },
    [inputCapture]
  );

  useEffect(() => {
    if (source.indexOf('CAPTURE') !== -1) {
      setCaptureMode(setCaptureModeList(source));
    }
  }, [setCaptureModeList, source]);

  const ndiSourceItems = useMemo(
    () =>
      ndiList?.length
        ? ndiList.map((i: IDiscoverDevice) => {
            return {
              value: i.sourceName,
              option: i.sourceName
            };
          })
        : [{ value: 'none', option: t('none') }],
    [ndiList]
  );

  useEffect(() => {
    getDevicesNdi().then((resp: any) => {
      streamingDispatch({
        type: EnumInputsAction.SET_NDI,
        payload: resp
      });
    });

    getDecklink().then((resp: IDecklinkResp) => {
      streamingDispatch({
        type: EnumInputsAction.SET_DECKLINK,
        payload: resp
      });
    });
    getCapture().then((resp: ICaptureResp) => {
      streamingDispatch({
        type: EnumInputsAction.SET_CAPTURE,
        payload: resp
      });
    });
  }, [streamingDispatch]);

  const urlHelpMsg = () => {
    switch (source) {
      case 'MJPEGRAW':
        return t('STREAMING.INPUTS.ADD.urlRawHelp');
      case 'RTSP':
        return t('STREAMING.INPUTS.ADD.RTSPHelp');
      case 'RTMP':
        return t('STREAMING.INPUTS.ADD.RTMPHelp');
      case 'MJPEGHTTP':
        return t('STREAMING.INPUTS.ADD.MJPEG_HTTPHelp');
      default:
        return t('STREAMING.INPUTS.ADD.urlHelp');
    }
  };

  return (
    <FadeIn delay={300}>
      <Form {...formProps}>
        <FormContainer columns={columns}>
          <FormInput
            autoFocus
            item={item}
            label={t('name')}
            name='name'
            required
            viewOnly={role.cannot('edit', 'inputs', 'rtspTransport')}
            dataAuto='input_name'
          />
          <RoleCan I='edit' a='inputs'>
            {submitHandler && (
              <FormButton
                cancel={cancel}
                columns={columns}
                submitHandler={submitHandler}
                state={buttonState}
                disabled={!formSubmitActive}
              />
            )}
          </RoleCan>
        </FormContainer>
        <FormSectionDark title={t('STREAMING.INPUTS.inputParameters')}>
          <FormContainer columns={columns}>
            {buildFormSelect({
              label: t('STREAMING.INPUTS.ADD.source'),
              items: [...sourcesOptions, ...deckLinkList, ...captureList],
              selectName: 'streamType',
              selectedId: item?.streamType,
              viewOnly: role.cannot('edit', 'inputs', 'streamType'),
              onChange: (e: ChangeEvent<HTMLInputElement>) => setSource(e.target.value),
              dataAuto: 'input_source'
            })}

            {buildFormSelect({
              isHidden: isHidden({
                param: source,
                condition: (source: string) => ['RTMP'].indexOf(source) === -1
              }),
              label: t('STREAMING.INPUTS.ADD.mode'),
              items: rtmpModeOptions,
              viewOnly: role.cannot('edit', 'inputs', 'rtmpMode'),
              selectName: 'rtmpMode',
              onChange: (e: ChangeEvent<HTMLInputElement>) => setSelectedRtmpMode(e.target.value),
              dataAuto: 'input_rtmp_mode'
            })}
            <FormCheckbox
              formRef={formRef}
              label={t('STREAMING.INPUTS.ADD.ipv6')}
              name='ipv6'
              item={item}
              viewOnly={role.cannot('edit', 'inputs', 'ipv6')}
              isHidden={isHidden({
                param: source,
                condition: (source: string) => {
                  if (source === 'RTMP' && selectedRtmpMode === 'server') {
                    return false;
                  }
                  return true;
                }
              })}
            />
            <FormInput
              label={t('STREAMING.INPUTS.ADD.url')}
              name='url'
              required
              item={item}
              showHint
              viewOnly={role.cannot('edit', 'inputs', 'url')}
              helpMessage={urlHelpMsg()}
              isHidden={isHidden({
                param: source,
                className: 'grid-column kraken-span-2',
                condition: (source: string) => {
                  if ('RTMP' === source && selectedRtmpMode === 'server') {
                    return true;
                  }
                  if (['SRT', 'ST2110', 'GIGEVIS', 'NDI'].indexOf(source) !== -1) {
                    return true;
                  }
                  if (source.indexOf('DECKLINK') !== -1 || source.indexOf('CAPTURE') !== -1) {
                    return true;
                  }
                }
              })}
              dataAuto='input_url'
            />
            {buildFormSelect({
              isHidden: isHidden({
                param: source,
                condition: (source: string) => ['RTSP'].indexOf(source) === -1
              }),
              label: t('STREAMING.INPUTS.ADD.transport'),
              viewOnly: role.cannot('edit', 'inputs', 'rtspTransport'),
              items: transportOptions,
              selectName: 'rtspTransport',
              helpMessage: t('STREAMING.INPUTS.ADD.transportHelp'),
              dataAuto: 'input_rtsp_transport'
            })}

            {buildFormSelect({
              isHidden: isHidden({
                param: source,
                condition: (source: string) => source.indexOf('DECKLINK') === -1
              }),
              label: t('STREAMING.INPUTS.ADD.decklinkMode'),
              viewOnly: role.cannot('edit', 'inputs', 'decklink'),
              items: decklinkMode,
              selectName: 'decklinkMode',
              dataAuto: 'input_decklink_mode'
            })}

            {buildFormSelect({
              isHidden: isHidden({
                param: source,
                condition: (source: string) => source.indexOf('CAPTURE') === -1
              }),
              label: t('STREAMING.INPUTS.ADD.captureMode'),
              viewOnly: role.cannot('edit', 'inputs', 'capture'),
              items: captureMode,
              selectName: 'captureMode',
              dataAuto: 'input_capture_mode'
            })}

            <FormInput
              label={t('STREAMING.INPUTS.ADD.port')}
              name='port'
              required
              viewOnly={role.cannot('edit', 'inputs', 'port')}
              item={item}
              showHint
              placeholder={t('STREAMING.INPUTS.ADD.portHelp')}
              helpMessage={t('STREAMING.INPUTS.ADD.portHelp')}
              isHidden={isHidden({
                param: source,
                condition: (source: string) => {
                  if (source === 'RTMP' && selectedRtmpMode === 'server') {
                    return false;
                  }
                  return true;
                }
              })}
              dataAuto='input_rtmp_port'
            />

            <FormInput
              label={t('STREAMING.INPUTS.ADD.sourceSpecificMulticast')}
              name='ssm'
              item={item}
              viewOnly={role.cannot('edit', 'inputs', 'ssm')}
              showHint
              helpMessage={t('STREAMING.INPUTS.ADD.sourceSpecificMulticastHelp')}
              isHidden={isHidden({
                param: source,
                condition: (source: string) => ['MPEG2TS'].indexOf(source) === -1
              })}
            />

            {buildFormSelect({
              isHidden: isHidden({
                param: source,
                condition: (source: string) => {
                  if (!networkInterface) {
                    // Network interface can be empty if user does not have access to api/system/network
                    return true;
                  }
                  if (source === 'SRT' && srtMode !== 'caller') {
                    return false;
                  }
                  return ['MPEG2TS'].indexOf(source) === -1;
                }
              }),
              viewOnly: role.cannot('edit', 'inputs', 'networkInterface'),
              label: t('STREAMING.INPUTS.ADD.nic'),
              items: networkInterface || [],
              idKey: 'name',
              nameProp: 'name',
              selectedId: item?.networkInterface || networkInterface?.[0]?.name,
              selectName: 'networkInterface',
              dataAuto: 'input_nic'
            })}

            <FormInput
              item={item}
              label={t('STREAMING.INPUTS.ADD.deviceAddress')}
              name='gigevisAddress'
              viewOnly={role.cannot('edit', 'inputs', 'gigevisAddress')}
              required
              isHidden={isHidden({
                param: source,
                className: 'grid-column kraken-span-2',
                condition: (source: string) => ['GIGEVIS'].indexOf(source) === -1
              })}
            />

            <FormInput
              item={item}
              label={t('STREAMING.INPUTS.ADD.address')}
              viewOnly={role.cannot('edit', 'inputs', 'deviceAddress')}
              required
              showHint
              name='deviceAddress'
              helpMessage={t('STREAMING.INPUTS.ADD.addressHelp')}
              isHidden={isHidden({
                param: source,
                className: 'grid-column kraken-span-2',
                condition: (source: string) => ['ST2110'].indexOf(source) === -1
              })}
              dataAuto='input_st2110_address'
            />

            <FormInput
              item={item}
              label={t('STREAMING.INPUTS.ADD.ipPort')}
              name='deviceIpPort'
              required
              showHint
              viewOnly={role.cannot('edit', 'inputs', 'deviceIpPort')}
              helpMessage={t('STREAMING.INPUTS.ADD.portHelp')}
              isHidden={isHidden({
                param: source,
                condition: (source: string) => ['ST2110'].indexOf(source) === -1
              })}
              dataAuto='input_st2110_ip_port'
            />

            <FormInput
              item={item}
              label={t('STREAMING.INPUTS.ADD.devicePort')}
              name='devicePort'
              required
              showHint
              viewOnly={role.cannot('edit', 'inputs', 'devicePort')}
              helpMessage={t('STREAMING.INPUTS.ADD.devicePortHelp')}
              isHidden={isHidden({
                param: source,
                condition: (source: string) => ['ST2110'].indexOf(source) === -1
              })}
              dataAuto='input_st2110_device_port'
            />

            {buildFormSelect({
              isHidden: isHidden({
                param: source,
                condition: (source: string) => ['GIGEVIS'].indexOf(source) === -1
              }),
              viewOnly: role.cannot('edit', 'inputs', 'gigevisMode'),
              label: t('STREAMING.INPUTS.ADD.videoMode'),
              items: [
                {
                  value: 'current',
                  option: formControl
                    ? t('STREAMING.INPUTS.currentCamera') // edit mode
                    : t('STREAMING.INPUTS.ADD.gigeApply') // add mode
                }
              ],
              selectName: 'gigevisMode'
            })}

            {buildFormSelect({
              isHidden: isHidden({
                param: source,
                condition: (source: string) => ['GIGEVIS'].indexOf(source) === -1
              }),
              viewOnly: role.cannot('edit', 'inputs', 'gigevisChroma'),
              label: t('STREAMING.INPUTS.ADD.chroma'),
              items: [
                {
                  value: 'current',
                  option: formControl
                    ? t('STREAMING.INPUTS.currentCamera') // edit mode
                    : t('STREAMING.INPUTS.ADD.gigeApply') // add mode
                }
              ],
              selectName: 'gigevisChroma'
            })}
            {buildFormSelect({
              isHidden: isHidden({
                param: source,
                condition: (source: string) => ['NDI'].indexOf(source) === -1
              }),
              viewOnly: role.cannot('edit', 'inputs', 'ndiSourceName'),
              label: t('STREAMING.INPUTS.ADD.ndiName'),
              items: ndiSourceItems,
              selectName: 'ndiSourceName',
              formRef
            })}
          </FormContainer>
        </FormSectionDark>

        <IsHidden param={source} condition={(f: string) => ['SRT'].indexOf(f) === -1}>
          <SrtForm
            formRef={formRef}
            srtMode={srtMode}
            setSrtMode={setSrtMode}
            columns={columns}
            viewOnly={role.cannot('edit', 'inputs', 'srt')}
          />
        </IsHidden>
      </Form>
    </FadeIn>
  );
};
