import { Dialog } from '@hai/ui-react';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import React, { useCallback, useContext } from 'react';

const ConfirmModal: React.FunctionComponent = () => {
  const { confirmModal, dispatch } = useContext(GlobalContext);

  const onClose = useCallback(() => {
    dispatch({ type: EnumGlobalAction.CLOSE_CONFIRM_MODAL });
  }, [dispatch]);

  const buttons = [];

  if (confirmModal.cancelText) {
    buttons.push({
      label: confirmModal.cancelText,
      variant: confirmModal.ghostCancel ? 'ghost' : 'secondary',
      onClick: () => {
        confirmModal.onCancel && confirmModal.onCancel();
      }
    });
  }

  if (confirmModal.altConfirmText) {
    buttons.push({
      label: confirmModal.altConfirmText,
      variant: 'secondary',
      onClick: (event: any) => {
        confirmModal.altConfirm && confirmModal.altConfirm(event);
      }
    });
  }

  if (confirmModal.confirmText) {
    buttons.push({
      label: confirmModal.confirmText,
      variant: confirmModal.isDanger ? 'danger' : 'primary',
      onClick: (event: any) => {
        confirmModal.onConfirm && confirmModal.onConfirm(event);
      }
    });
  }

  return confirmModal && confirmModal.show ? (
    <Dialog
      size={confirmModal.size}
      {...(confirmModal.close
        ? {
            dialogType: 'activity',
            title: confirmModal.title,
            content: confirmModal.desc
          }
        : {
            headline: confirmModal.title,
            bodyText: confirmModal.desc
          })}
      icon={confirmModal.icon}
      data-auto='confirm_modal'
      onClose={onClose}
      show={confirmModal.show}
      buttons={buttons}
      onHide={confirmModal?.onClose || confirmModal?.onCancel}
      useFsMask={true}
    />
  ) : null;
};

export default ConfirmModal;
