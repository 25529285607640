import { useAuthContext } from 'context/auth';
import {
  accountsReducer,
  EnumAccountsAction,
  IAccountsState,
  initAccountsState
} from 'context/security/accounts';
import {
  certificatesReducer,
  EnumCertificatesAction,
  ICertificatesState,
  initCertificatesState
} from 'context/security/certificates';
import {
  EnumPoliciesAction,
  initPoliciesState,
  IPoliciesState,
  policiesReducer
} from 'context/security/policies';
import {
  EnumUpdatesAction,
  initUpdatesState,
  IUpdatesState,
  updatesReducer
} from 'context/security/updates';
import React, { createContext, useEffect, useReducer } from 'react';

export interface ISecurityState
  extends IAccountsState,
    ICertificatesState,
    IPoliciesState,
    IUpdatesState {}

const initialState: ISecurityState = {
  ...initAccountsState,
  ...initCertificatesState,
  ...initPoliciesState,
  ...initUpdatesState
};

interface ISecurityContext extends ISecurityState {
  securityDispatch: React.Dispatch<any>;
}

const SecurityContext = createContext<ISecurityContext>({
  ...initialState,
  securityDispatch: () => null
});

const { Provider } = SecurityContext;

const combineReducers =
  (...reducers: Function[]) =>
  (state: any, action: any): any => {
    for (let i = 0; i < reducers.length; i++) {
      state = reducers[i](state, action);
    }
    return state;
  };

const SecurityReducer = combineReducers(
  accountsReducer,
  certificatesReducer,
  policiesReducer,
  updatesReducer
);

const SecurityProvider = ({ children }: any) => {
  const [securityState, securityDispatch] = useReducer(SecurityReducer, initialState);
  const [auth] = useAuthContext();

  useEffect(() => {
    if (!auth.isAuth) {
      securityDispatch({ type: EnumAccountsAction.RESET });
      securityDispatch({ type: EnumCertificatesAction.RESET });
      securityDispatch({ type: EnumPoliciesAction.RESET });
      securityDispatch({ type: EnumUpdatesAction.RESET });
    }
  }, [auth]);

  return <Provider value={{ ...securityState, securityDispatch }}>{children}</Provider>;
};

export { SecurityContext, SecurityProvider };
