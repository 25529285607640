export const policiesEn = {
  versions: 'TLS Versions',
  rpFilter: 'RP Filter',
  rpHelp: 'Set to Loose if using Asymmetric Routing or separate NICs are inside the same network',
  loose: 'Loose',
  strict: 'Strict',
  v13: 'TLSv1.3',
  v12: 'TLSv1.2',
  warning:
    'Warning: These settings are not preserved across software updates and need to be reapplied.',
  updated: 'Policy settings updated successfully'
};
