import client from 'api/utils/client';
import { normalizeResponse } from 'api/utils/propertyNormalizer';
import { AxiosResponse } from 'axios';
import { useAuthContext } from 'context';
import { EnumNotify, NotifyContext } from 'context/notify';
import { t } from 'i18next';
import { ReactElement, useContext, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import constant from 'utils/constant';

const ErrorHandler = ({ children }: { children: ReactElement }) => {
  const { dispatch } = useContext(NotifyContext);
  const [auth, setAuth] = useAuthContext();
  const [rememberMe] = useLocalStorage(constant.signin.rememberMe, false);
  const [storedName] = useLocalStorage(constant.signin.username, '');

  const onResponse = (response: AxiosResponse): AxiosResponse => {
    const newResponse = response;
    newResponse.data = normalizeResponse(newResponse.data);
    return newResponse;
  };

  useEffect(() => {
    const errorInterceptor = client.interceptors.response.use(onResponse, error => {
      if (error?.response?.status) {
        if (showError(error.config, error?.response?.status)) {
          dispatch({
            type: EnumNotify.ADD_NOTIFICATION,
            payload: {
              type: 'Error',
              message: handleError({
                status: error.response.status,
                url: error.response.config.url,
                method: error.response.config.method,
                error:
                  error.response.data.errors?.[0] ||
                  error.response.data.message ||
                  error.response.data.error?.message,
                message: error.response.data.message || error.response.data.error?.message
              })
            }
          });
        }

        switch (error.response.status) {
          case 404:
            throw error;
          case 401:
            // Actions for Error 401
            if (auth) {
              setAuth(
                rememberMe
                  ? {
                      username: storedName
                    }
                  : {}
              );

              // TODO: Show session expired.
            }
            throw error;
          case 500:
            // Actions for Error 500
            throw error;
          default:
            console.error('from hook interceptor => ', error);
            throw error;
        }
      } else {
        // Occurs for axios error.message = 'Network Error'
        throw error;
      }
    });
    return () => {
      client.interceptors.response.eject(errorInterceptor);
    };
  }, [auth, dispatch, rememberMe, setAuth, storedName]);

  return children;
};
export default ErrorHandler;

const hasUuid = (url: string) => {
  let newUrl = url.split('/');
  if (newUrl[newUrl.length - 1].length === 36) {
    newUrl.pop();
    return newUrl.join('/');
  }
  return url;
};

interface IHandleError {
  status: number;
  url: string;
  method: string;
  message: string;
  error: string;
}

const handleError = (props: IHandleError) => {
  const { status, url, method, message, error } = props;
  const strippedUrl = hasUuid(url);

  /*
  error message priority is as follows:
  1. statuscode./endpoint.'the error message returned from the server'
  2. 'statuscode./endpoint.method'
  3. /endpoint.'the error message returned from the server'
  4. 'error message returned from the server'
  5. error returned from the sever
  */
  const errorMessage = t(
    [
      `ERRORS.${status}.${strippedUrl}.${error}`,
      t(
        [
          `ERRORS.${status}.${strippedUrl}.${method}`,
          t(
            [
              `ERRORS.${error}`,
              t([
                `ERRORS.${strippedUrl}.${error}`,
                t([`ERRORS.${status}.default`, t(error)], { status, message })
              ])
            ],
            {
              status,
              url: strippedUrl
            }
          )
        ],
        {
          status,
          error
        }
      )
    ],
    {
      status,
      error
    }
  );

  // Unhandled error should show in the console for us to debug.
  if (errorMessage === t(`ERRORS.${status}.default`)) {
    console.groupCollapsed(`Unhandled error message: ${strippedUrl} : ${status}`);
    console.trace();
    console.groupEnd();
  }

  return errorMessage;
};

const showError = (config: any, errorStatus: number) => {
  if (config.ignoreErrors) {
    return false;
  }
  if (config.ignoreError && config.ignoreError.indexOf(errorStatus.toString()) !== -1) {
    return false;
  }
  return true;
};
