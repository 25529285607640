import { IGeneralApiProps } from 'api/global.type';
import client from 'api/utils/client';

export enum EnumArchiveLocation {
  NFS = 'nfs',
  LOCAL = 'local'
}

interface IArchiveStatus {
  state: string;
  filesystemFreeBytes?: number;
  filesystemUsedBytes?: number;
  usedBytes: number;
}

interface IArchiveNfs {
  remoteHost: string;
  remotePath: string;
}

export interface IArchive {
  uuid: string;
  autoConnect: boolean;
  description: string;
  name: string;
  status: IArchiveStatus;
  type: EnumArchiveLocation;
  nfs?: IArchiveNfs;
  selected?: boolean;
}

export interface IArchiveLocations extends IGeneralApiProps {
  archiveLocations: IArchiveLocation[];
}

interface IArchiveLocation {
  autoConnect: boolean;
  description: string;
  name: string;
  status: IState;
  nfs?: INfs;
  type: string;
  uuid: string;
}

interface INfs {
  remoteHost: string;
  remotePath: string;
}

interface IState {
  state: string;
}

export const getArchiveLocations = (props?: IArchiveLocations) => {
  return client
    .get('/archivelocations')
    .then(resp => {
      const data = resp.data.archiveLocations;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return [];
    });
};

interface IUpdateArchive extends IGeneralApiProps {
  item: Partial<IArchive>;
}

export const getArchiveLocation = (props: IUpdateArchive) => {
  const { item, onError } = props;

  return client
    .get(`archivelocations/${item.uuid}`)
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};

export const updateArchiveLocation = (props: IUpdateArchive) => {
  const { item, onError } = props;

  return client
    .put(`archivelocations/${item.uuid}`, { ...item })
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};

export const connectArchiveLocation = (props: IUpdateArchive) => {
  const { item, onError } = props;

  return client
    .put(`archivelocations/${item.uuid}/connect`, { ...item })
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};

export const disconnectArchiveLocation = (props: IUpdateArchive) => {
  const { item, onError } = props;

  return client
    .put(`archivelocations/${item.uuid}/disconnect`, { ...item })
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};

export const addArchiveLocation = (props: IUpdateArchive) => {
  const { item, onError } = props;

  return client
    .post('archivelocations', { ...item })
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};

export const deleteArchiveLocation = (props: IUpdateArchive) => {
  const { item, onError } = props;

  return client
    .delete(`archivelocations/${item.uuid}`)
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};

export const checkArchiveLocationConnection = (props: IUpdateArchive) => {
  const { item, onError } = props;

  return client
    .put(`archivelocations/${item.uuid}/checkconnection`)
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};
