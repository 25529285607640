import {
  CreateHaiThemeContext,
  ThemeNames,
  ThemeType,
  applyThemeType,
  useHaiTheme
} from '@hai/ui-react';
import { HaiTheme } from '@hai/ui-react/dist/ThemeContext';
import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

export const ThemeProvider: React.FunctionComponent<{ children: any }> = ({
  children,
  ...rest
}) => {
  CreateHaiThemeContext(ThemeNames.ARGON, ThemeType.DARK);
  const haiThemeContextState: HaiTheme = useHaiTheme();

  React.useEffect(() => {
    applyThemeType(ThemeType.DARK);
  }, []);

  return (
    <StyledThemeProvider theme={haiThemeContextState} {...rest}>
      {children}
    </StyledThemeProvider>
  );
};
