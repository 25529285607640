export interface IRoutePaths {
  [key: string]: {
    element?: any;
    path?: string;
    index?: boolean;
  };
}

export interface ISubTab {
  [key: string]: {
    path: string;
    title: string;
    licensedFeature?: boolean;
    show: boolean;
  };
}

export const mainPaths = {
  signin: '/signin',
  password: '/password',
  dashboard: '/dashboard',
  settings: '/settings',
  security: '/security',
  help: '/help',
  outputsPlayer: '/streaming/outputs/player/:id'
};

export const streamingTabPaths = {
  base: '/streaming',
  streams: '/streaming/streams',
  streamStats: '/streaming/streams/stream-statistics/:id',
  inputs: '/streaming/inputs',
  transcoders: '/streaming/transcoders',
  outputs: '/streaming/outputs',
  metadata: '/streaming/metadata'
};

export const settingsTabPaths = {
  base: '/settings',
  archive: '/settings/archive',
  reporting: '/settings/reporting',
  licensing: '/settings/licensing',
  services: '/settings/services',
  network: '/settings/network',
  updates: '/settings/updates'
};

export const securityTabPaths = {
  base: '/security',
  accounts: '/security/accounts',
  certificates: '/security/certificates',
  policies: '/security/policies'
};

export const routePaths = {
  ...mainPaths,
  streaming: {
    ...streamingTabPaths
  },
  settings: {
    ...settingsTabPaths
  },
  security: {
    ...securityTabPaths
  }
};
