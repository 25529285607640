import { srtValidationSchema } from 'components/streaming/srtForm/srtFormValidation';
import { t } from 'i18next';
import { isHostname, isIpV4, isIpV6, isRTMPUrl, isRTSPUrl, isUrl } from 'utils/validations';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  ...srtValidationSchema,
  ...{
    name: Yup.string().required(t('STREAMING.REQUIRED.required', { item: 'Name' }) as string),
    url: Yup.string().when(['streamType', 'rtmpMode'], {
      is: (streamType: string, rtmpMode: string) => {
        return (
          ['MPEG2TS', 'MJPEGHTTP', 'RTSP', 'MJPEGRAW'].indexOf(streamType) !== -1 ||
          (streamType === 'RTMP' && rtmpMode === 'client')
        );
      },
      then: Yup.string()
        .required(t('STREAMING.REQUIRED.required', { item: 'Url' }) as string)
        .test('validator-url', (address, obj) => {
          if (obj.parent.streamType === 'RTMP' && obj.parent.rtmpMode === 'client') {
            return isRTMPUrl(address?.toLowerCase());
          }

          if (address && obj.parent.streamType === 'RTSP') {
            return isRTSPUrl(address.toLowerCase());
          }

          if (obj.parent.streamType === 'MJPEGHTTP') {
            const pattern = /^((http|https):)/;
            if (address?.indexOf('?') !== -1) {
              // url contains parameters
              return obj.createError({
                message: t('STREAMING.REQUIRED.urlNoParameters') as string
              });
            } else if (!pattern.test(address)) {
              // url is missing http[s]
              return obj.createError({
                message: t('STREAMING.REQUIRED.urlHttp') as string
              });
            }
            return isUrl(address?.toLowerCase());
          }

          if (obj.parent.streamType === 'MJPEGRAW' || obj.parent.streamType === 'MPEG2TS') {
            /// For now just dont accept spaces
            return address?.toLowerCase().match(/^[^ ]+$/);
          }

          return true;
        })
    }),

    gigevisAddress: Yup.string().when('streamType', {
      is: (streamType: string) => streamType === 'GIGEVIS',
      then: Yup.string()
        .required(t('STREAMING.REQUIRED.gigevisAddress') as string)
        .test(srtAddress => {
          if (
            isIpV4(srtAddress as string) ||
            isIpV6(srtAddress as string) ||
            isHostname(srtAddress as string)
          ) {
            return true;
          }
          return false;
        })
    }),

    port: Yup.number().when(['streamType', 'rtmpMode'], {
      is: (streamType: string, rtmpMode: string) => streamType === 'RTMP' && rtmpMode === 'server',
      then: Yup.number()
        .typeError(t('VALIDATION.between', { x: 0, y: 65535 }) as string)
        .min(0, t('VALIDATION.between', { x: 0, y: 65535 }) as string)
        .max(65535, t('VALIDATION.between', { x: 0, y: 65535 }) as string)
    }),

    deviceAddress: Yup.string().when(['streamType'], {
      is: (streamType: string) => streamType === 'ST2110',
      then: Yup.string()
        .required(t('STREAMING.REQUIRED.deviceAddress') as string)
        .test(srtAddress => {
          if (
            isIpV4(srtAddress as string) ||
            isIpV6(srtAddress as string) ||
            isHostname(srtAddress as string)
          ) {
            return true;
          }
          return false;
        })
    }),

    deviceIpPort: Yup.number().when(['streamType'], {
      is: (streamType: string) => streamType === 'ST2110',
      then: Yup.number()
        .required(t('STREAMING.REQUIRED.deviceIpPort') as string)
        .typeError(t('VALIDATION.between', { x: 0, y: 65535 }) as string)
        .min(0, t('VALIDATION.between', { x: 0, y: 65535 }) as string)
        .max(65535, t('VALIDATION.between', { x: 0, y: 65535 }) as string)
    }),
    devicePort: Yup.number().when(['streamType'], {
      is: (streamType: string) => streamType === 'ST2110',
      then: Yup.number()
        .required(t('STREAMING.REQUIRED.devicePort') as string)
        .typeError(t('VALIDATION.between', { x: 1, y: 2 }) as string)
        .min(1, t('VALIDATION.between', { x: 1, y: 2 }) as string)
        .max(2, t('VALIDATION.between', { x: 1, y: 2 }) as string)
    })
  }
});
