import { useAuthContext } from 'context/auth';
import {
  EnumInputsAction,
  IInputsState,
  initInputsState,
  inputsReducer
} from 'context/streaming/inputs';
import {
  EnumMetadatasAction,
  IMetadatasState,
  initMetadatasState,
  metadatasReducer
} from 'context/streaming/metadatas';
import {
  EnumOutputsAction,
  IOutputsState,
  initOutputsState,
  outputsReducer
} from 'context/streaming/outputs';
import {
  EnumStreamsAction,
  IStreamsState,
  initStreamsState,
  streamsReducer
} from 'context/streaming/streams';
import {
  EnumTranscodersAction,
  ITranscodersState,
  initTranscodersState,
  transcodersReducer
} from 'context/streaming/transcoders';
import React, { createContext, useEffect, useReducer } from 'react';

export interface IStreamingState
  extends IStreamsState,
    IInputsState,
    ITranscodersState,
    IMetadatasState,
    IOutputsState {}

const initialState: IStreamingState = {
  ...initStreamsState,
  ...initInputsState,
  ...initTranscodersState,
  ...initMetadatasState,
  ...initOutputsState
};

interface IStreamingContext extends IStreamingState {
  streamingDispatch: React.Dispatch<any>;
}

const StreamingContext = createContext<IStreamingContext>({
  ...initialState,
  streamingDispatch: () => null
});

const { Provider } = StreamingContext;

const combineReducers =
  (...reducers: Function[]) =>
  (state: any, action: any): any => {
    for (let i = 0; i < reducers.length; i++) {
      state = reducers[i](state, action);
    }
    return state;
  };

const StreamingReducer = combineReducers(
  streamsReducer,
  metadatasReducer,
  inputsReducer,
  transcodersReducer,
  outputsReducer
);

const StreamingProvider = ({ children }: any) => {
  const [streamingState, streamingDispatch] = useReducer(StreamingReducer, initialState);
  const [auth] = useAuthContext();

  useEffect(() => {
    if (!auth.isAuth) {
      streamingDispatch({ type: EnumStreamsAction.RESET });
      streamingDispatch({ type: EnumOutputsAction.RESET });
      streamingDispatch({ type: EnumInputsAction.RESET });
      streamingDispatch({ type: EnumMetadatasAction.RESET });
      streamingDispatch({ type: EnumTranscodersAction.RESET });
    }
  }, [auth]);

  return <Provider value={{ ...streamingState, streamingDispatch }}>{children}</Provider>;
};

export { StreamingContext, StreamingProvider };
