import { t } from 'i18next';
import { isHostname, isIpV4, isIpV6 } from 'utils/validations';
import * as Yup from 'yup';

export const srtValidationSchema = {
  srtLatency: Yup.number().when('streamType', {
    is: (streamType: string) => streamType === 'SRT',
    then: Yup.number()
      .integer()
      .typeError(t('STREAMING.REQUIRED.notNumber') as string)
      .required(t('STREAMING.REQUIRED.srtLatency') as string)
      .min(20, t('STREAMING.REQUIRED.srtLatency') as string)
      .max(8000, t('STREAMING.REQUIRED.srtLatency') as string)
  }),
  srtAddress: Yup.string().when(['streamType', 'srtMode'], {
    is: (streamType: string, srtMode: string) => {
      return streamType === 'SRT' && srtMode !== 'listener';
    },
    then: Yup.string()
      .required(t('STREAMING.REQUIRED.required', { item: 'Address' }) as string)
      .test(srtAddress => {
        if (srtAddress?.substring(0, 3).toLowerCase() === 'srt') {
          srtAddress = srtAddress.toLowerCase().replace(/^srt:\/\/|srt:\/|srt:/, '');
        }

        if (
          isIpV4(srtAddress as string) ||
          isIpV6(srtAddress as string) ||
          isHostname(srtAddress as string)
        ) {
          return true;
        }
        return false;
      })
  }),
  srtPassphrase: Yup.string().when('streamType', {
    is: (streamType: string) => streamType === 'SRT',
    then: Yup.string().test((pass, condition) => {
      // skip validatoin for outputs that provide encryption selection but leave the type unset (None)
      if (condition.parent.srtEncryption === undefined && condition.parent.encryptionSelect) {
        return true;
      }
      // skip validation for inputs that have no passphrase set
      // they don't provide encryption select as it is automatic
      if (!condition.parent.encryptionSelect && !condition.parent.srtPassphrase) {
        return true;
      }
      // Don't validate the special masked password, or a passowrd when encryption is unset
      if (pass === '*********' || (!pass && condition.parent.srtEncryption === '')) {
        return true;
      }
      if (!pass || pass.length < 10 || pass.length > 79) {
        return condition.createError({
          message: t('STREAMING.REQUIRED.srtPassphrase') as string
        });
      }
      return true;
    })
  }),
  srtRemotePort: Yup.mixed().when(['streamType', 'srtMode'], {
    is: (streamType: string, srtMode: string) => {
      return streamType === 'SRT' && srtMode !== 'listener';
    },

    then: Yup.number()
      .required(
        t<string>('STREAMING.REQUIRED.required', {
          item: t('STREAMING.INPUTS.ADD.destinationPort')
        })
      )
      .typeError(t('VALIDATION.between', { x: 1025, y: 65535 }) as string)
      .min(1025, t('VALIDATION.between', { x: 1025, y: 65535 }) as string)
      .max(65535, t('VALIDATION.between', { x: 1025, y: 65535 }) as string),
    otherwise: Yup.mixed().notRequired()
  }),

  srtLocalPort: Yup.mixed().when(['streamType'], {
    is: (streamType: string) => 'SRT' === streamType,
    then: Yup.number()
      .when('srtMode', {
        is: 'listener',
        then: Yup.number().required(
          t<string>('STREAMING.REQUIRED.required', {
            item: t('STREAMING.INPUTS.ADD.port')
          })
        )
      })
      .typeError(t('VALIDATION.between', { x: 1025, y: 65535 }) as string)
      .min(1025, t('VALIDATION.between', { x: 1025, y: 65535 }) as string)
      .max(65535, t('VALIDATION.between', { x: 1025, y: 65535 }) as string),
    otherwise: Yup.mixed().notRequired()
  })
};
