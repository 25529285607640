import { PureAbility } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { EnumAccountRole } from 'api/accounts/account';
import { getLicense } from 'api/license/license';
import { useAuthContext } from 'context/auth';
import { buildPermissions } from 'permissions/role/buildPermissions';
import React, { createContext, useEffect, useState } from 'react';
import { useCache } from 'utils/hooks/useCache';

export const RoleContext = createContext<any>(null);
export const RoleCan = createContextualCan(RoleContext.Consumer);
interface ILicensed {
  children?: React.ReactNode;
  can?: string;
  to?: string;
}
export const Licensed = (props: ILicensed) => {
  const { can, children, to } = props;
  return (
    <RoleCan do='license' a={can ?? to}>
      {children}
    </RoleCan>
  );
};
interface IRoleProvider {
  children?: React.ReactNode;
}
export const RoleProvider = (props: IRoleProvider) => {
  const { children } = props;

  const [role, setRole] = useState<PureAbility>(
    new PureAbility([{ action: 'view', subject: 'all' }])
  );
  const [auth] = useAuthContext();

  const { status, data } = useCache({
    apiEndpoint: getLicense,
    url: '/license'
  });
  useEffect(() => {
    if (auth.group && data.length !== 0) {
      setRole(buildPermissions({ group: auth.group as EnumAccountRole, license: data }));
    }
  }, [auth.group, data, status]);

  return <RoleContext.Provider value={role}>{children}</RoleContext.Provider>;
};
