/**
 * The `process.env.BUILD_TYPE` variable is a special variable "injected" by Webpack
 * at runtime. The word "injected" is in double quotes for a good reason. Indeed,
 * the variable is not really injected, it's really a search & replace at build time
 * that is performed by Webpack.
 */

function translateBuildTypeToEnv() {
  // process.env.BUILD_TYPE comes from the teamcity pipeline
  let env = process.env.BUILD_TYPE;
  if (env == 'RELEASE') {
    return 'PROD';
  } else if (env == 'BETA') {
    return 'QA';
  } else {
    return 'DEV';
  }
}

export const constant = {
  lSLanguageKey: 'kraken:language',
  landingPage: '/streams',
  version: process.env.BUILD_VERSION_FULL ? process.env.BUILD_VERSION_FULL : '4.0.0',
  environment: translateBuildTypeToEnv(),
  productName: 'Kraken',
  fullStoryId: 'o-1EQT7G-na1',

  helpCenter: {
    address: `//doc.haivision.com`,
    iconLocation: '//assets.doc.haivision.com/assets/images',
    icon: '/favicon.png',
    local: '/help/index.html'
  },
  signin: {
    expirationWarning: 15,
    authorized: 'kraken:signin_authed',
    mustChangePassword: 'kraken:must_change_password',
    expiration: 'kraken:signin_expiration',
    timeout: 'kraken:signin_timeout',
    rememberMe: 'kraken:signin_remember',
    username: 'kraken:signin_username',
    group: 'kraken:signin_usergroup',
    userId: 'kraken:signin_userId'
  },
  pageBreakpoints: {
    sidebarOffset: 162,
    col1: 1111,
    col2: 1450
  },
  presets: {
    update: 1000 * 30
  },
  sidebar: {
    collapsed: 'kraken:sidebar_collapsed'
  },
  system: {
    rtspDefaultPort: 554
  },
  stream: {
    srtPortMin: 1025,
    srtPortMax: 65535,
    srtLatencyMin: 20,
    srtLatencyMax: 8000,
    update: 5000
  },
  interval: {
    systemLicense: 5000,
    systemMetrics: 5000,
    snapshot: 5000,
    streamStats: 5000,
    upgradeStatus: 1000
  },
  timeout: {
    panelTransition: 500,
    minButtonLoading: 500,
    maxButtonLoading: 1000 * 20
  },
  updating: {
    build: 'kraken:update_build',
    version: 'kraken:update_version',
    prevBuild: 'kraken:update_prevbuild',
    prevVersion: 'kraken:update_prevversion'
  }
};
export default constant;
