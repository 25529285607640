import { Button, FormControlContainer } from '@hai/ui-react';
import { ButtonStateType, ButtonVariantType } from '@hai/ui-react/dist/components/Button/Button';
import { t } from 'i18next';
import React, { ReactElement } from 'react';

export enum EnumFormButtonState {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  LOCATING = 'locating'
}

interface IFormButton {
  cancel?: () => void;
  columns?: number;
  disabled?: boolean;
  formItems?: number;
  submitHandler?: (event: any) => void;
  state?: ButtonStateType;
  variant?: ButtonVariantType;
  buttonText?: string;
  children?: ReactElement[];
}

export const FormButton = (props: IFormButton) => {
  const {
    cancel,
    columns = 2,
    disabled = false,
    formItems = 1,
    submitHandler,
    state,
    variant = 'primary',
    buttonText = t('apply'),
    children
  } = props;
  return (
    <>
      {((row: JSX.Element[], col: number, i = 1) => {
        while (col - 1 > i) {
          i++;
          row.push(<div key={`apply-btn-spacer-${i}`} />);
        }
        return row;
      })([], columns, formItems)}

      <FormControlContainer style={{ justifyContent: 'end', gap: '1rem' }}>
        {children ? (
          children
        ) : (
          <>
            {cancel && (
              <Button variant='secondary' onClick={cancel}>
                {t('cancel')}
              </Button>
            )}
            <Button variant={variant} onClick={submitHandler} state={state} disabled={disabled}>
              {buttonText}
            </Button>
          </>
        )}
      </FormControlContainer>
    </>
  );
};
