import './resetPassword.scss';

import {
  Button,
  Form,
  FormContext,
  FormControl,
  FormGroup,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle
} from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { changePassword } from 'api/accounts/account';
import { getSession } from 'api/session/session';
import { useAuthContext } from 'context';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormRef } from 'utils/hooks/useFormRef';
import { generalValidation } from 'utils/validations';
import * as Yup from 'yup';

export const ResetPassword: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const formRef = useFormRef();

  const [auth, setAuth] = useAuthContext();
  const navigate = useNavigate();
  const [buttonState, setButtonState] = useState<ButtonStateType>('idle');

  useEffect(() => {
    document.title = `${window.location.hostname} - ${t(`ROUTES.resetPassword`)}`;
  }, [t]);

  const cancelPasswordChange = () => {
    setAuth(old => {
      return { ...old, isAuth: false };
    });
    navigate('/signin');
  };

  const onSave = async (values: any, { setSubmitting }: any) => {
    setButtonState('pending');
    const result = await changePassword({ apiParams: values });
    if (result?.success) {
      setButtonState('success');
      const session = await getSession();
      await setAuth(old => {
        return {
          ...old,
          isAuth: true,
          group: session?.group,
          mustChangePassword: false
        };
      });
      navigate('/');
    } else {
      setButtonState('idle');
    }
    setSubmitting(false);
  };

  const validationSchema = Yup.object({
    currentPassword: generalValidation.required(t('RESETPASSWORD.oldPassword')),
    password: generalValidation.password,
    confirmPassword: generalValidation.confirmPassword
  });

  const formProps = {
    handleSubmit: onSave,
    restValidationProps: { innerRef: formRef, validationSchema: validationSchema },
    defaultValidation: true,
    initialValues: {
      confirm_password: '',
      current_password: '',
      id: auth.userId,
      password: '',
      state: '',
      username: auth.username
    }
  };

  return (
    <div>
      <Modal className='passwordExpired' show size='sm' centered>
        <ModalHeader>
          <ModalTitle>
            <Icon iconname='Security' className='mr-2 teal' />
            {t('RESETPASSWORD.title')}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Form {...formProps}>
            <Row>
              <Col>
                <FormGroup controlId='username'>
                  <FormLabel>{t('RESETPASSWORD.userName')}</FormLabel>
                  <FormControl useFsMask={true} name='username' disabled />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup controlId='oldPassword'>
                  <FormLabel>{t('RESETPASSWORD.oldPassword')}</FormLabel>
                  <FormControl useFsMask={true} name='currentPassword' type='password' />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup controlId='password'>
                  <FormLabel>{t('RESETPASSWORD.password')}</FormLabel>
                  <FormControl useFsMask={true} name='password' type='password' />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup controlId='confirmPassword'>
                  <FormLabel>{t('RESETPASSWORD.passwordConfirm')}</FormLabel>
                  <FormControl useFsMask={true} name='confirmPassword' type='password' />
                </FormGroup>
              </Col>
            </Row>

            <div className='buttonRow hai-mt-5 flex-column'>
              <FormContext.Consumer>
                {(formContext: any) => {
                  return (
                    <>
                      <Button
                        variant='primary'
                        onClick={formContext.handleSubmit}
                        disabled={!formContext.dirty}
                        state={buttonState}
                        className='mb-3 mt-2 password-apply'
                      >
                        {t('apply')}
                      </Button>
                      <Button variant='secondary' onClick={cancelPasswordChange}>
                        {t('cancel')}
                      </Button>
                    </>
                  );
                }}
              </FormContext.Consumer>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};
