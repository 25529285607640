import { List, colorValue } from '@hai/ui-react';
import { IMetadataItem } from 'api/metadata/metadata.type';
import {
  EnumSortDirection,
  EnumSortType,
  ISortComparator,
  SortManager
} from 'components/actionBar/SortManager';
import MetadataItem from 'components/streaming/metadata/MetadataItem';
import React from 'react';

interface IProps<T extends IMetadataItem> {
  list: T[];
  newItem?: string;
  hideCheckbox?: boolean;
  sortInfo: {
    [K in keyof Partial<T> | string]: {
      title: string;
      type: EnumSortType;
      /*
       *  Optional
       *  Supply custom comparator function receiving model A and B as input and returning -1 | 0 | 1
       */
      comparator?: ISortComparator<T>;
      /*
       *  Optional
       *  Value provider for the field. Takes model as input and output value to sort on
       */
      provider?: (arg: T) => any;
    };
  };
  sorting: string;
  direction: EnumSortDirection;
  saveCallback: Function;
}

const MetadataList = <T extends IMetadataItem>(props: IProps<T>) => {
  const { list, hideCheckbox = false, newItem, sortInfo, saveCallback, sorting, direction } = props;
  return (
    <List selectable={hideCheckbox} accentColor={colorValue('haiui-aqua-01')}>
      <SortManager
        newItem={newItem}
        sortInfo={sortInfo}
        list={list}
        sortKey={sorting}
        direction={direction}
      >
        {list &&
          list.map((item: IMetadataItem) => {
            return <MetadataItem key={item.uuid} item={item} saveCallback={saveCallback} />;
          })}
      </SortManager>
    </List>
  );
};

export default MetadataList;
