import { Icon, OverlayTrigger, Tag, colorValue } from '@hai/ui-react';
import { IPresetItem, deletePreset, exportPreset } from 'api/presets/presets';
import { t } from 'i18next';
import React from 'react';
import { downloadTextFile } from 'utils/downloadFiles';

export const sortPresets = (configList: IPresetItem[]) => {
  return [...configList].sort((a: IPresetItem, b: IPresetItem) => {
    // Active and startup on top of list
    if (a.startup && b.active) {
      return 1;
    }

    if (a.startup || a.active) {
      return -1;
    }
    if (b.startup || b.active) {
      return 1;
    }
    // Sort by date
    return a.lastModified > b.lastModified ? -1 : 1;
  });
};

export const triggerDeletePreset = async (uuid: string) => {
  return await deletePreset({ apiParams: { uuid } });
};

export const downloadPreset = async (uuid: string, configList: IPresetItem[] | undefined) => {
  const data = configList?.find(i => i.uuid === uuid);
  if (data) {
    const response = await exportPreset({ apiParams: { uuid } });
    await downloadTextFile({
      data: response.data,
      name: configList?.find(i => i.uuid === uuid)?.name
    });
    return response;
  }
};

export const presetsRowsWithIcons = (row: IPresetItem) => {
  const tags = [];
  tags.push(<div className='preset-name-container'>{row.name}</div>);
  if (row.active) {
    tags.push(
      <OverlayTrigger key='presets-loaded' popoverContent={t('loaded')} placement='top-center'>
        <Icon iconname='CheckmarkCircle' className='green-check' />
      </OverlayTrigger>
    );
  }
  if (row.startup) {
    tags.push(
      <Tag
        className='startup-tag'
        key='startup-tag'
        backgroundColor={colorValue('haiui-gray-03')}
        foregroundColor={colorValue('haiui-aqua-01')}
        text={t('PRESETS.startup')}
        useFsMask={true}
      />
    );
  }
  return tags;
};
