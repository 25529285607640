export const validationEN = {
  notNumber: 'Must be a number',
  invalid: '{{item}} is not valid',
  invalidFields: 'Form contains invalid fields',
  between: 'Must be between {{x}} and {{y}}.',
  betweenOr: 'Must be between {{x}} and {{y}} or {{or}}.',
  betweenString: 'Length must be between {{x}} and {{y}} characters.',
  required: '{{item}} is Required',
  stringAtMost: 'Must be at most {{max}} characters.',
  STREAM: {
    srtPort: 'Port must be between {{min}} and {{max}}.',
    srtLatency: 'Latency must be {{min}} and {{max}}.',
    srtMode: 'Mode is required.',
    name: 'Title is required.',
    streamType: 'Source is required.'
  },
  SETTINGS: {
    role: 'Role is required.',
    username: 'Username is required.',
    password: 'Password is required',
    noCertainChars: "Name must not include special characters '/ \\ [ ] : ; | = , + * ? < >'",
    passwordMustbe6: 'Must be at minimum 6 characters',
    currentPwd: 'Current password is required',
    matchPasswords: 'Passwords must match',
    confirmPwd: 'Confirm Password is required',
    hostname: 'Hostname is required.'
  },
  SECURITY: {
    name: 'Name is required.',
    type: 'Type is required',
    subject: 'Subject is required',
    subjectLeast2: 'Subject must be at least 2 characters',
    digest: 'Digest is required'
  }
};
