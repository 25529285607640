import './masonry.scss';

import React, { ReactElement, useCallback, useEffect } from 'react';

export interface IMasonrySection {
  title: string;
  data: any[];
}

interface IProps {
  sections: IMasonrySection[];
  renderSection: (data: any) => ReactElement;
  id: string;
  useFsMask?: boolean;
}

const Masonry: React.FunctionComponent<IProps> = ({
  sections,
  renderSection,
  id,
  useFsMask = false
}) => {
  const resize = useCallback(() => {
    const resizeGridItem = (item: any) => {
      const grid = document.getElementById(id);
      if (grid) {
        const rowHeight = parseInt(
          window.getComputedStyle(grid).getPropertyValue('grid-auto-rows')
        );
        const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
        const rowSpan = Math.ceil(
          (item.querySelector('.masonry-wrapper').getBoundingClientRect().height + rowGap) /
            (rowHeight + rowGap)
        );
        item.style.gridRowEnd = 'span ' + rowSpan;
      }
    };

    const resizeAllGridItems = () => {
      const allItems = document.getElementsByClassName('masonry-container');
      for (let x = 0; x < allItems.length; x++) {
        resizeGridItem(allItems[x]);
      }
    };
    resizeAllGridItems();
  }, [id]);

  useEffect(() => {
    resize();
  }, [resize, sections.length]);

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [resize]);

  return (
    <div
      className={`masonry${sections.length === 1 ? ' masonry-single' : ''}${
        useFsMask ? ' fs-mask' : ''
      }`}
      id={id}
    >
      {sections.map((section: IMasonrySection) => {
        return (
          <div className='masonry-container' key={section.title}>
            <div className='masonry-wrapper'>{renderSection(section)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Masonry;
