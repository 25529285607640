import { Sidebar, colorValue } from '@hai/ui-react';
import { getSystemInfo } from 'api/settings/system';
import { ISystemInfo } from 'api/settings/system.type';
import { ping } from 'api/utils/ping';
import { useNotifyChanges } from 'components/common/notify/NotifyReboot';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import { NetworkContext } from 'context/network';
import { EnumNotify, NotifyContext } from 'context/notify';
import { PresetContext } from 'context/preset';
import { SettingsContext } from 'context/settings/settings';
import { EnumStatusAction } from 'context/settings/status';
import { RoleContext } from 'permissions/role/Roles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { openNewWindow } from 'utils/global';
import { useCache } from 'utils/hooks/useCache';
import { useGetNetwork } from 'utils/hooks/useGetNetwork';

import { signout } from '../../api/auth/signout';
import { krakenLogo, krakenSmallLogo, sidebarBackground } from '../../assets/images';
import { useAuthContext } from '../../context';
import { t } from '../../i18n';
import constant from '../../utils/constant';
import { routePaths } from '../routes/routePaths';

export const KrakenSidebar = () => {
  const navigate = useNavigate();
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const { network } = useContext(NetworkContext);
  const { dispatch } = useContext(GlobalContext);
  const [auth, setAuth] = useAuthContext();

  const getNetworkService = useGetNetwork();
  useCache({
    apiEndpoint: getNetworkService,
    url: '/system/network'
  });
  const { unsavedChanges } = useContext(PresetContext);

  const { notifyChanges } = useNotifyChanges();

  const [rememberMe] = useLocalStorage(constant.signin.rememberMe, false);

  const { systemInfo, settingsDispatch } = useContext(SettingsContext);

  const role = useContext(RoleContext);
  const [, setIsSignedIn] = useLocalStorage(constant.signin.authorized, false);
  const [storedName] = useLocalStorage(constant.signin.username, '');
  const [collapsed, setCollapsed] = useLocalStorage(constant.sidebar?.collapsed, false);
  const [showExpiration, setShowExpiration] = useState<true | false | undefined>();
  const [hostname, setHostname] = useState(network?.hostname);

  const hideSettings = !role.can('view', 'settings');

  const navToAccounts = useCallback(() => {
    navigate('security/accounts', {
      state: { id: auth.userId, action: 'show' }
    });
  }, [auth.userId, navigate]);

  useEffect(() => {
    setHostname(network?.hostname);
  }, [network]);

  useEffect(() => {
    if (
      showExpiration === undefined &&
      auth.expiration !== undefined &&
      auth.expiration !== -1 &&
      auth.expiration <= constant.signin.expirationWarning
    ) {
      setShowExpiration(true);
    }
  }, [auth.expiration, showExpiration]);

  useEffect(() => {
    if (showExpiration) {
      setShowExpiration(false);
      notifyDispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Warning',
          long: true,
          linkText: t('SIGNIN.changePassword'),
          onLinkClick: navToAccounts,
          message: t('SIGNIN.passwordExpirationMsg', { count: auth.expiration })
        }
      });
    }
  }, [auth.expiration, navToAccounts, notifyDispatch, showExpiration]);

  let sidebarSections = [
    {
      key: 'dashboard',
      title: t('ROUTES.dashboard'),
      iconName: 'Dashboard',
      to: routePaths.dashboard
    },
    {
      key: 'streaming',
      title: t('ROUTES.streaming'),
      iconName: 'Streams',
      to: routePaths.streaming.base
    },
    {
      key: 'settings',
      title: t('ROUTES.settings'),
      iconName: 'Settings',
      to: routePaths.settings.base
    },
    {
      key: 'security',
      title: t('ROUTES.security'),
      iconName: 'Security',
      to: routePaths.security.base
    },
    {
      key: 'help',
      title: t('ROUTES.help'),
      iconName: 'HelpCenter',
      to: routePaths.help,
      onClick: () => {
        if (systemInfo?.version) {
          openHelpCenter(systemInfo?.version);
        } else {
          getSystemInfo().then((value: ISystemInfo) => {
            openHelpCenter(value.version);
            value && settingsDispatch({ type: EnumStatusAction.SET_SYSTEM_INFO, payload: value });
          });
        }
      }
    }
  ];

  if (hideSettings) {
    sidebarSections = sidebarSections.filter(i => i.key !== 'settings');
  }

  const getActivePath = (fullPath: string) => {
    const shortPaths = sidebarSections.map(s => {
      return s.key;
    });
    let current = '';
    shortPaths.map((shortPath: string) => {
      if (fullPath.search(shortPath) > 0) {
        current = shortPath;
      }
    });
    return current;
  };

  const openHelpCenter = (v: string) => {
    const version = (v.match(/\./g) || []).length == 2 && v.endsWith('.0') ? v.slice(0, -2) : v;
    const admin = auth.group === 'admin';
    const product = 'Kraken';
    const params = `?utm_source=${product}&utm_campaign=${version}&utm_medium=${
      admin ? 'admin' : 'user'
    }`;

    ping({
      address: constant.helpCenter.iconLocation,
      item: constant.helpCenter.icon,
      callback: (result: boolean) => {
        openNewWindow({
          url: result
            ? `${constant.helpCenter.address}/${product}/${version}${params}`
            : constant.helpCenter.local,
          target: '_blank'
        });
      }
    });
  };

  const handleSelect = (path: string) => {
    if (path !== routePaths.help) {
      navigate(path);
    }
  };

  const handleCollapse = () => {
    dispatch({
      type: EnumGlobalAction.SET_SIDEBAR,
      payload: { collapsed: !collapsed }
    });
    setCollapsed(!collapsed);
  };

  const handleSignOut = () => {
    const signoutEvent = () => {
      setIsSignedIn(false);

      setAuth(
        rememberMe
          ? {
              username: storedName
            }
          : {}
      );
      signout();
      return navigate(routePaths.signin);
    };

    if (unsavedChanges) {
      notifyChanges({ type: 'signout', confirm: signoutEvent });
    } else {
      signoutEvent();
    }
  };

  const actionPath = getActivePath(location.pathname) || 'dashboard';

  return (
    <Sidebar
      accentColor={colorValue('haiui-aqua-01')}
      defaultActive={actionPath}
      menuContent={sidebarSections}
      collapsed={collapsed}
      onCollapse={handleCollapse}
      signOutText={t('SIGNIN.signOut')}
      onSelect={handleSelect}
      onSignOut={handleSignOut}
      logoSrc={krakenLogo}
      logoCollapsedSrc={krakenSmallLogo}
      backgroundImage={sidebarBackground}
      userName={storedName}
      productName={'Kraken'}
      productNameCollapsed={'UI'}
      deviceSettings={{
        userName: auth.username,
        userRole: auth.group,
        deviceName: hostname,
        deviceIcon: 'Kraken'
      }}
      useFsMask={true}
      data-auto='kraken_sidebar'
    />
  );
};
