import { addInput, inputDefaults } from 'api/inputs/input';
import { InputFormLayout } from 'components/streaming/inputs/InputFormLayout';
import { EnumNotify, NotifyContext } from 'context/notify';
import { StreamingContext } from 'context/streaming/streaming';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
interface IProps {
  buttonState: string;
  setButtonState: Dispatch<SetStateAction<string>>;
  saveCallback: any;
  formControl: any;
}
export const AddInput = (props: IProps) => {
  const { buttonState, setButtonState, saveCallback, formControl } = props;
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const { inputNdiList } = useContext(StreamingContext);
  const [localState, setLocalState] = useState(buttonState);

  const ndiList = inputNdiList?.discoverDeviceList;

  const onSave = async (values: any, { setSubmitting }: any) => {
    if (localState !== 'idle') {
      return;
    }
    setLocalState('pending');
    setButtonState('pending');
    const result = await addInput({ apiParams: values });
    if (result?.success) {
      saveCallback(result.uuid);
      notifyDispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: {
            name: 'STREAMING.INPUTS.MESSAGES.added',
            params: { name: values.name }
          }
        }
      });
    } else {
      setButtonState('idle');
      setLocalState('idle');
    }
    setSubmitting(false);
  };

  const formProps = {
    handleSubmit: onSave,
    defaultValidation: true,
    initialValues: {
      ...inputDefaults,
      ndiSourceName: ndiList?.[0]?.sourceName
    }
  };
  return <InputFormLayout formProps={formProps} formControl={formControl} />;
};
