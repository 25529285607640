import { ILicenseSettings } from 'api/settings/system.type';
import { RoleContext } from 'permissions/role/Roles';
import { useContext } from 'react';
export interface ILicensesState {
  license: ILicenseSettings | null;
}

export enum EnumLicensesAction {
  SET_LICENSE = 'SET_LICENSE',
  RESET = 'RESET'
}

export interface ILicensesAction {
  type: EnumLicensesAction;
  payload: any;
}

// Init states

export const initLicensesState: ILicensesState = {
  license: null
};
// Reducer
export const licensesReducer = (state: ILicensesState, action: ILicensesAction): ILicensesState => {
  switch (action.type) {
    case EnumLicensesAction.SET_LICENSE: {
      return {
        ...state,
        license: action.payload
      };
    }
    case EnumLicensesAction.RESET: {
      return { ...state, ...initLicensesState };
    }
    default:
      return state;
  }
};

export const isLicensed = (i: any) => {
  const role = useContext(RoleContext);
  return role.can('license', i.licensedName ?? i.value);
};
