import { t } from 'i18n';
import * as Yup from 'yup';

import constant from './constant';

const srtPortErrMsg = t('VALIDATION.STREAM.srtPort', {
  min: constant.stream.srtPortMin,
  max: constant.stream.srtPortMax
});

const srtLatencyErrMsg = t('VALIDATION.STREAM.srtLatency', {
  min: constant.stream.srtLatencyMin,
  max: constant.stream.srtLatencyMax
});

export const streamValidation = {
  srtPort: Yup.number()
    .required(srtPortErrMsg)
    .min(constant.stream.srtPortMin, srtPortErrMsg)
    .max(constant.stream.srtPortMax, srtPortErrMsg),
  srtLatency: Yup.number()
    .required(srtLatencyErrMsg)
    .min(constant.stream.srtLatencyMin, srtLatencyErrMsg)
    .max(constant.stream.srtLatencyMax, srtLatencyErrMsg),
  srtMode: Yup.string().required(t<string>('VALIDATION.STREAM.srtMode')),
  name: Yup.string().required(t<string>('VALIDATION.STREAM.name')),
  streamType: Yup.string().required(t<string>('VALIDATION.STREAM.streamType')),
  networkInterface: Yup.string().notRequired()
};

const domainRegex = new RegExp(/^(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.?)+[a-z]{2,}$/);
const v4 =
  '(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}';

// Stolen from Makito
const ipv6Regex =
  // eslint-disable-next-line max-len
  /^((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?$/;

export const isIpV4 = (value: any, required: boolean = true) =>
  value ? new RegExp(`^${v4}$`).test(value) : !required;

export const isIpV6 = (value: any, required: boolean = true) =>
  value ? ipv6Regex.test(value) : !required;

export const isSubMask = (value: any, required: boolean = true) => {
  return value
    ? /^(255).(0|128|192|224|240|248|252|254|255).(0|128|192|224|240|248|252|254|255).(0|128|192|224|240|248|252|254|255)/gm.test(
        value
      )
    : !required;
};

export const encloseSquareBracketIPv6 = (address: string) =>
  isIpV6(address) ? '[' + address + ']' : address;

export const isHostname = (value: any, required: boolean = true) => {
  if (!value) {
    return !required;
  }
  const labels = value.split('.');
  // hostname labels can be up to 63 characters long
  if (labels.some((i: string) => i.length > 63)) {
    return false;
  }
  return new RegExp(
    /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])(:[0-9]{1,5})?$/
  ).test(value);
};

export const isDomain = (value: any) => value && value.match(domainRegex);

export const isRTSPUrl = (value: string) => {
  return value.match(/^rtsp:\/\/.+/);
};

export const isUrl = (value: string) => !!value.match(/^[^#%+, ]*$/);

export const isRTMPUrl = (value: any) => {
  if (!value || !isUrl(value)) {
    return false;
  }
  return value.match(/^rtmp:\/\/.+/);
};

export const generalValidation = {
  password: Yup.string()
    .required(t<string>('VALIDATION.SETTINGS.password'))
    .min(6, t<string>('VALIDATION.SETTINGS.passwordMustbe6')),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], t<string>('VALIDATION.SETTINGS.matchPasswords'))
    .required(t<string>('VALIDATION.SETTINGS.confirmPwd')),
  required: (item: string) => {
    return Yup.string().required(t<string>('required', { item }));
  },
  number: Yup.number().typeError(t('VALIDATION.notNumber') as string),
  hostname: (msg: string) => {
    return Yup.string()
      .nullable()
      .required(t<string>('VALIDATION.SETTINGS.hostname'))
      .test('hostname', msg, value => {
        return isHostname(value);
      });
  },
  dnsServer: (msg: string) => {
    return Yup.string()
      .nullable()
      .test('dnsServer', msg, value => {
        if (!value) {
          return true;
        }
        return isIpV4(value) || isIpV6(value);
      });
  },
  ntpServer: (msg: string) => {
    return Yup.string()
      .nullable()
      .test('ntpServer', msg, value => {
        if (!value) {
          return true;
        }
        return isHostname(value) || isIpV4(value) || isIpV6(value);
      });
  }
};

export const port = (t: Function) =>
  Yup.number()
    .typeError(t('validation.port'))
    .min(1, t('validation.port'))
    .max(65535, t('validation.port'));
