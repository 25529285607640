import { IGeneralApiProps } from 'api/global.type';
import { ISystemNetwork } from 'api/settings/system.type';
import { INetwork } from 'context/settings/network';

import client, { disableNormalizingConfig, handleErrors } from '../utils/client';

export const getSystemNetwork = (props?: IGeneralApiProps) => {
  return client
    .get('/system/network')
    .then(resp => {
      const data: ISystemNetwork = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return e;
    });
};

interface IGetSystemBandwidth extends IGeneralApiProps {
  start: number;
  end: number;
}
export interface ISystemBandwidth {
  x?: number;
  y?: number;
}

export const getSystemBandwidth = (props: IGetSystemBandwidth) => {
  const { start, end, onError } = props;
  handleErrors(props);

  return client
    .get(`/system/metric/network?startTime=${start}&endTime=${end}`)
    .then(resp => {
      const data = resp.data;
      return convertToGraphData(data) as ISystemBandwidth[];
    })
    .catch(e => {
      onError && onError(e);
      return null;
    });
};

interface IUpdateSystemNetwork extends IGeneralApiProps {
  item: INetwork;
}

export const updateSystemNetwork = (props: IUpdateSystemNetwork) => {
  return client
    .put('/system/network', formdataToApi(props.item), disableNormalizingConfig)
    .then(resp => {
      const data: INetwork = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      throw e;
    });
};

const formdataToApi = (apiData: INetwork) => {
  return {
    ...apiData,
    nics: apiData.nics.map(nic => {
      return {
        ...nic,
        speed: nic.link === 'MANUAL' ? nic.speed || 10 : null,
        duplex: nic.link === 'MANUAL' ? nic.duplex || 'FULL' : null,
        addressing: nic.addressing || 'NONE',
        ipv6PrivacyExtension: nic.ipv6PrivacyExtension ? 2 : 0
      };
    })
  };
};

export interface IBandwidthUnformatted {
  receivedMbps?: IGraphMetric[];
  sentMbps?: IGraphMetric[];
}

interface IGraphMetric {
  time: number;
  value: number;
}

interface IXy {
  x: number;
  y: number;
}

const convertToGraphData = (data: IBandwidthUnformatted) => {
  const sent: IXy[] = [];
  const recieved: IXy[] = [];

  data?.sentMbps?.map((d: IGraphMetric, index: number) => {
    // have the chart data end and start at the respected times.
    const mins =
      data.sentMbps?.length === index + 1 ? 0 : index === 0 ? 5 : (Date.now() - d.time) / 1000 / 60;
    sent.push({ x: mins, y: d.value });
    recieved.push({
      x: mins,
      y: data?.receivedMbps?.find(rec => rec.time === d.time)?.value || 0
    });
  });
  return [sent, recieved];
};
