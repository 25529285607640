import { Dialog, Form, colorValue } from '@hai/ui-react';
import { ISystemNetworkNic } from 'api/settings/system.type';
import { FormContainer } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { buildFormSelect } from 'components/common/form/formSelect/formSelect';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import { staticRouteSchema } from 'components/settings/network/validations';
import { IStaticRoute } from 'context/settings/network';
import { t } from 'i18next';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useFormRef } from 'utils/hooks/useFormRef';

interface IProps {
  show: boolean;
  handleClose: any;
  interfaces?: ISystemNetworkNic[];
  setStaticRoutes: Dispatch<SetStateAction<IStaticRoute[] | undefined>>;
  staticRoutes?: IStaticRoute[];
  selectedIndex?: number;
}

interface IFormData {
  destination: string;
  subnetMask: string;
  gateway: string;
  interface: string;
  protocol: number;
  prefixLength: number;
}

export const StaticRouteConfig = (props: IProps) => {
  const { show, handleClose, interfaces, setStaticRoutes, staticRoutes, selectedIndex } = props;

  const formControl = useFormRef();
  const { formRefCallback, formRef } = formControl;

  const options = interfaces?.map(i => {
    return {
      option: i.name + `${i.userLabel ? ' (' + i.userLabel + ')' : ''}`,
      value: i.name
    };
  });

  const selected =
    selectedIndex !== undefined && selectedIndex > -1 && staticRoutes?.length
      ? staticRoutes[selectedIndex]
      : null;

  const initData = {
    destination: selected?.destination || '',
    subnetMask: selected?.subnetMask || '',
    prefixLength: selected?.prefixLength || 0,
    gateway: selected?.gateway || '',
    interface: selected?.interface || (options ? options[0].value : ''),
    protocol: selected?.protocol || 4
  };

  const [formData, setFormData] = useState<IFormData>(initData);

  const onSave = () => {
    let newRoutes;
    const values = formRef?.current?.values;
    const newRoute = {
      ...values,
      prefixLength: values.protocal === 4 ? null : values.prefixLength || 0
    };
    if (!selected) {
      // Add
      if (staticRoutes?.length) {
        newRoutes = [...staticRoutes];
        newRoutes.push(newRoute);
      } else {
        newRoutes = [newRoute];
      }
    } else {
      // Edit
      newRoutes = staticRoutes ? [...staticRoutes] : [];
      if (selectedIndex !== undefined) {
        newRoutes[selectedIndex] = formRef?.current?.values;
      }
    }
    setStaticRoutes(newRoutes);
    handleClose();
  };

  const formProps = {
    defaultValidation: true,
    handleSubmit: onSave,
    initialValues: formData,
    restValidationProps: {
      innerRef: formRefCallback,
      validationSchema: staticRouteSchema
    }
  };

  const onChangeHandler = () => {
    const formValues = formRef?.current?.values;
    const newValues = JSON.parse(JSON.stringify(formValues));
    setFormData(newValues);
  };

  const protocolOptions = [
    {
      option: t('SETTINGS.NETWORK.ipV4'),
      value: 4
    },
    {
      option: t('SETTINGS.NETWORK.ipV6'),
      value: 6
    }
  ];

  return (
    <Dialog
      title={
        selected ? t('SETTINGS.NETWORK.editStaticRoute') : t('SETTINGS.NETWORK.addStaticRoute')
      }
      size='md'
      dialogType='activity'
      accentColor={colorValue('haiui-aqua-01')}
      headerIcon={selected ? 'Edit' : 'Route'}
      onCancel={handleClose}
      onHide={handleClose}
      dataAuto='modal_add_static_route'
      content={
        <Form {...formProps} className={'generate-cert-form'}>
          <FormContainer columns={1}>
            {buildFormSelect({
              label: t('SETTINGS.NETWORK.protocol'),
              items: protocolOptions,
              onChange: (event: any) => {
                const newValues = {
                  ...initData,
                  protocol: event.target.value
                };
                formRef.current.setValues(newValues).then(() => {
                  onChangeHandler();
                });
              },
              selectName: 'protocol',
              dataAuto: 'protocol'
            })}
            <FormInput
              label={t('SETTINGS.NETWORK.destination')}
              name='destination'
              required
              dataAuto='destination'
            />
            {formData.protocol === 4 ? (
              <FormInput
                label={t('SETTINGS.NETWORK.subnetMask')}
                name='subnetMask'
                onChange={onChangeHandler}
                required
                dataAuto='subnetmask'
              />
            ) : (
              <FormInput
                label={t('SETTINGS.NETWORK.prefixLength')}
                name='prefixLength'
                onChange={onChangeHandler}
                required
                dataAuto='prefix'
              />
            )}
            <FormInput
              label={t('SETTINGS.NETWORK.gateway')}
              name='gateway'
              onChange={onChangeHandler}
              dataAuto='gateway'
            />
            {options &&
              buildFormSelect({
                label: t('SETTINGS.NETWORK.interface'),
                items: options,
                selectName: 'interface',
                selectedId: options[0].value,
                onChange: onChangeHandler,
                dataAuto: 'interface'
              })}
          </FormContainer>
        </Form>
      }
      show={show}
      onClose={handleClose}
      buttons={[
        {
          variant: 'secondary',
          onClick: handleClose,
          disabled: false,
          label: t('cancel')
        },
        {
          variant: 'primary',
          close: false,
          onClick: () => submitHandler(formControl.formRef),
          disabled: !formControl.formSubmitActive,
          label: selected ? t('SETTINGS.NETWORK.editRoute') : t('SETTINGS.NETWORK.addRoute')
        }
      ]}
    />
  );
};
