import { Button, Header, Label2Book } from '@hai/ui-react';
import { getDashboardStatsById } from 'api/dashboard/dashboard';
import { IDashboardStat } from 'api/dashboard/dashboard.type';
import { IStreamItem } from 'api/streaming/streaming.type';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { streamingTabPaths } from 'components/routes/routePaths';
import { IStreamChartData, StreamChart } from 'components/streaming/streams/StreamChart';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router';
import constant from 'utils/constant';
import { humanReadableData, uptimeDayHHMMSS } from 'utils/formatText';
import { EnumStreamStateString } from 'utils/hooks/useDashboardInfo';
import { EnumStreamState } from 'utils/hooks/useStreamInfo';

interface IProps {
  stream: IStreamItem;
}

export const StreamStats: React.FC<IProps> = props => {
  const { stream } = props;

  const initData = {
    left: [[], []],
    right: []
  };

  const isRunning =
    stream.state === EnumStreamState.STREAMING || stream.state === EnumStreamState.WAITING;

  const navigate = useNavigate();

  const [graphHistory, setGraphHistory] = useState<IStreamChartData>({
    left: [[], []],
    right: []
  });
  const [generalStats, setGeneralStats] = useState<any[]>([
    {
      title: t('STREAMING.STREAMS.REPORT.uptime'),
      value: '00:00:00'
    },
    {
      title: t('STREAMING.STREAMS.REPORT.state'),
      value: isRunning ? t('STREAMING.STREAMS.active') : t('STREAMING.STREAMS.inactive')
    }
  ]);
  const scaleMultiplier = ((5000 / 1000) * 5) / 300;

  const getStats = useCallback(() => {
    // graph
    getDashboardStatsById({ uuid: stream.uuid, ignoreErrors: true }).then(
      (stats: IDashboardStat) => {
        if (
          stats &&
          (stats.state === EnumStreamStateString.STREAMING ||
            stats.state === EnumStreamStateString.WAITING)
        ) {
          // input rate
          const left1 = [...graphHistory.left[0]];
          const newLeft1 = left1.map((i: { x: number; y: number }) => {
            return { x: i.x + scaleMultiplier, y: i.y };
          });
          if (stats.inputBitrate !== -1) {
            newLeft1.unshift({
              x: 0,
              y: stats.inputBitrate
            });
          }
          // stream rate
          const left2 = [...graphHistory.left[1]];
          const newLeft2 = left2.map((i: { x: number; y: number }) => {
            return { x: i.x + scaleMultiplier, y: i.y };
          });
          if (stats.encoderBitrate !== undefined) {
            newLeft2.unshift({
              x: 0,
              y: stats.encoderBitrate
            });
          }
          // latency
          const right = graphHistory.right;
          const newRight = right.map((i: { x: number; y: number }) => {
            return { x: i.x + scaleMultiplier, y: i.y };
          });
          if (stats.outputLatency !== undefined) {
            newRight.unshift({
              x: 0,
              y: stats.outputLatency
            });
          }

          setGraphHistory({
            left: [newLeft1, newLeft2],
            right: newRight
          });
          // general
          const newGeneral = [
            {
              title: t('STREAMING.STREAMS.REPORT.uptime'),
              value: stats?.runtimeSeconds ? uptimeDayHHMMSS(stats?.runtimeSeconds) : '00:00:00'
            },
            {
              ...(stats?.totalOutputBitrate
                ? {
                    title: t('DASHBOARD.BOTTOM_ROW.overallRate'),
                    value: humanReadableData({ bytes: stats?.totalOutputBitrate, max: 100 })
                  }
                : {})
            },

            {
              ...(stats?.inputBitrate
                ? {
                    title: t('DASHBOARD.BOTTOM_ROW.input'),
                    value: humanReadableData({ bytes: stats?.inputBitrate, max: 100 })
                  }
                : {})
            },

            {
              ...(stats?.video?.resolution
                ? {
                    title: t('STREAMING.TRANSCODERS.DECODER_STATS.resolution'),
                    value: `${stats.video.resolution.width}x${stats.video.resolution.height}${
                      stats.video.isInterlaced ? 'i' : 'p'
                    }`
                  }
                : {})
            },
            {
              ...(stats?.video?.framerate
                ? {
                    title: t('fps'),
                    value: `${Number(stats.video.framerate.fpsStr)}`
                  }
                : {})
            }
          ];
          setGeneralStats(newGeneral);
        }
      }
    );
  }, [graphHistory.left, graphHistory.right, scaleMultiplier, stream.uuid]);

  const reset = () => {
    setGraphHistory(initData);
  };

  useEffect(() => {
    if (isRunning) {
      if (graphHistory && graphHistory?.right.length < 1) {
        getStats();
      }
      const interval = setInterval(() => {
        getStats();
      }, constant.interval.streamStats);
      return () => {
        clearInterval(interval);
      };
    } else if (!isRunning && graphHistory && graphHistory?.right?.length > 1) {
      const interval = setInterval(() => {
        const left1 = [{ x: 0, y: 0 }, ...graphHistory.left[0]];
        const newLeft1 = left1.map((i: { x: number; y: number }) => {
          return { x: i.x + scaleMultiplier, y: i.y };
        });
        const left2 = [{ x: 0, y: 0 }, ...graphHistory.left[1]];
        const newLeft2 = left2.map((i: { x: number; y: number }) => {
          return { x: i.x + scaleMultiplier, y: i.y };
        });
        const right = [{ x: 0, y: 0 }, ...graphHistory.right];
        const newRight = right.map((i: { x: number; y: number }) => {
          return { x: i.x + scaleMultiplier, y: i.y };
        });
        setGraphHistory({
          left: [newLeft1, newLeft2],
          right: newRight
        });
      }, constant.interval.streamStats);
      return () => {
        clearInterval(interval);
      };
    }
  }, [getStats, graphHistory, isRunning, scaleMultiplier]);

  const slicedLeftData: any[] = graphHistory.left.map((d: string | any[]) =>
    d.slice(0, 300 / (constant.interval.streamStats / 1000))
  );

  const slicedRightData = graphHistory.right.slice(0, 300 / (constant.interval.streamStats / 1000));

  const slicedGraphHistory = {
    left: slicedLeftData,
    right: slicedRightData
  };

  return (
    <FadeIn delay={300}>
      <div className='stream-report-panel'>
        <StreamChart showTimePicker={false} data={slicedGraphHistory} isPanelChart={true} />
        <div className='stream-report-panel-section'>
          <Header
            className='panel-header'
            type='panel-header'
            title={t('STREAMING.STREAMS.REPORT.statistics') as string}
          />
          {generalStats &&
            generalStats.map((item: any) => {
              return (
                <div className='panel-section-stats' key={item.title}>
                  <Label2Book className='stats-left'>{item.title}</Label2Book>
                  <Label2Book className='stats-right' multiline={false} ellipsis={true}>
                    {item.value}
                  </Label2Book>
                </div>
              );
            })}
          <div className='stats-buttons'>
            <Button disabled={!isRunning} variant='secondary' onClick={reset}>
              {t('STREAMING.STREAMS.REPORT.reset')}
            </Button>
            <Button
              disabled={!isRunning}
              variant='secondary'
              onClick={() => {
                const path = generatePath(streamingTabPaths.streamStats, { id: stream.uuid });
                navigate(path, {
                  state: { stream: stream, history: graphHistory }
                });
              }}
            >
              {t('STREAMING.STREAMS.REPORT.detailedStats')}
            </Button>
          </div>
        </div>
      </div>
    </FadeIn>
  );
};
