import { DataTable } from '@hai/ui-react';
import { HaiDataTableColumnType } from '@hai/ui-react/dist/components/DataTable/IHaiDataTable';
import { IStreamItem } from 'api/streaming/streaming.type';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { streamingTabPaths } from 'components/routes/routePaths';
import { EnumActions } from 'components/streaming/streams/StreamItem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useStreamInfo } from 'utils/hooks/useStreamInfo';

interface IInputStatsProps {
  streams?: IStreamItem[];
}

interface rowData {
  id: string;
  status: string;
  name: string;
}

export const StreamTable: React.FC<IInputStatsProps> = props => {
  const { streams } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [rowData, setRowData] = useState<rowData[]>([]);
  const getStreamInfo = useStreamInfo();

  const headerData = [
    { fieldKey: 'status', title: t('STREAMING.status'), type: HaiDataTableColumnType.ICON },
    { fieldKey: 'name', title: t('STREAMING.streamName') },
    { fieldKey: '', title: '', type: HaiDataTableColumnType.ACTIONS }
  ];

  useEffect(() => {
    const rows: rowData[] = [];
    streams &&
      streams.map((s: IStreamItem) => {
        const { uuid, name } = s;
        rows.push({
          id: uuid,
          status: getStreamInfo(s).icon,
          name: name
        });
      });
    setRowData(rows);
  }, [getStreamInfo, streams]);

  const onAction = (eventKey: any, data: any) => {
    const path = generatePath(streamingTabPaths.streams, { id: data.id });
    navigate(path, {
      state: { id: data.id, action: eventKey }
    });
  };

  const actionItems = [
    {
      actionIcon: 'ReportStats',
      title: '',
      eventKey: EnumActions.REPORT,
      onSelect: onAction
    },
    { actionIcon: 'Settings', title: '', eventKey: EnumActions.EDIT, onSelect: onAction }
  ];

  return streams ? (
    <FadeIn delay={300}>
      <DataTable
        compact={true}
        selectable={false}
        sortable={rowData.length > 1}
        columnStructure={headerData}
        useFsMask={true}
      >
        <DataTable.Header />
        {rowData.map((row: rowData) => (
          <DataTable.Row key={row.id} rowData={row} actionItems={actionItems}></DataTable.Row>
        ))}
      </DataTable>
    </FadeIn>
  ) : (
    <div />
  );
};
