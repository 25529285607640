import { IGeneralApiProps } from 'api/global.type';
import client from 'api/utils/client';
import { decodeResponse } from 'api/utils/helper';

interface IGetEvents extends IGeneralApiProps {
  page: number;
  pageSize: number;
}

export interface IEventsPaging {
  results: number;
  page: number;
  pageSize: number;
}

export interface IEvent {
  date: string;
  message: string;
  name: string;
  pid: string;
}

export interface IEvents {
  eventList: IEvent[];
  paging: IEventsPaging;
}

export const getEvents = (props: IGetEvents) => {
  const { page, pageSize, onError } = props;

  return client
    .get(`/events?page=${page}&pageSize=${pageSize}`)
    .then(resp => {
      return apiToFormData(resp.data);
    })
    .catch(e => {
      onError && onError(e);
      return e;
    });
};

const apiToFormData = (data: IEvents) => {
  return {
    paging: data.paging,
    eventList: data.eventList.map(item => {
      return {
        ...item,
        name: decodeResponse(item.name),
        message: decodeResponse(item.message)
      };
    })
  };
};

export const downloadEvents = (props?: IGeneralApiProps) => {
  return client
    .get(`/events?format=download`)
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return e;
    });
};
