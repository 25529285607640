import { Label3Book, Label3Med } from '@hai/ui-react';
import React from 'react';
import './statsDetailPanel.scss';

interface IProps {
  title?: string;
  data?: any[];
  dataAuto?: string;
}

const StatsDetailPanel: React.FunctionComponent<IProps> = ({ title, data, dataAuto }) => {
  return (
    <div className='stats-panel-container' data-auto={`stats_section_${dataAuto}`}>
      <div className='header'>
        <Label3Med className='header-title' data-auto={`stats_section_header`}>
          {title}
        </Label3Med>
      </div>
      <div className='content'>
        {data &&
          data.map(item => {
            return (
              <div key={item.name} className='content-item' data-auto={`stats_${item.dataAuto}`}>
                <Label3Book
                  multiline={false}
                  ellipsis={true}
                  className='left'
                  data-auto='stat_title'
                >
                  {item.name}
                </Label3Book>
                <Label3Book
                  multiline={false}
                  ellipsis={true}
                  className='right'
                  data-auto='stat_value'
                >
                  {item.value}
                </Label3Book>
              </div>
            );
          })}
      </div>
      <div className='bottom-filler' />
    </div>
  );
};

export default StatsDetailPanel;
