import { IGeneralApiProps } from 'api/global.type';
import client, { handleErrors } from 'api/utils/client';
import { decodeResponse } from 'api/utils/helper';

export const getDashboardStats = (props: IGeneralApiProps) => {
  const { onError } = props;
  return client
    .get('/dashboard-stats')
    .then(resp => {
      return apiToFormdata(resp.data.statsList);
    })
    .catch(e => {
      onError && onError(e);
    });
};

interface IGetDashboardStatsByIdProps extends IGeneralApiProps {
  uuid: string;
}

export const getDashboardStatsById = (props: IGetDashboardStatsByIdProps) => {
  const { onError, uuid } = props;
  handleErrors(props);
  return client
    .get(`/dashboard-stats/${uuid}`)
    .then(resp => {
      return resp.data;
    })
    .catch(e => {
      onError && onError(e);
    });
};

const apiToFormdata = (apiData: any) => {
  return apiData.map((i: any) => {
    return {
      ...i,
      streamName: decodeResponse(i.streamName)
    };
  });
};
