import { EnumProtocolType } from 'api/global.type';

export enum EnumStreamType {
  SRT = 'SRT',
  // UDP
  MPEG2TS = 'MPEG2TS',
  RTSP = 'RTSP',
  MJPEGRAW = 'MJPEGRAW',
  RTMP = 'RTMP',
  // tbd
  CAPTURE = 'CAPTURE',
  DECKLINK = 'DECKLINK',
  GIGEVIS = 'GIGEVIS',
  NDI = 'NDI',
  ST2110 = 'ST2110',
  V4L2 = 'V4L2'
}

export const streamSourceOptions = [
  {
    value: EnumStreamType.SRT,
    option: 'TS over SRT'
  },
  {
    value: EnumStreamType.MPEG2TS,
    option: 'TS over UDP'
  },
  {
    value: EnumStreamType.RTSP,
    option: 'RTSP'
  },
  {
    value: EnumStreamType.MJPEGRAW,
    option: 'RAW Motion JPEG'
  },
  {
    value: EnumStreamType.RTMP,
    option: 'RTMP'
  }
];

export const getStreamSourceDisplayValue = (value: EnumStreamType) => {
  return streamSourceOptions.find(o => o.value === value)?.option || '';
};

export interface IStreamItem {
  uuid: string;
  autoStart: boolean;
  input: string;
  logToFile: boolean;
  metadatas: string | any[];
  mode: string;
  name: string;
  outputs: string[];
  passthruUuid?: string;
  state: number;
  status: string;
  subStatus: string;
  transcoder: string;
  inputBypass?: string;
  inputPassthru?: string;
  // added for front-end
  success?: boolean;
  selected?: boolean;
  inputName?: string;
  outputSummaries?: Array<{
    id: string;
    name: string;
    streamType?: EnumProtocolType;
  }>;
}

export interface IStreamSnapshot {
  sessionId: string;
  snapshotId: string;
}

export enum EnumStreamStatus {
  ACTIVE = 'ACTIVE',
  WARNING = 'WARNING',
  STOPPED = 'STOPPED'
}

export enum EnumStreamSubStatus {
  STARTING = 'STARTING',
  CONNECTED = 'CONNECTED',
  WARNING = 'WARNING',
  NO_DATA = 'NO_DATA',
  INPUT_ERROR = 'INPUT_ERROR',
  OUTPUT_ERROR = 'OUTPUT_ERROR',
  TRANSCODER_ERROR = 'TRANSCODER_ERROR',
  ERROR = 'ERROR',
  OFFLINE = 'OFFLINE'
}

export enum EnumStreamMode {
  LISTENER = 'listener',
  CALLER = 'caller',
  RENDEZVOUS = 'rendezvous'
}

export const srtModeOptions = [
  {
    value: EnumStreamMode.LISTENER,
    option: 'Listener'
  },
  {
    value: EnumStreamMode.CALLER,
    option: 'Caller'
  },
  {
    value: EnumStreamMode.RENDEZVOUS,
    option: 'Rendezvous'
  }
];

export const getStreamModeDisplayValue = (value: EnumStreamMode) => {
  return srtModeOptions.find(o => o.value === value)?.option || '';
};

export enum EnumSnapshotTapPoint {
  INPUT = 'inputtap'
}
