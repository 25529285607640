import { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

import { normalizeRequest } from './propertyNormalizer';

interface IConfig extends InternalAxiosRequestConfig {
  ignoreErrors: boolean;
  ignoreError: number[];
}
const onRequest = (config: IConfig): InternalAxiosRequestConfig => {
  const newConfig = config;
  config.ignoreErrors = config.headers.ignoreErrors;
  config.ignoreError = config.headers.ignoreError;
  delete config.headers.ignoreError;
  delete config.headers.ignoreErrors;

  if (!config.headers.disableNormalizing) {
    newConfig.data = normalizeRequest(newConfig.data);
  }
  return newConfig;
};

const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest as any, undefined);
  return axiosInstance;
};

export default setupInterceptorsTo;
