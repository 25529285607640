import { Dialog, Form, colorValue } from '@hai/ui-react';
import { addCertificate, getCertificates } from 'api/security/certificates';
import { EnumFormButtonState } from 'components/common/form/formButton/formButton';
import { FormCheckbox } from 'components/common/form/formCheckbox/formCheckbox';
import { FormContainer } from 'components/common/form/formContainer/formContainer';
import { FormInfo } from 'components/common/form/formInfo/formInfo';
import { FormInput } from 'components/common/form/formInput/formInput';
import { BuildFormSelect } from 'components/common/form/formSelect/formSelect';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import { EditSubject } from 'components/security/certificates/EditSubject';
import { addCertSchema } from 'components/security/certificates/validations';
import { EnumNotify, NotifyContext } from 'context/notify';
import {
  EnumCertificateType,
  EnumCertificatesAction,
  ICertificate
} from 'context/security/certificates';
import { SecurityContext } from 'context/security/security';
import { t } from 'i18next';
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useFormRef } from 'utils/hooks/useFormRef';

interface IProps {
  show: boolean;
  handleClose: any;
}

const certificateTypeOptions = [
  {
    option: t('SECURITY.CERTIFICATES.TYPES.csr'),
    value: EnumCertificateType.CSR
  },
  {
    option: t('SECURITY.CERTIFICATES.TYPES.self'),
    value: EnumCertificateType.SELF
  }
];

const digestAlgoOptions = [
  {
    option: t('SECURITY.CERTIFICATES.DIGEST.sha256'),
    value: 'sha256'
  },
  {
    option: t('SECURITY.CERTIFICATES.DIGEST.sha384'),
    value: 'sha384'
  },
  {
    option: t('SECURITY.CERTIFICATES.DIGEST.sha512'),
    value: 'sha512'
  }
];

export const GenerateCertificate = (props: IProps) => {
  const { show, handleClose } = props;

  const [buttonState, setButtonState] = useState(EnumFormButtonState.IDLE);
  const [showEdit, setShowEdit] = useState(false);

  const formControl = useFormRef();
  const { formRefCallback, formRef } = formControl;

  const subjectFormControl = useFormRef();

  const { securityDispatch } = useContext(SecurityContext);
  const { dispatch } = useContext(NotifyContext);

  const initData = useMemo(() => {
    return {
      name: '',
      subject:
        '/C=US/ST=Arizona/L=Scottsdale/O=GoDaddy.com, Inc./OU=http://certs.godaddy.com/repository//' +
        'CN=Go Daddy Secure Certificate Authority - G2',
      digest: 'sha256',
      type: EnumCertificateType.CSR,
      newPrivateKey: false,
      newKey: '2048',
      v3: ''
    };
  }, []);

  const [formData, setFormData] = useState(initData);

  useEffect(() => {
    formRef.current?.setFieldValue('subject', formData.subject, false);
  }, [formData.subject, formRef]);

  useEffect(() => {
    if (!formData.newPrivateKey) {
      formRef.current?.setFieldValue('newKey', '2048', false);
    }
  }, [formData.newPrivateKey, formRef]);

  const isCsr = formData.type === EnumCertificateType.CSR;

  useEffect(() => {
    setFormData(initData);
    setButtonState(EnumFormButtonState.IDLE);
  }, [initData, show]);

  useEffect(() => {
    if (isCsr && formData.newPrivateKey) {
      setFormData({
        ...formData,
        newPrivateKey: false,
        newKey: '2048'
      });
      formRef.current?.setFieldValue('newPrivateKey', false, false);
      formRef.current?.setFieldValue('newKey', '2048', false);
    }
  }, [formData, formRef, isCsr]);

  const onChangeHandler = (evt: any) => {
    const { name, value } = evt.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const onSave = async (values: any) => {
    setButtonState(EnumFormButtonState.PENDING);
    const certificate = {
      digest: values.digest,
      name: values.name,
      subject: values.subject,
      type: values.type,
      v3extension: values.v3,
      keyLength: values.newKey,
      newPrivateKey: values.newPrivateKey
    };
    const res = await addCertificate({ item: certificate });
    if (res.data) {
      getCertificates().then((value: ICertificate[]) => {
        value &&
          securityDispatch({ type: EnumCertificatesAction.SET_CERTIFICATES, payload: value });
        setButtonState(EnumFormButtonState.SUCCESS);
        handleClose();
        if (res.data) {
          dispatch({
            type: EnumNotify.ADD_NOTIFICATION,
            payload: {
              type: 'Success',
              message: t('SECURITY.CERTIFICATES.MESSAGES.added', { name: values.name })
            }
          });
        }
      });
    } else {
      setButtonState(EnumFormButtonState.IDLE);
    }
  };

  const formProps = {
    defaultValidation: true,
    handleSubmit: onSave,
    initialValues: formData,
    restValidationProps: {
      validationSchema: addCertSchema,
      innerRef: formRefCallback
    }
  };

  const onCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      newPrivateKey: e.target.checked
    });
  };

  const isPending = buttonState === EnumFormButtonState.PENDING;

  const main = () => (
    <Form
      {...formProps}
      onSubmit={e => {
        e.preventDefault();
      }}
      className={'generate-cert-form'}
    >
      <FormContainer columns={1}>
        <FormInput
          label={t('SECURITY.CERTIFICATES.FORMS.name')}
          name='name'
          defaultValue={''}
          required
          onChange={onChangeHandler}
          disabled={isPending}
          dataAuto='cert_name'
        />
        {BuildFormSelect({
          label: t('SECURITY.CERTIFICATES.FORMS.type'),
          items: certificateTypeOptions,
          selectName: 'type',
          onChange: onChangeHandler,
          disabled: isPending,
          dataAuto: 'cert_type'
        })}
        {!isCsr && (
          <FormCheckbox
            formRef={formRef}
            label={t('SECURITY.CERTIFICATES.FORMS.newPrivateKey')}
            name='newPrivateKey'
            onChange={onCheck}
            className={'with-margin-bottom'}
            checked={formData.newPrivateKey}
            disabled={isPending}
          />
        )}
        {!isCsr && formData.newPrivateKey && (
          <>
            <FormInput
              label={t('SECURITY.CERTIFICATES.FORMS.newKey')}
              name='newKey'
              defaultValue={''}
              onChange={onChangeHandler}
              dataAuto='cert_key_length'
            />
            <FormInfo
              className={'new-key-warning'}
              info={t('SECURITY.CERTIFICATES.FORMS.newKeyWarning')}
            />
          </>
        )}
        {BuildFormSelect({
          label: t('SECURITY.CERTIFICATES.FORMS.digest'),
          items: digestAlgoOptions,
          selectName: 'digest',
          onChange: onChangeHandler,
          disabled: isPending,
          dataAuto: 'cert_digest'
        })}
        <FormInput
          label={t('SECURITY.CERTIFICATES.FORMS.subject')}
          name='subject'
          icon={'Edit'}
          onIconClick={() => (isPending ? null : setShowEdit(true))}
          onChange={onChangeHandler}
          disabled={isPending}
          required
          dataAuto='cert_subject'
        />
        {isCsr && (
          <FormInput
            as={'textarea'}
            label={t('SECURITY.CERTIFICATES.FORMS.v3')}
            name='v3'
            defaultValue={''}
            onChange={onChangeHandler}
            disabled={isPending}
            dataAuto='cert_v3_extension'
          />
        )}
      </FormContainer>
    </Form>
  );

  const onSaveSubject = (subject: string) => {
    setFormData({
      ...formData,
      subject: subject
    });
    setShowEdit(false);
  };

  const onCancelClick = () => {
    if (buttonState === EnumFormButtonState.PENDING) {
      return null;
    }
    if (showEdit) {
      setShowEdit(false);
      return false;
    } else {
      return handleClose();
    }
  };

  return (
    <Dialog
      title={
        showEdit
          ? t('SECURITY.CERTIFICATES.FORMS.editSubject')
          : t('SECURITY.CERTIFICATES.FORMS.generateCertificate')
      }
      size='md'
      dialogType='activity'
      accentColor={colorValue('haiui-aqua-01')}
      headerIcon={showEdit ? 'Edit' : 'Certificate'}
      content={
        showEdit ? (
          <EditSubject
            subjectString={formData.subject}
            formControl={subjectFormControl}
            onSave={onSaveSubject}
          />
        ) : (
          main()
        )
      }
      show={show}
      headerCloseButton={!showEdit || isPending}
      onClose={!isPending && !showEdit ? handleClose : null}
      buttons={[
        {
          variant: 'secondary',
          onClick: onCancelClick,
          disabled: isPending,
          label: showEdit ? t('back') : t('cancel')
        },
        {
          variant: 'primary',
          close: false,
          onClick: () => submitHandler(showEdit ? subjectFormControl.formRef : formControl.formRef),
          disabled: showEdit ? false : initData === formData,
          state: buttonState,
          label: showEdit
            ? t('SECURITY.CERTIFICATES.FORMS.editSubject')
            : t('SECURITY.CERTIFICATES.FORMS.generate')
        }
      ]}
      dataAuto='generate_certificate_modal'
    />
  );
};
