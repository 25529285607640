import { Form, FormGroup, FormLabel, Icon, OverlayTrigger, Switch, TagInput } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { IDevices } from 'api/devices/devices.type';
import {
  audioOptions,
  codecOptions,
  decimationFactor,
  decimationFactorMode,
  encoderOptions,
  formatOptions,
  frameRateOptions,
  framingOptions,
  klvTagsCustomOptions,
  klvTagsOptions,
  resolutionOptions
} from 'api/global.type';
import { UasSecurityTable, UasTable } from 'api/metadata/metadata.type';
import { ITranscoder } from 'api/transcoder/transcoder.type';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { FormButton } from 'components/common/form/formButton/formButton';
import { FormCheckbox } from 'components/common/form/formCheckbox/formCheckbox';
import { FormContainer, FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { FormInfo } from 'components/common/form/formInfo/formInfo';
import { FormInput } from 'components/common/form/formInput/formInput';
import { buildFormSelect } from 'components/common/form/formSelect/formSelect';
import { FormSliderWithInput } from 'components/common/form/formSlider/formSlider';
import { IsHidden, isHidden } from 'components/isHidden/isHidden';
import { validationSchema } from 'components/streaming/transcoders/transcoderValidation';
import { DevicesContext } from 'context/devices';
import { t } from 'i18next';
import { Licensed, RoleCan, RoleContext } from 'permissions/role/Roles';
import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { IFormRef } from 'utils/hooks/useFormRef';
import {
  Tags,
  UasMax,
  UasMin,
  UasSecurityMax,
  UasSecurityMin,
  UasSecurityNone,
  buildSelectedUasTags,
  filterOnActiveTagSets,
  filteredUasMax,
  filteredUasMin,
  filteredUasSecurityMax,
  filteredUasSecurityMin,
  tagSetToApi,
  uasDefaultDropdown,
  uasNone,
  uasSecurityDefaultDropdown
} from 'utils/metadata';

interface FormLayout {
  cancel?: () => void;

  applyBtn?: any;
  formControl: IFormRef;
  formProps: any;
  buttonState?: ButtonStateType;
  item?: ITranscoder;
  columns?: number;
  submitHandler?: (event: any) => void;
}

export const TranscoderFormLayout = (props: FormLayout) => {
  const { columns = 2, cancel, formControl, formProps, item, submitHandler, buttonState } = props;

  const { formRef, formRefCallback, formSubmitActive } = formControl;
  const role = useContext(RoleContext);
  const devices = useContext(DevicesContext);

  const [encoderList, setEncoderList] = useState([...encoderOptions]);
  const [audio, setAudio] = useState<boolean>(item ? item.audio : true);
  const [encoderType, setEncoderType] = useState<string>(item ? item.encoderType : 'unset');
  const [showShaping, setShowShaping] = useState<boolean>(
    item && item.shapingBitrate !== '' ? true : false
  );
  const [showFactor, setShowFactor] = useState(item && item.klvDecimationMode !== 'none');

  const [showMetadata, setShowMetadata] = useState<boolean>(
    item?.metadata === 'enabled' || item?.metadata === true
  );
  const [showUasSecurity, setShowUasSecurity] = useState<boolean>(
    item?.klvUasSecurityFilterEnabled ? true : false
  );
  const [showUas, setShowUas] = useState<boolean>(item?.klvUasFilterEnabled ? true : false);
  const [showOutputPacing, setShowOutputPacing] = useState<boolean>(
    (item?.outputPacing && item.shapingBitrate === '') ||
      formProps.initialValues.outputPacing === 'enabled'
      ? true
      : false
  );

  const [storedShapingBitrate, setStoredShapingBitrate] = useState(item?.shapingBitrate);

  useEffect(() => {
    if (!showShaping) {
      setStoredShapingBitrate(item?.shapingBitrate);
      formRef.current.setFieldValue('shapingBitrate', -1);
    } else {
      formRef.current.setFieldValue('shapingBitrate', storedShapingBitrate);
    }
  }, [formRef, item?.shapingBitrate, showShaping, storedShapingBitrate]);

  useEffect(() => {
    if (
      showUasSecurity !== item?.klvUasSecurityFilterEnabled &&
      item?.uasSecurityTags.length === 0
    ) {
      setSecurityUasTags(UasSecurityMin);
    }
  }, [item, showUasSecurity]);

  useEffect(() => {
    if (showUas !== item?.klvUasFilterEnabled && item?.uasTags.length === 0) {
      setUasTags(UasMin);
    }
  }, [item, showUas]);

  const [resolutionMode, setResolutionMode] = useState<string>(
    item?.resolutionMode ? item.resolutionMode.toString() : '3'
  );

  const [format, setFormat] = useState<string>(item?.videoType || 'avc');

  formProps.restValidationProps = {
    validationSchema,
    innerRef: formRefCallback,
    enableReinitialize: true
  };

  useEffect(() => {
    if (encoderType === 'qsv' && ['mpeg4', 'mpeg2video'].indexOf(format) !== -1) {
      setFormat('avc');
    }
  }, [encoderType, format]);

  const [uasSelectedTag, setUasSelectedTag] = useState(uasDefaultDropdown(item?.uasTags));
  const [uasSecuritySelectedTag, setUasSecuritySelectedTag] = useState(
    uasSecurityDefaultDropdown(item?.uasSecurityTags)
  );

  const [uasTags, setUasTags] = useState<Tags>(
    item?.uasTags ? buildSelectedUasTags(item.uasTags, UasTable) : UasMin
  );
  const [securityUasTags, setSecurityUasTags] = useState<Tags>(
    item?.uasSecurityTags
      ? buildSelectedUasTags(item.uasSecurityTags, UasSecurityTable)
      : UasSecurityMin
  );

  const changeModeFormat = (e: ChangeEvent<HTMLInputElement>) => {
    setFormat(e.target.value);
  };
  const changeEncoderType = (e: ChangeEvent<HTMLInputElement>) => {
    setEncoderType(e.target.value);
  };

  const setTagSetEvent = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case 'none':
        setUasTags(uasNone);
        break;
      case 'min':
        setUasTags(UasMin);
        break;
      case 'all':
        setUasTags(UasMax);
        break;
    }
  }, []);
  const setSecurityTagSetEvent = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case 'none':
        setSecurityUasTags(UasSecurityNone);
        break;
      case 'min':
        setSecurityUasTags(UasSecurityMin);
        break;
      case 'all':
        setSecurityUasTags(UasSecurityMax);
        break;
    }
  }, []);

  const changeResolutionMode = (e: ChangeEvent<HTMLInputElement>) => {
    setResolutionMode(e.target.value);
  };

  const decimationModeOnChange = (e: ChangeEvent<HTMLInputElement>) =>
    setShowFactor(e.target.value !== 'none');

  const shapingOnChange = (checked: boolean) => setShowShaping(checked);
  const audioOnChange = (checked: boolean) => setAudio(checked);
  const metadataOnChange = (checked: boolean) => setShowMetadata(checked);
  const uasKlvChange = (checked: boolean) => setShowUas(checked);
  const uasKlvSecurityChange = (checked: boolean) => setShowUasSecurity(checked);

  const outputPacingOnChange = (e: boolean) => setShowOutputPacing(e);

  useEffect(() => {
    if (!devices.fetching) {
      const list = [...encoderOptions];
      devices.devices?.forEach((nvidia: IDevices, index: number) => {
        list.push({
          option: `Hardware ${nvidia.name}${devices.devices.length > 1 ? index : ''}`,
          value: nvidia.index
        });
      });

      setEncoderList(list);
    }
  }, [devices]);

  const tagChangedUas = useCallback(
    (tagSet: Tags) => {
      const selectedTags = filterOnActiveTagSets(tagSet);
      let val = 'custom';
      switch (selectedTags) {
        case filteredUasMin:
          val = 'min';
          break;
        case filteredUasMax:
          val = 'all';
          break;
        case '':
          val = 'none';
          break;
        default:
          val = 'custom';
      }
      setUasTags(tagSet);
      setUasSelectedTag(val);

      formRef.current.setFieldValue('uasTags', tagSetToApi(tagSet));
      formRef.current.setFieldValue('klvUasFilterEnabledSets', val);
    },
    [formRef]
  );

  const tagChangedUasSecurity = useCallback(
    (tagSet: Tags) => {
      const selectedTags = filterOnActiveTagSets(tagSet);
      let val = 'custom';
      switch (selectedTags) {
        case filteredUasSecurityMin:
          val = 'min';
          break;
        case filteredUasSecurityMax:
          val = 'all';
          break;
        case '':
          val = 'none';
          break;
        default:
          val = 'custom';
      }
      setSecurityUasTags(tagSet);
      setUasSecuritySelectedTag(val);

      formRef.current.setFieldValue('uasSecurityTags', tagSetToApi(tagSet));
      formRef.current.setFieldValue('klvUasFilterSecuritySet', val);
    },
    [formRef]
  );

  const [klvUasDefaultSets, setKlvUasDefaultSets] = useState(
    uasSelectedTag === 'custom' ? klvTagsCustomOptions : klvTagsOptions
  );

  useEffect(() => {
    setKlvUasDefaultSets(
      formRef.current.values?.klvUasFilterEnabledSets === 'custom'
        ? klvTagsCustomOptions
        : klvTagsOptions
    );
    tagChangedUas(uasTags);
  }, [formRef, uasTags, tagChangedUas]);

  const [klvUasSecurityDefaultSets, setKlvUasSecurityDefaultSets] = useState(
    uasSecuritySelectedTag === 'custom' ? klvTagsCustomOptions : klvTagsOptions
  );
  useEffect(() => {
    setKlvUasSecurityDefaultSets(
      formRef.current?.values?.klvUasFilterSecuritySet === 'custom'
        ? klvTagsCustomOptions
        : klvTagsOptions
    );
    tagChangedUasSecurity(securityUasTags);
  }, [formRef, securityUasTags, tagChangedUasSecurity]);

  return (
    <FadeIn delay={300}>
      <Form {...formProps}>
        <FormContainer columns={columns}>
          <FormInput
            autoFocus
            item={item}
            label={t('STREAMING.TRANSCODERS.ADD.name')}
            name='name'
            required
            viewOnly={role.cannot('edit', 'transcoders', 'name')}
          />
          <RoleCan I='edit' a='transcoders'>
            {submitHandler && (
              <FormButton
                cancel={cancel}
                columns={columns}
                submitHandler={submitHandler}
                state={buttonState}
                disabled={!formSubmitActive}
              />
            )}
          </RoleCan>
        </FormContainer>
        <FormContainer columns={columns}>
          {buildFormSelect({
            label: t('STREAMING.TRANSCODERS.ADD.encoder'),
            viewOnly: role.cannot('edit', 'transcoders', 'encoderType'),
            items: encoderList,
            selectName: 'encoderType',
            onChange: changeEncoderType
          })}
        </FormContainer>
        <FormSectionDark title={t('STREAMING.TRANSCODERS.ADD.videoParameters')}>
          <FormContainer columns={columns}>
            {buildFormSelect({
              label: t('STREAMING.TRANSCODERS.ADD.format'),
              viewOnly: role.cannot('edit', 'transcoders', 'videoType'),
              items: formatOptions(encoderType),
              selectName: 'videoType',
              onChange: changeModeFormat,
              defaultValue: format
            })}

            <FormGroup
              {...isHidden({
                param: { format, encoderType },
                condition: (f: any) =>
                  ['hevc'].indexOf(f.format) === -1 || ['unset'].indexOf(f.encoderType) === -1,
                className: 'checkHelp'
              })}
            >
              <FormCheckbox
                formRef={formRef}
                viewOnly={role.cannot('edit', 'transcoders', 'performanceControlSystem')}
                name='performanceControlSystem'
                label={t('STREAMING.TRANSCODERS.ADD.performanceControlSystem')}
              />
              <OverlayTrigger
                popoverContent={t('STREAMING.TRANSCODERS.ADD.performanceControlSystemHelp')}
                className='checkbox-label-text'
              >
                <Icon iconname='HelpFilled' size='sm3' color='haiui-gray-06' />
              </OverlayTrigger>
            </FormGroup>

            {buildFormSelect({
              label: t('STREAMING.TRANSCODERS.codecProfile'),
              viewOnly: role.cannot('edit', 'transcoders', 'videoProfile'),
              items: codecOptions,
              selectName: 'videoProfile',
              selectedId: item?.videoProfile,
              isHidden: isHidden({
                param: format,
                condition: (f: string) => ['mpeg4', 'mpeg2video'].indexOf(f) === -1
              })
            })}
            <FormInput
              item={item}
              required={showShaping}
              viewOnly={role.cannot('edit', 'transcoders', 'videoBitrate')}
              label={t('STREAMING.TRANSCODERS.ADD.videoBitrate')}
              placeholder={t('STREAMING.TRANSCODERS.ADD.videoBitrateHelp')}
              showHint
              name='videoBitrate'
            />

            {buildFormSelect({
              label: t('STREAMING.TRANSCODERS.ADD.resolution'),
              viewOnly: role.cannot('edit', 'transcoders', 'resolutionMode'),
              items: resolutionOptions,
              selectedId: item?.resolutionMode.toString(),
              selectName: 'resolutionMode',
              onChange: changeResolutionMode
            })}

            <FormContainer
              columns={2}
              {...isHidden({
                param: resolutionMode,
                condition: (f: string) => ['1'].indexOf(f) === -1
              })}
            >
              <FormGroup className='mb-0'>
                <FormLabel>{t('STREAMING.TRANSCODERS.ADD.width')}</FormLabel>
                <FormInput
                  item={item}
                  name='resolutionWidth'
                  viewOnly={role.cannot('edit', 'transcoders', 'resolutionWidth')}
                />
              </FormGroup>
              <FormGroup className='mb-0'>
                <FormLabel>{t('STREAMING.TRANSCODERS.ADD.height')}</FormLabel>
                <FormInput
                  item={item}
                  name='resolutionHeight'
                  viewOnly={role.cannot('edit', 'transcoders', 'resolutionHeight')}
                />
              </FormGroup>
            </FormContainer>

            {buildFormSelect({
              label: t('STREAMING.TRANSCODERS.ADD.frameRate'),
              viewOnly: role.cannot('edit', 'transcoders', 'frameRate'),
              items: frameRateOptions,
              selectedId: item?.framerate,
              selectName: 'framerate'
            })}
            {buildFormSelect({
              label: t('STREAMING.TRANSCODERS.ADD.framing'),
              viewOnly: role.cannot('edit', 'transcoders', 'bframe'),
              items: framingOptions,
              selectedId: item?.bframe,
              selectName: 'bframe'
            })}
            <FormInput
              item={item}
              viewOnly={role.cannot('edit', 'transcoders', 'gopSize')}
              label={t('STREAMING.TRANSCODERS.ADD.gopSize')}
              name='gopSize'
            />

            {/* <FormCheckbox
            formRef={formRef}
            viewOnly={role.cannot('edit', 'transcoders', 'autoStart')}
            label={t('STREAMING.STREAMS.ADD.autoStart')}
            item={item}
            name='autoStart'
          /> */}

            <FormCheckbox
              isHidden={isHidden({
                param: encoderType,
                condition: (a: string) => ['unset', 'qsv'].indexOf(a) === -1
              })}
              formRef={formRef}
              viewOnly={role.cannot('edit', 'transcoders', 'intraRefresh')}
              item={item}
              label={t('STREAMING.TRANSCODERS.ADD.intraRefresh')}
              name='intraRefresh'
            />
          </FormContainer>
        </FormSectionDark>

        <FormSectionDark title={t('STREAMING.TRANSCODERS.ADD.audioParameters')}>
          <Switch
            checked={audio}
            name='audio'
            className='pb-4'
            disabled={role.cannot('edit', 'transcoders', 'audio')}
            labelPlacement='left'
            label={t('STREAMING.TRANSCODERS.ADD.includeAudio')}
            onChange={audioOnChange}
          />

          <IsHidden param={audio} condition={(a: boolean) => !a}>
            <FormContainer columns={columns}>
              {buildFormSelect({
                label: t('STREAMING.TRANSCODERS.ADD.audioCodec'),
                viewOnly: role.cannot('edit', 'transcoders', 'audioType'),
                items: audioOptions,
                selectName: 'audioType'
              })}

              <FormInput
                isHidden={isHidden({ param: audio, condition: (a: boolean) => !a })}
                label={t('STREAMING.TRANSCODERS.ADD.audioBitrate')}
                viewOnly={role.cannot('edit', 'transcoders', 'audioBitrate')}
                name='audioBitrate'
                item={item}
                placeholder={t('STREAMING.TRANSCODERS.ADD.audioBitrateHelp')}
                showHint
              />
            </FormContainer>
          </IsHidden>
        </FormSectionDark>

        <Licensed to='METADATA'>
          <FormSectionDark title={t('STREAMING.TRANSCODERS.ADD.metadata')} className='pb-4'>
            <Switch
              disabled={role.cannot('edit', 'transcoders', 'klvMetadata')}
              labelPlacement='left'
              className='pb-2'
              name='metadata'
              label={t('STREAMING.TRANSCODERS.ADD.klvMetadata')}
              onChange={metadataOnChange}
              checked={showMetadata}
            />
            <IsHidden param={showMetadata} condition={(a: boolean) => !a}>
              <FormContainer columns={columns}>
                {buildFormSelect({
                  viewOnly: role.cannot('edit', 'transcoders', 'klvDecimationMode'),
                  label: t('STREAMING.TRANSCODERS.ADD.decimation'),
                  items: decimationFactorMode,
                  onChange: decimationModeOnChange,
                  selectName: 'klvDecimationMode'
                })}

                {buildFormSelect({
                  viewOnly: role.cannot('edit', 'transcoders', 'klvDecimationFactor'),
                  label: t('STREAMING.TRANSCODERS.ADD.decimationFactor'),
                  items: decimationFactor,
                  selectName: 'klvDecimationFactor',
                  isHidden: isHidden({ param: showFactor, condition: (a: boolean) => !a })
                })}

                <FormGroup></FormGroup>
              </FormContainer>
              <Switch
                className='pb-4'
                disabled={role.cannot('edit', 'transcoders', 'enableUasKlvTagFiltering')}
                labelPlacement='left'
                name='klvUasFilterEnabled'
                label={t('STREAMING.TRANSCODERS.ADD.enableUasKlvTagFiltering')}
                onChange={uasKlvChange}
                checked={showUas}
              />
              <IsHidden className='pt-3' param={showUas} condition={(a: boolean) => !a}>
                <FormContainer columns={columns}>
                  {buildFormSelect({
                    viewOnly: role.cannot('edit', 'transcoders', 'klvUasFilterEnabledSets'),
                    label: t('STREAMING.TRANSCODERS.ADD.includedUasTags'),
                    items: klvUasDefaultSets,
                    selectedId: uasSelectedTag,
                    selectName: 'klvUasFilterEnabledSets',
                    onChange: setTagSetEvent
                  })}

                  <FormGroup></FormGroup>
                </FormContainer>
                <FormGroup data-auto='form_section_uas_tags'>
                  <TagInput
                    tags={uasTags}
                    labelText={t('tags')}
                    placeholder={t('search')}
                    clickableTags
                    className='uasTags'
                    onChange={tagChangedUas}
                    disabledInput={role.cannot('edit', 'transcoders', 'uasTags')}
                  />
                </FormGroup>
              </IsHidden>
              <Switch
                className='pt-4 '
                disabled={role.cannot('edit', 'transcoders', 'enableUasKlvSecurityTagFiltering')}
                labelPlacement='left'
                name='klvUasSecurityFilterEnabled'
                label={t('STREAMING.TRANSCODERS.ADD.enableUasKlvSecurityTagFiltering')}
                onChange={uasKlvSecurityChange}
                checked={showUasSecurity}
              />

              <IsHidden param={showUasSecurity} className='pt-3' condition={(a: boolean) => !a}>
                <FormContainer className='pt-3' columns={columns}>
                  {buildFormSelect({
                    viewOnly: role.cannot('edit', 'transcoders', 'klvUasFilterSecuritySet'),
                    label: t('STREAMING.TRANSCODERS.ADD.includedSecurityTags'),
                    items: klvUasSecurityDefaultSets,
                    selectedId: uasSecuritySelectedTag,
                    selectName: 'klvUasFilterSecuritySet',
                    onChange: setSecurityTagSetEvent
                  })}

                  <FormGroup></FormGroup>
                </FormContainer>
                <FormGroup data-auto='form_section_security_uas_tags'>
                  <TagInput
                    tags={securityUasTags}
                    labelText={t('tags')}
                    placeholder={t('search')}
                    clickableTags
                    className='uasTags'
                    onChange={tagChangedUasSecurity}
                    disabledInput={role.cannot('edit', 'transcoders', 'uasTags')}
                  />
                </FormGroup>
              </IsHidden>
            </IsHidden>
          </FormSectionDark>
        </Licensed>

        <FormSectionDark title={t('STREAMING.TRANSCODERS.ADD.advancedParameters')}>
          <Switch
            disabled={role.cannot('edit', 'transcoders', 'outputPacing') || showShaping}
            className='pb-4'
            name='outputPacing'
            checked={showOutputPacing}
            labelPlacement='left'
            label={t('STREAMING.TRANSCODERS.ADD.outputPacing')}
            onChange={outputPacingOnChange}
          />

          <IsHidden param={showOutputPacing} condition={(a: boolean) => !a}>
            <FormContainer columns={columns > 2 ? 2 : columns}>
              <FormSliderWithInput
                isModal={!submitHandler}
                showRange={{ left: '0', right: '1000' }}
                viewOnly={role.cannot('edit', 'transcoders', 'outputPacingInterval')}
                className='pt-5'
                label={t('STREAMING.TRANSCODERS.ADD.outputPacingLabel')}
                formRef={formRef}
                min='0'
                max='1000'
                defaultValue='100'
                name='outputPacingInterval'
              />
            </FormContainer>
          </IsHidden>
        </FormSectionDark>

        <FormSectionDark>
          <Switch
            checked={showShaping}
            className='pb-4'
            disabled={
              role.cannot('edit', 'transcoders', 'transportStreamShaping') || showOutputPacing
            }
            labelPlacement='left'
            label={t('STREAMING.TRANSCODERS.ADD.transportStreamShaping')}
            onChange={shapingOnChange}
          />
          <IsHidden param={showShaping} condition={(a: boolean) => !a}>
            <FormContainer className='pt-5' columns={columns}>
              <FormInput
                item={item}
                viewOnly={role.cannot('edit', 'transcoders', 'shapingBitrate')}
                label={t('STREAMING.TRANSCODERS.ADD.transportStreamBitrate')}
                name='shapingBitrate'
              />
            </FormContainer>
            <FormInfo info={t('STREAMING.TRANSCODERS.ADD.transportStreamBitrateNote')} />
            <FormContainer className='pt-5' columns={columns}>
              <FormInput
                item={item}
                viewOnly={role.cannot('edit', 'transcoders', 'shapingMaxBitratePercent')}
                label={t('STREAMING.TRANSCODERS.ADD.shapingVideoMaxBitrate')}
                name='shapingMaxBitratePercent'
                helpMessage={t('STREAMING.TRANSCODERS.ADD.shapingVideoMaxBitrateHint')}
              />
              <FormInput
                item={item}
                viewOnly={role.cannot('edit', 'transcoders', 'shapingTargetBitratePercent')}
                label={t('STREAMING.TRANSCODERS.ADD.shapingTargetBitratePercent')}
                name='shapingTargetBitratePercent'
                helpMessage={t('STREAMING.TRANSCODERS.ADD.shapingTargetBitratePercentHint')}
              />
            </FormContainer>
            <FormContainer columns={columns > 2 ? 2 : columns}>
              <FormSliderWithInput
                isModal={!submitHandler}
                viewOnly={role.cannot('edit', 'transcoders', 'shapingVbvSizeMs')}
                label={t('STREAMING.TRANSCODERS.ADD.vbvDepth')}
                min='499'
                max='3000'
                showRange={{ left: '500', right: '3000' }}
                formRef={formRef}
                defaultValue='499'
                name='shapingVbvSizeMs'
              />
              <FormSliderWithInput
                isModal={!submitHandler}
                viewOnly={role.cannot('edit', 'transcoders', 'shapingBufferingIntervalMs')}
                label={t('STREAMING.TRANSCODERS.ADD.vbvShapingDepth')}
                min='499'
                showRange={{ left: '500', right: '3000' }}
                formRef={formRef}
                max='3000'
                defaultValue='499'
                name='shapingBufferingIntervalMs'
              />
            </FormContainer>
            <FormInfo info={t('STREAMING.TRANSCODERS.ADD.shapeWarning')} />
          </IsHidden>
        </FormSectionDark>

        <FormSectionDark>
          {/* <FormExpando> */}
          <FormContainer columns={columns}>
            <FormInput
              item={item}
              viewOnly={role.cannot('edit', 'transcoders', 'interleaveAdjustmentMs')}
              label={t('STREAMING.TRANSCODERS.ADD.interleaveAdjustment')}
              name='interleaveAdjustmentMs'
              placeholder={t('STREAMING.TRANSCODERS.ADD.interleaveAdjustmentHelper')}
              showHint
            />
            <FormInput
              item={item}
              viewOnly={role.cannot('edit', 'transcoders', 'pcrWindowSize')}
              label={t('STREAMING.TRANSCODERS.ADD.pcrDelay')}
              name='pcrWindowSize'
              placeholder={t('STREAMING.TRANSCODERS.ADD.pcrDelayHelper')}
              showHint
            />
            <FormInput
              item={item}
              viewOnly={role.cannot('edit', 'transcoders', 'presenterJitterbufferSizeMs')}
              label={t('STREAMING.TRANSCODERS.ADD.jitterBuffer')}
              name='presenterJitterbufferSizeMs'
              placeholder={t('STREAMING.TRANSCODERS.ADD.jitterBufferHelper')}
              showHint
            />
          </FormContainer>
          {/* </FormExpando> */}
        </FormSectionDark>
      </Form>
    </FadeIn>
  );
};
