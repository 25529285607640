import './rebooting.scss';

import { Heading2Med, Heading4Book, Icon, colorValue } from '@hai/ui-react';
import { getSystemNetwork } from 'api/system/network';
import { topographic } from 'assets/images';
import { t } from 'i18n';
import React, { useEffect, useState } from 'react';
import { useService } from 'utils/useService';

export const Rebooting = () => {
  const getSystemNetworkService = useService(getSystemNetwork);

  const [trying, setTrying] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setTrying(false);
      const response = await getSystemNetworkService();
      if (response) {
        setTrying(true);
      }
    };
    if (trying) {
      fetchData();
    }
  }, [getSystemNetworkService, trying]);

  return (
    <div className='kraken-reboot' style={{ backgroundImage: `url(${topographic})` }}>
      <Icon iconname='StatusLoading' spinning color={colorValue('haiui-aqua-01')} size='xl' />
      <Heading2Med>{t('REBOOT.rebooting')}</Heading2Med>
      <Heading4Book>{t('REBOOT.rebootingDesc')}</Heading4Book>
    </div>
  );
};

export default Rebooting;
