import { useAuthContext } from 'context';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';

interface IUseCache {
  url: string;
  apiEndpoint: Function;
}

export const useCache = (props: IUseCache) => {
  const { url, apiEndpoint } = props;
  const cache: any = useRef({});
  const [update, doUpdate] = useState(1);

  const updateCache = useCallback(() => {
    doUpdate(Date.now());
  }, []);

  const [auth] = useAuthContext();

  const initialState = {
    status: 'idle',
    error: null,
    data: [],
    updateCache
  };

  const [state, dispatch] = useReducer((state: any, action: any) => {
    switch (action.type) {
      case 'FETCHING':
        return { ...initialState, status: 'fetching' };
      case 'FETCHED':
        return { ...initialState, status: 'fetched', data: action.payload };
      case 'FETCH_ERROR':
        return { ...initialState, status: 'error', error: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;
    if (!url || !auth.isAuth) {
      return;
    }
    const fetchData = async () => {
      dispatch({ type: 'FETCHING' });
      if (cache.current[url]) {
        const data = cache.current[url];
        dispatch({ type: 'FETCHED', payload: data });
      } else {
        try {
          const res = await apiEndpoint();
          if (res?.response?.status === 401) {
            dispatch({ type: 'FETCH_ERROR', payload: res });
            return;
          }

          cache.current[url] = res;

          if (cancelRequest || !res) {
            return;
          }
          dispatch({ type: 'FETCHED', payload: res });
        } catch (error: any) {
          if (cancelRequest) {
            return;
          }
          dispatch({ type: 'FETCH_ERROR', payload: error.message });
        }
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [apiEndpoint, auth.isAuth, url, update]);

  return state;
};
