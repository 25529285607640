import './inputs.scss';

import { List } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { IInputItem } from 'api/inputs/input.type';
import { IStreamItem } from 'api/streaming/streaming.type';
import { StreamTable } from 'components/common/streamTable/StreamTable';
import { EditInput } from 'components/streaming/inputs/EditInput';
import ListThumbnail from 'components/streaming/streams/ListThumbnail';
import { EnumInputsAction } from 'context/streaming/inputs';
import { StreamingContext } from 'context/streaming/streaming';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import constant from 'utils/constant';
import { useInputInfo } from 'utils/hooks/useInputInfo';
import { getPanelColor } from 'utils/streaming';

interface IInputItemProps {
  item: IInputItem;
  saveCallback: Function;
}

enum EnumActions {
  STATS = 'stats',
  EDIT = 'edit',
  NONE = 'none'
}

const InputItem = (props: IInputItemProps) => {
  const { saveCallback, item } = props;
  const { streamingDispatch, streamsClean } = useContext(StreamingContext);

  const [openedTab, setOpenedTab] = useState<EnumActions>(EnumActions.NONE);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [buttonState, setButtonState] = useState<ButtonStateType>('idle');

  const getInputInfo = useInputInfo();

  const [inputInfo, setInputInfo] = useState(getInputInfo(item));
  useEffect(() => {
    setInputInfo(getInputInfo(item));
  }, [getInputInfo, item]);

  const [killContentSlow, setKillContentSlow] = useState(false);

  useEffect(() => {
    setTimeout(() => setKillContentSlow(false), constant.timeout.panelTransition);
  }, [isOpen]);

  const cancel = () => {
    setIsOpen(false);
    setOpenedTab(EnumActions.NONE);
  };
  const onSelectHandler = useCallback(
    (eventKey: EnumActions, expanded: boolean) => {
      if (isOpen && expanded) {
        setIsOpen(false);
        setTimeout(() => {
          setOpenedTab(eventKey);
          setIsOpen(expanded);
        }, 100);
      } else {
        setOpenedTab(eventKey);
        setIsOpen(expanded);
      }
    },
    [isOpen]
  );
  const toggleSelection = (selected: boolean): void => {
    streamingDispatch({
      type: EnumInputsAction.SELECT_INPUT,
      payload: {
        id: item.uuid,
        selected: selected
      }
    });
  };

  const streamList = streamsClean?.filter((stream: IStreamItem) => stream.input === item.uuid);

  const getExpandContent = useCallback(() => {
    if (!item || killContentSlow) {
      // clear states in tab
      return null;
    }

    const onSettingsApply = () => {
      saveCallback();
      setIsOpen(false);
      setButtonState('idle');
      setOpenedTab(EnumActions.NONE);
    };

    if (openedTab === EnumActions.STATS) {
      return <StreamTable streams={streamList} />;
    }

    if (openedTab === EnumActions.EDIT) {
      return (
        <EditInput
          cancel={cancel}
          item={item}
          saveCallback={onSettingsApply}
          setButtonState={setButtonState}
          buttonState={buttonState}
        />
      );
    }

    return null;
  }, [item, killContentSlow, openedTab, saveCallback, streamList, buttonState]);

  const getConnection = () => {
    if (item?.streamType === 'SRT') {
      return `srt://${item.connection}`;
    } else if (item?.streamType === 'MPEG2TS') {
      if (item.connection?.substring(0, 6).toLowerCase() !== 'udp://') {
        return `udp://${item.connection}`;
      }
    } else if (item?.streamType === 'RTMP') {
      if (item.rtmpMode === 'client') {
        return `${item.connection}`;
      }
      return `${item.connection}:${item.port}`;
    } else if (item?.streamType === 'ST2110') {
      return item.deviceAddress;
    } else if (item?.streamType === 'NDI') {
      return item?.ndiSourceName;
    } else if (item?.streamType === 'GIGEVIS') {
      return item?.gigevisAddress;
    }
    return item.connection;
  };

  return (
    <List.Panel
      checked={item.selected}
      onSelect={toggleSelection}
      onClick={() => {
        onSelectHandler(isOpen ? openedTab : EnumActions.EDIT, !isOpen);
      }}
      expandedState={{
        expanded: isOpen,
        eventKey: openedTab
      }}
      panelColor={getPanelColor(inputInfo.actionType)}
      key={`input-panel-${item.uuid}`}
    >
      <ListThumbnail thumbnail={inputInfo.thumbnail} title={inputInfo.thumbnailTitle} />
      <List.PanelTitle
        title={item.name}
        statusIcon={inputInfo.icon}
        iconColor={getPanelColor(inputInfo.actionType)}
        useFsMask={true}
      />
      <List.PanelDetail>
        <List.PanelDetailItem
          useFsMask={true}
          dataAuto='panel-detail-connection'
          text={getConnection()}
        />
      </List.PanelDetail>
      <List.Actions>
        <List.ActionItem
          expandButton={true}
          eventKey={EnumActions.STATS}
          icon={'StreamsOut'}
          title={'stats'}
          disabled={!streamList?.length}
          onSelect={(eventKey: string, expanded?: boolean) =>
            onSelectHandler(eventKey as EnumActions, expanded!)
          }
        ></List.ActionItem>
        <List.ActionItem
          expandButton={true}
          eventKey={EnumActions.EDIT}
          icon={'Settings'}
          title={'settings'}
          onSelect={(eventKey: string, expanded?: boolean) =>
            onSelectHandler(eventKey as EnumActions, expanded!)
          }
        />
      </List.Actions>
      <List.ExpandedPanel>{getExpandContent()}</List.ExpandedPanel>
    </List.Panel>
  );
};

export default InputItem;
