import { getStreamSnapshotURL } from 'api/streaming/streaming';
import {
  EnumSnapshotTapPoint,
  EnumStreamStatus,
  EnumStreamSubStatus,
  IStreamItem
} from 'api/streaming/streaming.type';
import { thumbnailDefault } from 'assets/images';
import { StreamingContext } from 'context/streaming/streaming';
import { useCallback, useContext } from 'react';
import { EnumThumbnail, actionStatus, getThumbnailTitle } from 'utils/streaming';

export enum EnumStreamState {
  INACTIVE = 4,
  STREAMING = 2,
  WAITING = 5
}

export enum EnumStreamStateString {
  STREAMING = 'STATE_RUNNING',
  WAITING = 'STATE_WAITING',
  UNKNOWN = 'STATE_UNKNOWN'
}

export const useStreamInfo = () => {
  const { snapshots } = useContext(StreamingContext);

  const getStreamInfo = useCallback(
    (i: IStreamItem) => {
      return buildStreamStatusObject(i, i?.uuid, snapshots);
    },
    [snapshots]
  );

  return getStreamInfo;
};

export const buildStreamStatusObject = (item: any, uuid: string, snapshots: any[] | null) => {
  const isStopped = item?.status === EnumStreamStatus.STOPPED;

  const streamSnapshots = snapshots?.filter(snapshot => snapshot.sessionId === uuid) || [];
  const hasSnapshot = streamSnapshots.length > 0;
  const getSnapshotUrl = () => {
    if (hasSnapshot) {
      return `${getStreamSnapshotURL(
        EnumSnapshotTapPoint.INPUT,
        streamSnapshots[0].sessionId
      )}?id=${streamSnapshots[0].snapshotId}`;
    } else {
      return thumbnailDefault;
    }
  };
  if (!item) {
    return {
      thumbnail: getSnapshotUrl(),
      thumbnailTitle: getThumbnailTitle(EnumThumbnail.OFFLINE),
      icon: 'StatusInactive',
      stopped: isStopped,
      actionType: actionStatus.inactive
    };
  }
  // ACTION STATUS: STREAMING - Streaming
  if (item.status === EnumStreamStatus.ACTIVE && item.subStatus === EnumStreamSubStatus.CONNECTED) {
    return {
      thumbnail: getSnapshotUrl(),
      thumbnailTitle: getThumbnailTitle(
        item.mode === 'iorouter' ? EnumThumbnail.STREAMING : EnumThumbnail.NORMAL
      ),
      icon: 'StatusStreaming',
      actionType: actionStatus.streaming,
      stopped: isStopped
    };
  }

  if (item.status === EnumStreamStatus.WARNING) {
    if (item.subStatus === EnumStreamSubStatus.STARTING) {
      return {
        thumbnail: getSnapshotUrl(),
        thumbnailTitle: getThumbnailTitle(EnumThumbnail.OFFLINE),
        icon: 'StatusWarning',
        actionType: actionStatus.alert,
        stopped: isStopped
      };
    }

    // ACTION STATUS: ALERT - No data
    if (item.subStatus === EnumStreamSubStatus.NO_DATA) {
      return {
        thumbnail: getSnapshotUrl(),
        thumbnailTitle: getThumbnailTitle(EnumThumbnail.NO_DATA),
        icon: 'StatusInputOffline',
        actionType: actionStatus.alert,
        stopped: isStopped
      };
    }

    // ACTION STATUS: ALERT - Input offline(TBD)
    if (item.subStatus === EnumStreamSubStatus.INPUT_ERROR) {
      return {
        thumbnailTitle: getThumbnailTitle(EnumThumbnail.INPUT_ERROR),
        thumbnail: getSnapshotUrl(),
        icon: 'StatusInputOffline',
        actionType: actionStatus.alert,
        stopped: isStopped
      };
    }

    // ACTION STATUS: ALERT - Streaming with no outputs
    if (item.subStatus === EnumStreamSubStatus.OUTPUT_ERROR) {
      return {
        thumbnail: getSnapshotUrl(),
        thumbnailTitle: getThumbnailTitle(
          item.mode === 'iorouter' ? EnumThumbnail.STREAMING : EnumThumbnail.NORMAL
        ),
        icon: 'StatusStreamWarning',
        actionType: actionStatus.alert,
        stopped: isStopped
      };
    }

    if (item.subStatus === EnumStreamSubStatus.TRANSCODER_ERROR) {
      return {
        thumbnail: getSnapshotUrl(),
        thumbnailTitle: getThumbnailTitle(
          item.mode === 'iorouter' ? EnumThumbnail.STREAMING : EnumThumbnail.NORMAL
        ),
        icon: 'StatusWarning',
        actionType: actionStatus.alert,
        stopped: isStopped
      };
    }
    // ACTION STATUS: ALERT - Output(s) Disconnected, other issues // EnumStreamSubStatus.ERROR
    let thumbTitle;
    if (!hasSnapshot) {
      thumbTitle = getThumbnailTitle(EnumThumbnail.WARNING);
    } else {
      thumbTitle = getThumbnailTitle(
        item.mode === 'iorouter' ? EnumThumbnail.STREAMING : EnumThumbnail.NORMAL
      );
    }
    return {
      thumbnailTitle: thumbTitle,
      thumbnail: getSnapshotUrl(),
      icon: 'StatusWarning',
      actionType: actionStatus.alert,
      stopped: isStopped
    };
  }

  if (item.status === EnumStreamStatus.STOPPED) {
    // ACTION STATUS: INACTIVE - Incomplete
    if (item.subStatus === EnumStreamSubStatus.ERROR) {
      return {
        thumbnail: getSnapshotUrl(),
        thumbnailTitle: getThumbnailTitle(EnumThumbnail.INCOMPLETE),
        icon: 'StatusInactive',
        stopped: isStopped,
        actionType: actionStatus.inactive
      };
    }

    // ACTION STATUS: INACTIVE - Offline
    // same as fallback
    return {
      thumbnail: getSnapshotUrl(),
      thumbnailTitle: getThumbnailTitle(EnumThumbnail.OFFLINE),
      icon: 'StatusInactive',
      stopped: isStopped,
      actionType: actionStatus.inactive
    };
  }
  // fallback
  return {
    thumbnail: getSnapshotUrl(),
    thumbnailTitle: getThumbnailTitle(EnumThumbnail.OFFLINE),
    icon: 'StatusInactive',
    stopped: isStopped,
    actionType: actionStatus.inactive
  };
};
