import { useAuthContext } from 'context';
import { SecurityContext } from 'context/security/security';
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMouseClick } from 'utils/hooks/useMouseClick';
import { useMousePosition } from 'utils/hooks/useMousePosition';

export const AutoLogout = (props: { children: any }) => {
  const location = useLocation();

  const { upgrade } = useContext(SecurityContext);
  const [auth, setAuth] = useAuthContext();

  const click = useMouseClick();
  const position = useMousePosition();

  useEffect(() => {
    if (auth.timeout) {
      const interval = setTimeout(() => {
        if (upgrade?.state !== 'UPGRADING') {
          setAuth({});
        }
      }, auth.timeout);

      return () => {
        clearTimeout(interval);
      };
    }
  }, [auth.timeout, location.pathname, position, setAuth, upgrade?.state, click]);

  return <>{props.children}</>;
};
