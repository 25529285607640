import { INetwork } from 'context/settings/network';
import React, { Dispatch, createContext, useReducer } from 'react';

// Init states
export const initNetworkState: any = { network: null };

export enum EnumNetwork {
  SET_NETWORK = 'SET_NETWORK'
}

// Reducer
export const networkReducer = (state: any, action: any): any => {
  switch (action.type) {
    case EnumNetwork.SET_NETWORK:
      return {
        ...state,
        fetching: false,
        network: action.payload
      };
    default:
      return state;
  }
};

interface INetworkContext extends INetwork {
  dispatch: Dispatch<any>;
  network: any;
  fetching: boolean;
}

const NetworkContext = createContext<INetworkContext>({
  ...initNetworkState,
  dispatch: () => null
});
const { Provider } = NetworkContext;

const NetworkProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(networkReducer, initNetworkState);

  return <Provider value={{ ...state, dispatch }}>{children}</Provider>;
};

export { NetworkContext, NetworkProvider };
