import { useAuthContext } from 'context/auth';
import { EnumGlobalAction, EnumRebootState, GlobalContext } from 'context/global';
import { useCallback, useContext } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import constant from 'utils/constant';

export const useService = (service: Function) => {
  const [auth, setAuth] = useAuthContext();
  const { rebootState, dispatch } = useContext(GlobalContext);

  const [rememberMe] = useLocalStorage(constant.signin.rememberMe, false);
  const [storedName] = useLocalStorage(constant.signin.username, '');

  const callback = useCallback(
    (props?: { [key: string]: any }, onError?: Function) => {
      const onServiceError = (e: any) => {
        if (auth && e?.response?.status === 401) {
          setAuth(
            rememberMe
              ? {
                  username: storedName
                }
              : {}
          );

          if (rebootState === EnumRebootState.START) {
            dispatch({
              type: EnumGlobalAction.SET_REBOOT_STATE,
              payload: EnumRebootState.NONE
            });
          }
        }
        if (onError) {
          onError();
        }
      };

      return service({
        onError: onServiceError,
        ...props
      });
    },
    [auth, dispatch, rebootState, rememberMe, service, setAuth, storedName]
  );

  return callback;
};
