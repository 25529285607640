import { DropdownButton, DropdownMenuItem } from '@hai/ui-react';
import { EnumSortDirection } from 'components/actionBar/SortManager';
import { t } from 'i18n';
import React from 'react';

interface IProps {
  classNames?: string;
  direction: EnumSortDirection;
  title: string;
  onChangeSort: (sections: any) => void;
  sortInfo: any;
  sorting: string;
  sortByTestIds?: string[];
  sortOrderTestIds?: string[];
}

interface ISortOrder {
  title: string;
  eventKey: string;
  selected: boolean;
}

const SortDropdown: React.FunctionComponent<IProps> = ({
  direction,
  sortInfo,
  title,
  onChangeSort,
  sorting,
  sortByTestIds,
  sortOrderTestIds
}) => {
  const sortOrder: ISortOrder[] = [
    {
      title: t('SORTING.ORDER.ascending'),
      eventKey: 'ascending',
      selected: direction === EnumSortDirection.ASCENDING
    },
    {
      title: t('SORTING.ORDER.descending'),
      eventKey: 'descending',
      selected: direction === EnumSortDirection.DESCENDING
    }
  ];

  return (
    <DropdownButton
      title={sortInfo[title].title}
      className='sortByDropdown left-align'
      onChange={onChangeSort}
      label={{
        text: t('SORTING.sort'),
        icon: direction === EnumSortDirection.ASCENDING ? 'SortAscending' : 'SortDescending'
      }}
      alignLabel='left'
      floatRight
    >
      <DropdownMenuItem sectionHead title={t('SORTING.sortBy')} eventKey='sortBy' />
      {Object.keys(sortInfo).map((key, index) => {
        const item = sortInfo[key];
        return (
          <DropdownMenuItem
            key={key}
            title={item.title}
            eventKey={key}
            selected={sorting === key}
            data-testid={sortByTestIds ? sortByTestIds[index] : null}
          />
        );
      })}
      <DropdownMenuItem sectionHead title={t('SORTING.sortOrder')} eventKey='direction' />
      {sortOrder.map((order: ISortOrder, index: number) => {
        return (
          <DropdownMenuItem
            title={order.title}
            eventKey={order.eventKey}
            selected={order.selected}
            key={order.eventKey}
            data-testid={sortOrderTestIds ? sortOrderTestIds[index] : null}
          />
        );
      })}
    </DropdownButton>
  );
};

export default SortDropdown;
