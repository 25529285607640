import client from 'api/utils/client';

export interface IDecklinkResp {
  portInfoSize: number;
  portInfo: IDecklink[];
}
export interface IDecklink {
  label: string;
  modelName: string;
  portno: number;
  index: number;
  videomodes: IDecklinkVideomode[];
}
interface IDecklinkVideomode {
  width: number;
  height: number;
  framerate: number;
  interlaced: boolean;
}

export const getDecklink = () => {
  return client
    .get('/decklink')
    .then(resp => {
      const data = resp.data;
      return apiToFormdata(data);
    })
    .catch(e => {
      return e;
    });
};

const apiToFormdata = (data: IDecklinkResp) => {
  if (data.portInfoSize && data.portInfo) {
    return {
      portInfoSize: data.portInfoSize,
      portInfo: data.portInfo.map((item: IDecklink, index: number) => {
        return {
          modelName: item.modelName,
          portno: item.portno,
          index: index,
          videomodes: item.videomodes.map((i: IDecklinkVideomode, index: number) => {
            return {
              value: index,
              option: `${i.width}x${i.height}${i.interlaced ? 'i' : 'p'}${i.framerate}`
            };
          }),
          label: `${item.modelName.toUpperCase().indexOf('DECKLINK ') !== -1 ? 'SDI ' : ''}${
            item.modelName
          } ${item.portno + 1}`
        };
      })
    };
  }
  return data;
};
