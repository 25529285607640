import { IStreamItem } from 'api/streaming/streaming.type';
import { IStreamingState } from 'context/streaming/streaming';

export interface IStreamsState {
  streams: IStreamItem[] | null;
  streamsClean: IStreamItem[] | null;
  snapshots: any[] | null;
  updatingStreams: IStreamItem[];
}

export enum EnumStreamsAction {
  SET_STREAMS = 'SET_STREAMS',
  SET_STREAMS_CLEAN = 'SET_STREAMS_CLEAN',
  SELECT_STREAM = 'SELECT_STREAM',
  SELECT_ALL = 'SELECT_ALL_STREAMS',
  ADD_STREAMS_STATS = 'ADD_STREAMS_STATS',
  SET_STREAM_SNAPSHOTS = 'SET_STREAM_SNAPSHOTS',
  ADD_UPDATING_STREAMS = 'SET_UPDATING_STREAMS',
  REMOVE_UPDATING_STREAM = 'REMOVE_UPDATING_STREAM',
  RESET = 'RESET'
}

export interface IStreamsAction {
  type: EnumStreamsAction;
  payload: any;
}

// Init states
export const initStreamsState: IStreamsState = {
  streams: [],
  streamsClean: null,
  snapshots: null,
  updatingStreams: []
};

// Reducer
export const streamsReducer = (state: IStreamingState, action: IStreamsAction): IStreamsState => {
  switch (action.type) {
    case EnumStreamsAction.SET_STREAMS_CLEAN: {
      const newStreams = action.payload?.map((stream: IStreamItem) => {
        const selected = state.streamsClean?.find(i => i.uuid === stream.uuid)?.selected;
        const newItem = stream;
        newItem.selected = selected;
        return newItem;
      });

      return {
        ...state,
        streamsClean: newStreams,
        streams: newStreams
      };
    }
    case EnumStreamsAction.SET_STREAMS: {
      return {
        ...state,
        streams: action.payload
      };
    }
    case EnumStreamsAction.SET_STREAM_SNAPSHOTS: {
      return {
        ...state,
        snapshots: action.payload
      };
    }
    case EnumStreamsAction.SELECT_STREAM: {
      const { id, selected } = action.payload;
      const newStreams = state.streams?.map((i: IStreamItem) =>
        i.uuid === id
          ? {
              ...i,
              selected
            }
          : i
      );
      const newCleanStreams = state.streamsClean?.map((i: IStreamItem) =>
        i.uuid === id
          ? {
              ...i,
              selected
            }
          : i
      );
      return {
        ...state,
        streams: newStreams || [],
        streamsClean: newCleanStreams || []
      };
    }
    case EnumStreamsAction.SELECT_ALL: {
      const selected = action.payload;
      const newStreams = state.streams?.map((i: IStreamItem) => {
        const newItem = i;
        newItem.selected = selected;
        return newItem;
      });
      const newStreamsClean = state.streamsClean?.map((i: IStreamItem) => {
        const newItem = i;
        newItem.selected = selected;
        return newItem;
      });
      return {
        ...state,
        streams: newStreams || [],
        streamsClean: newStreamsClean || []
      };
    }
    case EnumStreamsAction.ADD_UPDATING_STREAMS: {
      const newStreams = [...state.updatingStreams];
      if (!state?.updatingStreams?.find(stream => stream.uuid === action.payload.uuid)) {
        newStreams?.push(action.payload);
      }
      return {
        ...state,
        updatingStreams: newStreams
      };
    }
    case EnumStreamsAction.REMOVE_UPDATING_STREAM: {
      const newStreams = state?.updatingStreams?.filter(
        (stream: IStreamItem) => stream.uuid !== action.payload.uuid
      );
      return {
        ...state,
        updatingStreams: newStreams
      };
    }
    case EnumStreamsAction.RESET: {
      return { ...state, ...initStreamsState };
    }
    default:
      return state;
  }
};
