import { PureAbility } from '@casl/ability';
import PageHeader from 'components/common/page/PageHeader';
import TabPage from 'components/common/tabPage/TabPage';
import Archive from 'components/settings/archive/Archive';
import Licensing from 'components/settings/licensing/Licensing';
import Network from 'components/settings/network/Network';
import Reporting from 'components/settings/reporting/Reporting';
import Services from 'components/settings/services/Services';
import Updates from 'components/settings/updates/Updates';
import { t } from 'i18n';
import React, { ReactElement } from 'react';

import { IRoutePaths, settingsTabPaths } from './routePaths';

const tabHeader = <PageHeader title={t('ROUTES.settings')} iconName='Settings' />;

interface ISubTab {
  component: ReactElement;
  key: string;
  title: string;
  licensedFeature?: boolean;
  path: string;
  index?: boolean;
  show: boolean;
}

interface ISubTabs {
  [key: string]: ISubTab;
}

const subTabs = (role: PureAbility): ISubTabs => {
  return {
    network: {
      path: settingsTabPaths.network,
      title: t('ROUTES.SETTINGS.network'),
      key: 'settingsNetwork',
      component: <Network />,
      show: role.can('view', 'settings/network')
    },
    archive: {
      path: settingsTabPaths.archive,
      title: t('ROUTES.SETTINGS.archive'),
      key: 'settingsArchive',
      component: <Archive />,
      show: role.can('view', 'settings/archive') && role.can('license', 'RECORDING')
    },
    reporting: {
      path: settingsTabPaths.reporting,
      title: t('ROUTES.SETTINGS.reporting'),
      key: 'settingsReporting',
      component: <Reporting />,
      show: role.can('view', 'settings/reporting')
    },
    licensing: {
      path: settingsTabPaths.licensing,
      title: t('ROUTES.SETTINGS.licensing'),
      key: 'settingsLicensing',
      component: <Licensing />,
      show: role.can('view', 'settings/licensing')
    },
    services: {
      path: settingsTabPaths.services,
      title: t('ROUTES.SETTINGS.services'),
      key: 'settingsServices',
      component: <Services />,
      show: role.can('view', 'settings/services')
    },
    updates: {
      path: settingsTabPaths.updates,
      title: t('ROUTES.SETTINGS.updates'),
      key: 'securityUpdates',
      component: <Updates />,
      licensedFeature: true,
      show: role.can('view', 'settings/updates')
    }
  };
};

export const settingTabs = (role: PureAbility, isLicenseLoading: boolean): IRoutePaths => {
  const tabs = subTabs(role);

  const tabValues = Object.values(tabs).filter((tab: ISubTab) => tab.show);

  const tabsObject: IRoutePaths = {};

  const settingsTab = (tab: ISubTab) => {
    return (
      <TabPage
        tabs={tabs}
        element={tab.component}
        selected={tab.path}
        headerComponent={tabHeader}
        isLicenseLoading={isLicenseLoading}
      />
    );
  };

  tabValues.map((tab: ISubTab) => {
    tabsObject[tab.key] = {
      path: tab.path,
      element: settingsTab(tab)
    };
  });

  return tabsObject;
};
