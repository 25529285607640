export const errors = {
  ERRORS: {
    404: {
      default: '{{status}}: Nothing found here {{url}}',
      '/outputs': {
        get: 'No output found.',
        put: 'Error creating output.'
      },
      '/streams': {
        get: 'Error fetching streams.',
        put: 'Error creating stream.'
      }
    },
    400: {
      '/streams': {
        get: 'Error fetching streams.'
      },
      '/inputs': {
        'Must be a valid RTMP address': 'URL is not a valid RTMP address.'
      }
    },
    500: {
      '/streams': {
        get: 'Error fetching streams.'
      }
    },
    SIGNIN: {
      configurationError: 'Account settings configuration error.',
      configurationErrorNotification: 'Account settings configuration error. Contact your administrator.',
      invalid: 'Sorry, the username or password is incorrect.',
      ERR_NETWORK: 'Server is unreachable.',
      locked: 'User account is temporarily locked. Try again later.',
      disabled: 'Account is disabled. Contact your administrator.',
      serviceError: 'Server Error. Try again later.'
    },
    'Failed to write, read or delete file on NFS': 'Failed to write, read or delete file on NFS.',
    'Failed to parse certificate': 'Failed to parse certificate.',
    'The file uploaded was not recognized as a private key file': 'The file uploaded was not recognized as a private key file.',
    'The private key is password protected, please provide a password': 'The private key is password protected. Please provide a password.',
    'Error creating certificate signing request': 'Error creating certificate signing request.',
    'Invalid subject string': 'Invalid subject string.',
    'Certificate with same name already exists, delete it first': 'Certificate with the same name exists. Delete it first.'
  }
};
