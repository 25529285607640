import './pageNotFound.scss';

import { Button } from '@hai/ui-react';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { streamingTabPaths } from 'components/routes/routePaths';
import { t } from 'i18next';
import React, { useEffect } from 'react';

export const PageNotFound = () => {
  useEffect(() => {
    document.title = `${window.location.hostname} - ${t(`ROUTES.pageNotFound`)}`;
  }, []);

  return (
    <FadeIn delay={200} transitionDuration={1}>
      <Squid />
      <MainContainer>
        <div className='row'>
          <NotFoundText />
        </div>
      </MainContainer>
    </FadeIn>
  );
};

interface IMainContainer {
  children: React.ReactNode;
}
const MainContainer = (props: IMainContainer) => {
  return <div className='not-found'>{props.children}</div>;
};

const Squid = () => {
  return (
    <div className='squid-container'>
      <div className='squid-bg static-image' />
      <div className='squid squid-arm-1 animateloop static-image' />
      <div className='squid squid-arm-2 animateloop static-image' />
      <div className='squid squid-arm-3 animateloop static-image' />
      <div className='squid squid-body static-image' />
      <div className='binocular animateloop static-image' />
    </div>
  );
};

const NotFoundText = () => {
  const redirectToBase = () => {
    // Redirect to base url and drop this invalid entry.
    window.location.replace(streamingTabPaths.base);
  };

  return (
    <>
      <div className='col-5'></div>
      <div className='text-section'>
        <div className='haiui-heading-02-med kraken'>{t('PAGENOTFOUND.kraken')}</div>
        <div className='haiui-heading-01-med devoured'>{t('PAGENOTFOUND.devoured')}</div>
        <div className='haiui-heading-03-med t404'>{t('PAGENOTFOUND.404')}</div>
        <div className='haiui-heading-04-book details'>{t('PAGENOTFOUND.details')}</div>
        <Button variant='primary' onClick={redirectToBase}>
          {t('PAGENOTFOUND.backToMain')}
        </Button>
      </div>
    </>
  );
};

export default PageNotFound;
