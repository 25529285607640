import { addMetadata } from 'api/metadata/metadata';
import { IMetadataItem } from 'api/metadata/metadata.type';
import { MetadataFormLayout } from 'components/streaming/metadata/MetadataFormLayout';
import { EnumNotify, NotifyContext } from 'context/notify';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

interface IProps {
  buttonState: string;
  setButtonState: Dispatch<SetStateAction<string>>;
  formControl: any;
  saveCallback: any;
}
export const AddMetadata = (props: IProps) => {
  const { saveCallback, buttonState, setButtonState, formControl } = props;
  const { dispatch: notifyDispatch } = useContext(NotifyContext);

  const [localState, setLocalState] = useState(buttonState);

  const onSave = async (values: any, { setSubmitting }: any) => {
    if (localState !== 'idle') {
      return;
    }

    setLocalState('pending');
    setButtonState('pending');

    const result = await addMetadata({ apiParams: values });
    if (result?.success) {
      saveCallback(result);
      notifyDispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: {
            name: 'STREAMING.METADATA.MESSAGES.added',
            params: { name: values.name }
          }
        }
      });
    } else {
      setLocalState('idle');
      setButtonState('idle');
    }
    setSubmitting(false);
  };
  const item = {
    uuid: '',
    name: '',
    format: 'klv',
    method: 'video_source',
    serialDevice: 'manual',
    serialStandard: 'rs232',
    serialFlowcontrol: 'none',
    serialParity: 'none',
    serialStopbits: '1',
    missionIdInsertion: '',
    securityClassification: 'unclassified',
    codingMethod: 'iso31661a8',
    classifyingCountry: '',
    objectCountryCodes: '',
    serialBaudrate: '115200',
    serialDatabits: '8',
    ipPort: '',
    relays: [],
    relayEnable: false,
    enableSecurityDataSetInsertion: false,
    maxAircraftSpiDeltaMs: 0,
    sensorDiscovery: true,
    type: '0'
  } as IMetadataItem;

  const formProps = {
    handleSubmit: onSave,
    defaultValidation: true,
    initialValues: item
  };

  return <MetadataFormLayout formProps={formProps} formControl={formControl} item={item} />;
};
