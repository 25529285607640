export const licensingEn = {
  expires: 'License Expires',
  features: 'License Features',
  instanceUuid: 'Instance UUID',
  instanceId: 'Instance ID',
  resourceId: 'Resource ID',
  kraken: 'Haivision Kraken',
  licensed: 'Licensed',
  loadBypass: 'Active Bypass Load',
  streamLoad: 'Active Stream Load',
  mac: 'MAC Address',
  product: 'Product',
  bypassAllowed: 'Bypass Allowed',
  cloudLicenseType: 'Cloud License',
  streamsAllowed: 'Streams Allowed',
  unlimited: 'Unlimited',
  update: 'License Update',
  updateComplete: 'Successfully Updated License',

  updateFailed: 'License Update Failed',
  versionLimit: 'Upgrade Version Limit',
  warning: 'The License is invalid',
  warningMac: 'The License MAC Address does not match the Server MAC Address',
  warningExpired: 'The License expired on',
  FEATURES: {
    mpeg2: 'MPEG-2 Video Encoder',
    gige: 'GigE Vision Input',
    qsvH264Encoder: 'Hardware Accelerated (QSV) H.264 Encoder',
    qsvHvecEncoder: 'Hardware Accelerated (QSV) HEVC Encoder',
    hvec: 'HEVC Video Encoder',
    ndi: 'NDI Input',
    klv: 'KLV Option',
    st2110: 'ST2110 Input',
    recording: 'Recording'
  }
};
