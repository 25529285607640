export const archivesEn = {
  add: 'Add Archive Location',
  archiveLocation: 'Archive Location',
  createArchive: 'Add an Archive Location to get started',
  noArchive: 'No Archive Locations',
  filesystemFree: 'FILESYSTEM FREE SPACE',
  filesystemUsed: 'FILESYSTEM USED SPACE',
  locationUsed: 'LOCATION USED SPACE',
  parameters: 'BASIC PARAMETERS',
  remaining: 'Remaining {{percentage}}% of {{amount}}',
  spaceUsage: 'STORAGE CAPACITY USED',
  status: 'Status',
  systemFiles: 'System Files',
  LOCATION: {
    local: 'Kraken (Local)',
    network: 'Network (NFS)'
  },
  STATE: {
    title: 'State',
    connected: 'Connected',
    connecting: 'Connecting',
    disconnected: 'Disconnected'
  },
  FORM: {
    name: 'Name',
    location: 'Location',
    autoConnect: 'Auto Connect',
    mountPoint: 'Mount Point',
    remoteHost: 'Remote Host',
    remotePath: 'Remote Path',
    test: 'TEST CONNECTION'
  },
  MESSAGES: {
    test: 'Test successful.',
    added: '"{{name}}" successfully added.',
    refresh: 'Refresh successful.',
    updated: '"{{name}}" updated successfully.',
    deleted: 'Successfully deleted {{count}} archive locations.',
    deleted_one: 'Successfully deleted "{{name}}".',
    connect_one: 'Connecting "{{name}}".',
    connect_other: 'Connecting {{count}} archive locations.',
    disconnect_one: 'Disconnecting "{{name}}".',
    disconnect_other: 'Disconnecting {{count}} archive locations.'
  },
  MODALS: {
    deleteTitle_one: 'Are you sure you want to delete this archive location?',
    deleteTitle_other: 'Are you sure you want to delete these archive locations?',
    deleteDesc_one:
      "This archive location will be deleted immediately. You can't undo this action.",
    deleteDesc_other:
      "{{count}} archive locations will be deleted immediately. You can't undo this action."
  }
};
