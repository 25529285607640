import { getMetadatas } from 'api/metadata/metadata';
import PageHeader from 'components/common/page/PageHeader';
import TabPage from 'components/common/tabPage/TabPage';
import Inputs from 'components/streaming/inputs/Inputs';
import Metadata from 'components/streaming/metadata/Metadata';
import Outputs from 'components/streaming/outputs/Outputs';
import Streams from 'components/streaming/streams/Streams';
import StreamStatsDetail from 'components/streaming/streamStatsDetail/StreamStatsDetail';
import Transcoders from 'components/streaming/transcoders/Transcoders';
import { t } from 'i18n';
import React, { useCallback } from 'react';
import { useGetInputs } from 'utils/hooks/useGetInputs';
import { useGetOutputs } from 'utils/hooks/useGetOutputs';
import { useGetStreams } from 'utils/hooks/useGetStreams';
import { useGetTranscoders } from 'utils/hooks/useGetTranscoders';
import { useService } from 'utils/useService';

import { IRoutePaths, streamingTabPaths } from './routePaths';

const streamingTabHeader = <PageHeader title={t('ROUTES.streaming')} iconName='Streams' />;

const subTabs = {
  streams: {
    path: streamingTabPaths.streams,
    title: t('ROUTES.STREAMING.streams'),
    show: true
  },
  inputs: {
    path: streamingTabPaths.inputs,
    title: t('ROUTES.STREAMING.inputs'),
    show: true
  },
  transcoders: {
    path: streamingTabPaths.transcoders,
    title: t('ROUTES.STREAMING.transcoders'),
    show: true
  },
  outputs: {
    path: streamingTabPaths.outputs,
    title: t('ROUTES.STREAMING.outputs'),
    show: true
  },
  metadata: {
    path: streamingTabPaths.metadata,
    title: t('ROUTES.STREAMING.metadata'),
    licensedFeature: true,
    show: true
  }
};

export const streamingTabs = (isLicenseLoading: boolean): IRoutePaths => {
  const getStreamsService = useGetStreams();
  const getInputsService = useGetInputs();
  const getOutputsService = useGetOutputs();
  const getTranscodersService = useGetTranscoders();
  const getMetadataService = useService(getMetadatas);

  const streamCallback = useCallback(() => {
    getInputsService({});
    getOutputsService();
    getTranscodersService({});
    getMetadataService();
    getStreamsService();
  }, [
    getInputsService,
    getMetadataService,
    getOutputsService,
    getStreamsService,
    getTranscodersService
  ]);

  return {
    streamingStreams: {
      path: streamingTabPaths.streams,
      element: (
        <TabPage
          clickCb={streamCallback}
          tabs={subTabs}
          element={<Streams />}
          selected={streamingTabPaths.streams}
          headerComponent={streamingTabHeader}
          isLicenseLoading={isLicenseLoading}
        />
      )
    },
    streamingStreamStats: {
      path: streamingTabPaths.streamStats,
      element: <StreamStatsDetail />
    },
    streamingInputs: {
      path: streamingTabPaths.inputs,
      element: (
        <TabPage
          tabs={subTabs}
          element={<Inputs />}
          clickCb={streamCallback}
          selected={streamingTabPaths.inputs}
          headerComponent={streamingTabHeader}
          isLicenseLoading={isLicenseLoading}
        />
      )
    },
    streamingTranscoders: {
      path: streamingTabPaths.transcoders,
      element: (
        <TabPage
          tabs={subTabs}
          clickCb={streamCallback}
          element={<Transcoders />}
          selected={streamingTabPaths.transcoders}
          headerComponent={streamingTabHeader}
          isLicenseLoading={isLicenseLoading}
        />
      )
    },
    streamingOutputs: {
      path: streamingTabPaths.outputs,
      element: (
        <TabPage
          tabs={subTabs}
          clickCb={streamCallback}
          element={<Outputs />}
          selected={streamingTabPaths.outputs}
          headerComponent={streamingTabHeader}
          isLicenseLoading={isLicenseLoading}
        />
      )
    },
    streamingMetadata: {
      path: streamingTabPaths.metadata,
      element: (
        <TabPage
          clickCb={streamCallback}
          tabs={subTabs}
          element={<Metadata />}
          selected={streamingTabPaths.metadata}
          headerComponent={streamingTabHeader}
          isLicenseLoading={isLicenseLoading}
        />
      )
    }
  };
};
