import { DynamicContainer, FormSection } from '@hai/ui-react';
import React from 'react';

interface formContainer {
  columns?: number;
  children?: React.ReactNode;
  className?: string;
  dataAuto?: string;
}

export const FormContainer = (props: formContainer) => {
  const { children, className, columns, dataAuto = '' } = props;

  let dataAutoContainer = dataAuto;
  if (dataAuto) {
    dataAutoContainer = 'form_container_' + dataAuto;
  }

  return (
    <DynamicContainer
      data-auto={dataAutoContainer}
      className={className}
      maxColumns={columns}
      minColumns={columns}
    >
      {children}
    </DynamicContainer>
  );
};

interface formSectionDark {
  title?: string;
  children?: React.ReactNode;
  isHidden?: boolean;
  className?: string;
  rightComponent?: React.ReactNode;
  name?: string;
}
export const FormSectionDark = (props: formSectionDark) => {
  const { isHidden, children, className, title, rightComponent, name } = props;
  if (isHidden) {
    return <></>;
  }
  return (
    <FormSection
      className={`formSectionDark ${className}`}
      title={title}
      useFsMask={true}
      data-auto={`form_section_${title ? title?.replace(/\s/g, '_').toLocaleLowerCase() : name}`}
      titleChildren={rightComponent}
    >
      {children}
    </FormSection>
  );
};
