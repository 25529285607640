import { IAccount } from 'api/accounts/account';
import { t } from 'i18n';
import { actionStatus } from 'utils/streaming';

export const getAccountStyle = (item: IAccount) => {
  const stateCode = item.state;
  let type = actionStatus.disabled;
  let icon = 'StatusInactive';
  let iconColor = 'haiui-gray-07';
  let panelColor = 'haiui-gray-07';

  if (stateCode === 'disabled') {
    icon = 'StatusInactive';
    type = actionStatus.disabled;
  } else if (stateCode === 'locked') {
    icon = 'StatusLocked';
    type = actionStatus.locked;
    panelColor = 'haiui-red-01';
    iconColor = 'haiui-red-01';
  } else if (item.expiring) {
    type = actionStatus.expiring;
    icon = 'StatusWarning';
    panelColor = 'haiui-amber-01';
    iconColor = 'haiui-amber-01';
  } else if (stateCode === 'active') {
    type = actionStatus.active;
    icon = 'StatusOK';
    panelColor = 'haiui-green-01';
    iconColor = 'haiui-green-01';
  }
  return {
    actionType: type,
    icon,
    iconColor,
    panelColor
  };
};

export const getRoleValue = (item: IAccount) => {
  switch (item.role) {
    case 'admin':
      return t('SECURITY.ACCOUNTS.ROLES.admin');
    case 'operator':
      return t('SECURITY.ACCOUNTS.ROLES.operator');
    case 'viewer':
      return t('SECURITY.ACCOUNTS.ROLES.viewer');
    default:
      return t('SECURITY.ACCOUNTS.ROLES.viewer');
  }
};
