import { IGeneralApiProps } from 'api/global.type';
import { IInputItem } from 'api/inputs/input.type';
import { convertApiEmptyToEmpty, convertAutoToBlank, decodeResponse } from 'api/utils/helper';
import { AxiosError } from 'axios';
import client, { handleErrors } from '../utils/client';

interface IAddInputProps extends IGeneralApiProps {
  apiParams: IInputItem;
}

export const addInput = (props: IAddInputProps) => {
  const { apiParams, onError } = props;

  return client
    .post('/inputs', formdataToApi(apiParams))
    .then(resp => {
      if (resp.status === 200) {
        return { success: true, ...resp.data };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

export const getInputs = (props: IGeneralApiProps) => {
  const { onError } = props;
  handleErrors(props);

  return client
    .get('/inputs')
    .then(resp => {
      const inputList: IInputItem[] = resp.data.inputList;
      return apiToFormdata(inputList);
    })
    .catch(e => {
      onError && onError(e);
      return e;
    });
};

export const updateInput = (props: IAddInputProps) => {
  const { apiParams, onError } = props;
  return client
    .put(`/inputs/${apiParams.uuid}`, formdataToApi(apiParams))
    .then(resp => {
      return { ...resp.data, success: true };
    })
    .catch((e: Error) => {
      onError && onError(e);
    });
};

interface IDeleteInput extends IGeneralApiProps {
  input: IInputItem;
}

export const deleteInput = (props: IDeleteInput) => {
  const { onError, input } = props;
  return client
    .delete(`/inputs/${input.uuid}`)
    .then(() => {
      return true;
    })
    .catch((e: AxiosError) => {
      if (e.status === 204) {
        return true; // no response from the server is expected...
      }
      onError && onError(e);
    });
};

const processStreamType = (item: any) => {
  if (item.streamType === '') {
    return inputDefaults.streamType;
  }
  if (item.streamType === 'DECKLINK') {
    return item.streamType + item.decklinkPort;
  }
  if (item.streamType === 'CAPTURE') {
    return item.streamType + item.capturePort;
  }
  return item.streamType;
};

const apiToFormdata = (apiData: any) => {
  return apiData.map((i: any) => {
    return {
      ...i,
      name: decodeResponse(i.name),
      decklinkPort: convertApiEmptyToEmpty(i.decklinkPort) || '',
      deviceIpPort: convertApiEmptyToEmpty(i.deviceIpPort),
      devicePort: convertApiEmptyToEmpty(i.devicePort) || '',
      srtLocalPort: convertApiEmptyToEmpty(i.srtLocalPort) || '',
      streamType: processStreamType(i),
      gigevisChroma:
        i.gigevisChroma === 'current' ? 'Current Camera Configuration' : i.gigevisChroma,
      gigevisMode: i.gigevisMode === 'current' ? 'Current Camera Configuration' : i.gigevisMode,
      srtRemotePort: convertApiEmptyToEmpty(i.srtRemotePort) || '',
      state: i.state === -1 ? 5 : i.state,
      port: convertApiEmptyToEmpty(i.port) || inputDefaults.port
    };
  });
};

const formdataToApi = (formData: any) => {
  let apiData = <any>{
    name: formData.name,
    description: '',
    streamType: formData.streamType,
    url: formData.url || ''
  };

  if (formData.streamType.indexOf('DECKLINK') !== -1) {
    apiData.decklinkMode = formData.decklinkMode === undefined ? -1 : formData.decklinkMode;
    apiData.decklinkPort = Number(formData.streamType.replace('DECKLINK', ''));
    apiData.streamType = 'DECKLINK';
    // reset URL, we don't use it in this mode
    apiData.url = '';
  }

  if (formData.streamType.indexOf('CAPTURE') !== -1) {
    apiData.captureMode = formData.captureMode === undefined ? -1 : formData.captureMode;
    apiData.capturePort = Number(formData.streamType.replace('CAPTURE', ''));
    apiData.streamType = 'CAPTURE';
    // reset URL, we don't use it in this mode
    apiData.url = '';
  }

  switch (formData.streamType) {
    case 'MPEG2TS':
      apiData.networkInterface = convertAutoToBlank(formData.networkInterface);
      apiData.ssm = formData.ssm;
      break;
    case 'SRT':
      apiData = { ...formData, ...apiData };
      // reset URL, we don't use it in this mode
      apiData.url = '';

      if (formData.srtAddress?.substring(0, 3).toLowerCase() === 'srt') {
        apiData.srtAddress = formData.srtAddress.toLowerCase().replace(/^srt:\/\/|srt:\/|srt:/, '');
      }

      if (formData.srtMode === '') {
        apiData.srtMode = inputDefaults.srtMode;
      } else if (formData.srtMode === 'listener') {
        apiData.srtAddress = '';
      }
      if (formData.srtPassphrase === '*********') {
        delete apiData.srtPassphrase;
      }
      apiData.networkInterface = convertAutoToBlank(formData.networkInterface);
      break;
    case 'RTSP':
      apiData.rtspTransport = formData.rtspTransport;
      break;
    case 'MJPEGRAW':
      apiData.ssm = formData.ssm;
      break;
    case 'RTMP':
      apiData.rtmpMode = formData.rtmpMode;
      apiData.ipv6 = formData.ipv6 ? '1' : '0';
      if (apiData.rtmpMode === 'server') {
        apiData.url = 'rtmp://';
        apiData.port = formData.port;
      }
      break;
    case 'ST2110':
      apiData.deviceAddress = formData.deviceAddress;
      apiData.deviceIpPort = formData.deviceIpPort;
      apiData.devicePort = formData.devicePort;
      break;
    case 'GIGEVIS':
      apiData.gigevisMode = formData.gigevisMode;
      apiData.gigevisChroma = formData.gigevisChroma;
      apiData.gigevisAddress = formData.gigevisAddress;
      break;
    case 'NDI':
      apiData.ndiSourceName = formData.ndiSourceName;
      break;
  }
  return apiData;
};

export const inputDefaults = {
  name: '',
  srtLatency: '125',
  srtMode: 'caller',
  url: '',
  streamType: 'MPEG2TS',
  networkInterface: '',
  port: '1935',
  rtspTransport: '',
  ipv6: false,
  gigevisChroma: 'current',
  gigevisMode: 'current',
  // srtRemotePort: sourcePort, // defaultValidation does not allow dynamic updates to
  rtmpMode: 'client'
};
