import { ButtonToggleGroup, Graph, Label3Med, colorValue } from '@hai/ui-react';
import { IButtonToggleOption } from '@hai/ui-react/dist/components/ButtonToggleGroup/ButtonToggleGroup';
import { ILineGraph } from '@hai/ui-react/dist/components/Graph/Interfaces/IGraphProps';
import { EnumTimescale } from 'components/streaming/streamStatsDetail/StreamStatsDetail';
import { t } from 'i18n';
import React from 'react';
import { bitsToMegabits } from 'utils/streaming';

export interface IStreamChartData {
  left: any[];
  right: any[];
}

interface IProps {
  data: IStreamChartData;
  showTimePicker?: boolean;
  onTimescaleClick?: (opt: IButtonToggleOption) => any;
  timescale?: EnumTimescale;
  isPanelChart?: boolean;
  compact?: boolean;
  graphHeight?: number;
}

export const StreamChart: React.FC<IProps> = props => {
  const {
    data,
    showTimePicker = false,
    onTimescaleClick,
    timescale,
    isPanelChart = false,
    compact,
    graphHeight
  } = props;

  const getXAxisValueByTimescale = (ts: EnumTimescale) => {
    switch (ts) {
      case EnumTimescale.fiveMins:
        return 5;
      case EnumTimescale.sixtyMins:
        return 60;
      case EnumTimescale.twentyFourHours:
        return 24;
      default:
        return 5;
    }
  };

  const leftText = [];
  data.left[0].length && leftText.push(t('STREAMING.STREAMS.inputRate'));
  data.left[1].length && leftText.push(t('STREAMING.STREAMS.streamRate'));

  const maxXAxis = timescale ? getXAxisValueByTimescale(timescale) : 5;
  const graphData: ILineGraph = {
    left: {
      data: [data.left[0].length ? data.left[0] : [], data.left[1].length ? data.left[1] : []],
      colors: !data.left[1].length
        ? [colorValue('haiui-pistachio-01')]
        : [colorValue('haiui-pistachio-01'), colorValue('haiui-aqua-01')],
      tickFormat: (i: number) => bitsToMegabits(i),
      label: `${t('STREAMING.STREAMS.bandwidth')} (${t('UNITS.megabit')})`,
      labelStyle: {
        fontSize: compact ? 14 : 10
      }
    },
    right: {
      data: data.right,
      colors: [colorValue('haiui-pink-01')],
      tickFormat: (i: number) => Math.round(i).toString(),
      label: compact ? (
        <Label3Med
          style={{
            fontSize: '14px',
            color: colorValue('haiui-gray-08'),
            marginRight: '15px'
          }}
        >
          {`${t('STREAMING.STREAMS.latency')} (${t('UNITS.millisecond')})`}
        </Label3Med>
      ) : (
        `${t('STREAMING.STREAMS.latency')} (${t('UNITS.millisecond')})`
      ),
      labelStyle: {
        fontSize: 10
      }
    },
    fields: {
      x: t('STREAMING.STREAMS.time'),
      y: {
        left: leftText,
        right: [t('STREAMING.STREAMS.latency')]
      }
    },
    tickLabelStyle: {
      fontSize: compact ? 10 : 14
    },
    xTickFormat: (i: number) => (compact ? `${i}m` : i.toString()),
    xDomain: [0, maxXAxis],
    xLabel: compact ? '' : `${t('STREAMING.STREAMS.time')} (${t('UNITS.minute')})`,
    paddings: compact
      ? {
          top: graphHeight && graphHeight < 90 ? 25 : 38,
          bottom: 18,
          left: 60,
          right: 60
        }
      : undefined
  };

  return (
    <div className={`graphs-section-content ${isPanelChart ? 'short' : ''}`}>
      {showTimePicker && (
        <div className='chart-mid-section'>
          <ButtonToggleGroup
            options={[
              {
                label: t('STREAMING.STREAMS.TIMESCALE.fiveMins'),
                value: EnumTimescale.fiveMins,
                active: timescale === EnumTimescale.fiveMins
              },
              {
                label: t('STREAMING.STREAMS.TIMESCALE.sixtyMins'),
                value: EnumTimescale.sixtyMins,
                active: timescale === EnumTimescale.sixtyMins
              },
              {
                label: t('STREAMING.STREAMS.TIMESCALE.twentyFourHours'),
                value: EnumTimescale.twentyFourHours,
                active: timescale === EnumTimescale.twentyFourHours
              }
            ]}
            onOptionClicked={(opt: IButtonToggleOption) =>
              onTimescaleClick && onTimescaleClick(opt)
            }
          ></ButtonToggleGroup>
        </div>
      )}
      <Graph
        lineGraph={graphData}
        xAxisReverse
        // @ts-ignore
        dimensions={{ h: compact ? graphHeight || 100 : 300 }}
        numTicks={{
          x: 6,
          y: 4
        }}
        hideLegend={!data.right.length || compact}
      />
    </div>
  );
};
