import { dashboardEn } from 'i18n/locales/en/dashboard/dashboard';
import { miscEn } from 'i18n/locales/en/misc/misc';
import { presetsEn } from 'i18n/locales/en/presets/presets';
import { securityEn } from 'i18n/locales/en/security/security';
import { settingsEn } from 'i18n/locales/en/settings/settings';
import { sortingEn } from 'i18n/locales/en/sorting/sorting';

import { routesEn } from './routes/routes';
import { signinEn } from './signin/signin';
import { streamingEn } from './streaming/streaming';
import { validationEN } from './validations/validations';

export const baseEn = {
  DASHBOARD: {
    ...dashboardEn
  },
  SIGNIN: {
    ...signinEn
  },
  PRESETS: {
    ...presetsEn
  },
  ROUTES: {
    ...routesEn
  },
  STREAMING: {
    ...streamingEn
  },
  SETTINGS: {
    ...settingsEn
  },
  SECURITY: {
    ...securityEn
  },
  VALIDATION: {
    ...validationEN
  },
  SORTING: {
    ...sortingEn
  },
  ...miscEn
};
