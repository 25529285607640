/**
 * Util to open a new window.
 * @param url
 * @param target
 * @param features
 */

interface IOpenWindow {
  url?: string;
  target?: string;
  features?: string;
}
export const openNewWindow = (props: IOpenWindow): Window | null => {
  const { url, target = '_system', features = '' } = props;
  return window.open(url, target, features);
};
