import { List, colorValue } from '@hai/ui-react';
import { IOutputItem } from 'api/outputs/output.type';
import {
  EnumSortDirection,
  EnumSortType,
  ISortComparator,
  SortManager
} from 'components/actionBar/SortManager';
import OutputItem from 'components/streaming/outputs/OutputItem';
import React from 'react';
import { useOutputInfo } from 'utils/hooks/useOutputInfo';

interface IProps<T extends IOutputItem> {
  list: T[];
  newItem?: string;
  hideCheckbox?: boolean;
  saveCallback: Function;
  sortInfo: {
    [K in keyof Partial<T> | string]: {
      title: string;
      type: EnumSortType;
      /*
       *  Optional
       *  Supply custom comparator function receiving model A and B as input and returning -1 | 0 | 1
       */
      comparator?: ISortComparator<T>;
      /*
       *  Optional
       *  Value provider for the field. Takes model as input and output value to sort on
       */
      provider?: (arg: T) => any;
    };
  };
  selectedFilter: string;

  sorting: string;
  direction: EnumSortDirection;
}

const OutputList = <T extends IOutputItem>(props: IProps<T>) => {
  const {
    list,
    newItem,
    hideCheckbox = false,
    saveCallback,
    selectedFilter,
    sortInfo,
    sorting,
    direction
  } = props;
  const getOutputInfo = useOutputInfo();

  return (
    <List selectable={hideCheckbox} accentColor={colorValue('haiui-aqua-01')}>
      <SortManager
        newItem={newItem}
        sortInfo={sortInfo}
        list={list}
        sortKey={sorting}
        direction={direction}
      >
        {list &&
          list
            .filter((item: IOutputItem) => {
              if (selectedFilter && selectedFilter !== 'None') {
                if (selectedFilter !== getOutputInfo(item).actionType) {
                  return false;
                }
              }
              return true;
            })
            .map((item: IOutputItem) => {
              return <OutputItem key={item.uuid} item={item} saveCallback={saveCallback} />;
            })}
      </SortManager>
    </List>
  );
};

export default OutputList;
