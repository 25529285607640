export const sortingEn = {
  sort: 'Sort',
  sortBy: 'SORT By',
  sortOrder: 'SORT ORDER',
  ORDER: {
    ascending: 'Ascending',
    descending: 'Descending'
  },
  STREAM: {
    input: 'Input Name',
    modified: 'Modified',
    name: 'Name',
    output: 'Output Name',
    status: 'Status',
    transcoder: 'Transcoder Name'
  },
  INPUT: {
    name: 'Name',
    source: 'Source',
    status: 'Status'
  },
  OUTPUT: {
    destination: 'Destination',
    name: 'Name',
    status: 'Status'
  },
  TRANSCODER: {
    audio: 'Audio',
    gop: 'GOP',
    name: 'Name',
    resolution: 'Resolution',
    status: 'Status',
    videoBitrate: 'Bitrate'
  }
};
