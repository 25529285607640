import { Form } from '@hai/ui-react';
import {
  EnumArchiveLocation,
  IArchive,
  addArchiveLocation,
  getArchiveLocations
} from 'api/settings/archiveLocations';
import { EnumFormButtonState } from 'components/common/form/formButton/formButton';
import { FormCheckbox } from 'components/common/form/formCheckbox/formCheckbox';
import { FormContainer } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { buildFormSelect } from 'components/common/form/formSelect/formSelect';
import { archiveLocationOptions } from 'components/settings/archive/EditArchive';
import { editArchiveSchema, editNfsArchiveSchema } from 'components/settings/archive/validations';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumArchivesAction } from 'context/settings/archives';
import { SettingsContext } from 'context/settings/settings';
import { t } from 'i18next';
import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useState } from 'react';
import constant from 'utils/constant';
import { IFormRef } from 'utils/hooks/useFormRef';

interface IProps {
  buttonState: EnumFormButtonState;
  setButtonState: Dispatch<SetStateAction<EnumFormButtonState>>;
  formControl: IFormRef;
  onSuccess: () => void;
}

export const AddArchive = (props: IProps) => {
  const { setButtonState, onSuccess, formControl } = props;

  const { formRef, formRefCallback } = formControl;

  const { settingsDispatch } = useContext(SettingsContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);

  const [formData, setFormData] = useState({
    location: EnumArchiveLocation.LOCAL,
    autoConnect: false
  });

  const isLocal = formData.location === EnumArchiveLocation.LOCAL;

  const onChangeHandler = (evt: any) => {
    const { name, value } = evt.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const onSave = async (values: any) => {
    setButtonState(EnumFormButtonState.PENDING);
    const archive: any = {
      type: values.location,
      name: values.name,
      autoConnect: values.autoConnect
    };
    if (!isLocal) {
      archive.nfs = {
        remoteHost: values.remoteHost,
        remotePath: values.remotePath
      };
    }
    const res = await addArchiveLocation({ item: archive });
    if (res.message) {
      getArchiveLocations().then((value: IArchive[]) => {
        value && settingsDispatch({ type: EnumArchivesAction.SET_ARCHIVES_CLEAN, payload: value });
        onSuccess();
      });
      notifyDispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: {
            name: 'SETTINGS.ARCHIVES.MESSAGES.added',
            params: { name: values.name }
          }
        }
      });
    }
    setTimeout(() => {
      setButtonState(EnumFormButtonState.IDLE);
    }, constant.timeout.minButtonLoading);
  };

  const formProps = {
    defaultValidation: true,
    handleSubmit: onSave,
    initialValues: {
      location: EnumArchiveLocation.LOCAL,
      name: '',
      autoConnect: false,
      remoteHost: '',
      remotePath: ''
    },
    restValidationProps: {
      validationSchema: isLocal ? editArchiveSchema : editNfsArchiveSchema,
      innerRef: formRefCallback
    }
  };

  const onAutoConnectChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      autoConnect: e.target.checked
    });
  };

  return (
    <Form
      {...formProps}
      onSubmit={e => {
        e.preventDefault();
      }}
      className={'add-archive'}
    >
      <FormContainer columns={1}>
        <FormInput
          dataAuto='archive_location_name'
          label={t('SETTINGS.ARCHIVES.FORM.name')}
          name='name'
          onChange={onChangeHandler}
          required
        />
        {buildFormSelect({
          dataAuto: 'archive_location_selection',
          label: t('SETTINGS.ARCHIVES.FORM.location'),
          items: archiveLocationOptions,
          selectName: 'location',
          defaultValue: EnumArchiveLocation.LOCAL,
          onChange: onChangeHandler
        })}
        <FormCheckbox
          formRef={formRef}
          label={t('SETTINGS.ARCHIVES.FORM.autoConnect')}
          name='autoConnect'
          onChange={onAutoConnectChange}
          className={'with-margin-bottom'}
        />
        {!isLocal && (
          <>
            <FormInput
              dataAuto='archive_location_remote_host'
              label={t('SETTINGS.ARCHIVES.FORM.remoteHost')}
              name='remoteHost'
              onChange={onChangeHandler}
              required={true}
            />
            <FormInput
              dataAuto='archive_location_remote_path'
              label={t('SETTINGS.ARCHIVES.FORM.remotePath')}
              name='remotePath'
              onChange={onChangeHandler}
              required={true}
            />
          </>
        )}
      </FormContainer>
    </Form>
  );
};
