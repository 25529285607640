import client from 'api/utils/client';

export const signout = () => {
  const triggerSignout = async () => {
    try {
      await client.get('/logout');
    } catch (error) {
      return { error };
    }
    return true;
  };

  return triggerSignout();
};
