import { Form } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { streamModeOptions } from 'api/global.type';
import { IStreamItem } from 'api/streaming/streaming.type';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { FormButton } from 'components/common/form/formButton/formButton';
import { FormCheckbox } from 'components/common/form/formCheckbox/formCheckbox';
import { FormContainer, FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { buildFormSelect, buildMultiSelect } from 'components/common/form/formSelect/formSelect';
import { isHidden } from 'components/isHidden/isHidden';
import { isLicensed } from 'context/settings/licenses';
import { StreamingContext } from 'context/streaming/streaming';
import { t } from 'i18next';
import { Licensed, RoleCan, RoleContext } from 'permissions/role/Roles';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { IFormRef } from 'utils/hooks/useFormRef';
import * as Yup from 'yup';

interface streamFormLayout {
  cancel?: () => void;

  formControl: IFormRef;
  formProps: any;
  item?: IStreamItem;
  columns?: number;
  buttonState?: ButtonStateType;
  submitHandler?: (event: any) => void;
  selectedOutputs: any;
  setSelectedOutputs: any;
}

export const StreamFormLayout = (props: streamFormLayout) => {
  const {
    buttonState,
    cancel,
    columns = 2,
    formControl,
    formProps,
    item,
    submitHandler,
    selectedOutputs,
    setSelectedOutputs
  } = props;

  const { formRef, formRefCallback, formSubmitActive } = formControl;
  const { inputsClean, metadatasClean, outputsClean, transcodersClean } =
    useContext(StreamingContext);
  const role = useContext(RoleContext);

  const [inputNames, setInputNames] = useState<any>([]);

  const modeOptions = streamModeOptions(!!submitHandler, item?.mode || '');
  const defaultMode = modeOptions.filter((i: any) =>
    i.licensedFeature ? isLicensed(i) : true
  )?.[0];

  const [mode, setMode] = useState<string>(item?.mode ?? defaultMode.value ?? '');

  useEffect(() => {
    setInputNames(
      inputsClean
        ?.map(input => {
          const inputType = input?.streamType;
          let newName = '';
          if (inputType === 'SRT') {
            newName = `SRT: `;
          } else if (inputType && inputType === 'MPEG2TS') {
            newName = `UDP: `;
          }
          return { ...input, nameWithType: newName + input.name };
        })
        .filter(i => {
          if (mode === 'passthru' || mode === 'iorouter') {
            if (
              i.streamType.includes('DECKLINK') ||
              ['MJPEGRAW', 'MJPEGHTTP', 'ST2110', 'NDI', 'GIGEVIS', 'CAPTURE'].indexOf(
                i.streamType
              ) !== -1
            ) {
              return false;
            }
          }
          return true;
        })
    );
  }, [inputsClean, mode]);

  const [outputNames, setOutputNames] = useState<any>(null);
  useEffect(() => {
    setOutputNames(
      outputsClean
        ?.map(output => {
          const outputType = output?.streamType;
          let newName = '';
          if (outputType === 'SRT') {
            newName = `SRT: `;
          } else if (outputType && outputType === 'MPEG2TS') {
            newName = `UDP: `;
          }
          return { ...output, nameWithType: newName + output.name };
        })
        .filter(i => {
          if (mode !== 'iorouter') {
            if (['TS_SEGMENT_ARCHIVE'].indexOf(i.streamType) !== -1) {
              return false;
            }
          }
          return true;
        })
    );
  }, [mode, outputsClean]);

  const changeModeEvent = (e: ChangeEvent<HTMLInputElement>) => {
    setMode(e.target.value);
    formRef.current?.setFieldValue('input', '');
    setSelectedOutputs([undefined]);
  };

  if (item?.metadatas && Array.isArray(item.metadatas)) {
    item.metadatas = item.metadatas[0];
  }

  const validationSchema = Yup.object({
    name: Yup.string().required(t('STREAMING.REQUIRED.nameRequired') as string)
  });

  formProps.restValidationProps = {
    validationSchema,
    innerRef: formRefCallback
  };

  return (
    <FadeIn delay={300}>
      <Form {...formProps}>
        <FormContainer columns={columns}>
          <FormInput
            autoFocus
            item={item}
            label={t('STREAMING.STREAMS.ADD.name')}
            name='name'
            required
            viewOnly={role.cannot('edit', 'streams', 'name')}
          />
          <RoleCan I='edit' a='streams'>
            {submitHandler && (
              <FormButton
                cancel={cancel}
                columns={columns}
                submitHandler={submitHandler}
                state={buttonState}
                disabled={!formSubmitActive}
              />
            )}
          </RoleCan>
        </FormContainer>
        <FormSectionDark title={t('STREAMING.STREAMS.streamingParameters')}>
          <FormContainer columns={columns}>
            {buildFormSelect({
              label: t('STREAMING.STREAMS.ADD.mode'),
              viewOnly: role.cannot('edit', 'streams', 'mode'),
              items: modeOptions,
              selectName: 'mode',
              defaultValue: item?.mode,
              selectedId: item?.mode,
              onChange: changeModeEvent
            })}

            {buildFormSelect({
              isHidden: isHidden({
                param: mode,
                condition: (mode: string) => mode === 'iorouter'
              }),
              viewOnly: role.cannot('edit', 'streams', 'transcoder'),
              label: t('STREAMING.STREAMS.ADD.transcoder'),
              items: [
                {
                  name: t('none'),
                  uuid: ''
                },
                ...(transcodersClean as [])
              ],
              selectName: 'transcoder',
              nameProp: 'name',
              selectedId: item?.transcoder,
              typeahead: true,
              formRef,
              idKey: 'uuid',
              includeNone: true
            })}
          </FormContainer>
          <FormContainer columns={columns}>
            {outputNames !== null &&
              buildFormSelect({
                label: t('STREAMING.STREAMS.ADD.input'),
                viewOnly: role.cannot('edit', 'streams', 'input'),
                items: [
                  {
                    name: t('none'),
                    uuid: '',
                    nameWithType: t('none')
                  },
                  ...inputNames
                ],
                nameProp: 'nameWithType',
                idKey: 'uuid',
                selectedId: formRef?.current?.values?.input ?? '',
                typeahead: true,
                formRef,
                selectName: 'input',
                className: 'mb-4'
              })}
            <Licensed to='METADATA'>
              {buildFormSelect({
                isHidden: isHidden({
                  className: 'mb-4',
                  param: mode,
                  condition: (mode: string) => mode === 'iorouter'
                }),
                viewOnly: role.cannot('edit', 'streams', 'metadatas'),
                label: t('STREAMING.STREAMS.ADD.metadata'),
                items: [
                  {
                    name: t('none'),
                    uuid: ''
                  },
                  ...(metadatasClean as [])
                ],
                selectName: 'metadatas',
                selectedId: item?.metadatas as any,
                nameProp: 'name',
                idKey: 'uuid',
                typeahead: true,
                formRef,
                includeNone: true
              })}
            </Licensed>
          </FormContainer>
          <FormContainer columns={columns}>
            <FormCheckbox
              formRef={formRef}
              viewOnly={role.cannot('edit', 'streams', 'autoStart')}
              item={item}
              label={t('STREAMING.STREAMS.ADD.autoStart')}
              name='autoStart'
            />
          </FormContainer>
          <FormContainer columns={columns}>
            {buildMultiSelect({
              label: t('STREAMING.STREAMS.ADD.output'),
              viewOnly: role.cannot('edit', 'streams', 'multiOutputs'),
              selectedIds: selectedOutputs,
              setSelectedIds: setSelectedOutputs,
              btnLabel: t('STREAMING.STREAMS.ADD.addOutput'),
              nameProp: 'nameWithType',
              formRef: formRef,
              idKey: 'uuid',
              selectName: 'multiOutputs',
              items: [
                {
                  name: t('none'),
                  uuid: ''
                },
                ...((outputNames as []) || [])
              ],
              includeNone: true
            })}

            {buildFormSelect({
              isHidden: isHidden({
                param: mode,
                condition: (mode: string) => mode !== 'passthru'
              }),
              viewOnly: role.cannot('edit', 'streams', 'passthruUuid'),
              label: t('STREAMING.STREAMS.ADD.passthruOutput'),
              items: outputsClean,
              selectName: 'passthruUuid',
              nameProp: 'name',
              idKey: 'uuid',
              includeNone: true
            })}
          </FormContainer>
        </FormSectionDark>
      </Form>
    </FadeIn>
  );
};
