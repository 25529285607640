import './network.scss';

import { Switch } from '@hai/ui-react';
import { FormContainer } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { buildFormSelect } from 'components/common/form/formSelect/formSelect';
import { t } from 'i18n';
import React from 'react';

interface IProps {
  activeIcIndex?: number;
  formRef: any;
  columns: number;
  isDisabled: boolean;
}

const IpV4Config = ({ formRef, activeIcIndex = 0, columns, isDisabled }: IProps) => {
  const v4AddressOptions = [
    {
      option: t('SETTINGS.NETWORK.dhcp'),
      value: 'DHCP'
    },
    {
      option: t('SETTINGS.NETWORK.static'),
      value: 'STATIC'
    },
    {
      option: t('none'),
      value: 'NONE'
    }
  ];

  const speedOptions = [
    {
      option: t('SETTINGS.NETWORK.speed10'),
      value: 10
    },
    {
      option: t('SETTINGS.NETWORK.speed100'),
      value: 100
    },
    {
      option: t('SETTINGS.NETWORK.speed1000'),
      value: 1000
    }
  ];

  const duplexOptions = [
    {
      option: t('SETTINGS.NETWORK.full'),
      value: 'FULL'
    },
    {
      option: t('SETTINGS.NETWORK.half'),
      value: 'HALF'
    }
  ];

  const linkOptions = [
    {
      option: t('SETTINGS.NETWORK.linkAuto'),
      value: 'AUTO'
    },
    {
      option: t('SETTINGS.NETWORK.linkManual'),
      value: 'MANUAL'
    }
  ];

  const formValues = formRef?.current?.values;
  const isIpV4Static = formValues?.nics[activeIcIndex]?.addressing === 'STATIC';
  const ipV4On = formValues?.nics[activeIcIndex]?.ipv4;
  const initialValues = formRef?.current?.initialValues;
  const isManualLink = formValues?.nics[activeIcIndex]?.link === 'MANUAL';

  return (
    <div>
      <Switch
        labelPlacement='left'
        dataAuto={`ipV4-${ipV4On}`}
        label={t('SETTINGS.NETWORK.ipV4')}
        checked={ipV4On}
        className='pb-4'
        disabled={isDisabled}
        onChange={(value: boolean) => {
          const newValues = {
            ...formValues.nics[activeIcIndex],
            ipv4: value,
            // reset
            addressing: initialValues.nics[activeIcIndex].addressing,
            ipAddress: initialValues.nics[activeIcIndex].ipAddress,
            gateway: initialValues.nics[activeIcIndex].gateway,
            mtu: initialValues.nics[activeIcIndex].mtu,
            subnetMask: initialValues.nics[activeIcIndex].subnetMask,
            link: initialValues.nics[activeIcIndex].link,
            duplex: initialValues.nics[activeIcIndex].duplex,
            speed: initialValues.nics[activeIcIndex].speed
          };
          formRef.current.setFieldValue(`nics.${activeIcIndex}`, newValues);
        }}
      />
      {ipV4On && (
        <div>
          <FormContainer columns={columns} dataAuto='ipv4'>
            {buildFormSelect({
              label: t('SETTINGS.NETWORK.addressing'),
              items: v4AddressOptions,
              onChange: (event: any) => {
                const newValues = {
                  ...formValues.nics[activeIcIndex],
                  addressing: event.target.value,
                  // reset
                  ipAddress: initialValues.nics[activeIcIndex].ipAddress,
                  gateway: initialValues.nics[activeIcIndex].gateway,
                  subnetMask: initialValues.nics[activeIcIndex].subnetMask,
                  mtu: initialValues.nics[activeIcIndex].mtu
                };
                formRef.current.setFieldValue(`nics.${activeIcIndex}`, newValues);
              },
              selectName: `nics.${activeIcIndex}.addressing`,
              disabled: isDisabled,
              dataAuto: `ipv4-addressing`
            })}
            <FormInput
              label={t('SETTINGS.NETWORK.ipAddress')}
              name={`nics.${activeIcIndex}.ipAddress`}
              viewOnly={!isIpV4Static}
              required
              defaultValue={formValues.nics[activeIcIndex].ipAddress}
              disabled={isDisabled}
              dataAuto='ipv4_address'
            />
            <FormInput
              label={t('SETTINGS.NETWORK.subnetMask')}
              name={`nics.${activeIcIndex}.subnetMask`}
              required
              viewOnly={!isIpV4Static}
              defaultValue={formValues.nics[activeIcIndex].subnetMask}
              disabled={isDisabled}
              dataAuto='ipv4_subnet'
            />
            <FormInput
              label={t('SETTINGS.NETWORK.gateway')}
              name={`nics.${activeIcIndex}.gateway`}
              viewOnly={!isIpV4Static}
              defaultValue={formValues.nics[activeIcIndex].gateway}
              disabled={isDisabled}
              dataAuto='ipv4_gateway'
            />
            <FormInput
              label={t('SETTINGS.NETWORK.mtu')}
              name={`nics.${activeIcIndex}.mtu`}
              disabled={isDisabled}
              dataAuto='ipv4_mtu'
            />
            <FormInput
              label={t('SETTINGS.NETWORK.macAddress')}
              name={`nics.${activeIcIndex}.macAddress`}
              viewOnly={true}
              defaultValue={formValues.nics[activeIcIndex].macAddress}
              disabled={isDisabled}
              dataAuto='ipv4_mac'
            />
          </FormContainer>
          <FormContainer columns={columns}>
            {buildFormSelect({
              label: t('SETTINGS.NETWORK.link'),
              items: linkOptions,
              onChange: (event: any) => {
                const newValues = {
                  ...formValues.nics[activeIcIndex],
                  link: event.target.value,
                  // reset
                  duplex: initialValues.nics[activeIcIndex].duplex,
                  speed: initialValues.nics[activeIcIndex].speed
                };
                formRef.current.setFieldValue(`nics.${activeIcIndex}`, newValues);
              },
              selectName: `nics.${activeIcIndex}.link`,
              disabled: isDisabled,
              dataAuto: `ipv4-link`
            })}
          </FormContainer>
          {isManualLink && (
            <FormContainer columns={columns}>
              {buildFormSelect({
                label: t('SETTINGS.NETWORK.speed'),
                items: speedOptions,
                onChange: (event: any) => {
                  formRef.current.setFieldValue(`nics.${activeIcIndex}.speed`, event.target.value);
                },
                selectName: `nics.${activeIcIndex}.speed`,
                disabled: isDisabled
              })}
              {buildFormSelect({
                label: t('SETTINGS.NETWORK.duplex'),
                items: duplexOptions,
                onChange: (event: any) => {
                  formRef.current.setFieldValue(`nics.${activeIcIndex}.duplex`, event.target.value);
                },
                selectName: `nics.${activeIcIndex}.duplex`,
                disabled: isDisabled
              })}
            </FormContainer>
          )}
        </div>
      )}
    </div>
  );
};

export default IpV4Config;
