import { ILicenseSettings, ISystemInfo } from 'api/settings/system.type';
import { t } from 'i18next';
import { DateTime } from 'luxon';

export const shouldHideInstanceUuid = (param: {
  isVmware: boolean;
  systemInstanceUuid: string;
}) => {
  return !param.isVmware || !param.systemInstanceUuid ? true : false;
};

interface IProps {
  license: ILicenseSettings | null;
  systemInfo: ISystemInfo | null;
}
export const licenseWarningMessage = (props: IProps) => {
  const { license, systemInfo } = props;
  if (license === null || license === undefined) {
    return;
  }

  if (!license?.isLicenseValid) {
    // NOTE: if the license is expired then the server returns "" for the license_mac_address
    // So always test for expiration first.
    if (
      license?.expiration &&
      systemInfo?.currenttime &&
      Number(license.expiration) < Number(systemInfo.currenttime)
    ) {
      if (license.expiration === '1') {
        // No license is present
        return t('SETTINGS.LICENSING.warning');
      } else {
        return `${t('SETTINGS.LICENSING.warningExpired')} ${DateTime.fromSeconds(
          Number(license.expiration)
        ).toLocaleString(DateTime.DATETIME_MED)}`;
      }
    } else if (!license?.isCloud && license?.licenseMacAddress !== license?.nicMacAddress) {
      return t('SETTINGS.LICENSING.warningMac');
    } else {
      return t('SETTINGS.LICENSING.warning');
    }
  }
};
