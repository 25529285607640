import { Form, Switch } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { EnumProtocolType, protocolOptions, rtspDirection, segmentFormat } from 'api/global.type';
import { IOutputItem } from 'api/outputs/output.type';
import { getArchiveLocations } from 'api/settings/archiveLocations';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { FormButton } from 'components/common/form/formButton/formButton';
import { FormContainer, FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { buildFormSelect } from 'components/common/form/formSelect/formSelect';
import { IsHidden, isHidden } from 'components/isHidden/isHidden';
import { validationSchema } from 'components/streaming/outputs/outputValidation';
import { SrtForm } from 'components/streaming/srtForm/SrtForm';
import { SettingsContext } from 'context/settings/settings';
import { t } from 'i18next';
import { RoleCan, RoleContext } from 'permissions/role/Roles';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { IFormRef } from 'utils/hooks/useFormRef';

interface FormLayout {
  applyBtn?: any;
  cancel?: () => void;

  buttonState?: ButtonStateType;
  formControl: IFormRef;
  formProps: any;
  item?: IOutputItem;
  columns?: number;
  submitHandler?: (event: any) => void;
}

export const OutputFormLayout = (props: FormLayout) => {
  const { buttonState, cancel, submitHandler, columns = 2, formControl, formProps, item } = props;
  const { formRefCallback, formSubmitActive, formRef } = formControl;
  const { networkInterface } = useContext(SettingsContext);
  const [direction, setDirection] = useState<string>(item?.rtspDirection || 'PUSH');
  const [loaded, setLoaded] = useState(false);
  const [url, setUrl] = useState(item?.url);
  const [protocol, setProtocol] = useState<EnumProtocolType>(
    item?.streamType || EnumProtocolType.MPEG2TS
  );
  const [srtMode, setSrtMode] = useState<string>(item?.srtMode || 'caller');
  const [sapEnable, setSapEnable] = useState<boolean>(!!item?.sapEnable);
  const [archiveLocations, setArchiveLocations] = useState<any>();
  const role = useContext(RoleContext);
  useEffect(() => {
    if (!loaded) {
      getArchiveLocations()
        .then((value: any) => {
          if (value) {
            setArchiveLocations(value);
            formProps.initialValues.tsSegmentArchive = value[0];
            formProps.initialValues.archiveLocationUuid = value[0]?.uuid;
          }
        })
        .finally(() => {
          setLoaded(true);
        });
    }
  }, [formProps.initialValues, loaded]);

  const sapEnableOnChange = (checked: boolean) => {
    setSapEnable(checked);
  };
  formProps.restValidationProps = {
    validationSchema,
    innerRef: formRefCallback
  };

  const urlHelpMsg = (protocol: EnumProtocolType) => {
    switch (protocol) {
      case EnumProtocolType.RTMP:
        return t('STREAMING.OUTPUTS.ADD.urlHelpRTMP');
      default:
        return t('STREAMING.OUTPUTS.ADD.urlPlaceholder');
    }
  };

  return (
    <FadeIn delay={300}>
      <Form {...formProps}>
        <FormContainer columns={columns}>
          <FormInput
            autoFocus
            item={item}
            label={t('name')}
            name='name'
            required
            viewOnly={role.cannot('edit', 'outputs', 'name')}
            dataAuto='output_name'
          />
          <RoleCan I='edit' a='outputs'>
            {submitHandler && (
              <FormButton
                cancel={cancel}
                columns={columns}
                submitHandler={submitHandler}
                state={buttonState}
                disabled={!formSubmitActive}
              />
            )}
          </RoleCan>
        </FormContainer>

        <FormSectionDark title={t('parameters')}>
          <FormContainer columns={columns}>
            {buildFormSelect({
              viewOnly: role.cannot('edit', 'outputs', 'streamType'),
              label: t('STREAMING.OUTPUTS.ADD.protocol'),
              items: protocolOptions,
              selectedId: item?.streamType,
              selectName: 'streamType',
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                setProtocol(e.target.value as EnumProtocolType),
              dataAuto: 'output_protocol'
            })}
            {/* <FormCheckbox
            formRef={formRef}
            label={t('STREAMING.INPUTS.ADD.ipv6')}
            name='ipv6'
            item={item}
            viewOnly={role.cannot('edit', 'inputs', 'ipv6')}
            isHidden={isHidden({
              param: protocol,
              condition: (protocol: EnumProtocolType) => {
                if ([EnumProtocolType.SRT].indexOf(protocol) !== -1) {
                  return false;
                }
                return true;
              }
            })}
          /> */}
            <FormInput
              label={t('url')}
              name='url'
              viewOnly={role.cannot('edit', 'outputs', 'url')}
              required
              showHint
              onChange={e => {
                setUrl(e.currentTarget.value);
              }}
              placeholder={urlHelpMsg(protocol)}
              item={item}
              isHidden={isHidden({
                param: protocol,
                className: 'grid-column kraken-span-2',
                condition: (protocol: EnumProtocolType) => {
                  if ([EnumProtocolType.RTMP, EnumProtocolType.MPEG2TS].indexOf(protocol) === -1) {
                    return true;
                  }
                  return false;
                }
              })}
              dataAuto='output_url'
            />

            {buildFormSelect({
              viewOnly: role.cannot('edit', 'outputs', 'segmentFormat'),
              label: t('STREAMING.OUTPUTS.ADD.segmentFormat'),
              items: segmentFormat,
              isHidden: isHidden({
                param: protocol,
                condition: (protocol: EnumProtocolType) => protocol !== EnumProtocolType.HLS
              }),
              selectName: 'hls.segmentFormat',
              dataAuto: 'output_segment_format'
            })}
            <FormInput
              viewOnly={role.cannot('edit', 'outputs', 'segmentsMax')}
              label={t('STREAMING.OUTPUTS.ADD.segmentsMax')}
              name='hls.playlistMaxNumSegments'
              item={item}
              required
              isHidden={isHidden({
                param: protocol,
                condition: (protocol: EnumProtocolType) => protocol !== EnumProtocolType.HLS
              })}
              helpMessage='[1 - 20]'
              dataAuto='output_segments_max'
            />
            <FormInput
              viewOnly={role.cannot('edit', 'outputs', 'segmentsMax')}
              label={t('STREAMING.OUTPUTS.ADD.segmentsDuration')}
              name='hls.segmentDurationMsec'
              required
              item={item}
              isHidden={isHidden({
                param: protocol,
                condition: (protocol: EnumProtocolType) => protocol !== EnumProtocolType.HLS
              })}
              helpMessage='[1000 - 10000]'
              dataAuto='output_segments_duration'
            />

            {/* RTSP */}
            {buildFormSelect({
              viewOnly: role.cannot('edit', 'outputs', 'rtspDirection'),
              label: t('STREAMING.OUTPUTS.ADD.rtspDirection'),
              items: rtspDirection,
              onChange: (e: ChangeEvent<HTMLInputElement>) => setDirection(e.target.value),
              isHidden: isHidden({
                param: protocol,
                condition: (protocol: EnumProtocolType) => protocol !== EnumProtocolType.RTSP
              }),
              selectName: 'rtspDirection',
              dataAuto: 'output_rtsp_direction'
            })}

            <FormInput
              label={t('STREAMING.OUTPUTS.ADD.rtspServer')}
              name='rtspServer'
              helpMessage={t('STREAMING.OUTPUTS.ADD.rtspServerHelp')}
              defaultValue={item?.rtspServer}
              required
              isHidden={isHidden({
                param: { protocol, direction },
                className: ' kraken-span-2',
                condition: (props: any) => {
                  const { direction, protocol } = props;
                  if (protocol === EnumProtocolType.RTSP && direction !== 'PULL') {
                    return false;
                  }
                  return true;
                }
              })}
              dataAuto='output_rtsp_server'
            />
            <FormInput
              label={t('STREAMING.OUTPUTS.ADD.rtspPort')}
              name='rtspPort'
              defaultValue={item?.rtspPort}
              placeholder='[1 - 65535]'
              isHidden={isHidden({
                param: { protocol, direction },
                condition: (props: any) => {
                  const { direction, protocol } = props;
                  if (protocol === EnumProtocolType.RTSP && direction !== 'PULL') {
                    return false;
                  }
                  return true;
                }
              })}
              dataAuto='output_rtsp_port'
            />

            <FormInput
              viewOnly={role.cannot('edit', 'outputs', 'rtspPath')}
              label={t('STREAMING.OUTPUTS.ADD.rtspPath')}
              name='rtspPath'
              item={item}
              required
              isHidden={isHidden({
                className: ' kraken-span-2',
                param: protocol,
                condition: (protocol: EnumProtocolType) => protocol !== EnumProtocolType.RTSP
              })}
              helpMessage='mystream?key1=value1&key2=value2'
              dataAuto='output_rtsp_path'
            />
          </FormContainer>

          <FormInput
            label={t('STREAMING.OUTPUTS.ADD.hlsUrl')}
            name='url'
            viewOnly
            showCopy={true}
            defaultValue={item?.hls?.hlsUrl}
            isHidden={isHidden({
              param: protocol,
              className: 'grid-column hai-hack-HAIUI-1325 kraken-span-3',
              condition: (protocol: EnumProtocolType) => {
                if (protocol === EnumProtocolType.HLS && item?.hls?.hlsUrl) {
                  return false;
                }
                return true;
              }
            })}
          />

          <IsHidden
            param={{ protocol, loaded }}
            condition={({ protocol, loaded }: { protocol: EnumProtocolType; loaded: boolean }) => {
              return loaded && [EnumProtocolType.TS_SEGMENT_ARCHIVE].indexOf(protocol) === -1;
            }}
          >
            <FormContainer columns={columns}>
              {loaded &&
                buildFormSelect({
                  label: t('STREAMING.OUTPUTS.ADD.archiveLocation'),
                  viewOnly: role.cannot('edit', 'outputs', 'archiveLocationUuid'),
                  items: archiveLocations || [],
                  selectName: 'archiveLocationUuid',

                  defaultValue: archiveLocations?.find(
                    (i: any) => i.uuid === item?.tsSegmentArchive?.archiveLocationUuid
                  )?.name,
                  idKey: 'uuid',
                  nameProp: 'name',
                  typeahead: true,
                  typeaheadDirection: 'up',
                  formRef,
                  required: true,
                  dataAuto: 'output_archive_location'
                })}

              <FormInput
                isHidden={isHidden({
                  param: item?.tsSegmentArchive?.archiveUuid,
                  condition: (uuid: string) => uuid === undefined
                })}
                viewOnly
                label={t('STREAMING.OUTPUTS.ADD.archive')}
                name='archiveUuid'
                item={item}
                defaultValue={item?.tsSegmentArchive?.archiveUuid}
                dataAuto='archive_uuid'
              />
              <FormInput
                required
                viewOnly={role.cannot('edit', 'outputs', 'prefix')}
                label={t('STREAMING.OUTPUTS.ADD.prefix')}
                name='prefix'
                item={item}
                dataAuto='output_prefix'
              />
            </FormContainer>
          </IsHidden>
        </FormSectionDark>

        <IsHidden
          param={protocol}
          condition={(protocol: EnumProtocolType) =>
            [EnumProtocolType.SRT].indexOf(protocol) === -1
          }
        >
          <SrtForm
            formRef={formRef}
            viewOnly={role.cannot('edit', 'outputs', 'srt')}
            columns={columns}
            srtMode={srtMode}
            setSrtMode={setSrtMode}
            encryptionSelect={true}
            item={item}
          />
        </IsHidden>

        <IsHidden
          param={protocol}
          condition={(protocol: EnumProtocolType) =>
            [EnumProtocolType.MPEG2TS, EnumProtocolType.SRT].indexOf(protocol) === -1
          }
        >
          <FormSectionDark title={t('STREAMING.OUTPUTS.ADD.linkParameters')}>
            <FormContainer columns={columns}>
              {buildFormSelect({
                isHidden: isHidden({
                  param: protocol,
                  condition: (protocol: EnumProtocolType) => {
                    if (!networkInterface) {
                      // Network interface can be empty if user does not have access to api/system/network
                      return true;
                    }
                    if (protocol === EnumProtocolType.MPEG2TS) {
                      return false;
                    }
                    if (protocol === EnumProtocolType.SRT && srtMode !== 'caller') {
                      return false;
                    }
                    return true;
                  }
                }),
                viewOnly: role.cannot('edit', 'inputs', 'networkInterface'),
                label: t('STREAMING.INPUTS.ADD.nic'),
                items: networkInterface || [],
                idKey: 'name',
                nameProp: 'name',
                selectedId: item?.networkInterface || networkInterface?.[0]?.name,
                selectName: 'networkInterface',
                dataAuto: 'output_nic'
              })}

              <FormInput
                label={t('STREAMING.OUTPUTS.ADD.mtu')}
                name='mtu'
                item={item}
                viewOnly={role.cannot('edit', 'outputs', 'mtu')}
                placeholder='[124 - 10000]'
                showHint
                dataAuto='output_mtu'
              />
              <FormInput
                item={item}
                viewOnly={role.cannot('edit', 'outputs', 'ttl')}
                label={t('STREAMING.OUTPUTS.ADD.ttl')}
                name='ttl'
                placeholder='[0 - 255]'
                showHint
                dataAuto='output_ttl'
              />
              <FormInput
                item={item}
                viewOnly={role.cannot('edit', 'outputs', 'tos')}
                label={t('STREAMING.OUTPUTS.ADD.tos')}
                name='tos'
                placeholder='[0 - 255]'
                showHint
                dataAuto='output_tos'
              />

              <FormInput
                isHidden={isHidden({
                  param: protocol,
                  condition: (protocol: EnumProtocolType) => {
                    if (protocol === EnumProtocolType.SRT) {
                      return false;
                    }
                    return true;
                  }
                })}
                item={item}
                label={t('STREAMING.OUTPUTS.ADD.bandwidthOverhead')}
                name='srtOverhead'
                viewOnly={role.cannot('edit', 'outputs', 'srtOverhead')}
                placeholder='[5 - 100]%'
                showHint
                dataAuto='output_overhead'
              />
            </FormContainer>
          </FormSectionDark>
        </IsHidden>
        <IsHidden
          param={protocol}
          condition={(protocol: EnumProtocolType) =>
            [EnumProtocolType.MPEG2TS].indexOf(protocol) === -1
          }
        >
          <FormSectionDark title={t('STREAMING.OUTPUTS.ADD.sap')}>
            <Switch
              labelPlacement='left'
              label={t('STREAMING.OUTPUTS.ADD.transmitSAP')}
              onChange={sapEnableOnChange}
              checked={sapEnable}
              className='pb-4'
              name='sapEnable'
              disabled={role.cannot('edit', 'outputs', 'transmitSAP')}
            />
            <IsHidden param={sapEnable} className='pt-3' condition={(a: boolean) => !a}>
              <FormContainer dataAuto='sap_fields' columns={columns}>
                <FormInput
                  item={item}
                  viewOnly={role.cannot('edit', 'outputs', 'sapName')}
                  label={t('name')}
                  name='sapName'
                  required
                  dataAuto='sap_name'
                />
                <FormInput
                  item={item}
                  viewOnly={role.cannot('edit', 'outputs', 'sapDescription')}
                  label={t('description')}
                  name='sapDescription'
                  dataAuto='sap_description'
                />
                <FormInput
                  item={item}
                  viewOnly={role.cannot('edit', 'outputs', 'sapKeywords')}
                  label={t('keywords')}
                  name='sapKeywords'
                  dataAuto='sap_keywords'
                />
                <FormInput
                  item={item}
                  viewOnly={role.cannot('edit', 'outputs', 'sapAuthor')}
                  label={t('author')}
                  name='sapAuthor'
                  dataAuto='sap_author'
                />
                <FormInput
                  item={item}
                  viewOnly={role.cannot('edit', 'outputs', 'sapAddress')}
                  label={t('address')}
                  helpMessage={url && url.includes('[') ? 'ff08::2:7ffe' : '224.2.127.254'}
                  name='sapAddress'
                  classNames='grid-column kraken-span-2'
                  dataAuto='sap_address'
                />
                <FormInput
                  helpMessage='9875'
                  item={item}
                  viewOnly={role.cannot('edit', 'outputs', 'sapPort')}
                  label={t('port')}
                  name='sapPort'
                  dataAuto='sap_port'
                />
              </FormContainer>
            </IsHidden>
          </FormSectionDark>
        </IsHidden>
      </Form>
    </FadeIn>
  );
};
