import {
  Dialog,
  DynamicContainer,
  Form,
  FormGroup,
  FormLabel,
  Switch,
  colorValue
} from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { updateWebserver } from 'api/system/services';
import { FormCheckbox } from 'components/common/form/formCheckbox/formCheckbox';
import { FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { FormInfo } from 'components/common/form/formInfo/formInfo';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import { IsHidden } from 'components/isHidden/isHidden';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumServicesAction } from 'context/settings/services';
import { SettingsContext } from 'context/settings/settings';
import { t } from 'i18next';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useFormRef } from 'utils/hooks/useFormRef';

export interface IWebserverConfig {
  name: string;
  checked: boolean;
  label: string;
}

interface IProps {
  show: boolean;
  handleClose: any;
  handleReset: any;
  webserverConfig: IWebserverConfig[];
}

interface IFormData {
  [key: string]: boolean;
}

export const WebserverConfig = (props: IProps) => {
  const { show, handleClose, handleReset, webserverConfig } = props;
  const [buttonState, setButtonState] = useState<ButtonStateType>('idle');

  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const { settingsDispatch } = useContext(SettingsContext);
  const formControl = useFormRef();

  const { formRefCallback, formRef } = formControl;

  const [allChecked, setAllChecked] = useState<boolean>(
    webserverConfig.find(i => i.name === 'all')?.checked || true
  );

  const initData = useMemo(() => {
    const data: IFormData = {};
    webserverConfig.map(i => {
      data[i.name] = i.checked;
    });

    const isChecked = webserverConfig.find(i => i.name === 'all')?.checked;
    if (isChecked !== undefined) {
      setAllChecked(isChecked);
    }
    return data;
  }, [webserverConfig]);

  const [formData, setFormData] = useState<IFormData>(initData);

  useEffect(() => {
    setFormData(initData);
  }, [initData]);

  const onSave = () => {
    setButtonState('pending');
    formData.all = allChecked;
    const interfaces = Object.keys(formData).filter(key => key !== 'all' && formData[key]);
    const config = { listeners: { all: formData.all, interfaces: interfaces } };
    if (!allChecked && interfaces.length === 0) {
      notifyDispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Error',
          message: t('SETTINGS.SERVICES.webserverError')
        }
      });
      setButtonState('idle');
    } else {
      updateWebserver({ config })
        .then(data => {
          if (data) {
            settingsDispatch({ type: EnumServicesAction.SET_WEBSERVER, payload: data });
            notifyDispatch({
              type: EnumNotify.ADD_NOTIFICATION,
              payload: {
                type: 'Success',
                message: t('SETTINGS.SERVICES.webserverUpdated')
              }
            });
          } else {
            setFormData(initData);
          }
        })
        .catch(_e => {
          notifyDispatch({
            type: EnumNotify.ADD_NOTIFICATION,
            payload: {
              type: 'Error',
              message: t('SETTINGS.SERVICES.webserverUpdateFailed')
            }
          });
        })
        .finally(() => {
          setButtonState('idle');
          handleClose();
        });
    }
  };

  const formProps = {
    defaultValidation: true,
    handleSubmit: onSave,
    initialValues: formData,
    restValidationProps: {
      innerRef: formRefCallback
    }
  };

  const onChange = (name: string) => {
    setFormData({
      ...formData,
      [name]: !formData[name]
    });
  };

  const options = webserverConfig
    .filter(i => i.name !== 'all')
    .map(i => {
      return {
        label: i.label,
        name: i.name,
        onChange: () => onChange(i.name),
        checked: i.checked
      };
    });

  return (
    <Dialog
      title={t('SETTINGS.SERVICES.interfaces')}
      size='md'
      dialogType='activity'
      accentColor={colorValue('haiui-aqua-01')}
      headerIcon={'Settings'}
      content={
        <div>
          <Form {...formProps} className={'generate-cert-form'}>
            <FormInfo dataAuto='warning-message' info={t('SETTINGS.SERVICES.warning')} />
            <FormSectionDark className='pb-0 mb-3' name='webserverConfig'>
              <Switch
                checked={allChecked}
                name='all'
                className='pb-4'
                labelPlacement='left'
                label={t('SETTINGS.SERVICES.interfacesAll')}
                onChange={() => {
                  setAllChecked(o => !o);
                }}
              />
              <IsHidden param={allChecked} condition={(a: boolean) => !a}>
                <FormGroup className='pb-5'>
                  <FormLabel data-auto='label-all-interfaces'>
                    ({options.map(i => i.label).join(', ')})
                  </FormLabel>
                </FormGroup>
              </IsHidden>
              <IsHidden param={!allChecked} condition={(a: boolean) => !a}>
                <DynamicContainer maxColumns={2}>
                  {options.map(i => {
                    return (
                      <FormCheckbox
                        key={i.name}
                        formRef={formRef}
                        label={i.label}
                        name={i.name}
                        onChange={i.onChange}
                        checked={formData[i.name]}
                        disabled={buttonState === 'pending'}
                        useFsMask={true}
                      />
                    );
                  })}
                </DynamicContainer>
              </IsHidden>
            </FormSectionDark>
          </Form>
        </div>
      }
      show={show}
      onClose={handleClose}
      onHide={handleReset}
      buttons={[
        {
          variant: 'secondary',
          onClick: handleReset,
          disabled: false,
          label: t('cancel')
        },
        {
          variant: 'primary',
          close: false,
          onClick: () => submitHandler(formControl.formRef),
          disabled: !formControl.formSubmitActive,
          state: buttonState,
          label: t('apply')
        }
      ]}
    />
  );
};
