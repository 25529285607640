import { FileUpload } from '@hai/ui-react';
import { Accept, IUploadState } from '@hai/ui-react/dist/components/FileUpload/IFileUpload';
import { t } from 'i18n';
import React, { useEffect, useState } from 'react';

interface IProps {
  onFileChange: (files: File[]) => void;
  accept?: Accept;
  successMessage?: string;
  uploadErrorMessage?: string;
  mainMessage?: string;
  uploadState?: IUploadState;
  browseMessage?: string;
  conjunction?: string;
  hint?: string;
  files: File[];
}

const KrakenUpload: React.FunctionComponent<IProps> = ({
  uploadErrorMessage,
  onFileChange,
  accept,
  successMessage = t('uploadSuccessful'),
  uploadState,
  hint,
  mainMessage = t('dragAndDrop'),
  browseMessage = t('browse'),
  conjunction = t('or'),
  files
}) => {
  const [state, setState] = useState(uploadState);

  useEffect(() => {
    setState(uploadState);
  }, [uploadState]);

  const dropzoneMessage: any = {
    browseMessage: browseMessage,
    mainMessage: mainMessage,
    conjunction: conjunction
  };

  return (
    <FileUpload
      accept={accept}
      dropzoneMessage={dropzoneMessage}
      errorMessage={`${t('incorrectFileType')}`}
      files={files}
      useFsMask={true}
      hint={hint}
      multiple={false}
      onFileChange={onFileChange}
      data-auto='section_drag_and_drop'
      onUploadComplete={() => {}}
      successMessage={successMessage}
      onUploadError={(e: any) => {
        setState((o: any) => {
          return { ...o, ...e };
        });
      }}
      uploadState={{
        uploadErrorMessage: uploadErrorMessage || t('problemUploading'),
        resetMessage: t('tryAgain'),
        ...state
      }}
    />
  );
};

export default KrakenUpload;
