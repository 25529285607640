import './network.scss';

import { Switch } from '@hai/ui-react';
import { FormCheckbox } from 'components/common/form/formCheckbox/formCheckbox';
import { FormContainer } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { buildFormSelect } from 'components/common/form/formSelect/formSelect';
import { t } from 'i18n';
import React from 'react';

interface IProps {
  activeIcIndex?: number;
  formRef: any;
  columns: number;
  isDisabled: boolean;
}

const IpV6Config = ({ formRef, activeIcIndex = 0, columns, isDisabled }: IProps) => {
  const v6AddressOptions = [
    {
      option: t('SETTINGS.NETWORK.automatic'),
      value: 'AUTO'
    },
    {
      option: t('SETTINGS.NETWORK.automaticDhcp'),
      value: 'DHCP'
    },
    {
      option: t('SETTINGS.NETWORK.static'),
      value: 'STATIC'
    }
  ];

  const formValues = formRef?.current?.values;
  const ipV6On = formValues?.nics?.[activeIcIndex]?.ipv6;
  const initialValues = formRef?.current?.initialValues;

  return (
    <div>
      <Switch
        labelPlacement='left'
        dataAuto={`ipV6-${ipV6On}`}
        label={t('SETTINGS.NETWORK.ipV6')}
        checked={ipV6On}
        disabled={isDisabled}
        className='pb-4'
        onChange={(value: boolean) => {
          const newValues = {
            ...formValues.nics[activeIcIndex],
            ipv6: value,
            // reset
            ipv6Addressing: initialValues.nics[activeIcIndex].ipv6Addressing,
            ipv6PrivacyExtension: initialValues.nics[activeIcIndex].ipv6PrivacyExtension,
            ipv6Address: initialValues.nics[activeIcIndex].ipv6Address,
            ipv6Gateway: initialValues.nics[activeIcIndex].ipv6Gateway
          };
          formRef.current.setFieldValue(`nics.${activeIcIndex}`, newValues);
        }}
      />
      {ipV6On && (
        <div>
          <FormContainer columns={columns} dataAuto='ipv6'>
            {buildFormSelect({
              label: t('SETTINGS.NETWORK.addressing'),
              items: v6AddressOptions,
              onChange: (event: any) => {
                const newValues = {
                  ...formValues.nics[activeIcIndex],
                  ipv6Addressing: event.target.value,
                  // reset
                  ipv6Address: initialValues.nics[activeIcIndex].ipv6Address,
                  subnetMask: initialValues.nics[activeIcIndex].subnetMask,
                  mtu: initialValues.nics[activeIcIndex].mtu
                };
                formRef.current.setFieldValue(`nics.${activeIcIndex}`, newValues);
              },
              selectName: `nics[${activeIcIndex}].ipv6Addressing`,
              disabled: isDisabled,
              dataAuto: `ipv6-addressing`
            })}
            <FormInput
              label={t('SETTINGS.NETWORK.ipv6linklocal')}
              name={`nics.${activeIcIndex}.ipv6Address.linklocal`}
              viewOnly
              defaultValue={formValues.nics[activeIcIndex].ipv6Address.linklocal}
              disabled={isDisabled}
              dataAuto='link_local'
            />
            {formValues.nics[activeIcIndex].ipv6Addressing.mngtmpaddr && (
              <FormInput
                label={t('SETTINGS.NETWORK.ipv6management')}
                name={`nics.${activeIcIndex}.ipv6Address.mngtmpaddr`}
                viewOnly
                defaultValue={formValues.nics[activeIcIndex].ipv6Address.mngtmpaddr}
                disabled={isDisabled}
                dataAuto='ipv6_mngtmpaddr'
              />
            )}
            {formValues.nics[activeIcIndex].ipv6Addressing.temporary && (
              <FormInput
                label={t('SETTINGS.NETWORK.ipv6temporary')}
                name={`nics.${activeIcIndex}.ipv6Address.temporary`}
                viewOnly
                defaultValue={formValues.nics[activeIcIndex].ipv6Address.temporary}
                disabled={isDisabled}
                dataAuto='ipv6_temporary'
              />
            )}
          </FormContainer>
          <FormContainer columns={columns}>
            {formValues.nics[activeIcIndex].ipv6Addressing === 'STATIC' && (
              <>
                <FormInput
                  label={t('SETTINGS.NETWORK.ipV6Address')}
                  name={`nics.${activeIcIndex}.ipv6AddressStatic`}
                  disabled={isDisabled}
                  required
                  dataAuto='ipv6_address'
                />
                <FormInput
                  label={t('SETTINGS.NETWORK.subnetPrefixLength')}
                  name={`nics.${activeIcIndex}.ipv6PrefixLength`}
                  disabled={isDisabled}
                  required
                  dataAuto='ipv6_subnet'
                />
                <FormInput
                  label={t('SETTINGS.NETWORK.gateway')}
                  name={`nics.${activeIcIndex}.ipv6Gateway`}
                  disabled={isDisabled}
                  dataAuto='ipv6_gateway'
                />
              </>
            )}
            {(formValues.nics[activeIcIndex].ipv6Addressing === 'DHCP' ||
              formValues.nics[activeIcIndex].ipv6Addressing === 'AUTO') && (
              <>
                <FormInput
                  label={t('SETTINGS.NETWORK.ipV6Address')}
                  name={`nics.${activeIcIndex}.ipv6Address.dhcp`}
                  viewOnly
                  defaultValue={formValues.nics[activeIcIndex].ipv6Address.dhcp}
                  disabled={isDisabled}
                  dataAuto='ipv6_dhcp'
                />
                <FormInput
                  label={t('SETTINGS.NETWORK.gateway')}
                  name={`nics.${activeIcIndex}.ipv6Gateway`}
                  viewOnly
                  defaultValue={formValues.nics[activeIcIndex].ipv6Gateway}
                  disabled={isDisabled}
                  dataAuto='ipv6_gateway'
                />
              </>
            )}
          </FormContainer>
          <FormContainer columns={columns}>
            <FormCheckbox
              formRef={formRef}
              label={t('SETTINGS.NETWORK.duplicateAddressDetection')}
              name={`nics[${activeIcIndex}].duplicateAddressDetection`}
              checked={formValues.nics[activeIcIndex].duplicateAddressDetection ? true : false}
              disabled={isDisabled}
            />
            {formValues.nics[activeIcIndex].ipv6Addressing === 'AUTO' && (
              <FormCheckbox
                formRef={formRef}
                label={t('SETTINGS.NETWORK.privacyExtension')}
                name={`nics[${activeIcIndex}].ipv6PrivacyExtension`}
                checked={formValues.nics[activeIcIndex].ipv6PrivacyExtension === 2 ? true : false}
                disabled={isDisabled}
                onChange={e => {
                  formRef.current.setFieldValue(
                    `nics.${activeIcIndex}.ipv6PrivacyExtension`,
                    e.target.value === 'true' ? 2 : 0
                  );
                }}
              />
            )}
          </FormContainer>
        </div>
      )}
    </div>
  );
};

export default IpV6Config;
