import './streams.scss';

import { Button, Dialog, colorValue } from '@hai/ui-react';
import { deleteStreams, updateStreamToStart, updateStreamToStop } from 'api/streaming/streaming';
import { EnumStreamStatus, IStreamItem } from 'api/streaming/streaming.type';
import ActionBar, { Status } from 'components/actionBar/ActionBar';
import SortDropdown from 'components/actionBar/SortDropdown';
import { EnumSortDirection, EnumSortType } from 'components/actionBar/SortManager';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import NoContent from 'components/noItems/noContent';
import StreamingPage from 'components/streaming/StreamingPage';
import { AddStream } from 'components/streaming/streams/AddStream';
import StreamList from 'components/streaming/streams/StreamList';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { StreamingContext } from 'context/streaming/streaming';
import { EnumStreamsAction } from 'context/streaming/streams';
import { t } from 'i18next';
import { RoleContext } from 'permissions/role/Roles';
import React, { useCallback, useContext, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import constant from 'utils/constant';
import { useFormRef } from 'utils/hooks/useFormRef';
import { useGetPresets } from 'utils/hooks/useGetPresets';
import { useGetStreams } from 'utils/hooks/useGetStreams';
import { useStreamInfo } from 'utils/hooks/useStreamInfo';
import { actionStatus, convertActionTypeForSort, getActionStatusColor } from 'utils/streaming';
import { useService } from 'utils/useService';

const Streams = () => {
  const { streams, streamsClean, streamingDispatch } = useContext(StreamingContext);
  const [show, setShow] = useState(false);
  const [buttonState, setButtonState] = useState('idle');

  const formControl = useFormRef();

  const [direction, setDirection] = useState(EnumSortDirection.ASCENDING);
  const [sorting, setSorting] = useState('name');
  const [isSearchOn, setIsSearchOn] = useState(false);
  const [newItem, setNewItems] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { dispatch } = useContext(GlobalContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const role = useContext(RoleContext);

  const getStreamInfo = useStreamInfo();
  const startStream = useService(updateStreamToStart);
  const stopStream = useService(updateStreamToStop);

  const getPresetsService = useGetPresets();
  const getStreamsService = useGetStreams();

  useInterval(() => {
    if (!isSearchOn) {
      getStreamsService();
    }
  }, constant.stream.update);

  const statusTypes: Status[] = [
    {
      status: actionStatus.streaming,
      color: getActionStatusColor(actionStatus.streaming)
    },
    {
      status: actionStatus.alert,
      color: getActionStatusColor(actionStatus.alert)
    },
    {
      status: actionStatus.inactive,
      color: getActionStatusColor(actionStatus.inactive)
    }
  ];

  const memoizedSetItems = useCallback(
    (value: any) => {
      streamingDispatch({ type: EnumStreamsAction.SET_STREAMS, payload: value });
    },
    [streamingDispatch]
  );

  const actionDelete = () => {
    const triggerDelete = async (stream: IStreamItem) => {
      const result = await deleteStreams({ stream });
      getPresetsService();

      return { result: result.success, item: stream };
    };
    (async () => {
      if (streams) {
        await Promise.all(streams.filter(i => i.selected).map(triggerDelete)).then(values => {
          const successItems = values.filter(x => x.result);
          if (successItems) {
            notifyDispatch({
              type: EnumNotify.ADD_NOTIFICATION,
              payload: {
                type: 'Success',
                // groupId: 'deleteStream',
                message: {
                  name: 'STREAMING.STREAMS.MESSAGES.deleted',
                  params: { name: values[0].item.name },
                  items: values
                }
              }
            });
          }
        });
        getStreamsService();
      }
    })();
  };
  const saveCallback = (item: any) => {
    getStreamsService(() => {
      setNewItems(item);
      setButtonState('idle');

      handleClose();
      getPresetsService();
    });
  };

  const onDeleteClick = () => {
    const count = streams?.filter(i => i.selected).length;
    dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        onConfirm: actionDelete,
        title: t('STREAMING.STREAMS.DELETE_MODAL.title', { count }),
        desc: t('STREAMING.STREAMS.DELETE_MODAL.desc', { count })
      }
    });
  };

  const onSelectAll = (selected: boolean | 'indeterminate' | 'none') => {
    return streamingDispatch({ type: EnumStreamsAction.SELECT_ALL, payload: selected === false });
  };

  const onStartAll = () => {
    const triggerStart = async (item: IStreamItem) => {
      return await startStream({ item, ignoreError: [500] });
    };
    (async () => {
      if (streams) {
        const results = await Promise.all(
          streams.filter(i => i.selected && i.state !== 2).map(triggerStart)
        );
        const updatedStreamCount = results.filter(i => i.result).length;
        if (updatedStreamCount) {
          notifyDispatch({
            type: EnumNotify.ADD_NOTIFICATION,
            payload: {
              type: 'Success',
              // groupId: 'startedStream',
              message: {
                name: `STREAMING.STREAMS.MESSAGES.startedStream${
                  updatedStreamCount > 1 ? 's' : ''
                }`,
                params: {
                  name: results.filter(i => i.result)[0].item.name,
                  itemsStopped: updatedStreamCount
                }
              }
            }
          });
        }
        results.forEach(res => {
          const result = res.result;
          if (result) {
            streamingDispatch({
              type: EnumStreamsAction.ADD_UPDATING_STREAMS,
              payload: res.item
            });
          } else if (res.status === 500) {
            notifyDispatch({
              type: EnumNotify.ADD_NOTIFICATION,
              payload: {
                type: 'Error',
                groupId: res.data.message,
                itemId: res.data.message, // no true id, just use the message to prevent dupes
                message: {
                  name: `STREAMING.STREAMS.MESSAGES.${res.data.message}`
                }
              }
            });
          }
        });
      }
    })();
  };

  const onStopAll = () => {
    const triggerStop = async (item: IStreamItem) => {
      return await stopStream({ item });
    };
    (async () => {
      if (streams) {
        const result = await Promise.all(
          streams.filter(i => i.selected && i.status !== EnumStreamStatus.STOPPED).map(triggerStop)
        );
        const updatedStreamCount = result.filter(i => i.result).length;
        if (updatedStreamCount) {
          notifyDispatch({
            type: EnumNotify.ADD_NOTIFICATION,
            payload: {
              type: 'Success',
              // groupId: 'stoppedStream',
              message: {
                name: `STREAMING.STREAMS.MESSAGES.stoppedStream${
                  updatedStreamCount > 1 ? 's' : ''
                }`,
                params: {
                  name: result.filter(i => i.result)[0].item.name,
                  itemsStopped: updatedStreamCount
                }
              }
            }
          });
        }

        result.forEach(res => {
          const result = res.result;
          if (result) {
            streamingDispatch({
              type: EnumStreamsAction.ADD_UPDATING_STREAMS,
              payload: res.item
            });
          }
        });
      }
    })();
  };

  const sortInfo = {
    name: { title: t('SORTING.STREAM.name'), type: EnumSortType.NATURAL_SORT },
    status: {
      title: t('SORTING.STREAM.status'),
      type: EnumSortType.CUSTOM_FUNCTION,
      comparator: (a: any, b: any, dirVal: any) => {
        const aa = getStreamInfo(a);
        const bb = getStreamInfo(b);

        // convert actionType string to number for sorting
        let aNum = convertActionTypeForSort(aa.actionType);
        let bNum = convertActionTypeForSort(bb.actionType);

        let result = 0; // equal
        if (aNum > bNum) {
          result = 1;
        } else if (aNum < bNum) {
          result = -1;
        }
        if (dirVal < 0) {
          if (result === -1) {
            return 1;
          } else if (result === 1) {
            return -1;
          }
        }
        return result as 0 | 1 | -1;
      }
    },
    inputName: { title: t('SORTING.STREAM.input'), type: EnumSortType.NATURAL_SORT },
    mode: { title: t('SORTING.STREAM.transcoder'), type: EnumSortType.NATURAL_SORT },
    outputNames: { title: t('SORTING.STREAM.output'), type: EnumSortType.NATURAL_SORT }
  };

  const onChangeSort = (sections: any) => {
    if (sections.sortBy?.eventKey) {
      setSorting(sections.sortBy?.eventKey);
    }

    if (sections.direction?.eventKey === 'ascending') {
      setDirection(EnumSortDirection.ASCENDING);
    } else if (sections.direction?.eventKey === 'descending') {
      setDirection(EnumSortDirection.DESCENDING);
    } else {
      setDirection(EnumSortDirection.IDLE);
    }
  };

  const onSearch = (searchItems: any) => {
    setIsSearchOn(!!searchItems.length);
  };

  const isNoSearchResult = isSearchOn && !streams?.length;

  const buildActionButtons = (streams: any) => {
    const actionButtons = [];
    if (role.can('delete', 'streams')) {
      actionButtons.push(
        <Button key='streams-delete' onClick={onDeleteClick}>
          {t('delete')}
        </Button>
      );
    }
    if (
      role.can('start', 'streams') &&
      streams &&
      streams.filter((i: any) => i.selected && i.state === 4).length !== 0
    ) {
      actionButtons.push(
        <Button key='streams-start' onClick={onStartAll}>
          {t('start')}
        </Button>
      );
    }
    if (
      role.can('stop', 'streams') &&
      streams &&
      streams.filter((i: any) => i.selected && [1, 2, 3, 5].indexOf(i.state) !== -1).length !== 0
    ) {
      actionButtons.push(
        <Button key='streams-stop' onClick={onStopAll}>
          {t('stop')}
        </Button>
      );
    }
    return actionButtons.length ? { ActionButtons: actionButtons } : {};
  };

  const [actionFilter, setActionFilter] = useState('None');
  return (
    <StreamingPage>
      {streamsClean?.length !== 0 && (
        <ActionBar
          cleanItems={streamsClean}
          getStatus={getStreamInfo}
          items={streams}
          onSearch={onSearch}
          onSelectAll={onSelectAll}
          searchKeys={['name', 'mode', 'inputName', 'outputNames']}
          setActionFilter={setActionFilter}
          setItems={memoizedSetItems}
          statusTypes={statusTypes}
          sortDropdown={
            <SortDropdown
              direction={direction}
              title={sorting}
              onChangeSort={onChangeSort}
              sortInfo={sortInfo}
              sorting={sorting}
            />
          }
          {...buildActionButtons(streams)}
          {...(role.can('add', 'streams')
            ? {
                actionRightPrimary: {
                  onClick: handleShow,
                  children: t('STREAMING.STREAMS.addStream')
                }
              }
            : {})}
        />
      )}
      {streams && (
        <StreamList
          direction={direction}
          hideCheckbox={!!buildActionButtons(streams)?.ActionButtons}
          sorting={sorting}
          sortInfo={sortInfo}
          selectedFilter={actionFilter}
          list={streams}
          newItem={newItem}
          saveCallback={saveCallback}
        />
      )}

      {streamsClean?.length === 0 && (
        <NoContent
          buttonText={t('STREAMING.STREAMS.addStream')}
          buttonAction={handleShow}
          detailsText={t('STREAMING.STREAMS.createStream')}
          iconName='Streams'
          noItemMsg={t('STREAMING.STREAMS.noStreams')}
        />
      )}
      {isNoSearchResult && <NoContent iconName='Search' noItemMsg={t('noSearchResult')} />}
      <Dialog
        title={t('STREAMING.STREAMS.addStream')}
        size='lg'
        dialogType='activity'
        accentColor={colorValue('haiui-aqua-01')}
        headerIcon='StreamsOut'
        content={
          <AddStream
            saveCallback={saveCallback}
            formControl={formControl}
            buttonState={buttonState}
            setButtonState={setButtonState}
          />
        }
        show={show}
        onClose={handleClose}
        buttons={[
          { variant: 'secondary', onClick: handleClose, label: t('cancel') },
          {
            variant: 'primary',
            close: false,
            onClick: () => submitHandler(formControl.formRef, notifyDispatch),
            disabled: !formControl.formSubmitActive,
            state: buttonState,
            label: t('STREAMING.STREAMS.addStream')
          }
        ]}
      />
    </StreamingPage>
  );
};

export default Streams;
