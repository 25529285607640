import { StreamingContext } from 'context/streaming/streaming';
import { useCallback, useContext } from 'react';

export const useGetOutputSummariesById = () => {
  const { outputsClean } = useContext(StreamingContext);

  const getAllOutputSummaries = useCallback(
    (ids: string[]) => {
      const getOutputSummary = (id: string) => {
        const output = outputsClean?.find((o: any) => o.uuid === id);
        if (output) {
          return {
            id: output.uuid,
            name: output.name,
            streamType: output.streamType
          };
        } else {
          return {
            name: ''
          };
        }
      };

      return ids.map((id: string) => getOutputSummary(id));
    },
    [outputsClean]
  );

  return getAllOutputSummaries;
};
