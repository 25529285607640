interface ConfigType {
  ApiUrl: string;
  ApiPath: string;
}

const config: ConfigType = {
  ApiUrl: process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_URL || '' : '',
  ApiPath: process.env.REACT_APP_API_PATH || ''
};

export default config;
