import { IInputItem } from 'api/inputs/input.type';
import { StreamingContext } from 'context/streaming/streaming';
import { useCallback, useContext } from 'react';

export const useGetInputNameById = () => {
  const { inputsClean } = useContext(StreamingContext);

  const getInputName = useCallback(
    (id: string) => {
      return inputsClean?.find((i: IInputItem) => i.uuid === id)?.name || '';
    },
    [inputsClean]
  );

  return getInputName;
};
