import './output.scss';

import { List } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { IOutputItem } from 'api/outputs/output.type';
import { IStreamItem } from 'api/streaming/streaming.type';
import { StreamTable } from 'components/common/streamTable/StreamTable';
import { EditOutput } from 'components/streaming/outputs/EditOutput';
import ListThumbnail from 'components/streaming/streams/ListThumbnail';
import { EnumOutputsAction } from 'context/streaming/outputs';
import { StreamingContext } from 'context/streaming/streaming';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import constant from 'utils/constant';
import { useOutputInfo } from 'utils/hooks/useOutputInfo';
import { getPanelColor } from 'utils/streaming';

import { OutputPlayerLink } from './OutputPlayerLink';

interface IOutputItemProps {
  item: IOutputItem;
  saveCallback: Function;
}

enum EnumActions {
  WARNINGS = 'warnings',
  REPORT = 'report',
  EDIT = 'edit',
  STATUS = 'STATUS',

  NONE = 'none'
}

const OutputItem = (props: IOutputItemProps) => {
  const { item, saveCallback } = props;

  const [openedTab, setOpenedTab] = useState<EnumActions>(EnumActions.NONE);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [buttonState, setButtonState] = useState<ButtonStateType>('idle');

  const { streamingDispatch, streamsClean } = useContext(StreamingContext);
  const getOutputInfo = useOutputInfo();

  const [outputInfo, setOutputInfo] = useState(getOutputInfo(item));

  useEffect(() => {
    setOutputInfo(getOutputInfo(item));
  }, [getOutputInfo, item]);

  const [killContentSlow, setKillContentSlow] = useState(false);

  useEffect(() => {
    setTimeout(() => setKillContentSlow(false), constant.timeout.panelTransition);
  }, [isOpen]);

  const cancel = () => {
    setOpenedTab(EnumActions.NONE);
    setIsOpen(false);
  };

  const onSelectHandler = useCallback(
    (eventKey: EnumActions, expanded: boolean) => {
      if (isOpen && expanded) {
        setIsOpen(false);
        setTimeout(() => {
          setOpenedTab(eventKey);
          setIsOpen(expanded);
        }, 100);
      } else {
        setOpenedTab(eventKey);
        setIsOpen(expanded);
      }
    },
    [isOpen]
  );

  const toggleSelection = (selected: boolean): void => {
    streamingDispatch({
      type: EnumOutputsAction.SELECT_OUTPUT,
      payload: {
        id: item.uuid,
        selected: selected
      }
    });
  };

  const streamList = streamsClean?.filter((stream: IStreamItem) =>
    stream.outputs.find((o: string) => o === item.uuid)
  );

  const getExpandContent = useCallback(() => {
    if (!item || killContentSlow) {
      // clear states in tab
      return null;
    }

    const onSettingsApply = () => {
      setButtonState('idle');
      saveCallback();
      setOpenedTab(EnumActions.NONE);
      setIsOpen(false);
    };
    if (openedTab === EnumActions.EDIT) {
      return (
        <EditOutput
          cancel={cancel}
          item={item}
          saveCallback={onSettingsApply}
          setButtonState={setButtonState}
          buttonState={buttonState}
        />
      );
    }

    if (openedTab === EnumActions.REPORT) {
      return <StreamTable streams={streamList} />;
    }

    return null;
  }, [buttonState, item, killContentSlow, openedTab, saveCallback, streamList]);

  const getConnection = () => {
    if (item?.streamType === 'SRT') {
      return `srt://${item.connection}`;
    } else if (item?.streamType === 'MPEG2TS') {
      if (['udp://'].indexOf(item.connection.substring(0, 6).toLowerCase()) === -1) {
        return `udp://${item.connection}`;
      }
    } else if (item?.streamType === 'HLS') {
      return <OutputPlayerLink outputId={item.uuid} text={item.hls?.hlsUrl || item.name} />;
    } else if (item?.streamType === 'TS_SEGMENT_ARCHIVE') {
      return item?.tsSegmentArchive?.prefix;
    }
    return item.connection;
  };
  return (
    <List.Panel
      checked={item.selected}
      onSelect={toggleSelection}
      onClick={() => {
        onSelectHandler(isOpen ? openedTab : EnumActions.EDIT, !isOpen);
      }}
      panelColor={getPanelColor(outputInfo.actionType)}
      expandedState={{
        expanded: isOpen,
        eventKey: openedTab
      }}
      key={`output-panel-${item.uuid}`}
    >
      <ListThumbnail thumbnail={outputInfo.thumbnail} title={outputInfo.thumbnailTitle} />
      <List.PanelTitle
        title={item.name}
        statusIcon={outputInfo.icon}
        iconColor={getPanelColor(outputInfo.actionType)}
        useFsMask={true}
      />
      <List.PanelDetail className='force-overflow'>
        <List.PanelDetailItem
          useFsMask={true}
          dataAuto='panel-detail-connection'
          text={getConnection()}
        />
      </List.PanelDetail>
      <List.Actions>
        <List.ActionItem
          expandButton={true}
          disabled={!streamList?.length}
          eventKey={EnumActions.REPORT}
          icon='StreamsOut'
          title='StreamsOut'
          onSelect={(eventKey: string, expanded?: boolean) =>
            onSelectHandler(eventKey as EnumActions, expanded!)
          }
        />
        <List.ActionItem
          eventKey={EnumActions.EDIT}
          icon='Settings'
          title='Settings'
          expandButton={true}
          onSelect={(eventKey: string, expanded?: boolean) =>
            onSelectHandler(eventKey as EnumActions, expanded!)
          }
        />
      </List.Actions>
      <List.ExpandedPanel>{getExpandContent()}</List.ExpandedPanel>
    </List.Panel>
  );
};

export default OutputItem;
