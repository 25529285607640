import './signin.scss';

import { Login as HaiSignin, Icon, Link, colorValue } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { ProductName, ProductNameCaption } from '@hai/ui-react/dist/hook';
import { signin } from 'api/auth/signin';
import { getInputs } from 'api/inputs/input';
import { getLicense } from 'api/license/license';
import { getSession, getSessionSettings, setSessionSettings } from 'api/session/session';
import { getSystemInfo } from 'api/settings/system';
import { ISystemInfo } from 'api/settings/system.type';
import { topographic } from 'assets/images';
import { Logo } from 'components/common/logo/Logo';
import { routePaths } from 'components/routes/routePaths';
import { licenseWarningMessage } from 'components/settings/licensing/licensing_helpers';
import { useAuthContext } from 'context';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { SettingsContext } from 'context/settings/settings';
import { RoleContext } from 'permissions/role/Roles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import constant from 'utils/constant';
import { useCache } from 'utils/hooks/useCache';
import { useGetInputs } from 'utils/hooks/useGetInputs';
import { useGetOutputs } from 'utils/hooks/useGetOutputs';
import * as yup from 'yup';

const Signin = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { dispatch } = useContext(GlobalContext);
  const role = useContext(RoleContext);

  const getInputsService = useGetInputs();
  const getOutputsService = useGetOutputs();
  const { license } = useContext(SettingsContext);

  const [buttonState, setButtonState] = useState<ButtonStateType>('idle');
  const [errorShown, setErrorShown] = useState<{ shown: boolean; message: string }>({
    shown: false,
    message: ''
  });

  const [auth, setAuth] = useAuthContext();
  const { dispatch: dispatchNotify } = useContext(NotifyContext);
  const { updateCache, status } = useCache({
    apiEndpoint: getLicense,
    url: '/license'
  });

  const showAnalyticsPrompt = useCallback(() => {
    dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        cancelText: t('decline'),
        confirmText: t('continue'),
        isDanger: false,
        ghostCancel: true,
        onConfirm: () => {
          setSessionSettings({ apiParams: { shareUserAnalytics: true } });
        },
        onCancel: () => {
          setSessionSettings({ apiParams: { shareUserAnalytics: false } });
        },
        onClose: () => {},
        close: true,
        centered: false,
        title: (
          <div className='text-left fullstory-title'>
            <Icon iconname='Information' size='lg' color='haiui-gray-07' className='pr-3' />
            {t('FULLSTORY.title')}
          </div>
        ),
        icon: <></>,
        desc: (
          <div className='text-left fullstory-desc'>
            <div className='pb-4 left'>{t('FULLSTORY.desc')}</div>
            <Trans
              t={t}
              key='privacy-link-desc'
              i18nKey={'FULLSTORY.moreInfo'}
              values={{ link: t('SETTINGS.SERVICES.privacyPolicy') }}
              components={[
                <Link
                  href='https://www.haivision.com/legal/technology-privacy-policy/'
                  target='_blank'
                  showGoTo={false}
                  className='privacy-policy-link'
                  key='privacy-link'
                />
              ]}
            />
          </div>
        )
      }
    });
  }, [dispatch, t]);

  const [rememberMe, setRememberMe] = useLocalStorage(constant.signin.rememberMe, false);
  const [storedName, setStoredName] = useLocalStorage(constant.signin.username, '');

  useEffect(() => {
    if (auth.isAuth && status === 'fetched' && role.can('role', 'isSet')) {
      (async () => {
        getSystemInfo().then(async (systemInfo: ISystemInfo) => {
          // determine a license error message
          const licenseErrorMsg = licenseWarningMessage({ systemInfo, license });
          if (licenseErrorMsg) {
            dispatchNotify({
              type: EnumNotify.ADD_NOTIFICATION,
              payload: {
                type: 'error',
                message: licenseErrorMsg
              }
            });
          }

          if (
            (auth.shareUserAnalytics === null || auth.shareUserAnalytics === undefined) &&
            role.can('edit', 'fullstory')
          ) {
            showAnalyticsPrompt();
          }

          const inputs = await getInputs({});
          if (inputs.length === 0) {
            // If we dont have any inputs send the user to the inputs page.
            navigate(routePaths.streaming.inputs);
          } else {
            navigate(routePaths.dashboard);
          }
        });
      })();
    }
  }, [navigate, auth, role, showAnalyticsPrompt, status, license, dispatchNotify, t]);

  useEffect(() => {
    document.title = `${ProductNameCaption[ProductName.KRAKEN]} - ${t('ROUTES.signin')}`;
  }, [t]);

  const rememberUsername = (username: string) => {
    setStoredName(username);
  };

  const triggerSignin = async (username: string, password: string) => {
    setButtonState('pending');
    rememberUsername(username);
    setErrorShown({ shown: false, message: '' });
    const result: any = await signin({ username, password, ignoreError: [401] });

    if ([401, 500, 502, 504].indexOf(result?.response?.status) !== -1) {
      let msg = t(`ERRORS.SIGNIN.invalid`);
      const errResp = result?.response?.data?.message;
      if ([500, 502, 504].indexOf(result?.response?.status) !== -1) {
        msg = t(`ERRORS.SIGNIN.serviceError`);
      } else if (errResp.includes('is disabled')) {
        msg = t(`ERRORS.SIGNIN.disabled`);
      } else if (errResp.includes('temporarily locked')) {
        msg = t(`ERRORS.SIGNIN.locked`);
      } else if (errResp.includes('Account Settings configuration error')) {
        msg = t(`ERRORS.SIGNIN.configurationError`);
        dispatchNotify({
          type: EnumNotify.ADD_NOTIFICATION,
          payload: {
            type: 'error',
            message: t(`ERRORS.SIGNIN.configurationErrorNotification`)
          }
        });
      }

      setButtonState('idle');
      setErrorShown({
        shown: true,
        message: msg
      });
    } else if (result?.response?.status === 'ERR_NETWORK') {
      dispatchNotify({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'error',
          message: t(`ERRORS.SIGNIN.ERR_NETWORK`)
        }
      });
    } else {
      const session = await getSession({ ignoreError: [401] });
      if (session?.id) {
        setButtonState('success');
        const sessionSettings = await getSessionSettings({
          ignoreError: [404]
        }).catch(() => {
          return false;
        });
        setAuth({
          isAuth: true,
          group: session?.group,
          mustChangePassword: !!session.mustChangePassword,
          userId: session?.id,
          expiration: session?.expiration,
          timeout: session?.idleTimeout * 1000 * 60,
          username: username,
          shareUserAnalytics: sessionSettings.shareUserAnalytics
        });

        getOutputsService();
        getInputsService({});
        if (session.mustChangePassword) {
          navigate('/password');
        } else {
          updateCache();
        }
      } else {
        setButtonState('idle');
        if ([401].indexOf(result?.response?.status) !== -1) {
          setErrorShown({
            shown: true,
            message: t(`ERRORS.SIGNIN.locked`)
          });
          dispatchNotify({
            type: EnumNotify.ADD_NOTIFICATION,
            payload: {
              type: 'error',
              message: t(`ERRORS.SIGNIN.locked`)
            }
          });
        } else if ([500, 502, 504].indexOf(result?.response?.status) !== -1) {
          setErrorShown({
            shown: true,
            message: t(`ERRORS.SIGNIN.serviceError`)
          });
          dispatchNotify({
            type: EnumNotify.ADD_NOTIFICATION,
            payload: {
              type: 'error',
              message: t(`ERRORS.SIGNIN.serviceError`)
            }
          });
        }
      }
    }
  };

  const toggleRememberMe = (checked: boolean) => {
    setRememberMe(checked);
  };
  return (
    <>
      <HaiSignin
        accentColor={colorValue('haiui-aqua-01')}
        appLogo={Logo}
        // https://haivision.jira.com/browse/KRAK-5241
        // Edge autocomplete changed the text to the same color as the background
        className={window.navigator.userAgent.indexOf('Edg') > -1 ? 'edge' : ''}
        backgroundImage={topographic}
        errorMessages={{ password: errorShown.message, username: errorShown.shown ? ' ' : '' }}
        productName='Kraken'
        usernamePlaceholder={t('SIGNIN.username')}
        passwordPlaceholder={t('SIGNIN.password')}
        handleRememberMe={toggleRememberMe}
        handleLogin={triggerSignin}
        signInButton={{ state: buttonState }}
        rememberMe={rememberMe}
        rememberMeLabel={t('SIGNIN.rememberMe')}
        username={storedName}
        validationSchema={yup.object({
          username: yup.string().required(t<string>('SIGNIN.username_required')),
          password: yup.string().required(t<string>('SIGNIN.password_required'))
        })}
      />
    </>
  );
};

export default Signin;
