import { getDebugLogging } from 'api/settings/system';
import { IDebugLogging } from 'api/settings/system.type';
import { SettingsContext } from 'context/settings/settings';
import { EnumStatusAction } from 'context/settings/status';
import React, { ReactNode, useContext, useEffect } from 'react';

interface IPageProps {
  children: ReactNode;
}

const SettingsPage: React.FunctionComponent<IPageProps> = ({ children }) => {
  const { settingsDispatch } = useContext(SettingsContext);

  useEffect(() => {
    getDebugLogging().then((value: IDebugLogging) => {
      settingsDispatch({ type: EnumStatusAction.SET_DEBUG_LOGGING, payload: value.enabled });
    });
  }, [settingsDispatch]);

  return <>{children}</>;
};

export default SettingsPage;
