export type ISslProtocols = ('TLSv1.2' | 'TLSv1.3')[];

export interface IPolicy {
  httpsPort: number;
  sslProtocols: ISslProtocols;
  sslCiphers: string[];
  httpPort: number;
  rpFilter: '1' | '2';
}

export interface IPoliciesState {
  policies?: IPolicy;
}

export enum EnumPoliciesAction {
  SET = 'SET_POLICIES',
  RESET = 'RESET'
}

export interface IPoliciesAction {
  type: EnumPoliciesAction;
  payload: any;
}

// Init states
export const initPoliciesState: IPoliciesState = {};

// Reducer
export const policiesReducer = (state: IPoliciesState, action: IPoliciesAction): IPoliciesState => {
  switch (action.type) {
    case EnumPoliciesAction.SET: {
      return {
        ...state,
        policies: action.payload
      };
    }
    case EnumPoliciesAction.RESET: {
      return initPoliciesState;
    }
    default:
      return state;
  }
};
