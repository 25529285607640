export const accountsEn = {
  ROLES: {
    viewer: 'Viewer',
    admin: 'Admin',
    operator: 'Operator'
  },
  STATE: {
    active: 'ACTIVE',
    disabled: 'DISABLED',
    locked: 'LOCKED'
  },
  FORMS: {
    addAccount: 'Add Account',
    username: 'Username',
    newPassword: 'New Password',
    currentPwd: 'Current Password',
    confirmPwd: 'Confirm Password',
    retypePwd: 'Re-type Password',
    settings: 'ACCOUNT SETTINGS',
    role: 'Role'
  },
  MODALS: {
    deleteTitle_one: 'Are you sure you want to delete this account?',
    deleteTitle_other: 'Are you sure you want to delete these accounts?',
    deleteDesc_one: "This account will be deleted immediately. You can't undo this action.",
    deleteDesc_other: "{{count}} accounts will be deleted immediately. You can't undo this action."
  },
  MESSAGES: {
    updated: 'Account "{{name}}" updated successfully.',
    deleted: 'Account "{{name}}" deleted successfully.',
    disabled: 'Account disabled successfully.',
    disabledAccount: '"{{name}}" disabled successfully.',
    enabled: 'Account enabled successfully.',
    enabledAccount: '"{{name}}" enabled successfully.',
    accountAdded: 'Account created successfully.',
    'Must be at minimum 6 characters': 'Must be at minimum 6 characters.',
    'Given current password is invalid': 'Given current password is invalid.',
    passwordExpirationMsg: 'Your password expires in one day.',
    passwordExpirationMsg_other: 'Password expires in {{count}} days.',
    passwordExpirationMsg_zero: 'Password expires in less than a day.'
  }
};
