import { Form } from '@hai/ui-react';
import { EnumAccountRole, IAccount, addAccount, getAccounts } from 'api/accounts/account';
import { EnumFormButtonState } from 'components/common/form/formButton/formButton';
import { FormContainer } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { buildFormSelect } from 'components/common/form/formSelect/formSelect';
import { addAccountSchema } from 'components/security/accounts/validations';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumAccountsAction } from 'context/security/accounts';
import { SecurityContext } from 'context/security/security';
import { t } from 'i18next';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { IFormRef } from 'utils/hooks/useFormRef';

interface IProps {
  buttonState: EnumFormButtonState;
  setButtonState: Dispatch<SetStateAction<EnumFormButtonState>>;
  formControl: IFormRef;
  onSuccess: () => void;
}

export const accountSettingOptions = [
  {
    option: t('SECURITY.ACCOUNTS.ROLES.admin'),
    value: EnumAccountRole.ADMIN
  },
  {
    option: t('SECURITY.ACCOUNTS.ROLES.operator'),
    value: EnumAccountRole.OPERATOR
  },
  {
    option: t('SECURITY.ACCOUNTS.ROLES.viewer'),
    value: EnumAccountRole.VIEWER
  }
];

export const AddAccount = (props: IProps) => {
  const { setButtonState, formControl, onSuccess } = props;

  const { formRefCallback } = formControl;

  const { securityDispatch } = useContext(SecurityContext);
  const { dispatch } = useContext(NotifyContext);

  const [formData, setFormData] = useState({
    password: '',
    confirmPwd: '',
    username: '',
    role: EnumAccountRole.ADMIN
  });

  const onChangeHandler = (evt: any) => {
    const { name, value } = evt.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const onSave = async (values: any) => {
    setButtonState(EnumFormButtonState.PENDING);
    const account = {
      username: values.username,
      password: values.password,
      role: values.role
    };
    const res = await addAccount({ item: account });
    if (res) {
      getAccounts().then((value: IAccount[]) => {
        value && securityDispatch({ type: EnumAccountsAction.SET_ACCOUNTS_CLEAN, payload: value });
        setButtonState(EnumFormButtonState.IDLE);
        if (res.data) {
          onSuccess();
          dispatch({
            type: EnumNotify.ADD_NOTIFICATION,
            payload: {
              type: 'Success',
              message: t('SECURITY.ACCOUNTS.MESSAGES.accountAdded')
            }
          });
        }
      });
    }
  };

  const formProps = {
    defaultValidation: true,
    handleSubmit: onSave,
    initialValues: {
      role: EnumAccountRole.ADMIN,
      password: '',
      confirmPwd: ''
    },
    restValidationProps: {
      validationSchema: addAccountSchema,
      innerRef: formRefCallback
    }
  };

  return (
    <Form
      {...formProps}
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <FormContainer columns={1}>
        <FormInput
          label={t('SECURITY.ACCOUNTS.FORMS.username')}
          name='username'
          defaultValue={''}
          required
          dataAuto='account_username'
        />
        {buildFormSelect({
          label: t('SECURITY.ACCOUNTS.FORMS.role'),
          items: accountSettingOptions,
          selectName: 'role',
          selectedId: EnumAccountRole.ADMIN,
          defaultValue: EnumAccountRole.ADMIN,
          onChange: onChangeHandler,
          dataAuto: 'account_role'
        })}
        <FormInput
          label={t('SECURITY.ACCOUNTS.FORMS.newPassword')}
          name='password'
          defaultValue={''}
          pwdToggle={true}
          inputType={'password'}
          onChange={onChangeHandler}
          required
          dataAuto='account_new_pass'
        />
        <FormInput
          label={t('SECURITY.ACCOUNTS.FORMS.confirmPwd')}
          name='confirmPwd'
          defaultValue={''}
          pwdToggle={true}
          inputType={'password'}
          placeholder={t('SECURITY.ACCOUNTS.FORMS.retypePwd')}
          onChange={onChangeHandler}
          required
          dataAuto='account_confirm_pass'
        />
      </FormContainer>
    </Form>
  );
};
