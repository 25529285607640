import './network.scss';

import { Button, DataTable } from '@hai/ui-react';
import { HaiDataTableColumnType } from '@hai/ui-react/dist/components/DataTable/IHaiDataTable';
import { ISystemNetworkNic } from 'api/settings/system.type';
import { FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { StaticRouteConfig } from 'components/settings/network/StaticRouteConfig';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import { IStaticRoute } from 'context/settings/network';
import { t } from 'i18n';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

interface IProps {
  staticRoutes?: IStaticRoute[];
  interfaces: ISystemNetworkNic[];
  setStaticRoutes: Dispatch<SetStateAction<IStaticRoute[] | undefined>>;
  isDisabled: boolean;
}

export interface IStaticRouteWithSelected extends IStaticRoute {
  selected?: boolean;
}

const StaticRoutes = ({ staticRoutes, interfaces, setStaticRoutes, isDisabled }: IProps) => {
  const [routes, setRoutes] = useState<IStaticRouteWithSelected[]>();
  const [show, setShow] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const { dispatch } = useContext(GlobalContext);

  useEffect(() => {
    const newRoutes = staticRoutes?.map((i: IStaticRoute) => {
      return {
        selected: false,
        ...i
      };
    });
    setRoutes(newRoutes);
  }, [staticRoutes]);

  useEffect(() => {
    setShow(selectedIndex !== -1);
  }, [selectedIndex]);

  const deleteItem = (index: number) => {
    if (staticRoutes) {
      const newRoutes = [...staticRoutes];
      newRoutes.splice(index, 1);
      setStaticRoutes(newRoutes);
    }
    return true;
  };

  const onCheckAll = (v: boolean | 'indeterminate') => {
    const newRoutes = routes?.map(i => {
      return {
        ...i,
        selected: v === true
      };
    });
    setRoutes(newRoutes);
  };

  const selected = routes?.filter(i => i.selected) || [];

  const actionDelete = () => {
    let remainingRoutes = routes?.filter(route => {
      return route.selected !== true;
    });
    // We track two separate route states.
    // routes/setRoutes is local to this component and manages selection.
    // staticRoutes/setStaticRoutes is the state from the parent component and ties into the form.
    // When deleting selected routes, we need to process the result and pass it back to the main state.
    setRoutes(remainingRoutes);
    setStaticRoutes(
      remainingRoutes?.map(route => {
        let cleanRoute = { ...route };
        delete cleanRoute.selected;
        return cleanRoute;
      })
    );
    return true;
  };

  const onDeleteClick = () => {
    dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        onConfirm: () => actionDelete(),
        title: t('SETTINGS.NETWORK.deleteRoutesTitle', { count: selected.length })
      }
    });
  };

  return (
    <FormSectionDark
      title={t('SETTINGS.NETWORK.staticRoutes')}
      rightComponent={
        <div className='side-button'>
          <Button onClick={() => setShow(true)} variant='secondary' disabled={isDisabled}>
            {t('SETTINGS.NETWORK.addRoute')}
          </Button>
        </div>
      }
      className={`static-routes-table ${!routes?.length && 'empty'}`}
    >
      {show && (
        <StaticRouteConfig
          setStaticRoutes={setStaticRoutes}
          staticRoutes={staticRoutes}
          interfaces={interfaces}
          show={show}
          handleClose={() => {
            if (selectedIndex >= 0) {
              setSelectedIndex(-1);
            }
            setShow(false);
          }}
          selectedIndex={selectedIndex}
        />
      )}
      {routes?.length && (
        <DataTable
          className='content'
          sortable={true}
          selectable={true}
          columnStructure={[
            { fieldKey: 'destination', title: t('SETTINGS.NETWORK.destination') },
            { fieldKey: 'subnetMask', title: t('SETTINGS.NETWORK.maskPrefix') },
            { fieldKey: 'gateway', title: t('SETTINGS.NETWORK.gateway') },
            { fieldKey: 'interface', title: t('SETTINGS.NETWORK.interface') },
            { fieldKey: '', title: '', type: HaiDataTableColumnType.ACTIONS }
          ]}
          onCheckAll={onCheckAll}
          dataAuto='static_routes'
          useFsMask={true}
        >
          <DataTable.Header
            bulkActions={() => (
              <Button size='small' onClick={onDeleteClick}>
                {t('delete')}
              </Button>
            )}
            data-auto='routes_header'
          />
          {routes.map((value, index: number) => {
            return (
              <DataTable.Row
                key={`${value.destination}-${index}`}
                rowData={{
                  index: index,
                  id: `${value.destination}-${index}`,
                  destination: value.destination,
                  subnetMask: value.subnetMask || value.prefixLength,
                  gateway: value.gateway,
                  interface: value.interface
                }}
                onSelect={selected => {
                  const newRoutes = routes.map(i => {
                    if (i === value) {
                      return {
                        ...i,
                        selected: selected
                      };
                    } else {
                      return i;
                    }
                  });
                  setRoutes(newRoutes);
                }}
                checked={!!value.selected}
                className='static-routes-datatable-row'
                actionItems={[
                  {
                    actionIcon: 'Edit',
                    title: t('edit'),
                    eventKey: 'edit',
                    onSelect: () => setSelectedIndex(index),
                    disabled: isDisabled
                  },
                  {
                    actionIcon: 'TrashCan',
                    title: t('delete'),
                    eventKey: 'delete',
                    onSelect: () => null,
                    disabled: isDisabled,
                    inlineAction: {
                      confirmIcon: 'TrashCan',
                      confirmationMessage: t('SETTINGS.NETWORK.deleteRoutesTitle', {
                        count: 1
                      }),
                      onConfirm: () => deleteItem(index)
                    }
                  }
                ]}
              ></DataTable.Row>
            );
          })}
        </DataTable>
      )}
    </FormSectionDark>
  );
};

export default StaticRoutes;
