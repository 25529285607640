import { Heading4Med, Icon, Placeholder } from '@hai/ui-react';
import { IsHidden } from 'components/isHidden/isHidden';
import { PresetsManager } from 'components/presets/PresetsManager';
import PresetMenu from 'components/presets/PresetsMenu';
import { PresetsSaveAs } from 'components/presets/PresetsSaveAs';
import { RoleContext } from 'permissions/role/Roles';
import React, { useContext, useState } from 'react';

interface IPageHeaderProps {
  title: string;
  iconName?: string;
  loading?: boolean;
  headerRightControls?: any;
  withPresets?: boolean;
}

const PageHeader: React.FunctionComponent<IPageHeaderProps> = ({
  title,
  iconName,
  loading = false,
  headerRightControls,
  withPresets = true
}) => {
  const role = useContext(RoleContext);
  const [saveAsShowing, setSaveAsShowing] = useState(false);
  const [manageShowing, setManageShowing] = useState(false);

  const showDialog = (name: string) => {
    if (name === 'saveAs') {
      setSaveAsShowing(true);
    } else if (name === 'manage') {
      setManageShowing(true);
    }
  };

  return (
    <>
      <div className='tab-header-container'>
        {loading && <Placeholder as='layout' style={{ width: '158px', height: '26px' }} />}
        <IsHidden condition={(loading: boolean) => loading} param={loading}>
          <div className='tab-header-section-container'>
            {iconName && <Icon iconname={iconName} className='tab-header-icon' size='md' />}
            <Heading4Med data-auto='pageTitle'>{title}</Heading4Med>
          </div>
        </IsHidden>
        {headerRightControls && headerRightControls}
        {withPresets && role.can('get', 'presets') && <PresetMenu showDialog={showDialog} />}
      </div>
      <PresetsSaveAs
        handleClose={() => {
          setSaveAsShowing(false);
        }}
        show={saveAsShowing}
      />
      {manageShowing && (
        <PresetsManager
          newPreset={setSaveAsShowing}
          handleClose={() => {
            setManageShowing(false);
          }}
          show={true}
        />
      )}
    </>
  );
};

export default PageHeader;
