import { List, Spinner } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { EnumProtocolType } from 'api/global.type';
import { updateStreamToStart, updateStreamToStop } from 'api/streaming/streaming';
import { IStreamItem } from 'api/streaming/streaming.type';
import LoadingIcon from 'components/common/loadingIcon/LoadingIcon';
import { EditStream } from 'components/streaming/streams/EditStream';
import ListThumbnail from 'components/streaming/streams/ListThumbnail';
import { StreamStats } from 'components/streaming/streams/StreamStats';
import { EnumNotify, NotifyContext } from 'context/notify';
import { StreamingContext } from 'context/streaming/streaming';
import { EnumStreamsAction } from 'context/streaming/streams';
import { t } from 'i18next';
import { RoleContext } from 'permissions/role/Roles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import constant from 'utils/constant';
import { useStreamInfo } from 'utils/hooks/useStreamInfo';
import { getPanelColor } from 'utils/streaming';
import { useService } from 'utils/useService';

import { OutputPlayerLink } from '../outputs/OutputPlayerLink';

interface IStreamItemProps {
  item: IStreamItem;
  saveCallback: Function;
  panelRef: any;
  openPanel?: EnumActions;
  dataTestid?: string;
}

export enum EnumActions {
  WARNINGS = 'warnings',
  REPORT = 'report',
  EDIT = 'edit',
  STATUS = 'STATUS',
  NONE = 'none'
}

const StreamItem = (props: IStreamItemProps) => {
  const { item, saveCallback, panelRef, openPanel, dataTestid } = props;
  const { mode, transcoder, outputSummaries } = item;

  const { streamingDispatch, transcodersClean } = useContext(StreamingContext);

  const { dispatch: notifyDispatch } = useContext(NotifyContext);

  const role = useContext(RoleContext);
  const getStreamInfo = useStreamInfo();

  const [streamInfo, setStreamInfo] = useState(getStreamInfo(item));

  useEffect(() => {
    setStreamInfo(getStreamInfo(item));
  }, [getStreamInfo, item]);

  const [buttonState, setButtonState] = useState<ButtonStateType>('idle');
  const [openedTab, setOpenedTab] = useState<EnumActions>(EnumActions.NONE);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const [autoOpenTriggered, setAutoOpenTriggered] = useState(false);

  const startStream = useService(updateStreamToStart);
  const stopStream = useService(updateStreamToStop);

  const onSelectHandler = useCallback(
    (eventKey: EnumActions, expanded: boolean) => {
      if (isOpen && expanded) {
        setIsOpen(false);
        setTimeout(() => {
          setOpenedTab(eventKey);
          setIsOpen(expanded);
        }, 100);
      } else {
        setOpenedTab(eventKey);
        setIsOpen(expanded);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    if (openPanel && panelRef && !autoOpenTriggered) {
      setAutoOpenTriggered(true);
      onSelectHandler(openPanel, true);
    }
  }, [autoOpenTriggered, onSelectHandler, openPanel, panelRef]);

  const cancel = () => {
    setOpenedTab(EnumActions.NONE);
    setIsOpen(false);
  };
  const onPlayButtonClick = () => {
    setIsSpinning(true);
    setTimeout(() => {
      setIsSpinning(false);
    }, 2000);
  };

  const [startTriggered, setStartTriggered] = useState(false);
  const [beginTriggered, setBeginTriggered] = useState(false);
  const [killContentSlow, setKillContentSlow] = useState(!!openPanel);

  useEffect(() => {
    setTimeout(() => setKillContentSlow(false), constant.timeout.panelTransition);
  }, [isOpen]);

  useEffect(() => {
    if (startTriggered && beginTriggered) {
      setBeginTriggered(false);
      setTimeout(() => {
        setStartTriggered(false);
      }, 5000);
    }
  }, [beginTriggered, startTriggered]);

  const onStatusChange = async () => {
    onPlayButtonClick();
    let result = true;
    if (streamInfo.stopped && streamInfo.actionType !== 'ALERT') {
      const res = await startStream({ item });
      setBeginTriggered(true);
      setStartTriggered(true);
      result = !!res?.result;
    } else {
      const res = await stopStream({ item });
      result = !!res?.result;
    }
    if (result) {
      streamingDispatch({ type: EnumStreamsAction.ADD_UPDATING_STREAMS, payload: item });
      notifyDispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          groupId: streamInfo.stopped && streamInfo.actionType !== 'ALERT' ? 'started' : 'stopped',
          itemId: item.uuid,
          message: {
            name: `STREAMING.STREAMS.MESSAGES.${
              streamInfo.stopped && streamInfo.actionType !== 'ALERT' ? 'started' : 'stopped'
            }Stream`,
            params: {
              name: item.name
            }
          }
        }
      });
    }
  };

  const formatTranscoderText = () => {
    const jsx = [];
    const getText = (mode: string) => {
      switch (mode) {
        case 'iorouter':
          return t('STREAMING.TRANSCODERS.bypass');
        case 'passthru':
          return t('STREAMING.TRANSCODERS.passthru');
        default:
          return transcodersClean?.find(i => i.uuid === transcoder)?.name || t('none');
      }
    };
    if (mode === 'passthru') {
      jsx.push(
        <List.PanelDetailItem
          key={`passthru-${item.uuid}`}
          dataAuto='panel-detail-transcoder'
          divider={'dot'}
          text={transcodersClean?.find(i => i.uuid === transcoder)?.name || t('none')}
          useFsMask={true}
          icon={'Transcoder'}
        />
      );
    }
    jsx.push(
      <List.PanelDetailItem
        key={`mode-detail-${item.uuid}`}
        dataAuto='panel-detail-transcoder'
        divider={outputSummaries?.length ? 'vertical' : 'none'}
        text={getText(mode)}
        useFsMask={true}
        {...(mode !== 'passthru' && { icon: 'Transcoder' })}
      />
    );

    return jsx;
  };

  const toggleSelection = (selected: boolean): void => {
    streamingDispatch({
      type: EnumStreamsAction.SELECT_STREAM,
      payload: {
        id: item.uuid,
        selected: selected
      }
    });
  };

  const getExpandContent = useCallback(() => {
    if (!item || killContentSlow) {
      // clear states in tab
      return null;
    }

    const onSettingsApply = () => {
      setButtonState('idle');
      saveCallback();
      setIsOpen(false);
      setOpenedTab(EnumActions.NONE);
    };

    if (openedTab === EnumActions.EDIT) {
      return (
        <EditStream
          setButtonState={setButtonState}
          cancel={cancel}
          buttonState={buttonState}
          item={item}
          onApply={onSettingsApply}
        />
      );
    }

    if (openedTab === EnumActions.REPORT) {
      return <StreamStats stream={item} />;
    }

    return null;
  }, [item, killContentSlow, openedTab, saveCallback, buttonState]);

  return (
    <div ref={panelRef} key={item.uuid}>
      <List.Panel
        checked={item.selected}
        onSelect={toggleSelection}
        onClick={() => {
          onSelectHandler(isOpen ? openedTab : EnumActions.EDIT, !isOpen);
        }}
        panelColor={getPanelColor(streamInfo.actionType)}
        expandedState={{
          expanded: isOpen,
          eventKey: openedTab
        }}
        key={`stream-panel-${item.uuid}`}
        data-testid={dataTestid}
      >
        <ListThumbnail
          thumbnail={streamInfo.thumbnail}
          title={startTriggered ? t('STREAMING.THUMBNAILS.starting') : streamInfo.thumbnailTitle}
        />
        <List.PanelTitle
          title={item.name}
          useFsMask={true}
          statusIcon={
            isSpinning ? (
              <Spinner loopTime={1} className={'kraken-status-spinner loading icon'} />
            ) : startTriggered ? (
              'StatusStreaming'
            ) : (
              streamInfo.icon
            )
          }
        />
        <List.PanelDetail>
          {item.inputName ? (
            <List.PanelDetailItem
              dataAuto='panel-detail-input'
              divider={'vertical'}
              text={item.inputName}
              useFsMask={true}
              icon={'Input'}
            />
          ) : (
            <></>
          )}
          {formatTranscoderText()}
          {outputSummaries?.length ? (
            outputSummaries.map(
              (
                {
                  id,
                  name,
                  streamType
                }: { id: string; name: string; streamType?: EnumProtocolType },
                index: number
              ) => {
                let text: React.ReactNode | JSX.Element = name;
                if (streamType === EnumProtocolType.HLS) {
                  text = <OutputPlayerLink outputId={id} text={name} />;
                }
                return (
                  name && (
                    <List.PanelDetailItem
                      dataAuto='panel-detail-output'
                      key={`output-${name}-${index}`}
                      divider={outputSummaries.length === index + 1 ? 'none' : 'dot'}
                      text={text}
                      useFsMask={true}
                      icon={outputSummaries.length > 1 && index !== 0 ? '' : 'Output'}
                    />
                  )
                );
              }
            )
          ) : (
            <></>
          )}
        </List.PanelDetail>
        <List.Actions>
          <List.ActionItem
            eventKey={EnumActions.REPORT}
            icon='ReportStats'
            title='Statistics'
            expandButton={true}
            onSelect={(eventKey: string, expanded?: boolean) =>
              onSelectHandler(eventKey as EnumActions, expanded!)
            }
          />
          <List.ActionItem
            eventKey={EnumActions.EDIT}
            icon='Settings'
            title='Settings'
            expandButton={true}
            onSelect={(eventKey: string, expanded?: boolean) =>
              onSelectHandler(eventKey as EnumActions, expanded!)
            }
          />
          <List.ActionItem isDivider />
          <List.ActionItem
            eventKey={EnumActions.STATUS}
            icon={
              <LoadingIcon
                isActive={!streamInfo.stopped}
                activeIcon='StopFilled'
                inactiveIcon='PlayFilled'
              />
            }
            onSelect={onStatusChange}
            showActive={false}
            disabled={isSpinning || role.cannot('start', 'streams')}
          />
        </List.Actions>
        <List.ExpandedPanel>{getExpandContent()}</List.ExpandedPanel>
      </List.Panel>
    </div>
  );
};

export default StreamItem;
