import { useCallback, useRef, useState } from 'react';

export interface IFormRef {
  formRef: any;
  formRefCallback?: any;
  formSubmitActive?: boolean;
}

export interface FormikProps {
  setFieldValue?: any;
  values?: any;
  setValues?: any;
}

export const useFormRef = () => {
  const formRef = useRef<any>();
  const [formSubmitActive, setFormSubmitActive] = useState<boolean>(false);
  const [forceDirty, setForceDirty] = useState(false);

  const formRefCallback = useCallback(
    (node: any) => {
      if (node !== null) {
        formRef.current = node;
        setFormSubmitActive(node.dirty || forceDirty);
      }
    },
    [forceDirty]
  );

  return { formSubmitActive, formRefCallback, setForceDirty, formRef };
};
