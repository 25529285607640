export const generalEn = {
  add: 'Add',
  address: 'Address',
  apply: 'Apply',
  author: 'Author',
  auto: 'Auto',
  autoAssign: 'Auto-Assign',
  browse: 'Browse',
  cancel: 'Cancel',
  close: 'Close',
  connect: 'Connect',
  continue: 'CONTINUE',
  copyOf: 'Copy of',
  decline: 'DECLINE',
  default: 'Default',
  days: 'Days',
  delete: 'Delete',
  description: 'Description',
  disable: 'Disable',
  disabled: 'Disabled',
  disconnect: 'Disconnect',
  done: 'Done',
  download: 'Download',
  dragAndDrop: 'Drag & Drop to Upload File',
  edit: 'Edit',
  enabled: 'Enabled',
  enable: 'Enable',
  export: 'Export',
  fileUploaded: 'Your file was uploaded successfully!',
  fps: 'FPS',
  import: 'Import',
  keywords: 'Keywords',
  load: 'Load',
  loaded: 'Loaded',
  loading: 'Loading',
  name: 'Name',
  none: 'None',
  noSearchResult: 'No Search Results',
  not: 'not',
  on: 'on',
  off: 'off',
  options: 'Options',
  or: 'OR',
  parameters: 'Parameters',
  port: 'Port',
  problemUploading: 'There was a problem uploading.',
  readyToUpdate: 'Ready to Update',
  refresh: 'Refresh',
  required: "'{{item}}' is required",
  save: 'Save',
  saveAs: 'Save As',
  search: 'Search',
  select: 'Select',
  start: 'Start',
  stop: 'Stop',
  tags: 'Tags',
  tryAgain: 'Try Again',
  unset: 'Unset',
  upload: 'Upload',
  update: 'Update',
  uploading: 'Uploading',
  uploadSuccessful: 'Upload Successful!',
  url: 'URL',
  view: 'View',
  reboot: 'Reboot',
  rebootNow: 'Reboot Now',
  rebootLater: 'Reboot Later',
  REBOOT: {
    title: '{{type}} Kraken?',
    save: 'Save and {{type}}',
    desc: 'This action will terminate all processes currently running and may take several minutes to restart.',
    presetUnsaved: 'The currently loaded preset has unsaved changes and will be lost if you choose “{{type}}”.',
    presetUnsaved2: 'Choose “Save and {{type}}” to save the preset and {{type}}.',
    rebooting: 'The system is rebooting',
    rebootingDesc: 'Please refresh this page in 2 minutes if  it does not refresh automatically.'
  },
  incorrectFileType: 'Incorrect file type',
  UNITS: {
    megabit: 'Mb',
    minute: 'm',
    millisecond: 'ms'
  }
};
