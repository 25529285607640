import { archivesEn } from 'i18n/locales/en/settings/archives/archives';
import { licensingEn } from 'i18n/locales/en/settings/licensing/licensing';
import { networkEn } from 'i18n/locales/en/settings/network/network';
import { reportingEn } from 'i18n/locales/en/settings/reporting/reporting';
import { servicesEn } from 'i18n/locales/en/settings/services/service';
import { updatesEn } from 'i18n/locales/en/settings/updates/updates';

export const settingsEn = {
  ARCHIVES: {
    ...archivesEn
  },
  UPDATES: {
    ...updatesEn
  },
  REPORTING: {
    ...reportingEn
  },
  LICENSING: {
    ...licensingEn
  },
  SERVICES: {
    ...servicesEn
  },
  NETWORK: {
    ...networkEn
  }
};
