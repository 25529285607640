import { inputsEn } from 'i18n/locales/en/streaming/inputs/inputs';
import { metadataEn } from 'i18n/locales/en/streaming/metadata/metadata';
import { outputsEn } from 'i18n/locales/en/streaming/outputs/outputs';
import { streamsEn } from 'i18n/locales/en/streaming/streams/streams';
import { thumbnailsEn } from 'i18n/locales/en/streaming/thumbnails/thumbnails';
import { transcodersEn } from 'i18n/locales/en/streaming/transcoders/transcoders';

export const streamingEn = {
  CONNECTION: {
    any: 'any'
  },
  status: 'STATUS',
  streamName: 'STREAM NAME',
  INPUTS: {
    ...inputsEn
  },
  OUTPUTS: {
    ...outputsEn
  },
  STREAMS: {
    ...streamsEn
  },
  TRANSCODERS: {
    ...transcodersEn
  },
  MESSAGES: {
    added: '"{{name}}" added successfully.',
    updated: '"{{name}}" updated successfully.'
  },
  METADATA: {
    ...metadataEn
  },
  THUMBNAILS: {
    ...thumbnailsEn
  },
  FORM: {
    apply: 'APPLY',
    inputParameters: 'INPUT PARAMETERS',
    latency: 'Latency',
    mode: 'Mode',
    networkInterface: 'Network Interface',
    port: 'Port',
    source: 'Source',
    srtSettings: 'SRT SETTINGS',
    title: 'Title'
  },
  STATE: {
    alert: 'ALERT',
    expiring: 'EXPIRING',
    inactive: 'INACTIVE',
    online: 'ONLINE',
    streaming: 'STREAMING'
  },
  REQUIRED: {
    archiveLocation: 'Must select an Archive Location',
    cot: 'Must be between 0 and 1000',
    deviceAddress: 'Address must be a valid IP address or hostname.',
    deviceIpPort: 'IP Port must be between 0 and 65535.',
    devicePort: 'Device Port must be between 1 and 2.',
    gigevisAddress: 'Device Address is required',
    inputRequired: 'Input is Required',
    nameRequired: 'Name is Required',
    notNumber: 'Must be a number',
    port: 'Must be between 1025 and 65535',
    prefix: 'Must be -, _ or alphanumeric characters',
    required: '{{item}} is Required',
    srtPassphrase: 'Passphrase must be between 10 and 79 characters',
    srtLatency: 'Must be between 20 and 8000',
    urlHttp: 'Url must start with http or https',
    urlNoParameters: 'Url must not contain parameters'
  }
};
