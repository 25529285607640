import { PureAbility } from '@casl/ability';
import PageHeader from 'components/common/page/PageHeader';
import TabPage from 'components/common/tabPage/TabPage';
import Accounts from 'components/security/accounts/Accounts';
import Certificates from 'components/security/certificates/Certificates';
import Policies from 'components/security/policies/Policies';
import { t } from 'i18n';
import React, { ReactElement } from 'react';

import { IRoutePaths, securityTabPaths } from './routePaths';

const tabHeader = <PageHeader title={t('ROUTES.security')} iconName='Security' />;

interface ISubTab {
  component: ReactElement;
  key: string;
  title: string;
  path: string;
  index?: boolean;
  show: boolean;
}

interface ISubTabs {
  [key: string]: ISubTab;
}

const subTabs = (role: PureAbility): ISubTabs => {
  return {
    accounts: {
      path: securityTabPaths.accounts,
      title: t('ROUTES.SECURITY.accounts'),
      key: 'securityAccount',
      component: <Accounts />,
      show: role.can('view', 'security/accounts')
    },
    myAccount: {
      path: securityTabPaths.accounts,
      title: t('ROUTES.SECURITY.myAccount'),
      key: 'securityAccount',
      component: <Accounts />,
      show: role.cannot('view', 'security/accounts') && role.can('view', 'security/myAccount')
    },
    certificates: {
      path: securityTabPaths.certificates,
      title: t('ROUTES.SECURITY.certificates'),
      key: 'securityCertificates',
      component: <Certificates />,
      show: role.can('view', 'security/certificates')
    },
    policies: {
      path: securityTabPaths.policies,
      title: t('ROUTES.SECURITY.policies'),
      key: 'securityPolicies',
      component: <Policies />,
      show: role.can('view', 'security/policies')
    }
  };
};

export const securityTabs = (role: PureAbility, isLicenseLoading: boolean): IRoutePaths => {
  const tabs = subTabs(role);

  const tabValues = Object.values(tabs).filter((tab: ISubTab) => tab.show);

  const tabsObject: IRoutePaths = {};

  const securityTab = (tab: ISubTab) => {
    return (
      <TabPage
        tabs={tabs}
        element={tab.component}
        selected={tab.path}
        headerComponent={tabHeader}
        isLicenseLoading={isLicenseLoading}
      />
    );
  };

  tabValues.map((tab: ISubTab) => {
    tabsObject[tab.key] = {
      path: tab.path,
      element: securityTab(tab)
    };
  });

  return tabsObject;
};
