export enum EnumCertificateType {
  SELF = 'self-signed',
  CRT = 'CRT', // api payload
  CSR = 'csr', // api payload
  CSR_RESPONSE = 'CSR', // api response
  CA_CHAIN = 'CA-CHAIN' // // api response
}

export interface ICertificate {
  digest: string;
  expired: boolean;
  expiringDate: number;
  expiringDays: number;
  extension: string;
  fileContents: string;
  id: string;
  isDefault: boolean;
  keySize: number;
  matchesCurrentPrivateKey: boolean;
  modulus: string;
  name: string;
  selfSigned: boolean;
  subject: string;
  type: EnumCertificateType;
  // frontend use only
  selected?: boolean;
}

export interface ICertificatesState {
  certificates: ICertificate[] | null;
}

export enum EnumCertificatesAction {
  SET_CERTIFICATES = 'SET_CERTIFICATES',
  SELECT_CERTIFICATE = 'SELECT_CERTIFICATE',
  SELECT_ALL = 'SELECT_ALL_CERTIFICATE',
  DELETE_CERTIFICATE = 'DELETE_CERTIFICATE',
  RESET = 'RESET'
}

export interface ICertificatesAction {
  type: EnumCertificatesAction;
  payload: any;
}

// Init states
export const initCertificatesState: ICertificatesState = {
  certificates: null
};

// Reducer
export const certificatesReducer = (
  state: ICertificatesState,
  action: ICertificatesAction
): ICertificatesState => {
  switch (action.type) {
    case EnumCertificatesAction.SET_CERTIFICATES: {
      const newCertificates = action.payload?.map((item: ICertificate) => {
        const selected = state.certificates?.find(i => i.id === item.id)?.selected;
        const newItem = item;
        newItem.selected = selected;
        return newItem;
      });
      return {
        ...state,
        certificates: newCertificates
      };
    }
    case EnumCertificatesAction.SELECT_CERTIFICATE: {
      const { id, selected } = action.payload;
      const newCertificates = state.certificates?.map((i: ICertificate) => {
        const newItem = i;
        if (i.id === id) {
          newItem.selected = selected;
        }
        return newItem;
      });
      return {
        ...state,
        certificates: newCertificates || null
      };
    }
    case EnumCertificatesAction.DELETE_CERTIFICATE: {
      const { id } = action.payload;
      const newCertificates = state.certificates?.filter((i: ICertificate) => i.id !== id);
      return {
        ...state,
        certificates: newCertificates || null
      };
    }
    case EnumCertificatesAction.SELECT_ALL: {
      const selected = action.payload;
      const newCertificates = state.certificates?.map((i: ICertificate) => {
        const newItem = i;
        newItem.selected = selected;
        return newItem;
      });

      return {
        ...state,
        certificates: newCertificates || null
      };
    }
    case EnumCertificatesAction.RESET: {
      return initCertificatesState;
    }
    default:
      return state;
  }
};
