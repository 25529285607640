import React from 'react';

interface Params {
  param: any;
  condition: Function;
  className?: string;
  children?: React.ReactNode;
}

export const isHidden = (params: Params) => {
  const { param, condition, className = '' } = params;
  return condition(param) ? { className: `${className} d-none` } : { className };
};

export const IsHidden = (props: Params) => {
  return <div {...isHidden(props)}>{props.children}</div>;
};
