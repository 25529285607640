import { IGeneralApiProps } from 'api/global.type';
import { IOutputItem } from 'api/outputs/output.type';
import { convertApiEmptyToEmpty, convertAutoToBlank, decodeResponse } from 'api/utils/helper';

import client from '../utils/client';
import { encloseSquareBracketIPv6 } from 'utils/validations';

interface IGetOutputProps extends IGeneralApiProps {
  apiParams: { uuid: string };
}

export const outputsDefaults = {
  name: '',
  streamType: 'MPEG2TS',
  url: '',
  mtu: '',
  networkInterface: '',
  sapAddress: '',
  sapAuthor: '',
  sapDescription: '',
  sapEnabled: '0',
  sapKeywords: '',
  sapName: '',
  sapPort: '9875',
  srtAddress: '',
  srtEncryption: '',
  encryptionSelect: true,
  srtLatency: '125',
  rtspPort: '554',
  srtLocalPort: '',
  srtMode: 'caller',
  srtOverhead: '',
  srtPassphrase: '',
  srtRemotePort: '',
  tos: '',
  ttl: '',
  archiveLocationUuid: '',
  prefix: '',
  hls: {
    segmentFormat: 'MPEG2TS',
    playlistMaxNumSegments: 10,
    segmentDurationMsec: 2000,
    hlsUrl: ''
  }
};

export const getOutput = (props: IGetOutputProps): Promise<{ output?: any; success: boolean }> => {
  const { apiParams, onError } = props;

  return client
    .get(`/outputs/${apiParams.uuid}`)
    .then(resp => {
      if (resp.status === 200) {
        return { output: resp.data, success: true };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

export const getOutputs = (props: IGeneralApiProps) => {
  const { onError } = props;
  return client
    .get('/outputs')
    .then(resp => {
      const outputs = resp.data.outputList;
      return apiToFormdata(outputs);
    })
    .catch(e => {
      onError && onError(e);
      return e;
    });
};

interface IAddOutputProps extends IGeneralApiProps {
  apiParams: IOutputItem;
}

export const addOutput = (props: IAddOutputProps) => {
  const { apiParams, onError } = props;

  return client
    .post('/outputs', formdataToApi(apiParams))
    .then(resp => {
      if (resp.status === 200) {
        return { success: true, ...resp.data };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

export const updateOutput = (props: IAddOutputProps) => {
  const { apiParams, onError } = props;

  return client
    .put(`/outputs/${apiParams.uuid}`, formdataToApi(apiParams))
    .then(resp => {
      if (resp.status === 200) {
        return { ...resp.data, success: true };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

interface IDeleteOutput extends IGeneralApiProps {
  output: IOutputItem;
}

export const deleteOutput = (props: IDeleteOutput) => {
  const { onError, output } = props;
  return client
    .delete(`/outputs/${output.uuid}`)
    .then(() => {
      return true;
    })
    .catch(e => {
      onError && onError(e);
    });
};

const apiToFormdata = (apiData: any) => {
  return apiData.map((i: any) => {
    return {
      ...i,
      name: decodeResponse(i.name),
      srtLocalPort: convertApiEmptyToEmpty(i.srtLocalPort) || '',
      srtRemotePort: convertApiEmptyToEmpty(i.srtRemotePort) || '',
      mtu: convertApiEmptyToEmpty(i.mtu) || '',
      ttl: convertApiEmptyToEmpty(i.ttl) || '',
      tos: convertApiEmptyToEmpty(i.tos) || '',
      srtOverhead: convertApiEmptyToEmpty(i.srtOverhead) || '',
      sapPort: convertApiEmptyToEmpty(i.sapPort) || '',
      hls: {
        hlsUrl: i?.hls?.url,
        playlistMaxNumSegments:
          convertApiEmptyToEmpty(i?.hls?.playlistMaxNumSegments) ||
          outputsDefaults.hls.playlistMaxNumSegments,
        playlistType: convertApiEmptyToEmpty(i?.hls?.playlistType),
        segmentDurationMsec:
          convertApiEmptyToEmpty(i?.hls?.segmentDurationMsec) ||
          outputsDefaults.hls.segmentDurationMsec,
        segmentFormat: convertApiEmptyToEmpty(i?.hls?.segmentFormat)
      },
      state: i.state === -1 ? 5 : i.state,
      streamType: i.streamType === '' ? 'MPEG2TS' : i.streamType,
      rtspPath: i?.rtsp?.path,
      rtspDirection: i?.rtsp?.direction,
      rtspServer: i?.rtsp?.server,
      rtspPort: i?.rtsp?.port || outputsDefaults.rtspPort
    };
  });
};

const formdataToApi = (formData: any) => {
  let apiData = <any>{
    name: formData.name,
    description: '',
    streamType: formData.streamType === '' ? 'MPEG2TS' : formData.streamType,
    networkInterface: formData.networkInterface || '',
    mtu: '',
    ttl: '',
    tos: '',
    srtIpstack: '',
    srtMode: '',
    srtAddress: '',
    srtLocalPort: '',
    srtLatency: '',
    srtRemotePort: '',
    srtEncryption: '',
    srtPassphrase: '',
    srtOverhead: '',
    sapName: formData.sapName || '',
    sapDescription: formData.sapDescription || '',
    sapKeywords: formData.sapKeywords || '',
    sapAuthor: formData.sapAuthor || '',
    sapAddress: formData.sapAddress || '',
    sapPort: formData.sapPort || '',
    tsSegmentArchive: {
      archiveLocationUuid: formData.archiveLocationUuid || '',
      prefix: formData.prefix || ''
    },
    sapEnable: formData.sapEnable || '0'
  };

  switch (apiData.streamType) {
    case 'MPEG2TS':
      apiData.url = encloseSquareBracketIPv6(formData.url) || '';
      apiData.mtu = formData.mtu;
      apiData.networkInterface = convertAutoToBlank(formData.networkInterface);

      apiData.ttl = formData.ttl;
      apiData.tos = formData.tos;
      break;
    case 'SRT':
      apiData = {
        ...apiData,
        // reset URL, we don't use it in this mode
        url: '',
        srtMode: formData.srtMode || 'caller',
        srtOverhead: formData.srtOverhead || '',
        srtAddress: formData.srtAddress || '',
        ...(formData.srtRemotePort
          ? { srtRemotePort: convertApiEmptyToEmpty(formData.srtRemotePort) || '' }
          : {}),
        srtLocalPort: formData.srtLocalPort || '',
        srtLatency: formData.srtLatency || outputsDefaults.srtLatency,
        srtEncryption: formData.srtEncryption || '',
        srtPassphrase: formData.srtPassphrase || '',
        sapPort: '',
        mtu: formData.mtu,
        ttl: formData.ttl,
        tos: formData.tos
      };

      if (formData.srtEncryption === '') {
        apiData.srtPassphrase = '';
      }

      if (formData.srtMode === 'listener') {
        apiData.srtAddress = '';
      }
      break;
    case 'RTMP':
      apiData.url = formData.url || '';
      break;
    case 'TS_SEGMENT_ARCHIVE':
      apiData = {
        // reset URL, we don't use it in this mode
        url: '',
        name: formData.name,
        description: '',
        streamType: formData.streamType,
        tsSegmentArchive: {
          archiveLocationUuid: formData.archiveLocationUuid || '',
          prefix: formData.prefix || ''
        }
      };
      break;
    case 'HLS':
      // reset URL, we don't use it in this mode
      apiData.url = '';
      apiData.hls = {
        playlistMaxNumSegments: formData.hls.playlistMaxNumSegments,
        segmentDurationMsec: formData.hls.segmentDurationMsec,
        segmentFormat: formData.hls.segmentFormat
      };
      break;
    case 'RTSP': {
      const direction = formData.rtspDirection || 'PUSH';
      // reset URL, we don't use it in this mode
      apiData.url = '';
      apiData.rtsp = {
        direction,
        path: formData.rtspPath,
        ...(direction === 'PUSH' ? { server: formData.rtspServer } : {}),
        ...(direction === 'PUSH' ? { port: formData.rtspPort } : {})
      };
      break;
    }
  }

  return apiData;
};
