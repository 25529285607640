import './assets/scss/_kraken.scss';

import { ProductName, useFavicon } from '@hai/ui-react/dist/hook';
import Notify from 'components/common/notify/notify';
import { Context } from 'context/context';
import React from 'react';

import RouteManager from './components/routes/RouteManager';
import { ThemeProvider } from './themeProvider';

// Tell typescript there's a global defined in webpack config
// declare const __VERSION__: string;
// declare const __ENV__: string;

const App: React.FunctionComponent = () => {
  // TODO - review settings and how to handle fullStory init.
  /*
  const userAssistanceSettings = {
    // TODO - this needs to be controlled by server settings
    isFullStoryEnabled: true,
    fullStoryOrganizationId: 'o-1EQT7G-na1',
    fullStoryProductName: 'Kraken',
    // TODO - can we determine if this is the local dev server or a real build 'prod'
    fullStoryEnvironment: 'dev'
    // TODO - determine correct version data
    //  
  };
  */

  useFavicon(ProductName.KRAKEN);

  return (
    <ThemeProvider>
      <Context>
        <Notify />
        <RouteManager />
      </Context>
    </ThemeProvider>
  );
};

export default App;
