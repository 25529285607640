export const signinEn = {
  changePassword: 'Change Password',
  password_required: 'Password required',
  password: 'Password',
  rememberMe: 'Remember Me',
  signin: 'Sign in',
  signOut: 'Sign out',
  username_required: 'Username required',
  username: 'Username',
  passwordExpirationMsg: 'Your password expires in one day.',
  passwordExpirationMsg_other: 'Your password expires in {{count}} days.',
  passwordExpirationMsg_zero: 'Your password expires in less than a day.'
};
