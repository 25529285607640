import { IGeneralApiProps } from 'api/global.type';

import client, { handleErrors } from '../utils/client';

export const getSystemInfo = (props?: IGeneralApiProps) => {
  return client
    .get('/systeminfo')
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return null;
    });
};

export const getSystemMetrics = (props?: IGeneralApiProps) => {
  handleErrors(props);
  return client
    .get(`system/metric/snapshot`)
    .then(resp => {
      return formatSysMetrics(resp.data);
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return [];
    });
};

const formatSysMetrics = (data: any) => {
  return {
    cpuload: data.cpu.loadPercent,
    memload: data.memory.usedPercent,
    uptime: data.system.uptime,
    ...data
  };
};

export const getDebugLogging = (props?: IGeneralApiProps) => {
  return client
    .get('/debuglogging')
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return [];
    });
};

interface ISetDebugLoggingProps extends IGeneralApiProps {
  apiParams: {
    enabled: boolean;
  };
}

export const updateDebugLogging = (props: ISetDebugLoggingProps) => {
  const { apiParams, onError } = props;

  return client
    .put('/debuglogging', apiParams)
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};

export const rebootSystem = (props?: IGeneralApiProps) => {
  return client
    .put('/system/reboot')
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
    });
};
