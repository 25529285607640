import { addStream } from 'api/streaming/streaming';
import { StreamFormLayout } from 'components/streaming/streams/StreamFormLayout';
import { EnumNotify, NotifyContext } from 'context/notify';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { IFormRef } from 'utils/hooks/useFormRef';
interface IProps {
  saveCallback: any;
  buttonState: string;
  setButtonState: Dispatch<SetStateAction<string>>;
  formControl: IFormRef;
}
export const AddStream = (props: IProps) => {
  const { setButtonState, saveCallback, formControl, buttonState } = props;
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const [selectedOutputs, setSelectedOutputs] = useState<string[] | undefined[]>([undefined]);

  const [localState, setLocalState] = useState(buttonState);

  const onSave = async (values: any, { setSubmitting }: any) => {
    if (localState !== 'idle') {
      return;
    }
    setLocalState('pending');
    setButtonState('pending');
    values.outputs = [...selectedOutputs].filter(i => (i ? true : false));
    if (values.outputs.length === 0) {
      values.outputs = [''];
    }
    values.metadatas = Array.isArray(values.metadatas) ? values.metadatas : [values.metadatas];
    delete values.multiOutputs;
    const result = await addStream({ apiParams: values });
    if (result?.success) {
      saveCallback(result.uuid);
      notifyDispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: {
            name: 'STREAMING.STREAMS.MESSAGES.added',
            params: { name: values.name }
          }
        }
      });
    } else {
      setButtonState('idle');
      setLocalState('idle');
    }
    setSubmitting(false);
  };

  const formProps = {
    defaultValidation: true,
    handleSubmit: onSave,
    initialValues: {
      name: '',
      inputs: '',
      metadatas: '',
      outputs: '',
      transcoder: ''
    }
  };

  return (
    <StreamFormLayout
      formProps={formProps}
      formControl={formControl}
      selectedOutputs={selectedOutputs}
      setSelectedOutputs={setSelectedOutputs}
    />
  );
};
