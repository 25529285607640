export const reportingEn = {
  date: 'Date',
  debugLogging: 'Debug Logging',
  events: 'Events',
  message: 'Message',
  status: 'Status',
  stream: 'Stream',
  noEvents: 'No recorded events.',
  systemSnapshot: 'System Snapshot',
  DEBUG_LOGGING: {
    enableTitle: 'Enabling debug log',
    disableTitle: 'Disabling debug log',
    enableDesc:
      'Enabling transcoder logs may affect system performance and should not be used in production. Use of ' +
      'transcoder logs is for debugging purpose only, and leveraged under the guidance of Haivision support. ' +
      '\n' +
      'Transcoder logs are off by default and can be turned on/off individually for each stream with a button in ' +
      'the stream statistics page.',
    disableDesc: 'Streams with logs enabled will be restarted.',
    enabledMsg: 'Debug mode was Enabled',
    disabledMsg: 'Debug mode was Disabled'
  }
};
