import React from 'react';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';

export interface HlsPlayerProps {
  options?: {
    fill?: boolean;
    fluid?: boolean;
    responsive?: boolean;
    preload?: string;
    autoplay?: boolean;
    controls?: boolean;
    muted?: boolean;
    preview?: boolean;
    controlBar: {
      liveDisplay?: boolean;
      pictureInPictureToggle?: boolean;
    };
    sources?: Array<{
      src: string;
      type?: string;
    }>;
  };
  onReady?: (player: Player) => void;
}

export const HlsPlayer = (props: HlsPlayerProps) => {
  const videoRef = React.useRef<HTMLDivElement | null>(null);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const playerRef = React.useRef<Player | null>(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');
      videoElement.classList.add('vjs-big-play-centered');

      videoRef.current?.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      }));
    } else {
      const player = playerRef.current as Player;
      const existingSrc = player.src(undefined);

      // check if source added
      if ((existingSrc?.length || 0) < (options?.sources?.length || 0)) {
        player.autoplay(options?.autoplay);
        player.src(options?.sources);
      }
    }
  }, [onReady, options, videoRef]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div
      ref={containerRef}
      data-vjs-player
      style={{
        height: '100%',
        width: '100%'
      }}
    >
      <div
        ref={videoRef}
        style={{
          height: 'calc(100% - 30px)',
          width: '100%'
        }}
      />
    </div>
  );
};

export default HlsPlayer;
