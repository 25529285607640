import { Heading4Book } from '@hai/ui-react';
import React, { ReactElement } from 'react';
import './settingsHeader.scss';

interface IProps {
  title: string;
  buttons?: ReactElement;
}

const SettingsHeader: React.FunctionComponent<IProps> = ({ title, buttons }) => {
  return (
    <div className='settings-header'>
      <Heading4Book data-auto='header_title'>{title}</Heading4Book>
      <div className='buttons'>{buttons}</div>
    </div>
  );
};

export default SettingsHeader;
