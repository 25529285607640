import { List } from '@hai/ui-react';
import { IMetadataItem } from 'api/metadata/metadata.type';
import { t } from 'i18next';
import React from 'react';

export const buildDetails = (props: { details: string[]; item: any; type: any }) => {
  const { details, item, type } = props;
  const buildList = () => {
    return details.map((detailName: any, index: number) => {
      const detail = (type as any)[detailName](item);
      return (
        <List.PanelDetailItem
          key={index}
          {...(index !== details.length - 1 ? { divider: 'vertical' } : {})}
          dataAuto={`panel-detail-${detailName}`}
          text={detail.text}
          icon={detail.icon}
        />
      );
    });
  };

  return <List.PanelDetail>{buildList()}</List.PanelDetail>;
};

export const uptimeHHMMSS = (runtimeSeconds: number | undefined) => {
  if (runtimeSeconds) {
    const d = Math.floor(runtimeSeconds / (3600 * 24));
    const h = Math.floor((runtimeSeconds % (3600 * 24)) / 3600);
    const m = Math.floor((runtimeSeconds % 3600) / 60);
    let ss = '';
    if (d === 0 && h === 0) {
      ss = ` ${Math.floor(runtimeSeconds % 60)}s`;
    }
    const dd = d ? `${d}d ` : '';
    const hh = h || d ? `${h}h ` : '';
    const mm = m || h || d ? `${m}m` : '';

    return `${dd}${hh}${mm}${ss}`;
  }
  return '00:00:00';
};

export const uptimeDayHHMMSS = (runtimeSeconds: number) => {
  if (runtimeSeconds) {
    const d = Math.floor(runtimeSeconds / (3600 * 24));
    const h = Math.floor((runtimeSeconds % (3600 * 24)) / 3600);
    const m = Math.floor((runtimeSeconds % 3600) / 60);

    const dd = d ? `${d} ${t('days')}, ` : '';
    const hh = h ? `${('0' + h).slice(-2)}:` : '00:';
    const mm = m ? `${('0' + m).slice(-2)}:` : '00:';
    let ss = `0${Math.floor(runtimeSeconds % 60)}`.slice(-2);

    return `${dd}${hh}${mm}${ss}`;
  }
  return '00:00:00';
};

interface IFormatResolution {
  height: number;
  width: number;
}

export const formatBool = (item: boolean) => {
  return item ? t('enabled') : t('disabled');
};

export const formatResolution = (props: IFormatResolution) => {
  const { height, width } = props;
  if (height === -1 || width === -1 || !height || !width) {
    return t('auto');
  }
  return `${width}x${height}`;
};

interface IFormatVideoBitrate {
  videoBitrate: number | string;
}

export const formatVideoBitrate = (props: IFormatVideoBitrate) => {
  const { videoBitrate } = props;
  if (videoBitrate === -1 || videoBitrate === '') {
    return `${t('auto')} kbps`;
  }

  return `${videoBitrate} kbps`;
};

interface IFormatGop {
  gopSize: number | string;
}
export const formatGop = (props: IFormatGop) => {
  const { gopSize } = props;
  if (gopSize === -1 || gopSize === '') {
    return ` ${t('auto')} GOP`;
  }
  return `GOP ${gopSize}`;
};

interface IFormatAudioEnabled {
  audio: boolean;
}

export const formatAudioEnabled = (props: IFormatAudioEnabled) => {
  const { audio } = props;
  return formatBool(audio);
};

interface IFormatKlvEnabled {
  klv: boolean;
}

export const formatKlvEnabled = (props: IFormatKlvEnabled) => {
  const { klv } = props;
  return `KLV  ${formatBool(klv)}`;
};

interface IFormatMethod {
  method: string;
}
export const formatMethod = (props: IFormatMethod) => {
  const { method } = props;

  return { text: t(`STREAMING.METADATA.METHODS.${method}`) };
};

interface IFormatSecurity {
  security: boolean;
}

export const formatSecurity = (props: IFormatSecurity) => {
  const { security } = props;
  return {
    text: security
      ? t(`STREAMING.METADATA.securityEnabled`)
      : t(`STREAMING.METADATA.securityDisabled`),
    icon: security ? 'Security' : 'SecurityDisabled'
  };
};

interface IFormatIdEnabled {
  id: boolean;
}

export const formatIdEnabled = (props: IFormatIdEnabled) => {
  const { id } = props;
  return {
    text: id ? t(`STREAMING.METADATA.idEnabled`) : t(`STREAMING.METADATA.idDisabled`)
  };
};
// //
// Specific groupings

// Metadata
//
export const formatMetadataMap = {
  method: (item: IMetadataItem) => formatMethod({ method: item.method }),
  idEnabled: (item: IMetadataItem) => formatIdEnabled({ id: item.enableSecurityDataSetInsertion }),
  security: (item: IMetadataItem) =>
    formatSecurity({ security: item.enableSecurityDataSetInsertion })
};

export const humanReadableTime = (props: { ms: number | undefined; zero?: string }) => {
  const { ms, zero = '0ms' } = props;
  if (ms === 0) {
    return zero;
  }
  if (!ms) {
    return 'NA';
  }
  if (ms < 1000) {
    return `${ms}ms`;
  }
  return `${Math.round(ms / 100) / 10}s`;
};

interface IHumanReadableData {
  bytes: number | undefined;
  max?: number;
  zero?: string;
}

export const humanReadableData = (props: IHumanReadableData) => {
  const { bytes, max = 1000, zero = '0B/s' } = props;
  if (bytes === 0) {
    return zero;
  }
  if (!bytes) {
    return 'NA';
  }
  if (bytes < max) {
    return `${bytes}B`;
  } else if (bytes < 1000 * max) {
    return `${Math.round(bytes / 100) / 10}Kbps`;
  } else if (bytes < 1000000 * max) {
    return `${Math.round(bytes / 100000) / 10}Mbps`;
  } else if (bytes < 1000000000 * max) {
    return `${Math.round(bytes / 100000000) / 10}Gbps`;
  }
  return `${Math.round(bytes / 100000000000) / 10}Tbps`;
};

export const humanReadableMinData = (props: IHumanReadableData) => {
  const { bytes, max = 1000, zero = '0B' } = props;
  if (bytes === 0) {
    return zero;
  }
  if (!bytes) {
    return 'NA';
  }
  if (bytes < max) {
    return `${bytes}B`;
  } else if (bytes < 1000 * max) {
    return `${Math.round(bytes / 100) / 10}K`;
  } else if (bytes < 1000000 * max) {
    return `${Math.round(bytes / 100000) / 10}M`;
  } else if (bytes < 1000000000 * max) {
    return `${Math.round(bytes / 100000000) / 10}G`;
  }
  return `${Math.round(bytes / 100000000000) / 10}T`;
};

export const htmlDecode = (input: string) => {
  var doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};
