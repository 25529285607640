export type ISystemUpgradeState =
  | 'IDLE'
  | 'UPLOADING_BUNDLE'
  | 'ERROR'
  | 'BUNDLE_READY'
  | 'PREPARING_UPGRADE'
  | 'UPGRADING'
  | 'VERIFYING_BUNDLE';

export interface ISystemUpgrade {
  state: ISystemUpgradeState;
  id?: string;
  msg?: string;
  build?: string;
  version?: string;
}

export interface IUpdatesState {
  upgrade?: ISystemUpgrade;
}

export enum EnumUpdatesAction {
  SET_UPGRADE = 'SET_UPGRADE',
  RESET = 'RESET'
}

export interface IUpdatesAction {
  type: EnumUpdatesAction;
  payload: any;
}

// Init states
export const initUpdatesState: IUpdatesState = {};

// Reducer
export const updatesReducer = (state: IUpdatesState, action: IUpdatesAction): IUpdatesState => {
  switch (action.type) {
    case EnumUpdatesAction.SET_UPGRADE: {
      return {
        ...state,
        upgrade: action.payload
      };
    }
    case EnumUpdatesAction.RESET: {
      return initUpdatesState;
    }
    default:
      return state;
  }
};
