import './streamStatsDetail.scss';

import { Button, Heading4Med, Link, Placeholder } from '@hai/ui-react';
import { IButtonToggleOption } from '@hai/ui-react/dist/components/ButtonToggleGroup/ButtonToggleGroup';
import { getDashboardStatsById } from 'api/dashboard/dashboard';
import { IDashboardStat } from 'api/dashboard/dashboard.type';
import { getDebugLogging } from 'api/settings/system';
import { IDebugLogging } from 'api/settings/system.type';
import {
  getStream,
  getStreamStats,
  streamLogDisable,
  streamLogEnable
} from 'api/streaming/streaming';
import { EnumStreamSubStatus, IStreamItem } from 'api/streaming/streaming.type';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import Masonry, { IMasonrySection } from 'components/common/masonry/Masonry';
import Page from 'components/common/page/Page';
import StatsDetailPanel from 'components/common/statsDetailPanel/StatsDetailPanel';
import StatsHeaderPanel from 'components/common/statsHeaderPanel/StatsHeaderPanel';
import StreamingPage from 'components/streaming/StreamingPage';
import { EnumNotify, NotifyContext } from 'context/notify';
import { SettingsContext } from 'context/settings/settings';
import { EnumStatusAction } from 'context/settings/status';
import { StreamingContext } from 'context/streaming/streaming';
import { t } from 'i18n';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';
import constant from 'utils/constant';
import { EnumStreamStateString } from 'utils/hooks/useDashboardInfo';
import { useStreamInfo } from 'utils/hooks/useStreamInfo';
import {
  formatGraphHistory,
  getAmountByTimescale,
  getBypassStats,
  getDecoderStats,
  getEncoderStats,
  getInputStats,
  getOutputStats,
  getPanelColor
} from 'utils/streaming';
import { useService } from 'utils/useService';

export enum EnumTimescale {
  fiveMins = 'fiveMins',
  sixtyMins = 'sixtyMins',
  twentyFourHours = 'twentyFourHours'
}

const StreamStatsDetail: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const params = useParams();

  const getStreamInfo = useStreamInfo();
  const getStreamStatsService = useService(getStreamStats);
  const [statsHistory, setStatsHistory] = useState<any[]>([]);
  const [graphHistory, setGraphHistory] = useState<IDashboardStat[]>([]);
  const [timescale, setTimescale] = useState<EnumTimescale>(EnumTimescale.fiveMins);
  const { inputsClean, transcodersClean } = useContext(StreamingContext);

  const [stream, setStream] = useState<IStreamItem>();
  const id = params.id as string;

  const { settingsDispatch, debugLoggingEnabled } = useContext(SettingsContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);

  const [streamInfo, setStreamInfo] = useState<any>();

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (debugLoggingEnabled === 'unset') {
      getDebugLogging().then((value: IDebugLogging) => {
        settingsDispatch({ type: EnumStatusAction.SET_DEBUG_LOGGING, payload: value.enabled });
      });
    }
  }, [debugLoggingEnabled, settingsDispatch]);

  const [dataFetching, setDataFetching] = useState(false);

  useEffect(() => {
    document.title = `${window.location.hostname} - ${stream?.name}`;
    const fetchData = async () => {
      const result = await getStream({ apiParams: { uuid: id } });
      setStreamInfo(getStreamInfo(result as any));
      setStream(result);
    };
    if (stream === undefined && id && !dataFetching) {
      setDataFetching(true);
      fetchData();
    }
  }, [dataFetching, getStreamInfo, id, stream]);

  const itemsLength = getAmountByTimescale(timescale);
  const maxItemsLength = getAmountByTimescale(EnumTimescale.twentyFourHours);

  const getStats = useCallback(() => {
    const updateStream = async () => {
      const result = await getStream({ apiParams: { uuid: id } });
      setStream(result);
      setStreamInfo(getStreamInfo(result));
    };

    updateStream();
    getStreamStatsService({ uuid: id, ignoreError: ['401', '404', '500'] }).then((value: any) => {
      const stats = value?.stats;
      if (stats) {
        const newHistory = JSON.parse(JSON.stringify(statsHistory || []));
        newHistory.unshift(stats);
        setStatsHistory(newHistory.slice(0, maxItemsLength));
      }
    });
    getDashboardStatsById({ uuid: id }).then((value: IDashboardStat) => {
      if (
        value &&
        (value.state === EnumStreamStateString.STREAMING ||
          value.state === EnumStreamStateString.WAITING)
      ) {
        const stats = value;
        const date = new Date();
        stats.date = date.toISOString();
        const newHistory = graphHistory;
        newHistory.unshift(stats);
        setGraphHistory(newHistory.slice(0, maxItemsLength));
      } else if (graphHistory.length !== 0) {
        const newHistory = graphHistory;
        newHistory.unshift({
          date: '',
          encoderBitrate: 0,
          inputBitrate: 0,
          outputLatency: 0,
          runtimeSeconds: 0,
          state: EnumStreamStateString.WAITING,
          subStatus: EnumStreamSubStatus.NO_DATA,
          streamId: '',
          streamName: '',
          totalOutputBitrate: 0
        });
        setGraphHistory(newHistory.slice(0, maxItemsLength));
      }
      dataFetchedRef.current = false;
    });
  }, [getStreamInfo, getStreamStatsService, id, statsHistory, maxItemsLength, graphHistory]);

  const reset = () => {
    setStatsHistory([]);
    setGraphHistory([]);
    setTimescale(EnumTimescale.fiveMins);
  };

  useInterval(getStats, constant.interval.streamStats);

  useEffect(() => {
    if (statsHistory.length === 0 && !dataFetchedRef.current) {
      dataFetchedRef.current = true;
      getStats();
    }
  }, [getStats, statsHistory]);

  const [sections, setSections] = useState<IMasonrySection[]>([]);
  useEffect(() => {
    if (statsHistory) {
      const inputs = statsHistory[0]?.legacyInputs;
      const outputs = statsHistory[0]?.legacyOutputs;
      const encoders = statsHistory[0]?.legacyEncoders;
      const decoders = statsHistory[0]?.legacyDecoders;
      const localSection = [];
      inputs?.map((input: any, index: number) => {
        localSection.push({
          title: t('STREAMING.STREAMS.STATISTICS.input') + (index + 1),
          data: getInputStats(input),
          dataAuto: `input${index + 1}`
        });
      });
      decoders?.map((decoder: any, index: number) => {
        localSection.push({
          title: t('STREAMING.STREAMS.STATISTICS.decoder') + (index + 1),
          data: getDecoderStats(decoder),
          dataAuto: `decoder${index + 1}`
        });
      });
      outputs?.map((output: any, index: number) => {
        localSection.push({
          title: t('STREAMING.STREAMS.STATISTICS.output') + (index + 1),
          data: getOutputStats(output),
          dataAuto: `output${index + 1}`
        });
      });
      encoders?.map((encoder: any, index: number) => {
        localSection.push({
          title: t('STREAMING.STREAMS.STATISTICS.encoder') + (index + 1),
          data: getEncoderStats(encoder),
          dataAuto: `encoder${index + 1}`
        });
      });
      if (statsHistory[0]?.mode === 'iorouter') {
        localSection.push({
          title: statsHistory[0].name,
          data: getBypassStats(statsHistory[0]),
          dataAuto: `bypass`
        });
      }
      setSections(localSection);
    }
  }, [statsHistory]);

  const renderSection = (data: any) => {
    return <StatsDetailPanel title={data.title} data={data.data} dataAuto={data.dataAuto} />;
  };

  const onTimescaleClick = (opt: IButtonToggleOption) => {
    setTimescale(opt.value as EnumTimescale);
  };

  // const csvDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   let data: any = [];
  //   data = graphHistory.map(i => {
  //     const result = {
  //       date: i.date,
  //       inputRate: bitsToMegabits(i.inputBitrate),
  //       streamRate: bitsToMegabits(i.encoderBitrate),
  //       overallRate: bitsToMegabits(i.totalOutputBitrate),
  //       latency: i.outputLatency
  //     };
  //     return result;
  //   });
  //   const headers = [
  //     [
  //       t('STREAMING.STREAMS.REPORT.CSV.date'),
  //       t('STREAMING.STREAMS.REPORT.CSV.inputRate'),
  //       t('STREAMING.STREAMS.REPORT.CSV.streamRate'),
  //       t('STREAMING.STREAMS.REPORT.CSV.overallRate'),
  //       t('STREAMING.STREAMS.REPORT.CSV.latency')
  //     ].join(',')
  //   ];
  //   const dataCsv = data.reduce(
  //     (
  //       acc: string[],
  //       item: {
  //         date: string;
  //         inputRate: number;
  //         streamRate: number;
  //         overallRate: number;
  //         latency: number;
  //       }
  //     ) => {
  //       const { date, latency, inputRate, streamRate, overallRate } = item;
  //       acc.push([date, inputRate, streamRate, overallRate, latency].join(','));
  //       return acc;
  //     },
  //     []
  //   );
  //   const fileNameDate = data[data.length - 1].date;
  //   const fileName = `srt-statistics-${fileNameDate}.csv`;
  //   exportToCsv(e, dataCsv, headers, fileName);
  // };

  if (stream === undefined) {
    return null;
  }

  const formattedGraphHistory = formatGraphHistory(graphHistory, itemsLength);

  const placeholderHeader = (
    <Placeholder as='layout' style={{ height: '480px', marginBottom: '15px' }} />
  );
  const placeholderMasonry = (
    <div className='masonry'>
      <div className='masonry-container'>
        <div className='masonry-wrapper' style={{ height: '800px' }}>
          <Placeholder as='layout' />
        </div>
      </div>
      <div className='masonry-container'>
        <div className='masonry-wrapper' style={{ height: '800px' }}>
          <Placeholder as='layout' />
        </div>
      </div>
      <div className='masonry-container'>
        <div className='masonry-wrapper' style={{ height: '800px' }}>
          <Placeholder as='layout' />
        </div>
      </div>
    </div>
  );

  const toggleLogToFile = () => {
    const callbackFn = (data: IStreamItem) => {
      if (data.success) {
        notifyDispatch({
          type: EnumNotify.ADD_NOTIFICATION,
          payload: {
            type: 'Success',
            message: data.logToFile
              ? t('SETTINGS.REPORTING.DEBUG_LOGGING.enabledMsg')
              : t('SETTINGS.REPORTING.DEBUG_LOGGING.disabledMsg')
          }
        });
        setStream(data);
      }
    };
    if (stream.logToFile) {
      streamLogDisable(stream).then(callbackFn);
    } else {
      streamLogEnable(stream).then(callbackFn);
    }
  };
  return (
    <StreamingPage fadeIn={false}>
      <Page classNames='stream-stats'>
        <Link className='go-back link' onClick={() => navigate(-1)}>
          {t('STREAMING.STREAMS.REPORT.goBack')}
        </Link>
        <div className='separator' />
        <header className='header'>
          <Heading4Med>{t('STREAMING.STREAMS.REPORT.statistics')}</Heading4Med>
          <div className='buttons'>
            {/* <Button variant='secondary' onClick={csvDownload}>
              {t('STREAMING.STREAMS.REPORT.downloadCsv')}
            </Button> */}
            {debugLoggingEnabled && stream.mode !== 'iorouter' && (
              <Button variant='secondary' onClick={toggleLogToFile}>
                {t('STREAMING.STREAMS.REPORT.enableLog', {
                  enabled: stream.logToFile ? t('disable') : t('enable')
                })}
              </Button>
            )}
            <Button variant='primary' onClick={reset}>
              {t('STREAMING.STREAMS.REPORT.resetStats')}
            </Button>
          </div>
        </header>
        <FadeIn suspenseComponent={placeholderHeader} visible={!!inputsClean && !!transcodersClean}>
          <StatsHeaderPanel
            data={formattedGraphHistory}
            title={stream?.name}
            status={{ icon: streamInfo.icon, color: getPanelColor(streamInfo.actionType) }}
            thumbnail={streamInfo.thumbnail}
            onTimescaleClick={onTimescaleClick}
            timescale={timescale}
          />
        </FadeIn>
        <FadeIn
          suspenseComponent={placeholderMasonry}
          visible={!!inputsClean && !!transcodersClean && !!sections.length}
        >
          <Masonry
            sections={sections}
            renderSection={renderSection}
            id={'stream-stats-masonry'}
            useFsMask={true}
          />
        </FadeIn>
      </Page>
    </StreamingPage>
  );
};

export default StreamStatsDetail;
