export const servicesEn = {
  analyticsShare: 'Share Product Analytics Enabled',
  analyticsDontShare: 'Share Product Analytics Disabled',
  all: 'All',
  analog: 'Analog Capture',
  capture: 'Capture',
  general: 'General',
  gigE: 'GigE Capture',
  interfaces: 'Webserver Interfaces',
  interfacesAll: 'All Interfaces',
  ndi: 'NDI Capture',
  productAnalytics: 'PRODUCT ANALYTICS',
  enableProductAnalytics: 'Enable Anonymous Product Analytics',
  privacyPolicy: 'privacy policy',
  productAnalyticsInfo:
    'To help us build better products, we collect anonymous data from this product for analytics purposes. \
    For more information, please review our <0>{{- link}}</0>.',
  recording: 'Recording',
  rtsp: 'RTSP Server',
  rtspInfo:
    'Warning: Active Streams using RTSP PULL Kraken outputs require a manual restart following an RTSP Port change.',
  rtspUpdated: 'RTSP Port Updated',
  sdi: 'SDI Capture',
  services: 'Services',
  serviceWarning: 'Service {{name}} is {{status}}, but startup is {{isStartup}}on',
  ssh: 'SSH',
  st2110: 'ST2110 Capture',
  started: '{{name}} service started successfully',
  started_other: '{{count}} services started successfully',

  status: 'Status',
  stopped: '{{name}} service stopped successfully',
  stopped_other: '{{count}} services stopped successfully',

  webserver: 'Webserver',
  webserverUpdated: 'Webserver interfaces updated.',
  webserverUpdateFailed: 'Webserver interfaces failed to update.',
  webserverError: 'You must specify at least one interface.',
  warning:
    'Changing the interfaces used for the webserver could impact your access. ' +
    'Changing Network IP Address will impact your access. ' +
    'These settings are not preserved across software updates and need to be reapplied.'
};
