import { Header } from '@hai/ui-react';
import React from 'react';
import './sectionHeader.scss';

interface IProps {
  title: string;
}

const SectionHeader: React.FunctionComponent<IProps> = ({ title }) => {
  return <Header className='panel-header' type='panel-header' title={title} />;
};

export default SectionHeader;
