import { List } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { IMetadataItem } from 'api/metadata/metadata.type';
import { IStreamItem } from 'api/streaming/streaming.type';
import { StreamTable } from 'components/common/streamTable/StreamTable';
import { EditMetadata } from 'components/streaming/metadata/EditMetadata';
import { EnumMetadatasAction } from 'context/streaming/metadatas';
import { StreamingContext } from 'context/streaming/streaming';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import constant from 'utils/constant';
import { buildDetails, formatMetadataMap } from 'utils/formatText';
import { useGetStreams } from 'utils/hooks/useGetStreams';

enum EnumActions {
  EDIT = 'edit',
  STREAMS = 'streams',
  NONE = 'none'
}

interface IProps {
  item: IMetadataItem;
  saveCallback: Function;
}
const MetadataItem = (props: IProps) => {
  const { item, saveCallback } = props;
  const { streamingDispatch, streamsClean } = useContext(StreamingContext);

  const [openedTab, setOpenedTab] = useState<EnumActions>(EnumActions.NONE);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const streamList = streamsClean?.filter(
    (stream: IStreamItem) => stream.metadatas?.indexOf(item.uuid) !== -1
  );
  const [buttonState, setButtonState] = useState<ButtonStateType>('idle');

  const [killContentSlow, setKillContentSlow] = useState(false);

  useEffect(() => {
    setTimeout(() => setKillContentSlow(false), constant.timeout.panelTransition);
  }, [isOpen]);

  const cancel = () => {
    setOpenedTab(EnumActions.NONE);
    setIsOpen(false);
  };

  const onSelectHandler = useCallback(
    (eventKey: EnumActions, expanded: boolean) => {
      if (isOpen && expanded) {
        setIsOpen(false);
        setTimeout(() => {
          setOpenedTab(eventKey);
          setIsOpen(expanded);
        }, 100);
      } else {
        setOpenedTab(eventKey);
        setIsOpen(expanded);
      }
    },
    [isOpen]
  );

  const getStreamsService = useGetStreams();

  useEffect(() => {
    if (!streamsClean) {
      getStreamsService(() => {});
    }
  }, [getStreamsService, streamsClean]);

  const getExpandContent = useCallback(() => {
    if (!item || killContentSlow) {
      // clear states in tab
      return null;
    }

    const onSettingsApply = () => {
      saveCallback();
      setIsOpen(false);
      setButtonState('idle');
      setOpenedTab(EnumActions.NONE);
    };

    if (openedTab === EnumActions.STREAMS) {
      return <StreamTable streams={streamList} />;
    }

    if (openedTab === EnumActions.EDIT) {
      return (
        <EditMetadata
          item={item}
          cancel={cancel}
          saveCallback={onSettingsApply}
          setButtonState={setButtonState}
          buttonState={buttonState}
        />
      );
    }

    return null;
  }, [buttonState, item, killContentSlow, openedTab, saveCallback, streamList]);

  const toggleSelection = (selected: boolean): void => {
    streamingDispatch({
      type: EnumMetadatasAction.SELECT_METADATA,
      payload: {
        id: item.uuid,
        selected: selected
      }
    });
  };

  return (
    <List.Panel
      checked={item.selected}
      onClick={() => {
        onSelectHandler(isOpen ? openedTab : EnumActions.EDIT, !isOpen);
      }}
      onSelect={toggleSelection}
      expandedState={{
        expanded: isOpen,
        eventKey: openedTab
      }}
      key={`input-panel-${item.uuid}`}
    >
      <List.PanelTitle title={item.name} useFsMask={true} />
      {buildDetails({
        item,
        type: formatMetadataMap,
        details: ['method', 'idEnabled', 'security']
      })}
      <List.Actions>
        <List.ActionItem
          expandButton={true}
          eventKey={EnumActions.STREAMS}
          icon={'StreamsOut'}
          title={'stats'}
          disabled={!streamList?.length}
          onSelect={(eventKey: string, expanded?: boolean) =>
            onSelectHandler(eventKey as EnumActions, expanded!)
          }
        ></List.ActionItem>

        <List.ActionItem
          expandButton={true}
          eventKey={EnumActions.EDIT}
          icon={'Settings'}
          title={'settings'}
          onSelect={(eventKey: string, expanded?: boolean) =>
            onSelectHandler(eventKey as EnumActions, expanded!)
          }
        />
      </List.Actions>
      <List.ExpandedPanel>{getExpandContent()}</List.ExpandedPanel>
    </List.Panel>
  );
};

export default MetadataItem;
