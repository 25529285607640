enum EnumPropertyNormalizerType {
  SNAKETOCAMEL = 'snake to camel',
  CAMELTOSNAKE = 'camel to snake'
}

type IProperty = any;

const isCamel = (str: string) => {
  return /^([a-z0-9]+)(([A-Z]([a-z]+))+)$/.test(str);
};

const isUppercase = (str: string) => {
  return /^([^a-z]+)$/.test(str);
};

const snakeToCamel = (str: string) => {
  if (isCamel(str)) {
    return str;
  }
  // Don't convert something that is fully uppercase
  if (isUppercase(str)) {
    return str;
  }

  return str
    .toLowerCase()
    .replace(/([-_][a-z0-9])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));
};

const camelToSnake = (str: string) => {
  return str.replace(/[A-Z]/g, m => '_' + m.toLowerCase());
};

const propertyNormalizer: IProperty = (src: IProperty, type: EnumPropertyNormalizerType) => {
  if (!src || typeof src !== 'object') {
    return src;
  }

  if (src instanceof Array) {
    return src.map((item: IProperty) => propertyNormalizer(item, type));
  }

  if (src instanceof Object) {
    return Object.keys(src).reduce((newObject: IProperty, propertyName: string) => {
      let name = '';
      if (type === EnumPropertyNormalizerType.SNAKETOCAMEL) {
        name = snakeToCamel(propertyName);
      } else if (type === EnumPropertyNormalizerType.CAMELTOSNAKE) {
        name = camelToSnake(propertyName);
      }
      newObject[name] = propertyNormalizer(src[propertyName], type);

      return newObject;
    }, {});
  }

  throw new Error(`Unable to copy. ${src} isn't supported.`);
};

export const normalizeResponse: IProperty = (src: IProperty) => {
  return propertyNormalizer(src, EnumPropertyNormalizerType.SNAKETOCAMEL);
};

export const normalizeRequest: IProperty = (src: IProperty) => {
  if (src instanceof FormData) {
    return src;
  }
  return propertyNormalizer(src, EnumPropertyNormalizerType.CAMELTOSNAKE);
};
