import { t } from 'i18next';
import { isIpV4, isIpV6 } from 'utils/validations';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required(t('STREAMING.REQUIRED.nameRequired') as string),
  maxAircraftSpiDeltaMs: Yup.number().when(['method', 'format'], {
    is: (method: string, format: string) =>
      format === 'cot' && ['udp', 'serial'].indexOf(method) !== -1,
    then: Yup.number()
      .required(
        t<string>('VALIDATION.required', {
          item: t('STREAMING.METADATA.ADD.maxAircraftSpiDeltaMs')
        })
      )
      .typeError(t<string>('VALIDATION.between', { x: 0, y: 1000 }))
      .integer(t<string>('VALIDATION.between', { x: 0, y: 1000 }))
      .min(0, t<string>('VALIDATION.between', { x: 0, y: 1000 }))
      .max(1000, t<string>('VALIDATION.between', { x: 0, y: 1000 }))
  }),
  ipPort: Yup.number().when(['method', 'format'], {
    is: (method: string) => ['udp'].indexOf(method) !== -1,
    then: Yup.number()
      .integer()
      .typeError(t('STREAMING.REQUIRED.notNumber') as string)
      .required(
        t<string>('VALIDATION.required', {
          item: t('port')
        })
      )
      .min(1025, t('STREAMING.REQUIRED.port') as string)
      .max(65535, t('STREAMING.REQUIRED.port') as string)
  }),
  ipAddress: Yup.string().when(['method', 'type'], {
    is: (method: string, type: string) => method === 'udp' && type === 'multicast',
    then: Yup.string()
      .required(
        t<string>('VALIDATION.required', {
          item: t('STREAMING.METADATA.ipAddress')
        })
      )
      .test(
        'ipv4',
        t<string>('VALIDATION.invalid', { item: t('STREAMING.METADATA.ipAddress') }),
        value => {
          return isIpV4(value) || isIpV6(value);
        }
      )
  }),

  classifyingCountry: Yup.string().when('enableSecurityDataSetInsertion', {
    is: (enableSecurityDataSetInsertion: boolean) => enableSecurityDataSetInsertion === true,
    then: Yup.string()
      .required(
        t<string>('VALIDATION.required', {
          item: t('STREAMING.METADATA.ADD.classifyingCountry')
        })
      )
      .test('Alpha Only', t<string>('STREAMING.METADATA.nonAlpha'), classifyingCountry => {
        return /^[a-zA-Z]*$/.test(classifyingCountry || '');
      })
  }),
  objectCountryCodes: Yup.string().when('enableSecurityDataSetInsertion', {
    is: (enableSecurityDataSetInsertion: boolean) => enableSecurityDataSetInsertion === true,
    then: Yup.string()
      .required(
        t<string>('VALIDATION.required', {
          item: t('STREAMING.METADATA.ADD.objectCountryCodes')
        })
      )
      // GENC two-letter, three-letter, three-digit numeric or administrative subdivisions.
      // Max 6 characters for each code. Max 6 codes separated by 5 ";".
      .min(2, t<string>('VALIDATION.betweenString', { x: 2, y: 41 }))
      .max(41, t<string>('VALIDATION.betweenString', { x: 2, y: 41 }))
      .test('Alpha Only', t<string>('STREAMING.METADATA.objectCountryCode'), objectCountryCodes => {
        return /^[a-zA-Z0-9-]{2,6}(;[a-zA-Z0-9-]{2,6}){0,5}$/.test(objectCountryCodes || '');
      })
  })
});
