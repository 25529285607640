import { IGeneralApiProps } from 'api/global.type';
import client, { disableNormalizingConfig } from 'api/utils/client';
import { IPolicy } from 'context/security/policies';

export const getSecurity = (props?: IGeneralApiProps) => {
  return client
    .get('/system/security')
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return [];
    });
};

interface IUpdateSecurity extends IGeneralApiProps {
  item: IPolicy;
}

export const updateSecurity = (props: IUpdateSecurity) => {
  const { item, onError } = props;

  return client
    .put('/system/security', item, disableNormalizingConfig)
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      return [];
    });
};
