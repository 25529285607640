import { Form } from '@hai/ui-react';
import { IAccount, getAccounts, updateAccount } from 'api/accounts/account';
import { EnumFormButtonState, FormButton } from 'components/common/form/formButton/formButton';
import { FormContainer, FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { buildFormSelect } from 'components/common/form/formSelect/formSelect';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import { accountSettingOptions } from 'components/security/accounts/AddAccount';
import {
  editAccountSchema,
  editCurrentAccountSchema
} from 'components/security/accounts/validations';
import { GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumAccountsAction } from 'context/security/accounts';
import { SecurityContext } from 'context/security/security';
import { t } from 'i18next';
import { RoleContext } from 'permissions/role/Roles';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useFormRef } from 'utils/hooks/useFormRef';

interface IProps {
  buttonState: EnumFormButtonState;
  setButtonState: Dispatch<SetStateAction<EnumFormButtonState>>;
  item: IAccount;
  onSuccess: () => void;
  isCurrentUser: boolean;
}

export const EditAccount = (props: IProps) => {
  const { setButtonState, buttonState, item, onSuccess, isCurrentUser } = props;

  const formControl = useFormRef();
  const { formRef, formRefCallback, formSubmitActive } = formControl;
  const { securityDispatch } = useContext(SecurityContext);
  const { dispatch } = useContext(NotifyContext);
  const role = useContext(RoleContext);
  const { screen } = useContext(GlobalContext);
  const columns = screen.columns.main;

  const [formData, setFormData] = useState({
    password: '',
    confirmPwd: '',
    currentPassword: '',
    role: item.role
  });

  const onChangeHandler = (evt: any) => {
    const { name, value } = evt.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const notifyDispatch = (res: { data: any; message: string }) => {
    const successDispatch = () => {
      dispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: t('SECURITY.ACCOUNTS.MESSAGES.updated', { name: item.username })
        }
      });
    };
    const errorDispatch = () => {
      dispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Error',
          message: t(`SECURITY.ACCOUNTS.MESSAGES.${res.message}`)
        }
      });
    };
    setButtonState(EnumFormButtonState.IDLE);
    if (res.data) {
      onSuccess();
      successDispatch();
    } else if (res.message) {
      errorDispatch();
    }
  };

  const onSave = async (values: any) => {
    setButtonState(EnumFormButtonState.PENDING);
    const account = {
      id: item.id,
      username: item.username,
      password: values.password,
      currentPassword: values.currentPassword,
      confirmPassword: values.confirmPwd,
      role: values.role,
      state: item.state
    };
    const res = await updateAccount({ item: account });
    if (res) {
      if (role.can('call', 'api/accounts')) {
        getAccounts().then((value: IAccount[]) => {
          value &&
            securityDispatch({ type: EnumAccountsAction.SET_ACCOUNTS_CLEAN, payload: value });
          setButtonState(EnumFormButtonState.IDLE);
          notifyDispatch(res);
        });
      } else {
        setButtonState(EnumFormButtonState.IDLE);
        notifyDispatch(res);
      }
      if (res?.data) {
        formRef.current.setFieldValue('currentPassword', '');
        formRef.current.setFieldValue('confirmPwd', '');
        formRef.current.setFieldValue('password', '');
        formRef.current.validateForm();
      }
    }
  };

  const formProps = {
    defaultValidation: true,
    handleSubmit: onSave,
    initialValues: {
      role: item.role,
      currentPassword: '',
      password: '',
      confirmPwd: ''
    },
    restValidationProps: {
      validationSchema: isCurrentUser ? editCurrentAccountSchema : editAccountSchema,
      enableReinitialize: true,
      innerRef: formRefCallback
    }
  };

  return (
    <Form
      {...formProps}
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <FormContainer columns={columns}>
        <FormInput
          label={t('SECURITY.ACCOUNTS.FORMS.username')}
          name='name'
          viewOnly
          defaultValue={item.username}
          dataAuto='edit_account_username'
        />
        <FormButton
          columns={columns}
          state={buttonState}
          submitHandler={() => submitHandler(formRef)}
          disabled={!formSubmitActive}
        />
      </FormContainer>
      <FormSectionDark title={t('SECURITY.ACCOUNTS.FORMS.settings')}>
        <FormContainer columns={columns}>
          {buildFormSelect({
            label: t('SECURITY.ACCOUNTS.FORMS.role'),
            items: accountSettingOptions,
            selectName: 'role',
            selectedId: item.role,
            defaultValue: item.role,
            onChange: onChangeHandler,
            disabled: isCurrentUser,
            dataAuto: 'edit_account_role'
          })}
          {isCurrentUser && (
            <FormInput
              label={t('SECURITY.ACCOUNTS.FORMS.currentPwd')}
              name='currentPassword'
              defaultValue={''}
              pwdToggle={true}
              inputType={'password'}
              onChange={onChangeHandler}
              required={isCurrentUser}
              dataAuto='edit_account_current_pass'
            />
          )}
          <FormInput
            label={t('SECURITY.ACCOUNTS.FORMS.newPassword')}
            name='password'
            defaultValue={''}
            pwdToggle={true}
            inputType={'password'}
            onChange={onChangeHandler}
            required={isCurrentUser}
            dataAuto='edit_account_new_pass'
          />
          <FormInput
            label={t('SECURITY.ACCOUNTS.FORMS.confirmPwd')}
            name='confirmPwd'
            defaultValue={''}
            pwdToggle={true}
            inputType={'password'}
            onChange={onChangeHandler}
            required={isCurrentUser}
            dataAuto='edit_account_confirm_pass'
          />
        </FormContainer>
      </FormSectionDark>
    </Form>
  );
};
