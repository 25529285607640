import './dashboard.scss';

import { Graph, PanelGroup, Placeholder, ProgressRing, colorValue } from '@hai/ui-react';
import { IDashboardStat } from 'api/dashboard/dashboard.type';
import { getLicense } from 'api/license/license';
import { getSystemInfo, getSystemMetrics } from 'api/settings/system';
import { ILicenseSettings, ISystemInfo, ISystemMetrics } from 'api/settings/system.type';
import { getStreamSnapshots } from 'api/streaming/streaming';
import { EnumSnapshotTapPoint, EnumStreamSubStatus } from 'api/streaming/streaming.type';
import { getSystemBandwidth } from 'api/system/network';
import { colors } from 'assets/colors';
import { Card } from 'components/card/Card';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import PageHeader from 'components/common/page/PageHeader';
import { TextLabel } from 'components/common/text/TextLabel';
import { DashboardPanelItem } from 'components/dashboard/DashboardPanelItem';
import NoContent from 'components/noItems/noContent';
import { DashboardContext } from 'context/dashboard';
import { GlobalContext } from 'context/global';
import { EnumLicensesAction } from 'context/settings/licenses';
import { SettingsContext } from 'context/settings/settings';
import { EnumStatusAction } from 'context/settings/status';
import { StreamingContext } from 'context/streaming/streaming';
import { EnumStreamsAction } from 'context/streaming/streams';
import { t } from 'i18next';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';
import constant from 'utils/constant';
import { uptimeHHMMSS } from 'utils/formatText';
import { useGetDashboardStats } from 'utils/hooks/useGetDashboardStats';
import { EnumStreamStateString } from 'utils/hooks/useStreamInfo';
import { useService } from 'utils/useService';

const Dashboard = () => {
  const { settingsDispatch, systemInfo, systemMetrics, license, systemBandwidth } =
    useContext(SettingsContext);
  const { screen } = useContext(GlobalContext);

  const { streamingDispatch } = useContext(StreamingContext);

  const { dashboardStats } = useContext(DashboardContext);

  const getDashboardStatsService = useGetDashboardStats();
  const getInputSnapshotsService = useService(getStreamSnapshots);

  const [isLoading, setIsLoading] = useState(true);
  const [streamsOnPage, _setStreamsOnPage] = useState(6);

  const refreshDashboardStatsInfo = useCallback(() => {
    return getDashboardStatsService();
  }, [getDashboardStatsService]);

  const overallBandwidthRef = useRef<any>(null);

  const [dashboardStreams, setDashboardStreams] = useState<any[] | null>(null);

  const refreshSystemBandwidth = useCallback(() => {
    const end = new Date();
    const start = new Date().setMinutes(end.getMinutes() - 5);
    getSystemBandwidth({ start: start, end: end.getTime(), ignoreError: [404] }).then(value => {
      value && settingsDispatch({ type: EnumStatusAction.SET_SYSTEM_BANDWIDTH, payload: value });
    });
  }, [settingsDispatch]);

  const refreshSystemInfo = useCallback(
    () =>
      getSystemInfo().then((value: ISystemInfo) => {
        return (
          value && settingsDispatch({ type: EnumStatusAction.SET_SYSTEM_INFO, payload: value })
        );
      }),
    [settingsDispatch]
  );

  const refreshSystemSettings = useCallback(
    () =>
      getLicense().then(
        (value: ILicenseSettings) =>
          value && settingsDispatch({ type: EnumLicensesAction.SET_LICENSE, payload: value })
      ),
    [settingsDispatch]
  );

  const refreshSystemMetrics = useCallback(
    () =>
      getSystemMetrics().then(
        (value: ISystemMetrics) =>
          value && settingsDispatch({ type: EnumStatusAction.SET_SYSTEM_METRICS, payload: value })
      ),
    [settingsDispatch]
  );

  const loadSnapshots = useCallback(
    () =>
      getInputSnapshotsService({ tappoint: EnumSnapshotTapPoint.INPUT }).then((value: any) => {
        if (value) {
          return streamingDispatch({
            type: EnumStreamsAction.SET_STREAM_SNAPSHOTS,
            payload: value
          });
        } else {
          // There was an error fetching snapshots. Possible cors error.
          return streamingDispatch({
            type: EnumStreamsAction.SET_STREAM_SNAPSHOTS,
            payload: []
          });
        }
      }),
    [streamingDispatch, getInputSnapshotsService]
  );

  const fetchContent = useCallback(() => {
    return Promise.all([
      refreshSystemMetrics(),
      refreshSystemBandwidth(),
      refreshDashboardStatsInfo(),
      loadSnapshots()
    ]);
  }, [refreshSystemMetrics, refreshSystemBandwidth, refreshDashboardStatsInfo, loadSnapshots]);

  useInterval(() => {
    fetchContent();
  }, constant.interval.systemMetrics);

  useInterval(() => {
    refreshSystemSettings();
  }, constant.interval.systemLicense);

  const getRingColor = (ringPercent: number) => {
    if (ringPercent < 85) {
      return colors.ringGreen;
    }
    if (ringPercent <= 95) {
      return colorValue('haiui-amber-01');
    }
    return colorValue('haiui-red-01');
  };

  const sortByType = useCallback((dashboardStats: IDashboardStat[] | null) => {
    if (!dashboardStats) {
      return null;
    } else if (dashboardStats.length === 0) {
      return [];
    }
    const streams = [...dashboardStats];
    const sortedStreams = streams
      .filter(
        i =>
          [EnumStreamStateString.STREAMING, EnumStreamStateString.WAITING].indexOf(i.state) !== -1
      )
      .sort((a, b) => {
        if (
          a.subStatus === EnumStreamSubStatus.CONNECTED &&
          b.subStatus === EnumStreamSubStatus.CONNECTED
        ) {
          return a.streamName < b.streamName ? -1 : 1;
        }
        if (
          a.subStatus === EnumStreamSubStatus.CONNECTED ||
          b.subStatus === EnumStreamSubStatus.CONNECTED
        ) {
          return a.subStatus === EnumStreamSubStatus.CONNECTED ? 1 : -1;
        }
        return a.streamName < b.streamName ? -1 : 1;
      });

    return sortedStreams;
  }, []);

  const getMaxStreams = ({
    amount = 6,
    sortedStreams
  }: {
    amount: number;
    sortedStreams: any[];
  }) => {
    return sortedStreams?.splice(0, amount);
  };

  useEffect(() => {
    if (!systemInfo) {
      refreshSystemInfo();
    }
  }, [refreshSystemInfo, systemInfo]);

  useEffect(() => {
    const sorted = sortByType(dashboardStats);
    if (sorted) {
      setDashboardStreams(getMaxStreams({ sortedStreams: sorted, amount: streamsOnPage }));
    }
  }, [dashboardStats, sortByType, streamsOnPage]);

  useEffect(() => {
    (async () => {
      if (isLoading) {
        await fetchContent();
        setIsLoading(false);
      }
    })();
  }, [fetchContent, isLoading, sortByType, streamsOnPage]);

  const buildPlaceholder = () => {
    const obj = [];
    if (isLoading || dashboardStreams === null) {
      for (let x = 0; x < streamsOnPage; x++) {
        obj.push(<Placeholder key={`placeholder-${x}`} as='layout' style={{ height: `300px` }} />);
      }
      return <div className='grid-streams'>{obj}</div>;
    }
    return null;
  };
  const [graphWidth, setGraphWidth] = useState<null | number>(null);

  const resize = useCallback(() => {
    if (overallBandwidthRef.current) {
      // If the parent does not exist (can happen on first render), set it to something safe'ish.
      setGraphWidth(
        overallBandwidthRef.current?.parentElement?.offsetWidth - 20 ||
          (screen.width - (screen.collapsed ? 0 : 55)) / 3
      );
    }
  }, [screen.collapsed, screen.width]);

  useEffect(() => {
    // Shrink the graph quick to prevent overflow
    setGraphWidth(
      overallBandwidthRef.current?.parentElement?.offsetWidth - 100 ||
        (screen.width - (screen.collapsed ? 0 : 100)) / 3
    );

    // adjust the graph to the correct size waiting for  render.
    setTimeout(resize, 250);
  }, [resize, screen]);

  // Large delay for first time render.
  useEffect(() => {
    setTimeout(resize, 500);
  });

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [resize]);

  const lineGraph = {
    left: {
      data: systemBandwidth as any,
      colors: [colorValue('haiui-aqua-01'), colorValue('haiui-purple-01')],
      tickFormat: (i: number) => `${Math.round(i * 10) / 10}Mb`,
      labelStyle: {
        fontSize: 10
      }
    },
    xDomain: [0, 5],

    fields: {
      x: t('STREAMING.STREAMS.time'),
      y: {
        left: [t('DASHBOARD.overallOutput'), t('DASHBOARD.overallInput')]
      }
    },

    tickLabelStyle: {
      fontSize: 12
    },
    paddings: {
      top: 18,
      bottom: 25,
      left: 60,
      right: 25
    },
    tooltipStyle: { background: 'red' },
    xTickFormat: (i: number) => `${i}m`
  };

  const showBypass = (license: ILicenseSettings | null) => {
    if (license?.maxBypassMode === 2) {
      return false;
    } else if (license?.maxBypassMode === 0 && license?.maxBypass === 0) {
      return false;
    }
    return true;
  };
  /**
   * Rounds a percentage string to the nearest whole number.
   *
   * @param {string | undefined} formattedNumber - The percentage string to round, e.g., "50.5%".
   * @returns {string} The rounded percentage as a whole number string, or an empty string if undefined.
   * Note: TODO: KRAK-5156 Remove When move to API V3
   */
  const roundTextPercentage = (formattedNumber: string | undefined) => {
    // If kraken just started load is empty, we show 0%
    return formattedNumber === undefined || formattedNumber === ''
      ? '0%'
      : `${Math.round(parseFloat(formattedNumber.replace('%', '')))}%`;
  };

  const navigate = useNavigate();

  const navToStreams = () => navigate('/streaming/streams');
  return (
    <div className='dashboard tab-page-container'>
      <PageHeader
        loading={isLoading}
        title={t('ROUTES.dashboard')}
        withPresets={true}
        iconName='Dashboard'
      />
      <div className='card-stats-row'>
        <Card
          placeholderHeight={150}
          loading={isLoading}
          className='card-graph'
          dataAuto='bandwidth_graph_card'
          title={t('DASHBOARD.overallBandwidth')}
        >
          <div className='dash-card' ref={overallBandwidthRef}>
            {systemBandwidth ? (
              <Graph
                lineGraph={lineGraph}
                xAxisReverse
                hideLegend
                // @ts-ignore
                dimensions={graphWidth !== null ? { h: 125, w: graphWidth } : { h: 125 }}
                numTicks={{ x: 6, y: 4 }}
              />
            ) : null}
          </div>
        </Card>
        <Card
          placeholderHeight={150}
          loading={isLoading}
          className='card-progress-wheel'
          title={t('DASHBOARD.resources')}
          dataAuto='resources_usage_card'
        >
          <div className='dash-card'>
            {/* {systemMetrics?.cpuload ? ( */}
            <ProgressRing
              label={t('DASHBOARD.cpu')}
              currentPercentage={Math.round(systemMetrics?.cpuload || 0)}
              currentPercentColor={getRingColor(systemMetrics?.cpuload || 0)}
              data-auto='cpu_usage_ring'
            />
            {/* ) : null} */}
          </div>
          <div className='dash-card'>
            <ProgressRing
              label={t('DASHBOARD.memory')}
              currentPercentage={Math.round(systemMetrics?.memload ?? 0)}
              currentPercentColor={getRingColor(Math.round(systemMetrics?.memload ?? 0))}
              data-auto='memory_usage_ring'
            />
          </div>
        </Card>
        <Card
          placeholderHeight={150}
          loading={isLoading}
          title={t('DASHBOARD.capacity')}
          dataAuto='capacity_card'
        >
          <div className='dash-card'>
            <ProgressRing
              {...(license?.maxEncoders === 0 ? { icon: 'CustomStreamsOff' } : {})}
              label={t('DASHBOARD.streams')}
              {...(license?.maxEncoders !== 0
                ? { textValue: license?.activeTranscoderSessions?.toString() || '0' }
                : {})}
              hideCurrentPercentLabel
              currentPercentColor={colors.ringGreen}
              currentPercentage={Math.round(
                (license &&
                  license?.maxEncoders &&
                  (license?.activeTranscoderSessions / license?.maxEncoders) * 100) ??
                  0
              )}
              data-auto='streams_ring'
            />
          </div>
          {showBypass(license) && (
            <div className='dash-card'>
              <ProgressRing
                {...(license?.maxBypassMode === 1
                  ? { icon: { iconname: 'Infinite', color: 'haiui-green-01', size: 'lg' } }
                  : {})}
                label={t('DASHBOARD.bypass')}
                hideCurrentPercentLabel
                {...(license?.maxBypassMode !== 1
                  ? { textValue: license?.activeBypassSessions?.toString() || '0' }
                  : {})}
                currentPercentage={
                  license?.maxBypassMode === 1
                    ? 0
                    : Math.round(
                        (license && (license?.activeBypassSessions / license?.maxBypass) * 100) ?? 0
                      )
                }
                currentPercentColor={colors.ringGreen}
                data-auto='bypass_ring'
              />
            </div>
          )}
        </Card>
        <Card
          placeholderHeight={150}
          loading={isLoading}
          title={t('DASHBOARD.deviceStatus')}
          dataAuto='device_status_card'
        >
          <div className='dash-card stats-card'>
            <div>
              <TextLabel
                label={t('DASHBOARD.version')}
                className='dashboardLargeView'
                info={systemInfo?.version}
                dataAuto='kraken_version'
              />
              <TextLabel
                label={t('DASHBOARD.uptime')}
                info={uptimeHHMMSS(systemMetrics?.uptime)}
                dataAuto='uptime'
              />
              <TextLabel
                label={t('DASHBOARD.load')}
                info={roundTextPercentage(license?.load)}
                dataAuto='stream_load'
              />
              <TextLabel
                className='dashboardLargeView'
                label={t('DASHBOARD.activeTranscoderSessions')}
                info={license?.activeTranscoderSessions?.toString()}
                dataAuto='active_transcoders'
              />
            </div>
          </div>
        </Card>
      </div>
      {buildPlaceholder()}
      <FadeIn visible={!isLoading}>
        {dashboardStreams !== null && dashboardStreams?.length === 0 && (
          <NoContent
            buttonText={t('STREAMING.STREAMS.NO_STREAMS.go')}
            buttonAction={navToStreams}
            detailsText={t('STREAMING.STREAMS.NO_STREAMS.desc')}
            iconName='Streams'
            noItemMsg={t('STREAMING.STREAMS.NO_STREAMS.title')}
          />
        )}
        {dashboardStreams?.length !== 0 && (
          <PanelGroup className='grid-streams' data-auto='dashboard_panel_group'>
            {dashboardStreams?.map(item => {
              return <DashboardPanelItem key={`dash-stream-${item.streamId}`} item={item} />;
            })}
          </PanelGroup>
        )}
      </FadeIn>
    </div>
  );
};

export default Dashboard;
