import { IArchive } from 'api/settings/archiveLocations';

export interface IArchivesState {
  archives: IArchive[] | null;
  archivesClean: IArchive[] | null;
  updatingArchives: IArchive[];
}

export enum EnumArchivesAction {
  SET_ARCHIVES = 'SET_ARCHIVES',
  SELECT_ARCHIVES = 'SELECT_ARCHIVES',
  UPDATE_ARCHIVE = 'UPDATE_ARCHIVE',
  SET_ARCHIVES_CLEAN = 'SET_ARCHIVES_CLEAN',
  SELECT_ALL = 'SELECT_ALL_ARCHIVES',
  ADD_UPDATING_ARCHIVE = 'ADD_UPDATING_ARCHIVE',
  REMOVE_UPDATING_ARCHIVE = 'REMOVE_UPDATING_ARCHIVE',
  RESET = 'RESET'
}

export interface IArchivesAction {
  type: EnumArchivesAction;
  payload: any;
}

// Init states
export const initArchivesState: IArchivesState = {
  archives: null,
  archivesClean: null,
  updatingArchives: []
};

// Reducer
export const archivesReducer = (state: IArchivesState, action: IArchivesAction): IArchivesState => {
  switch (action.type) {
    case EnumArchivesAction.SET_ARCHIVES_CLEAN: {
      const newArchives = action.payload?.map((archive: IArchive) => {
        const selected = state.archivesClean?.find(i => i.uuid === archive.uuid)?.selected;
        const newItem = archive;
        newItem.selected = selected;
        return newItem;
      });
      return {
        ...state,
        archivesClean: newArchives,
        archives: newArchives
      };
    }
    case EnumArchivesAction.SET_ARCHIVES: {
      return {
        ...state,
        archives: action.payload
      };
    }
    case EnumArchivesAction.SELECT_ARCHIVES: {
      const { id, selected } = action.payload;
      const newArchives = state.archives?.map((i: IArchive) => {
        const newItem = i;
        if (i.uuid === id) {
          newItem.selected = selected;
        }
        return newItem;
      });
      const newArchivesClean = state.archivesClean?.map((i: IArchive) => {
        const newItem = i;
        if (i.uuid === id) {
          newItem.selected = selected;
        }
        return newItem;
      });
      return {
        ...state,
        archivesClean: newArchivesClean || null,
        archives: newArchives || null
      };
    }
    case EnumArchivesAction.SELECT_ALL: {
      const selected = action.payload;
      const newArchives = state.archives?.map((i: IArchive) => {
        const newItem = i;
        newItem.selected = selected;
        return newItem;
      });
      const newArchivesClean = state.archivesClean?.map((i: IArchive) => {
        const newItem = i;
        newItem.selected = selected;
        return newItem;
      });
      return {
        ...state,
        archives: newArchives || [],
        archivesClean: newArchivesClean || []
      };
    }
    case EnumArchivesAction.UPDATE_ARCHIVE: {
      const item: IArchive = action.payload;
      const newArchives = state.archives?.map((i: IArchive) => {
        if (item.uuid === i.uuid) {
          return item;
        } else {
          return i;
        }
      });
      const newArchivesClean = state.archivesClean?.map((i: IArchive) => {
        if (item.uuid === i.uuid) {
          return item;
        } else {
          return i;
        }
      });
      return {
        ...state,
        archives: newArchives || [],
        archivesClean: newArchivesClean || []
      };
    }
    case EnumArchivesAction.ADD_UPDATING_ARCHIVE: {
      const newArchives = [...state.updatingArchives];
      if (!state.updatingArchives?.find(stream => stream.uuid === action.payload.uuid)) {
        newArchives?.push(action.payload);
      }
      return {
        ...state,
        updatingArchives: newArchives || []
      };
    }
    case EnumArchivesAction.REMOVE_UPDATING_ARCHIVE: {
      const newArchives = state.updatingArchives?.filter(
        (archive: IArchive) => archive.uuid !== action.payload
      );
      return {
        ...state,
        updatingArchives: newArchives || []
      };
    }
    case EnumArchivesAction.RESET: {
      return { ...state, ...initArchivesState };
    }
    default:
      return state;
  }
};
