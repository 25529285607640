import { IGeneralApiProps } from 'api/global.type';
import { getStreams } from 'api/streaming/streaming';
import { IStreamItem } from 'api/streaming/streaming.type';
import { StreamingContext } from 'context/streaming/streaming';
import { EnumStreamsAction } from 'context/streaming/streams';
import { useCallback, useContext } from 'react';
import { useGetInputNameById } from 'utils/hooks/useGetInputNameById';
import { useGetOutputSummariesById } from 'utils/hooks/useGetOutputSummariesById';
import { useService } from 'utils/useService';

export const useGetStreams = (props?: IGeneralApiProps) => {
  const fetchStreams = useService(getStreams);
  const { streamingDispatch } = useContext(StreamingContext);
  const getInputName = useGetInputNameById();
  const getAllOutputSummaries = useGetOutputSummariesById();

  const getStreamsService = useCallback(
    (callback?: Function) => {
      return fetchStreams(props)
        .then((value: any) => {
          if (value.error) {
            streamingDispatch({ type: EnumStreamsAction.SET_STREAMS_CLEAN, payload: [] });
            callback && callback(value);
          } else if (value) {
            const newData = value.map((stream: IStreamItem) => {
              const outputsPlusPassthrough = Array.from(stream.outputs);
              if (stream.passthruUuid) {
                outputsPlusPassthrough.push(stream.passthruUuid);
              }
              return {
                ...stream,
                inputName: getInputName(stream.input),
                outputSummaries: getAllOutputSummaries(outputsPlusPassthrough)
              };
            });

            streamingDispatch({ type: EnumStreamsAction.SET_STREAMS_CLEAN, payload: newData });
            callback && callback();
          }
        })
        .catch((e: any) => {
          callback && callback(e);
        });
    },
    [fetchStreams, getAllOutputSummaries, getInputName, props, streamingDispatch]
  );

  return getStreamsService;
};
