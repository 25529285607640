import './noContent.scss';

import { Button, Heading2Med, Icon } from '@hai/ui-react';
import { RoleCan } from 'permissions/role/Roles';
import React from 'react';

interface Props {
  buttonAction?: any;
  buttonText?: string;
  detailsText?: string;
  noItemMsg: string;
  iconName: string;
}

const NoContent = (props: Props) => {
  const { buttonAction, buttonText, detailsText, iconName, noItemMsg } = props;
  return (
    <div className='no-content d-flex justify-content-center' data-auto='no_content'>
      <div className=''>
        <div className='icon d-flex justify-content-center pb-5'>
          <Icon iconname={iconName} size='xl' />
        </div>
        <div className='centered'>
          <Heading2Med>{noItemMsg}</Heading2Med>
        </div>
        <RoleCan I='add' a={iconName.toLowerCase()}>
          {detailsText && (
            <div className='detail-text d-flex justify-content-center haiui-heading-04-med pt-2'>
              {detailsText}
            </div>
          )}
          {buttonText && (
            <div className='add-button d-flex justify-content-center pt-5'>
              <Button variant='primary' onClick={buttonAction}>
                {buttonText}
              </Button>
            </div>
          )}
        </RoleCan>
      </div>
    </div>
  );
};

export default NoContent;
