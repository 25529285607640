import { DropdownMenuItem } from '@hai/ui-react';
import { IPresetItem, loadPreset } from 'api/presets/presets';
import { presetsRowsWithIcons, sortPresets } from 'components/presets/presetsHelper';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import { PresetContext } from 'context/preset';
import { t } from 'i18next';
import React, { useContext } from 'react';
interface IPresetMenu {
  showDialog: (name: string) => void;
}

const PresetsLoadMenu = (props: IPresetMenu) => {
  const { showDialog } = props;
  const totalMenuItems = 4;
  const { configList, autosave } = useContext(PresetContext);
  const { dispatch } = useContext(GlobalContext);

  if (!configList) {
    return null;
  }

  const menuitems = sortPresets(configList).slice(0, totalMenuItems);
  let dropDownMenu: any = [];
  if (menuitems) {
    dropDownMenu = menuitems.map((item: IPresetItem) => (
      <DropdownMenuItem
        onSelect={e => askToLoadPreset({ dispatch, configList, id: e, autosave })}
        // Withcheckmark is a hack to get haiui to properly color the highlighted color of the sub item
        // This should be fixed in haiui and the !important styling on the main hover should be addressed.
        className='withCheckmark'
        key={`submenu-${item.uuid}${Math.random()}`}
        selected={item.active}
        eventKey={item.uuid}
        title={presetsRowsWithIcons(item)}
        data-auto={`dropdown-item_${item.name}`}
        useFsMask={true}
      />
    ));
  }
  dropDownMenu.push(
    <DropdownMenuItem
      key='view-all-presets'
      className='withCheckmark separator'
      eventKey='options'
      title={t('PRESETS.viewAllPresets')}
      onSelect={() => showDialog('manage')}
      useFsMask={true}
    />
  );
  return dropDownMenu;
};

export const presetLoadEvent = async (itemToLoad: IPresetItem, cb?: Function) => {
  cb?.();
  await loadPreset({ apiParams: itemToLoad });
  window.location.reload();
};
export const askToLoadPreset = ({
  autosave,
  dispatch,
  configList,
  id,
  cb
}: {
  autosave: boolean;
  dispatch: any;
  configList: any;
  id: any;
  cb?: Function;
}) => {
  const itemToLoad = configList?.find((i: IPresetItem) => i.uuid === id);

  if (itemToLoad) {
    itemToLoad.startup = true;
    dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        onConfirm: () => presetLoadEvent(itemToLoad, cb),
        isDanger: false,
        confirmText: t('load'),
        title: t('PRESETS.loadTitle', { name: itemToLoad.name }),
        desc: (
          <div>
            <div>
              {autosave
                ? t('PRESETS.loadDescAutosave', { preset: itemToLoad.name })
                : t('PRESETS.loadDesc')}
            </div>
          </div>
        )
      }
    });
  }
};

export default PresetsLoadMenu;
