import { getTranscoders } from 'api/transcoder/transcoder';
import { ITranscoder } from 'api/transcoder/transcoder.type';
import { StreamingContext } from 'context/streaming/streaming';
import { EnumTranscodersAction } from 'context/streaming/transcoders';
import { useCallback, useContext } from 'react';
import {
  formatAudioEnabled,
  formatGop,
  formatKlvEnabled,
  formatResolution,
  formatVideoBitrate
} from 'utils/formatText';
import { useService } from 'utils/useService';

export const useGetTranscoders = () => {
  const fetchTranscoders = useService(getTranscoders);
  const { streamingDispatch } = useContext(StreamingContext);

  const getTranscodersService = useCallback(
    ({ onSuccess, onEmpty }: { onSuccess?: Function; onEmpty?: Function }) => {
      return fetchTranscoders()
        .then((value: any) => {
          if (value) {
            const newData = value.map((transcoder: ITranscoder) => {
              return {
                ...transcoder,
                audioEnabled: formatAudioEnabled({
                  audio: transcoder.audio
                }),
                resolution: formatResolution({
                  width: transcoder.resolutionWidth,
                  height: transcoder.resolutionHeight
                }),
                videoBitrateFormatted: formatVideoBitrate({
                  videoBitrate: transcoder.videoBitrate
                }),
                klvEnabled: formatKlvEnabled({ klv: transcoder.klvUasSecurityFilterEnabled }),
                gop: formatGop({ gopSize: transcoder.gopSize })
              };
            });

            streamingDispatch({
              type: EnumTranscodersAction.SET_TRANSCODERS_CLEAN,
              payload: newData
            });
            onSuccess && onSuccess();
          } else {
            onEmpty && onEmpty();
          }
        })
        .catch(() => onEmpty && onEmpty());
    },
    [fetchTranscoders, streamingDispatch]
  );

  return getTranscodersService;
};
