import { addTranscoder } from 'api/transcoder/transcoder';
import { EnumNotify, NotifyContext } from 'context/notify';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { IFormRef } from 'utils/hooks/useFormRef';

import { TranscoderFormLayout } from './TranscoderFormLayout';

interface IProps {
  saveCallback: any;
  setButtonState: Dispatch<SetStateAction<string>>;
  buttonState: string;
  formControl: IFormRef;
}

export const AddTranscoder = (props: IProps) => {
  const { setButtonState, buttonState, saveCallback, formControl } = props;
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const [localState, setLocalState] = useState(buttonState);

  const onSave = async (values: any, { setSubmitting }: any) => {
    if (localState !== 'idle') {
      return;
    }

    setLocalState('pending');
    setButtonState('pending');

    const result = await addTranscoder({ apiParams: values });
    if (result?.success) {
      saveCallback(result.uuid);
      notifyDispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: {
            name: 'STREAMING.TRANSCODERS.MESSAGES.added',
            params: { name: values.name }
          }
        }
      });
    } else {
      setLocalState('idle');
      setButtonState('idle');
    }
    setSubmitting(false);
  };
  const formProps = {
    className: 'addTranscoders',
    defaultValidation: true,
    handleSubmit: onSave,
    initialValues: {
      audio: true,
      audioBitrate: '',
      audioType: 'unset',
      framerate: '-1.0',
      gopSize: '',
      intraRefresh: false,
      interleaveAdjustmentMs: '',
      klvDecimationFactor: '2',
      name: '',
      outputPacing: 'enabled',
      outputPacingInterval: '100',
      pcrWindowSize: ''
    }
  };

  return <TranscoderFormLayout formProps={formProps} formControl={formControl} />;
};
