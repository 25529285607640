import { Divider, List } from '@hai/ui-react';
import { IAccount, disableAccount, enableAccount, getAccounts } from 'api/accounts/account';
import { EnumFormButtonState } from 'components/common/form/formButton/formButton';
import LoadingIcon from 'components/common/loadingIcon/LoadingIcon';
import { EditAccount } from 'components/security/accounts/EditAccount';
import { useAuthContext } from 'context';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumAccountsAction } from 'context/security/accounts';
import { SecurityContext } from 'context/security/security';
import { t } from 'i18next';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import constant from 'utils/constant';
import { getAccountStyle, getRoleValue } from 'utils/security';
import { actionStatus } from 'utils/streaming';

enum EnumActions {
  CONFIG = 'settings',
  NONE = 'none'
}

interface IProps {
  item: IAccount;
  panelRef: any;
}
const AccountItem = (props: IProps) => {
  const { item, panelRef } = props;

  const [openedTab, setOpenedTab] = useState<EnumActions>(EnumActions.NONE);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [buttonState, setButtonState] = useState(EnumFormButtonState.IDLE);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  item.expiring =
    item.expiration !== undefined &&
    item.expiration !== -1 &&
    item.expiration <= constant.signin.expirationWarning;

  const { securityDispatch } = useContext(SecurityContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);

  const [auth] = useAuthContext();
  const { userId } = auth;

  useEffect(() => {
    if (panelRef) {
      onSelectHandler(EnumActions.CONFIG, true);
    }
  }, [panelRef]);

  const isCurrentUser = userId === item.id;

  const { icon, iconColor, panelColor, actionType } = getAccountStyle(item);
  const role = getRoleValue(item);

  const [killContentSlow, setKillContentSlow] = useState(false);

  useEffect(() => {
    setTimeout(() => setKillContentSlow(false), constant.timeout.panelTransition);
  }, [isOpen]);

  const onSelectHandler = (eventKey: EnumActions, expanded: boolean) => {
    setOpenedTab(eventKey);
    setIsOpen(expanded);
  };

  const toggleSelection = (selected: boolean): void => {
    securityDispatch({
      type: EnumAccountsAction.SELECT_ACCOUNT,
      payload: {
        id: item.id,
        selected: selected
      }
    });
  };

  const getExpandContent = useCallback(() => {
    if (!item || killContentSlow) {
      return null;
    }

    const onSuccess = () => {
      setIsOpen(false);
    };

    if (openedTab === EnumActions.CONFIG) {
      return (
        <EditAccount
          setButtonState={setButtonState}
          buttonState={buttonState}
          item={item}
          onSuccess={onSuccess}
          isCurrentUser={isCurrentUser}
        />
      );
    }

    return null;
  }, [buttonState, killContentSlow, item, openedTab, isCurrentUser]);

  const isActive = [actionStatus.active, actionStatus.expiring].indexOf(actionType) !== -1;

  const onStatusChange = async () => {
    setIsUpdating(true);
    let result = true;
    if (isActive) {
      const res = await disableAccount({ item });
      result = !!res?.result;
    } else {
      const res = await enableAccount({ item });
      result = !!res?.result;
    }
    if (result) {
      notifyDispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: t(
            `SECURITY.ACCOUNTS.MESSAGES.${isActive ? 'disabledAccount' : 'enabledAccount'}`,
            {
              name: item.username
            }
          )
        }
      });
      getAccounts().then((value: IAccount[]) => {
        value && securityDispatch({ type: EnumAccountsAction.SET_ACCOUNTS_CLEAN, payload: value });
      });
    }
    setTimeout(() => {
      setIsUpdating(false);
    }, constant.timeout.minButtonLoading);
  };

  return (
    <div key={`account-panel-${item.id}`} ref={panelRef}>
      <List.Panel
        checked={item.selected}
        onSelect={toggleSelection}
        onClick={() => {
          onSelectHandler(isOpen ? EnumActions.NONE : EnumActions.CONFIG, !isOpen);
        }}
        expandedState={{
          expanded: isOpen,
          eventKey: openedTab
        }}
        panelColor={panelColor}
      >
        <List.PanelTitle
          title={item.username}
          statusIcon={icon}
          iconColor={iconColor}
          useFsMask={true}
        />
        <List.PanelDetail>
          {role && <List.PanelDetailItem dataAuto='panel-detail-role' text={role} />}
          {item.expiration !== undefined && item.state === 'active' && (
            <>
              <Divider type='vertical' />
              <List.PanelDetailItem
                text={t('SECURITY.ACCOUNTS.MESSAGES.passwordExpirationMsg', {
                  count: item?.expiration || 0
                })}
                icon='AccessAdmin'
                dataAuto='panel-detail-expirationWarning'
              />
            </>
          )}
        </List.PanelDetail>
        <List.Actions>
          <List.ActionItem
            expandButton={true}
            eventKey={EnumActions.CONFIG}
            icon={'Settings'}
            title={'settings'}
            onSelect={(eventKey: string, expanded?: boolean) =>
              onSelectHandler(eventKey as EnumActions, expanded!)
            }
          />
          <List.ActionItem isDivider />
          <List.ActionItem
            eventKey={item.id}
            icon={
              <LoadingIcon
                isActive={isActive}
                activeIcon='Unlock'
                inactiveIcon='Lock'
                loading={isUpdating}
                tooltip={
                  !isUpdating && !isCurrentUser
                    ? { active: t('disable'), inactive: t('enable') }
                    : undefined
                }
              />
            }
            onSelect={onStatusChange}
            showActive={false}
            disabled={isUpdating || isCurrentUser}
          />
        </List.Actions>
        <List.ExpandedPanel>{getExpandContent()}</List.ExpandedPanel>
      </List.Panel>
    </div>
  );
};

export default AccountItem;
