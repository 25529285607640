export const transcodersEn = {
  addTranscoder: 'Add Transcoder',
  audioOff: 'Disabled',
  autoSize: 'Auto Size',
  autoFps: 'Auto fps',
  audioOn: 'Enabled',
  bypass: 'Bypass',
  passthru: 'Passthru',
  codecProfile: 'Codec Profile',
  createTranscoder: 'Add a transcoder to start processing video',
  noTranscoders: 'No Transcoders Configured',
  klvEnabled: 'KLV Enabled',
  klvDisabled: 'KLV Disabled',
  CODEC_PROFILE: {
    auto: 'Auto',
    simple: 'Simple',
    main: 'Main'
  },
  ADD: {
    advancedParameters: 'Advanced Parameters',
    audio: 'Audio',
    audioBitrate: 'Audio Bitrate',
    audioBitrateHelp: 'Audio Bitrate in kbps',
    audioCodec: 'Audio Codec',
    audioParameters: 'Audio Parameters',
    decimation: 'Decimation',
    enableUasKlvSecurityTagFiltering: 'UAS KLV Security Tag Filtering',
    enableUasKlvTagFiltering: 'UAS KLV Tag Filtering',
    encoder: 'Encoder',
    format: 'Format',
    frameRate: 'Frame Rate',
    framing: 'Framing',
    gopSize: 'GOP Size',
    height: 'Height',
    includeAudio: 'Include Audio',
    includedSecurityTags: 'Included Security Tags',
    includedUasTags: 'Included UAS Tags',
    interleaveAdjustment: 'Interleave Adjustment',
    interleaveAdjustmentHelper: 'Range [-5000, 5000] ms',
    intraRefresh: 'Intra Refresh',
    jitterBuffer: 'Jitter Buffer',
    jitterBufferHelper: 'Range [0, 5000] ms',
    klvDecimationSettings: 'KLV Decimation Settings',
    klvMetadata: 'KLV Metadata',
    metadata: 'Metadata',
    name: 'Transcoder Name',
    outputPacing: 'Output Pacing',
    outputPacingLabel: 'Output Pacing Buffering Interval',
    pcrDelay: 'PCR Delay',
    pcrDelayHelper: 'Range [0 - 10000] ms',
    performanceControlSystem: 'Performance Control System',
    performanceControlSystemHelp: 'Enable Performance Control System, attempts to optimize quality',
    resolution: 'Resolution',
    shapingVideoMaxBitrate: 'Shaping Video Max Bitrate',
    shapingVideoMaxBitrateHint: 'Range 50% - 150%',
    shapingTargetBitratePercent: 'Shaping Video Target Bitrate',
    shapingTargetBitratePercentHint: 'Range 50% - 150%',
    shapeWarning:
      'WARNING - Changing these settings can negatively impact the video performance and/or network performance.',
    transportStreamBitrate: 'Transport Stream Bitrate',
    transportStreamBitrateNote:
      'Note: if KLV data is enabled, this field will be increased proportionally up to a maximum of 200kbps. \
      Please increase your Transport Stream Bitrate to allow room for it.',
    transportStreamShaping: 'Transport Stream Shaping',
    uasKlvSecurityTagFiltering: 'UAS KLV Security Tag Filtering',
    uasKlvTagFiltering: 'UAS KLV Tag Filtering',
    vbvDepth: 'Depth of VBV',
    byFactor: 'By Factor',
    decimationFactor: 'Factor',
    vbvShapingDepth: 'Depth of Network Shaping Buffer',
    videoBitrate: 'Video Bitrate',
    videoBitrateHelp: 'Video Bitrate in kbps',
    videoParameters: 'Video Parameters',
    width: 'Width',
    ENCODER: {
      hardware: 'Hardware (QSV)',
      software: 'Software'
    },
    FRAME_RATE: {
      auto: 'Auto (Detect Continuously)',
      start: 'Auto (Detect on Stream Start)'
    },
    RESOLUTION_MODE: {
      auto: 'Auto (Detect Continuously)',
      start: 'Auto (Detect on Stream Start)',
      custom: 'Custom'
    },
    TAG_SETS: {
      custom: 'Custom',
      none: 'None',
      min: 'Minimum Set',
      all: 'All'
    }
  },
  DELETE_MODAL: {
    title: 'Delete transcoder?',
    title_other: 'Delete {{count}} transcoders?',
    desc: 'Are you sure you want to delete this transcoder?',
    desc_other: 'Are you sure you want to delete these {{count}} transcoders?'
  },
  ENCODER_STATS: {
    aspectRatio: 'Pixel Aspect Ratio',
    audioChannels: 'Audio Channels',
    audioCount: 'Audio Count',
    audioSampleRate: 'Audio Sample Rate',
    auxCount: 'Aux Count',
    instantFramerate: 'Instantaneous Framerate',
    pixelFormat: 'Pixel Format',
    resolution: 'Resolution',
    targetFramerate: 'Target Framerate',
    videoCount: 'Video Count'
  },
  BYPASS_STATS: {
    bitrate: 'Bitrate',
    bytes: 'Bytes',
    date: 'Date',
    inputUrl: 'Input Url',
    mode: 'Mode',
    name: 'Name',
    outputUrls: 'Output Url',
    outputUrls_other: 'Output Urls',
    pid: 'PID',
    uptime: 'Uptime',
    uuid: 'UUID'
  },
  DECODER_STATS: {
    aspectRatio: 'Pixel Aspect Ratio',
    audioChannels: 'Audio Channels',
    audioCount: 'Audio Count',
    audioSampleRate: 'Audio Sample Rate',
    auxCount: 'Aux Count',
    calculatedFramerate: 'Calculated Framerate',
    framerate: 'Framerate',
    pixelFormat: 'Pixel Format',
    resolution: 'Resolution',
    videoCount: 'Video Count'
  },
  MESSAGES: {
    added: '"{{name}}" added successfully.',
    deleted: '"{{name}}" deleted successfully.',
    deleted_other: '{{count}} transcoders deleted successfully.'
  },
  RESTART: {
    title: 'Are you sure you want to restart the stream now?',
    restartStream: 'Restart Stream',
    desc: `The transcoder you modified is in use, you will need to restart the following
     streams before those changes will take effect ({{listOfStreams}})\n
    Would you like to restart the following streams now?`
  }
};
