interface IPing {
  address: string;
  item?: string;
  callback: (res: boolean) => void;
  timeout?: number;
}

export const ping = (props: IPing) => {
  const { address, callback, timeout = 5000, item = '/favicon.ico' } = props;
  if (!window.navigator.onLine) {
    // Browser reports offline
    callback(false);
    return;
  }

  var img = document.createElement('img');

  var timer = setTimeout(() => {
    img.onload = null;
    img.onerror = null;
    img.src = '';
    callback(false);
  }, timeout);

  img.onload = function () {
    clearTimeout(timer);
    callback(true);
  };

  img.onerror = function () {
    clearTimeout(timer);
    callback(false);
  };

  img.referrerPolicy = 'origin';

  img.src = `${address}${item}?${new Date().getTime()}`;
};
