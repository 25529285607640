import './statsHeaderPanel.scss';

import { Button, Heading3Med, Icon, colorValue } from '@hai/ui-react';
import { IButtonToggleOption } from '@hai/ui-react/dist/components/ButtonToggleGroup/ButtonToggleGroup';
import SectionHeader from 'components/common/sectionHeader/SectionHeader';
import { StreamChart } from 'components/streaming/streams/StreamChart';
import { EnumTimescale } from 'components/streaming/streamStatsDetail/StreamStatsDetail';
import { t } from 'i18next';
import React from 'react';
import { exportImage } from 'utils/downloadFiles';

interface IProps {
  title?: string;
  status: {
    icon: string;
    color: string;
  };
  thumbnail: string;
  onTimescaleClick: (opt: IButtonToggleOption) => any;
  timescale: EnumTimescale;
  data: any;
}

const StatsHeaderPanel: React.FunctionComponent<IProps> = ({
  title,
  status,
  thumbnail,
  onTimescaleClick,
  timescale,
  data
}) => {
  const download = (e: React.MouseEvent<HTMLButtonElement>) => {
    exportImage(e, thumbnail, `${title}-snapshot`);
  };

  const renderPreview = () => {
    return (
      <>
        <SectionHeader title={t('STREAMING.STREAMS.REPORT.streamReview')} />
        <div className='section-content center'>
          <img className='thumbnail fs-mask' src={thumbnail} data-auto='thumbnail' />
          <Button variant='secondary' size='regular' onClick={download}>
            {t('STREAMING.STREAMS.REPORT.downloadImage')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className='stats-header-panel'>
      <div className='header'>
        <div className='status-indicator' style={{ backgroundColor: colorValue(status.color) }} />
        <Icon iconname={status.icon} size={'md'} />
        <Heading3Med className='header-title fs-mask' data-auto='stats_header_title'>
          {title}
        </Heading3Med>
      </div>
      <div className='content'>
        <div className='thumbnail-section'>{renderPreview()}</div>
        <StreamChart
          showTimePicker={false}
          data={data}
          onTimescaleClick={onTimescaleClick}
          timescale={timescale}
          isPanelChart={false}
        />
      </div>
    </div>
  );
};

export default StatsHeaderPanel;
