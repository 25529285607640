import { EnumStreamSubStatus } from 'api/streaming/streaming.type';
import { Dispatch } from 'react';
import { EnumStreamStateString } from 'utils/hooks/useStreamInfo';

export interface IDashboardStat {
  streamId: string;
  streamName: string;
  state: EnumStreamStateString;
  subStatus: EnumStreamSubStatus;
  runtimeSeconds: number;
  inputBitrate: number;
  encoderBitrate: number;
  totalOutputBitrate: number;
  outputLatency: number;
  video?: IVideoFormat;
  date: string;
}

interface IVideoFormat {
  format: string;
  resolution: {
    width: number;
    height: number;
  };
  isInterlaced: boolean;
  pixelAspectRatio: {
    width: number;
    height: number;
  };
  framerate: {
    fps: number;
    fpsStr: string;
  };
}

export interface IDashboardStats {
  dashboardStats: IDashboardStat[] | null;
}

export enum EnumDashboardAction {
  SET = 'SET',
  RESET = 'RESET'
}

export interface IDashboardAction {
  type: EnumDashboardAction;
  payload?: any;
}

export interface IDashboardContext extends IDashboardStats {
  dashboardDispatch: Dispatch<any>;
}
