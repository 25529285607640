import { addOutput, outputsDefaults } from 'api/outputs/outputs';
import { OutputFormLayout } from 'components/streaming/outputs/OutputFormLayout';
import { EnumNotify, NotifyContext } from 'context/notify';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { IFormRef } from 'utils/hooks/useFormRef';
interface IProps {
  saveCallback: any;
  buttonState: string;
  setButtonState: Dispatch<SetStateAction<string>>;
  formControl: IFormRef;
}

export const AddOutput = (props: IProps) => {
  const { buttonState, setButtonState, saveCallback, formControl } = props;
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const [localState, setLocalState] = useState(buttonState);

  const onSave = async (values: any, { setSubmitting }: any) => {
    if (localState !== 'idle') {
      return;
    }

    setLocalState('pending');
    setButtonState('pending');

    const result = await addOutput({ apiParams: values });
    if (result?.success) {
      saveCallback(result.uuid);
      notifyDispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: {
            name: 'STREAMING.OUTPUTS.MESSAGES.added',
            params: { name: values.name }
          }
        }
      });
    } else {
      setLocalState('idle');
      setButtonState('idle');
    }
    setSubmitting(false);
  };

  const formProps = {
    defaultValidation: true,
    handleSubmit: onSave,
    initialValues: outputsDefaults
  };
  return <OutputFormLayout formProps={formProps} formControl={formControl} />;
};
