import { ISystemInfo, ISystemMetrics } from 'api/settings/system.type';
import { ISystemBandwidth } from 'api/system/network';

export interface IStatusState {
  debugLoggingEnabled: boolean | 'unset';
  systemInfo: ISystemInfo | null;
  systemMetrics: ISystemMetrics | null;
  systemBandwidth: ISystemBandwidth[] | null;
}

export enum EnumStatusAction {
  SET_DEBUG_LOGGING = 'SET_DEBUG_LOGGING',
  SET_SYSTEM_BANDWIDTH = 'SET_SYSTEM_BANDWIDTH',
  SET_SYSTEM_INFO = 'SET_SYSTEM_INFO',
  SET_SYSTEM_METRICS = 'SET_SYSTEM_METRICS',
  RESET = 'RESET'
}

export interface IStatusAction {
  type: EnumStatusAction;
  payload: any;
}

// Init states
export const initStatusState: IStatusState = {
  debugLoggingEnabled: 'unset',
  systemInfo: null,
  systemMetrics: null,
  systemBandwidth: null
};

// Reducer
export const statusReducer = (state: IStatusState, action: IStatusAction): IStatusState => {
  switch (action.type) {
    case EnumStatusAction.SET_DEBUG_LOGGING: {
      return {
        ...state,
        debugLoggingEnabled: action.payload
      };
    }
    case EnumStatusAction.SET_SYSTEM_INFO: {
      return {
        ...state,
        systemInfo: action.payload
      };
    }
    case EnumStatusAction.SET_SYSTEM_BANDWIDTH: {
      return {
        ...state,
        systemBandwidth: action.payload
      };
    }
    case EnumStatusAction.SET_SYSTEM_METRICS: {
      return {
        ...state,
        systemMetrics: action.payload
      };
    }
    case EnumStatusAction.RESET: {
      return { ...state, ...initStatusState };
    }
    default:
      return state;
  }
};
