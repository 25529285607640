import { Divider, Icon, PanelItem } from '@hai/ui-react';
import { IDashboardStat } from 'api/dashboard/dashboard.type';
import { streamingTabPaths } from 'components/routes/routePaths';
import ListThumbnail from 'components/streaming/streams/ListThumbnail';
import { StreamChart } from 'components/streaming/streams/StreamChart';
import { EnumTimescale } from 'components/streaming/streamStatsDetail/StreamStatsDetail';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import constant from 'utils/constant';
import { humanReadableMinData, humanReadableTime, uptimeHHMMSS } from 'utils/formatText';
import { useDashboardInfo } from 'utils/hooks/useDashboardInfo';
import { EnumStreamStateString } from 'utils/hooks/useStreamInfo';
import { formatGraphHistory, getActionStatusColor, getAmountByTimescale } from 'utils/streaming';
interface IDashProps {
  item: any;
}

export const DashboardPanelItem = (props: IDashProps) => {
  const { item } = props;
  const navigate = useNavigate();
  const getDashboardInfo = useDashboardInfo();
  const [graphHeight, setGraphHeight] = React.useState(120);
  const [graphContainerHeight, setGraphContainerHeight] = React.useState(125);

  const { thumbnail, thumbnailTitle, icon, actionType } = getDashboardInfo(item);

  const [statsHistory, setStatsHistory] = useState<IDashboardStat[]>([]);

  useEffect(() => {
    if (item?.encoderBitrate || item?.inputBitrate || item?.outputLatency) {
      setStatsHistory((old: any) =>
        [item, ...old].slice(0, 300 / (constant.interval.systemMetrics / 1000))
      );
    }
  }, [item]);

  const formatFrameRate = (fr: string) => {
    return Number(fr);
  };
  const buildResolution = () => {
    const video = item?.video;
    const framerate = () => {
      const f = formatFrameRate(video.framerate.fpsStr);
      if (!f) {
        return '';
      }
      return `${video.isInterlaced ? 'i' : 'p'}${f}`;
    };

    if (video) {
      return (
        <span key={`multi-hw-${item.streamId}`}>
          <Divider type='vertical' />
          {`${video.resolution.width}x${video.resolution.height}${framerate()}`}
        </span>
      );
    }
    return null;
  };

  const onAction = (eventKey: any, data: any) => {
    const path = generatePath(streamingTabPaths.streams, { id: data.id });
    navigate(path, {
      state: { id: data.id, action: eventKey }
    });
  };

  const onResize = (height: number) => {
    const container = height / 1.8;
    setGraphHeight(container - 20);
    setGraphContainerHeight(container);
  };

  const buildFormat = () => {
    if (!item?.video?.format) {
      return null;
    }

    const formatVideoFormat = (format: string) => {
      if (format === 'H265') {
        return 'H.265';
      }
      if (format === 'H264') {
        return 'H.264';
      }
      return format;
    };

    return (
      <>
        <Divider type='vertical' />
        {formatVideoFormat(item.video.format)}
      </>
    );
  };

  const itemsLength = getAmountByTimescale(EnumTimescale.fiveMins);
  const formattedGraphHistory = formatGraphHistory(statsHistory, itemsLength);

  return (
    <PanelItem
      panelColor={getActionStatusColor(actionType)}
      onResize={onResize}
      icon={<Icon iconname={icon} size='sm2' style={{ fill: getActionStatusColor(actionType) }} />}
      onClickTitle={() => onAction('report', { id: item?.streamId })}
      menuItems={[
        {
          title: t('DASHBOARD.resetStatistics'),
          disabled: item.state !== EnumStreamStateString.STREAMING,
          onSelect: () => {
            setStatsHistory((old: any) => {
              return [old[old.length - 1]];
            });
          }
        },
        {
          title: t('DASHBOARD.detailedStatus'),
          disabled: item.state !== EnumStreamStateString.STREAMING,
          onSelect: () => {
            navigate(`/streaming/streams/stream-statistics/${item?.streamId}`);
          }
        }
      ]}
      iconHover={<Icon iconname='ArrowThinRight' size='sm2' color='white' />}
      title={item?.streamName}
      subtitle={
        <>
          <div className='tnum' data-auto='stream_runtime'>
            {uptimeHHMMSS(item?.runtimeSeconds)}
          </div>
          {buildResolution()}
          {buildFormat()}
        </>
      }
      {...(statsHistory.length
        ? {
            slidingPanel: {
              height: `${graphContainerHeight}px`,
              expandedContent: (
                <>
                  <StreamChart
                    data={formattedGraphHistory}
                    compact
                    showTimePicker={false}
                    graphHeight={graphHeight}
                  />
                </>
              ),
              bottomRow: [
                {
                  label: t('DASHBOARD.BOTTOM_ROW.inputAbr'),
                  tooltip: t('DASHBOARD.BOTTOM_ROW.input'),
                  onClick: () => {},
                  text: humanReadableMinData({ bytes: item?.inputBitrate, max: 100 }),
                  color: 'haiui-pistachio-01'
                },
                ...(humanReadableMinData({ bytes: item?.encoderBitrate, max: 100 })
                  ? [
                      {
                        label: t('DASHBOARD.BOTTOM_ROW.streamRateAbr'),
                        tooltip: t('DASHBOARD.BOTTOM_ROW.streamRate'),
                        text: humanReadableMinData({ bytes: item?.encoderBitrate, max: 100 }),
                        onClick: () => {},
                        color: 'haiui-aqua-01'
                      }
                    ]
                  : []),

                ...(humanReadableTime({ ms: item?.outputLatency })
                  ? [
                      {
                        label: t('DASHBOARD.BOTTOM_ROW.latencyAbr'),
                        tooltip: t('DASHBOARD.BOTTOM_ROW.latency'),
                        onClick: () => {},
                        text: humanReadableTime({ ms: item?.outputLatency }),
                        color: 'haiui-pink-01'
                      }
                    ]
                  : []),
                {
                  label: t('DASHBOARD.BOTTOM_ROW.overallRateAbr'),
                  tooltip: t('DASHBOARD.BOTTOM_ROW.overallRate'),
                  text: humanReadableMinData({ bytes: item?.totalOutputBitrate, max: 100 }),
                  onClick: () => {},
                  color: 'haiui-gray-05'
                }
              ]
            }
          }
        : {})}
      data-auto={`panel_item_${item?.streamName}`}
      useFsMask={true}
    >
      <ListThumbnail thumbnail={thumbnail} title={thumbnailTitle} textSize='1rem' />
    </PanelItem>
  );
};
