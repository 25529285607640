import { Button, ButtonToggleGroup, ListActionBar, SearchInput } from '@hai/ui-react';
import { IButtonProps } from '@hai/ui-react/dist/components/Button/Button';
import {
  LayoutDirection,
  SearchInputFilter
} from '@hai/ui-react/dist/components/SearchInput/ISearchInput';
import { IButtonToggleOption } from '@hai/ui-react/dist/types';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';

interface IProps {
  ActionButtons?: ReactNode;
  actionButtonsPersist?: boolean; // always display action buttons
  actionRightPrimary?: IButtonProps;
  actionRightSecondary?: IButtonProps;
  setActionFilter?: Function;
  searchKeys?: string[];
  statusTypes?: Status[];
  items: any;
  cleanItems: any;
  setItems: Dispatch<SetStateAction<any>>;
  getStatus: (item: any) => any;
  onSearch?: (searchItems: any[]) => void;
  onSelectAll?: (selected: boolean | 'indeterminate' | 'none') => void; // if set, it will let the view handle it completely
  sortDropdown?: ReactNode;
  searchButtonTestId?: string;
}

export interface Status {
  hideWhenEmpty?: boolean;
  numberText?: string;
  status: string;
  color: string;
}

const ActionBar = (props: IProps) => {
  const {
    ActionButtons,
    setActionFilter,
    actionRightPrimary = false,
    actionRightSecondary = false,
    searchKeys = null,
    statusTypes = [],
    items,
    cleanItems,
    setItems,
    getStatus,
    onSearch,
    onSelectAll,
    sortDropdown,
    actionButtonsPersist,
    searchButtonTestId
  } = props;

  const [selectedFilter, setSelectedFilter] = useState('None');

  const [selectAll, setSelectAll] = useState<boolean | 'indeterminate' | 'none'>(
    !ActionButtons ? 'none' : false
  );
  const [StatusToggle, setStatusToggle] = useState<ReactNode>();
  const [searchKey] = useState(searchKeys);
  const [searchItems, setSearchItems] = useState<any>([]);

  const onSelectAllHandler = onSelectAll
    ? () => onSelectAll(selectAll)
    : useCallback(() => {
        const updated = items.map((i: any) => ({
          ...i,
          selected: selectAll === false
        }));
        setItems(updated);
      }, [items, selectAll, setItems]);

  // Build out the status filter area
  const dataStatusFilter = useCallback(
    (cleanItems: any[]) => {
      const status: Status[] = statusTypes
        .filter(i => !i.hideWhenEmpty)
        .map(i => ({ ...i, numberText: '00' }));

      cleanItems?.forEach((item: any) => {
        let existingStatus = status.findIndex(
          (s: Status) => s.status === getStatus(item).actionType
        );
        if (existingStatus === -1) {
          const statusFromType = statusTypes.find(
            (s: Status) => s.status === getStatus(item).actionType
          );
          if (statusFromType) {
            status.push({ ...statusFromType, numberText: statusFromType.numberText || '00' });
            existingStatus = status.length - 1;
          }
        }

        if (existingStatus !== -1) {
          status[existingStatus].numberText = (Number(status[existingStatus].numberText) + 1)
            .toString()
            .padStart(2, '0');
        }
      });

      return statusTypes
        .map(item => status.find(i => i.status === item.status))
        .filter(i => i !== undefined);
    },
    [getStatus, statusTypes]
  );

  useEffect(() => {
    // --- Filter section
    let updated: any[] = [];
    if (selectedFilter && selectedFilter !== 'None') {
      updated = cleanItems.filter((i: any) => getStatus(i).actionType === selectedFilter);
      if (updated.length === 0) {
        updated = cleanItems;
      }
    } else {
      updated = cleanItems;
    }
    // --- Search section
    if (searchKey && searchItems) {
      updated = updated?.filter((u: any) => {
        let searchText = '';
        searchKey.forEach(key => (searchText += ' ' + u[key]));
        return searchItems.every((i: string) => searchText.toLowerCase().includes(i.toLowerCase()));
      });
    }
    setItems(updated);
  }, [cleanItems, searchKey, searchItems, selectedFilter, setItems, getStatus]);

  const searchAddFilter = (filter: string | SearchInputFilter) => {
    setSearchItems((old: any) => {
      const items = [...old, filter];
      onSearch?.(items);
      return [...old, filter];
    });
  };

  const searchRemoveFilter = (filter?: string | SearchInputFilter) => {
    setSearchItems((old: any) => {
      const items = old.filter((item: string) => {
        return item !== filter;
      });
      onSearch?.(items);
      return items;
    });
  };

  const Search = (
    <SearchInput
      data-testid={searchButtonTestId}
      layoutDirection={LayoutDirection.LEFT}
      collapsible={true}
      onAddFilter={searchAddFilter}
      onRemoveFilter={searchRemoveFilter}
      filters={searchItems}
      searchMode='simple'
      useFsMask={true}
    />
  );

  useEffect(() => {
    const selectedItems = items?.filter((p: any) => p.selected);
    const isSelectAll = () => {
      if (!ActionButtons) {
        return 'none';
      } else if (selectedItems?.length === 0) {
        return false;
      }
      if (selectedItems?.length === items?.length) {
        return true;
      }
      return 'indeterminate';
    };

    setSelectAll(isSelectAll());
    if (statusTypes?.length > 0 && cleanItems?.length > 0) {
      setStatusToggle(
        <ButtonToggleGroup
          allowAllDeactivated
          onOptionClicked={(filter: IButtonToggleOption) => {
            const newFilter = filter.active && filter.status ? filter.status : 'None';
            setSelectedFilter(newFilter);
            setActionFilter?.(newFilter);
          }}
        >
          {dataStatusFilter(cleanItems).map(item => {
            return (
              <ButtonToggleGroup.StatusFilter
                key={`list-action-bar-status-filter-${item?.status}`}
                active={selectedFilter === item?.status}
                numberText={item?.numberText}
                disabled={item?.numberText === '00'}
                status={item?.status}
                color={item?.color}
              />
            );
          })}
        </ButtonToggleGroup>
      );
    }
  }, [
    dataStatusFilter,
    items,
    cleanItems,
    selectedFilter,
    statusTypes,
    ActionButtons,
    setActionFilter
  ]);

  return (
    <ListActionBar
      className='mt-4'
      selectAll={selectAll}
      onSelectAll={onSelectAllHandler}
      data-auto='list_action_bar'
    >
      {statusTypes.length > 0 && (
        <ListActionBar.Item className='statustoggle' hideOnSelect={!ActionButtons ? false : true}>
          {StatusToggle}
        </ListActionBar.Item>
      )}
      {actionButtonsPersist && (
        <ListActionBar.Item className='statustoggle' hideOnSelect={true}>
          {ActionButtons}
        </ListActionBar.Item>
      )}
      <ListActionBar.Item className='statustoggle' hideOnSelect={true}></ListActionBar.Item>
      <ListActionBar.Item as='button-group' hideOnSelect={false}>
        {ActionButtons}
      </ListActionBar.Item>
      {searchKey ? <ListActionBar.Item alignment='right'>{Search}</ListActionBar.Item> : <></>}
      {sortDropdown && (
        <ListActionBar.Item alignment='right' className='ml-1'>
          {sortDropdown}
        </ListActionBar.Item>
      )}
      {/* TODO sort <ListActionBar.Item alignment='right'>{}</ListActionBar.Item> */}
      {actionRightSecondary && (
        <ListActionBar.Item alignment='right'>
          <Button variant='secondary' {...actionRightSecondary} />
        </ListActionBar.Item>
      )}
      {actionRightPrimary && (
        <ListActionBar.Item alignment='right'>
          <Button variant='primary' {...actionRightPrimary} />
        </ListActionBar.Item>
      )}
    </ListActionBar>
  );
};
export default ActionBar;
