import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import constant from '../utils/constant';
import { createCtx } from '../utils/createCtx';

export interface IAuthContextType {
  isAuth?: boolean;
  group?: string;
  userId?: string;
  username?: string;
  timeout?: number;
  expiration?: number | undefined;
  mustChangePassword?: boolean;
  shareUserAnalytics?: boolean;
}

type IsAuthContext = [IAuthContextType, Dispatch<SetStateAction<IAuthContextType>>];

export const [useAuthContext, AuthContext] = createCtx<IsAuthContext>();

interface IAuthProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FunctionComponent<IAuthProps> = ({ children }) => {
  const [storedAuth, setStoredAuth] = useLocalStorage(constant.signin.authorized, false);
  const [storedGroup, setStoredGroup] = useLocalStorage(constant.signin.group, '');
  const [storedId, setStoredId] = useLocalStorage(constant.signin.userId, '');
  const [storedExpiration, setStoredExpiration] = useLocalStorage(
    constant.signin.expiration,
    undefined
  );
  const [storedName, setStoredName] = useLocalStorage(constant.signin.username, '');
  const [storedTimeout, setStoredTimeout] = useLocalStorage(
    constant.signin.timeout,
    30 * 1000 * 60
  );
  const [storedMustChangePassword, setStoredMustChangePassword] = useLocalStorage(
    constant.signin.mustChangePassword,
    false
  );

  const [auth, setAuth] = useState<IAuthContextType>({
    isAuth: storedAuth,
    group: storedGroup,
    userId: storedId,
    timeout: storedTimeout,
    expiration: storedExpiration,
    username: storedName,
    mustChangePassword: storedMustChangePassword
  });

  useEffect(() => {
    setStoredAuth(auth.isAuth as boolean);
    setStoredGroup(auth.group as string);
    setStoredId(auth.userId as string);
    setStoredTimeout(auth.timeout as number);
    setStoredExpiration(auth.expiration as any);
    setStoredName(auth.username as string);
    setStoredMustChangePassword(auth.mustChangePassword as boolean);
  }, [
    auth,
    setStoredAuth,
    setStoredTimeout,
    setStoredGroup,
    setStoredId,
    setStoredExpiration,
    setStoredMustChangePassword,
    setStoredName
  ]);

  return <AuthContext value={[auth, setAuth]}>{children}</AuthContext>;
};
