import { IOutputItem } from 'api/outputs/output.type';
import { getStreamSnapshotURL } from 'api/streaming/streaming';
import {
  EnumSnapshotTapPoint,
  EnumStreamStatus,
  EnumStreamSubStatus
} from 'api/streaming/streaming.type';
import { thumbnailDefault } from 'assets/images';
import { StreamingContext } from 'context/streaming/streaming';
import { useCallback, useContext } from 'react';
import { EnumThumbnail, actionStatus, getThumbnailTitle } from 'utils/streaming';

export const useOutputInfo = () => {
  const { snapshots, streamsClean } = useContext(StreamingContext);

  const useOutputInfo = useCallback(
    (i: IOutputItem) => {
      const getInfo = (
        item: IOutputItem
      ): {
        thumbnail: string;
        thumbnailTitle: string;
        icon: string;
        actionType: string;
      } => {
        const outputStreams =
          streamsClean?.filter(
            s => s.outputs.includes(item.uuid) || s.passthruUuid === item.uuid
          ) || [];
        const outputStatus = outputStreams.map(i => {
          return {
            mode: i.mode,
            status: i.status,
            subStatus: i.subStatus,
            uuid: i.uuid
          };
        });

        const outputSnapshots =
          snapshots?.filter(s => outputStatus.findIndex(i => i.uuid === s.sessionId) !== -1) || [];

        const hasSnapshot = outputSnapshots.length > 0;

        // If we are a bypass stream and have no thumbnail, try to set an ok state
        let bypassStream = false;
        let onlineStreams = outputStatus.filter(i => i.status !== 'STOPPED');
        if (onlineStreams.length === 1 && onlineStreams[0].mode === 'iorouter') {
          bypassStream = true;
        }

        const getSnapshotUrl = () => {
          if (hasSnapshot) {
            return `${getStreamSnapshotURL(
              EnumSnapshotTapPoint.INPUT,
              outputSnapshots[0].sessionId
            )}?id=${outputSnapshots[0].snapshotId}`;
          } else {
            return thumbnailDefault;
          }
        };

        if (
          outputStatus.findIndex(
            i =>
              i.status === EnumStreamStatus.ACTIVE && i.subStatus === EnumStreamSubStatus.CONNECTED
          ) !== -1
        ) {
          return {
            thumbnail: getSnapshotUrl(),
            thumbnailTitle: getThumbnailTitle(
              hasSnapshot && !bypassStream ? EnumThumbnail.NORMAL : EnumThumbnail.STREAMING
            ),
            icon: 'StatusStreaming',
            actionType: actionStatus.online
          };
        }

        if (outputStatus.findIndex(i => i.status === EnumStreamStatus.WARNING) !== -1) {
          if (outputStatus.findIndex(i => i.subStatus === EnumStreamSubStatus.STARTING) !== -1) {
            return {
              thumbnail: getSnapshotUrl(),
              thumbnailTitle: getThumbnailTitle(EnumThumbnail.OFFLINE),
              icon: 'StatusWarning',
              actionType: actionStatus.alert
            };
          }

          if (outputStatus.findIndex(i => i.subStatus === EnumStreamSubStatus.NO_DATA) !== -1) {
            return {
              thumbnail: getSnapshotUrl(),
              thumbnailTitle: getThumbnailTitle(EnumThumbnail.NO_DATA),
              icon: 'StatusInputOffline',
              actionType: actionStatus.alert
            };
          }

          if (outputStatus.findIndex(i => i.subStatus === EnumStreamSubStatus.INPUT_ERROR) !== -1) {
            return {
              thumbnailTitle: getThumbnailTitle(EnumThumbnail.INPUT_ERROR),
              thumbnail: getSnapshotUrl(),
              icon: 'StatusInputOffline',
              actionType: actionStatus.alert
            };
          }

          if (
            outputStatus.findIndex(i => i.subStatus === EnumStreamSubStatus.OUTPUT_ERROR) !== -1
          ) {
            // The stream thinks there is an output error, we need to inspect the output data and set the status
            if (item.ioState === 'STATE_RUNNING') {
              return {
                thumbnail: getSnapshotUrl(),
                thumbnailTitle: getThumbnailTitle(
                  hasSnapshot && !bypassStream ? EnumThumbnail.NORMAL : EnumThumbnail.STREAMING
                ),
                icon: 'StatusStreaming',
                actionType: actionStatus.online
              };
            } else {
              return {
                thumbnail: getSnapshotUrl(),
                thumbnailTitle: getThumbnailTitle(EnumThumbnail.WARNING),
                icon: 'StatusStreamWarning',
                actionType: actionStatus.alert
              };
            }
          }

          if (
            outputStatus.findIndex(i => i.subStatus === EnumStreamSubStatus.TRANSCODER_ERROR) !== -1
          ) {
            return {
              thumbnailTitle: getThumbnailTitle(EnumThumbnail.OFFLINE),
              thumbnail: getSnapshotUrl(),
              icon: 'StatusWarning',
              actionType: actionStatus.alert
            };
          }

          if (outputStatus.findIndex(i => i.subStatus === EnumStreamSubStatus.ERROR) !== -1) {
            return {
              thumbnailTitle: getThumbnailTitle(EnumThumbnail.OFFLINE),
              thumbnail: getSnapshotUrl(),
              icon: 'StatusWarning',
              actionType: actionStatus.alert
            };
          }
        }

        if (
          outputStatus.findIndex(
            i => i.status === EnumStreamStatus.STOPPED && i.subStatus === EnumStreamSubStatus.ERROR
          ) !== -1
        ) {
          // ACTION STATUS: INACTIVE - Incomplete
          return {
            thumbnail: getSnapshotUrl(),
            thumbnailTitle: getThumbnailTitle(EnumThumbnail.INCOMPLETE),
            icon: 'StatusInactive',
            actionType: actionStatus.inactive
          };
        }
        // last case EnumStreamStatus.STOPPED && EnumStreamSubStatus.OFFLINE
        return {
          thumbnail: getSnapshotUrl(),
          thumbnailTitle: getThumbnailTitle(EnumThumbnail.OFFLINE),
          icon: 'StatusInactive',
          actionType: actionStatus.inactive
        };
      };

      return getInfo(i);
    },
    [snapshots, streamsClean]
  );

  return useOutputInfo;
};
