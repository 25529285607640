import { IDashboardStat } from 'api/dashboard/dashboard.type';
import { StreamingContext } from 'context/streaming/streaming';
import { useCallback, useContext } from 'react';
import { buildStreamStatusObject } from 'utils/hooks/useStreamInfo';

export enum EnumStreamStatus {
  INACTIVE = 4,
  STREAMING = 2,
  WAITING = 5
}

export enum EnumStreamStateString {
  STREAMING = 'STATE_RUNNING',
  WAITING = 'STATE_WAITING',
  UNKNOWN = 'STATE_UNKNOWN'
}

export const useDashboardInfo = () => {
  const { snapshots } = useContext(StreamingContext);

  const getDashboardInfo = useCallback(
    (i: IDashboardStat) => {
      return buildStreamStatusObject(i, i.streamId, snapshots);
    },
    [snapshots]
  );
  return getDashboardInfo;
};
