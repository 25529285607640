import './text.scss';

import React from 'react';

interface ITextLabel {
  info: string | undefined;
  label: string | undefined;
  className?: string;
  dataAuto?: string;
}
export const TextLabel = (params: ITextLabel) => {
  const { info, className = '', label, dataAuto = '' } = params;
  return (
    <div className={'text-label ' + className} data-auto={`${dataAuto}_text_label`}>
      <div className='label' data-auto='label'>
        {label ?? ''}
      </div>
      <div className='info' data-auto='info'>
        {info ?? ''}
      </div>
    </div>
  );
};
