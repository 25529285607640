import './page.scss';

import React, { ReactNode } from 'react';

interface IPageProps {
  children: ReactNode;
  classNames?: string;
}

// With some basic paddings and a darker background
const Page: React.FunctionComponent<IPageProps> = ({ children, classNames = '' }) => {
  return <div className={'tab-page-container ' + classNames}>{children}</div>;
};

export default Page;
