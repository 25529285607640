import { Button, Dialog, colorValue } from '@hai/ui-react';
import { deleteMetadata, getMetadatas } from 'api/metadata/metadata';
import { IMetadataItem } from 'api/metadata/metadata.type';
import ActionBar from 'components/actionBar/ActionBar';
import SortDropdown from 'components/actionBar/SortDropdown';
import { EnumSortDirection, EnumSortType } from 'components/actionBar/SortManager';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import NoContent from 'components/noItems/noContent';
import { AddMetadata } from 'components/streaming/metadata/AddMetadata';
import MetadataList from 'components/streaming/metadata/MetadataList';
import StreamingPage from 'components/streaming/StreamingPage';
import { EnumGlobalAction, GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumMetadatasAction } from 'context/streaming/metadatas';
import { StreamingContext } from 'context/streaming/streaming';
import { t } from 'i18next';
import { RoleContext } from 'permissions/role/Roles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFormRef } from 'utils/hooks/useFormRef';
import { useGetPresets } from 'utils/hooks/useGetPresets';
import { getActionStatus } from 'utils/streaming';
import { useService } from 'utils/useService';

const Metadata = () => {
  const { metadatas, metadatasClean, streamingDispatch } = useContext(StreamingContext);
  const { dispatch } = useContext(GlobalContext);
  const role = useContext(RoleContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);

  const formControl = useFormRef();

  const getMetadataService = useService(getMetadatas);
  const getPresetsService = useGetPresets();

  const [buttonState, setButtonState] = useState('idle');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nothingFound, setNothingFound] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [direction, setDirection] = useState(EnumSortDirection.ASCENDING);
  const [sorting, setSorting] = useState<string>('name');
  const [isSearchOn, setIsSearchOn] = useState(false);
  const [newItem, setNewItems] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (metadatasClean && metadatasClean.length === 0) {
      setNothingFound(true);
    }
  }, [metadatasClean]);

  useEffect(() => {
    if (!metadatasClean?.length && !isLoading && !nothingFound) {
      setIsLoading(true);
      getMetadataService().then((value: any) => {
        streamingDispatch({ type: EnumMetadatasAction.SET_METADATAS_CLEAN, payload: value });
        setIsLoading(false);
      });
    }
  }, [getMetadataService, isLoading, nothingFound, streamingDispatch, metadatasClean]);

  const memoizedSetMetadata = useCallback(
    (value: any) => {
      streamingDispatch({ type: EnumMetadatasAction.SET_METADATAS, payload: value });
    },
    [streamingDispatch]
  );

  const saveCallback = (result: { uuid: string }) => {
    setNewItems(result?.uuid);
    getMetadataService().then((value: any) => {
      if (value?.length) {
        streamingDispatch({ type: EnumMetadatasAction.SET_METADATAS_CLEAN, payload: value });
        setIsLoading(false);
        setNothingFound(false);
      }
      setButtonState('idle');
      handleClose();

      getPresetsService();
    });
  };

  const actionDelete = () => {
    const triggerDelete = async (metadata: IMetadataItem) => {
      const result = await deleteMetadata({ metadata });
      getPresetsService();

      return { result, item: metadata };
    };
    (async () => {
      if (metadatas) {
        await Promise.all(metadatas.filter(i => i.selected).map(triggerDelete)).then(values => {
          const successItems = values.filter(x => x.result);
          if (successItems) {
            notifyDispatch({
              type: EnumNotify.ADD_NOTIFICATION,
              payload: {
                type: 'Success',
                // groupId: 'deleteMetadata',
                message: {
                  name: 'STREAMING.METADATA.MESSAGES.deleted',
                  params: { name: successItems[0].item.name },
                  items: successItems
                }
              }
            });
            getMetadataService().then((value: any) => {
              streamingDispatch({
                type: EnumMetadatasAction.SET_METADATAS_CLEAN,
                payload: value
              });
              if (value?.length) {
                setNothingFound(false);
                setIsLoading(false);
              } else {
                setNothingFound(true);
              }
            });
          } else {
            // TODO: Show error message if deleting failed.
          }
        });
      }
    })();
  };

  const onDeleteClick = () => {
    const count = metadatas?.filter(i => i.selected).length;
    dispatch({
      type: EnumGlobalAction.SET_CONFIRM_MODAL,
      payload: {
        show: true,
        onConfirm: actionDelete,
        title: t('STREAMING.METADATA.DELETE_MODAL.title', { count }),
        desc: t('STREAMING.METADATA.DELETE_MODAL.desc', { count })
      }
    });
  };

  const sortInfo = {
    name: { title: t('SORTING.STREAM.name'), type: EnumSortType.NATURAL_SORT },
    modified: { title: t('SORTING.STREAM.modified'), type: EnumSortType.NATURAL_SORT }
  };

  const onChangeSort = (sections: any) => {
    if (sections.sortBy?.eventKey) {
      setSorting(sections.sortBy?.eventKey);
    }

    if (sections.direction?.eventKey === 'ascending') {
      setDirection(EnumSortDirection.ASCENDING);
    } else if (sections.direction?.eventKey === 'descending') {
      setDirection(EnumSortDirection.DESCENDING);
    } else {
      setDirection(EnumSortDirection.IDLE);
    }
  };

  const onSearch = (searchItems: any) => {
    setIsSearchOn(!!searchItems.length);
  };

  const isNoSearchResult = isSearchOn && !metadatas?.length;

  const actionButtons = role.can('delete', 'metadatas')
    ? {
        ActionButtons: (
          <Button onClick={onDeleteClick} disabled={!metadatas?.find(i => i.selected)}>
            {t('delete')}
          </Button>
        )
      }
    : {};

  return (
    <StreamingPage>
      {metadatasClean?.length !== 0 && (
        <ActionBar
          onSearch={onSearch}
          getStatus={getActionStatus}
          items={metadatas}
          cleanItems={metadatasClean}
          searchKeys={['name', 'videoType', 'videoProfile', 'encodingProfile', 'encoderType']}
          setItems={memoizedSetMetadata}
          {...actionButtons}
          actionButtonsPersist
          {...(role.can('add', 'metadata')
            ? {
                actionRightPrimary: {
                  onClick: handleShow,
                  children: t('STREAMING.METADATA.addMetadata')
                }
              }
            : {})}
          sortDropdown={
            <SortDropdown
              direction={direction}
              title={sorting}
              onChangeSort={onChangeSort}
              sortInfo={sortInfo}
              sorting={sorting}
            />
          }
        />
      )}
      {metadatas && !isLoading && (
        <MetadataList
          hideCheckbox={!!actionButtons?.ActionButtons}
          direction={direction}
          sorting={sorting}
          sortInfo={sortInfo}
          saveCallback={saveCallback}
          list={metadatas}
          newItem={newItem}
        />
      )}
      {nothingFound && (
        <NoContent
          buttonText={t('STREAMING.METADATA.addMetadata')}
          buttonAction={handleShow}
          detailsText={t('STREAMING.METADATA.createMetadata')}
          iconName='Metadata'
          noItemMsg={t('STREAMING.METADATA.noMetadata')}
        />
      )}
      {isNoSearchResult && <NoContent iconName='Search' noItemMsg={t('noSearchResult')} />}
      <Dialog
        title={t('STREAMING.METADATA.addMetadata')}
        size='lg'
        data-auto='add-metadata-modal'
        dialogType='activity'
        accentColor={colorValue('haiui-aqua-01')}
        headerIcon='Metadata'
        content={
          <AddMetadata
            buttonState={buttonState}
            saveCallback={saveCallback}
            formControl={formControl}
            setButtonState={setButtonState}
          />
        }
        show={show}
        onClose={handleClose}
        buttons={[
          { variant: 'secondary', onClick: handleClose, label: t('cancel') },
          {
            variant: 'primary',
            close: false,
            state: buttonState,
            disabled: !formControl.formSubmitActive,
            onClick: () => submitHandler(formControl.formRef, notifyDispatch),
            label: t('STREAMING.METADATA.addMetadata')
          }
        ]}
      />
    </StreamingPage>
  );
};

export default Metadata;
