import { getLicense } from 'api/license/license';
import { ILicenseSettings } from 'api/settings/system.type';
import { useAuthContext } from 'context/auth';
import {
  EnumArchivesAction,
  IArchivesState,
  archivesReducer,
  initArchivesState
} from 'context/settings/archives';
import {
  EnumLicensesAction,
  ILicensesState,
  initLicensesState,
  licensesReducer
} from 'context/settings/licenses';
import {
  EnumNetworkAction,
  INetworkState,
  initNetworkState,
  networkReducer
} from 'context/settings/network';
import {
  EnumServicesAction,
  IServicesState,
  initServicesState,
  servicesReducer
} from 'context/settings/services';
import {
  EnumStatusAction,
  IStatusState,
  initStatusState,
  statusReducer
} from 'context/settings/status';
import React, { createContext, useEffect, useReducer } from 'react';

export interface ISettingsState
  extends IStatusState,
    ILicensesState,
    IArchivesState,
    IServicesState,
    INetworkState {}

const initialState: ISettingsState = {
  ...initStatusState,
  ...initLicensesState,
  ...initArchivesState,
  ...initServicesState,
  ...initNetworkState
};

interface ISettingsContext extends ISettingsState {
  settingsDispatch: React.Dispatch<any>;
}

const SettingsContext = createContext<ISettingsContext>({
  ...initialState,
  settingsDispatch: () => null
});

const { Provider } = SettingsContext;

const combineReducers =
  (...reducers: Function[]) =>
  (state: any, action: any): any => {
    for (let i = 0; i < reducers.length; i++) {
      state = reducers[i](state, action);
    }
    return state;
  };

const SettingsReducer = combineReducers(
  statusReducer,
  licensesReducer,
  archivesReducer,
  servicesReducer,
  networkReducer
);

const SettingsProvider = ({ children }: any) => {
  const [settingsState, settingsDispatch] = useReducer(SettingsReducer, initialState);
  const [auth] = useAuthContext();

  useEffect(() => {
    if (!settingsState.license && auth.isAuth) {
      getLicense().then((value: ILicenseSettings) => {
        value && settingsDispatch({ type: EnumLicensesAction.SET_LICENSE, payload: value });
      });
    }
  }, [settingsState.license, auth]);

  useEffect(() => {
    if (!auth.isAuth) {
      settingsDispatch({ type: EnumNetworkAction.RESET });
      settingsDispatch({ type: EnumServicesAction.RESET });
      settingsDispatch({ type: EnumLicensesAction.RESET });
      settingsDispatch({ type: EnumArchivesAction.RESET });
      settingsDispatch({ type: EnumStatusAction.RESET });
    }
  }, [auth]);

  return <Provider value={{ ...settingsState, settingsDispatch }}>{children}</Provider>;
};

export { SettingsContext, SettingsProvider };
