import {
  EnumDashboardAction,
  IDashboardAction,
  IDashboardContext,
  IDashboardStats
} from 'api/dashboard/dashboard.type';
import { useAuthContext } from 'context/auth';
import React, { createContext, useEffect, useReducer } from 'react';

// Init states
export const initDashboardStats: IDashboardStats = {
  dashboardStats: null
};
// Reducer
export const DashboardReducer = (state: any, action: IDashboardAction): IDashboardStats => {
  switch (action.type) {
    case EnumDashboardAction.SET: {
      return {
        ...state,
        dashboardStats: action.payload
      };
    }
    case EnumDashboardAction.RESET: {
      return initDashboardStats;
    }

    default:
      return state;
  }
};

const DashboardContext = createContext<IDashboardContext>({
  ...initDashboardStats,
  dashboardDispatch: () => null
});
const { Provider } = DashboardContext;

const DashboardProvider = ({ children }: any) => {
  const [state, dashboardDispatch] = useReducer(DashboardReducer, initDashboardStats);
  const [auth] = useAuthContext();

  useEffect(() => {
    if (!auth.isAuth) {
      dashboardDispatch({ type: EnumDashboardAction.RESET });
    }
  }, [auth]);
  return <Provider value={{ ...state, dashboardDispatch }}>{children}</Provider>;
};

export { DashboardContext, DashboardProvider };
