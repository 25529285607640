import { accountsEn } from 'i18n/locales/en/security/accounts/accounts';
import { certificatesEn } from 'i18n/locales/en/security/certificates/certificates';
import { policiesEn } from 'i18n/locales/en/security/policies/policies';

export const securityEn = {
  ACCOUNTS: {
    ...accountsEn
  },
  CERTIFICATES: {
    ...certificatesEn
  },
  POLICIES: {
    ...policiesEn
  }
};
