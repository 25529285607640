export const metadataEn = {
  addMetadata: 'Add Metadata',
  createMetadata: 'Create a metadata source to filter',
  enterManually: 'Enter manually...',
  format: 'Data Format',
  idDisabled: 'ID Disabled',
  idEnabled: 'ID Enabled',
  inputSettings: 'Input Settings',
  ipAddress: 'Multicast Address',
  noMetadata: 'No Metadata Configured',
  nonAlpha: 'Classifying country contains non-alphabetical characters',
  objectCountryCode: 'Max 6 chars for each code. Max 6 codes separated by ;',

  securityDisabled: 'Security Disabled',
  securityEnabled: 'Security Enabled',
  ADD: {
    classifyingCountry: 'Classifying Country',
    codingMethod: 'Country Coding Method',
    cotRelaying: 'CoT Relaying',
    inputMethod: 'Input Method',
    ipPort: 'Port',
    maxAircraftSpiDeltaMs: 'Max AirCraft-SPI Delta (0-1000) ms',
    missionId: 'Mission ID',
    missionIdInsertion: 'Mission ID Insertion',
    name: 'Name',
    networkSettings: 'Network Settings',
    objectCountryCodes: 'Object Country Codes',
    securityClassification: 'Security Classification',
    securityData: 'Security Data Set Insertion',
    securityDataset: 'Security Data Set Insertion',
    serialBaudrate: 'Baud Rate',
    serialDatabits: 'Data Bits',
    serialFlowcontrol: 'Flow Control',
    serialParity: 'Parity',
    serialPort: 'Serial Port',
    serialSettings: 'Serial Settings',
    serialStandard: 'COM Port',
    serialStopBits: 'Stop Bits',
    spiUid: 'SPI UID',
    type: 'Type'
  },
  COMPORT: {
    rs232: 'RS-232',
    rs422: 'RS-422'
  },
  COT: {
    add: 'Add CoT Relay',
    ipaddress: 'IP Address',
    port: 'Port',
    ttl: 'TTL',
    tos: 'ToS',
    tosOutOfRange: 'ToS is out of range'
  },
  DATAFORMAT: {
    klv: 'KLV',
    cot: 'CoT'
  },
  DELETE_MODAL: {
    title: 'Delete metadata?',
    title_other: 'Delete {{count}} metadata?',
    desc: 'Are you sure you want to delete this metadata?',
    desc_other: 'Are you sure you want to delete these {{count}} metadata?'
  },
  FLOWCONTROL: {
    none: 'None',
    software: 'Software',
    hardware: 'Hardware'
  },
  MESSAGES: {
    added: '"{{name}}" added successfully.',
    deleted: '"{{name}}" deleted successfully.',
    deleted_other: '{{count}} metadata deleted successfully.'
  },
  METHODS: {
    serial: 'Serial Input',
    udp: 'Network (UDP)',
    video_source: 'Video Source'
  },
  NETWORKTYPE: {
    multicast: 'Multicast',
    unicast: 'Unicast'
  },
  RESTART: {
    title: 'Are you sure you want to restart the stream now?',
    restartStream: 'Restart Stream',
    desc: `The metadata you modified is in use, you will need to restart the following
     streams before those changes will take effect ({{listOfStreams}})\n
    Would you like to restart the following streams now?`
  },
  PARITY: {
    none: 'None',
    odd: 'Odd',
    even: 'Even'
  },
  SECURITYCLASSIFICATION: {
    unclassified: 'Unclassified',
    restricted: 'Restricted',
    confidential: 'Confidential',
    secret: 'Secret',
    topsecret: 'Topsecret'
  },
  CODINGMETHOD: {
    3166: 'ISO 3166-1 alpha-3'
  }
};
