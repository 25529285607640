import { getOutput } from 'api/outputs/outputs';
import HlsPlayer from 'components/common/hlsPlayer/HlsPlayer';
import SectionHeader from 'components/common/sectionHeader/SectionHeader';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const OutputPlayer = () => {
  const params = useParams();
  const id = params.id as string;

  const [output, setOutput] = useState<null | any>(null);

  const getPageTitle = useCallback(() => {
    return output?.name || t('ROUTES.outputsPlayer');
  }, [output?.name]);

  // set title
  useEffect(() => {
    document.title = `${window.location.hostname} - ${getPageTitle()}`;
  }, [getPageTitle, output]);

  // fetch outputs
  useEffect(() => {
    getOutput({ apiParams: { uuid: id } }).then(output => setOutput(output?.output));
  }, [id]);

  const playerOpts = {
    fill: true,
    fluid: false,
    responsive: true,
    preload: 'auto',
    autoplay: true,
    controls: true,
    muted: true,
    preview: false,
    controlBar: {
      liveDisplay: true,
      pictureInPictureToggle: false
    },
    sources: output?.hls?.url
      ? [
          {
            src: output.hls.url
          }
        ]
      : []
  };

  return (
    <>
      <SectionHeader title={getPageTitle()} />
      <HlsPlayer options={playerOpts} />
    </>
  );
};
