import { List, colorValue } from '@hai/ui-react';
import { IAccount } from 'api/accounts/account';
import AccountItem from 'components/security/accounts/AccountItem';
import { RoleContext } from 'permissions/role/Roles';
import React, { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import constant from 'utils/constant';

interface IProps {
  list: IAccount[];
}

const AccountList = (props: IProps) => {
  const { list } = props;
  const role = useContext(RoleContext);
  const location = useLocation();
  const panelRef = useRef<null | HTMLElement>(null);

  const currentStreamId = location?.state?.id;

  useEffect(() => {
    if (panelRef.current) {
      // wait till the panel expanded
      const timer = setTimeout(() => {
        panelRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, constant.timeout.panelTransition);
      return () => clearTimeout(timer);
    }
  }, [panelRef]);
  return (
    <List accentColor={colorValue('haiui-aqua-01')} selectable={role.can('edit', 'accounts')}>
      {list.map((item: IAccount) => {
        return (
          <AccountItem
            key={item.id}
            item={item}
            panelRef={currentStreamId === item.id ? panelRef : null}
          />
        );
      })}
    </List>
  );
};

export default AccountList;
