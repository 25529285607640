import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { updateStream } from 'api/streaming/streaming';
import { IStreamItem } from 'api/streaming/streaming.type';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import { useNotifyRestartStream } from 'components/common/notify/notify';
import { StreamFormLayout } from 'components/streaming/streams/StreamFormLayout';
import { GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { t } from 'i18next';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useFormRef } from 'utils/hooks/useFormRef';

interface IFormItems {
  values: any;
  setSubmitting: any;
}
interface IProps {
  cancel: () => void;
  item: IStreamItem;
  onApply?: () => void;
  setButtonState: Dispatch<SetStateAction<ButtonStateType>>;
  buttonState: ButtonStateType;
}
export const EditStream = (props: IProps) => {
  const { buttonState, cancel, item, onApply, setButtonState } = props;
  const { notifyRestartStream } = useNotifyRestartStream();
  const formControl = useFormRef();

  const { setForceDirty } = formControl;
  const { screen } = useContext(GlobalContext);
  const columns = screen.columns.main;
  const { dispatch } = useContext(NotifyContext);

  const [selectedOutputs, setSelectedOutputs] = useState<string[]>(item.outputs || [undefined]);

  const saveForm = async (values: any, { setSubmitting }: any) => {
    const result = await onSave({ values, setSubmitting });
    if (result.restartStreamNeeded) {
      notifyRestartStream({
        result,
        type: 'STREAMS'
      });
    }
  };

  useEffect(() => {
    if (selectedOutputs !== formControl.formRef.current.values.outputs) {
      setForceDirty(true);
    }
  }, [formControl.formRef, selectedOutputs, setForceDirty]);

  const onSave = async ({ values, setSubmitting }: IFormItems) => {
    setButtonState('pending');

    values.outputs = [...selectedOutputs].filter(i => (i ? true : false));
    if (values.outputs.length === 0) {
      values.outputs = [''];
    }
    if (!values.metadatas) {
      values.metadatas = [];
    }
    values.metadatas = Array.isArray(values.metadatas) ? values.metadatas : [values.metadatas];
    delete values.multiOutputs;

    const result = await updateStream({ apiParams: values });
    if (result?.success) {
      dispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: t('STREAMING.STREAMS.MESSAGES.updated', { name: values.name })
        }
      });
      onApply?.();
    } else {
      setButtonState('idle');
    }
    setSubmitting(false);
    return result;
  };

  const formProps = {
    defaultValidation: true,
    handleSubmit: saveForm,
    initialValues: {
      ...item
    }
  };
  return (
    <StreamFormLayout
      cancel={cancel}
      columns={columns}
      formControl={formControl}
      formProps={formProps}
      buttonState={buttonState}
      item={item}
      submitHandler={() => submitHandler(formControl.formRef)}
      selectedOutputs={selectedOutputs}
      setSelectedOutputs={setSelectedOutputs}
    />
  );
};
