import { ITranscoder } from 'api/transcoder/transcoder.type';
import { IStreamingState } from 'context/streaming/streaming';

export interface ITranscodersState {
  transcoders: ITranscoder[] | null;
  transcodersClean: ITranscoder[] | null;
}

export enum EnumTranscodersAction {
  SET_TRANSCODERS = 'SET_TRANSCODERS',
  SET_TRANSCODERS_CLEAN = 'SET_TRANSCODERS_CLEAN',
  SELECT_TRANSCODER = 'SELECT_TRANSCODER',
  SELECT_ALL = 'SELECT_ALL',
  RESET = 'RESET'
}

export interface ITranscodersAction {
  type: EnumTranscodersAction;
  payload: any;
}

// Init states
export const initTranscodersState: ITranscodersState = {
  transcoders: null,
  transcodersClean: null
};

// Reducer
export const transcodersReducer = (
  state: IStreamingState,
  action: ITranscodersAction
): ITranscodersState => {
  switch (action.type) {
    case EnumTranscodersAction.SET_TRANSCODERS_CLEAN: {
      const newTranscoders = action.payload?.map((archive: ITranscoder) => {
        const selected = state.transcodersClean?.find(i => i.uuid === archive.uuid)?.selected;
        const newItem = archive;
        newItem.selected = selected;
        return newItem;
      });
      return {
        ...state,
        transcoders: newTranscoders,
        transcodersClean: newTranscoders
      };
    }
    case EnumTranscodersAction.SET_TRANSCODERS: {
      return {
        ...state,
        transcoders: action.payload
      };
    }
    case EnumTranscodersAction.SELECT_TRANSCODER: {
      const { id, selected } = action.payload;
      const newTranscoders = state.transcoders?.map((i: ITranscoder) =>
        i.uuid === id
          ? {
              ...i,
              selected
            }
          : i
      );

      const newCleanTranscoders = state.transcodersClean?.map((i: ITranscoder) =>
        i.uuid === id
          ? {
              ...i,
              selected
            }
          : i
      );

      return {
        ...state,
        transcoders: newTranscoders || null,
        transcodersClean: newCleanTranscoders || null
      };
    }
    case EnumTranscodersAction.SELECT_ALL: {
      const selected = action.payload;
      const newOutputs = state.transcoders?.map((i: ITranscoder) => {
        const newItem = i;
        newItem.selected = selected;
        return newItem;
      });
      const newOutputsClean = state.transcodersClean?.map((i: ITranscoder) => {
        const newItem = i;
        newItem.selected = selected;
        return newItem;
      });
      return {
        ...state,
        transcoders: newOutputs || [],
        transcodersClean: newOutputsClean || []
      };
    }

    case EnumTranscodersAction.RESET: {
      return { ...state, ...initTranscodersState };
    }
    default:
      return state;
  }
};
