import { t } from 'i18next';

export interface IMetadataItem {
  uuid: string;
  name: string;
  description?: string;
  format: string;
  maxAircraftSpiDeltaMs: number;
  method: string;
  serialDevice: string;
  serialStandard: string;
  serialFlowcontrol: string;
  serialParity: string;
  serialStopbits: string;
  sensorDiscovery?: boolean;
  ipAddress?: string;
  missionIdInsertion: string;
  securityClassification: string;
  codingMethod: string;
  classifyingCountry: string;
  objectCountryCodes: string;
  serialBaudrate: string | number;
  serialDatabits: string | number;
  ipPort: string | number;
  relays: ICotRelays[];
  relayEnable: boolean;
  enableSecurityDataSetInsertion: boolean;
  status?: string;
  selected?: boolean;
  type: string;
}
export interface ICotRelays {
  ip_address: string;
  isNew: boolean;
  port: string;
  tos: string;
  ttl: string;
}

export interface IMetadataWrapper {
  result: boolean;
  metadataList: IMetadataItem[];
}

export const UasTable = [
  { id: 1, title: '1 - Checksum', minST0902: true },
  { id: 2, title: '2 - Precision Time Stamp', minST0902: true },
  { id: 3, title: '3 - Mission ID', minST0902: true },
  { id: 4, title: '4 - Platform Tail Number' },
  { id: 5, title: '5 - Platform Heading Angle', minST0902: true },
  { id: 6, title: '6 - Platform Pitch Angle', minST0902: true },
  { id: 7, title: '7 - Platform Roll Angle', minST0902: true },
  { id: 8, title: '8 - Platform True Airspeed' },
  { id: 9, title: '9 - Platform Indicated Airspeed' },
  { id: 10, title: '10 - Platform Designation', minST0902: true },
  { id: 11, title: '11 - Image Source Sensor', minST0902: true },
  { id: 12, title: '12 - Image Coordinate System', minST0902: true },
  { id: 13, title: '13 - Sensor Latitude', minST0902: true },
  { id: 14, title: '14 - Sensor Longitude', minST0902: true },
  { id: 15, title: '15 - Sensor True Altitude', minST0902: true },
  { id: 16, title: '16 - Sensor Horizontal field of View', minST0902: true },
  { id: 17, title: '17 - Sensor Vertical Field of View', minST0902: true },
  { id: 18, title: '18 - Sensor Relative Azimuth Angle', minST0902: true },
  { id: 19, title: '19 - Sensor Relative Elevation Angle', minST0902: true },
  { id: 20, title: '20 - Sensor Relative Roll Angle', minST0902: true },
  { id: 21, title: '21 - Slant Range', minST0902: true },
  { id: 22, title: '22 - Target Width', minST0902: true },
  { id: 23, title: '23 - Frame Center Latitude', minST0902: true },
  { id: 24, title: '24 - Frame Center Longitude', minST0902: true },
  { id: 25, title: '25 - Frame Center Elevation', minST0902: true },
  { id: 26, title: '26 - Offset Corner Latitude Point 1' },
  { id: 27, title: '27 - Offset Corner Longitude Point 1' },
  { id: 28, title: '28 - Offset Corner Latitude Point 2' },
  { id: 29, title: '29 - Offset Corner Longitude Point 2' },
  { id: 30, title: '30 - Offset Corner Latitude Point 3' },
  { id: 31, title: '31 - Offset Corner Longitude Point 3' },
  { id: 32, title: '32 - Offset Corner Latitude Point 4' },
  { id: 33, title: '33 - Offset Corner Longitude Point 4' },
  { id: 34, title: '34 - Icing Detected' },
  { id: 35, title: '35 - Wind Direction' },
  { id: 36, title: '36 - Wind Speed' },
  { id: 37, title: '37 - Static Pressure' },
  { id: 38, title: '38 - Density Altitude' },
  { id: 39, title: '39 - Outside Air Temperature' },
  { id: 40, title: '40 - Target Location Latitude' },
  { id: 41, title: '41 - Target Location Longitude' },
  { id: 42, title: '42 - Target Location Elevation' },
  { id: 43, title: '43 - Target Track Gate Width' },
  { id: 44, title: '44 - Target Track Gate Height' },
  { id: 45, title: '45 - Target Error Estimate - CE90' },
  { id: 46, title: '46 - Target Error Estimate - LE90' },
  { id: 47, title: '47 - Generic Flag Data 01' },
  { id: 48, title: '48 - Security Local Set', minST0902: true },
  { id: 49, title: '49 - Differential Pressure' },
  { id: 50, title: '50 - Platform Angle of Attack' },
  { id: 51, title: '51 - Platform Vertical Speed' },
  { id: 52, title: '52 - Platform Sideslip Angle' },
  { id: 53, title: '53 - Airfield Barometric Pressure' },
  { id: 54, title: '54 - Airfield Elevation' },
  { id: 55, title: '55 - Relative Humidity' },
  { id: 56, title: '56 - Platform Ground Speed' },
  { id: 57, title: '57 - Ground Range' },
  { id: 58, title: '58 - Platform Fuel Remaining' },
  { id: 59, title: '59 - Platform Call Sign' },
  { id: 60, title: '60 - Weapon Load' },
  { id: 61, title: '61 - Weapon Fired' },
  { id: 62, title: '62 - Laser PRF Code' },
  { id: 63, title: '63 - Sensor Field of View Name' },
  { id: 64, title: '64 - Platform Magnetic Heading' },
  { id: 65, title: '65 - UAS Datalink LS Version Number', minST0902: true },
  { id: 66, title: '66 - Target Location Covariance Matrix' },
  { id: 67, title: '67 - Alternate Platform Latitude' },
  { id: 68, title: '68 - Alternate Platform Longitude' },
  { id: 69, title: '69 - Alternate Platform Altitude' },
  { id: 70, title: '70 - Alternate Platform Name' },
  { id: 71, title: '71 - Alternate Platform Heading' },
  { id: 72, title: '72 - Event Start Time - UTC' },
  { id: 73, title: '73 - RVT Local Set' },
  { id: 74, title: '74 - VMTI Local Set' },
  { id: 75, title: '75 - Sensor Ellipsoid Height', minST0902: true },
  { id: 76, title: '76 - Alternate Platform Ellipsoid Height' },
  { id: 77, title: '77 - Operational Mode' },
  { id: 78, title: '78 - Frame Center Height Above Ellipsoid', minST0902: true },
  { id: 79, title: '79 - Sensor North Velocity' },
  { id: 80, title: '80 - Sensor East Velocity' },
  { id: 81, title: '81 - Image Horizon Pixel Pack' },
  { id: 82, title: '82 - Corner Latitude Point 1 (Full)' },
  { id: 83, title: '83 - Corner Longitude Point 1 (Full)' },
  { id: 84, title: '84 - Corner Latitude Point 2 (Full)' },
  { id: 85, title: '85 - Corner Longitude Point 2 (Full)' },
  { id: 86, title: '86 - Corner Latitude Point 3 (Full)' },
  { id: 87, title: '87 - Corner Longitude Point 3 (Full)' },
  { id: 88, title: '88 - Corner Latitude Point 4 (Full)' },
  { id: 89, title: '89 - Corner Longitude Point 4 (Full)' },
  { id: 90, title: '90 - Platform Pitch Angle (Full)', minST0902: true },
  { id: 91, title: '91 - Platform Roll Angle (Full)', minST0902: true },
  { id: 92, title: '92 - Platform Angle of Attack (Full)' },
  { id: 93, title: '93 - Platform Sideslip Angle (Full)' },
  { id: 94, title: '94 - MIIS Core Identifier', minST0902: true },
  { id: 95, title: '95 - SAR Motion Imagery Local Set' },
  { id: 96, title: '96 - Target Width Extended', minST0902: true },
  { id: 97, title: '97 - Range Image Local Set' },
  { id: 98, title: '98 - Geo-Registration Local Set' },
  { id: 99, title: '99 - Composite Imaging Local Set' },
  { id: 100, title: '100 - Segment Local Set' },
  { id: 101, title: '101 - Amend Local Set' },
  { id: 102, title: '102 - SDCC-FLP' },
  { id: 103, title: '103 - Density Altitude Extended' },
  { id: 104, title: '104 - Sensor Ellipsoid Height Extended', minST0902: true },
  { id: 105, title: '105 - Alternate Platform Ellipsoid Height Extended' },
  {
    id: 106,
    title: '106 - Stream Designator'
  },
  {
    id: 107,
    title: '107 - Operational Base'
  },
  {
    id: 108,
    title: '108 - Broadcast Source'
  },
  {
    id: 109,
    title: '109 - Range To Recovery Location'
  },
  {
    id: 110,
    title: '110 - Time Airborne'
  },
  {
    id: 111,
    title: '111 - Propulsion Unit Speed'
  },
  {
    id: 112,
    title: '112 - Platform Course Angle'
  },
  {
    id: 113,
    title: '113 - Altitude AGL'
  },
  {
    id: 114,
    title: '114 - Radar Altimeter'
  },
  {
    id: 115,
    title: '115 - Control Command'
  },
  {
    id: 116,
    title: '116 - Control Command Verification List'
  },
  {
    id: 117,
    title: '117 - Sensor Azimuth Rate'
  },
  {
    id: 118,
    title: '118 - Sensor Elevation Rate'
  },
  {
    id: 119,
    title: '119 - Sensor Roll Rate'
  },
  {
    id: 120,
    title: '120 - On-board MI Storage Percent Full'
  },
  {
    id: 121,
    title: '121 - Active Wavelength List'
  },
  {
    id: 122,
    title: '122 - Country Codes'
  },
  {
    id: 123,
    title: '123 - Number of NAVSATs in View'
  },
  {
    id: 124,
    title: '124 - Positioning Method Source'
  },
  {
    id: 125,
    title: '125 - Platform Status'
  },
  {
    id: 126,
    title: '126 - Sensor Control Mode'
  },
  {
    id: 127,
    title: '127 - Sensor Frame Rate Pack'
  },
  {
    id: 128,
    title: '128 - Wavelengths List'
  },
  {
    id: 129,
    title: '129 - Target ID'
  },
  {
    id: 130,
    title: '130 - Airbase Locations'
  },
  {
    id: 131,
    title: '131 - Take-off Time'
  },
  {
    id: 132,
    title: '132 - Transmission Frequency'
  },
  {
    id: 133,
    title: '133 - On-board MI Storage Capacity'
  },
  {
    id: 134,
    title: '134 - Zoom Percentage'
  },
  {
    id: 135,
    title: '135 - Communications Method'
  },
  {
    id: 136,
    title: '136 - Leap Seconds'
  },
  {
    id: 137,
    title: '137 - Correction Offset'
  },
  {
    id: 138,
    title: '138 - Payload List'
  },
  {
    id: 139,
    title: '139 - Active Payloads'
  },
  {
    id: 140,
    title: '140 - Weapons Stores'
  },
  {
    id: 141,
    title: '141 - Waypoint List'
  }
];

export const UasSecurityTable = [
  { id: 1, title: '1 - Security Classification', minST0902: true },
  {
    id: 2,
    title: '2 - Classifying Country and Releasing Instructions Country Coding Method',
    minST0902: true
  },
  { id: 3, title: '3 - Classifying Country', minST0902: true },
  { id: 4, title: '4 - Security-SCI/SHI information', minST0902: true },
  { id: 5, title: '5 - Caveats', minST0902: true },
  { id: 6, title: '6 - Releasing Instructions', minST0902: true },
  { id: 7, title: '7 - Classified By' },
  { id: 8, title: '8 - Derived From' },
  { id: 9, title: '9 - Classification Reason' },
  { id: 10, title: '10 - Declassification Date' },
  { id: 11, title: '11 - Classification and Marking System' },
  { id: 12, title: '12 - Object Country Coding Method', minST0902: true },
  { id: 13, title: '13 - Object Country Codes', minST0902: true },
  { id: 14, title: '14 - Classification Comments' },
  { id: 15, title: '15 - UMID' },
  { id: 19, title: '19 - Stream ID' },
  { id: 20, title: '20 - Transport Stream ID' },
  { id: 21, title: '21 - Item Designator ID (16 byte)' },
  { id: 22, title: '22 - Version', minST0902: true },
  {
    id: 23,
    title: '23 - Classifying Country and Releasing Instructions Country Coding Method Version Date'
  },
  { id: 24, title: '24 - Object Country Coding Method Version Date' }
];

export const methodOptions = [
  { option: t('STREAMING.METADATA.METHODS.video_source'), value: 'video_source' },
  { option: t('STREAMING.METADATA.METHODS.serial'), value: 'serial' },
  { option: t('STREAMING.METADATA.METHODS.udp'), value: 'udp' }
];

export const securityClassification = [
  { option: t('STREAMING.METADATA.SECURITYCLASSIFICATION.unclassified'), value: 'unclassified' },
  { option: t('STREAMING.METADATA.SECURITYCLASSIFICATION.restricted'), value: 'restricted' },
  { option: t('STREAMING.METADATA.SECURITYCLASSIFICATION.confidential'), value: 'confidential' },
  { option: t('STREAMING.METADATA.SECURITYCLASSIFICATION.secret'), value: 'secret' },
  { option: t('STREAMING.METADATA.SECURITYCLASSIFICATION.topsecret'), value: 'topsecret' }
];

export const codingMethod = [
  { option: t('STREAMING.METADATA.CODINGMETHOD.3166'), value: 'iso31661a8' }
];

export const formatOptions = [
  { option: t('STREAMING.METADATA.DATAFORMAT.klv'), value: 'klv' },
  { option: t('STREAMING.METADATA.DATAFORMAT.cot'), value: 'cot' }
];

export const serialStandardOptions = [
  { option: t('STREAMING.METADATA.COMPORT.rs232'), value: 'rs232' },
  { option: t('STREAMING.METADATA.COMPORT.rs422'), value: 'rs422' }
];

export const serialBaudrateOptions = [
  { value: '1200' },
  { value: '2400' },
  { value: '4800' },
  { value: '9600' },
  { value: '19200' },
  { value: '38400' },
  { value: '57600' },
  { value: '115200' }
];

export const serialDatabitsOptions = [{ value: '8' }];
export const serialStopBitsOptions = [{ value: '1' }, { value: '1.5' }, { value: '2' }];

export const serialParityOptions = [
  { option: t('STREAMING.METADATA.PARITY.none'), value: 'none' },
  { option: t('STREAMING.METADATA.PARITY.odd'), value: 'odd' },
  { option: t('STREAMING.METADATA.PARITY.even'), value: 'even' }
];

export const serialFlowcontrolOptions = [
  { option: t('STREAMING.METADATA.FLOWCONTROL.none'), value: 'none' },
  { option: t('STREAMING.METADATA.FLOWCONTROL.software'), value: 'software' },
  { option: t('STREAMING.METADATA.FLOWCONTROL.hardware'), value: 'hardware' }
];

export const networkTypeOptions = [
  {
    option: t('STREAMING.METADATA.NETWORKTYPE.unicast'),
    value: 'unicast'
  },
  {
    option: t('STREAMING.METADATA.NETWORKTYPE.multicast'),
    value: 'multicast'
  }
];
