import { Card as HaiCard, Placeholder } from '@hai/ui-react';
import { IHaiCardProps } from '@hai/ui-react/dist/components/Card/Card';
import React from 'react';
interface ICard extends IHaiCardProps {
  loading?: boolean;
  placeholderHeight?: number;
}

const Card = (props: ICard) => {
  const { loading, placeholderHeight, ...rest } = props;
  if (loading) {
    return (
      <Placeholder as='layout' style={placeholderHeight ? { height: placeholderHeight } : {}} />
    );
  }
  return <HaiCard {...rest} />;
};
export { Card };
