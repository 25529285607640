import { IOutputItem } from 'api/outputs/output.type';
import { getOutputs } from 'api/outputs/outputs';
import { EnumOutputsAction } from 'context/streaming/outputs';
import { StreamingContext } from 'context/streaming/streaming';
import { useCallback, useContext } from 'react';
import { getStreamConnection } from 'utils/streaming';
import { useService } from 'utils/useService';

export const useGetOutputs = () => {
  const fetchOutputs = useService(getOutputs);
  const { streamingDispatch } = useContext(StreamingContext);

  const getOutputsService = useCallback(
    (callback?: Function) => {
      return fetchOutputs()
        .then((value: any) => {
          if (value) {
            const newData = value.map((output: IOutputItem) => {
              return {
                ...output,
                connection: getStreamConnection(output)
              };
            });

            streamingDispatch({ type: EnumOutputsAction.SET_OUTPUTS_CLEAN, payload: newData });
          }
          callback && callback();
        })
        .catch(() => callback && callback());
    },
    [fetchOutputs, streamingDispatch]
  );

  return getOutputsService;
};
