import './metadata.scss';

import { Button, DataTable, Dialog, Form, Switch, colorValue } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import {
  HaiDataTableColumnType,
  IActionItem
} from '@hai/ui-react/dist/components/DataTable/IHaiDataTable';
import {
  ICotRelays,
  IMetadataItem,
  codingMethod,
  formatOptions,
  methodOptions,
  networkTypeOptions,
  securityClassification,
  serialBaudrateOptions,
  serialDatabitsOptions,
  serialFlowcontrolOptions,
  serialParityOptions,
  serialStandardOptions,
  serialStopBitsOptions
} from 'api/metadata/metadata.type';
import { getSerialports } from 'api/serialports/serialports';
import { FadeIn } from 'components/common/fadeIn/FadeIn';
import { FormButton } from 'components/common/form/formButton/formButton';
import { FormContainer, FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { buildFormSelect } from 'components/common/form/formSelect/formSelect';
import { IsHidden, isHidden } from 'components/isHidden/isHidden';
import { AddCot } from 'components/streaming/metadata/AddCot';
import { validationSchema } from 'components/streaming/metadata/metadataValidation';
import { EnumNotify, NotifyContext } from 'context/notify';
import { t } from 'i18next';
import { RoleCan, RoleContext } from 'permissions/role/Roles';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { IFormRef, useFormRef } from 'utils/hooks/useFormRef';

interface FormLayout {
  applyBtn?: any;
  cancel?: () => void;
  formControl: IFormRef;
  formProps: any;
  item?: IMetadataItem;
  columns?: number;
  submitHandler?: (event: any) => void;
  buttonState?: ButtonStateType;
}

export const MetadataFormLayout = (props: FormLayout) => {
  const { buttonState, cancel, submitHandler, columns = 2, formControl, formProps, item } = props;

  const { formRefCallback, formSubmitActive } = formControl;
  const role = useContext(RoleContext);

  const { dispatch: notifyDispatch } = useContext(NotifyContext);

  const [method, setMethod] = useState<string>(item?.method || 'video_source');
  const [serialPort, setSerialPort] = useState<string>(item?.serialDevice || 'manual');
  const [networkType, setNetworkType] = useState(item?.ipAddress ? 'multicast' : 'unicast');
  const [format, setFormat] = useState(item?.format || 'klv');
  const [relays, setRelays] = useState<ICotRelays[]>(item?.relays || []);
  const [dirtyHack, setDirtyHack] = useState(false);

  const [selectedCot, setSelectedCot] = useState(null);
  const [showSecureDataset, setShowSecureDataset] = useState<boolean>(
    item && item.enableSecurityDataSetInsertion ? true : false
  );
  const [showCotRelaying, setShowCotRelaying] = useState<boolean>(item?.relayEnable ? true : false);
  const [serialPortOptions, setSerialPortOptions]: any = useState([
    { value: '', option: `${t('loading')}...` }
  ]);
  const [showAddCot, setShowAddCot] = useState<boolean>(false);
  const handleCloseCot = () => {
    setSelectedCot(null);
    setShowAddCot(false);
  };
  const cotFormRef = useFormRef();

  useEffect(() => {
    formControl.formRef.current.setFieldValue('relays', relays);
  }, [formControl.formRef, relays]);

  useEffect(() => {
    if (method === 'serial' && serialPortOptions?.[0]?.option === `${t('loading')}...`) {
      (async () => {
        const result = await getSerialports();

        let list = [
          {
            value: 'manual',
            option: t('STREAMING.METADATA.enterManually')
          }
        ];

        if (result?.portsList) {
          list = [
            ...list,
            ...result.portsList.map(i => {
              return { value: i.id, option: i.id };
            })
          ];
        }
        setSerialPortOptions(list);
      })();
    }
  }, [item, method, serialPortOptions]);

  const secureDatasetOnChange = (checked: boolean) => setShowSecureDataset(checked);

  const addCotRelay = () => {
    if (cotFormRef && cotFormRef.formRef.current) {
      if (Object.keys(cotFormRef.formRef.current.errors).length !== 0) {
        // Dont submit, show the errors.
        notifyDispatch({
          type: EnumNotify.ADD_NOTIFICATION,
          payload: {
            type: 'Error',
            message: {
              name: Object.values(cotFormRef.formRef.current.errors).join(' ')
            }
          }
        });
      } else {
        const formValues = cotFormRef?.formRef?.current?.values;
        setDirtyHack(true);
        if (selectedCot) {
          deleteCot(null, selectedCot);
          setSelectedCot(null);
        }

        setRelays((old: any) => {
          return [formValues, ...old];
        });
        handleCloseCot();
      }
    }
  };

  formProps.restValidationProps = {
    validationSchema,
    innerRef: formRefCallback,
    enableReinitialize: true
  };

  const deleteCot = (eventKey: any, row: any) => {
    setRelays((old: any) => {
      const index = old.findIndex((o: any) => o.port === row.port && o.ipaddress === row.ipaddress);
      if (index !== -1) {
        const o = [...old];
        o.splice(index, 1);
        return o;
      }
      return old;
    });
  };

  const editRow = (eventKey: any, row?: any) => {
    setSelectedCot(row);
    setShowAddCot(true);
  };

  const rowToId = (row: any) => `${row.ipAddress}-${row.port}-${row.ttl}-${row.tos}`;

  const actionItems: IActionItem[] = role.cannot('edit', 'metadata', 'cot')
    ? []
    : [
        {
          actionIcon: 'TrashCan',
          title: t('delete'),
          eventKey: 'delete',
          onSelect: (e, r) => deleteCot(e, r)
        },
        {
          actionIcon: 'Edit',
          title: t('edit'),
          eventKey: 'edit',
          onSelect: (e, r) => editRow(e, r)
        }
      ];

  const bulkActionComponents = (selectedRows: any) => {
    const onClickBulkActions = () => {
      setRelays((old: any) => {
        const rows = [...old];
        const filteredRows = rows.filter((row: any) => {
          if (selectedRows.indexOf(rowToId(row)) !== -1) {
            return false;
          }
          return true;
        });
        return filteredRows;
      });
    };
    return (
      <Button state='idle' size='small' onClick={onClickBulkActions}>
        {t('DELETE')}
      </Button>
    );
  };

  const headerFields = [
    {
      fieldKey: 'ipAddress',
      title: t('STREAMING.METADATA.COT.ipaddress')
    },
    { fieldKey: 'port', title: t('STREAMING.METADATA.COT.port') },
    { fieldKey: 'ttl', title: t('STREAMING.METADATA.COT.ttl') },
    { fieldKey: 'tos', title: t('STREAMING.METADATA.COT.tos') },
    { fieldKey: '', title: '', type: HaiDataTableColumnType.ACTIONS }
  ];

  return (
    <FadeIn delay={300}>
      <Form {...formProps}>
        <FormContainer columns={columns}>
          <FormInput
            label={t('name')}
            name='name'
            item={item}
            required
            viewOnly={role.cannot('edit', 'metadata', 'name')}
          />
          <RoleCan I='edit' a='metadata'>
            {submitHandler && (
              <FormButton
                cancel={cancel}
                state={buttonState}
                columns={columns}
                submitHandler={submitHandler}
                disabled={!formSubmitActive && !dirtyHack}
              />
            )}
          </RoleCan>
        </FormContainer>
        <FormSectionDark title={t('STREAMING.METADATA.inputSettings')}>
          <FormContainer columns={columns}>
            {buildFormSelect({
              dataAuto: 'input_method',
              label: t('STREAMING.METADATA.ADD.inputMethod'),
              items: methodOptions,
              selectName: 'method',
              selectedId: item?.method,
              viewOnly: role.cannot('edit', 'metadata', 'method'),
              onChange: (e: ChangeEvent<HTMLInputElement>) => setMethod(e.target.value)
            })}
          </FormContainer>
          <FormContainer columns={columns}>
            {buildFormSelect({
              dataAuto: 'serial_port',
              label: t('STREAMING.METADATA.ADD.serialPort'),
              isHidden: isHidden({
                param: method,
                condition: (a: string) => ['serial'].indexOf(a) === -1
              }),
              items: serialPortOptions,
              selectName: 'serialDevice',
              selectedId: item?.serialDevice,
              viewOnly: role.cannot('edit', 'metadata', 'serialDevice'),
              onChange: (e: ChangeEvent<HTMLInputElement>) => setSerialPort(e.target.value)
            })}
            {/* Empty space is an internal hack for haiui */}
            <FormInput
              label=' '
              name='serialDeviceManual'
              required
              item={item}
              isHidden={isHidden({
                param: { serialDevice: serialPort, method: method },
                condition: (param: { serialDevice: string; method: string }) => {
                  return param.serialDevice !== 'manual' || param.method !== 'serial';
                }
              })}
              viewOnly={role.cannot('edit', 'metadata', 'serialDevice')}
            />
          </FormContainer>
        </FormSectionDark>

        <IsHidden param={method} condition={(a: string) => ['serial', 'udp'].indexOf(a) === -1}>
          <FormSectionDark>
            <FormContainer columns={columns}>
              {buildFormSelect({
                dataAuto: 'data_format',
                label: t('STREAMING.METADATA.format'),
                items: formatOptions,
                selectName: 'format',
                selectedId: format,
                viewOnly: role.cannot('edit', 'metadata', 'format'),
                onChange: (e: ChangeEvent<HTMLInputElement>) => setFormat(e.target.value)
              })}

              <FormInput
                isHidden={isHidden({
                  param: format,
                  condition: (a: string) => ['cot'].indexOf(a) === -1
                })}
                required
                item={item}
                viewOnly={role.cannot('edit', 'metadata', 'maxAircraftSpiDeltaMs')}
                label={t('STREAMING.METADATA.ADD.maxAircraftSpiDeltaMs')}
                name='maxAircraftSpiDeltaMs'
                dataAuto='max_aircraft-spi_delta'
              />
              <FormInput
                isHidden={isHidden({
                  param: format,
                  condition: (a: string) => ['cot'].indexOf(a) === -1
                })}
                item={item}
                label={t('STREAMING.METADATA.ADD.spiUid')}
                name='spiUid'
                viewOnly={role.cannot('edit', 'metadata', 'spiUid')}
                dataAuto='spi_uid'
              />
            </FormContainer>
          </FormSectionDark>
          <IsHidden param={format} condition={(a: string) => ['cot'].indexOf(a) === -1}>
            <FormSectionDark name='cot_relaying'>
              <Switch
                checked={showCotRelaying}
                name='relayEnable'
                className='pb-4'
                disabled={role.cannot('edit', 'metadata', 'cot')}
                labelPlacement='left'
                label={t('STREAMING.METADATA.ADD.cotRelaying')}
                onChange={setShowCotRelaying}
              />
              <IsHidden
                param={showCotRelaying}
                condition={(a: boolean) => role.cannot('add', 'metadata', 'cot') || !a}
              >
                <Button onClick={() => editRow(null)}>{t('add')}</Button>
              </IsHidden>
            </FormSectionDark>
            <IsHidden param={showCotRelaying} condition={(a: boolean) => !a}>
              <FormSectionDark className='pl-0 pr-0 pt-0 cot-data-table' name='cot_table'>
                <DataTable
                  dataAuto='cot_table'
                  className='content'
                  compact={false}
                  selectable={!role.cannot('edit', 'metadata', 'cot')}
                  sortable={item && item.relays?.length > 1}
                  columnStructure={headerFields}
                  // Note: selectall does not work without this function defined
                  onCheckAll={() => {}}
                  useFsMask={true}
                >
                  <DataTable.Header
                    bulkActions={bulkActionComponents}
                    data-auto='cot_table_header'
                  />
                  {relays.map((row: any) => {
                    const id = rowToId(row);
                    return (
                      <DataTable.Row
                        key={`relay-${id}`}
                        rowData={{
                          ...row,
                          key: `relay-${id}`,
                          id: `${id}`
                        }}
                        actionItems={actionItems}
                        checked={row.selected}
                        className={`ca-datatable-row`}
                      />
                    );
                  }) ?? []}
                </DataTable>
              </FormSectionDark>
            </IsHidden>
          </IsHidden>
          <IsHidden param={method} condition={(a: string) => a !== 'serial'}>
            <FormSectionDark title={t('STREAMING.METADATA.ADD.serialSettings')}>
              <FormContainer columns={columns}>
                {buildFormSelect({
                  dataAuto: 'com_port',
                  label: t('STREAMING.METADATA.ADD.serialStandard'),
                  items: serialStandardOptions,
                  selectName: 'serialStandard',
                  selectedId: item?.serialStandard,
                  viewOnly: role.cannot('edit', 'metadata', 'serialStandard')

                  // onChange: (e: ChangeEvent<HTMLInputElement>) => setMethod(e.target.value)
                })}
                {buildFormSelect({
                  dataAuto: 'baud_rate',
                  label: t('STREAMING.METADATA.ADD.serialBaudrate'),
                  items: serialBaudrateOptions,
                  selectName: 'serialBaudrate',
                  nameProp: 'value',
                  selectedId: item?.serialBaudrate.toString(),
                  viewOnly: role.cannot('edit', 'metadata', 'serialBaudrate')
                  // onChange: (e: ChangeEvent<HTMLInputElement>) => setMethod(e.target.value)
                })}

                {buildFormSelect({
                  dataAuto: 'data_bits',
                  label: t('STREAMING.METADATA.ADD.serialDatabits'),
                  items: serialDatabitsOptions,
                  selectName: 'serialDatabits',
                  nameProp: 'value',
                  viewOnly: role.cannot('edit', 'metadata', 'serialDataBits')
                })}

                {buildFormSelect({
                  dataAuto: 'parity',
                  label: t('STREAMING.METADATA.ADD.serialParity'),
                  items: serialParityOptions,
                  selectName: 'serialParity',
                  selectedId: item?.serialParity,
                  viewOnly: role.cannot('edit', 'metadata', 'serialParity')
                })}

                {buildFormSelect({
                  dataAuto: 'stop_bits',
                  label: t('STREAMING.METADATA.ADD.serialStopBits'),
                  items: serialStopBitsOptions,
                  selectName: 'serialStopBits',
                  nameProp: 'value',
                  viewOnly: role.cannot('edit', 'metadata', 'serialStopBits')
                })}

                {buildFormSelect({
                  dataAuto: 'flow_control',
                  label: t('STREAMING.METADATA.ADD.serialFlowcontrol'),
                  items: serialFlowcontrolOptions,
                  selectName: 'serialFlowcontrol',
                  selectedId: item?.serialFlowcontrol,
                  viewOnly: role.cannot('edit', 'metadata', 'serialFlowcontrol')
                })}
              </FormContainer>
            </FormSectionDark>
          </IsHidden>
        </IsHidden>
        <IsHidden param={method} condition={(a: string) => ['udp'].indexOf(a) === -1}>
          <FormSectionDark title={t('STREAMING.METADATA.ADD.networkSettings')}>
            <FormContainer columns={columns}>
              {buildFormSelect({
                dataAuto: 'type',
                label: t('STREAMING.METADATA.ADD.type'),
                items: networkTypeOptions,
                selectName: 'type',
                selectedId: networkType,
                onChange: (e: ChangeEvent<HTMLInputElement>) => setNetworkType(e.target.value),
                viewOnly: role.cannot('edit', 'metadata', 'type')
              })}

              <FormInput
                isHidden={isHidden({
                  param: networkType,
                  condition: (a: string) => ['multicast'].indexOf(a) === -1
                })}
                label={t('STREAMING.METADATA.ipAddress')}
                name='ipAddress'
                item={item}
                required
                viewOnly={role.cannot('edit', 'metadata', 'ipAddress')}
                dataAuto='multicast_address'
              />
              <FormInput
                label={t('STREAMING.METADATA.ADD.ipPort')}
                name='ipPort'
                item={item}
                required
                viewOnly={role.cannot('edit', 'metadata', 'ipPort')}
                dataAuto='port'
              />
            </FormContainer>
          </FormSectionDark>
        </IsHidden>

        <FormSectionDark title={t('STREAMING.METADATA.ADD.missionIdInsertion')}>
          <FormContainer columns={columns}>
            <FormInput
              label={t('STREAMING.METADATA.ADD.missionId')}
              name='missionIdInsertion'
              item={item}
              viewOnly={role.cannot('edit', 'metadata', 'missionId')}
              dataAuto='mission_id'
            />
          </FormContainer>
        </FormSectionDark>

        <FormSectionDark name='security_data_set_insertion'>
          <Switch
            checked={showSecureDataset}
            name='enableSecurityDataSetInsertion'
            className='pb-4'
            disabled={role.cannot('edit', 'metadata', 'enableSecurityDataSetInsertion')}
            labelPlacement='left'
            label={t('STREAMING.METADATA.ADD.securityDataset')}
            onChange={secureDatasetOnChange}
          />

          <IsHidden param={showSecureDataset} condition={(a: boolean) => !a}>
            <FormContainer columns={columns}>
              {buildFormSelect({
                label: t('STREAMING.METADATA.ADD.securityClassification'),
                items: securityClassification,
                selectName: 'securityClassification',
                selectedId: item?.codingMethod,
                viewOnly: role.cannot('edit', 'metadata', 'securityClassification'),
                dataAuto: 'security_classification'
                // onChange: (e: ChangeEvent<HTMLInputElement>) => setMethod(e.target.value)
              })}
              {buildFormSelect({
                label: t('STREAMING.METADATA.ADD.codingMethod'),
                items: codingMethod,
                selectName: 'codingMethod',
                selectedId: item?.codingMethod,
                viewOnly: role.cannot('edit', 'metadata', 'codingMethod'),
                dataAuto: 'country_coding_method'
                // onChange: (e: ChangeEvent<HTMLInputElement>) => setMethod(e.target.value)
              })}
              <FormInput
                label={t('STREAMING.METADATA.ADD.classifyingCountry')}
                name='classifyingCountry'
                required
                item={item}
                maxLength={3}
                viewOnly={role.cannot('edit', 'metadata', 'classifyingCountry')}
                dataAuto='classifying_country'
              />

              <FormInput
                required
                label={t('STREAMING.METADATA.ADD.objectCountryCodes')}
                name='objectCountryCodes'
                item={item}
                viewOnly={role.cannot('edit', 'metadata', 'objectCountryCodes')}
                dataAuto='object_country_codes'
              />
            </FormContainer>
          </IsHidden>
        </FormSectionDark>
      </Form>
      <Dialog
        title={t('STREAMING.METADATA.COT.add')}
        dialogType='activity'
        data-auto='add-cot-relay-modal'
        accentColor={colorValue('haiui-aqua-01')}
        headerIcon='Route'
        content={<AddCot item={selectedCot} columns={columns} formControl={cotFormRef} />}
        show={showAddCot}
        onClose={handleCloseCot}
        buttons={[
          { variant: 'secondary', onClick: handleCloseCot, label: t('cancel') },
          {
            variant: 'primary',
            close: false,
            disabled: !cotFormRef.formSubmitActive,
            state: buttonState,
            onClick: addCotRelay,
            label: t('STREAMING.INPUTS.addInput')
          }
        ]}
      />
    </FadeIn>
  );
};
