import { IGeneralApiProps } from 'api/global.type';
import { ISession } from 'api/session/session.type';
import client, { handleErrors } from 'api/utils/client';

export const getSession = (props?: IGeneralApiProps) => {
  handleErrors(props);
  return client
    .get('/session')
    .then(resp => {
      const inputList: ISession = resp.data.data;
      return inputList;
    })
    .catch(_e => {
      return null;
    });
};

interface ISetSessionSettings extends IGeneralApiProps {
  apiParams: {
    shareUserAnalytics: boolean;
  };
}
export const setSessionSettings = (props: ISetSessionSettings) => {
  const { apiParams, onError } = props;

  return client
    .put('/session/settings', apiParams)
    .then(resp => {
      if (resp.status === 200) {
        return { ...resp.data, success: true };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

export const getSessionSettings = (props?: IGeneralApiProps) => {
  handleErrors(props);

  return client.get('/session/settings').then(resp => {
    if (resp.status === 200) {
      return { ...resp.data.data };
    }
  });
};
