import { FormSection } from '@hai/ui-react';
import { srtEncryptionOptions, srtModeOptions } from 'api/global.type';
// import { FormCheckbox } from 'components/common/form/formCheckbox/formCheckbox'; KRAK-4052.
import { FormContainer } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { buildFormSelect } from 'components/common/form/formSelect/formSelect';
import { isHidden } from 'components/isHidden/isHidden';
import { t } from 'i18next';
import React, { ChangeEvent, useCallback, useState } from 'react';

interface IProps {
  formRef: any;
  columns?: number;
  disabled?: boolean;
  encryptionSelect?: boolean;
  // formRef: any; KRAK-4052.
  item?: any;
  srtMode: string;
  setSrtMode: Function;
  viewOnly: boolean;
}
export const SrtForm = (props: IProps) => {
  const {
    columns = 2,
    disabled,
    formRef,
    item,
    srtMode,
    setSrtMode,
    encryptionSelect = false,
    viewOnly
  } = props;

  const [encryptionType, setEncryptionType] = useState<string>(item?.srtEncryption || '');
  return (
    <FormSection
      title={t('STREAMING.FORM.srtSettings')}
      className='formSectionDark'
      data-auto='form_section_srt_settings'
    >
      <FormContainer columns={columns}>
        {buildFormSelect({
          dataAuto: 'srt_form_mode',
          label: t('STREAMING.INPUTS.ADD.mode'),
          items: srtModeOptions,
          selectedId: srtMode,
          disabled,
          viewOnly,
          selectName: 'srtMode',
          onChange: (e: ChangeEvent<HTMLInputElement>) => setSrtMode(e.target.value)
        })}
        {/*
        https://haivision.jira.com/browse/KRAK-4052.
        <FormCheckbox
          formRef={formRef}
          label={t('STREAMING.INPUTS.ADD.ipv6')}
          name='ipv6'
          item={item}
          viewOnly={viewOnly}
        />
      */}
        <FormInput
          dataAuto='srt_form_address'
          label={t('STREAMING.INPUTS.ADD.address')}
          name='srtAddress'
          item={item}
          disabled={disabled}
          viewOnly={viewOnly}
          isHidden={isHidden({
            param: srtMode,
            className: 'grid-column kraken-span-2',
            condition: (srtMode: string) => ['caller', 'rendezvous'].indexOf(srtMode) === -1
          })}
          required
        />

        <FormInput
          dataAuto='srt_form_local_port'
          label={
            srtMode === 'listener'
              ? t('STREAMING.INPUTS.ADD.port')
              : t('STREAMING.INPUTS.ADD.sourcePort')
          }
          item={item}
          disabled={disabled || srtMode === 'rendezvous'}
          viewOnly={viewOnly}
          {...(srtMode !== 'caller' ? { required: true } : {})}
          isHidden={isHidden({
            param: srtMode,
            condition: (srtMode: string) => ['rendezvous'].indexOf(srtMode) !== -1
          })}
          name='srtLocalPort'
          {...(srtMode === 'caller' ? { helpMessage: t('autoAssign') } : {})}
        />
        <FormInput
          dataAuto='srt_form_local_port_disabled'
          label={t('STREAMING.INPUTS.ADD.sourcePort')}
          disabled
          isHidden={isHidden({
            param: srtMode,
            condition: (srtMode: string) => ['rendezvous'].indexOf(srtMode) === -1
          })}
          helpMessage={t('autoAssign')}
          name='srtLocalPortx'
        />

        <FormInput
          dataAuto='srt_form_remote_port'
          isHidden={isHidden({
            param: srtMode,
            condition: (srtMode: string) => ['caller', 'rendezvous'].indexOf(srtMode) === -1
          })}
          showHint
          required
          disabled={disabled}
          item={item}
          label={t('STREAMING.INPUTS.ADD.destinationPort')}
          viewOnly={viewOnly}
          name='srtRemotePort'
        />

        <FormInput
          dataAuto='srt_form_latency'
          item={item}
          disabled={disabled}
          label={t('STREAMING.INPUTS.ADD.latency')}
          name='srtLatency'
          showHint
          placeholder={'[20 - 8000]'}
          viewOnly={viewOnly}
          required
        />
        {buildFormSelect({
          dataAuto: 'srt_form_encryption',
          label: t('STREAMING.OUTPUTS.ADD.encryption'),
          items: srtEncryptionOptions,
          disabled,
          selectName: 'srtEncryption',
          selectedId: item?.srtEncryption,
          viewOnly,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setEncryptionType(e.target.value);
          },
          isHidden: isHidden({
            param: encryptionSelect,
            condition: (e: boolean) => !e
          })
        })}

        <FormInput
          dataAuto='srt_form_passphrase'
          label={t('STREAMING.INPUTS.ADD.passphrase')}
          name='srtPassphrase'
          inputType='password'
          item={item}
          viewOnly={viewOnly}
          onFocus={useCallback(
            (e: any) => {
              if (e.target.value === '*********') {
                formRef.current.setFieldValue('srtPassphrase', '');
              }
            },
            [formRef]
          )}
          disabled={disabled}
          {...(encryptionType !== '' ? { required: true } : {})}
          isHidden={isHidden({
            param: {
              encryptionType: encryptionType,
              encryptionSelect: encryptionSelect
            },
            condition: (param: { encryptionType: string; encryptionSelect: boolean }) => {
              if (param.encryptionType === '' && param.encryptionSelect) {
                return true;
              }
            }
          })}
        />
      </FormContainer>
    </FormSection>
  );
};
