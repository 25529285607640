export interface IServiceState {
  active: boolean;
  startup: boolean;
  launching?: boolean;
}

interface IServiceWithLaunchingState extends IServiceState {
  launching: boolean;
}

export interface IServices {
  error?: any;
  ssh: IServiceState;
  mdns: IServiceState;
  desktopVideoHelper: IServiceState;
  ems: IServiceWithLaunchingState;
  decklink: IServiceWithLaunchingState;
  gigevis: IServiceWithLaunchingState;
  ndi: IServiceWithLaunchingState;
  st2110: IServiceWithLaunchingState;
  v4l2: IServiceWithLaunchingState;
  rtspserver: IServiceWithLaunchingState;
  webserver: IServiceWithLaunchingState;
  recording: IServiceWithLaunchingState;
}

export interface IWebserver {
  listeners: {
    all: boolean;
    interfaces: string[];
  };
}

export interface IRtspserver {
  rtspPort: number;
}

export interface IServicesState {
  services: IServices | null;
  webserver: IWebserver | null;
  rtspPort: IRtspserver | null;
}

export enum EnumServicesAction {
  SET_SERVICES = 'SET_SERVICES',
  SET_WEBSERVER = 'SET_WEBSERVER',
  SET_RTSPSERVER = 'SET_RTSPSERVER',
  RESET = 'RESET'
}

export interface IServicesAction {
  type: EnumServicesAction;
  payload: any;
}

// Init states

export const initServicesState: IServicesState = {
  services: null,
  webserver: null,
  rtspPort: null
};
// Reducer
export const servicesReducer = (state: IServicesState, action: IServicesAction): IServicesState => {
  switch (action.type) {
    case EnumServicesAction.SET_SERVICES: {
      return {
        ...state,
        services: action.payload
      };
    }
    case EnumServicesAction.SET_RTSPSERVER: {
      return {
        ...state,
        rtspPort: action.payload
      };
    }
    case EnumServicesAction.SET_WEBSERVER: {
      return {
        ...state,
        webserver: action.payload
      };
    }
    case EnumServicesAction.RESET: {
      return { ...state, ...initServicesState };
    }
    default:
      return state;
  }
};
