import { IPresets } from 'api/presets/presets';
import { useAuthContext } from 'context/auth';
import React, { Dispatch, createContext, useEffect, useReducer } from 'react';

export enum EnumPresets {
  SET_PRESETS = 'SET_PRESETS',
  RESET = 'RESET'
}

export interface IPresetsAction {
  type: EnumPresets;
  payload?: IPresets;
}

// Init states
export const initPresetsState: IPresets = {
  configList: undefined,
  activeUuid: undefined,
  currentUuid: undefined,
  autosave: false,
  unsavedChanges: false,
  useCurrentForStartup: true
};

// Reducer
export const presetsReducer = (state: IPresets, action: IPresetsAction): IPresets => {
  switch (action.type) {
    case EnumPresets.SET_PRESETS:
      return {
        ...state,
        activeUuid: action.payload?.activeUuid,
        autosave: !!action.payload?.autosave,
        currentUuid: action.payload?.currentUuid,
        unsavedChanges: !!action.payload?.unsavedChanges,
        configList: action.payload?.configList,
        useCurrentForStartup: !!action.payload?.useCurrentForStartup
      };
    case EnumPresets.RESET:
      return initPresetsState;
    default:
      return state;
  }
};

interface IPresetContext extends IPresets {
  dispatch: Dispatch<any>;
}

const PresetContext = createContext<IPresetContext>({
  ...initPresetsState,
  dispatch: () => null
});
const { Provider } = PresetContext;

const PresetProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(presetsReducer, initPresetsState);

  const [auth] = useAuthContext();

  useEffect(() => {
    if (!auth.isAuth) {
      dispatch({ type: EnumPresets.RESET });
    }
  }, [auth]);

  return <Provider value={{ ...state, dispatch }}>{children}</Provider>;
};

export { PresetContext, PresetProvider };
