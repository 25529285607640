import { IHaiNotificationProps } from '@hai/ui-react/dist/components/Notification/Notification';
import React, { Dispatch, createContext, useReducer } from 'react';

export enum EnumNotify {
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'
}

export interface INotifyAction {
  type: EnumNotify;
  payload: INotifyMessage;
}

// Init states
export const initNotifyState: INotifyState = {
  notifyList: []
};

interface INotifyState {
  notifyList: INotifyMessage[];
}

export interface INotifyMessage {
  message: any;
  groupId?: string;
  itemId?: string;
  linkText?: string;
  onLinkClick?: (event: any) => void;
  count?: number;
  items?: [];
  type?: IHaiNotificationProps['variant'];
}

// Reducer
export const notifyReducer = (state: INotifyState, action: INotifyAction): INotifyState => {
  switch (action.type) {
    case EnumNotify.ADD_NOTIFICATION: {
      if (!action.payload.message) {
        // don't send a notification and simply return current state if the message is empty
        return state;
      }
      return {
        ...state,
        notifyList: [...state.notifyList, action.payload]
      };
    }

    case EnumNotify.REMOVE_NOTIFICATION: {
      if (action.payload.groupId) {
        return {
          ...state,
          notifyList: [
            ...(state.notifyList.filter(s => s.groupId !== action.payload.groupId) || [])
          ]
        };
      }
      if (state.notifyList.findIndex(i => i.message === action.payload.message) !== -1) {
        state.notifyList.splice(
          state.notifyList.findIndex(i => i.message === action.payload.message),
          1
        );
      }

      return {
        ...state,
        notifyList: [...state.notifyList]
      };
    }

    default:
      return state;
  }
};

interface INotifyContext extends INotifyState {
  dispatch: Dispatch<any>;
}

const NotifyContext = createContext<INotifyContext>({
  ...initNotifyState,
  dispatch: () => null
});
const { Provider } = NotifyContext;

const NotifyProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(notifyReducer, initNotifyState);
  return <Provider value={{ ...state, dispatch }}>{children}</Provider>;
};

export { NotifyContext, NotifyProvider };
