import { Dialog, Form, FormGroup, FormLabel, colorValue } from '@hai/ui-react';
import {
  IPresets,
  PRESET_STARTUP_CURRENT_ID,
  addPreset,
  autosavePreset,
  startupPreset
} from 'api/presets/presets';
import { FormInput } from 'components/common/form/formInput/formInput';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import { EnumNotify, NotifyContext } from 'context/notify';
import { PresetContext } from 'context/preset';
import { t } from 'i18next';
import React, { useContext, useState } from 'react';
import { useFormRef } from 'utils/hooks/useFormRef';
import { useGetPresets } from 'utils/hooks/useGetPresets';
import * as Yup from 'yup';

interface IPresetsSaveAs {
  handleClose: () => void;
  show: boolean;
}

export const PresetsSaveAs = (props: IPresetsSaveAs) => {
  const { handleClose, show } = props;
  const formControl = useFormRef();

  const getPresetsService = useGetPresets();
  const { dispatch: notifyDispatch } = useContext(NotifyContext);
  const [hasError, setHasError] = useState(false);
  const [state, setState] = React.useState('idle');
  const { configList } = useContext(PresetContext);
  const initialName = `${t('PRESETS.configuration')} ${new Date(Date.now())
    .toLocaleString()
    .replaceAll('/', '-')}`;

  const { formRefCallback } = formControl;

  const savePreset = async (values: any) => {
    setState('pending');

    if (!values?.name) {
      values.name = initialName;
    }

    await addPreset({ apiParams: values });
    getPresetsService(async (presets: IPresets) => {
      if (presets?.configList?.length === 1) {
        await startupPreset({ apiParams: { uuid: PRESET_STARTUP_CURRENT_ID } });
        await autosavePreset({
          apiParams: { autosave: true }
        });
        getPresetsService();
      }
      setState('idle');
    });

    notifyDispatch({
      type: EnumNotify.ADD_NOTIFICATION,
      payload: {
        type: 'Success',
        message: t(`PRESETS.saveAs`, values)
      }
    });
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(250)
      .test('unique', 'Name already exists, make it unique.', value => {
        if (configList?.find(c => c.name === value) !== undefined) {
          return false;
        }
        return true;
      })
  });
  const formProps = {
    defaultValidation: true,
    handleSubmit: savePreset,
    initialValues: { name: initialName },
    restValidationProps: { validationSchema, innerRef: formRefCallback }
  };

  const content = (
    <Form {...formProps} data-auto='form_save_as'>
      <FormGroup>
        <FormLabel>{t('PRESETS.saveAsDesc')}</FormLabel>
      </FormGroup>
      <FormInput
        label={t('PRESETS.presetName')}
        name='name'
        classNames='mt-3'
        onChange={obj => {
          setHasError(
            configList?.find(c => c.name === obj.target.value) !== undefined ||
              obj.target.value.length >= 250
          );
        }}
        placeholder={initialName}
        showHint
      />
    </Form>
  );

  return (
    <Dialog
      dataAuto='modal_save_as'
      accentColor={colorValue('haiui-aqua-01')}
      dialogType='activity'
      className='save-preset'
      title={`${t('saveAs')} …`}
      content={content}
      onClose={handleClose}
      show={show}
      buttons={[
        { label: t('cancel') },
        {
          label: t('save'),
          variant: 'primary',
          state: state,
          disabled: hasError,
          onClick: () => submitHandler(formControl.formRef, notifyDispatch)
        }
      ]}
      headerIcon='Save'
    />
  );
};
