import { ISystemNetworkNic } from 'api/settings/system.type';

export interface INetwork {
  autoDns: boolean;
  nics: ISystemNetworkNic[];
  ipForward: boolean;
  acceptRedirects: boolean;
  hostname: string;
  dnsserver0?: string;
  dnsserver1?: string;
  ntpAddress: string;
  searchdomain0?: string;
  searchdomain1?: string;
  searchdomain2?: string;
  snmp: string;
  defaultInterface: string;
  staticRoutes: IStaticRoute[];
  dnsPrecedence: string;
  snmpTrapServers: ISnmpTrapServer[];
  snmpRoCommunity: string;
}

export interface ISnmpTrapServer {
  type: string;
  server: string;
  community: string;
}

export interface IStaticRoute {
  destination: string;
  gateway: string;
  interface: string;
  subnetMask: string;
  protocol: number;
  prefixLength: number;
}

export interface INetworkState {
  network?: INetwork;
  networkInterface: ISystemNetworkNic[] | null;
  networkInterfaceClean: ISystemNetworkNic[] | null;
}

export enum EnumNetworkAction {
  SET = 'SET_NETWORK',
  SET_NIC_CLEAN = 'SET_NIC_CLEAN',
  SET_NIC = 'SET_NIC',
  RESET = 'RESET'
}

export interface INetworkAction {
  type: EnumNetworkAction;
  payload: any;
}

// Init states
export const initNetworkState: INetworkState = {
  network: undefined,
  networkInterface: null,
  networkInterfaceClean: null
};
// Reducer
export const networkReducer = (state: INetworkState, action: INetworkAction): INetworkState => {
  switch (action.type) {
    case EnumNetworkAction.SET: {
      return {
        ...state,
        network: action.payload
      };
    }
    case EnumNetworkAction.RESET: {
      return { ...state, ...initNetworkState };
    }
    case EnumNetworkAction.SET_NIC_CLEAN: {
      return {
        ...state,
        networkInterface: action.payload,
        networkInterfaceClean: action.payload
      };
    }
    case EnumNetworkAction.SET_NIC: {
      return {
        ...state,
        networkInterface: action.payload
      };
    }
    default:
      return state;
  }
};
