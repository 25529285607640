import { ICaptureResp } from 'api/capture/capture';
import { IDecklinkResp } from 'api/decklink/decklink';
import { IInputItem, IInputNdi } from 'api/inputs/input.type';
import { IStreamingState } from 'context/streaming/streaming';

export interface IInputsState {
  inputs: IInputItem[] | null;
  inputsClean: IInputItem[] | null;
  inputNdiList?: IInputNdi;
  inputDecklink?: IDecklinkResp;
  inputCapture?: ICaptureResp;
}

export enum EnumInputsAction {
  SET_INPUTS = 'SET_INPUTS',
  SET_INPUTS_CLEAN = 'SET_INPUTS_CLEAN',
  SELECT_INPUT = 'SELECT_INPUT',
  SELECT_ALL = 'SELECT_ALL_INPUTS',
  RESET = 'RESET',
  SET_DECKLINK = 'SET_DECKLINK',
  SET_CAPTURE = 'SET_CAPTURE',
  SET_NDI = 'SET_NDI'
}

export interface IInputsAction {
  type: EnumInputsAction;
  payload: any;
}

// Init states
export const initInputsState: IInputsState = {
  inputs: null,
  inputsClean: null,
  inputNdiList: undefined,
  inputDecklink: undefined,
  inputCapture: undefined
};

// Reducer
export const inputsReducer = (state: IStreamingState, action: IInputsAction): IInputsState => {
  switch (action.type) {
    case EnumInputsAction.SET_INPUTS_CLEAN: {
      const newInputs = action.payload?.map((archive: IInputItem) => {
        const selected = state.inputsClean?.find(i => i.uuid === archive.uuid)?.selected;
        const newItem = archive;
        newItem.selected = selected;
        return newItem;
      });
      return {
        ...state,
        inputs: newInputs,
        inputsClean: newInputs
      };
    }
    case EnumInputsAction.SET_INPUTS: {
      return {
        ...state,
        inputs: action.payload
      };
    }
    case EnumInputsAction.SELECT_INPUT: {
      const { id, selected } = action.payload;
      const newInputs = state.inputs?.map((i: IInputItem) =>
        i.uuid === id
          ? {
              ...i,
              selected
            }
          : i
      );
      const newInputsClean = state.inputsClean?.map((i: IInputItem) =>
        i.uuid === id
          ? {
              ...i,
              selected
            }
          : i
      );
      return {
        ...state,
        inputs: newInputs || null,
        inputsClean: newInputsClean || null
      };
    }
    case EnumInputsAction.SELECT_ALL: {
      const selected = action.payload;
      const newInputs = state.inputs?.map((i: IInputItem) => {
        const newItem = i;
        newItem.selected = selected;
        return newItem;
      });
      const newInputsClean = state.inputsClean?.map((i: IInputItem) => {
        const newItem = i;
        newItem.selected = selected;
        return newItem;
      });
      return {
        ...state,
        inputs: newInputs || null,
        inputsClean: newInputsClean || null
      };
    }
    case EnumInputsAction.SET_NDI: {
      return {
        ...state,
        inputNdiList: action.payload
      };
    }
    case EnumInputsAction.SET_DECKLINK: {
      return {
        ...state,
        inputDecklink: action.payload
      };
    }
    case EnumInputsAction.SET_CAPTURE: {
      return {
        ...state,
        inputCapture: action.payload
      };
    }
    case EnumInputsAction.RESET: {
      return { ...state, ...initInputsState };
    }
    default:
      return state;
  }
};
