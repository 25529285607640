import { Link } from '@hai/ui-react';
import React from 'react';

export interface OutputPlayerLinkProps {
  text: string;
  outputId: string;
}

export const OutputPlayerLink = (props: OutputPlayerLinkProps) => {
  const { outputId, text } = props;
  const url = `/streaming/outputs/player/${outputId}`;
  return (
    <Link
      onClick={evt => {
        const playerPage = window.open(
          url,
          `Player ${url}`,
          'menubar=no,width=1280,height=750,scrollbars=false'
        );
        playerPage?.focus();
        evt.stopPropagation();
      }}
      // href={url}
      showGoTo={false}
      target='_blank'
      className='kraken-player-output-link'
      useFsMask={true}
    >
      {text}
    </Link>
  );
};
