import { IGeneralApiProps } from 'api/global.type';
import client, { handleErrors } from 'api/utils/client';

export const getSystemUpgradeStatus = (props?: IGeneralApiProps) => {
  handleErrors({ ignoreErrors: true });
  return client
    .get('/system/upgrade/status')
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return e;
    });
};

export const getSystemUpgrade = (props?: IGeneralApiProps) => {
  return client
    .get('/system/upgrade')
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return null;
    });
};

interface IGetSystemUpgradeUploadProgress extends IGeneralApiProps {
  id: string;
}

// https://haivision.jira.com/browse/KRAK-3978
// This endpoint does not allow CORS, temporary backend fix can be done
export const getSystemUpgradeUploadProgress = (props?: IGetSystemUpgradeUploadProgress) => {
  return client
    .get(`/system/upgrade/upload/progress?X-Progress-ID=${props?.id}`)
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return null;
    });
};

export const startSystemUpgrade = (props?: IGeneralApiProps) => {
  handleErrors({ ignoreErrors: true });
  return client
    .put('/system/upgrade/upload')
    .then(resp => {
      const data = resp.data.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return e;
    });
};

interface IUploadSystemUpgrade extends IGeneralApiProps {
  file: File;
  id: string;
}

export const uploadSystemUpgrade = (props: IUploadSystemUpgrade) => {
  const { onError, file, id } = props;
  const formData = new FormData();
  formData.append('userfile', file);

  return client
    .post(`/system/upgrade/upload?X-Progress-ID=${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      onError && onError(e);
      throw e;
    });
};

export const deleteSystemUpgrade = (props?: IGeneralApiProps) => {
  return client
    .delete('/system/upgrade/upload')
    .then(resp => {
      return resp;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return e;
    });
};

export const confirmSystemUpgrade = (props?: IGeneralApiProps) => {
  return client
    .post('/system/upgrade')
    .then(resp => {
      return resp;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return e;
    });
};
