export const routesEn = {
  dashboard: 'Dashboard',
  streaming: 'Streaming',
  security: 'Security',
  signin: 'Sign In',
  outputsPlayer: 'Output Player',
  pageNotFound: 'Page Not Found',
  resetPassword: 'Reset Password',
  STREAMING: {
    streams: 'Streams',
    inputs: 'Inputs',
    transcoders: 'Transcoders',
    outputs: 'Outputs',
    metadata: 'Metadata'
  },
  settings: 'Settings',
  SETTINGS: {
    status: 'Status',
    archive: 'Archive Locations',
    reporting: 'Reporting',
    licensing: 'Licensing',
    services: 'Services',
    network: 'Network',
    updates: 'Update'
  },
  SECURITY: {
    accounts: 'Accounts',
    myAccount: 'My Account',
    policies: 'Policies',
    certificates: 'Certificates'
  },
  help: 'Help Center'
};
