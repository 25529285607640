import { Checkbox, FormControlContainer, FormGroup } from '@hai/ui-react';
import React, { ChangeEvent } from 'react';
import { FormikProps } from 'utils/hooks/useFormRef';

interface formCheckbox {
  name: string;
  formRef: React.MutableRefObject<FormikProps | undefined>;
  item?: any;
  isHidden?: Object;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  centered?: boolean;
  viewOnly?: boolean;
  className?: string;
  useFsMask?: boolean;
}

export const FormCheckbox = (props: formCheckbox) => {
  const {
    checked = props.item?.[props.name],
    isHidden = '',
    label,
    disabled,
    name,
    onChange,
    formRef,
    centered = true,
    viewOnly = false,
    className = 'checkbox-form-item',
    useFsMask = false
  } = props;

  const checkboxJsx = (
    <Checkbox
      data-auto={name}
      name={name}
      disabled={disabled}
      checked={checked}
      readOnly={viewOnly}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        checkboxClick(e, formRef);
        onChange && onChange(e);
      }}
      useFsMask={useFsMask}
    >
      {label}
    </Checkbox>
  );

  return (
    <FormGroup className={className} {...isHidden}>
      {centered ? <FormControlContainer>{checkboxJsx}</FormControlContainer> : checkboxJsx}
    </FormGroup>
  );
};

const checkboxClick = (e: ChangeEvent<HTMLInputElement>, formRef: any) => {
  formRef.current.setFieldValue(e.target.name, e.target.value === 'true');
};
