import { EnumNotify } from 'context/notify';
import { FormikTouched, FormikValues, setNestedObjectValues } from 'formik';
import { t } from 'i18next';

export const submitHandler = (formRef: any, notifyDispatch?: any, callback?: () => void) => {
  if (formRef && formRef.current) {
    const errors = formRef.current.errors;

    if (Object.keys(formRef.current.errors).length !== 0) {
      // Touch invalid fields: Show error msg below all invalid fields
      formRef.current.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true));
      // Dont submit, show the errors.
      const singleError =
        Object.values(formRef.current.errors).length === 1 &&
        typeof Object.values(formRef.current.errors)[0] === 'string';

      if (notifyDispatch) {
        notifyDispatch({
          type: EnumNotify.ADD_NOTIFICATION,
          payload: {
            type: 'Error',
            message: {
              name: singleError
                ? Object.values(formRef.current.errors)[0]
                : t('VALIDATION.invalidFields')
            }
          }
        });
      } else {
        console.warn('notifyDispatch not set in submitHandler.tsx');
      }
    } else {
      if (callback) {
        formRef?.current?.setFieldValue('callback', callback).then(() => {
          formRef.current.handleSubmit();
        });
      } else {
        formRef.current.handleSubmit();
      }
    }
  }
};
