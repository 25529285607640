import i18nLib, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import constant from '../utils/constant';
import { english } from './locales/en/english';

/**
 * Locale enum - not trimmed
 */
export enum Locale {
  English = 'en-US',
  Japanese = 'ja-JP',
  Spanish = 'es'
}

// /**
//  * Locale trimmed to language enum
//  */
export enum Language {
  English = 'en',
  Japanese = 'ja',
  Spanish = 'es'
}

/**
 * Returns an array of supported locales
 */
export const supportedLocales = Object.values(Locale);

/**
 * Returns an array of supported languages
 */
export const supportedLanguages = Object.values(Language);

const buildResourcesConfig: (translations: { [locale: string]: any }) => {
  [key: string]: { [locale: string]: any };
} = translations => {
  const resources: { [key: string]: { [locale: string]: any } } = {};
  Object.keys(translations).forEach(locale => {
    resources[locale] = {
      translations: translations[locale]
    };
  });

  return resources;
};

export const createI18nInitConfig: (
  translations: { [locale: string]: any },
  debugOverride?: boolean
) => InitOptions = (translations, debugOverride) => ({
  fallbackLng: Language.English,
  ns: ['translations'],
  defaultNS: 'translations',
  resources: buildResourcesConfig(translations),
  debug: debugOverride ?? false,
  interpolation: {
    escapeValue: false,
    format: (value: any) => value
  },

  nonExplicitWhitelist: true,
  whitelist: supportedLanguages,

  wait: false,
  useSuspense: true,

  detection: {
    order: ['localStorage'],
    lookupLocalStorage: constant.lSLanguageKey,
    excludeCacheFor: supportedLocales
  }
});

export const initializeI18n = (debug?: boolean): void => {
  void i18nLib
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
      createI18nInitConfig(
        {
          en: english
        },
        debug
      )
    );
};

initializeI18n(true);

// Can be used outside of a react component
export const i18n = i18nLib;
export const t = i18nLib.t.bind(i18n);
