import { IHaiDialogProps } from '@hai/ui-react/dist/components/Dialog/IDialog';
import { useAuthContext } from 'context/auth';
import { t } from 'i18next';
import React, { createContext, useEffect, useReducer } from 'react';
import { useLocalStorage, useWindowSize } from 'usehooks-ts';
import constant from 'utils/constant';

export enum EnumGlobalAction {
  SET_CONFIRM_MODAL = 'SET_CONFIRM_MODAL',
  CLOSE_CONFIRM_MODAL = 'CLOSE_CONFIRM_MODAL',
  SET_PAGE_WIDTH = 'SET_PAGE_WIDTH',
  SET_REBOOT_STATE = 'SET_REBOOT_STATE',
  SET_SCREEN = 'SET_SCREEN',
  SET_SIDEBAR = 'SET_SIDEBAR',
  RESET = 'RESET'
}

interface IGlobalAction {
  type: EnumGlobalAction;
  payload?: any;
}

interface IConfirmModal {
  icon?: string;
  title?: string;
  desc?: string;
  onCancel?: (event?: any) => void;
  close?: boolean;
  onClose?: (event: any) => void;
  ghostCancel?: boolean;
  cancelText: string;
  altConfirmText?: string;
  altConfirm?: (event: any) => void;
  confirmText: string;
  onConfirm?: (event: any) => void;
  isDanger?: boolean;
  show: boolean;
  size: IHaiDialogProps['size'];
}

export enum EnumRebootState {
  START = 'START',
  NONE = 'NONE'
}

interface IGlobalState {
  confirmModal: IConfirmModal;
  rebootState: EnumRebootState;
  screen: {
    columns: {
      main: number;
      modal: number;
    };
    collapsed: boolean;
    width: number;
  };
}

interface IGlobalContext extends IGlobalState {
  dispatch: React.Dispatch<any>;
}

const trackWidth = ({ width, collapsed }: any) => {
  const sidebarOffset = constant.pageBreakpoints.sidebarOffset;
  const col1 = constant.pageBreakpoints.col1;
  const col2 = constant.pageBreakpoints.col2;

  let main = 2;
  if (width + (collapsed ? sidebarOffset : 0) < col1) {
    main = 1;
  } else if (width + (collapsed ? sidebarOffset : 0) < col2) {
    main = 2;
  } else {
    main = 3;
  }

  let modal = 2;
  if (width < col1) {
    modal = 1;
  } else if (width < col2) {
    modal = 2;
  } else {
    modal = 3;
  }

  return { main, modal };
};

const initialState: IGlobalState = {
  confirmModal: {
    show: false,
    ghostCancel: false,
    close: false,
    cancelText: t('cancel'),
    confirmText: t('delete'),
    icon: 'StatusWarning',
    isDanger: true,
    size: 'md'
  },
  screen: {
    columns: { main: 2, modal: 2 },
    collapsed: false,
    width: 0
  },
  rebootState: EnumRebootState.NONE
};

// Reducer
export const globalReducer = (state: IGlobalState, action: IGlobalAction): IGlobalState => {
  switch (action.type) {
    case EnumGlobalAction.SET_CONFIRM_MODAL: {
      return {
        ...state,
        confirmModal: {
          ...state.confirmModal,
          ...action.payload
        }
      };
    }
    case EnumGlobalAction.CLOSE_CONFIRM_MODAL: {
      return {
        ...state,
        confirmModal: initialState.confirmModal
      };
    }
    case EnumGlobalAction.SET_REBOOT_STATE: {
      return {
        ...state,
        rebootState: action.payload
      };
    }
    case EnumGlobalAction.SET_SCREEN: {
      return {
        ...state,
        screen: {
          ...state.screen,
          ...action.payload
        }
      };
    }
    case EnumGlobalAction.SET_SIDEBAR: {
      return {
        ...state,
        screen: {
          ...state.screen,
          columns: trackWidth({ width: state.screen.width, collapsed: action.payload?.collapsed }),
          ...action.payload
        }
      };
    }
    case EnumGlobalAction.RESET:
      return initialState;
    default:
      return state;
  }
};

const GlobalContext = createContext<IGlobalContext>({
  ...initialState,
  dispatch: () => null
});

const { Provider } = GlobalContext;

const GlobalProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(globalReducer, {
    ...initialState
  });
  const [auth] = useAuthContext();

  const { width } = useWindowSize();
  const [collapsed] = useLocalStorage(constant.sidebar?.collapsed, false);

  useEffect(() => {
    dispatch({
      type: EnumGlobalAction.SET_SCREEN,
      payload: { width, columns: trackWidth({ width, collapsed }) }
    });
  }, [width, collapsed]);

  useEffect(() => {
    if (!auth.isAuth) {
      dispatch({ type: EnumGlobalAction.RESET });
    }
  }, [auth]);

  return <Provider value={{ ...state, dispatch }}>{children}</Provider>;
};

export { GlobalContext, GlobalProvider };
