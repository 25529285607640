import { t } from 'i18n';
import { generalValidation } from 'utils/validations';
import * as Yup from 'yup';

export const editArchiveSchema = Yup.object({
  name: generalValidation.required(t('SETTINGS.ARCHIVES.FORM.name'))
});

export const editNfsArchiveSchema = Yup.object({
  name: generalValidation.required(t('SETTINGS.ARCHIVES.FORM.name')),
  remoteHost: generalValidation.required(t('SETTINGS.ARCHIVES.FORM.remoteHost')),
  remotePath: generalValidation.required(t('SETTINGS.ARCHIVES.FORM.remotePath'))
});
