import './Presets.scss';

import { DropdownButton, DropdownMenuItem, Icon } from '@hai/ui-react';
import { IPresetItem, autosavePreset, savePreset } from 'api/presets/presets';
import PresetsLoadMenu from 'components/presets/PresetsLoadMenu';
import { GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { PresetContext } from 'context/preset';
import { SecurityContext } from 'context/security/security';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'usehooks-ts';
import constant from 'utils/constant';
import { useCache } from 'utils/hooks/useCache';
import { useGetPresets } from 'utils/hooks/useGetPresets';

interface IPresetMenu {
  showDialog: (name: string) => void;
}

const PresetMenu = (props: IPresetMenu) => {
  const { t } = useTranslation();
  const { showDialog } = props;
  const getPresetsService = useGetPresets();
  const { upgrade } = useContext(SecurityContext);

  const { screen } = useContext(GlobalContext);
  const columns = screen.columns.main;

  useCache({
    apiEndpoint: getPresetsService,
    url: '/presets'
  });
  const { configList, autosave, currentUuid, unsavedChanges } = useContext(PresetContext);
  const { dispatch: notifyDispatch } = useContext(NotifyContext);

  const updatePresets = async () => {
    if (upgrade?.state !== 'UPGRADING') {
      return await getPresetsService();
    }
  };

  useInterval(updatePresets, constant.presets.update);

  const [isChecked, setIsChecked] = useState(autosave);
  useEffect(() => {
    setIsChecked(autosave);
  }, [autosave]);

  // Allow clicking on Icon to open dropdown
  const handleClick = () => {
    const dropdown = document.getElementsByClassName('preset-dropdown');
    if (dropdown) {
      const button = dropdown[0].getElementsByTagName('button');
      if (button) {
        button[0].click();
      }
    }
  };

  const currentPreset = configList?.find((preset: IPresetItem) => preset.active) || {
    name: t('PRESETS.untitledPreset')
  };

  const truncateText = (text: string) => {
    const maxLen = columns === 3 ? 49 : 30;
    if (text) {
      if (text.length > maxLen) {
        return `${text.substring(0, maxLen - 3)}...`;
      }
      return text;
    }
    return undefined;
  };

  return (
    <>
      <div className='tab-header-section-container preset-menu-container'>
        <div onClick={handleClick} className='position-relative' data-auto='header_presets_icon'>
          <Icon
            indicatorColor={
              autosave ? 'haiui-green-01' : unsavedChanges ? 'haiui-amber-01' : 'haiui-green-01'
            }
            iconname={'Presets'}
            className='tab-header-preset-icon'
            size='sm2'
          />
        </div>
        <DropdownButton
          dataAuto='dropdown_presets'
          keepOpenOnToggle
          variant='multilevel'
          className='preset-dropdown'
          title={
            truncateText(
              configList?.find((preset: IPresetItem) => preset.uuid === currentUuid)?.name
            ) || t('PRESETS.untitledPreset')
          }
          useFsMask={true}
          withCheckmark={false}
        >
          <DropdownMenuItem
            sectionHead
            eventKey='devicePreset'
            title={t('PRESETS.dropdownHeader')}
          />
          <DropdownMenuItem
            eventKey='save'
            title={t('save')}
            icon='Save'
            disabled={autosave || !unsavedChanges}
            selectable={false}
            onSelect={async () => {
              const { success } = await savePreset({
                apiParams: {
                  ...configList?.find((preset: IPresetItem) => preset.active)
                }
              });

              notifyDispatch({
                type: EnumNotify.ADD_NOTIFICATION,
                payload: {
                  type: success ? 'Success' : 'Error',
                  message: t(success ? `PRESETS.saved` : `PRESETS.saveFailed`, currentPreset)
                }
              });
              updatePresets();
            }}
          />

          <DropdownMenuItem
            eventKey='saveAs'
            title={t('saveAs')}
            icon='Duplicate'
            selectable={false}
            onSelect={() => {
              showDialog('saveAs');
            }}
          />

          <DropdownMenuItem title={t('load')} icon='Import' subMenuItem>
            <PresetsLoadMenu showDialog={showDialog} />
          </DropdownMenuItem>
          <DropdownMenuItem
            eventKey='manage'
            title={t('PRESETS.managePresets')}
            icon='Presets'
            selectable={false}
            onSelect={() => {
              showDialog('manage');
            }}
          />

          <DropdownMenuItem
            title={t('PRESETS.autosave')}
            data-auto='auto_save'
            className='separator'
            eventKey='switch01'
            as='switch'
            switchChecked={isChecked}
            onSelect={async (eventKey, evt) => {
              const { success } = await autosavePreset({
                apiParams: { autosave: evt.target.value }
              });
              updatePresets();
              notifyDispatch({
                type: EnumNotify.ADD_NOTIFICATION,
                payload: {
                  type: success ? 'Success' : 'Error',
                  message: success
                    ? t(`PRESETS.autosaveToggled`, {
                        switchState: evt.target.value ? 'On' : 'Off'
                      })
                    : t('PRESETS.ERROR.autosaveToggled')
                }
              });
            }}
          />
        </DropdownButton>
      </div>
    </>
  );
};

export default PresetMenu;
