import { IGeneralApiProps } from 'api/global.type';
import client, { disableNormalizingConfig } from 'api/utils/client';
import { IServices, IWebserver } from 'context/settings/services';

export const getServices = (props?: IGeneralApiProps) => {
  return client
    .get('/system/services')
    .then(resp => {
      const data = resp.data.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return { error: e };
    });
};

export const getWebserver = (props?: IGeneralApiProps) => {
  return client
    .get('/system/services/webserver')
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return e;
    });
};

interface IUpdateWebserver extends IGeneralApiProps {
  config: IWebserver;
}

export const updateWebserver = (props: IUpdateWebserver) => {
  return client
    .put('/system/services/webserver', props.config)
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return e;
    });
};

export const getRtspserver = (props?: IGeneralApiProps) => {
  return client
    .get('/system/services/rtspserver')
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return e;
    });
};

interface IUpdateRtspserver extends IGeneralApiProps {
  rtspPort: number;
}

export const updateRtspserver = (props: IUpdateRtspserver) => {
  return client
    .put('/system/services/rtspserver', props)
    .then(resp => {
      const data = resp.data;
      return data;
    })
    .catch(e => {
      props?.onError && props.onError(e);
      return e;
    });
};

interface IUpdateServices extends IGeneralApiProps {
  services: IServices;
}

export const updateServices = (props: IUpdateServices) => {
  return client.put('/system/services', props.services, disableNormalizingConfig).then(resp => {
    const data = resp.data.data;
    return data;
  });
};
