import { getLicense } from 'api/license/license';
import ConfirmModal from 'components/common/confirmModal/ConfirmModal';
import PageLoading from 'components/common/pageLoading/PageLoading';
import Dashboard from 'components/dashboard/Dashboard';
import Layout from 'components/layout/Layout';
import PageNotFound from 'components/pageNotFound/PageNotFound';
import { ResetPassword } from 'components/resetPassword/ResetPassword';
import { securityTabs } from 'components/routes/securityTabs';
import { settingTabs } from 'components/routes/settingTabs';
import Signin from 'components/signin/Signin';
import { OutputPlayer } from 'components/streaming/outputs/OutputPlayer';
import { useAuthContext } from 'context';
import { t } from 'i18next';
import { RoleContext } from 'permissions/role/Roles';
import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import constant from 'utils/constant';
import { useCache } from 'utils/hooks/useCache';
import { useFullStory } from 'utils/hooks/useFullStory';

import ProtectedRoute, { IProtectedRouteProps } from './ProtectedRoute';
import {
  IRoutePaths,
  routePaths,
  securityTabPaths,
  settingsTabPaths,
  streamingTabPaths
} from './routePaths';
import { streamingTabs } from './streamingTabs';
import TabRedirect from './TabRedirect';

export const getPageName = (path: string): string => {
  switch (path) {
    case routePaths.dashboard: {
      return t('ROUTES.dashboard');
    }
    case routePaths.streaming.streams: {
      return t('ROUTES.STREAMING.streams');
    }
    case routePaths.streaming.inputs: {
      return t('ROUTES.STREAMING.inputs');
    }
    case routePaths.streaming.transcoders: {
      return t('ROUTES.STREAMING.transcoders');
    }
    case routePaths.streaming.outputs: {
      return t('ROUTES.STREAMING.outputs');
    }
    case routePaths.streaming.metadata: {
      return t('ROUTES.STREAMING.metadata');
    }
    case routePaths.settings.network: {
      return t('ROUTES.SETTINGS.network');
    }
    case routePaths.settings.archive: {
      return t('ROUTES.SETTINGS.archive');
    }
    case routePaths.settings.licensing: {
      return t('ROUTES.SETTINGS.licensing');
    }
    case routePaths.settings.reporting: {
      return t('ROUTES.SETTINGS.reporting');
    }
    case routePaths.settings.services: {
      return t('ROUTES.SETTINGS.services');
    }
    case routePaths.settings.updates: {
      return t('ROUTES.SETTINGS.updates');
    }
    case routePaths.security.accounts: {
      return t('ROUTES.SECURITY.accounts');
    }
    case routePaths.security.certificates: {
      return t('ROUTES.SECURITY.certificates');
    }
    case routePaths.security.policies: {
      return t('ROUTES.SECURITY.policies');
    }
    default:
      return '';
  }
};

const RouteManager: React.FunctionComponent = () => {
  const [auth] = useAuthContext();
  const role = useContext(RoleContext);
  const { status } = useCache({
    apiEndpoint: getLicense,
    url: '/license'
  });

  useFullStory(
    auth,
    constant.fullStoryId,
    constant.productName,
    constant.environment,
    constant.version
  );

  const isLicenseLoading = status === 'fetching';

  const defaultProtectedRouteProps: Omit<IProtectedRouteProps, 'outlet'> = {
    isAuthenticated: auth.isAuth as boolean,
    authenticationPath: routePaths.signin
  };

  const securities = securityTabs(role, isLicenseLoading);

  const settings = settingTabs(role, isLicenseLoading);
  // Operator landing falls back to reporting page.
  const settingsBase = role.can('view', 'settings/network')
    ? settingsTabPaths.network
    : settingsTabPaths.reporting;

  const mainRoutes: IRoutePaths = {
    dashboard: {
      index: true,
      path: routePaths.dashboard,
      element: <Dashboard />
    },
    streaming: {
      index: true,
      path: streamingTabPaths.base,
      element: <TabRedirect to={streamingTabPaths.streams} />
    },
    ...streamingTabs(isLicenseLoading),
    settings: {
      index: true,
      path: settingsTabPaths.base,
      element: <TabRedirect to={settingsBase} />
    },
    ...settings,
    security: {
      index: true,
      path: securityTabPaths.base,
      element: <TabRedirect to={securityTabPaths.accounts} />
    },
    ...securities,
    help: {
      index: false,
      path: routePaths.help
    }
  };

  // This is here to dynamically set a basename
  //  if we are loading from an installed appliance "/argon" or "/" from local dev
  // TODO - convert to a webpack plugin env param after supporting config-overrides in KRAK-3649
  const basename = location.pathname.startsWith('/argon') ? '/argon' : '/';

  return (
    <BrowserRouter basename={basename}>
      <ConfirmModal />
      <Routes>
        <Route element={<TabRedirect to={routePaths.dashboard} />} path={'/'} />
        <Route element={<Signin />} path={status === 'error' ? '*' : routePaths.signin} />
        {auth.mustChangePassword && <Route element={<ResetPassword />} path='*' />}
        {isLicenseLoading && <Route element={<PageLoading />} path='*' />}
        {!isLicenseLoading && !auth.mustChangePassword && (
          <>
            <Route element={<Layout />}>
              {Object.keys(mainRoutes).map(key => {
                return (
                  <Route
                    key={mainRoutes[key].path}
                    index={!!mainRoutes[key].index}
                    path={mainRoutes[key].path}
                    {...(mainRoutes[key].element !== null
                      ? {
                          element: (
                            <ProtectedRoute
                              {...defaultProtectedRouteProps}
                              outlet={mainRoutes[key].element}
                            />
                          )
                        }
                      : {})}
                  />
                );
              })}
            </Route>
            <Route
              element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<OutputPlayer />} />}
              path={routePaths.outputsPlayer}
            />
          </>
        )}
        <Route
          element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<PageNotFound />} />}
          path='*'
        />
        <Route path='*' element={<Signin />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;
