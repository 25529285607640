import './loadingIcon.scss';

import { Icon, OverlayTrigger, Spinner, colorValue } from '@hai/ui-react';
import React, { useEffect } from 'react';

interface ILoading {
  loading?: boolean;
  tooltip?: { active: string; inactive: string };
  isActive: boolean;
  activeIcon: string;
  inactiveIcon: string;
  onTimeout?: any;
}

const LoadingIcon: React.FunctionComponent<ILoading> = ({
  loading,
  isActive,
  activeIcon,
  tooltip,
  inactiveIcon,
  onTimeout
}: ILoading) => {
  useEffect(() => {
    if (loading && onTimeout) {
      const timeout = setTimeout(() => {
        onTimeout();
      }, 1000 * 20);
      return () => clearTimeout(timeout);
    }
  }, [loading, onTimeout]);

  if (loading) {
    return <Spinner loopTime={1} className={'kraken-spinner loading icon'} />;
  } else {
    return (
      <OverlayTrigger popoverContent={isActive ? tooltip?.active : tooltip?.inactive}>
        <Icon
          iconname={isActive ? activeIcon : inactiveIcon}
          size='sm'
          color={colorValue('haiui-gray-04')}
        />
      </OverlayTrigger>
    );
  }
};

export default LoadingIcon;
