import { List, colorValue } from '@hai/ui-react';
import { IStreamItem } from 'api/streaming/streaming.type';
import {
  EnumSortDirection,
  EnumSortType,
  ISortComparator,
  SortManager
} from 'components/actionBar/SortManager';
import StreamItem from 'components/streaming/streams/StreamItem';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import constant from 'utils/constant';
import { useStreamInfo } from 'utils/hooks/useStreamInfo';

interface IProps<T extends IStreamItem> {
  list: T[];
  newItem?: string;
  hideCheckbox?: boolean;
  saveCallback: Function;
  sortInfo: {
    [K in keyof Partial<T> | string]: {
      title: string;
      type: EnumSortType;
      /*
       *  Optional
       *  Supply custom comparator function receiving model A and B as input and returning -1 | 0 | 1
       */
      comparator?: ISortComparator<T>;
      /*
       *  Optional
       *  Value provider for the field. Takes model as input and output value to sort on
       */
      provider?: (arg: T) => any;
    };
  };
  selectedFilter: string;
  sorting: string;
  direction: EnumSortDirection;
  listItemTestId?: string;
  streamListTestId?: string;
}

const StreamList = <T extends IStreamItem>(props: IProps<T>) => {
  const {
    list,
    newItem,
    selectedFilter,
    hideCheckbox = false,
    saveCallback,
    sortInfo,
    sorting,
    direction,
    listItemTestId,
    streamListTestId
  } = props;

  const panelRef = useRef<null | HTMLElement>(null);
  const getStreamInfo = useStreamInfo();

  const location = useLocation();
  const currentStreamId = location?.state?.id;
  const actionPanel = location?.state?.action;

  useEffect(() => {
    if (panelRef.current) {
      // wait till the panel expanded
      const timer = setTimeout(() => {
        panelRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, constant.timeout.panelTransition);
      return () => clearTimeout(timer);
    }
  }, [panelRef]);

  return (
    <List
      selectable={hideCheckbox}
      accentColor={colorValue('haiui-aqua-01')}
      data-testid={streamListTestId}
    >
      <SortManager
        newItem={newItem}
        sortInfo={sortInfo}
        list={list}
        sortKey={sorting}
        direction={direction}
      >
        {list &&
          list
            .filter((item: IStreamItem) => {
              if (selectedFilter && selectedFilter !== 'None') {
                if (selectedFilter !== getStreamInfo(item).actionType) {
                  return false;
                }
              }
              return true;
            })
            .map((item: IStreamItem, index: number) => {
              return (
                <StreamItem
                  panelRef={currentStreamId === item.uuid ? panelRef : null}
                  openPanel={actionPanel}
                  saveCallback={saveCallback}
                  key={item.uuid}
                  item={item}
                  dataTestid={listItemTestId ? `${listItemTestId}-${index}` : ''}
                />
              );
            })}
      </SortManager>
    </List>
  );
};

export default StreamList;
