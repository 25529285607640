import * as FullStoryLib from '@fullstory/browser';
import { IAuthContextType } from 'context/auth';
import React, { useEffect } from 'react';

// borrowed from Hub360
// https://bitbucket.org/haivision/oly-ufe-auth/src/0ed3ed49907d2e6c25e9dc3b06429946fc9a1545/src/app/UserAssistance/useFullStory.tsx
export const useFullStory = (
  auth: IAuthContextType,
  fsOrgId: string,
  product: string,
  environment: string,
  version: string
) => {
  useEffect(() => {
    if (fsOrgId) {
      if (auth.shareUserAnalytics) {
        if (!FullStoryLib?.isInitialized()) {
          FullStoryLib.init({ orgId: fsOrgId }, ({ sessionUrl }) => {
            // TODO - decide if we keep or remove this
            console.info(`Started FullStory session: ${sessionUrl}`);
            FullStoryLib.setUserVars({
              product_str: product,
              environment_str: environment,
              version_str: version
              // unused vars in Kraken
              // sku_str (HAIAPP-PRO)
              // platform_str (iPhone / Android)
            });
          });
        } else {
          FullStoryLib?.restart();
        }
      } else {
        if (FullStoryLib?.isInitialized()) {
          FullStoryLib.shutdown();
        }
      }
    }
  }, [environment, fsOrgId, auth.shareUserAnalytics, product, version]);

  return <></>;
};
