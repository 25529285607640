import { Button, Form } from '@hai/ui-react';
import {
  EnumArchiveLocation,
  IArchive,
  checkArchiveLocationConnection,
  getArchiveLocations,
  updateArchiveLocation
} from 'api/settings/archiveLocations';
import { EnumFormButtonState, FormButton } from 'components/common/form/formButton/formButton';
import { FormCheckbox } from 'components/common/form/formCheckbox/formCheckbox';
import { FormContainer, FormSectionDark } from 'components/common/form/formContainer/formContainer';
import { FormInput } from 'components/common/form/formInput/formInput';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import { editArchiveSchema, editNfsArchiveSchema } from 'components/settings/archive/validations';
import { GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { EnumArchivesAction } from 'context/settings/archives';
import { SettingsContext } from 'context/settings/settings';
import { t } from 'i18next';
import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useState } from 'react';
import constant from 'utils/constant';
import { useFormRef } from 'utils/hooks/useFormRef';
import { getArchiveLocationType } from 'utils/settings';

interface IProps {
  buttonState: EnumFormButtonState;
  setButtonState: Dispatch<SetStateAction<EnumFormButtonState>>;
  item: IArchive;
  onSuccess: () => void;
}

export const archiveLocationOptions = [
  {
    option: t('SETTINGS.ARCHIVES.LOCATION.local'),
    value: EnumArchiveLocation.LOCAL
  },
  {
    option: t('SETTINGS.ARCHIVES.LOCATION.network'),
    value: EnumArchiveLocation.NFS
  }
];

export const EditArchive = (props: IProps) => {
  const { item, onSuccess, buttonState, setButtonState } = props;

  const [testBtnState, setTestBtnState] = useState(EnumFormButtonState.IDLE);

  const formControl = useFormRef();
  const { formRef, formRefCallback, formSubmitActive } = formControl;
  const { screen } = useContext(GlobalContext);
  const columns = screen.columns.main;
  const { settingsDispatch } = useContext(SettingsContext);
  const { dispatch } = useContext(NotifyContext);

  const [formData, setFormData] = useState({
    name: item.name,
    autoConnect: item.autoConnect,
    mountPoint: `/assets/archive_locations/${item.uuid}}`,
    location: getArchiveLocationType(item.type)
  });

  const onChangeHandler = (evt: any) => {
    const { name, value } = evt.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const isLocal = item.type === EnumArchiveLocation.LOCAL;

  const onSave = async (values: any) => {
    setButtonState(EnumFormButtonState.PENDING);
    const archive: any = {
      uuid: item.uuid,
      type: item.type,
      name: values.name,
      autoConnect: values.autoConnect
    };
    if (!isLocal) {
      archive.nfs = {
        remoteHost: values.remoteHost,
        remotePath: values.remotePath
      };
    }
    const res = await updateArchiveLocation({ item: archive });
    if (res.message) {
      getArchiveLocations().then((value: IArchive[]) => {
        value && settingsDispatch({ type: EnumArchivesAction.SET_ARCHIVES_CLEAN, payload: value });
        onSuccess();
      });
      dispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: t('SETTINGS.ARCHIVES.MESSAGES.updated', { name: item.name })
        }
      });
    }
    setTimeout(() => {
      setButtonState(EnumFormButtonState.IDLE);
    }, constant.timeout.minButtonLoading);
  };

  const checkConnection = () => {
    setTestBtnState(EnumFormButtonState.PENDING);
    checkArchiveLocationConnection({ item }).then(res => {
      if (res.result) {
        dispatch({
          type: EnumNotify.ADD_NOTIFICATION,
          payload: {
            type: 'Success',
            message: t('SETTINGS.ARCHIVES.MESSAGES.test')
          }
        });
      }
      setTimeout(() => {
        setTestBtnState(EnumFormButtonState.IDLE);
      }, constant.timeout.minButtonLoading);
    });
  };

  const formProps = {
    defaultValidation: true,
    handleSubmit: onSave,
    initialValues: {
      location: getArchiveLocationType(item.type),
      name: item.name,
      autoConnect: item.autoConnect,
      mountPoint: `/assets/archive_locations/${item.uuid}}`,
      remoteHost: !isLocal ? item.nfs?.remoteHost : '',
      remotePath: !isLocal ? item.nfs?.remotePath : ''
    },
    restValidationProps: {
      validationSchema: isLocal ? editArchiveSchema : editNfsArchiveSchema,
      innerRef: formRefCallback
    }
  };

  let containerClassName = 'form-container-lg';
  if (columns === 2) {
    containerClassName = 'form-container-md';
  }
  if (columns === 1) {
    containerClassName = 'form-container-sm';
  }

  const onAutoConnectChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      autoConnect: e.target.checked
    });
  };

  return (
    <Form
      {...formProps}
      onSubmit={e => {
        e.preventDefault();
      }}
      className={'edit-archive'}
    >
      <FormContainer columns={columns}>
        <FormInput
          label={t('SETTINGS.ARCHIVES.FORM.name')}
          name='name'
          defaultValue={item.name}
          required
        />
        <FormButton columns={columns}>
          <Button variant={'secondary'} onClick={checkConnection} state={testBtnState}>
            {t('SETTINGS.ARCHIVES.FORM.test')}
          </Button>
          <Button
            variant={'primary'}
            state={buttonState}
            onClick={() => submitHandler(formRef)}
            disabled={!formSubmitActive}
          >
            {t('apply')}
          </Button>
        </FormButton>
      </FormContainer>
      <FormSectionDark title={t('SETTINGS.ARCHIVES.parameters')}>
        <FormContainer columns={columns} className={containerClassName}>
          <FormInput
            label={t('SETTINGS.ARCHIVES.FORM.location')}
            name='location'
            viewOnly
            defaultValue={getArchiveLocationType(item.type)}
          />
          <FormInput
            label={t('SETTINGS.ARCHIVES.FORM.mountPoint')}
            name='mountPoint'
            viewOnly
            showCopy={true}
            defaultValue={`/assets/archive_locations/${item.uuid}`}
            classNames={'grid-column span1'}
          />
          <FormCheckbox
            formRef={formRef}
            item={item}
            label={t('SETTINGS.ARCHIVES.FORM.autoConnect')}
            name='autoConnect'
            onChange={onAutoConnectChange}
          />
          {!isLocal && (
            <>
              <FormInput
                label={t('SETTINGS.ARCHIVES.FORM.remoteHost')}
                name='remoteHost'
                onChange={onChangeHandler}
                required={true}
              />
              <FormInput
                label={t('SETTINGS.ARCHIVES.FORM.remotePath')}
                name='remotePath'
                onChange={onChangeHandler}
                required={true}
              />
            </>
          )}
        </FormContainer>
      </FormSectionDark>
    </Form>
  );
};
