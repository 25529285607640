import { ButtonStateType } from '@hai/ui-react/dist/components/Button/Button';
import { updateTranscoder } from 'api/transcoder/transcoder';
import { ITranscoder } from 'api/transcoder/transcoder.type';
import { submitHandler } from 'components/common/form/submitHandler/submitHandler';
import { useNotifyRestartStream } from 'components/common/notify/notify';
import { GlobalContext } from 'context/global';
import { EnumNotify, NotifyContext } from 'context/notify';
import { t } from 'i18next';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useFormRef } from 'utils/hooks/useFormRef';

import { TranscoderFormLayout } from './TranscoderFormLayout';

interface IFormItems {
  values: any;
  setSubmitting: any;
}
interface IProps {
  cancel: () => void;
  item: ITranscoder;
  saveCallback: () => void;
  setButtonState: Dispatch<SetStateAction<ButtonStateType>>;
  buttonState: ButtonStateType;
}
export const EditTranscoder = (props: IProps) => {
  const { buttonState, cancel, item, saveCallback, setButtonState } = props;
  const { notifyRestartStream } = useNotifyRestartStream();

  const formControl = useFormRef();

  const { screen } = useContext(GlobalContext);
  const columns = screen.columns.main;
  const { dispatch } = useContext(NotifyContext);

  const [staticItem] = useState(item);

  const saveForm = async (values: any, { setSubmitting }: any) => {
    const result = await onSave({ values, setSubmitting });
    if (result.restartStreamNeeded) {
      notifyRestartStream({
        result,
        type: 'TRANSCODERS'
      });
    }
  };

  const onSave = async ({ values, setSubmitting }: IFormItems) => {
    setButtonState('pending');
    const result = await updateTranscoder({ apiParams: values });
    if (result?.success) {
      dispatch({
        type: EnumNotify.ADD_NOTIFICATION,
        payload: {
          type: 'Success',
          message: t('STREAMING.MESSAGES.updated', { name: values.name })
        }
      });
      saveCallback();
    } else {
      setButtonState('idle');
    }
    setSubmitting(false);
    return result;
  };

  const formProps = {
    defaultValidation: true,
    handleSubmit: saveForm,
    initialValues: staticItem
  };

  return (
    <TranscoderFormLayout
      cancel={cancel}
      columns={columns}
      buttonState={buttonState}
      formControl={formControl}
      formProps={formProps}
      item={item}
      submitHandler={() => submitHandler(formControl.formRef)}
    />
  );
};
