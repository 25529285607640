import { FileUpload } from '@hai/ui-react';
import React from 'react';

interface IPresetsUpload {
  files: any;
  setFiles: any;
  uploadState: any;
}

export const PresetsUpload = (props: IPresetsUpload) => {
  const { files, setFiles, uploadState } = props;

  const onFileChangeHandler = (files: any) => {
    setFiles(files);
  };

  return (
    <div className='preset-drag-n-drop' data-auto='section_drag_and_drop'>
      <FileUpload
        files={files}
        useFsMask={true}
        uploadState={uploadState}
        onFileChange={onFileChangeHandler}
      />
    </div>
  );
};
