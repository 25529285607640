import { colorValue } from '@hai/ui-react';
import { IDashboardStat } from 'api/dashboard/dashboard.type';
import { IInputItem } from 'api/inputs/input.type';
import { IOutputItem } from 'api/outputs/output.type';
import { EnumStreamMode, EnumStreamType } from 'api/streaming/streaming.type';
import { EnumTimescale } from 'components/streaming/streamStatsDetail/StreamStatsDetail';
import { t } from 'i18n';
import constant from 'utils/constant';
import { uptimeHHMMSS } from 'utils/formatText';

export enum EnumThumbnail {
  NORMAL = 'NORMAL',
  DISABLED = 'DISABLED',
  WARNING = 'WARNING',
  STREAMING = 'STREAMING',
  INPUT_ERROR = 'INPUT_ERROR',
  OUTPUT_ERROR = 'OUTPUT_ERROR',
  INCOMPLETE = 'INCOMPLETE',
  OFFLINE = 'OFFLINE',
  NO_DATA = 'NO_DATA'
}

export enum EnumStatusType {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
  TRANSCODER = 'TRANSCODER'
}

export const actionStatus = {
  streaming: t('STREAMING.STATE.streaming'),
  alert: t('STREAMING.STATE.alert'),
  expiring: t('STREAMING.STATE.expiring'),
  inactive: t('STREAMING.STATE.inactive'),
  online: t('STREAMING.STATE.online'),
  active: t('SECURITY.ACCOUNTS.STATE.active'),
  disabled: t('SECURITY.ACCOUNTS.STATE.disabled'),
  locked: t('SECURITY.ACCOUNTS.STATE.locked')
};

export const convertActionTypeForSort = (type: string) => {
  if (
    type === actionStatus.active ||
    type === actionStatus.online ||
    type === actionStatus.streaming
  ) {
    return 0;
  }
  if (type === actionStatus.alert) {
    return 1;
  }
  if (type === actionStatus.inactive) {
    return 2;
  }
  return 2;
};

export const getActionStatus = (item: any) => {
  const stateCode = item.state;
  if (stateCode === 2) {
    return actionStatus.streaming;
  }
  if (stateCode === 4) {
    return actionStatus.inactive;
  }
  if (stateCode === 5) {
    return actionStatus.alert;
  }
  return actionStatus.inactive;
};

export const getPanelColor = (actionType: string) => {
  const streaming = 'haiui-green-01';
  const inactive = 'haiui-gray-07';
  const alert = 'haiui-amber-01';
  const active = streaming;
  const disabled = inactive;
  const locked = 'haiui-white';

  switch (actionType) {
    case actionStatus.streaming:
      return streaming;
    case actionStatus.inactive:
      return inactive;
    case actionStatus.alert:
      return alert;
    case actionStatus.online:
    case actionStatus.active:
      return active;
    case actionStatus.disabled:
      return disabled;
    case actionStatus.locked:
      return locked;
    default:
      return inactive;
  }
};

export const getThumbnailTitle = (type: EnumThumbnail) => {
  switch (type) {
    case EnumThumbnail.DISABLED:
      return t('STREAMING.THUMBNAILS.disabled');
    case EnumThumbnail.INCOMPLETE:
      return t('STREAMING.THUMBNAILS.incomplete');
    case EnumThumbnail.INPUT_ERROR:
      return t('STREAMING.THUMBNAILS.inputErr');
    case EnumThumbnail.OUTPUT_ERROR:
      return t('STREAMING.THUMBNAILS.outputErr');
    case EnumThumbnail.NORMAL:
      return '';
    case EnumThumbnail.STREAMING:
      return t('STREAMING.THUMBNAILS.streaming');
    case EnumThumbnail.OFFLINE:
      return t('STREAMING.THUMBNAILS.offline');
    case EnumThumbnail.WARNING:
      return t('STREAMING.THUMBNAILS.warning');
    case EnumThumbnail.NO_DATA:
      return t('STREAMING.THUMBNAILS.noData');
    default:
      return t('STREAMING.THUMBNAILS.offline');
  }
};

export const getActionStatusColor = (value: string) => {
  const streaming = colorValue('haiui-green-01');
  const alert = colorValue('haiui-amber-01');
  const inactive = colorValue('haiui-gray-07');
  const online = colorValue('haiui-blue-04');
  const locked = colorValue('haiui-red-01');

  switch (value) {
    case actionStatus.streaming:
      return streaming;
    case actionStatus.inactive:
      return inactive;
    case actionStatus.alert:
      return alert;
    case actionStatus.online:
      return online;
    case actionStatus.active:
      return streaming;
    case actionStatus.disabled:
      return inactive;
    case actionStatus.locked:
      return locked;
    default:
      return inactive;
  }
};

export const getStreamConnection = (item: IInputItem | IOutputItem): string => {
  const isSRTStream = item.streamType === EnumStreamType.SRT;
  let connection = item.url;
  if (isSRTStream) {
    const address = item.srtAddress || t('STREAMING.CONNECTION.any');
    const port = item.srtMode === EnumStreamMode.LISTENER ? item.srtLocalPort : item.srtRemotePort;
    connection = address + ':' + port;
  }
  return connection || '';
};

export const getInputStats = (input: any) => {
  const isSRT = input.srtStats;
  const srtData = isSRT
    ? [
        {
          name: t('STREAMING.INPUTS.STATS.srtMode'),
          value: input?.srtStats[0].mode,
          dataAuto: 'srtMode'
        },
        {
          name: t('STREAMING.INPUTS.STATS.srtState'),
          value: input?.srtStats[0].state,
          dataAuto: 'srtState'
        }
      ]
    : [];
  const data = [
    {
      name: t('STREAMING.INPUTS.STATS.url'),
      value: isSRT ? input.srtStats[0].url : input.urls[0],
      dataAuto: 'url'
    },
    {
      name: t('STREAMING.INPUTS.STATS.streamType'),
      value: input.streamType,
      dataAuto: 'streamType'
    },
    { name: t('STREAMING.INPUTS.STATS.bytes'), value: input.bytes, dataAuto: 'bytes' },
    {
      name: t('STREAMING.INPUTS.STATS.videoBytes'),
      value: input.videoBytes,
      dataAuto: 'videoBytes'
    },
    {
      name: t('STREAMING.INPUTS.STATS.audioBytes'),
      value: input.audioBytes,
      dataAuto: 'audioBytes'
    },
    {
      name: t('STREAMING.INPUTS.STATS.auxBytes'),
      value: input.auxBytes === '-1' ? t('STREAMING.INPUTS.STATS.noAux') : input.auxBytes,
      dataAuto: 'auxBytes'
    },
    { name: t('STREAMING.INPUTS.STATS.bitrate'), value: input.bitrate, dataAuto: 'bitrate' },
    {
      name: t('STREAMING.INPUTS.STATS.videoBitrate'),
      value: input.videoBitrate,
      dataAuto: 'videoBitrate'
    },
    {
      name: t('STREAMING.INPUTS.STATS.audioBitrate'),
      value: input.audioBitrate,
      dataAuto: 'audioBitrate'
    },
    {
      name: t('STREAMING.INPUTS.STATS.auxBitrate'),
      value: input.auxBitrate,
      dataAuto: 'auxBitrate'
    },
    { name: t('STREAMING.INPUTS.STATS.videoType'), value: input.videoType, dataAuto: 'videoType' },
    { name: t('STREAMING.INPUTS.STATS.audioType'), value: input.audioType, dataAuto: 'audioType' },
    { name: t('STREAMING.INPUTS.STATS.auxType'), value: input.auxType, dataAuto: 'auxType' },
    {
      name: t('STREAMING.INPUTS.STATS.videoCount'),
      value: input.videoCount,
      dataAuto: 'videoCount'
    },
    {
      name: t('STREAMING.INPUTS.STATS.audioCount'),
      value: input.audioCount,
      dataAuto: 'audioCount'
    },
    { name: t('STREAMING.INPUTS.STATS.auxCount'), value: input.auxCount, dataAuto: 'auxCount' },
    {
      name: t('STREAMING.INPUTS.STATS.videoProgId'),
      value: input.videoProgid,
      dataAuto: 'videoProgId'
    },
    {
      name: t('STREAMING.INPUTS.STATS.audioProgId'),
      value: input.audioProgid,
      dataAuto: 'audioProgId'
    },
    { name: t('STREAMING.INPUTS.STATS.auxProgId'), value: input.auxProgid, dataAuto: 'auxProgId' },
    { name: t('STREAMING.INPUTS.STATS.psiCount'), value: input.psiCount, dataAuto: 'psiCount' },
    { name: t('STREAMING.INPUTS.STATS.patCount'), value: input.patCount, dataAuto: 'patCount' },
    { name: t('STREAMING.INPUTS.STATS.pmtCount'), value: input.pmtCount, dataAuto: 'pmtCount' }
  ];
  return srtData.concat(data);
};

export const getOutputStats = (output: any) => {
  const isSRT = output.srtStats;
  const srtData = isSRT
    ? [
        {
          name: t('STREAMING.OUTPUTS.STATS.srtMode'),
          value: output?.srtStats[0].mode,
          dataAuto: 'srtMode'
        },
        {
          name: t('STREAMING.OUTPUTS.STATS.srtState'),
          value: output?.srtStats[0].state,
          dataAuto: 'srtState'
        }
      ]
    : [];
  const data = [
    {
      name: t('STREAMING.OUTPUTS.STATS.url'),
      value: isSRT ? output.srtStats[0].url : output.urls[0],
      dataAuto: 'url'
    },
    { name: t('STREAMING.OUTPUTS.STATS.bytes'), value: output.bytes, dataAuto: 'bytes' },
    {
      name: t('STREAMING.OUTPUTS.STATS.videoBytes'),
      value: output.videoBytes,
      dataAuto: 'videoBytes'
    },
    {
      name: t('STREAMING.OUTPUTS.STATS.audioBytes'),
      value: output.audioBytes,
      dataAuto: 'audioBytes'
    },
    {
      name: t('STREAMING.OUTPUTS.STATS.auxBytes'),
      value: output.auxBytes === '-1' ? t('STREAMING.OUTPUTS.STATS.noAux') : output.auxBytes,
      dataAuto: 'auxBytes'
    },
    { name: t('STREAMING.OUTPUTS.STATS.bitrate'), value: output.bitrate, dataAuto: 'bitrate' },
    {
      name: t('STREAMING.OUTPUTS.STATS.videoBitrate'),
      value: output.videoBitrate,
      dataAuto: 'videoBitrate'
    },
    {
      name: t('STREAMING.OUTPUTS.STATS.audioBitrate'),
      value: output.audioBitrate,
      dataAuto: 'audioBitrate'
    },
    {
      name: t('STREAMING.OUTPUTS.STATS.auxBitrate'),
      value: output.auxBitrate,
      dataAuto: 'auxBitrate'
    },
    {
      name: t('STREAMING.OUTPUTS.STATS.videoType'),
      value: output.videoType,
      dataAuto: 'videoType'
    },
    {
      name: t('STREAMING.OUTPUTS.STATS.audioType'),
      value: output.audioType,
      dataAuto: 'audioType'
    },
    { name: t('STREAMING.OUTPUTS.STATS.auxType'), value: output.auxType, dataAuto: 'auxType' },
    {
      name: t('STREAMING.OUTPUTS.STATS.videoCount'),
      value: output.videoCount,
      dataAuto: 'videoCount'
    },
    {
      name: t('STREAMING.OUTPUTS.STATS.audioCount'),
      value: output.audioCount,
      dataAuto: 'audioCount'
    },
    { name: t('STREAMING.OUTPUTS.STATS.auxCount'), value: output.auxCount, dataAuto: 'auxCount' },
    {
      name: t('STREAMING.OUTPUTS.STATS.videoProgId'),
      value: output.videoProgid,
      dataAuto: 'videoProgId'
    },
    {
      name: t('STREAMING.OUTPUTS.STATS.audioProgId'),
      value: output.audioProgid,
      dataAuto: 'audioProgId'
    },
    {
      name: t('STREAMING.OUTPUTS.STATS.auxProgId'),
      value: output.auxProgid,
      dataAuto: 'auxProgId'
    },
    { name: t('STREAMING.OUTPUTS.STATS.psiCount'), value: output.psiCount, dataAuto: 'psiCount' },
    { name: t('STREAMING.OUTPUTS.STATS.patCount'), value: output.patCount, dataAuto: 'patCount' },
    { name: t('STREAMING.OUTPUTS.STATS.pmtCount'), value: output.pmtCount, dataAuto: 'pmtCount' }
  ];
  return srtData.concat(data);
};

export const getDecoderStats = (decoder: any) => {
  const data = [
    {
      name: t('STREAMING.TRANSCODERS.DECODER_STATS.videoCount'),
      value: decoder.videoCount,
      dataAuto: 'videoCount'
    },
    {
      name: t('STREAMING.TRANSCODERS.DECODER_STATS.audioCount'),
      value: decoder.audioCount,
      dataAuto: 'audioCount'
    },
    {
      name: t('STREAMING.TRANSCODERS.DECODER_STATS.auxCount'),
      value: decoder.auxCount,
      dataAuto: 'auxCount'
    },
    {
      name: t('STREAMING.TRANSCODERS.DECODER_STATS.resolution'),
      value: decoder.videoResolution.height + 'x' + decoder.videoResolution.width,
      dataAuto: 'resolution'
    },
    {
      name: t('STREAMING.TRANSCODERS.DECODER_STATS.pixelFormat'),
      value: decoder.videoPixelFormat,
      dataAuto: 'pixelFormat'
    },
    {
      name: t('STREAMING.TRANSCODERS.DECODER_STATS.aspectRatio'),
      value: decoder.videoPixelAspectRatio.height + ':' + decoder.videoPixelAspectRatio.width,
      dataAuto: 'aspectRatio'
    },
    {
      name: t('STREAMING.TRANSCODERS.DECODER_STATS.framerate'),
      value: decoder.videoFramerate,
      dataAuto: 'framerate'
    },
    {
      name: t('STREAMING.TRANSCODERS.DECODER_STATS.calculatedFramerate'),
      value: decoder.videoCalculatedFramerate,
      dataAuto: 'calculatedFramerate'
    },
    {
      name: t('STREAMING.TRANSCODERS.DECODER_STATS.audioSampleRate'),
      value: decoder.audioSamplerate,
      dataAuto: 'audioSampleRate'
    },
    {
      name: t('STREAMING.TRANSCODERS.DECODER_STATS.audioChannels'),
      value: decoder.audioChannels,
      dataAuto: 'audioChannels'
    }
  ];
  return data;
};

export const getBypassStats = (bypass: any) => {
  const obj = [
    { name: t('STREAMING.TRANSCODERS.BYPASS_STATS.uuid'), value: bypass.uuid, dataAuto: 'uuid' },
    { name: t('STREAMING.TRANSCODERS.BYPASS_STATS.name'), value: bypass.name, dataAuto: 'name' },
    {
      name: t('STREAMING.TRANSCODERS.BYPASS_STATS.uptime'),
      value: uptimeHHMMSS(bypass.uptime),
      dataAuto: 'uptime'
    },
    { name: t('STREAMING.TRANSCODERS.BYPASS_STATS.pid'), value: bypass.pid, dataAuto: 'pid' },
    { name: t('STREAMING.TRANSCODERS.BYPASS_STATS.bytes'), value: bypass.bytes, dataAuto: 'bytes' },
    {
      name: t('STREAMING.TRANSCODERS.BYPASS_STATS.bitrate'),
      value: bypass.bitrate,
      dataAuto: 'bitrate'
    },
    {
      name: t('STREAMING.TRANSCODERS.BYPASS_STATS.inputUrl'),
      value: bypass.inputUrl,
      dataAuto: 'inputUrl'
    }
  ];
  bypass.outputUrls.forEach((i: string, index: number) =>
    obj.push({
      name:
        index === 0
          ? `${t('STREAMING.TRANSCODERS.BYPASS_STATS.outputUrls', {
              count: bypass.outputUrls.length
            })}`
          : '',
      value: i,
      dataAuto: `outputUrl_${index}`
    })
  );

  return obj;
};

export const getEncoderStats = (encoder: any) => {
  const data = [
    {
      name: t('STREAMING.TRANSCODERS.ENCODER_STATS.videoCount'),
      value: encoder.videoCount,
      dataAuto: 'videoCount'
    },
    {
      name: t('STREAMING.TRANSCODERS.ENCODER_STATS.audioCount'),
      value: encoder.audioCount,
      dataAuto: 'audioCount'
    },
    {
      name: t('STREAMING.TRANSCODERS.ENCODER_STATS.auxCount'),
      value: encoder.auxCount,
      dataAuto: 'auxCount'
    },
    {
      name: t('STREAMING.TRANSCODERS.ENCODER_STATS.resolution'),
      value: encoder.videoResolution.height + 'x' + encoder.videoResolution.width,
      dataAuto: 'resolution'
    },
    {
      name: t('STREAMING.TRANSCODERS.ENCODER_STATS.pixelFormat'),
      value: encoder.videoPixelFormat,
      dataAuto: 'pixelFormat'
    },
    {
      name: t('STREAMING.TRANSCODERS.ENCODER_STATS.aspectRatio'),
      value: encoder.videoPixelAspectRatio.height + ':' + encoder.videoPixelAspectRatio.width,
      dataAuto: 'aspectRatio'
    },
    {
      name: t('STREAMING.TRANSCODERS.ENCODER_STATS.targetFramerate'),
      value: encoder.videoFramerate,
      dataAuto: 'targetFramerate'
    },
    {
      name: t('STREAMING.TRANSCODERS.ENCODER_STATS.instantFramerate'),
      value: encoder.videoCalculatedFramerate,
      dataAuto: 'instantFramerate'
    },
    {
      name: t('STREAMING.TRANSCODERS.ENCODER_STATS.audioSampleRate'),
      value: encoder.audioSamplerate,
      dataAuto: 'audioSampleRate'
    },
    {
      name: t('STREAMING.TRANSCODERS.ENCODER_STATS.audioChannels'),
      value: encoder.audioChannels,
      dataAuto: 'audioChannels'
    }
  ];
  return data;
};

export const bitsToMegabits = (n: number) => {
  return (n / Math.pow(10, 6)).toFixed(2);
};

export const getAmountByTimescale = (ts: EnumTimescale) => {
  switch (ts) {
    case EnumTimescale.fiveMins:
      return 60;
    case EnumTimescale.sixtyMins:
      return 60 * 12;
    case EnumTimescale.twentyFourHours:
      return 60 * 12 * 24;
    default:
      return 60;
  }
};

export const getItemLengthByTimescale = (ts: EnumTimescale) => {
  switch (ts) {
    case EnumTimescale.fiveMins:
      return 0.08333;
    case EnumTimescale.sixtyMins:
      return 0.08333;
    case EnumTimescale.twentyFourHours:
      return 0.00139;
    default:
      return 0.08333;
  }
};

export const formatGraphHistory = (graphHistory: IDashboardStat[], itemsLength: number) => {
  const scaleMultiplier = ((constant.interval.systemMetrics / 1000) * 5) / 300;

  const inputGraphData: any[] = [];
  const streamGraphData: any[] = [];
  const latencyGraphData: any[] = [];

  graphHistory.slice(0, itemsLength).map((i, index) => {
    const xValue = scaleMultiplier * index;
    if (i.inputBitrate !== undefined) {
      inputGraphData.push({
        x: xValue,
        y: i.inputBitrate
      });
    }
    if (i.encoderBitrate !== undefined) {
      streamGraphData.push({
        x: xValue,
        y: i.encoderBitrate
      });
    }
    if (i.outputLatency) {
      latencyGraphData.push({
        x: xValue,
        y: i.outputLatency
      });
    }
  });

  const history = {
    left: [inputGraphData, streamGraphData],
    right: [latencyGraphData]
  };
  return history;
};
