import { IGeneralApiProps } from 'api/global.type';
import { decodeResponse } from 'api/utils/helper';

import client, { handleErrors } from '../utils/client';
import config from '../utils/config';
import { IStreamItem, IStreamSnapshot } from './streaming.type';

// /streams
interface IAddStreamProps extends IGeneralApiProps {
  apiParams: IStreamItem;
}
export const addStream = (props: IAddStreamProps) => {
  const { apiParams, onError } = props;

  return client
    .post('/streams', formatFormToApi(apiParams))
    .then(resp => {
      if (resp.status === 200) {
        return { success: true, ...resp.data };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

export const updateStream = (props: IAddStreamProps) => {
  const { apiParams, onError } = props;

  return client
    .put(`/streams/${apiParams.uuid}`, formatFormToApi(apiParams))
    .then(resp => {
      if (resp.status === 200) {
        return { ...resp.data, success: true };
      } else {
        onError && onError(resp);
        return { success: false };
      }
    })
    .catch(e => {
      onError && onError(e);
      return { success: false };
    });
};

export const streamLogEnable = (props: IStreamItem) => {
  const { uuid } = props;
  return client.put(`/streams/${uuid}/enable-logs`).then(resp => {
    if (resp.status === 200) {
      return { ...resp.data, success: true };
    } else {
      return { success: false };
    }
  });
};
export const streamLogDisable = (props: IStreamItem) => {
  const { uuid } = props;
  return client.put(`/streams/${uuid}/disable-logs`).then(resp => {
    if (resp.status === 200) {
      return { ...resp.data, success: true };
    } else {
      return { success: false };
    }
  });
};

export const getStreams = (props: IGeneralApiProps) => {
  const { onError } = props;
  handleErrors(props);
  return client
    .get('/streams')
    .then(resp => {
      const streamList: IStreamItem[] = resp.data.streamList;
      return apiToFormdata(streamList);
    })
    .catch(e => {
      onError && onError(e);
      return { error: e };
    });
};

interface IGetStreamProps extends IGeneralApiProps {
  apiParams: { uuid: string };
}

export const getStream = (props: IGetStreamProps) => {
  const { apiParams, onError } = props;
  handleErrors(props);
  return client
    .get(`/streams/${apiParams.uuid}`)
    .then(resp => {
      const streamList: IStreamItem[] = resp.data;
      return apiToFormdata([streamList])[0];
    })
    .catch(e => {
      onError && onError(e);
    });
};

interface IRestartProps extends IGeneralApiProps {
  apiParams: { uuid: string };
}

export const restartStream = (props: IRestartProps) => {
  const { apiParams, onError } = props;
  handleErrors(props);
  return client.put(`/streams/restart?uuid=${apiParams.uuid}`, apiParams).then(resp => {
    if (resp.status === 200) {
      return { success: true };
    } else {
      onError && onError(resp);
      return { success: false };
    }
  });
};

interface IGetSnapshots extends IGeneralApiProps {
  tappoint: string;
}

export const getStreamSnapshots = (props: IGetSnapshots) => {
  const { onError, tappoint } = props;
  handleErrors(props);
  return client
    .get(`/snapshots/tappoints/${tappoint}/sessions`)
    .then(resp => {
      const snapshotList: IStreamSnapshot[] = resp.data;
      return snapshotList;
    })
    .catch(e => {
      onError && onError(e);
    });
};

export const getStreamSnapshotURL = (tapPoint: string, sessionID: string) => {
  return `${config.ApiUrl}${config.ApiPath}snapshots/tappoints/${tapPoint}/sessions/${sessionID}`;
};

interface IGetSnapshot extends IGeneralApiProps {
  url: string;
}

export const getSnapshot = (props: IGetSnapshot) => {
  const { url, onError } = props;

  return client
    .get(url, {
      responseType: 'blob'
    })
    .then(resp => {
      return resp.request.response;
    })
    .catch(e => {
      onError && onError(e);
    });
};

interface IDeleteStream extends IGeneralApiProps {
  stream: IStreamItem;
}

export const deleteStreams = (props: IDeleteStream) => {
  const { onError, stream } = props;
  return client
    .delete(`/streams/${stream.uuid}`)
    .then(() => {
      return { success: true };
    })
    .catch(e => {
      onError && onError(e);
      return { success: true }; // delete api returns nothing
    });
};

interface IGetStreamStatsProps extends IGeneralApiProps {
  uuid: string;
}

export const getStreamStats = (props: IGetStreamStatsProps): Promise<{ success: boolean }> => {
  const { uuid, onError } = props;
  handleErrors(props);

  return client
    .get(`/stream-stats?uuid=${uuid}`)
    .then(resp => {
      return resp.data;
    })
    .catch(e => {
      onError && onError(e);
    });
};

interface IUpdateStreamStatus extends IGeneralApiProps {
  item: IStreamItem;
}

export const updateStreamToStart = (props: IUpdateStreamStatus): Promise<{ success: boolean }> => {
  const { item, onError } = props;
  handleErrors(props);

  return client
    .put(`/streams/${item.uuid}/start`)
    .then(resp => {
      return { ...resp.data, item };
    })
    .catch(e => {
      onError && onError(e);

      return e?.response;
    });
};

export const updateStreamToStop = (props: IUpdateStreamStatus): Promise<{ success: boolean }> => {
  const { item, onError } = props;

  return client
    .put(`/streams/${item.uuid}/stop`)
    .then(resp => {
      return { ...resp.data, item };
    })
    .catch(e => {
      onError && onError(e);
      return e?.response?.data;
    });
};

const apiToFormdata = (apiData: any) => {
  return apiData.map((i: any) => {
    return {
      ...i,
      outputs: i.outputs.filter(
        (val: string, index: number, array: string[]) => array.indexOf(val) === index
      ),
      name: decodeResponse(i.name)
    };
  });
};

const formatFormToApi = (apiData: IStreamItem) => {
  delete apiData.inputName;
  delete apiData.outputSummaries;
  if (apiData.mode === 'iorouter') {
    apiData.inputBypass = apiData.inputPassthru = apiData.input;
  }

  return apiData;
};
