import { t } from 'i18n';
import * as Yup from 'yup';

export const addCertSchema = Yup.object({
  name: Yup.string().required(t<string>('VALIDATION.SECURITY.name')),
  type: Yup.string().required(t<string>('VALIDATION.SECURITY.type')),
  subject: Yup.string()
    .required(t<string>('VALIDATION.SECURITY.subject'))
    .min(2, t<string>('VALIDATION.SECURITY.subjectLeast2')),
  digest: Yup.string().required(t<string>('VALIDATION.SECURITY.digest')),
  newKey: Yup.number()
    .integer()
    .typeError(t('VALIDATION.notNumber') as string)
});
